import React, { ReactNode } from "react"
import { toAbsoluteUrl } from "../../_metronic/_helpers";

type AuthPageContentWrapperProps = {
    children: ReactNode;
}

export function AuthPageContentWrapper({ children }: AuthPageContentWrapperProps) {
    return (
        <div className="d-flex flex-column flex-root">
            {/*begin::Login*/}
            <div
                className="login login-1 login-signin-on d-flex flex-column flex-lg-row justify-content-lg-start
                    justify-content-center flex-column-fluid align-items-lg-stretch align-items-center"
                id="kt_login"
                style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-login.png")})`, backgroundSize: "cover" }}
            >
                <div className="d-none d-lg-flex align-items-center" style={{ opacity: "0.5" }}>
                    <div className="animate__animated animate__backInLeft bg-white position-absolute w-25"
                    style={{
                        height: "93%",
                        left: "18%",
                        borderTopRightRadius: "3rem",
                        borderBottomRightRadius: "3rem"
                    }}></div>
                </div>

                {/*begin::Content*/}
                <div className="animate__animated animate__backInLeft custom-login-card col-lg-5 col-md-10 col-11 d-flex
                    flex-column justify-content-center position-relative p-5 overflow-hidden">

                    {children}

                    {/* begin::Mobile footer */}
                    <div className="d-flex flex-column-auto flex-column flex-sm-row justify-content-center align-items-center mt-5">
                        <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                            &copy; {new Date().getFullYear()} Uninteract
                        </div>
                    </div>
                    {/* end::Mobile footer */}
                </div>
                {/*end::Content*/}

                {/*begin::Aside*/}
                <div
                    className="col-lg-7 d-none d-lg-flex justify-content-center align-items-center px-30"
                >
                    <div className="animate__animated animate__fadeInDown mb-20 text-white">
                        <img className="mb-6" width="480" src={toAbsoluteUrl("/media/logos/header/logo-header-black.png")} alt="" />
                        {/* <h1 className="mb-6 font-weight-boldest" style={{ fontSize: "46px" }}>Único. Inteligente. Interativo.</h1> */}
                        <h3 style={{ lineHeight: "1.5" }}>Com o <b>UNINTERACT</b>, você gerencia suas finanças empresariais e estoques, gera boletos bancários, emite notas fiscais, controla vendas e compras, gera relatórios personalizados, e muito mais!</h3>
                    </div>
                </div>
                {/*begin::Aside*/}
            </div>
            {/*end::Login*/}
        </div>
    );
}