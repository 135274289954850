/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Badge, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import useCompany from "../../../../../app/hooks/company";
import { useCompanyBranch } from "../../../../../app/hooks/companyBranch";
import useMenu, { Menus } from "../../../../../app/hooks/menu";
import useNotification from "../../../../../app/hooks/notification";
import CompanyService from "../../../../../app/services/CompanyService";
import { formatDate } from "../../../../../app/utils/dateFormat";
import { getDate } from "../../../../../app/utils/dateTimeHelper";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import '../../../../../app/style.css'
import { limitString } from "../../../../../app/utils/limitString";
import api from "../../../../../app/services/Api";
import * as auth from "../../../../../app/modules/Auth";
import '../../../../css/HeaderMenu.css';
import { Dashboard } from "@material-ui/icons";
import clsx from "clsx";

export function HeaderMenu({ layoutProps, notificationsOpen, setNotificationsOpen }) {
    const isMobile = useMediaQuery('(max-width:992px)');
    const location = useLocation();
    const {user}  = useSelector((state) => state.auth);
    const { menus } = useMenu();
    const { companies, companyCanHaveBranches } = useCompany({ only: user?.companyId, appendSubscriptionPlan: true });
    const { notifications, countNotifications, handleDismissNotification } = useNotification();
    const [ mainCompany ] = companies;
    const { selectedCompany } = useCompanyBranch();
    const dispatch = useDispatch();
    const menuRefs = useRef({});
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const iconColors = [
        'text-tertiary',
        'text-danger',
        'text-info',
        'text-success',
        'text-secondary'
    ]

    const menuIdWithActiveSubmenu = activeSubmenu && activeSubmenu.mainMenuId; 
    const activeSubmenuId = activeSubmenu && activeSubmenu.id;

    const addMenuRef = (key, element) => {
        menuRefs.current[key] = element;
    };

    const handleOpenSubmenu = (submenu, mainMenuId) => {
        setActiveSubmenu((prevState) => {
            if (prevState && prevState.mainMenuId === mainMenuId && prevState.id === submenu.id) {
                return null; // Fechar menu
            }

            return {
                mainMenuId,
                id: submenu.id,
                name: submenu.name,
                submenus: submenu.submenus,
            };
        });
    }

    const userPermissions = user ? JSON.parse(user.permissions) : [];

    const getMenuItemActive = (url, menuId = null) => {
        return checkIsActive(location, url) || (menuId && activeSubmenuId === menuId) ? "menu-item-active" : "";
    };

    const menuShowConditions = useCallback((menu) => {
        if (user.isAccountant == "y") {
            var permissionAccountant = userPermissions.find((permission) => permission.companyId == selectedCompany?.id)?.permissions;

            if (permissionAccountant) {
                return permissionAccountant.includes(menu.id) || (user.isAdmin === 'y' && menu.route?.id === 1);
            }

        } else {
            return userPermissions.includes(menu.id) || (user.isAdmin === 'y' && menu.route?.id === 1);
        }
    }, [selectedCompany]);

    const subMenuShowConditions = useCallback((menu) => {
        let canShow = false;

        var permissionAccountant = user.isAccountant == "y" ? userPermissions.find((permission) => permission.companyId == selectedCompany?.id).permissions : [];

        if(user.isAccountant == "n" && userPermissions.includes(menu.id)) {
            canShow = true;
        } else if (user.isAccountant == "y" && permissionAccountant.includes(menu.id)) {
            canShow = true;
        }

        if(user.isAdmin === 'y' && menu.route?.startsWith('/usuarios')) {
            canShow = true;
        }

        if(mainCompany && !companyCanHaveBranches(mainCompany) && menu.id === Menus.BRANCHES && CompanyService.isMainCompany(selectedCompany)) {
            canShow = false;
        }
        
        return canShow;
    }, [selectedCompany]);

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

    
                {user.isSuper === 'y' && <>
                    {/*begin::1 Level*/}
                    <li
                        data-menu-toggle={layoutProps.menuDesktopToggle}
                        aria-haspopup="true"
                        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}
                    >
                        <NavLink className="menu-link menu-toggle" to="/google-material">
                            <i className="flaticon2-safe text-white mr-2"></i>
                            <span className="menu-text">SUPER</span>
                            <i className="menu-arrow d-flex"></i>
                        </NavLink>
                        <div
                            className={`menu-submenu menu-submenu-classic menu-submenu-left custom-menu`}
                            style={{ width: 800, position: 'fixed', left: '20%', top: 100 }}
                        >
                            <div className="row menu-subnav">
                                <div className="col-lg-7 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                                    <div className="row">
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/empresas')}`}>
                                                <NavLink to="/empresas" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <i className="flaticon2-group text-tertiary"></i>
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Empresas</span>
                                                        <span className="font-size-sm text-muted">Reports & statistics</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/planos')}`}>
                                                <NavLink to="/planos" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <i className="flaticon2-list text-danger"></i>
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Planos</span>
                                                        <span className="font-size-sm text-muted">Informações sobre Planos</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/perfis-acesso')}`}>
                                                <NavLink to="/perfis-acesso" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <i className="flaticon2-user text-info"></i>
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Perfis de Acesso</span>
                                                        <span className="font-size-sm text-muted">Informações sobre Perfis de Acesso</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/faturas/super')}`}>
                                                <NavLink to="/faturas/super" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <i className="flaticon2-sheet text-success"></i>
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Faturas</span>
                                                        <span className="font-size-sm text-muted">Informações sobre Faturas</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/config/super')}`}>
                                                <NavLink to="/config/super" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <i className="flaticon2-gear text-secondary"></i>
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Configurações</span>
                                                        <span className="font-size-sm text-muted">Informações sobre Configurações</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-lg-6 px-lg-6 col-lg-5">
                                    <img className="img-fluid rounded"
                                        src={toAbsoluteUrl("/media/bg/super-background.jpeg")}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                </>}

                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/painel')}`}>
                    <NavLink className="menu-link" to="/painel">
                        <i className="flaticon2-pie-chart text-white mr-2"></i>
                        <span className="menu-text">Dashboard</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow d-flex" />)}
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {menus.length > 0 && menus.map((menu, menuIndex) => (menuShowConditions(menu)) && (
                    <li
                        data-menu-toggle={menu.submenus?.length && layoutProps.menuDesktopToggle}
                        aria-haspopup={menu.submenus?.length && 'true'}
                        key={menu.id}
                        ref={(element) => addMenuRef(menu.id, element)}
                        className={`menu-item ${menu.submenus?.length && 'menu-item-submenu'} menu-item-rel ${getMenuItemActive(menu.route ?? '/toggle')}`}
                    >
                        <NavLink className={`menu-link ${menu.submenus?.length && 'menu-toggle'}`} to={menu.route ?? '/toggle'}>
                            <i className={`${menu.icon} text-white mr-2`}></i>
                            <span className="menu-text">{menu.name}</span>
                            {menu.submenus?.length && <i className="menu-arrow d-flex"></i>}
                        </NavLink>
                        {menu.submenus?.length && (
                            <div
                                className={`menu-submenu menu-submenu-classic menu-submenu-left custom-menu`}
                                style={{ width: 900, position: 'fixed', left: '20%', top: 100 }}
                            >
                                <div className="row menu-subnav">
                                    <div className={clsx(
                                        'mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6 custom-menu-main-menu col-lg-7',
                                    )}>
                                        <div className="row">
                                        {menu.submenus.map((submenu1, index) => (subMenuShowConditions(submenu1)) && (
                                            <div key={submenu1.id} className="col-lg-6 my-2">
                                                <div className={`menu-item p-0 m-0 ${getMenuItemActive(submenu1.route ?? '/toggle/sub', submenu1.id)}`}>
                                                    <NavLink
                                                        className={`menu-link ${!!submenu1.submenus?.length && 'menu-toggle'}`}
                                                        to={submenu1.route ?? '/toggle/sub'}
                                                        onClick={!!submenu1.submenus?.length && !isMobile ? () => handleOpenSubmenu(submenu1, menu.id) : undefined}
                                                    >
                                                        <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                            <i className={`${submenu1.icon} ${iconColors[index % iconColors.length]}`}></i>
                                                        </span>
                                                        <span className="d-flex flex-column text-body">
                                                            <span className="font-weight-bolder menu-text">{submenu1.name}</span>
                                                            <span className="font-size-sm text-muted">Informações sobre {submenu1.name}</span>
                                                        </span>
                                                        {!!submenu1.submenus?.length && <i className="menu-arrow" style={{position: 'absolute', right: 4, top: 12}}></i>}
                                                    </NavLink>
                                                    {!!submenu1.submenus?.length && (
                                                        <div className={`menu-submenu menu-submenu-classic menu-submenu-right ${!isMobile ? 'd-none' : ''}`}>
                                                            <ul className="menu-subnav">
                                                                {submenu1.submenus.map(submenu2 => (user.isAccountant == "y" ? JSON.parse(userPermissions.find((permission) => permission.companyId == selectedCompany?.id).permissions).includes(submenu2.id) : userPermissions.includes(submenu2.id)) && (
                                                                    <li key={submenu2.id} className={`menu-item ${getMenuItemActive(submenu2.route ?? '/toggle/sub')}`}>
                                                                        <NavLink className="menu-link" to={submenu2.route ?? '/toggle/sub'}>
                                                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                                                            <span className="menu-text">{submenu2.name}</span>
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    <div
                                        className={clsx(
                                            'col-lg-5 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6 custom-menu-submenu',
                                            menuIdWithActiveSubmenu === menu.id ? '' : 'd-none',
                                        )}
                                        style={{ backgroundColor: '#f9f9f9' }}
                                    >
                                        {activeSubmenu && (
                                            <div className="row">
                                                <div className="col-lg-12 my-2">
                                                    <h3>{activeSubmenu.name}</h3>
                                                </div>

                                                {!!activeSubmenu.submenus?.length && (
                                                    <>
                                                        {activeSubmenu.submenus.map(submenu2 => (user.isAccountant == "y" ? JSON.parse(userPermissions.find((permission) => permission.companyId == selectedCompany?.id).permissions).includes(submenu2.id) : userPermissions.includes(submenu2.id)) && (
                                                            <div key={submenu2.id} className="col-lg-12 my-2">
                                                                <div className={`menu-item p-0 m-0 ${getMenuItemActive(submenu2.route ?? '/toggle/sub')}`}>
                                                                    <NavLink
                                                                        className={`menu-link menu-link-no-bg`}
                                                                        to={submenu2.route ?? '/toggle/sub'}
                                                                    >
                                                                        <span className="d-flex flex-column text-body">
                                                                            <span className="font-weight-bolder menu-text">{submenu2.name}</span>
                                                                        </span>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className={clsx('py-lg-6 px-lg-6 col-lg-5',
                                        menuIdWithActiveSubmenu === menu.id ? 'd-none' : ''
                                    )}>
                                        <img className="img-fluid rounded"
                                            src={toAbsoluteUrl("/media/bg/submenu-background.png")}
                                            alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                ))}

                {
                    user.isAccountant == "y"
                    ?
                    <li
                        data-menu-toggle={'hover'}
                        aria-haspopup={'true'}
                        // key={menu.id}
                        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/toggle')}`}
                    >
                        <NavLink className={`menu-link menu-toggle`} to={'/toggle'}>
                            <span className="menu-text">Contábil</span>
                        </NavLink>
                        <div
                            className={`menu-submenu menu-submenu-classic menu-submenu-left custom-menu`}
                            style={{ width: 800, position: 'fixed', left: '20%', top: 100 }}
                        >
                            <div className="row menu-subnav">
                                <div className="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                                    <div className="row">
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/contabil')}`}>
                                                <NavLink to="/contabil" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <Dashboard />
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Relatório Contábil</span>
                                                        <span className="font-size-sm text-muted">Reports & statistics</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/indicacoes')}`}>
                                                <NavLink to="/indicacoes" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <Dashboard />
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Indicações</span>
                                                        <span className="font-size-sm text-muted">Reports & statistics</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 my-2">
                                            <div className={`menu-item p-0 m-0 ${getMenuItemActive('/contador/empresas')}`}>
                                                <NavLink to="/contador/empresas" className="menu-link">
                                                    <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                        <Dashboard />
                                                    </span>
                                                    <span className="d-flex flex-column text-body">
                                                        <span className="font-weight-bolder menu-text">Empresas</span>
                                                        <span className="font-size-sm text-muted">Informações sobre Empresas</span>
                                                    </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    : <></>
                }

                {
                    <li 
                        data-menu-toggle={layoutProps.menuDesktopToggle}
                        aria-haspopup="true"
                        className={`menu-item menu-item-submenu menu-subnav menu-item-rel`}
                    >
                        <NavLink onClick={() => setNotificationsOpen(!notificationsOpen)} className="menu-link menu-toggle" to="submenu1.route ?? '/toggle/sub">
                            <Badge className="text-white" badgeContent={countNotifications} color="primary">
                                <i className="mb-0 flaticon2-bell-4 text-white" style={{ fontSize: "20px" }}></i>
                            </Badge>
                            <i className="menu-arrow"></i>
                        </NavLink>
                    </li>
                }
            </ul>
            {/*end::Header Nav*/}
    </div>;
}
