import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import {List} from '../../../_metronic/layout/components/List/List';
import { orderByIdDesc } from '../../utils/orderTable';
import api from "../../services/Api";
import { Search } from '../../components/Search';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'name', value: "Nome" },
];

export function ListCategoryProduct() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [data, setData] = useState<{category: any[]}>({ category: [] });
    const [countTotalCategories, setCountTotalCategories] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: any[], count: number}>('categoryproduct', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setData({ category: rows });
        setCountTotalCategories(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [data]);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = data.category;

        aux.forEach(({ id, nameCategory, }) => {
            const data: BodyDataBaseProps[] = [
                { for: 'id', value: String(id), id: true },
                { for: 'name', value: nameCategory },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [data]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = { searchQuery };

        reloadData();
    }, [data, searchQuery]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            await api.delete(`categoryproduct/${id}`);
        } catch (error) {
            console.log(error);
        }
    }, []);
    
    return (
        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de categorias de produtos</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2 text-dark"
                            onClick={() => handleClickAdd()}
                        >
                            <i className="fas fa-plus text-dark"></i>
                            Adicionar
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalCategories}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                />
            </div>
        </MainPageContentWrapper>
    );
}