import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ValidationBadge } from "../../components/ValidationBadge";
import api from "../../services/Api";
import { Indications, status } from "../../types/Indications";
import { AuthPageContentWrapper } from "../../components/AuthPageContentWrapper";

export function DeclineIndication() {
    const { indicationId } = useParams<{ indicationId: string }>();
    const [indicationExpired, setIndicationExpired] = useState(false);
    const [resultLoaded, setResultLoaded] = useState(false);

    useEffect(() => {
        async function declineIndication() {
            if (!indicationId) {
                return;
            }

            try {
                const indicationDeclineResponse = await api.patch<Indications>(`/indications/status`, { id: indicationId, status: status.CANCELED });
            } catch (error) {
                console.log('error', error);
                setIndicationExpired(true);
            } finally {
                setResultLoaded(true);
            }
        }

        declineIndication();
    }, [indicationId])

    return (
        <AuthPageContentWrapper>
            <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
                <div className="login-form login-signin" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        {resultLoaded && (
                            <>
                                {!indicationExpired && (
                                    <>
                                        <h3 className="font-size-h1">
                                            <ValidationBadge label="" variant="success" />
                                            Convite recusado corretamente! Você já pode fechar esta aba.
                                        </h3>
                                    </>
                                )}

                                {indicationExpired && (
                                    <>
                                        <h3 className="font-size-h1">
                                            <ValidationBadge label="" variant="danger" />
                                            Convite não é válido!
                                        </h3>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AuthPageContentWrapper>
    );
}