import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal
} from "react-bootstrap";

import '../../style.css';

import api from '../../services/Api';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';
import ModalError from '../../components/ModalError';
import LogService from '../../services/LogService';
import { SubCategory } from '../../types/Dre';
import SellerService from '../../services/SellerService';
import { frequency as frequencyEnum, RecurringExpenses } from '../../types/RecurringExpenses';
import { frequencyToMonths } from '../../utils/frequencyToMonths';
import { extractDateStringFromTimestamp, getLastDayOfMonth } from '../../utils/dateTimeHelper';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import CustomerService from '../../services/CustomerService';
import BankAccountService from '../../services/BankAccountService';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import useAddAccountBank from '../../hooks/addAccountBank';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';



type BankAccount = {
    id: number;
    name: string;
    nameBank: string;
};

type Beneficiary = {
    id: number;
    name: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        },
    },
}));


export function NewRecurringExpenses() {
    const [activeTab, setActiveTab] = useState("detalhes-despesa");
    const [frequency, setFrequency] = useState("");
    const [name, setName] = useState("");
    const [payment, setPayment] = useState("");
    const [nextPaymentDate, setNextPaymentDate] = useState(new Date().toDateString());
    const [docNumber, setDocNumber] = useState("");
    const [comments, setComments] = useState("");
    const [amount, setAmount] = useState("0");
    const [isPaid, setIsPaid] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [occurrence] = useState("unica");
    const [expireDay, setExpireDay] = useState("");
    const [daysBeforeExpire, setDaysBeforeExpire] = useState("");
    const [accountBank, setAccountBank] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [beneficiaryType, setBeneficiaryType] = useState("supplier");
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)
    const [categoryName, setCategoryName] = useState("")

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    // Inputs Verify
    const [expensesError, setExpensesError] = useState(false);
    const [accountBankError, setAccountBankError] = useState(false);
    const [payMethodsError, setPayMethodsError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [accountPlanError, setAccountPlanError] = useState(false);
    const [expireDayIsInvalid, setExpireDayIsInvalid] = useState(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const detailsRequiredFields = [name, accountBank, payment, dreSubCategoryId, amount];
    const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

    const recorrenciaRequiredFields = [expireDay];
    const isRecorrenciaRequiredFieldsFilled = recorrenciaRequiredFields.every(recorrenciaRequiredField => recorrenciaRequiredField);

    const { idToClone } = useParams<{idToClone: string}>();
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, watch, handleSubmit } = useForm();

    subHeader.setTitle("Adicionar Despesa Recorrente");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        setAccountBank(String(accountBank.id));
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setBeneficiaryId(String(createdCustomer.id));
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    useEffect(() => {
        async function getDefaultAccountBank() {
            const { standardExpenseAccount } = await BankAccountService.getDefaultBankAccounts();
            setAccountBank(String(standardExpenseAccount?.id ?? ''));
        }

        getDefaultAccountBank();
    }, []);

    useEffect(() => {
        if(!idToClone) return;

        async function getExpenseToCloneData() {
            const { data: expenseToClone } = await api.get<RecurringExpenses>(`recurringExpenses/${idToClone}`);

            if(!expenseToClone) return;

            setName(expenseToClone.name);
            setAmount(formatCurrency(expenseToClone.amount));
            setPayment(expenseToClone.payment);
            setExpireDay(expenseToClone.expireDay);
            setNextPaymentDate(expenseToClone.nextPaymentDate ? extractDateStringFromTimestamp(expenseToClone.nextPaymentDate) : '');
            setComments(expenseToClone.comments);
            setBeneficiaryType(expenseToClone.beneficiaryType);
            setBeneficiaryId(
                expenseToClone.beneficiaryType === 'supplier'
                    ? String(expenseToClone.supplier ?? '')
                    : String(expenseToClone.seller ?? '')
            );
            setDocNumber(expenseToClone.docNumber);
            setCenterCost(expenseToClone.centerCost ? String(expenseToClone.centerCost) : '');
            setAccountBank(expenseToClone.bankAccount);
            setCategoryName(expenseToClone.categoryName);
            setDreSubCategoryId(expenseToClone.dreSubCategoryId);
            setDaysBeforeExpire(expenseToClone.daysBeforeExpire);
            setFrequency(expenseToClone.frequency);
        }

        getExpenseToCloneData();
    }, [idToClone]);

    const onSubmit = async (dataForm: any) => {
        try {
            setIsSubmit(true);

            if (!inputsVerify(dataForm)) return;

            const bank = await api.get(`/accountBank/${parseInt(accountBank)}`);
            const today = new Date().toISOString().split('T')[0];

            const generateDateObj = new Date(`${extractDateStringFromTimestamp(nextPaymentDate)} 00:00:00`);
            generateDateObj.setDate(generateDateObj.getDate() - Number(daysBeforeExpire));
            const nextDate = extractDateStringFromTimestamp(generateDateObj.toISOString());

            const data = {
                name: name,
                amount: formatToFloat(amount),
                remaining: formatToFloat(amount),
                status: isPaid ? "paid" : "pending",
                bankAccount: String(accountBank).length > 0 ? parseInt(accountBank) : null,
                nameBank: String(accountBank).length > 0 ? bank.data.nameBank : null,
                centerCost: String(centerCost).length > 0 ? parseInt(centerCost) : null,
                docNumber: String(docNumber).length > 0 ? docNumber : null,
                comments: String(comments).length > 0 ? comments : null,
                nextPaymentDate: nextPaymentDate ? new Date(nextPaymentDate) : null,
                nextGenerateDate: nextDate,
                beneficiaryType,
                payment: String(payment).length > 0 ? payment : null,
                supplier: beneficiaryType === 'supplier' ? (beneficiaryId || null) : null,
                seller: beneficiaryType === 'seller' ? (beneficiaryId || null) : null,
                occurrence: "unica",
                frequency: frequency || frequencyEnum.MONTHLY,
                daysBeforeExpire: daysBeforeExpire || 5,
                expireDay: expireDay,
                categoryName: categoryName,
                dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
                payedDate: isPaid ? today : null,
                totalPaid: isPaid ? formatToFloat(amount) : null,
            }

            const unicResult = await api.post("recurringExpenses", data);

            LogService.logRegister({
                itemId: unicResult.data.id,
                module: 'Despesas Recorrentes',
                itemName: unicResult.data.name
            });


            setIsSubmit(false);

            history.push("/despesas-recorrentes");

        } catch (error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    function inputsVerify(dataForm: any) {
        
        if (!name) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Nome da despesa é obrigatório!");
            setShowModalError(true);
            setExpensesError(true);
            return false;
        }

        if (!accountBank) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Selecione uma conta bancária!");
            setShowModalError(true);
            return false;
        }

        // if(!dataForm.categoryName) {
        //     setIsSubmit(false);
        //     setMsgError("Campo Plano de Contas é obrigatório!");
        //     setShowModalError(true);
        //     return false;
        // }

        if (!payment) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            setPayMethodsError(true);
            return false;
        }

        if (!dreSubCategoryId) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas é obrigatório!");
            setShowModalError(true);
            setAccountPlanError(true);
            return false;
        }

        if (!amount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }
        if (Number(amount) <= 0) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }

        if (!expireDay) {
            setActiveTab("recorrencia");
            setIsSubmit(false);
            setMsgError('Informe o dia do vencimento!');
            setShowModalError(true);
            setExpireDayIsInvalid(true);
            return false;
        }

        return true;
    }

    function validateNextPaymentDate(expire: string, paymentDate: string) {
        if (!paymentDate || !expire) return;

        const paymentDateObj = new Date(`${paymentDate} 00:00:00`);
        if (!(paymentDateObj instanceof Date)) {
            setNextPaymentDate('');
            return;
        }

        const expireDayNumber = Number(expire);
        const monthLastDay = getLastDayOfMonth(paymentDateObj.getFullYear(), paymentDateObj.getMonth() + 1);
        if (monthLastDay <= expireDayNumber) {
            paymentDateObj.setDate(monthLastDay);
        } else {
            paymentDateObj.setDate(expireDayNumber);
        }

        setNextPaymentDate(extractDateStringFromTimestamp(paymentDateObj.toISOString()));
    }

    function handleChangeNextPaymentDate(event: React.ChangeEvent<HTMLInputElement>) {
        setNextPaymentDate(event.target.value);

        validateNextPaymentDate(expireDay, event.target.value);
    }

    function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>) {
        setFrequency(event.target.value);
    }

    function handleChangeExpireDay(event: React.ChangeEvent<HTMLInputElement>) {
        const expireDayNumber = Number(event.target.value);
        let expireDayValue = event.target.value;

        setExpireDayIsInvalid(expireDayValue ? false : true);

        if (expireDayNumber <= 0) {
            expireDayValue = '';
        } else if (expireDayNumber > 31) {
            expireDayValue = '31';
        }

        setExpireDay(expireDayValue);
        changeTabIcon('flaticon-refresh');
        validateNextPaymentDate(expireDayValue, nextPaymentDate);
    }


    function convertToFloat(value: any) {
        var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
        if (valueConverted.includes(".") && valueConverted.includes(",")) {
            valueConverted = valueConverted.replace(".", "").replace(",", ".");
        } else {
            valueConverted = valueConverted.replace(",", ".");
        }

        return parseFloat(valueConverted);
    }


    function handleChangeBeneficiaryType(value: string) {
        setBeneficiaryType(value);
        setBeneficiaryId('');
    }

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-doc") {
            if (isDetailsRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#detalhes-despesa .flaticon2-delete');
                
                if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#detalhes-despesa .flaticon2-check-mark');

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else if (previousIcon === "flaticon-refresh") {
            if (isRecorrenciaRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#recorrencia .flaticon2-delete');
                
                if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                } else {
                    prev_icon?.classList.replace("flaticon-refresh", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#recorrencia .flaticon2-check-mark');

                if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                } else {
                    prev_icon?.classList.replace("flaticon-refresh", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('detalhes-despesa')?.classList.remove('current');
        document.getElementById('detalhes-conta')?.classList.remove('current');
        document.getElementById('recorrencia')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
            all_icons.forEach(function(item){
                item.style.color = '#666';
            });
        }

        if (!detailsRequiredFields[0]) {
            setExpensesError(true);
        }

        if (!detailsRequiredFields[1]) {
            setAccountBankError(true);
        }

        if (!detailsRequiredFields[2]) {
            setPayMethodsError(true);
        }

        if (!detailsRequiredFields[3]) {
            setAccountPlanError(true);
        }

        if (!detailsRequiredFields[4]) {
            setValueError(true);
        }

        if (previousIcon === 'flaticon-refresh') {
            if (!recorrenciaRequiredFields[0]) {
                setExpireDayIsInvalid(true);
            }
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
            sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'detalhes-despesa') {
            setNextButton('first');
        } else if (tab == 'recorrencia') {
            setNextButton('last');
        } else {
            setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['detalhes-despesa', 'detalhes-conta', 'recorrencia'];
        let arr2 = ['flaticon-doc', 'flaticon-file-2', 'flaticon-refresh'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
            if (activeTab == arr[0]) {
                setNextButton('first');
            } else {
                index = index-1;
                changeTab(arr[index], arr2[index], arr2[index+1]);
            }
        } else {
            if (activeTab == arr[count-1]) {
                setNextButton('last');
            } else {                
                index = index+1;
                changeTab(arr[index], arr2[index], arr2[index-1]);
            }
        }
    }

    return (

        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <form
                className={`${classes.container} steppers`}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="detalhes-despesa" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-despesa', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Despesa</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a despesa da despesa recorrente</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="detalhes-conta" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-conta', 'flaticon-file-2', previousIcon)}  style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                <i className="flaticon-file-2 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Conta</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a conta da despesa recorrente</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="recorrencia" data-kt-stepper-element='nav' onClick={() => changeTab('recorrencia', 'flaticon-refresh', previousIcon)}  style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                <i className="flaticon-refresh icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Recorrência</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Recorrência sobre a despesa recorrente</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/despesas-recorrentes">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="mb-3 mt-8 mt-lg-22 mt-md-10">
                            {nextButton === 'first' ? (
                                <Link to="/despesas-recorrentes" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Link>
                            ) : (
                                <Button
                                type="button"
                                variant="outline-primary"
                                onClick={() => backNextOption('back')}
                                className="mb-2 mr-2 btn-lg"
                                >
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Button>
                            )}
                            <Button
                                type="button"
                                variant={nextButton === 'last' ? 'secondary' : 'primary'}
                                onClick={() => backNextOption('next')}
                                className="mb-2 btn-lg"
                                disabled={nextButton === 'last'}
                            >
                                <span>
                                    Avançar
                                </span>&nbsp;
                                <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                            </Button>
                            <hr />
                        </div>

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs" style={{ display: "none" }}>
                            <Tab eventKey="detalhes-despesa" title="Detalhes da Despesa">
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            label="Nome da despesa*"
                                            margin="normal"
                                            size="small"
                                            error={expensesError}
                                            className={classes.error}
                                            value={name}
                                            onChange={(evt) => {
                                                setName(evt.target.value)
                                                setExpensesError(evt.target.value ? false : true)
                                                changeTabIcon('flaticon-doc');
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Conta bancária *"
                                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                            value={accountBank}
                                            hasError={accountBankError}
                                            onSelect={(option) => {setAccountBank(String(option?.id ?? '')); setAccountBankError(option?.id ? false : true); changeTabIcon('flaticon-doc');}}
                                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!accountBank) return null;
                                                return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                                            }}
                                            onChangeTextField={(e) => setAccountBankError(e.target.value ? false : true)}
                                            renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            select
                                            label="Forma de Pagamento*"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu && classes.error
                                                },
                                            }}
                                            error={payMethodsError}
                                            margin="normal"
                                            size="small"
                                            value={payment}
                                            onChange={(evt) => {
                                                setPayment(evt.target.value)
                                                setPayMethodsError(evt.target.value ? false : true)
                                                changeTabIcon('flaticon-doc')
                                            }}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            {
                                                paymentOptions.map((payment, index) => (
                                                    <MenuItem key={index + 1} value={payment.value}>
                                                        {payment.value}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Plano de Contas *"
                                            getOptionLabel={(option: SubCategory) => option.name}
                                            value={dreSubCategoryId}
                                            hasError={accountPlanError}
                                            onSelect={(option) => {
                                                setDreSubCategoryId(option?.id ?? 0)
                                                setCategoryName(option?.name ?? '')
                                                setAccountPlanError(option?.name ? false : true);
                                                changeTabIcon('flaticon-doc')
                                            }}
                                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!dreSubCategoryId) return null;
                                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                            }}
                                            onChangeTextField={(e) => setAccountPlanError(e.target.value ? false : true)}
                                        />
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            value={beneficiaryType}
                                            select
                                            label="Tipo Beneficiário"
                                            size="small"
                                            className="ml-0"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                                        >
                                            <MenuItem key="0" value="supplier">
                                                Fornecedor
                                            </MenuItem>

                                            <MenuItem key="1" value="seller">
                                                Vendedor
                                            </MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <ApiResourceSelect
                                            style={{ width: "100%" }}
                                            label="Beneficiário"
                                            getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                                            value={beneficiaryId}
                                            onSelect={(option) => setBeneficiaryId(option ? String(option.id) : '')}
                                            apiSearchHandler={beneficiaryType === 'supplier'
                                                ? ((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true }))
                                                : ((typedText) => SellerService.getSellersFiltered({ name: typedText }))}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!beneficiaryId) return null;

                                                const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(beneficiaryId));
                                                if(loadedBeneficiary) return loadedBeneficiary;

                                                return beneficiaryType === 'supplier' ? CustomerService.getCustomerById(beneficiaryId) : SellerService.getSellerById(beneficiaryId);
                                            }}
                                            renderAddButton={(
                                                beneficiaryType === 'supplier'
                                                    ? (typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText)} />
                                                    : undefined
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor"
                                            startAdornment="R$"
                                            error={valueError}
                                            className={`${classes.error}`}
                                            value={amount}
                                            onChange={(evt) => {
                                                setAmount(evt.target.value)
                                                setValueError(evt.target.value ? false : true)
                                            }}
                                        />
                                    </Grid>
                                </div>

                            </Tab>

                            <Tab eventKey="detalhes-conta" title="Detalhes da Conta">
                                <div className="row">
                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            label="Nº do documento"
                                            size="small"
                                            className="ml-0"
                                            margin="normal"
                                            value={docNumber}
                                            onChange={(evt) => setDocNumber(evt.target.value)}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Centro de Custos"
                                            getOptionLabel={(option: CenterCost) => option.name}
                                            value={centerCost}
                                            onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                            apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!centerCost) return null;
                                                return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                            }}
                                        />
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={5} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">Anexo</label>
                                        <input className="form-control" type="file" id="formFile" />
                                        <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Observações"
                                            multiline
                                            rows="2"
                                            size="small"
                                            margin="normal"
                                            value={comments}
                                            onChange={(evt) => setComments(evt.target.value)}
                                        />
                                    </Grid>
                                </div>

                            </Tab>
                            <Tab eventKey="recorrencia" title="Recorrência">
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Dia de Vencimento*"
                                            className={`${classes.error}`}
                                            thousandSeparator={''}
                                            decimalScale={0}
                                            value={expireDay}
                                            onChange={handleChangeExpireDay}
                                            error={expireDayIsInvalid}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Dias para gerar Fatura"
                                            thousandSeparator={''}
                                            decimalScale={0}
                                            value={daysBeforeExpire}
                                            onChange={(e) => setDaysBeforeExpire(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            value={frequency}
                                            onChange={handleChangeFrequency}
                                            size="small"
                                            select
                                            label="Periodicidade"
                                            margin="normal"
                                            defaultValue={frequencyEnum.MONTHLY}
                                        >
                                            <MenuItem value={frequencyEnum.MONTHLY}>
                                                Mensal
                                            </MenuItem>
                                            <MenuItem value={frequencyEnum.BIMONTHLY}>
                                                Bimestral
                                            </MenuItem>
                                            <MenuItem value={frequencyEnum.TRIMONTHLY}>
                                                Trimestral
                                            </MenuItem>
                                            <MenuItem value={frequencyEnum.QUARTERLY}>
                                                Quadrimestral
                                            </MenuItem>
                                            <MenuItem value={frequencyEnum.SEMIANNUAL}>
                                                Semestral
                                            </MenuItem>
                                            <MenuItem value={frequencyEnum.ANNUAL}>
                                                Anual
                                            </MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        type='date'
                                        margin="normal"
                                        label="Próximo Pagamento"
                                        InputLabelProps={{ shrink: true }}
                                        className={`${classes.error}`}
                                        value={nextPaymentDate}
                                        onChange={handleChangeNextPaymentDate}
                                    />
                                    </Grid>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}
