import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core';

import {
    Button,
    Spinner,
} from "react-bootstrap";

import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import api from '../../services/Api';
import PermissionMenuSelector from '../../components/PermissionMenuSelector';
import { useSelector } from 'react-redux';
import useCompany from '../../hooks/company';
import { PermissionMenu } from '../../types/PermissionMenu';
import ModalSuccess from '../../components/ModalSuccess';
import PermissionMenuService from '../../services/PermissionMenuService';
import { useStyles } from '../../hooks/styles';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { Link } from 'react-router-dom';

export function NewUserProfile() {
    const classes = useStyles();
    const { user } = useSelector((state: any) => state.auth);
    const { companies } = useCompany({only: user.companyId});
    const [ company ] = companies;

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Perfil de Usuário");

    const { menus } = useMenu();
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(menus as PermissionMenu[]);

    const [name, setName] = useState("");
    const [nameInvalid, setNameInvalid] = useState(false);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if(!menus.length) return;
        if(!user) return;
        if(!company) return;

        const companyPermissions: Array<number> = JSON.parse(company.permissions);
        const filtered = PermissionMenuService.filterCompanyPermissionMenu(menus as PermissionMenu[], companyPermissions);
        setPermissionMenus(filtered);
    }, [menus, user, company]);

    function inputsVerify(): boolean {

        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    function handleChangeName(value: string) {
        setName(value);
        setNameInvalid(!value ? true : false);
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = PermissionMenuService.filterPermissionMenu(aux);
        const permissions = PermissionMenuService.mapPermissionMenu(menusFiltered);
        const extraPermissions = PermissionMenuService.mapMenuExtraPermissions(menusFiltered);

        const raw = {
            name,
            permissions: JSON.stringify(permissions),
            extraMenuPermissions: JSON.stringify(extraPermissions),
        };

        try {
            await api.post('userProfiles', raw);

            setMsgSuccess('Perfil criado com sucesso!');
            setShowModalSuccess(true);
        } catch (error) {
            setMsgError('Erro ao salvar perfil');
            setShowModalError(true);
        }

        setIsSubmit(false);
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/perfis-usuario"
            />
            <form
                className={`${classes.container} steppers`}
                onSubmit={(evt) => onSubmit(evt)}
            >
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a marca</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/perfis-usuario">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={(evt: any) => onSubmit(evt)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={6} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    className={classes.error}
                                    label="Nome do Perfil*"
                                    margin="normal"
                                    value={name}
                                    onChange={(e) => handleChangeName(e.target.value)}
                                    error={nameInvalid}
                                />
                            </Grid>
                        </Grid>

                        <div className="row">
                            <div className="col-12">
                                <PermissionMenuSelector
                                    permissionMenus={permissionMenus}
                                    setPermissionMenus={setPermissionMenus}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}