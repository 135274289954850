import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    Paper,
    Table,
    TableRow,
    MenuItem,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    TablePagination,
    FormControlLabel,
    Tooltip,
    Zoom,
} from '@material-ui/core';

import { Badge, Button, Modal } from 'react-bootstrap';
import { orderByIdDesc } from '../../utils/orderTable';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import api from "../../services/Api";

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import ModalError from '../../components/ModalError';
import { Search } from '../../components/Search';
import logService from '../../services/LogService';
import { BsVariant } from '../../types/BsVariant';
import useBackendLoad from '../../hooks/backendReload';
import CompanyService from '../../services/CompanyService';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { useSelector } from 'react-redux';
import StockService from '../../services/StockService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const styles = {
    headStyle: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
    },
    headStyleTop: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
        borderTop: 0,
    },
    headStyleMid: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
        borderTop: 0,
    },
};

type Filters = {
    searchQuery: string;
}

type Inputs = {
    name: string;
    description: string;
};

type StockLocations = {
    id: number;
    name: string;
    description: string;
};

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
    { reference: "description", value: "Descrição" },
];

export function ListStockLocations() {
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [stockLocations, setStockLocations] = useState<StockLocations[]>([]);
    const [countTotalStockLocations, setCountTotalStockLocations] = useState(0);
    const [idToDelete, setIdToDelete] = useState("");
    const [idToEdit, setIdToEdit] = useState("");

    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [btnSaveText, setBtnSaveText] = useState("Salvar");

    const [msgError, setMsgError] = useState('');
    const [showModalError, setShowModalError] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const { user } = useSelector((state: any) => state.auth);

    const {
        register,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: StockLocations[], count: number}>('stockLocation', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setStockLocations(rows);
        setCountTotalStockLocations(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [stockLocations]);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = stockLocations;

        aux.forEach((stockLocation) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(stockLocation.id), id: true },
                { for: "name", value: stockLocation.name },
                { for: "description", value: stockLocation.description },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [stockLocations]);

    async function saveStockLocation() {
        var raw = {
            name: newName,
            description: newDescription
        }

        try {
            if (newName && newDescription) {
                const createResponse = await api.post('/stockLocation', raw);

                logService.logRegister({
                    itemId: createResponse.data.id,
                    module: 'Locais de Estoque',
                    itemName: createResponse.data.name,
                });

                setIsModalOpen(false);
                reloadData();
                
                setNewName('');
                setNewDescription('');
            } else {
                alert('Preencha os campos antes de salvar!');
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleClickDeleteList = useCallback(async (id: string) => {
        const allCompanies = await CompanyService.getMainCompanyAndCompanyBranches(user?.companyId ?? 0);
        let isUsingStockLocation = false;
        allCompanies.forEach((company) => {
            if(company.pdvStockLocationId === Number(id)) {
                isUsingStockLocation = true;
            }
        });

        if(isUsingStockLocation) {
            setMsgError('Não é possível excluir o Local de estoque pois ele está sendo usado como local de estoque padrão pela empresa matriz ou por alguma filial');
            setShowModalError(true);
            return;
        }

        const result = await StockService.getTotalLocalStockAll(id);
        const totalStock = result.totalBalance;

        if(totalStock > 0) {
            setMsgError('Não é possível excluir o Local de estoque pois este possui produtos vinculados');
            setShowModalError(true);
            return;
        }
        
        setIsModalDeleteOpen(true);
        setIdToDelete(id);
    }, [user])

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const result = await StockService.getTotalLocalStockAll(id);
            const totalStock = result.totalBalance;
            if(totalStock > 0) {
                alert('Não é possível excluir o Local de estoque pois este possui produtos vinculados');
                setIsModalDeleteOpen(false);
                return;
            }
            const filtered = stockLocations.filter((stockLocation) => stockLocation.id !== Number(id));

            await api.delete(`stockLocation/${id}`);

            setIsModalDeleteOpen(false);
            setStockLocations([...filtered]);
            setIdToDelete("");
        } catch (error) {
            console.log(error);
        }
    }, [stockLocations]);

    async function editStockLocation(id: string) {
        var raw = {
            name: name,
            description: description
        }

        try {
            if (name && description) {
                const editResponse = await api.put(`stockLocation/${id}`, raw);

                logService.logEdit({
                    itemId: editResponse.data.id,
                    itemName: editResponse.data.name,
                    module: 'Locais de Estoque',
                    oldData: beforeSubmitData,
                    newData: editResponse.data,
                    fieldsMap: {
                        name: 'Nome',
                        description: 'Descrição',
                    },
                });

                setIsModalEditOpen(false);
                reloadData();
            } else {
                alert('Preencha os campos antes de salvar!');
            }

        } catch (error) {
            console.log(error);
        }
    }

    function setStockToEdit(id: string) {
        stockLocations.map((value, index) => {
            if (value.id == parseInt(id)) {
                setName(value.name);
                setDescription(value.description);
                setIdToEdit(id);

                setBeforeSubmitData(value);
            }
        });
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = { searchQuery };

        reloadData();
    }, [stockLocations, searchQuery]);

    const handleClickEdit = useCallback((id: string) => {
        setIsModalEditOpen(true);
        setStockToEdit(id)
    }, [setStockToEdit]);

    return (

        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de locais de estoque</h4>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <Modal
                centered
                aria-labelledby="contained-modal-warning"

                show={isModalDeleteOpen}
                onHide={() => setIsModalDeleteOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <strong>Tem certeza que deseja excluir este registro ?</strong>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => idToDelete ? handleClickDelete(idToDelete) : false}>Excluir</Button>
                    <Button variant="secondary" onClick={() => setIsModalDeleteOpen(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                centered
                aria-labelledby="contained-modal-warning"

                show={isModalEditOpen}
                onHide={() => setIsModalEditOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Editar Local de Estoque
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Nome'
                                margin='normal'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Descrição'
                                margin='normal'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" className="text-dark" onClick={() => editStockLocation(idToEdit)}>{btnSaveText}</Button>
                    <Button variant="secondary" onClick={() => setIsModalEditOpen(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                centered
                aria-labelledby="contained-modal-warning"

                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Novo local de estoque
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Nome'
                                margin='normal'
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Descrição'
                                margin='normal'
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="text-dark" variant="success" onClick={() => saveStockLocation()}>
                        <i className="fas fa-check text-dark"></i>
                        {btnSaveText}
                    </Button>
                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                        <i className="fas fa-times"></i>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2 text-dark"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <i className="fas fa-plus text-dark"></i>
                            Adicionar
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-1">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>


            <div className="mt-3">
                <ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
                    customButtons={
                        user.isAccountant == "n"
                        ?
                        [
                            {
                                class: 'btn-light-primary',
                                content: (<i className="flaticon2-edit mr-2"></i>),
                                variant: BsVariant.PRIMARY,
                                popup: "Editar",
                                onClick: handleClickEdit,
                            },
                            {
                                class: 'btn-light-danger',
                                content: (<i className="flaticon-delete mr-2"></i>),
                                variant: BsVariant.DANGER,
                                popup: "Deletar",
                                onClick: handleClickDeleteList,
                            },
                        ]
                        : []
                    }
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalStockLocations}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
            </div>
        </MainPageContentWrapper>
    );
}