import React, { useEffect, useState, useLayoutEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Dropdown, Modal, Row, Spinner } from 'react-bootstrap';
import api from "../../services/Api";
import {
    Situation,
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import '../../style.css';
import { Search } from '../../components/Search';
import { Card, Collapse, InputAdornment, makeStyles, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Autocomplete } from '@material-ui/lab';
import { paymentOptions } from '../../utils/paymentOptions';
import ModalSuccess from '../../components/ModalSuccess';
import { EmailToCustomer, ModalSendEmailMultiple } from '../../components/ModalSendEmailMultiple';
import { ModalSendWhatsappMultiple, WhatsappToCustomer } from '../../components/ModalSendWhatsappMultiple';
import { BillsToReceive } from '../../types/BillsToReceive';
import { sendBillEmailToCustomer } from '../../utils/sendEmailToCustomer';
import { getSituationText } from '../../utils/getSituationText';
import BillToReceiveService from '../../services/BillToReceiveService';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import CustomerService from '../../services/CustomerService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { SubCategory } from '../../types/Dre';
import DreCategoryService from '../../services/DreCategoryService';
import { banks } from '../../utils/banks';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import ModalError from '../../components/ModalError';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import axios from 'axios';
import { Area, AreaChart, Bar, BarChart, Legend, Line, LineChart, Tooltip as RechartTooltip, XAxis, YAxis } from 'recharts';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Id" },
    { reference: "doc", value: "Doc" },
    { reference: "name", value: "Nome" },
    { reference: "date", value: "Data de vencimento" },
    { reference: "value", value: "Valor" },
    { reference: "payment", value: "Forma de pagamento", notSortable: true },
    { reference: "status", value: "Situação", situation: true, notSortable: true },
];

type Filters = {
    searchQuery: string;
    situation: string;
    valueMin: number;
    valueMax: number;
    dreSubCategoryId: number;
    nameBank: string;
    centerCost: number;
    payment: string;
    issueDateMin: string;
    issueDateMax: string;
    dueDateMin: string;
    dueDateMax: string;
}

type BillsSituationChart = {
    status: string,
    quantity: number,
}

type BillsSituationTotalCharts = {
    status: string,
    value: number
}

const backgroundColors: { [index: string]: string } = {
    'canceled': 'bg-light-danger',
    'paid': 'bg-light-primary',
    'late': 'bg-light-danger',
    'pending': 'bg-light-success',
}

const textColors: { [index: string]: string } = {
    'canceled': '#F64E60',
    'paid': '#2E93B9',
    'late': '#F64E60',
    'pending': '#0CA321',
}

export function ListBillsToReceive() {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();
    
    const [totalLate, setTotalLate] = useState(0);
    const [direction, setDirection] = useState(false);
    const [totalToReceive, setTotalToReceive] = useState(0);
    const [totalDayToReceive, setTotalDayToReceive] = useState(0);
    const [totalMonthToReceive, setTotalMonthToReceive] = useState(0);
    const [countTotalBillsToReceive, setCountTotalBillsToReceive] = useState(0);
    const [countRemittance, setCountRemittance] = useState(0);

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [situationData, setSituationData] = useState<Situation[]>([]);
    const [billsToReceive, setBillsToReceive] = useState<BillsToReceive[]>([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [nameBank, setNameBank] = useState("");
    const [centerCost, setCenterCost] = useState(0);
    const [payment, setPayment] = useState("");
    const [issueDateMin, setIssueDateMin] = useState("");
    const [issueDateMax, setIssueDateMax] = useState("");
    const [dueDateMin, setDueDateMin] = useState("");
    const [dueDateMax, setDueDateMax] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [selectAllBills, setSelectAllBills] = useState(false);
    const [selectedBillsId, setSelectedBillsId] = useState<string[]>([]);
    const [showModalSendEmails, setShowModalSendEmails] = useState(false);
    const [message, setMessage] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [infoEmails, setInfoEmails] = useState<EmailToCustomer[]>([]);
    const [showModalSendWhatsapp, setShowModalSendWhatsapp] = useState(false);
    const [infoWhatsapp, setInfoWhatsapp] = useState<WhatsappToCustomer[]>([]);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [billsSituations, setBillsSituations] = useState<BillsSituationChart[]>();
    const [billsSituationsTotal, setBillsSituationsTotal] = useState<BillsSituationTotalCharts[]>();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [billsToReceive]);

    useEffect(() => {
        getCountRemittance();

        // loadData();
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setSelectedBillsId([]);
        setSelectAllBills(false);

        const result = await api.get<{rows: BillsToReceive[], count: number}>("billsToReceive", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });
        const {rows, count} = result.data;

        const foundBills = await BillToReceiveService.resolveBillsDueDates(rows);

        const { today, late, thisMonth, totalAmount } = foundBills.reduce((acc, { amount, dueDate }) => {
            let late = acc.late;
            let today = acc.today;
            let thisMonth = acc.thisMonth;

            const dateToCompare = new Date();
            const yearToCompare = dateToCompare.getFullYear();
            const monthToCompare = dateToCompare.getMonth();
            
            const [year, month, day] = dueDate.split("-");
            const date = new Date(`${year}-${month}-${day}`);

            if (yearToCompare === date.getFullYear()) {
                if (monthToCompare === date.getMonth()) {
                    thisMonth = acc.thisMonth + amount;
                }
            }

            const totalAmount: number = acc.totalAmount + amount;

            return {
                late,
                today,
                thisMonth,
                totalAmount,
            };
        }, { totalAmount: 0 , late: 0, today: 0, thisMonth: 0 });

        for (const bill of foundBills) {
            bill.customerName = CustomerService.getCustomerName(bill.customerEntity);
        }

        setCountTotalBillsToReceive(count);
        setTotalLate(late);
        setTotalDayToReceive(today);
        setTotalToReceive(totalAmount);
        setTotalMonthToReceive(thisMonth);

        const situations: Situation[] = foundBills.map(
            (situation: Omit<BillsToReceive, "issuanceDate">) => situation
        );

        setBillsToReceive(foundBills);
        setSituationData(situations);
    }, []);

    useEffect(() => {
        if(billsToReceive.length) {
            const situations: Situation[] = billsToReceive.map(
                (situation: Omit<BillsToReceive, "issuanceDate">) => ({ ...situation, beneficiaryName: situation.customerName })
            );
            setSituationData(situations);
        }
    }, [billsToReceive]);

    useEffect(() => {
        billsToReceivePromise();
    }, [billsToReceive]);

    async function billsToReceivePromise() {
        const list: BodyDataBaseProps[][] = [];
        
        billsToReceive.forEach(async({ id, customerName, name, amount, remaining, dueDate, payment, status: statusResponse, totalPaid, recordType, partialPayments }) => {
            let status = getSituationText(statusResponse);
            const today = extractDateStringFromTimestamp();

            if(statusResponse === 'pending') {
                if(dueDate === today) {
                    status = 'Vencendo'
                } else {
                    const dueDateObj = new Date(dueDate);
                    if(dueDateObj.getTime() <= (new Date()).getTime()) {
                        status = 'Vencido';
                    }
                }
            }

            const formatedAmount = totalPaid ? formatCurrency(totalPaid) : formatCurrency(remaining);
            let date = "";

            if (!!dueDate) {
                const [year, month, day] = dueDate.split("-");                
                date = `${day}/${month}/${year}`;
            }

            const paymentEdited = recordType === 'billet' && payment === 'Boleto' ? 'Boleto' : payment;

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(id), id: true },
                { for: "doc", value: name },
                { for: "name", value:  customerName ?? "-"},
                { for: "date", value: date },
                { for: "value", value: status == "paid" && partialPayments ? formatCurrency(amount) : formatedAmount },
                { for: "payment", value: paymentEdited ?? "-" },
                { for: "status", value: status },
                { for: "type", value: recordType },
            ];

            list.push(data);
        });

        setBodyData(list);
    }

    async function getCountRemittance() {
        const {data} = await api.post(`/billsToReceive/remittance`);
        setCountRemittance(data.length);
    }

    const handleClickDelete = useCallback(async (id: string, reason: string) => {
        try {
            var bill = await api.get(`billsToReceive/${id}`);
            
            await api.put(`billsToReceive/${id}`, { status: "canceled", reasonCanceled: reason});
            if (bill.data.idBillTransfer) {
                await api.put(`billsToPay/${bill.data.idBillTransfer}`, { status: "canceled", reasonCanceled: reason});
            }

            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleClickReversal = useCallback(async (id: string) => {
        try {
            const aux = billsToReceive;
            const billIndex = aux.findIndex((bill) => bill.id === Number(id));

            if (billIndex < 0) {
                throw new Error();
            }

            const hasPermission = await BillToReceiveService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(aux[billIndex].payedDate || aux[billIndex].dueDate, selectedCompany);
            if (!hasPermission) {
                setMsgError('O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema.');
                setShowModalError(true);
                return;
            }

            aux[billIndex] = {
                ...aux[billIndex],
                status: 'pending',
                remaining: aux[billIndex].amount,
                accValue: 0,
                taxValue: 0,
                discountValue: 0,
                feeValue: 0,
                totalPaid: 0,
                payedDate: "",
                partialPayments: null
            };            

            await api.put(`billsToReceive/${id}`, aux[billIndex]);

            setBillsToReceive([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [billsToReceive, selectedCompany]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleChangeSituation = useCallback(async ({
        id,
        status,
        dueDate,
        payment,
        customer,
        remaining,
        bankAccount,
        accValue,
        taxValue,
        discountValue,
        feeValue,
        totalPaid,
        payedDate,
        partialPayments,
        writeOffValue
    }: Situation) => {
        try {
            const aux = billsToReceive;
            const billIndex = aux.findIndex((bill) => bill.id === Number(id));

            if (billIndex < 0) {
                throw new Error();
            }

            // const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);

            // if (!((aux[billIndex].recordType == 'billet' && bank.data.shipmentSequence && bank.data.billetSequence) || aux[billIndex].recordType != "billet"))
            // {
            //     alert('Informe a sequência da Remessa e boleto da conta bancária!')
            //     return;
            // }

            const partialsJson = partialPayments ? JSON.parse(partialPayments) : null;

            if (remaining == 0 && partialsJson == null) {
                aux[billIndex] = {
                    ...aux[billIndex],
                    status,
                    dueDate,
                    payment,
                    remaining,
                    bankAccount,
                    accValue: Number(accValue),
                    taxValue: Number(taxValue),
                    discountValue: Number(discountValue),
                    feeValue: Number(feeValue),
                    totalPaid: Number(totalPaid),
                    payedDate: payedDate
                };

            } else if (remaining == 0 && partialsJson) {
                var auxPartials = [
                    ...partialsJson,
                    {
                        payedDate,
                        writeOffValue,
                        feeValue: Number(feeValue),
                        discountValue: Number(discountValue),
                        taxValue: Number(taxValue),
                        accValue: Number(accValue),
                        totalPaid: Number(totalPaid),
                        payment,
                        bankAccount
                    }
                ];

                aux[billIndex] = {
                    ...aux[billIndex],
                    status: "paid",
                    partialPayments: JSON.stringify(auxPartials),
                    remaining
                };
                
            } else {
                var auxPartials = [];

                if (partialsJson) {
                    auxPartials = [
                        ...partialsJson,
                        {
                            payedDate,
                            writeOffValue,
                            feeValue: Number(feeValue),
                            discountValue: Number(discountValue),
                            taxValue: Number(taxValue),
                            accValue: Number(accValue),
                            totalPaid: Number(totalPaid),
                            payment,
                            bankAccount
                        }
                    ];

                } else {
                    auxPartials = [
                        {
                            payedDate,
                            writeOffValue,
                            feeValue: Number(feeValue),
                            discountValue: Number(discountValue),
                            taxValue: Number(taxValue),
                            accValue: Number(accValue),
                            totalPaid: Number(totalPaid),
                            payment,
                            bankAccount
                        }
                    ];
                }

                aux[billIndex] = {
                    ...aux[billIndex],
                    partialPayments: JSON.stringify(auxPartials),
                    remaining
                };
            }
            
            await api.put(`billsToReceive/${id}`, aux[billIndex]);

            setBillsToReceive([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [billsToReceive]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickTransfer = useCallback(() => {
        pushHistory(`${pathname}/transferencia/adicionar`);
    }, [pathname]);

    const handleClickGeneratedRemittances = useCallback(() => {
        pushHistory(`${pathname}/remessas-geradas`);
    }, [pathname]);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setDreSubCategoryId(0);
        setNameBank('');
        setPayment('');
        setCenterCost(0);
        setValueMin(0);
        setValueMax(0);
        setIssueDateMin("");
        setIssueDateMax("");
        setDueDateMin("");
        setDueDateMax("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, situation,
            valueMin, valueMax, issueDateMin, issueDateMax, dueDateMin, dueDateMax,
            dreSubCategoryId, nameBank, payment, centerCost,
        };

        reloadData();
    }, [
        billsToReceive, searchQuery, situation,
        valueMin, valueMax, issueDateMin, issueDateMax, dueDateMin, dueDateMax,
        nameBank, payment, centerCost, dreSubCategoryId
    ]);

    const handleChangeSelectAllBills = useCallback((checked: boolean) => {
        setSelectAllBills(checked);
        if(checked) {
            const billsWithCheckbox = billsToReceive.filter(billObj => showConditionSelectBill(String(billObj.id)));
            setSelectedBillsId([...billsWithCheckbox.map(billObj => String(billObj.id))])
        } else {
            setSelectedBillsId([]);
        }
    }, [billsToReceive]);

    const checkedConditionSelectAllBills = useCallback(() => {
        return selectAllBills;
    }, [selectAllBills]);

    const handleChangeSelectedBill = useCallback((checked: boolean, id: string) => {
        setSelectedBillsId((prevState) => {
            if(prevState.includes(id)) {
                return prevState.filter(billId => billId !== id);
            } else {
                return [...prevState, id];
            }
        });
    }, []);

    const checkedConditionSelectedBill = useCallback((id: string) => {
        return selectedBillsId.includes(id);
    }, [selectedBillsId]);

    const showConditionSelectBill = useCallback((id: string) => {
        const bill = billsToReceive.find((billObj) => billObj.id === Number(id));
        if(!bill) {
            return false;
        }

        if(bill.status === 'paid') {
            return false;
        }

        return true;
    }, [billsToReceive]);

    const handleOpenSendEmailsModal = async () => {
        if(!selectedBillsId.length) return;

        setShowModalSendEmails(true);

        setEmailSubject('Contas a receber');
        setMessage('Este é um email para envio do pdf');

        setInfoEmails(
            await Promise.all(selectedBillsId.map(async (billId) => {
                const bill = billsToReceive.find(billObj => billObj.id === Number(billId));

                if(!bill) {
                    return {
                        refId: '',
                        refName: '',
                        toName: '',
                        customerName: '',
                        customerId: '',
                        email: '',
                    };
                }

                const customer = (await api.get(`customer/${bill?.customer}`)).data;
                return {
                    refId: billId,
                    refName: 'Contas a receber',
                    toName: bill.customerName ?? '',
                    customerName: bill.customerName ?? '',
                    customerId: bill.customer,
                    email: customer?.email ?? '',
                };
            }))
        );
    };

    const handleClickSendEmails = async () => {
        const subject = emailSubject;
        const messageBody = message;
        const allInfoEmails = [...infoEmails];

        allInfoEmails.forEach(async (info) => {
            const emails = info.email.split(';').map(email => email.trim());
            const bill = billsToReceive.find(billObj => billObj.id === Number(info.refId));

            
            if(bill) {
                await sendBillEmailToCustomer(emails, {
                    bill,
                    customerId: info.customerId,
                    customerName: info.customerName,
                    contactName: info.toName,
                    message: messageBody,
                    refName: info.refName,
                    subject,
                });
            }
        });

        setEmailSubject('');
        setMessage('');
        setShowModalSendEmails(false);

        setShowModalSuccess(true);
        setMessageSuccess('As mensagens estão sendo processadas e serão enviadas em instantes!');
    };

    const handleOpenSendWhatsappModal = async () => {
        if(!selectedBillsId.length) return;

        setShowModalSendWhatsapp(true);

        setMessage('Esta é uma mensagem para envio do boleto');

        setInfoWhatsapp(
            await Promise.all(selectedBillsId.map(async (billId) => {
                const bill = billsToReceive.find(billObj => billObj.id === Number(billId));

                if(!bill) {
                    return {
                        refId: '',
                        refName: '',
                        customerName: '',
                        customerCell: '',
                        customerId: '',
                    };
                }

                const customer = (await api.get(`customer/${bill?.customer}`)).data;
                return {
                    refId: billId,
                    refName: 'receive',
                    customerName: bill.customerName ?? '',
                    customerCell: customer.cell ?? '',
                    customerId: bill.customer,
                };
            }))
        );
    };

    const handleClickSendWhatsapp = async () => {
        const messageBody = message;
        const allInfoWhatsapp = [...infoWhatsapp];

        allInfoWhatsapp.forEach(async (info) => {
            const number = info.customerCell;

            const bill = await api.get<BillsToReceive>(`/billsToReceive/${info.refId}`); 

            let file = undefined;

            if (bill.data.recordType === 'billet') {
                const billetFilename = await BillToReceiveService.getBilletDocumentFilename(bill.data);
                bill.data.billetDocument = billetFilename;

                file = {
                    urlFile: await BillToReceiveService.getBilletFileUrl(bill.data),
                    fileName: `Conta-a-receber-${bill.data.id}.pdf`,
                }
            }

            const rawWhatsapp = {
                idBill: bill.data.id,
                typeBill: 'receive',
                number: number,
                message: messageBody,
                file,
            }

            api.post(`/bill/whatsapp/send`, rawWhatsapp);
        });

        setMessage('');
        setShowModalSendWhatsapp(false);

        setShowModalSuccess(true);
        setMessageSuccess('Mensagens enviadas com sucesso!');
    };

    const fetchBillsSituations = async () => {
        try {
            const results = await axios.get("/billsToReceive/getBillsSituations");
            setBillsSituations(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBillsSituationsTotal = async () => {
        try {
            const results = await axios.get("/billsToReceive/getBillsSituationsTotal");
            setBillsSituationsTotal(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBillsSituations();
        fetchBillsSituationsTotal();
    }, []);

    const formatStatus = (tickItem: string) => {
        switch(tickItem) {
            case 'canceled':
                return 'Cancelado';
            case 'paid':
                return 'Pago';
            case 'late':
                return 'Vencido';
            case 'pending':
                return 'Pendente';
            default:
                return "Nenhum";
        }
    }

    const formatLabel = (label: string, type: string) => {
        if (type === "situation") {
            switch(label) {
                case 'canceled':
                    return 'Cancelado';
                case 'paid':
                    return 'Pago';
                case 'late':
                    return 'Vencido';
                case 'pending':
                    return 'Pendente';
                default:
                    return "Nenhum";
            }
        }
    }

    const CustomTooltip = ({ active, payload, label, type }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabel(label, type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const createCustomTooltip = (type: any) => (props: any) => (
        <CustomTooltip {...props} type={type} />
    )

    const CustomTooltipPayMethod = ({active, payload, label}: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{`${label}`}</span>{`: ${payload[0].value}`}</p>
                </div>
            )
        }

        return null;
    }
    
    return (
        <>
            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
            />

            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSendEmailMultiple
                showModal={showModalSendEmails}
                setShowModal={setShowModalSendEmails}
                emailSubject={emailSubject}
                setEmailSubject={setEmailSubject}
                message={message}
                setMessage={setMessage}
                onClickSend={handleClickSendEmails}
                infoEmails={infoEmails}
                setInfoEmails={setInfoEmails}
            />
            <ModalSendWhatsappMultiple
                showModal={showModalSendWhatsapp}
                setShowModal={setShowModalSendWhatsapp}
                message={message}
                setMessage={setMessage}
                onClickSend={handleClickSendWhatsapp}
                infoMessages={infoWhatsapp}
            />

            <MainPageContentWrapper>
                <h4 className="font-weight-bolder">Lista de contas a receber</h4>
                {/* <div className="col-lg-12 my-3">
                    <Row xs={1} md={4} className="g-4">
                        <ColCard color="#17E30B" title="Recebimentos atrasados">{formatCurrency(totalLate)}</ColCard>

                        <ColCard color="#17E30B" title="Recebimentos para hoje">{formatCurrency(totalDayToReceive)}</ColCard>

                        <ColCard color="#17E30B" title="Recebimentos para o mês">{formatCurrency(totalMonthToReceive)}</ColCard>

                        <ColCard color="#17E30B" title="Total a receber">{formatCurrency(totalToReceive)}</ColCard>
                    </Row>
                </div> */}

                {
                    countRemittance && user.isAccountant == "n"
                    ?
                        <div className='col-lg-12 bg-warning-o-30 p-5 mb-3'>                        
                            <h3>Remessas a serem geradas</h3>
                            <b>({countRemittance})</b> títulos foram criados como boleto

                            <Button
                                type="button"
                                variant="success"
                                className="ml-3 text-dark"
                                onClick={() => pushHistory(`${pathname}/remessa`)}
                            >
                                <i className="fas fa-plus text-dark"></i>
                                Gerar Remessa
                            </Button>
                        </div>
                    : <></>
                }

                <div className="row d-flex justify-content-between align-items-end">
                    <div className="col-lg-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <>    
                                <Button
                                    type="button"
                                    variant="success"
                                    className="mr-2 mb-2 text-dark"
                                    onClick={() => handleClickAdd()}
                                >
                                    <i className="fas fa-plus text-dark"></i>
                                    Adicionar
                                </Button>

                                <Dropdown className="d-inline-block" style={{ width: "fit-content" }}>
                                    <Dropdown.Toggle className="align-items-end mr-2 mb-2" id="more-actions" variant="secondary">
                                        <i className="fas fa-chevron-down"></i>
                                        Mais ações
                                    </Dropdown.Toggle>
                                    
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleClickTransfer()}>
                                            <i className="flaticon-arrows mr-2"></i>
                                            Transferência entre contas
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => pushHistory(`${pathname}/retorno`)}>
                                            <i className="flaticon2-download mr-2"></i>
                                            Importar retorno
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleClickGeneratedRemittances()}>
                                            <i className="flaticon2-plus mr-2"></i>
                                            Remessas geradas
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            : <></>
                        }
                    </div>
                    
                    <div className="col-lg-3 d-flex justify-content-lg-center">
                        {
                            user.isAccountant == "n"
                            ?
                            <>
                                <Tooltip TransitionComponent={Zoom} title={selectedBillsId.length === 0 ? '' : <p className="font-size-lg mb-0">Enviar Whatsapp para selecionados</p>}>
                                    <Button 
                                        className="mr-2 mb-2 whatsapp-button"
                                        type="button"
                                        disabled={selectedBillsId.length === 0}
                                        onClick={handleOpenSendWhatsappModal}
                                    >
                                        <i className="flaticon-whatsapp text-dark"></i> <span className="text-dark">({selectedBillsId.length})</span>
                                    </Button>
                                </Tooltip>
                                <Tooltip TransitionComponent={Zoom} title={selectedBillsId.length === 0 ? '' : <p className="font-size-lg mb-0">Enviar email para selecionados</p>}>
                                    <Button
                                        className="mr-2 mb-2 email-button"
                                        type="button"
                                        disabled={selectedBillsId.length === 0}
                                        onClick={handleOpenSendEmailsModal}
                                    >
                                        <i className="flaticon2-email text-dark" style={{ color: "#fff" }}></i> <span className="text-dark">({selectedBillsId.length})</span>
                                    </Button>
                                </Tooltip>
                            </>
                            : <></>
                        }
                    </div>

                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={setAdvancedSearch}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                <Collapse in={advancedSearch}>
                    <div className="shadow-sm p-6 rounded-sm border border-2">
                        <h4>Pesquisa avançada</h4>

                        <div className="row d-flex align-items-center">
                            <div className="col-lg-2">
                                <TextField
                                    select
                                    size="small"
                                    label="Situação"
                                    margin="normal"
                                    variant="standard"
                                    value={situation}
                                >
                                    <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                        Nenhum
                                    </MenuItem>
                                    <MenuItem key="1" value="pending" onClick={() => setSituation('pending')}>
                                        Pendente
                                    </MenuItem>
                                    <MenuItem key="2" value="late" onClick={() => setSituation('late')}>
                                        Vencido
                                    </MenuItem>
                                    <MenuItem key="3" value="paid" onClick={() => setSituation('paid')}>
                                        Pago
                                    </MenuItem>
                                    <MenuItem key="4" value="canceled" onClick={() => setSituation('canceled')}>
                                        Cancelado
                                    </MenuItem>
                                </TextField>
                            </div>
                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor"
                                    startAdornment="DE"
                                    customInput={TextField}
                                    value={valueMin}
                                    onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
                                />
                            </div>
                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor"
                                    startAdornment="ATÉ"
                                    value={valueMax}
                                    onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
                                />
                            </div>
                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryId}
                                    onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                />
                            </div>
                            <div className="col-lg-3">
                                <Autocomplete
                                    size="small"
                                    noOptionsText="Sem opções"
                                    options={banks}
                                    value={banks.find(bank => bank.name === nameBank) ?? null}
                                    getOptionLabel={({name}) => name}
                                    onChange={(event, newInputValue) => setNameBank(newInputValue?.name ?? '')}
                                    renderInput={(params) =>
                                        <TextField
                                            size="small"
                                            { ...params }
                                            label="Banco"
                                            margin="normal"
                                            variant="standard"
                                        />
                                    }
                                />
                            </div>
                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Centro de Custos"
                                    getOptionLabel={(option: CenterCost) => option.name}
                                    value={centerCost}
                                    onSelect={(option) => setCenterCost(option?.id ?? 0)}
                                    apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!centerCost) return null;
                                        return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                    }}
                                />
                            </div>
                            <div className="col-lg-3">
                                <Autocomplete
                                    size="small"
                                    noOptionsText="Sem opções"
                                    options={paymentOptions}
                                    value={paymentOptions.find(payOption => payOption.value === payment) ?? null}
                                    getOptionLabel={({value}) => value}
                                    onChange={(event, newInputValue) => setPayment(newInputValue?.value ?? '')}
                                    renderInput={(params) =>
                                        <TextField
                                            size="small"
                                            { ...params }
                                            label="Forma de Pagamento"
                                            margin="normal"
                                            variant="standard"
                                        />
                                    }
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de Emissão"
                                    margin="normal"
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            DE
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={issueDateMin}
                                    onChange={(e) => setIssueDateMin(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de Emissão"
                                    margin="normal"
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            ATÉ
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={issueDateMax}
                                    onChange={(e) => setIssueDateMax(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de Vencimento"
                                    margin="normal"
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            DE
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={dueDateMin}
                                    onChange={(e) => setDueDateMin(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de Vencimento"
                                    margin="normal"
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            ATÉ
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={dueDateMax}
                                    onChange={(e) => setDueDateMax(e.target.value)}
                                />
                            </div>
                            <div className="col-12 d-flex mt-6">
                                <Button
                                    onClick={handleClickSearch}
                                    className="mr-3"
                                >
                                    <i className="fa fa-search"></i>
                                    Pesquisar
                                </Button>

                                <Button
                                    onClick={clearSearch}
                                    variant="danger"
                                >
                                    <i className="fas fa-times"></i>
                                    Limpar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>

                <div className="row">
                    <Paper elevation={1} className="mt-3 col-lg-4 col-12 border border-2 py-3" style={{ height: "fit-content" }}>
                        <Card variant="outlined" className="bg-light p-5 border mb-3">
                            <span className="text-dark font-size-lg font-weight-bolder">Situação</span> - mês corrente

                            <AreaChart className="w-100 mt-6" width={400} height={400} data={billsSituations}>
                                <XAxis dataKey="status" tickFormatter={formatStatus}/>
                                <YAxis />
                                <RechartTooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("situation")}  />
                                <Legend />
                                <Area dataKey="quantity" stroke='#7fc6e0' fill="#7fc6e0" />
                            </AreaChart>
                        </Card>

                        <Card variant="outlined" className="bg-light p-5 border mb-3">
                            <span className="text-dark font-size-lg font-weight-bolder">Total por situação</span> - mês corrente

                            {
                                billsSituationsTotal?.map((billSituationTotal, index) => {
                                    return (
                                        <div key={index} className="d-flex my-3" style={{ fontSize: "16px" }}>
                                            <div className={`${backgroundColors[billSituationTotal.status]} p-3 mr-2 rounded-circle`} style={{ width: "8px", border: "2px solid", borderColor: textColors[billSituationTotal.status] }}></div>
                                            <span className="font-weight-bolder">{formatStatus(billSituationTotal.status)}</span>: {formatCurrency(billSituationTotal.value)}
                                        </div>
                                    )
                                })
                            }

                            {/* <LineChart className="w-100 mt-6" width={400} height={200} data={billsSituationsTotal}>
                                <XAxis dataKey="recordType" tickFormatter={formatStatus} />
                                <YAxis />
                                <RechartTooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("recordType")} />
                                <Legend formatter={formatDataKey} />
                                <Line strokeWidth={3} type="monotone" dataKey="quantity" stroke="#2110ec" />
                            </LineChart> */}
                        </Card>
                    </Paper>

                    <div className="mt-3 col-lg-8 col-12">
                        <ListWithModalChangeSituation
                            selectedBillsId={selectedBillsId}
                            handleOpenSendWhatsappModalBills={handleOpenSendWhatsappModal}
                            handleOpenSendEmailsModalBills={handleOpenSendEmailsModal}
                            billsToReceive
                            situation
                            sortable={true}
                            headData={headData}
                            bodyData={bodyData}
                            onEdit={handleClickEdit}
                            onClone={handleClickClone}
                            onDelete={handleClickDelete}
                            onReversal={handleClickReversal}
                            objectSituation={situationData}
                            onChangeSituation={handleChangeSituation}
                            situationModalTitle="Liquidar conta a receber"
                            selectCheckbox={{
                                onChange: handleChangeSelectedBill,
                                checkedCondition: checkedConditionSelectedBill,
                                showCondition: showConditionSelectBill,
                                onChangeSelectAll: handleChangeSelectAllBills,
                                checkedConditionSelectAll: checkedConditionSelectAllBills,
                            }}
                            loadData={loadData}
                            totalCount={countTotalBillsToReceive}
                            triggerLoad={triggerLoad}
                            setTriggerLoad={setTriggerLoad}
                        />
                    </div>
                </div>
            </MainPageContentWrapper>
        </>
    );
}