import React, { useState, useEffect, useCallback } from 'react';

import { NewProductDetails } from './New-ProductDetails';
import { NewProductStructure } from './New-ProductStructure';
import { NewProductInvoice } from './New-ProductInvoice';
import { NewProductPicture } from './New-ProductPicture';
import api from "../../../services/Api";

import {
   Grid,
   InputAdornment,
   FormControlLabel,
   Checkbox,
   TextField,
   MenuItem,
   makeStyles,
} from '@material-ui/core';


import { NumericFormat } from '../../../components/NumericFormat';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import {
   Button,
   Card,
   Modal,
   Tabs,
   Tab,
   Spinner
} from 'react-bootstrap';

import '../../../style.css';
import ModalLimit from '../../../components/ModalLimit';
import logService from '../../../services/LogService';
import StockService from '../../../services/StockService';
import { useLocation } from 'react-router';
import { getTypeOrderText } from '../../../utils/getTypeOrderText';
import { getTypeOrderApiUrl, getTypeOrderUrl } from '../../../utils/getTypeOrderUrl';
import ModalSuccess from '../../../components/ModalSuccess';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ProductService from '../../../services/ProductService';
import StockLocationService from '../../../services/StockLocationService';
import { Product } from '../../../types/Product';
import { MainPageContentWrapper } from '../../MainPageContentWrapper';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   dense: {
      marginTop: theme.spacing(2),
   },
   menu: {
      width: 200,
   },
   error: {
      "& .Mui-error": {
         color: "#f64e60 !important"
      },
      "& .MuiFormHelperText-root": {
         color: "#f64e60 !important"
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
         borderColor: "#f64e60 !important"
      }
   }
}));

type Inputs = {
   name: string;
   code: string;
   isVariant: string;
   brand: string;
   unit: string;
   codeGtinEan: string;
   type: string;
   typeClassification: string;
};

type Data = {
   name: string;
   code: string;
   isVariant: string;
   brand: string;
   unit: string;
   codeGtinEan: string;
   stockLocation: string;
   stockMin: string;
   stockMax: string;
   stockInitial: string;
   currentStock: string;
   grossWeight: string;
   liquidColumn: string;
   saleValue: string;
   costValue: string;
   productSize: string;
   productOrigin: string;
   ordersNumber: string;
   typeClassification: string;
   situation: string;
   type: string;
   provider: string;
   note: string;
   category: string;
   icmsRate: string;
   icmsStRate: string;
   cstRate: string;
   mvaRate: string;
   mvaAdjustedRate: string;
   deferralRate: string;
   ipiRate: string;
   ipiCst: string;
   pisRate: string;
   pisCst: string;
   cofinsRate: string;
   taxableUnit: string;
   taxableCount: string;
   taxableValue: string;
   taxBenefitCode: string;
   federal: string;
   state: string;
   subCategory: string;
};

const DEFAULT_DATA = {
   name: '',
   code: '',
   isVariant: '',
   brand: '',
   unit: '',
   codeGtinEan: '',
   ncm: undefined,
   stockLocation: '',
   stockMin: '',
   stockMax: '',
   stockInitial: '',
   currentStock: '',
   grossWeight: '',
   liquidColumn: '',
   saleValue: '',
   costValue: '',
   productSize: '',
   productOrigin: '',
   ordersNumber: '',
   typeClassification: '',
   situation: '',
   type: '',
   provider: '',
   note: '',
   category: '',
   icmsRate: '',
   icmsStRate: '',
   cstRate: '',
   mvaRate: '',
   mvaAdjustedRate: '',
   deferralRate: '',
   ipiRate: '',
   ipiCst: '',
   pisRate: '',
   pisCst: '',
   cofinsRate: '',
   taxableUnit: '',
   taxableCount: '',
   taxableValue: '',
   taxBenefitCode: '',
   cestCode: undefined,
   federal: '',
   state: '',
   subCategory: '',
};


type products = {
   id: number,
   name: string,
}

type grid = {
   id: number,
   name: string,
   variation: variation[]
}

type variation = {
   id: number,
   idGrid: number,
   variationName: string
}

type StockLocations = {
   id: number;
   name: string;
   description: string;
};

type AvailableTabs = {
   variation?: boolean;
   productStructure?: boolean;
   nfe?: boolean;
}

type NewProductFormProps = {
   defaultData?: NewProductDefaultData;
   availableTabs: AvailableTabs;
   allowChangeType?: boolean;
   onCloseModalLimit: () => void;
   onProductCreated?: (createdProduct: Product) => void;
   onCloseProductCreatedSuccessModal: () => void;
};

export type NewProductDefaultData = {
   nameProduct?: string;
   selectedType?: 'product' | 'service';
}

export function NewProductForm({
   defaultData,
   availableTabs,
   allowChangeType = true,
   onCloseModalLimit,
   onProductCreated,
   onCloseProductCreatedSuccessModal,
}: NewProductFormProps) {
   const classes = useStyles();

   const [canCreateProduct, setCanCreateProduct] = useState(true);
   const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
   const [showModalLimit, setShowModalLimit] = useState(false);

   const [showModalSuccess, setShowModalSuccess] = useState(false);

   const [activeTab, setActiveTab] = useState("product-details");
   const [ipiCst, setIpiCst] = useState("");
   const [pisCst, setPisCst] = useState("");
   const [cofinsCst, setCofinsCst] = useState("");
   const [taxableValue, setTaxableValue] = useState("");
   const [nameProduct, setNameProduct] = useState("");
   const [codeProduct, setCodeProduct] = useState("");
   const [msgError, setMsgError] = useState("");
   const [showModalExist, setShowModalExist] = useState(false);

   const [productsKit, setProductsKit] = useState({
      products: [
         {
            productId: 0,
            productName: "",
            productCount: 1
         }
      ]
   });
   const [isKit, setIsKit] = useState("n");
   const [dismemberItems, setDismemberItems] = useState(false);

   const [grid, setGrid] = useState({
      grids: [
         {
            gridId: 0,
            gridName: ""
         }
      ]
   });

   const [gridNameCreate, setGridNameCreate] = useState("");
   const [variationCreate, setVariationCreate] = useState({
      variation: [
         {
            variationName: ""
         }
      ]
   });

   const [variationGrid, setVariationGrid] = useState({
      variation: [
         {
            grid: 0,
            variationId: 0
         }
      ]
   });

   const [variationProduct, setVariationProduct] = useState({
      variation: [
         {
            grid: [
               {
                  gridId: 0,
                  variationId: 0,
                  variationName: ""
               }
            ],
            code: "",
            name: "",
            stockLocation: "",
            stockMin: 0.0,
            stockMax: 0.0,
            stockInitial: 0.0,
            grossWeight: "",
            liquidWeight: "",
            situation: "",
            codeGtinEan: "",
            saleValue: "",
            costValue: "",
            inputsInvalid: {
               grid: false,
               stockLocation: false,
               stockMin: false,
               stockMax: false,
               stockInitial: false,
               saleValue: false
            }
         }
      ]
   })
   const [gridSelected, setGridSelected] = useState<grid[]>([]);
   const [showModalCreateGrid, setShowModalCreateGrid] = useState(false);

   const [showBrandModal, setShowBrandModal] = useState(false);
   const [showBrandModalConfirmDelete, setShowBrandModalConfirmDelete] = useState(false);
   const [addBrand, setAddBrand] = useState(true);
   const [idBrandToDelete, setIdBrandToDelete] = useState(0);
   const [indexBrandToDelete, setIndexBrandToDelete] = useState(0);

   const [showModal, setShowModal] = useState(false);
   const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
   const [showModalPrice, setShowModalPrice] = useState(false);
   const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
   const [showModalConfirmDeleteSubCategory, setShowModalConfirmDeleteSubCategory] = useState(false);
   const [addCategory, setAddCategory] = useState(true);
   const [showSubCategoryField, setShowSubCategoryField] = useState(false);
   const [addSubCategory, setAddSubCategory] = useState(true);
   const [selectProductInKit, setSelectProductInKit] = useState(false);
   const [idCategoryToDelete, setIdCategoryToDelete] = useState(0);
   const [indexCategoryToDelete, setIndexCategoryToDelete] = useState(0);

   const [hasVariation, setHasVariation] = useState('n');

   const [provider, setProvider] = useState("");

   const [buyValue, setBuyValue] = useState('');
   const [creditIcmsPercent, setCreditIcmsPercent] = useState('0');
   const [creditIcmsMoney, setCreditIcmsMoney] = useState('0');
   const [creditIcmsstPercent, setCreditIcmsstPercent] = useState('0');
   const [creditIcmsstMoney, setCreditIcmsstMoney] = useState('0');
   const [freightPercent, setFreightPercent] = useState('0');
   const [freightMoney, setFreightMoney] = useState('0');
   const [aditionalCost, setAditionalCost] = useState('0');
   const [ipiPercent, setIpiPercent] = useState('0');
   const [ipiMoney, setIpiMoney] = useState('0');
   const [profitMarginPercent, setProfitMarginPercent] = useState('0');
   const [profitMarginMoney, setProfitMarginMoney] = useState('0');
   const [sellerValue, setSellerValue] = useState('');

   const [brandProduct, setBrandProduct] = useState([]);
   const [categoryProduct, setCategoryProduct] = useState([]);
   const [subCategoryProduct, setSubCategoryProduct] = useState([]);
   const [selectedCategoryProductUuid, setSelectedCategoryProductUuid] = useState([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);

   const [data, setData] = useState<Data>(DEFAULT_DATA);

   const [isSubmit, setIsSubmit] = useState(false);


   const [typeInvalid, setTypeInvalid] = useState(false);
   const [nameInvalid, setNameInvalid] = useState(false);
   const [unitInvalid, setUnitInvalid] = useState(false);
   const [saleValueInvalid, setSaleValueInvalid] = useState(false);
   const [stockInvalid, setStockInvalid] = useState(false);
   const [stockMinInvalid, setStockMinInvalid] = useState(false);
   const [stockMaxInvalid, setStockMaxInvalid] = useState(false);
   const [stockInitialInvalid, setStockInitialInvalid] = useState(false);
   const [productByGtinEanCodeInvalid, setProductByGtinEanCodeInvalid] = useState(false);

   const [ncm, setNcm] = useState("");
   const [cestCode, setCestCode] = useState("");

   const [situation, setSituation] = useState("active");
   const [selectedType, setSelectedType] = useState('product');
   const [brandNameInput, setBrandNameInput] = useState("");
   const [saleValueApply, setSaleValueApply] = useState("");
   const [stockLocations, setStockLocations] = useState<StockLocations[]>();
   const [icms, setIcms] = useState("");
   const [icmsSt, setIcmsSt] = useState("");
   const [cst, setCst] = useState("");
   const [mva, setMva] = useState("");
   const [mvaAdjusted, setMvaAdjusted] = useState("");
   const [deferral, setDeferral] = useState("");
   const [ipi, setIpi] = useState("");
   const [pis, setPis] = useState("");
   const [cofins, setCofins] = useState("");
   const [federal, setFederal] = useState("");
   const [state, setState] = useState("");
   const [brand, setBrand] = useState("");
   const [category, setCategory] = useState("");
   const [subCategory, setSubCategory] = useState("");
   const [stockLocation, setStockLocation] = useState("");

   const [nextButton, setNextButton] = useState('first');
   const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

   const detailsRequiredFields = [data.unit, data.saleValue, data.stockLocation, data.stockMin, data.stockMax, data.stockInitial];
   const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

   const {
      register,
      watch,
      formState: { errors },
   } = useForm<Inputs>();

   const variationTabsAvailable = availableTabs && availableTabs.variation;
   const strucutreTabAvailable = availableTabs && availableTabs.productStructure;
   const nfeTabAvailable = availableTabs && availableTabs.nfe;

   async function registerBrandInput() {

      const uuidBrand = uuidv4();

      var raw = {
         uuidBrand: uuidBrand,
         nameBrand: brandNameInput,
      }

      try {

         await api.post("/brand", raw)

         //  setAddBrand(!addBrand);

      } catch (error) {

         console.log(error)

      }

   }

   function deleteBrand(idBrand: number, index: number) {
      try {
         const brand = api.delete(`/brand/${idBrand}`);

         var arrBrand = brandProduct;
         arrBrand.splice(index, 1);
         setBrandProduct(arrBrand);

         setShowBrandModalConfirmDelete(false);
         setShowBrandModal(true);

      } catch (error) {

         console.log(error);

      }
   }



   function handleChangePage(event: any, newPage: number) {
      setPage(newPage);
   }

   function calcPrice() {
      const buyValuePrice = parseFloat(buyValue);

      const valueIcmsMoney = buyValuePrice * (parseFloat(creditIcmsPercent) / 100);
      setCreditIcmsMoney(valueIcmsMoney.toString());
   }

   const handleChange = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
   ) => {

      const newData = { ...data };

      const inputName = e.target.name as keyof Data;

      if (e.target.type !== 'checkbox') {

         newData[inputName] = e.target.value;

      } else {

         const checkbox = e.target as HTMLInputElement;
         newData[inputName] = checkbox.checked ? 'y' : 'n';

      }

      setData(newData);

   }

   function addVariationProduct() {
      var variationArr = variationProduct.variation;

      if (!gridSelected.length) {
         setIsSubmit(false);
         setMsgError("Selecione pelo menos uma grade para a variação");
         setShowModalExist(true);

      } else {
         variationArr.push({
            grid: [
               {
                  gridId: 0,
                  variationId: 0,
                  variationName: ""
               }
            ],
            code: "",
            name: "",
            stockLocation: "",
            stockMin: 0,
            stockMax: 0,
            stockInitial: 0,
            grossWeight: "",
            liquidWeight: "",
            situation: "",
            codeGtinEan: "",
            saleValue: "",
            costValue: "",
            inputsInvalid: {
               grid: false,
               stockLocation: false,
               stockMin: false,
               stockMax: false,
               stockInitial: false,
               saleValue: false
            }
         });

         setVariationProduct({ variation: variationArr });
      }

   }

   function deleteVariationProduct(index: number) {
      var variationArr = variationProduct.variation;

      variationArr.splice(index, 1);

      setVariationProduct({ variation: variationArr });
   }

   async function getGrid() {
      var gridArr = grid.grids;

      try {
         const gridResponse = await api.get("/grid");

         for (let gridIndex = 0; gridIndex < gridResponse.data.length; gridIndex++) {
            if (!gridArr[0].gridName) {
               gridArr[0].gridId = gridResponse.data[gridIndex].id;
               gridArr[0].gridName = gridResponse.data[gridIndex].name;

            } else {
               gridArr.push({
                  gridId: gridResponse.data[gridIndex].id,
                  gridName: gridResponse.data[gridIndex].name
               })
            }
         }

         setGrid({ grids: gridArr });

      } catch (error) {
         console.log(error);
      }
   }

   async function selectGrid(id: number, name: string, target: HTMLInputElement) {
      var gridSelectedToVariation = gridSelected;
      var variationArr = variationProduct.variation;

      if (target.checked) {
         var selectedGrid = await api.get(`/grid/${id}`);
         var gridVariations = await api.get(`/gridVariation/grid/${id}`);

         gridSelectedToVariation.push({
            id: id,
            name: selectedGrid.data.name,
            variation: gridVariations.data
         });
      } else {
         if (gridSelectedToVariation.length === 1 && variationArr.length > 1) {
            target.click()
            setMsgError("É necessário manter pelo menos uma grade selecionada");
            setShowModalExist(true);
         }
         for (let removeGridSelected = 0; removeGridSelected < gridSelectedToVariation.length; removeGridSelected++) {
            if (gridSelectedToVariation[removeGridSelected].id === id) {
               gridSelectedToVariation.splice(removeGridSelected, 1);
            }
         }
         variationArr.map((variationObj, index) => {
            variationArr[index].grid = variationObj.grid.filter(gridObj => gridObj.gridId !== id);
         });
      }


      setVariationProduct({ variation: variationArr });
      setGridSelected(gridSelectedToVariation);
   }

   async function saveGridVariation(name: any, gridId: any) {
      var variationGridArr = variationGrid.variation;

      const rawGridVariation = {
         idGrid: gridId,
         variationName: name
      }

      const gridVariationSaved = await api.post("/gridVariation", rawGridVariation);

      variationGridArr.push({
         grid: gridId,
         variationId: gridVariationSaved.data.id
      })

      setVariationGrid({ variation: variationGridArr });
   }


   async function saveGrid() {
      var gridArr = grid.grids;
      var variationGridCreate = variationCreate.variation;

      if (gridNameCreate) {

         const raw = {
            name: gridNameCreate,
         }

         try {
            const gridSaved = await api.post("/grid", raw);

            variationGridCreate.map((variation, index) => {
               if (variation.variationName) {
                  saveGridVariation(variation.variationName, gridSaved.data.id);
               }
            });

            gridArr.push({
               gridId: gridSaved.data.id,
               gridName: gridSaved.data.name
            })

            setGrid({ grids: gridArr });

            setGridNameCreate("");
            variationGridCreate = [];
            setVariationCreate({ variation: variationGridCreate });
            setShowModalCreateGrid(!showModalCreateGrid)

         } catch (error) {
            console.log(error);
         }

      } else {
         alert("Preencha o nome da grade corretamente");
      }

   }

   function deleteVariation(index: number) {
      var variationGrid = variationCreate.variation;

      variationGrid.splice(index, 1);

      setVariationCreate({ variation: variationGrid });
   }

   function addVariation() {
      var variationGrid = variationCreate.variation;

      variationGrid.push({
         variationName: ""
      });

      setVariationCreate({ variation: variationGrid });

   }

   function changeNameVariationGrid(index: number, value: string) {
      var variationGrid = variationCreate.variation;

      variationGrid[index].variationName = value;

      setVariationCreate({ variation: variationGrid });
   }

   function updateVariationProductGrid(index: number, gridId: number, variationId: number) {
      var variationArr = variationProduct.variation;

      gridSelected.map((dataGrid, indexGrid) => {
         dataGrid.variation.map((variation, indexVariation) => {
            if (variation.id == variationId && variation.idGrid == gridId) {
               variationArr[index].grid = [{
                  gridId: gridId,
                  variationId: variationId,
                  variationName: variation.variationName
               }];
            }
         });
      });

      setVariationProduct({ variation: variationArr });
   }

   function updateVariationProduct(index: number, key: string, value: any) {
      var variationArr = variationProduct.variation;

      switch (key) {
         case 'code':
            variationArr[index].code = value;
            break;
         case 'stockLocation':
            variationArr[index].stockLocation = value;
            break;
         case 'stockMin':
            variationArr[index].stockMin = formatToFloat(value);
            break;
         case 'stockMax':
            variationArr[index].stockMax = formatToFloat(value);
            break;
         case 'stockInitial':
            variationArr[index].stockInitial = formatToFloat(value);
            break;
         case 'grossWeight':
            variationArr[index].grossWeight = value;
            break;
         case 'liquidWeight':
            variationArr[index].liquidWeight = value;
            break;
         case 'situation':
            variationArr[index].situation = value;
            break;
         case 'codeGtinEan':
            variationArr[index].codeGtinEan = value;
            break;
         case 'saleValue':
            variationArr[index].saleValue = value;
            break;
         case 'costValue':
            variationArr[index].costValue = value;
            break;
      }

      setVariationProduct({ variation: variationArr });
   }

   function setUpVariationProductsGrid() {
      var gridSelectedArr = gridSelected;
      var variationProductArr = variationProduct.variation;
      var variationGridIndex = null;
      gridSelectedArr.map(({ id, name, variation }) => {
         variationProductArr.map((variationObj, index) => {
            if (index > 0) {
               variationGridIndex = variationProduct.variation[index].grid.find(gridObj => gridObj.gridId === id);
               if (!variationGridIndex) {
                  variationProductArr[index].grid.push({
                     gridId: id,
                     variationId: variation[0].id,
                     variationName: variation[0].variationName
                  });
               }
            }
         });
         setVariationProduct({ variation: variationProductArr });
      });
   }

   function addProductToKit() {
      var kit = productsKit.products;

      kit.push({
         productId: 0,
         productName: '',
         productCount: 0
      });

      setProductsKit({ products: kit });
   }

   function deleteProductKit(index: number) {
      var kit = productsKit.products;

      kit.splice(index, 1);

      setProductsKit({ products: kit });
   }

   function changeCountProductKit(index: number, value: number) {
      var kit = productsKit.products;

      kit[index].productCount = value;

      setProductsKit({ products: kit });
   }

   useEffect(() => {
      getGrid();
      checkLimits();
   }, []);

   useEffect(() => {
      if (defaultData) {
         if (defaultData.selectedType) {
            setSelectedType(defaultData.selectedType ?? '');
         }

         if (defaultData.nameProduct) {
            setNameProduct(defaultData.nameProduct ?? '');
            generateCodeProduct(defaultData.nameProduct ?? '');
         }
      }
   }, [defaultData]);

   async function checkLimits() {
      const response = await api.get('companySubscriptionPlans/limits/byField?field=products');
      const limits = response.data;
      if (limits.max !== -1 && limits.current >= limits.max) {
         setCanCreateProduct(false);
         setShowModalLimit(true);
         setModalLimitMsg(['Seu plano atual não permite o cadastro de novos produtos/serviços']);
      }
   }

   const handleCloseModalLimit = useCallback(() => {
      if (!canCreateProduct) {
         onCloseModalLimit();
      }
   }, [canCreateProduct]);

   function kitVerify(): boolean {

      if (isKit === "y") {
         var kit = productsKit.products;

         if (kit.length) {
            for (let kitArr = 0; kitArr < kit.length; kitArr++) {
               if (!kit[kitArr].productName) {
                  setActiveTab("product-structure");
                  setIsSubmit(false);
                  setMsgError("Selecione o produto para o kit");
                  setShowModalExist(true);

                  return false

               } else if (!kit[kitArr].productCount) {
                  setActiveTab("product-structure");
                  setIsSubmit(false);
                  setMsgError("Informe a quantidade do produto corretamente");
                  setShowModalExist(true);

                  return false
               }
            }
         } else {
            setActiveTab("product-structure");
            setIsSubmit(false);
            setMsgError("Adicione pelo menos um produto ao kit");
            setShowModalExist(true);

            return false
         }
      }

      return true
   }

   async function generateCodeProduct(nameProduct: string) {
      if (nameProduct) {
         let splited = nameProduct.trim().split(" ");
         let number = 1;

         let initials = "";

         for (let splitData of splited) {
            initials += splitData[0]?.toUpperCase() ?? '';
         }

         const { data } = await api.get(`/products/verify-code/${initials + number}`);

         if (data) {
            var generateSuccess = false
            while (generateSuccess == false) {
               var productResponse = await api.get(`/products/verify-code/${initials + number}`);

               if (productResponse.data) {
                  number++;

               } else {
                  generateSuccess = true;
                  setCodeProduct(initials + "" + number);
               }
            }

         } else {
            setCodeProduct(initials + "" + number);
         }
      }
   }

   async function generateCodeVariationProduct(nameProduct: string, idVariation: number, index: number) {
      let nameVariation = variationProduct.variation[index].grid.find(gridObj => gridObj.variationId === idVariation)?.variationName;

      let variationSplited = nameVariation?.trim().split(" ") ?? [];
      let splited = nameProduct.trim().split(" ");
      let number = 1;

      let initials = "";
      let initialsVariation = "";

      for (let splitData of splited) {
         initials += splitData[0]?.toUpperCase() ?? '';
      }

      for (let splitVariationData of variationSplited) {
         initialsVariation += splitVariationData[0]?.toUpperCase() ?? '';
      }

      const { data } = await api.get(`/variations/verify-code/${initials + initialsVariation + number}`);

      if (data) {
         var generateSuccess = false
         while (generateSuccess == false) {
            var productResponse = await api.get(`/variations/verify-code/${initials + initialsVariation + number}`);

            if (productResponse.data) {
               number++;

            } else {
               var subGenerateSuccess = false
               while (subGenerateSuccess == false) {
                  var foundEqualCode = false;
                  for (let variationObj of variationProduct.variation) {
                     if (variationObj.code == String(initials + initialsVariation + number)) {
                        foundEqualCode = true;
                     }
                  }

                  if (foundEqualCode) {
                     number++;

                  } else {
                     subGenerateSuccess = true;
                     updateVariationProduct(index, 'code', initials + initialsVariation + number);
                  }
               }
               generateSuccess = true;
            }
         }

      } else {
         var generateSuccess = false
         while (generateSuccess == false) {
            var foundEqualCode = false;
            for (let variationObj of variationProduct.variation) {
               if (variationObj.code == String(initials + initialsVariation + number)) {
                  foundEqualCode = true;
               }
            }

            if (foundEqualCode) {
               number++;

            } else {
               generateSuccess = true;
               updateVariationProduct(index, 'code', initials + initialsVariation + number);
            }
         }

      }
   }

   function variationVerify(): boolean {
      if (hasVariation !== 'y') {
         return true;
      }

      if (gridSelected.length === 0) {
         setActiveTab("grid");
         setIsSubmit(false);
         setMsgError("Selecione pelo menos uma grade de variação");
         setShowModalExist(true);
         return false;
      }

      if (variationProduct.variation.length <= 1) {
         setActiveTab("variation");
         setIsSubmit(false);
         setMsgError("Adicione ao menos uma variação do produto");
         setShowModalExist(true);
         return false;
      }

      var variation = variationProduct.variation;
      for (let i = 0; i < variationProduct.variation.length; i++) {
         if (i > 0) {

            if (!variationProduct.variation[i].grid[0].gridId) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Selecione uma variação da grade para a variação do produto");
               setShowModalExist(true);
               variation[i].inputsInvalid.grid = true;
               return false;

            } else if (!variationProduct.variation[i].stockLocation) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Selecione a localização no estoque da variação");
               setShowModalExist(true);
               variation[i].inputsInvalid.stockLocation = true;
               return false;

            } else if (!variationProduct.variation[i].stockMin) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Preencha o estoque mínimo da variação");
               setShowModalExist(true);
               variation[i].inputsInvalid.stockMin = true;
               return false;

            } else if (!variationProduct.variation[i].stockMax) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Preencha o estoque máximo da variação");
               setShowModalExist(true);
               variation[i].inputsInvalid.stockMax = true;
               return false;

            } else if (!variationProduct.variation[i].stockInitial) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Preencha o estoque inicial da variação");
               setShowModalExist(true);
               variation[i].inputsInvalid.stockInitial = true;
               return false;

            } else if (!variationProduct.variation[i].saleValue) {
               setActiveTab("variation");
               setIsSubmit(false);
               setMsgError("Preencha o valor de venda da variação corretamente");
               setShowModalExist(true);
               variation[i].inputsInvalid.saleValue = true;
               return false;
            }
         }
      }

      setVariationProduct({ variation: variation });
      return true;
   }

   async function inputsVerify(data: any): Promise<boolean> {
      const {
         unit,
         saleValue,
         codeGtinEan,
         stockMin,
         stockMax,
         stockInitial,
         isVariant
      } = data;

      if (!selectedType) {
         setIsSubmit(false);
         setMsgError("Selecione o tipo corretamente");
         setShowModalExist(true);
         setTypeInvalid(true);

         return false
      } 
      
      if (!nameProduct) {
         setIsSubmit(false);
         setMsgError("Preencha o nome corretamente");
         setShowModalExist(true);
         setNameInvalid(true);

         return false
      } 
      
      if (!unit) {
         setActiveTab("product-details");
         setIsSubmit(false);
         setMsgError("Preencha a unidade corretamente");
         setShowModalExist(true);
         setUnitInvalid(true);

         return false
      } 
      
      if (!saleValue) {
         setActiveTab("product-details");
         setIsSubmit(false);
         setMsgError("Preencha o valor de venda corretamente");
         setShowModalExist(true);
         setSaleValueInvalid(true);

         return false
      }

      if (isVariant == "" && isKit == "n" && selectedType == "product") {
         if (!stockLocation) {
            setActiveTab("product-details");
            setIsSubmit(false);
            setMsgError("Selecione a localização no estoque");
            setShowModalExist(true);
            setStockInvalid(true);

            return false
         }
         
         if (!stockMin) {
            setActiveTab("product-details");
            setIsSubmit(false);
            setMsgError("Preencha o estoque mínimo");
            setShowModalExist(true);
            setStockMinInvalid(true);

            return false
         }
         
         if (!stockMax) {
            setActiveTab("product-details");
            setIsSubmit(false);
            setMsgError("Preencha o estoque máximo");
            setShowModalExist(true);
            setStockMaxInvalid(true);

            return false
         }
         
         if (!stockInitial) {
            setActiveTab("product-details");
            setIsSubmit(false);
            setMsgError("Preencha o estoque inicial");
            setShowModalExist(true);
            setStockInitialInvalid(true);

            return false
         }
      }

      if (codeGtinEan) {
         const { data: productByGtinEanCode } = await api.get(`/products/codeGtinEan/${codeGtinEan}`);

         if (productByGtinEanCode) {
            setActiveTab("product-details");
            setIsSubmit(false);
            setMsgError("Já existe um código GTIN/EAN com este valor!");
            setShowModalExist(true);
            setProductByGtinEanCodeInvalid(true);
            return false;
         }
      }

      return true;
   }

   function formatToFloat(value: string) {
      if (value.includes(',') && !value.includes('.')) {
         return parseFloat(value.replace(',', '.'));

      } else {
         return parseFloat(value.replace('.', '').replace(',', '.'));
      }
   }

   async function myHandleSubmit() {
      setIsSubmit(true);

      data.saleValue = data.saleValue ? data.saleValue : saleValueApply;

      if (!(await inputsVerify(data))) return;

      if (!kitVerify()) return;

      if (!variationVerify()) return;

      var totalStockVariation = 0;
      variationProduct.variation.map(async (value, index) => {
         if (index > 0) {
            totalStockVariation += value.stockInitial;
         }
      });

      const raw = {
         // files,
         name: nameProduct,
         code: codeProduct,
         hasVariation: (data.isVariant === "" ? "n" : data.isVariant),
         brand: brand ? Number(brand) : null,
         unit: (data.unit === "" ? "Un" : data.unit),
         codeGtinEan: data.codeGtinEan ? data.codeGtinEan : null,
         ncm: ncm,
         stockLocation: stockLocation ? Number(stockLocation) : null,
         stockMin: data.stockMin ? formatToFloat(data.stockMin) : null,
         stockMax: data.stockMax ? formatToFloat(data.stockMax) : null,
         stockInitial: data.stockInitial ? formatToFloat(data.stockInitial) : null,
         currentStock: data.stockInitial ? formatToFloat(data.stockInitial) : totalStockVariation,
         grossWeight: data.grossWeight,
         liquidColumn: data.liquidColumn,
         saleValue: data.saleValue,
         costValue: data.costValue,
         productSize: data.productSize,
         productOrigin: data.productOrigin,
         ordersNumber: data.ordersNumber,
         typeClassification: data.typeClassification,
         situation: (situation === "" ? "inactive" : situation),
         type: (selectedType === "" ? "product" : selectedType),
         provider: provider ? parseInt(provider) : null,
         note: data.note,
         category: category ? Number(category) : null,
         subCategory: subCategory ? Number(subCategory) : null,
         icmsRate: icms,
         icmsStRate: icmsSt,
         cstRate: cst,
         mvaRate: mva,
         mvaAdjustedRate: mvaAdjusted,
         deferralRate: deferral,
         ipiRate: ipi,
         ipiCst: ipiCst,
         pisRate: pis,
         pisCst: pisCst,
         cofinsRate: cofins,
         cofinsCst: cofinsCst,
         taxableUnit: (data.taxableUnit === "" ? "Un" : data.taxableUnit),
         taxableCount: data.taxableCount ? Number(data.taxableCount) : 0,
         taxableValue: formatToFloat(taxableValue),
         taxBenefitCode: data.taxBenefitCode,
         cestCode: cestCode,
         federal: federal,
         state: state,
         isKit: isKit,
         kit: JSON.stringify(productsKit.products)
      }

      try {
         const response = await api.post<Product>("/products", raw);

         logService.logRegister({
            itemId: response.data.id,
            module: 'Produtos / Serviços',
            itemName: response.data.name,
         });

         if (selectedType == "product") {
            if (hasVariation === 'y') {
               setUpVariationProductsGrid();
               await Promise.all(
                  variationProduct.variation.map(async (variationObj, index) => {
                     const stockId = variationObj.stockLocation !== "" ? variationObj.stockLocation : null;
                     const stockResult = stockId ? await api.get(`/stockLocation/${String(stockId)}`) : null;
                     const stockName = stockResult ? stockResult.data.name : '';

                     if (index > 0) {
                        const rawVariation = {
                           productId: Number(response.data.id),
                           name: nameProduct + "-" + variationObj.grid[0].variationName,
                           gridId: variationObj.grid[0].gridId,
                           variationId: variationObj.grid[0].variationId,
                           code: variationObj.code ?? null,
                           stockLocation: stockId,
                           stockMin: variationObj.stockMin,
                           stockMax: variationObj.stockMax,
                           stockInitial: variationObj.stockInitial,
                           currentStock: variationObj.stockInitial,
                           grossWeight: variationObj.grossWeight !== "" ? variationObj.grossWeight : null,
                           liquidWeight: variationObj.liquidWeight !== "" ? variationObj.liquidWeight : null,
                           situation: 'active',
                           codeGtinEan: variationObj.codeGtinEan !== "" ? variationObj.codeGtinEan : null,
                           saleValue: variationObj.saleValue,
                           costValue: variationObj.costValue !== "" ? variationObj.costValue : null,
                        }
                        const responseVariation = await api.post("/variations", rawVariation)
                        const today = new Date();
                        const month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
                        const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

                        const rawHistoric = {
                           productId: Number(response.data.id),
                           date: today.getFullYear() + "-" + month + "-" + day,
                           observation: "Variação criada e inserida no histórico",
                           stockId: stockId,
                           stockName: stockName,
                           value: formatToFloat(variationObj.saleValue),
                           type: "Balanço",
                           totalLocation: variationObj.stockInitial,
                           total: variationObj.stockInitial,
                           hasVariation: "y",
                           variationId: Number(responseVariation.data.id)
                        }

                        await api.post("/stockProductHistoric", rawHistoric);
                     }
                  })
               );

            } else {
               await StockService.createStockHistoric({
                  productId: Number(response.data.id),
                  stockLocationId: Number(response.data.stockLocation),
                  productValue: formatToFloat(data.saleValue),
                  type: 'Balanço',
                  observation: 'Produto criado e inserido no histórico',
                  total: formatToFloat(data.stockInitial),
                  totalLocation: formatToFloat(data.stockInitial),
                  hasVariation: 'n',
               });
            }
         }

         if (onProductCreated) {
            onProductCreated(response.data);
         }

         setShowModalSuccess(true);
      } catch (error) {
         console.log('error', error);
         setMsgError("Ocorreu um erro ao processar a requisição");
         setShowModalExist(true);
      }

      setIsSubmit(false);
   };

   async function apiSearchProducts(typedText: string) {
      const foundProducts = await ProductService.getProductsWithoutVariationsFiltered({ name: typedText }, true);

      const kitProductsId = productsKit.products.map(prod => prod.productId);

      return foundProducts.filter((prod) => !kitProductsId.includes(prod.id));
   }

   function handleCloseModalSuccess() {
      onCloseProductCreatedSuccessModal();
   }

   function changeTabIcon(previousIcon: string) {
      let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
      if (previousIcon === "flaticon-doc") {
            if (isDetailsRequiredFieldsFilled) {
               const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-delete');
               
               if (current_icon) {
                  current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                  current_icon?.classList.replace("text-danger", "text-success");
               } else {
                  prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                  prev_icon?.classList.add("text-success");
               }
            } else {
               const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-check-mark');

               if (current_icon) {
                  current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                  current_icon.classList.replace("text-success", "text-danger");
               } else {
                  prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                  prev_icon?.classList.add("text-danger");
               }
            }
      } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
      }
   }

   function changeTab(tab: string, icon: string, previousIcon: string) {
      document.getElementById('product-details')?.classList.remove('current');
      document.getElementById('product-structure')?.classList.remove('current');
      document.getElementById('nfe')?.classList.remove('current');
      document.getElementById('variation-products')?.classList.remove('current');

      let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
      if (all_icons != undefined) {
            all_icons.forEach(function(item){
               item.style.color = '#666';
            });
      }

      if (!detailsRequiredFields[0]) {
            setUnitInvalid(true);
      }

      if (!detailsRequiredFields[1]) {
            setSaleValueInvalid(true);
      }

      if (!detailsRequiredFields[2]) {
            setStockInvalid(true);
      }

      if (!detailsRequiredFields[3]) {
            setStockMinInvalid(true);
      }

      if (!detailsRequiredFields[4]) {
            setStockMaxInvalid(true);
      }

      if (!detailsRequiredFields[5]) {
            setStockInitialInvalid(true);
      }

      changeTabIcon(previousIcon);

      let sel_icon = document.querySelector<HTMLElement>('.'+icon);
      if (sel_icon != undefined) {
            sel_icon.style.color = '#fff';
      }

      document.getElementById(tab)?.classList.add('current');
      setActiveTab(tab);
      if (tab == 'product-details') {
            setNextButton('first');
      } else if (tab == 'variation-products') {
            setNextButton('last');
      } else {
            setNextButton('mid');
      }

      setPreviousIcon(icon);
   }

   function backNextOption(value:string){
      let arr = ['product-details', 'product-structure', 'nfe', 'variation-products'];
      let arr2 = ['flaticon-doc', 'flaticon-settings', 'flaticon-notepad', 'flaticon-open-box'];
      let count = arr.length
      let index = arr.indexOf(activeTab);

      if (value == 'back') {
            if (activeTab == arr[0]) {
               setNextButton('first');
            } else {
               index = index-1;
               changeTab(arr[index], arr2[index], arr2[index+1]);
            }
      } else {
            if (activeTab == arr[count-1]) {
               setNextButton('last');
            } else {                
               index = index+1;
               changeTab(arr[index], arr2[index], arr2[index-1]);
            }
      }
   }

   return (
      <MainPageContentWrapper>
         <ModalLimit
            showModalLimit={showModalLimit}
            setShowModalLimit={setShowModalLimit}
            messages={modalLimitMsg}
            onHide={handleCloseModalLimit}
         />
         <ModalSuccess
            msgModal={'Dados criados com sucesso !'}
            showModal={showModalSuccess}
            setShowModal={setShowModalSuccess}
            onClose={handleCloseModalSuccess}
         />
         <Modal
            show={showModalExist}
            onHide={() => setShowModalExist(false)}
            aria-labelledby="contained-modal-warning"
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title className="d-flex align-items-center">
                  <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                  Atenção
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <b>{msgError}</b>
            </Modal.Body>

            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
            </Modal.Footer>
         </Modal>

         <div className={`${classes.container} steppers`}>
            <div className="col-lg-12">
               <div
                     className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                     id='kt_create_account_stepper' style={{ float: "left" }}
               >
                     <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                        <div className='card-body px-6 py-10'>
                           <div className='stepper-nav'>
                                 <div className='stepper-item current' id="product-details" data-kt-stepper-element='nav' onClick={() => changeTab('product-details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                    <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                       <div className='stepper-icon w-40px h-40px'>
                                             <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                       </div>
                                       <div className='stepper-label'>
                                             <h3 className='stepper-title text-break'>Detalhes</h3>
                                             <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o produto/serviço</div>
                                       </div>
                                    </div>
                                    <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                 </div>
                                 <div className='stepper-item' id="product-structure" data-kt-stepper-element='nav' onClick={() => changeTab('product-structure', 'flaticon-settings', previousIcon)} style={{cursor: "pointer"}}>
                                    <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                       <div className='stepper-icon w-40px h-40px'>
                                             <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                             <i className="flaticon-settings icon_change_color" style={{color: "#666"}}></i>
                                       </div>
                                       <div className='stepper-label'>
                                             <h3 className='stepper-title text-break'>Estrutura</h3>
                                             <div className='stepper-desc fw-semibold text-break'>Estrutura do produto/serviço</div>
                                       </div>
                                    </div>
                                    <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                 </div>
                                 <div className='stepper-item' id="nfe" data-kt-stepper-element='nav' onClick={() => changeTab('nfe', 'flaticon-notepad', previousIcon)} style={{cursor: "pointer"}}>
                                    <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                       <div className='stepper-icon w-40px h-40px'>
                                             <i className='stepper-check fas fa-check'></i>
                                             <i className="flaticon-notepad icon_change_color" style={{color: "#666"}}></i>
                                       </div>
                                       <div className='stepper-label'>
                                             <h3 className='stepper-title text-break'>Nota Fiscal</h3>
                                             <div className='stepper-desc fw-semibold text-break'>Nota fiscal do produto/serviço</div>
                                       </div>
                                    </div>
                                    <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                 </div>
                                 <div className='stepper-item' id="variation-products" data-kt-stepper-element='nav' onClick={() => changeTab('variation-products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                    <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                       <div className='stepper-icon w-40px h-40px'>
                                             <i className='stepper-check fas fa-check'></i>
                                             <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                       </div>
                                       <div className='stepper-label'>
                                             <h3 className='stepper-title text-break'>Produto variante</h3>
                                             <div className='stepper-desc fw-semibold text-break'>Informações sobre produto variante</div>
                                       </div>
                                    </div>
                                 </div>
                           </div>
                        </div>
                     </div>               
               </div>
               <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                           <div id="form-buttons"
                              className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                              rounded p-2 position-fixed"
                              style={{ zIndex: 1 }}>
                              <Link to="/produtos">
                                 <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                              </Link>
                              <Button
                                 type="button"
                                 variant="primary"
                                 disabled={isSubmit}
                                 onClick={() => myHandleSubmit()}
                              >
                                 {isSubmit ? <>
                                       <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                       />

                                       <span className="ml-2">
                                          Aguarde...
                                       </span>
                                 </> : <>
                                       <span>
                                          Salvar
                                       </span>
                                 </>}
                              </Button>
                           </div>
                     </Grid>
                  </Grid>

                  <Grid container spacing={3} className="mb-3 mt-18">
                     <Grid item lg={2} md={6} xs={12}>
                        <TextField
                           size="small"
                           id='outlined-select-currency'
                           select
                           label='Tipo'
                           required
                           className={classes.error}
                           SelectProps={{
                              MenuProps: {
                                 className: classes.menu,
                              },
                           }}
                           margin='normal'
                           value={selectedType}
                           onChange={(e) => {
                              setSelectedType(e.target.value);
                              handleChange(e);
                              setTypeInvalid(e.target.value ? false : true)
                           }}
                           error={typeInvalid}
                           disabled={allowChangeType === false}
                        >
                           <MenuItem key='product' value='product'>
                              Produto
                           </MenuItem>

                           <MenuItem key='service' value='service'>
                              Serviço
                           </MenuItem>
                        </TextField>
                     </Grid>

                     <Grid item lg={8} md={6} xs={12}>
                        <TextField
                           size="small"
                           label={`Nome do ${selectedType === 'service' ? 'serviço' : 'produto'} `}
                           className={classes.error}
                           margin='normal'
                           required
                           value={nameProduct}
                           onChange={(e) => {
                              handleChange(e)
                              setNameProduct(e.target.value);
                              setNameInvalid(e.target.value ? false : true)
                           }}
                           onBlur={(e) => {
                              generateCodeProduct(e.target.value);
                           }}
                           error={nameInvalid}
                        />
                        {((errors.name && errors.name.type === 'required') ||
                           (errors.name && errors.name.type === 'pattern')) && (
                              <span className='text-danger pl-3'>
                                 {errors.name.message}
                              </span>
                           )}
                     </Grid>

                     <Grid item lg={2} md={6} xs={12}>
                        <TextField
                           size="small"
                           label={`Código do ${selectedType === 'service' ? 'serviço' : 'produto'}`}
                           margin='normal'
                           value={codeProduct}
                           onChange={(e) => setCodeProduct(e.target.value)}
                           disabled
                        />
                     </Grid>
                  </Grid>

                  {nextButton === 'first' ? (
                     <Link to="/produtos" className="btn btn-secondary mb-2 mr-2 btn-lg">
                           <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                           <span>Voltar</span>
                     </Link>
                  ) : (
                     <Button
                     type="button"
                     variant="outline-primary"
                     onClick={() => backNextOption('back')}
                     className="mb-2 mr-2 btn-lg"
                     >
                           <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                           <span>Voltar</span>
                     </Button>
                  )}
                  <Button
                     type="button"
                     variant={nextButton === 'last' ? 'secondary' : 'primary'}
                     onClick={() => backNextOption('next')}
                     className="mb-2 btn-lg"
                     disabled={nextButton === 'last'}
                  >
                     <span>
                           Avançar
                     </span>&nbsp;
                     <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                  </Button>
                  <hr />

                  <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs" style={{display: "none"}}>
                     <Tab eventKey="product-details" title="Detalhes do Produto">
                        <NewProductDetails
                           situation={situation}
                           setSituation={setSituation}
                           isKit={isKit}
                           provider={provider}
                           setProvider={setProvider}
                           buyValue={buyValue}
                           setBuyValue={setBuyValue}
                           creditIcmsPercent={creditIcmsPercent}
                           setCreditIcmsPercent={setCreditIcmsPercent}
                           freightMoney={freightMoney}
                           setFreightMoney={setFreightMoney}
                           freightPercent={freightPercent}
                           setFreightPercent={setFreightPercent}
                           ipiMoney={ipiMoney}
                           setIpiMoney={setIpiMoney}
                           ipiPercent={ipiPercent}
                           setIpiPercent={setIpiPercent}
                           profitMarginPercent={profitMarginPercent}
                           setProfitMarginPercent={setProfitMarginPercent}
                           profitMarginMoney={profitMarginMoney}
                           setProfitMarginMoney={setProfitMarginMoney}
                           creditIcmsstMoney={creditIcmsstMoney}
                           setCreditIcmsstMoney={setCreditIcmsstMoney}
                           creditIcmsstPercent={creditIcmsstPercent}
                           setCreditIcmsstPercent={setCreditIcmsstPercent}
                           sellerValue={sellerValue}
                           setSellerValue={setSellerValue}
                           calcPrice={calcPrice}
                           selectedType={selectedType}
                           hasVariation={hasVariation}
                           setHasVariation={setHasVariation}
                           creditIcmsMoney={creditIcmsMoney}
                           setCreditIcmsMoney={setCreditIcmsMoney}
                           showModalPrice={showModalPrice}
                           setShowModalPrice={setShowModalPrice}
                           aditionalCost={aditionalCost}
                           setAditionalCost={setAditionalCost}
                           category={category}
                           setCategory={setCategory}
                           subCategory={subCategory}
                           setSubCategory={setSubCategory}
                           categoryProduct={categoryProduct}
                           setCategoryProduct={setCategoryProduct}
                           selectedCategoryProductUuid={selectedCategoryProductUuid}
                           setSelectedCategoryProductUuid={setSelectedCategoryProductUuid}
                           subCategoryProduct={subCategoryProduct}
                           setSubCategoryProduct={setSubCategoryProduct}
                           showModal={showModal}
                           showSubCategoryModal={showSubCategoryModal}
                           setShowModal={setShowModal}
                           setShowSubCategoryModal={setShowSubCategoryModal}
                           addCategory={addCategory}
                           setAddCategory={setAddCategory}
                           showSubCategoryField={showSubCategoryField}
                           setShowSubCategoryField={setShowSubCategoryField}
                           addSubCategory={addSubCategory}
                           setAddSubCategory={setAddSubCategory}
                           rowsPerPage={rowsPerPage}
                           setRowsPerPage={setRowsPerPage}
                           handleChangePage={handleChangePage}
                           page={page}
                           setPage={setPage}
                           handleChange={handleChange}
                           showModalConfirmDelete={showModalConfirmDelete}
                           setShowModalConfirmDelete={setShowModalConfirmDelete}
                           showModalConfirmDeleteSubCategory={showModalConfirmDeleteSubCategory}
                           setShowModalConfirmDeleteSubCategory={setShowModalConfirmDeleteSubCategory}
                           idCategoryToDelete={idCategoryToDelete}
                           setIdCategoryToDelete={setIdCategoryToDelete}
                           indexCategoryToDelete={indexCategoryToDelete}
                           setIndexCategoryToDelete={setIndexCategoryToDelete}
                           saleValueInvalid={saleValueInvalid}
                           setSaleValueInvalid={setSaleValueInvalid}
                           brandProduct={brandProduct}
                           setBrandProduct={setBrandProduct}
                           showBrandModal={showBrandModal}
                           setShowBrandModal={setShowBrandModal}
                           addBrand={addBrand}
                           setAddBrand={setAddBrand}
                           showBrandModalConfirmDelete={showBrandModalConfirmDelete}
                           setShowBrandModalConfirmDelete={setShowBrandModalConfirmDelete}
                           idBrandToDelete={idBrandToDelete}
                           setIdBrandToDelete={setIdBrandToDelete}
                           indexBrandToDelete={indexBrandToDelete}
                           setIndexBrandToDelete={setIndexBrandToDelete}
                           stockInvalid={stockInvalid}
                           setStockInvalid={setStockInvalid}
                           stockMinInvalid={stockMinInvalid}
                           setStockMinInvalid={setStockMinInvalid}
                           stockMaxInvalid={stockMaxInvalid}
                           setStockMaxInvalid={setStockMaxInvalid}
                           stockInitialInvalid={stockInitialInvalid}
                           setStockInitialInvalid={setStockInitialInvalid}
                           typeInvalid={typeInvalid}
                           setTypeInvalid={setTypeInvalid}
                           nameInvalid={nameInvalid}
                           setNameInvalid={setNameInvalid}
                           unitInvalid={unitInvalid}
                           setUnitInvalid={setUnitInvalid}
                           productByGtinEanCodeInvalid={productByGtinEanCodeInvalid}
                           setProductByGtinEanCodeInvalid={setProductByGtinEanCodeInvalid}
                           saleValueApply={saleValueApply}
                           setSaleValueApply={setSaleValueApply}
                           brand={brand}
                           setBrand={setBrand}
                           stockLocation={stockLocation}
                           setStockLocation={setStockLocation}
                           changeTabIcon={changeTabIcon}
                        />
                     </Tab>
                     {variationTabsAvailable && hasVariation === 'y' && (
                        <Tab eventKey='grid' title='Grades'>
                           <Modal
                              show={showModalCreateGrid}
                              onHide={() => { setShowModalCreateGrid(false); setGridNameCreate("") }}
                              aria-labelledby="contained-modal-warning"
                              centered
                           >
                              <Modal.Header closeButton>
                                 <Modal.Title className="d-flex align-items-center">
                                    Adicionar Grade
                                 </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                 <Grid container spacing={3}>
                                    <Grid item md={6}>
                                       <TextField
                                          size="small"
                                          label='Nome da grade'
                                          margin='normal'
                                          value={gridNameCreate}
                                          onChange={(e) => setGridNameCreate(e.target.value)}
                                       />
                                    </Grid>
                                 </Grid>

                                 <Card.Header className="p-3">
                                    Variações
                                 </Card.Header>

                                 {variationCreate.variation.map(({ variationName }, index) => {

                                    return (
                                       <Grid key={index} container spacing={3}>
                                          <Grid item md={6}>
                                             <TextField
                                                size="small"
                                                label='Nome da variação'
                                                margin='normal'
                                                onChange={(e) => changeNameVariationGrid(index, e.target.value)}
                                             />
                                          </Grid>
                                          <Grid item md={2} className="d-flex align-items-center">
                                             <Button variant="link" onClick={() => deleteVariation(index)}><i className="flaticon-delete"></i></Button>
                                          </Grid>
                                       </Grid>
                                    )
                                 })}


                                 <button
                                    type='button'
                                    className='btn btn-link p-0 mt-10'
                                    onClick={() => addVariation()}
                                 >
                                    <ins>+ adicionar variação</ins>
                                 </button>
                              </Modal.Body>
                              <Modal.Footer>
                                 <Button variant="primary" onClick={() => saveGrid()}>Salvar</Button>
                                 <Button variant="secondary" onClick={() => { setShowModalCreateGrid(!showModalCreateGrid); setGridNameCreate("") }}>Fechar</Button>
                              </Modal.Footer>
                           </Modal>

                           <div className='col-lg-12'>
                              <Card.Body>
                                 <div className='d-flex align-items-center'>
                                    {
                                       grid.grids.map(({ gridId, gridName }, index) => {

                                          if (gridName) {
                                             return (
                                                <FormControlLabel
                                                   value='end'
                                                   control={<Checkbox color='primary' onChange={(e) => {
                                                      handleChange(e)
                                                      selectGrid(gridId, gridName, e.target)
                                                   }} />}
                                                   label={gridName}
                                                   labelPlacement='end'
                                                   className='mr-10'
                                                />
                                             )
                                          }

                                       })
                                    }
                                 </div>

                                 <button
                                    type='button'
                                    className='btn btn-link p-0 mt-10'
                                    onClick={() => setShowModalCreateGrid(true)}
                                 >
                                    <ins>+ adicionar grade</ins>
                                 </button>
                              </Card.Body>

                           </div>
                        </Tab>
                     )}

                     {variationTabsAvailable && hasVariation === 'y' && (
                        <Tab eventKey="variation" title="Variações do produto">
                           <div className='col-lg-12'>
                              <Card.Body>
                                 {variationProduct.variation.map((value, index) => {

                                    if (index > 0) {
                                       return (
                                          <Grid container spacing={3} className="pb-3 mb-4 border-bottom">
                                             <Grid item md={12}>
                                                {(nameProduct ?? '') + '-' + (value.grid[0]?.variationName ?? '')}
                                             </Grid>

                                             <Grid container spacing={3} className="mb-1">
                                                {gridSelected.map(({ id, name, variation }) => {
                                                   return (

                                                      <Grid item md={3}>
                                                         <TextField
                                                            size="small"
                                                            select
                                                            label={name}
                                                            SelectProps={{
                                                               MenuProps: {
                                                                  className: classes.menu,
                                                               },
                                                            }}
                                                            value={(variationProduct.variation[index].grid.find(gridObj => gridObj.gridId === id)?.variationId)}
                                                            onChange={e => {
                                                               handleChange(e)
                                                               updateVariationProductGrid(index, id, Number(e.target.value))
                                                               generateCodeVariationProduct(nameProduct, Number(e.target.value), index);
                                                               var variation = variationProduct.variation;
                                                               variation[index].inputsInvalid.grid = e.target.value ? false : true;
                                                            }}
                                                            margin='normal'
                                                            error={value.inputsInvalid.grid}
                                                         >
                                                            <MenuItem value='' disabled>
                                                               Selecione
                                                            </MenuItem>
                                                            {variation.map((value, index2) => {
                                                               if (value.idGrid == id) {
                                                                  return (
                                                                     <MenuItem key={index2} value={value.id}>
                                                                        {value.variationName}
                                                                     </MenuItem>
                                                                  )
                                                               }
                                                            })
                                                            }
                                                         </TextField>
                                                      </Grid>

                                                   )
                                                })
                                                }
                                             </Grid>

                                             <Grid container spacing={3} className="mb-1">

                                                <Grid item md={2}>
                                                   <TextField
                                                      size="small"
                                                      label='Código'
                                                      margin='normal'
                                                      value={value.code}
                                                      onChange={e => updateVariationProduct(index, 'code', e.target.value)}
                                                      disabled
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <ApiResourceSelect
                                                      style={{ width: '100%' }}
                                                      label="Localização no estoque"
                                                      getOptionLabel={(option: StockLocations) => option.name}
                                                      onSelect={(option) => {
                                                         updateVariationProduct(index, 'stockLocation', option?.id ?? 0);
                                                         var variation = variationProduct.variation;
                                                         variation[index].inputsInvalid.stockLocation = option ? false : true;
                                                         changeTabIcon("flaticon-doc");
                                                      }}
                                                      apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                                                      hasError={value.inputsInvalid.stockLocation}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Estoque mínimo"
                                                      required={watch('type') == "service" ? false : true}
                                                      onChange={(e) => {
                                                         updateVariationProduct(index, 'stockMin', e.target.value)
                                                         var variation = variationProduct.variation;
                                                         variation[index].inputsInvalid.stockMin = e.target.value ? false : true;
                                                      }}
                                                      error={value.inputsInvalid.stockMin}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Estoque máximo"
                                                      required={watch('type') == "service" ? false : true}
                                                      onChange={(e) => {
                                                         updateVariationProduct(index, 'stockMax', e.target.value)
                                                         var variation = variationProduct.variation;
                                                         variation[index].inputsInvalid.stockMax = e.target.value ? false : true;
                                                      }}
                                                      error={value.inputsInvalid.stockMax}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Estoque inicial"
                                                      required={watch('type') == "service" ? false : true}
                                                      onChange={(e) => {
                                                         updateVariationProduct(index, 'stockInitial', e.target.value)
                                                         var variation = variationProduct.variation;
                                                         variation[index].inputsInvalid.stockInitial = e.target.value ? false : true;
                                                      }}
                                                      error={value.inputsInvalid.stockInitial}
                                                   />
                                                </Grid>
                                             </Grid>

                                             <Grid container spacing={3}>

                                                <Grid item md={2}>
                                                   <TextField
                                                      margin="normal"
                                                      size="small"
                                                      label="Código GTIN/EAN"
                                                      onChange={e => updateVariationProduct(index, 'codeGtinEan', e.target.value)}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Peso bruto"
                                                      startAdornment="KG"
                                                      onChange={e => updateVariationProduct(index, 'grossWeight', e.target.value)}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Peso líquido"
                                                      startAdornment="KG"
                                                      onChange={e => updateVariationProduct(index, 'liquidWeight', e.target.value)}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Valor de venda *"
                                                      startAdornment="R$"
                                                      onChange={(e) => {
                                                         updateVariationProduct(index, 'saleValue', e.target.value)
                                                         var variation = variationProduct.variation;
                                                         variation[index].inputsInvalid.saleValue = e.target.value ? false : true;
                                                      }}
                                                      error={value.inputsInvalid.saleValue}
                                                   />
                                                </Grid>

                                                <Grid item md={2}>
                                                   <NumericFormat
                                                      label="Valor de custo"
                                                      startAdornment="R$"
                                                      onChange={e => updateVariationProduct(index, 'costValue', e.target.value)}
                                                   />
                                                </Grid>

                                                <Grid item md={2} className="d-flex align-items-center">
                                                   <Button variant="link" onClick={() => deleteVariationProduct(index)}><i className="flaticon-delete"></i></Button>
                                                </Grid>

                                             </Grid>
                                          </Grid>
                                       )
                                    }

                                 })}

                                 <button
                                    type='button'
                                    className='btn btn-link p-0 mt-10'
                                    onClick={() => addVariationProduct()}
                                 >
                                    <ins>+ adicionar variação</ins>
                                 </button>
                              </Card.Body>
                           </div>
                        </Tab>

                     )}

                     {strucutreTabAvailable && hasVariation !== 'y' && (
                        <Tab eventKey="product-structure" title="Estrutura do Produto">
                           <Grid container spacing={3}>
                              <Grid item md={12}>
                                 {hasVariation === 'y' ? (
                                    ''
                                 ) : (
                                    <div className='col-lg-12 p-0'>
                                       <Card.Body className="p-0 pt-3">
                                          <div className='d-flex align-items-center'>
                                             <input
                                                type='checkbox'
                                                className='mr-2'
                                                name='isKit'
                                                style={{ cursor: 'pointer' }}
                                                onChange={() => setIsKit(isKit === "y" ? "n" : "y")}
                                                checked={isKit === "y" ? true : false}
                                             />
                                             <label
                                                htmlFor='isKit'
                                                className='m-0'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setIsKit(isKit === "y" ? "n" : "y")}
                                             >
                                                Produto é um Kit
                                             </label>
                                          </div>

                                          {isKit === "y" ? (
                                             <>
                                                <Grid container spacing={1}>
                                                   {
                                                      productsKit.products.map(({ productId, productName, productCount }, index) => {
                                                         return (
                                                            <Grid key={index} container spacing={1}>
                                                               <Grid item md={4}>
                                                                  <ApiResourceSelect
                                                                     label="Produto"
                                                                     getOptionLabel={(option: { id: number, name: string }) => option.name}
                                                                     onSelect={(option) => {
                                                                        var kit = productsKit.products;

                                                                        kit[index].productId = option?.id ?? 0;
                                                                        kit[index].productName = option?.name ?? '';
                                                                        option && (kit[index].productCount = 1);

                                                                        setProductsKit({ products: kit });
                                                                     }}
                                                                     apiSearchHandler={apiSearchProducts}
                                                                  />
                                                               </Grid>

                                                               <Grid item md={2}>
                                                                  <NumericFormat
                                                                     label="Quantidade"
                                                                     decimalScale={0}
                                                                     thousandSeparator=""
                                                                     value={productCount}
                                                                     onChange={(e) => changeCountProductKit(index, parseInt(e.target.value))}
                                                                  />
                                                               </Grid>

                                                               <Grid item md={2} className="d-flex align-items-center">
                                                                  <Button variant='danger' onClick={() => deleteProductKit(index)}>Excluir</Button>
                                                               </Grid>
                                                            </Grid>
                                                         )
                                                      })
                                                   }

                                                </Grid>


                                                <div className='col-lg-5 pl-0 mt-5'>
                                                   <Button variant='primary' className='mr-3' onClick={() => addProductToKit()}>
                                                      Adicionar outro produto
                                                   </Button>
                                                </div>
                                                <br />
                                                <br />
                                             </>
                                          ) : (
                                             ''
                                          )}
                                       </Card.Body>
                                    </div>
                                 )}
                              </Grid>
                           </Grid>
                           {/* <NewProductStructure
                              hasVariation={hasVariation}
                              isKit={isKit}
                              setKit={setKit}
                              dismemberItems={dismemberItems}
                              setDismemberItems={setDismemberItems}
                              selectProductInKit={selectProductInKit}
                              setSelectProductInKit={setSelectProductInKit}
                           // inputs={inputs}
                              // setInputs={setInputs}
                           /> */}
                        </Tab>
                     )}

                     {nfeTabAvailable && (
                        <Tab eventKey="nfe" title="Nota Fiscal">
                           <NewProductInvoice
                              ncm={ncm}
                              setNcm={setNcm}
                              cestCode={cestCode}
                              setCestCode={setCestCode}
                              icms={icms}
                              setIcms={setIcms}
                              icmsSt={icmsSt}
                              setIcmsSt={setIcmsSt}
                              cst={cst}
                              setCst={setCst}
                              mva={mva}
                              setMva={setMva}
                              mvaAdjusted={mvaAdjusted}
                              setMvaAdjusted={setMvaAdjusted}
                              deferral={deferral}
                              setDeferral={setDeferral}
                              ipi={ipi}
                              setIpi={setIpi}
                              pis={pis}
                              setPis={setPis}
                              cofins={cofins}
                              setCofins={setCofins}
                              federal={federal}
                              setFederal={setFederal}
                              state={state}
                              setState={setState}
                              taxableValue={taxableValue}
                              setTaxableValue={setTaxableValue}
                              ipiCst={ipiCst}
                              setIpiCst={setIpiCst}
                              pisCst={pisCst}
                              setPisCst={setPisCst}
                              cofinsCst={cofinsCst}
                              setCofinsCst={setCofinsCst}
                              handleChange={handleChange}
                           />
                        </Tab>
                     )}


                     {/* <Tab eventKey='fotos-produto' title='Fotos do Produto'>
                        <NewProductPicture />
                     </Tab> */}

                     {variationTabsAvailable && (
                        <Tab eventKey="variation-products" title="Produto Variante">
                           <Grid container spacing={3}>
                              <Grid item md={3} className="pl-7 d-flex align-items-center">
                                 <FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary" />}
                                    label={`${selectedType === 'service' ? 'Serviço' : 'Produto'} variante`}
                                    labelPlacement="end"
                                    {...register('isVariant')}
                                    onChange={(e) => {
                                       setHasVariation(hasVariation === 'y' ? 'n' : 'y');
                                       handleChange(e as React.ChangeEvent<HTMLInputElement>);
                                    }}
                                 />
                              </Grid>
                           </Grid>
                        </Tab>
                     )}
                  </Tabs>
               </div>
            </div>
         </div>
      </MainPageContentWrapper>

   );

}
