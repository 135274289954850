import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, InputAdornment, makeStyles, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency, formatNumberToString } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { Autocomplete } from '@material-ui/lab';
import { formatDate } from '../../../utils/dateFormat';
import { getTypeService } from '../../../utils/getTypeService';
import { ProductsAndServicesTable } from './ProductsAndServicesTable';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { ServiceOrderData } from '../../../types/ServiceOrderData';
import useBackendLoad from '../../../hooks/backendReload';
import { Link } from 'react-router-dom';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: "numberSO", value: "OS" },
    { reference: "customer", value: "Cliente" },
    { reference: "seller", value: "Vendedor" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "installments", value: "Total de Parcelas" },
    { reference: "status", value: "Situação" },
    { reference: "typeService", value: "Tipo de Registro" },
    { reference: "date", value: "Data OS" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "total", value: "Valor Total" },
]

type Customer = {
    id: number;
    name: string;
    corporateName: string;
    typeRegister: "supplier" | "customer";
}

type Filters = {
    customer: number,
    startDateMin: string,
    startDateMax: string,
    ref: string,
    type: string,
    status: string,
    number: string,
    accomplishmentDate: string,
    accomplishmentFinalDate: string,
    date: string,
    finalDate: string,
    products: string,
}


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));

export function ListReportServiceOrder() {
    const [foundServiceOrderPaginate, setFoundServiceOrderPaginate] = useState<any[]>([]);
    const [foundServiceOrder, setFoundServiceOrder] = useState<any[]>([]);
    const [countTotalServiceOrder, setCountTotalServiceOrder] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [customer, setCustomer] = useState<any>('');
    const [customerError, setCustomerError] = useState(false);
    const [showProductsServices, setShowProductsServices] = useState(false);

    const [ref, setRef] = useState<any>('');
    const [type, setType] = useState('all');
    const [number, setNumber] = useState<string>(''); 
    const [status, setStatus] = useState('all');
    const [products, setProducts] = useState('');
    const [startDateMin, setStartDateMin] = useState('');
    const [startDateMax, setStartDateMax] = useState('');
    const [accomplishmentDate, setAccomplishmentDate] = useState('');
    const [accomplishmentFinalDate, setAccomplishmentFinalDate] = useState('');
    const [date, setDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [uTotal, setUTotal] = useState<number>(0);
    const [uDiscount, setUDiscount] = useState<number>(0);
    const [uExpenditure, setUExpenditure] = useState<number>(0);
    const [uTotalOS, setUTotalOS] = useState<number>(0);
    const [sttsOpen, setSttsOpen] = useState<number>(0);
    const [sttsAnswered, setSttsAnswered] = useState<number>(0);
    const [sttsCanceled, setSttsCanceled] = useState<number>(0);
    const [sttsProgress, setSttsProgress] = useState<number>(0);


    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);
    const classes = useStyles();
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundServiceOrderPaginate;
        let exportProductsData: ReportDataRow[] = [];
        let total: number = 0;
        let discount: number = 0;
        let expenditure: number = 0;
        let totalOS: number = 0;
        let sttsObj = {
            open: 0,
            cancelled: 0,
            attended: 0,
            progress: 0,
        };

        aux.forEach((serviceOrder) => {
            const dateOs = serviceOrder.date !== null ? serviceOrder.date.split('-').reverse().join('/') : ' - ';
            const installmentsFormated = JSON.parse(serviceOrder.installments);
            const productsFormated = JSON.parse(serviceOrder.products);
            const servicesFormated = JSON.parse(serviceOrder.services);
            const customer = serviceOrder.customerEntity.length 
                            ? (serviceOrder.customerEntity[0].typePeople == "physical" ? serviceOrder.customerEntity[0].name : serviceOrder.customerEntity[0].corporateName)
                            : ' - ';
            const seller = serviceOrder.sellerEntity.length 
                            ? (serviceOrder.sellerEntity[0].typePeople == "physical" ? serviceOrder.sellerEntity[0].name : serviceOrder.sellerEntity[0].corporateName)
                            : ' - ';
            const typeService = serviceOrder.type && serviceOrder.type !== null ? getTypeService(serviceOrder.type) : ' - ';

            const data: BodyDataBaseProps[] = [
                {
                    for: "numberSO",
                    value: String(serviceOrder.numberSO),
                    jsx: <Link to={`/ordem-de-servico/${serviceOrder.id}`}>{serviceOrder.id}</Link>,
                },
                { for: "customer", value: customer },
                { for: "seller", value: seller },
                { for: "totalValue", value: serviceOrder.totalValue ? formatCurrency(serviceOrder.totalValue) : formatCurrency(0)},
                { for: "installments", value: String(installmentsFormated.length) },
                { for: "status", value: getSituationText(serviceOrder.status) },
                { for: "typeService", value: typeService },
                { for: "date", value: dateOs },
            ];

            if (showProductsServices !== false) {
                data.push(
                    {
                        for: "products/services",
                        value: '',
                        secondaryRow: true,
                        jsx: <ProductsAndServicesTable products={productsFormated} services={servicesFormated} typeService={typeService} />
                    },
                );
            };

            list.push(data);
        });

        foundServiceOrder.forEach((serviceOrder) => {
            const dateOs = serviceOrder.date !== null ? serviceOrder.date.split('-').reverse().join('/') : ' - ';
            const productsFormated = JSON.parse(serviceOrder.products);
            const servicesFormated = JSON.parse(serviceOrder.services);
            const installmentsFormated = JSON.parse(serviceOrder.installments);
            const customer = serviceOrder.customerEntity.length 
                            ? (serviceOrder.customerEntity[0].typePeople == "physical" ? serviceOrder.customerEntity[0].name : serviceOrder.customerEntity[0].corporateName)
                            : ' - ';
            const seller = serviceOrder.sellerEntity.length 
                            ? (serviceOrder.sellerEntity[0].typePeople == "physical" ? serviceOrder.sellerEntity[0].name : serviceOrder.sellerEntity[0].corporateName)
                            : ' - ';
            const typeService = serviceOrder.type && serviceOrder.type !== null ? getTypeService(serviceOrder.type) : ' - ';

            const data: BodyDataBaseProps[] = [
                { for: "numberSO", value: String(serviceOrder.numberSO) },
                { for: "customer", value: customer },
                { for: "seller", value: seller },
                { for: "totalValue", value: serviceOrder.totalValue ? formatCurrency(serviceOrder.totalValue) : formatCurrency(0)},
                { for: "installments", value: String(installmentsFormated.length) },
                { for: "status", value: getSituationText(serviceOrder.status) },
                { for: "typeService", value: typeService },
                { for: "date", value: dateOs },
            ];

            total += Number(serviceOrder.totalValue);
            discount = discount !== null ? Number(serviceOrder.discount) : discount = 0;
            expenditure = expenditure !== null ? Number(serviceOrder.expenditure) : expenditure = 0;
            totalOS = total - (discount + expenditure);
            
            if(serviceOrder.status === 'open'){
                
                sttsObj.open = sttsObj.open + 1;

            }else if (serviceOrder.status === 'attended'){

                sttsObj.attended = sttsObj.attended + 1;

            }else if (serviceOrder.status === 'canceled'){

                sttsObj.cancelled = sttsObj.cancelled + 1;

            }else if (serviceOrder.status === 'progress'){

                sttsObj.progress = sttsObj.progress + 1;

            };

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            if (showProductsServices !== false) {
                exportProductsData = productsFormated.map((product: any) => {
                    return {
                        cells: [
                            {for: 'name', content: product.INSTANCE.name ?? '-'},
                            {for: 'type', content: typeService},
                        ],
                    };
                });

                exportProductsData = [
                    ...exportProductsData,
                    ...servicesFormated.map((service: any) => {
                        return {
                            cells: [
                                {for: 'name', content: service.INSTANCE.name ?? '-'},
                                {for: 'type', content: typeService},
                            ],
                        };
                    })
                ]

            };

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setExportTotals([
            { name: 'Total de Ordens', value: foundServiceOrder.length },
            { name: 'Valor total Serviços', value: 'R$ ' + formatNumberToString(total) },
            { name: 'Valor total Descontos', value: 'R$ ' + formatNumberToString(discount) },
            { name: 'Valor total Despesas', value: 'R$ ' + formatNumberToString(expenditure) },
            { name: 'Valor total OS', value: 'R$ ' + formatNumberToString(totalOS) },
            { name: 'Em Aberto', value: String(sttsObj.open) },
            { name: 'Atendido', value: String(sttsObj.attended) },
            { name: 'Cancelado', value: String(sttsObj.cancelled) },
            { name: 'Em Andamento', value: String(sttsObj.progress) },
        ]);

        setUTotal(total);
        setUDiscount(discount);
        setUExpenditure(expenditure);
        setUTotalOS(totalOS);

        setSttsOpen(sttsObj.open);
        setSttsAnswered(sttsObj.attended);
        setSttsCanceled(sttsObj.cancelled);
        setSttsProgress(sttsObj.progress);

        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundServiceOrderPaginate, foundServiceOrder]);

    const clearSearch = () => {
        setRef('')
        setProducts('all');
        setStatus('all');
        setCustomer('');
        setShowProductsServices(false);
        setType('all');
        setStartDateMin('');
        setStartDateMax('');
        setAccomplishmentDate('');
        setAccomplishmentFinalDate('');
        setDate('');
        setFinalDate('');
        setNumber('');
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: ServiceOrderData[], count:number}>("/report/serviceOrder", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows} = data;

        const serviceOrder = await api.get("/report/serviceOrder", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundServiceOrderPaginate(data.rows ? rows : []);

        setFoundServiceOrder(serviceOrder.data);
        setCountTotalServiceOrder(serviceOrder.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customer,
            startDateMin,
            startDateMax,
            ref,
            type: type == "all" ? "" : type,
            status: status == "all" ? "" : status,
            number,
            accomplishmentDate,
            accomplishmentFinalDate,
            date,
            finalDate,
            products,
        };
        reloadData();

    }, [
        customer,
        startDateMin,
        startDateMax,
        ref,
        type,
        status,
        number,
        accomplishmentDate,
        accomplishmentFinalDate,
        date,
        finalDate,
        products
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Ordem de Serviço', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">

                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                        onChangeTextField={(e) => setCustomerError(e.target.value ? false : true)}
                        textFieldClass={classes.error}
                        hasError={customerError}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data OS"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={startDateMin}
                        onChange={(e) => setStartDateMin(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data OS"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={startDateMax}
                        onChange={(e) => setStartDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Cadastro"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Cadastro"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={finalDate}
                        onChange={(e) => setFinalDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Referência"
                        margin="normal"
                        value={ref}
                        onChange={(e) => setRef(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo de Registro"
                        margin="normal"
                        value={type}
                    >
                        <MenuItem key="0" value="all" onClick={() => setType('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="serviceOrder" onClick={() => setType('serviceOrder')}>
                            Ordem de Serviço
                        </MenuItem>

                        <MenuItem key="2" value="budget" onClick={() => setType('budget')}>
                            Orçamento
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="progress" onClick={() => setStatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="3" value="attended" onClick={() => setStatus('attended')}>
                            Finalizado
                        </MenuItem>

                        <MenuItem key="4" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Número"
                        margin="normal"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Realização"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={accomplishmentDate}
                        onChange={(e) => setAccomplishmentDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Realização"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={accomplishmentFinalDate}
                        onChange={(e) => setAccomplishmentFinalDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-10" ></div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProductsServices}
                                onChange={(_, checked) => setShowProductsServices(checked)}
                            />
                        }
                        label="Exibir os Produtos/Serviços"
                        labelPlacement="end"
                    />
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Ordem de Serviços"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Ordem de Serviços', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalServiceOrder}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                    defaultSortReference="numberSO"
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de Ordens</TableCell>
                                    <TableCell colSpan={1}>{foundServiceOrder.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Produtos</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotal) }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Descontos</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uDiscount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Despesas</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uExpenditure)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total OS</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotalOS)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Em Aberto</TableCell>
                                    <TableCell colSpan={1}>{sttsOpen}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Atendido</TableCell>
                                    <TableCell colSpan={1}>{sttsAnswered}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Cancelado</TableCell>
                                    <TableCell colSpan={1}>{sttsCanceled}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Em Andamento</TableCell>
                                    <TableCell colSpan={1}>{sttsProgress}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}