import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { paymentOptions } from '../../../utils/paymentOptions';
import '../../../style.css';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { BankAccount } from '../../../hooks/bankAccount';
import { NumericFormat } from '../../../components/NumericFormat';
import { CenterCost } from '../../../hooks/parameters';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import { SubCategory } from '../../../types/Dre';
import { BillsToPay } from '../../../types/BillsToPay';
import { getSituationText } from '../../../utils/getSituationText';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import DreCategoryService from '../../../services/DreCategoryService';
import BankAccountService from '../../../services/BankAccountService';
import CenterCostService from '../../../services/CenterCostService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { formatStringDateToLocale, formatDate } from '../../../utils/dateFormat';
import { extractDateStringFromTimestamp } from '../../../utils/dateTimeHelper';
import { Link } from 'react-router-dom';
import { ReportDetails } from '../../../components/ReportDetails';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportBillsResponse = {
    rows: BillsToPay[];
    count: number;
    totalValue: number;
    totalDownloaded: number;
    totalFees: number;
    totalAddition: number;
    totalDiscount: number;
    totalTax: number;
    totalPaid: number;
    totalToPay: number;
    totalLate: number;
    totalToday: number;
    totalFuture: number;
}

type Filters = {
    situation: string;
    dreCategory: string;
    bankAccount: string;
    dueDateMin: string;
    dueDateMax: string;
    issueDateMin: string;
    issueDateMax: string;
    valueMin: number;
    valueMax: number;
    typePayment: string;
    centerCost: string;
    provider: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'N°' },
    { reference: 'dueDate', value: 'Vencimento' },
    { reference: 'payedDate', value: 'Data Paga' },
    { reference: 'issueDate', value: 'Emissão' },
    { reference: 'name', value: 'Nome da Despesa' },
    { reference: 'accountBank', value: 'Conta Bancária' },
    { reference: 'supplier', value: 'Fornecedor' },
    { reference: 'category', value: 'Categoria' },
    { reference: 'payment', value: 'Forma de Pagamento' },
    { reference: 'centerCost', value: 'Centro de Custo' },
    { reference: 'status', value: 'Situação' },
    { reference: 'originalValue', value: 'Valor Original' },
    { reference: 'downloadedValue', value: 'Valor Baixado' },
    { reference: 'paidValue', value: 'Valor Pago' },
];

export function ListReportBillsToPay() {
    const [billsToPay, setBillsToPay] = useState<any[]>([]);
    const [countTotalBillsToPayPaginated, setCountTotalBillsToPayPaginated] = useState(0);
    const [countTotalBillsToPay, setCountTotalBillsToPay] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [situation, setSituation] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [dueDateMin, setDueDateMin] = useState("");
    const [dueDateMax, setDueDateMax] = useState("");
    const [issueDateMin, setIssueDateMin] = useState("");
    const [issueDateMax, setIssueDateMax] = useState("");
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [typePayment, setTypePayment] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [provider, setProvider] = useState("");
    const [dreCategory, setDreCategory] =  useState('');

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalOriginal, setTotalOriginal] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [totalDownloaded, setTotalDownloaded] = useState(0);
    const [paymentsLate, setPaymentsLate] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [paymentsToday, setPaymentsToday] = useState(0);
    const [totalAddition, setTotalAddition] = useState(0);
    const [paymentsFuture, setPaymentsFuture] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportBillsResponse>("/report/billsToPay", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalValue,
            totalDownloaded,
            totalFees,
            totalAddition,
            totalDiscount,
            totalTax,
            totalPaid,
            totalToPay,
            totalLate,
            totalToday,
            totalFuture
        } = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        })

        setExportTotals([
            { name: 'Quantidade de Contas', value: count },
            { name: 'Valor Original Total', value: formatCurrency(totalValue) },
            { name: 'Valor Total Baixado', value: formatCurrency(totalDownloaded) },
            { name: 'Valor Total Juros', value: formatCurrency(totalFees) },
            { name: 'Valor Total Acréscimos', value: formatCurrency(totalAddition) },
            { name: 'Valor Total Descontos', value: formatCurrency(totalDiscount) },
            { name: 'Valor Total Taxas', value: formatCurrency(totalTax) },
            { name: 'Valor Total Pago', value: formatCurrency(totalPaid) },
            { name: 'Valor Total a Pagar', value: formatCurrency(totalToPay) },
            { name: 'Pagamentos Atrasados', value: formatCurrency(totalLate) },
            { name: 'Pagamentos para Hoje', value: formatCurrency(totalToday) },
            { name: 'Pagamentos Futuros', value: formatCurrency(totalFuture) },
        ])

        setExportBodyData(exportDataList);
        setCountTotalBillsToPay(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportBillsResponse>("/report/billsToPay", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalValue,
            totalDownloaded,
            totalFees,
            totalAddition,
            totalDiscount,
            totalTax,
            totalPaid,
            totalToPay,
            totalLate,
            totalToday,
            totalFuture
        } = data;

        setBillsToPay(rows);
        setCountTotalBillsToPayPaginated(count);
        setTotalOriginal(totalValue);
        setTotalDownloaded(totalDownloaded);
        setTotalFees(totalFees);
        setTotalAddition(totalAddition);
        setTotalDiscount(totalDiscount);
        setTotalTax(totalTax);
        setTotalPaid(totalPaid);
        setTotalToPay(totalToPay);
        setPaymentsLate(totalLate);
        setPaymentsToday(totalToday);
        setPaymentsFuture(totalFuture);

        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = billsToPay;

        aux.forEach((bill) => {
            const data = mountBodyDataRow(bill);

            list.push(data);
        });

        setBodyData(list);
    }, [billsToPay]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: any) {
        const data: BodyDataBaseProps[] = [
            {
                for: "id",
                value: reportRow.id ?? '-',
                jsx: <Link to={`/contas-a-pagar/${reportRow.id ?? '0'}`}>{reportRow.id ?? '-'}</Link>,
            },
            { for: "dueDate", value: formatStringDateToLocale(reportRow.dueDate) ?? '-' },
            { for: "payedDate", value: formatStringDateToLocale(reportRow.payedDate) ?? '-' },
            { for: "issueDate", value: formatStringDateToLocale(extractDateStringFromTimestamp(reportRow.issueDate)) ?? '-' },
            { for: "name", value: reportRow.name },
            { for: "accountBank", value: reportRow.bankAccountEntity ? reportRow.bankAccountEntity.name : '-' },
            { for: "supplier", value: CustomerService.getCustomerName(reportRow.customerEntity) ?? '-' },
            { for: "category", value: DreCategoryService.getDreSubCategoryName(reportRow.dreSubCategoryEntity) ?? '-' },
            { for: "payment", value: reportRow.payment ?? '-' },
            { for: "centerCost", value: CenterCostService.getCenterCostName(reportRow.centerCostEntity) ?? '-' },
            { for: "status", value: getSituationText(reportRow.status) ?? '-' },
            { for: "originalValue", value: formatCurrency(reportRow.amount) },
            { for: "downloadedValue", value: formatCurrency(reportRow.remaining) },
            { for: "paidValue", value: formatCurrency(reportRow.totalPaid) },
        ];

        return data;
    }

    const clearSearch = () => {
        setSituation("");
        setDreCategory("");
        setBankAccount("");
        setDueDateMin("");
        setDueDateMax("");
        setIssueDateMin("");
        setIssueDateMax("");
        setValueMin(0);
        setValueMax(0);
        setTypePayment("");
        setCenterCost("");
        setProvider("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            situation,
            dreCategory,
            bankAccount,
            dueDateMin,
            dueDateMax,
            issueDateMin,
            issueDateMax,
            valueMin,
            valueMax,
            typePayment,
            centerCost,
            provider
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        situation,
        dreCategory,
        bankAccount,
        dueDateMin,
        dueDateMax,
        issueDateMin,
        issueDateMax,
        valueMin,
        valueMax,
        typePayment,
        centerCost,
        provider
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Contas a Pagar', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        value={situation}
                    >
                        <MenuItem key="0" value="all" onClick={() => setSituation('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="pending" onClick={() => setSituation('pending')}>
                            Pendente
                        </MenuItem>

                        <MenuItem key="2" value="late" onClick={() => setSituation('late')}>
                            Vencido
                        </MenuItem>

                        <MenuItem key="3" value="paid" onClick={() => setSituation('paid')}>
                            Pago
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Plano de Contas"
                        getOptionLabel={(option: SubCategory) => option.name}
                        value={ dreCategory }
                        onSelect={(option) => setDreCategory(String(option?.id) ?? '')}
                        apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                        getSelectedOption={(loadedOptions) => {
                            if(!dreCategory) return null;
                            return loadedOptions.find((option) => option.id === Number(dreCategory)) ?? DreCategoryService.getDreSubCategoryById(dreCategory)
                        }}
                    />
                </div>
                <div className="col-lg-4 d-flex align-items-center">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={bankAccount}
                        onSelect={(option) => setBankAccount(String(option?.id ?? ''))}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!bankAccount) return null;
                            return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data Vencimento"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMin}
                        onChange={(e) => setDueDateMin(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data Vencimento"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMax}
                        onChange={(e) => setDueDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={issueDateMin}
                        onChange={(e) => setIssueDateMin(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={issueDateMax}
                        onChange={(e) => setIssueDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <NumericFormat
                        label="Valor Total"
                        className="inputSmaller"
                        customInput={TextField}
                        withPrefix={false}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={valueMin}
                        onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
                    />
                </div>

                <div className="col-lg-2">
                    <NumericFormat
                        label="Valor Total"
                        className="inputSmaller"
                        customInput={TextField}
                        withPrefix={false}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={valueMax}
                        onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        label="Forma de Pagamento"
                        margin="normal"
                        size="small"
                        value={typePayment}
                    >
                        <MenuItem key="0" value="">
                            Selecione
                        </MenuItem>

                        {
                            paymentOptions.map((payment, index) => (
                                <MenuItem key={index + 1} value={payment.value} onClick={() => setTypePayment(payment.value)}>
                                    {payment.value}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Centro de custos"
                        getOptionLabel={(option: CenterCost) => option.name}
                        onSelect={(option) => setCenterCost(String(option?.id ?? ''))}
                        value={centerCost}
                        apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!centerCost) return null;
                            return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                        }}
                    />
                </div>

                <div className="col-lg-4 d-flex align-items-center">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Fornecedor"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={provider}
                        onSelect={(option) => setProvider(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!provider) return null;
                            return loadedOptions.find((option) => option.id === Number(provider)) ?? CustomerService.getCustomerById(provider)
                        }}
                    />
                </div>
                
                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Contas a Pagar"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Contas a Pagar', extension: 'pdf' })}
                    >
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                        >
                            <i className="flaticon2-printer"></i>
                            Exportar PDF
                        </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3" style={{ overflow: "auto" }}>
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalBillsToPayPaginated}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card">
                    <div className="card-body row">
                        <ReportDetails
                            bodyData={[
                                { text: 'Quantidade de Contas', value: String(countTotalBillsToPay) },
                                { text: 'Valor Original Total', value: formatCurrency(totalOriginal) },
                                { text: 'Valor Total Baixado', value: formatCurrency(totalDownloaded) },
                                { text: 'Valor Total Juros', value: formatCurrency(totalFees) },
                                { text: 'Valor Total Acréscimos', value: formatCurrency(totalAddition) },
                                { text: 'Valor Total Descontos', value: formatCurrency(totalDiscount) },
                                { text: 'Valor Total Taxas', value: formatCurrency(totalTax) },
                            ]}
                            classes={
                                { col: 'col-lg-6' }
                            }
                        />
                        <ReportDetails
                            bodyData={[
                                { text: 'Valor Total Pago', value: formatCurrency(totalPaid) },
                                { text: 'Valor Total a Pagar', value: formatCurrency(totalToPay) },
                                { text: 'Pagamentos Atrasados', value: formatCurrency(paymentsLate) },
                                { text: 'Pagamentos para Hoje', value: formatCurrency(paymentsToday) },
                                { text: 'Pagamentos Futuros', value: formatCurrency(paymentsFuture) },
                            ]}
                            classes={
                                { col: 'col-lg-6' }
                            }
                        />
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    )
}