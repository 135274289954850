import React, { FormEvent, useCallback, useLayoutEffect, useState } from 'react';
import {
    Link,
    Radio,
    MenuItem,
    TextField,
    FormLabel,
    makeStyles,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
import { Button } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';


type ConditionPayment = {
    name: string;
    payment: string;
    interval: number;
    interest: number;
    status: "active" | "inactive";
    firstPaymentInstallment: number;
    amountPaymentInstallment: number;
    interestType: "simple" | "compound";
};


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

export function EditConditionPayment() {
    const [name, setName] = useState('');
    const [payment, setPayment] = useState('');
    const [interval, setInterval] = useState(0);
    const [interest, setInterest] = useState(0);
    const [status, setStatus] = useState<"active" | "inactive">("active");
    const [firstPaymentInstallment, setFirstPaymentInstallment] = useState(0);
    const [amountPaymentInstallment, setAmountPaymentInstallment] = useState(0);
    const [interestType, setInterestType] = useState<"simple" | "compound">("simple");

    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();

    subHeader.setTitle("Editar Condição de Pagamento");

    useLayoutEffect(() => {
        async function loadData() {
            const response = await api.get<ConditionPayment>(`conditionPayment/${id}`);
            const data = response.data;

            setName(data.name);
            setStatus(data.status);
            setPayment(data.payment);
            setInterval(data.interval);
            setInterest(data.interest);
            setInterestType(data.interestType);
            setFirstPaymentInstallment(data.firstPaymentInstallment);
            setAmountPaymentInstallment(data.amountPaymentInstallment);
        }

        loadData();
    }, []);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        try {
            const data: ConditionPayment = {
                name,
                status,
                payment,
                interval,
                interest,
                interestType,
                firstPaymentInstallment,
                amountPaymentInstallment,
            };

            await api.put(`conditionPayment/${id}`, data);

            history.push("/parametros/condicoes-de-pagamento");
        } catch (error) {
            console.log(error);
        }
    };
    
    return (
        <MainPageContentWrapper>
            <form className={classes.container} onSubmit={(evt) => onSubmit(evt)}>
                <div className="row col-lg-12">
                    <div className="col-lg-6">
                        <TextField
                            required
                            label="Nome"
                            margin="normal"
                            placeholder="Apelido para a condição. EX: 30 / 60 / 90, 10 DIAS."

                            value={name}
                            onChange={(evt) => setName(evt.target.value)}
                        />
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            select
                            label="Forma de Pagamento"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"

                            value={payment}
                            onChange={(evt) => setPayment(evt.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>

                            {
                                paymentOptions.map((payment, index) => (
                                    <MenuItem key={index + 1} value={payment.value}>
                                        {payment.value}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-3"> 
                        <TextField
                            select
                            label="Status"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"

                            value={status}
                            onChange={({ target: { value } }) => setStatus(value === "Ativo" ? "active" : "inactive")}
                        >
                            <MenuItem key="0" value="active">
                                Ativo
                            </MenuItem>

                            <MenuItem key="1" value="inactive">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div> 
                </div> 

                <div className="row col-lg-12">
                    <div className="col-lg-3"> 
                        <TextField
                            type="number"
                            label="Intervalo"
                            margin="normal"
                            placeholder="Entre Parcelas"

                            value={interval}
                            onChange={(evt) => setInterval(Number(evt.target.value))}
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            type="number"
                            label="Primeira Parcela"
                            margin="normal"
                            placeholder="Dias para a primeira parcela"

                            value={firstPaymentInstallment}
                            onChange={(evt) => setFirstPaymentInstallment(Number(evt.target.value))}
                        />
                    </div>

                    <div className="col-lg-3"> 
                        <TextField
                            type="number"
                            label="Qtde. de Parcelas"
                            margin="normal"
                            required

                            value={amountPaymentInstallment}
                            onChange={(evt) => setAmountPaymentInstallment(Number(evt.target.value))}
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            type="number"
                            label="Juros"
                            margin="normal"
                            placeholder="%"

                            value={interest}
                            onChange={(evt) => setInterest(Number(evt.target.value))}
                        />
                    </div>
                </div>

                <div className="row col-lg-12">
                    <div className="col-lg-5 d-flex align-items-center"> 
                        <FormControl component="fieldset" className="ml-3 mt-3">
                            <FormLabel component="legend">Percentual de Juros</FormLabel>
                            <RadioGroup
                                row
                                value={interestType}
                                onChange={
                                    ({ target: { value } }) => setInterestType(value === "simple" ? "simple" : "compound")
                                }
                            >
                                <FormControlLabel
                                    value="simple"
                                    label="Simples"
                                    labelPlacement="end"
                                    control={
                                        <Radio color="primary" />
                                    }
                                />

                                <FormControlLabel
                                    value="compound"
                                    label="Composto"
                                    labelPlacement="end"
                                    control={
                                        <Radio color="primary" />
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    <Button type="submit" variant="primary" className="mr-3">Salvar</Button>
                    <Link href="/parametros/condicoes-de-pagamento" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}