import { Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Badge, Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ModalError from '../../components/ModalError';
import { useCompanyBranch } from '../../hooks/companyBranch';
import api from '../../services/Api';
import { StockLocations } from '../../types/StockLocations';
import { getDate } from '../../utils/dateTimeHelper';
import { formatCpfCnpjDynamic } from '../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { freightModalityById, freightModalityKeyById } from '../../utils/freightModality';
import { textDanger, textMuted, textSuccess } from '../../utils/styles';
import UploadXml from './UploadXml';
import ModalSuccess from '../../components/ModalSuccess';
import EntryService, { NfCarrier, NfInformation, NfIssuer, NfProduct } from '../../services/EntryService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import StockLocationService from '../../services/StockLocationService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function ImportXmlEntry() {
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [isSubmittingFile, setIsSubmittingFile] = useState(false);
    const [isSubmittingConfirmation, setIsSubmittingConfirmation] = useState(false);

    const [activeTab, setActiveTab] = useState("supplier");

    const [issuerExists, setIssuerExists] = useState(false);
    const [issuer, setIssuer] = useState<NfIssuer>();
    const [products, setProducts] = useState<NfProduct[]>([]);
    const [carrier, setCarrier] = useState<NfCarrier>();
    const [carrierExists, setCarrierExists] = useState(false);

    const [launchInStock, setLaunchInStock] = useState(false);
    const [stockLocationIdForNewProducts, setStockLocationIdForNewProducts] = useState<number | null>(null);
    const [stockLocationIdForNewProductsError, setStockLocationIdForNewProductsError] = useState(false);

    const [nfInformation, setNfInformation] = useState<NfInformation>();
    const [uploadedXmlFile, setUploadedXmlFile] = useState<File>();
    const uploadedXmlRef = useRef<HTMLInputElement>(null);

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { selectedCompany } = useCompanyBranch();

    const handleClickUploadXml = useCallback(async () => {
        setIsSubmittingFile(true);

        const xmlFile = uploadedXmlRef?.current?.files ? uploadedXmlRef.current.files[0] : null;

        if (!xmlFile) {
            setMsgError('Selecione um arquivo');
            setShowModalError(true);
            setIsSubmittingFile(false);
            return;
        }

        if (xmlFile.type !== 'text/xml') {
            setMsgError('Arquivo enviado é inválido! Por favor selecione um arquivo .XML válido');
            setShowModalError(true);
            setIsSubmittingFile(false);
            return;
        }

        try {
            const content = await xmlFile.text();
            const fullXmlInfo = await EntryService.readEntryInformationFromXml(content, selectedCompany);

            setNfInformation(fullXmlInfo.generalInfo);
            setIssuer(fullXmlInfo.issuer);
            setIssuerExists(fullXmlInfo.issuerExists);
            setCarrier(fullXmlInfo.carrier);
            setCarrierExists(fullXmlInfo.carrierExists);
            setProducts(fullXmlInfo.products);

            setUploadedXmlFile(xmlFile);
            setIsSubmittingFile(false);
        } catch (error: any) {
            setMsgError(error);
            setShowModalError(true);
            setIsSubmittingFile(false);
            return;
        }
    }, [selectedCompany]);

    function getStatusStyle(statusCode: number) {
        if (statusCode && statusCode >= 100 && statusCode <= 151) {
            return textSuccess;
        } else if (statusCode && statusCode >= 201 && statusCode <= 999) {
            return textDanger;
        } else {
            return textMuted;
        }
    }

    function handleChangeReplaceProductId(value: any, productCode: string) {
        setProducts((prevState) => (
            prevState.map((product) => {
                if (product.code !== productCode) {
                    return product;
                }

                return {
                    ...product,
                    replaceProductId: value?.id ?? null,
                }
            })
        ));
    }

    function handleCancelImport() {
        setIssuerExists(false);
        setIssuer(undefined);
        setProducts([]);
        setCarrier(undefined);
        setCarrierExists(false);
        setLaunchInStock(false);
        setStockLocationIdForNewProducts(null);
        setNfInformation(undefined);
        setUploadedXmlFile(undefined);
        setActiveTab("supplier");
    }

    async function onSubmit() {
        if (!issuer || !nfInformation || !uploadedXmlFile) {
            setIsSubmittingConfirmation(false);
            setShowModalError(true);
            setMsgError('XML invalido');
            return;
        }

        if (!inputsVerify()) {
            setIsSubmittingConfirmation(false);
            setShowModalError(true);
            return;
        }

        try {
            await EntryService.importEntryFromXml({
                xmlFile: uploadedXmlFile,
                extractedXmlInformation: {
                    generalInfo: nfInformation,
                    issuer,
                    issuerExists,
                    carrier,
                    carrierExists,
                    products,
                },
                stockLocationId: stockLocationIdForNewProducts,
                launchInStock,
                selectedCompany,
            });

            setIsSubmittingConfirmation(false);

            setShowModalSuccess(true);
        } catch (error) {
            setIsSubmittingConfirmation(false);
            setMsgError('Ocorreu um erro ao realizar a importação');
            setShowModalError(true);
        }
    }

    function inputsVerify(): boolean {
        setStockLocationIdForNewProductsError(false);

        if (!stockLocationIdForNewProducts) {
            setActiveTab("products");
            setMsgError('Selecione um local de estoque');
            setStockLocationIdForNewProductsError(true);
            return false;
        }

        return true;
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalSuccess
                msgModal="Importação realizada com sucesso!"
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/entrada-de-mercadoria"
            />
            <div className="card card-custom gutter-b">
                <div className="card-header row m-0 pt-2 pb-3" style={{ minHeight: '10px' }}>
                    <div className="col-lg-6">
                        <h4 className="card-label m-0 mt-2">
                            Importação por XML
                        </h4>
                    </div>
                    {!!uploadedXmlFile && !!nfInformation && (
                        <div className="col-lg-6 d-flex justify-content-end mb-3 pr-0">
                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmittingConfirmation}
                                onClick={onSubmit}
                            >
                                {isSubmittingConfirmation ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-2">
                                            Aguarde...
                                        </span>

                                    </>
                                    :
                                    <span>Importar</span>
                                }
                            </Button>

                            <Button
                                size="lg"
                                variant="secondary"
                                className="ml-3"
                                onClick={handleCancelImport}
                            >
                                Cancelar
                            </Button>
                        </div>
                    )}
                </div>
                <div className="card-body mt-0 pt-0">
                    {!uploadedXmlFile && (
                        <UploadXml
                            uploadedXmlRef={uploadedXmlRef}
                            isSubmittingFile={isSubmittingFile}
                            handleClickUploadXml={handleClickUploadXml}
                        />
                    )}

                    {!!uploadedXmlFile && !!nfInformation && (
                        <>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="text-danger">* Registros sem cadastro no sistema serão inseridos após a confirmação da importação</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-5">
                                    <strong>Natureza da operação</strong>
                                    <p className="mt-2">{nfInformation.nature}</p>
                                </div>
                                <div className="col-lg-2">
                                    <strong>Série/Número</strong>
                                    <p className="mt-2">{`${nfInformation.nfSeries}/${nfInformation.nfNumber}`}</p>
                                </div>
                                <div className="col-lg-2">
                                    <strong>Data de emissão</strong>
                                    <p className="mt-2">{getDate({ initialDate: nfInformation.issuanceDate }).fullDateStr}</p>
                                </div>
                                <div className="col-lg-3">
                                    <strong>Versão do aplicativo</strong>
                                    <p className="mt-2">{nfInformation.version}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <strong>Chave de Acesso</strong>
                                    <p className="mt-2">{nfInformation.accessKey}</p>
                                </div>
                                <div className="col-lg-2">
                                    <strong>Protocolo</strong>
                                    <p className="mt-2">{nfInformation.protocol}</p>
                                </div>
                                <div className="col-lg-2">
                                    <strong>Data de recebimento</strong>
                                    <p className="mt-2">{getDate({ initialDate: nfInformation.entryDate }).fullDateStr}</p>
                                </div>
                                <div className="col-lg-3">
                                    <strong>Status</strong>
                                    <p
                                        style={getStatusStyle(nfInformation.status.code)}
                                        className="mt-2"
                                    >
                                        {`${nfInformation.status.code}/${nfInformation.status.description}`}
                                    </p>
                                </div>
                            </div>

                            <Tabs
                                activeKey={activeTab}
                                onSelect={(tab: string) => setActiveTab(tab)}
                                id='nf-tabs'
                            >
                                <Tab
                                    eventKey='supplier'
                                    title='Dados do Emitente'
                                >
                                    {issuer && (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <strong>Razão Social</strong>
                                                    <p className="mt-2">{issuer.corporateName}</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <strong>Nome Fantasia</strong>
                                                    <p className="mt-2">{issuer.fantasyName}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-4">
                                                    <strong>CNPJ/CPF</strong>
                                                    <p className="mt-2">{formatCpfCnpjDynamic(issuer.cpfCnpj)}</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <strong>Inscrição estadual</strong>
                                                    <p className="mt-2">{issuer.stateRegistration}</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <strong>Telefone</strong>
                                                    <p className="mt-2">{formatPhoneNumber(issuer.phone)}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <strong>Endereço</strong>
                                                    <p className="mt-2">{issuer.address}</p>
                                                </div>
                                                <div className="col-lg-2">
                                                    <strong>Número</strong>
                                                    <p className="mt-2">{issuer.number}</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <strong>Cidade/UF</strong>
                                                    <p className="mt-2">{`${issuer.city} / ${issuer.uf}`}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <strong>Bairro</strong>
                                                    <p className="mt-2">{issuer.district}</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <strong>CEP</strong>
                                                    <p className="mt-2">{issuer.zipcode}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    {issuerExists && (
                                                        <span className="text-success">
                                                            <Badge pill variant="primary" className="mr-3" style={{ color: "#fff", background: "green" }}>
                                                                <i className="flaticon2-check-mark" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                            <span style={{ color: "green" }}>Fornecedor Cadastrado</span>
                                                        </span>
                                                    )}
                                                    {!issuerExists && (
                                                        <span className="text-primary">
                                                            <Badge pill variant="warning" className="mr-3 text-light">
                                                                <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                            <span>Fornecedor não está cadastrado no sistema</span>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Tab>

                                <Tab
                                    eventKey='products'
                                    title='Produtos'
                                >
                                    <div className="row mt-3 align-items-center">
                                        <div className="col-lg-4">
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={launchInStock}
                                                        onChange={(_, checked) => setLaunchInStock(checked)}
                                                    />
                                                }
                                                label="Lançar no estoque"
                                                labelPlacement="end"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <ApiResourceSelect
                                                label="Local do estoque para lançamentos *"
                                                getOptionLabel={(option: StockLocations) => option.name}
                                                value={stockLocationIdForNewProducts ?? ''}
                                                hasError={stockLocationIdForNewProductsError}
                                                onSelect={(option) => setStockLocationIdForNewProducts(option?.id ?? 0)}
                                                apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                                                getSelectedOption={(loadedOptions) => {
                                                    if(!stockLocationIdForNewProducts) return null;
                                                    return loadedOptions.find((option) => option.id === Number(stockLocationIdForNewProducts)) ?? StockLocationService.getStockLocationById(stockLocationIdForNewProducts)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {products.map((product, index) => (
                                        <div key={product.code} className="card card-body p-3 mt-3">
                                            <div className="row mt-3">
                                                <div className="col-md-1">
                                                    <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-3 mt-2">
                                                        <label className="text-white m-0">{index + 1}.</label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <strong>Código</strong>
                                                            <p className="mt-2">{product.code}</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <strong>Produto</strong>
                                                            <p className="mt-2">{product.name}</p>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <strong>NCM</strong>
                                                            <p className="mt-2">{product.ncm}</p>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <strong>CFOP</strong>
                                                            <p className="mt-2">{product.cfop}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <strong>Unidade</strong>
                                                            <p className="mt-2">{product.unit}</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <strong>Quantidade</strong>
                                                            <p className="mt-2">{product.quantity}</p>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <strong>Valor unitário</strong>
                                                            <p className="mt-2">{product.saleValue}</p>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <strong>Valor total</strong>
                                                            <p className="mt-2">{product.totalValue}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3 align-items-center">
                                                        <div className="col-lg-6">
                                                            {product.productExists && (
                                                                <span className="text-success">
                                                                    <Badge pill variant="primary" className="mr-3" style={{ color: "#fff", background: "green" }}>
                                                                        <i className="flaticon2-check-mark" style={{ color: "#fff" }}></i>
                                                                    </Badge>
                                                                    <span style={{ color: "green" }}>Produto Cadastrado</span>
                                                                </span>
                                                            )}
                                                            {!product.productExists && (
                                                                <span className="text-primary">
                                                                    <Badge pill variant="warning" className="mr-3 text-light">
                                                                        <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                                                    </Badge>
                                                                    <span>Produto não está cadastrado no sistema</span>
                                                                </span>
                                                            )}
                                                        </div>
                                                        {/* {!product.productExists && (
                                                            <div className="col-lg-6">
                                                                <Autocomplete
                                                                    size="small"
                                                                    style={{ width: "100%" }}
                                                                    noOptionsText="Sem opções"
                                                                    options={hookProducts}
                                                                    getOptionLabel={prod => prod.name}
                                                                    value={hookProducts.find(option => option.id === product.replaceProductId) ?? { id: 0, name: '' }}
                                                                    onChange={(evt, value) => handleChangeReplaceProductId(value, product.code)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            size="small"
                                                                            {...params}
                                                                            label="Substituir um produto já cadastrado"
                                                                            margin="normal"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </Tab>

                                <Tab
                                    eventKey='totals'
                                    title='Totais da Entrada'
                                >
                                    <div className="row mt-3">
                                        <div className="col-lg-2">
                                            <strong>BC de ICMS</strong>
                                            <p className="mt-2">{nfInformation.bcIcms}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Valor do ICMS</strong>
                                            <p className="mt-2">{nfInformation.valueIcms}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>BC de ICMS-ST</strong>
                                            <p className="mt-2">{nfInformation.bcIcmsSt}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Valor do ICMS-ST</strong>
                                            <p className="mt-2">{nfInformation.valueIcmsSt}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Valor do IPI</strong>
                                            <p className="mt-2">{nfInformation.valueIpi}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Valor dos produtos</strong>
                                            <p className="mt-2">{nfInformation.valueProducts}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Valor do frete</strong>
                                            <p className="mt-2">{nfInformation.freight}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Desconto</strong>
                                            <p className="mt-2">{nfInformation.discount}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Peso Bruto</strong>
                                            <p className="mt-2">{nfInformation.grossWeight}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Peso Líquido</strong>
                                            <p className="mt-2">{nfInformation.liquidWeight}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            <strong>Total da nota</strong>
                                            <p style={textSuccess} className="mt-2">{nfInformation.totalValue}</p>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='carrier'
                                    title='Transporte'
                                >
                                    <div className="row mt-3">
                                        <div className="col-lg-4">
                                            <strong>Modalidade de frete</strong>
                                            <p className="mt-2">{freightModalityById(nfInformation.modalityFreight)}</p>
                                        </div>
                                    </div>
                                    {!!carrier && (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <strong>Nome da transportadora</strong>
                                                    <p className="mt-2">{carrier.name}</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <strong>CNPJ/CPF</strong>
                                                    <p className="mt-2">{formatCpfCnpjDynamic(carrier.cpfCnpj)}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    {carrierExists && (
                                                        <span className="text-success">
                                                            <Badge pill variant="primary" className="mr-3" style={{ color: "#fff", background: "green" }}>
                                                                <i className="flaticon2-check-mark" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                            <span style={{ color: "green" }}>Transportadora Cadastrada</span>
                                                        </span>
                                                    )}
                                                    {!carrierExists && (
                                                        <span className="text-primary">
                                                            <Badge pill variant="warning" className="mr-3 text-light">
                                                                <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                            <span>Transportadora não está cadastrada no sistema</span>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Tab>
                            </Tabs>
                        </>
                    )}
                </div>
            </div>
        </MainPageContentWrapper>
    );
}