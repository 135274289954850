import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { Search } from '../../components/Search';
import useBackendLoad from '../../hooks/backendReload';
import api from '../../services/Api';
import { Profile } from '../../types/Profile';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "name", value: "Nome" },
];

export function ListProfiles() {
    const { user } = useSelector((state: any) => state.auth);

    
    const { location: { pathname }, push: pushHistory } = useHistory();

    const [data, setData] = useState<Profile[]>([]);
    const [countTotalProfiles, setCountTotalProfiles] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Profile[], count: number}>('profiles', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setData(rows);
        setCountTotalProfiles(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [data]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = data;
    
        aux.forEach((item) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(item.id), id: true },
                { for: "name", value: item.name },
            ];
    
            list.push(data);
        });
    
        setBodyData(list);
    }, [data]);

    const handleClickDelete = useCallback(async (id: string) => {
        await api.delete(`profiles/${id}`);
        const aux = data.filter(profile => String(profile.id) !== id);

        setData([...aux]);
    }, [data]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = { searchQuery };

        reloadData();
    }, [data, searchQuery]);

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2 text-dark"
                            onClick={() => handleClickAdd()}
                        >
                            <i className="fas fa-plus text-dark"></i>
                            Adicionar
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>

			<div className="mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
					onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalProfiles}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
		</div>
    );
}