import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { format } from 'date-fns';
import { Button, Modal, Spinner, Tabs, Tab } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Grid, MenuItem, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import { useSubheader } from "../../../_metronic/layout";
import api from '../../services/Api';
import { Installment } from './Installment';
import { ListProducts } from './ListProducts';
import { InstallmentDetails, Product, useProductsInstallments } from './context';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { UnderlineLink } from '../../components/UnderlineLink';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import '../../style.css';
import LogService from '../../services/LogService';
import { useLinkedFieldsError } from '../../hooks/linkedFieldsError';
import ModalLinkedFieldsError from '../../components/ModalLinkedFieldsError';
import CustomerSelect from '../../components/CustomerSelect';
import { ServiceOrderData } from '../../types/ServiceOrderData';
import { yesOrNo } from '../../types/yesOrNo';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import SellerService from '../../services/SellerService';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { useStyles } from '../../hooks/styles';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import CustomerService from '../../services/CustomerService';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import useSellerUser from '../../hooks/sellerUser';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function NewServiceOrder() {
    const [activeTab, setActiveTab] = useState("services");

    const [type, setType] = useState("");
    const [warranty, setWarranty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [comments, setComments] = useState("");
    const [referencies, setReferencies] = useState("");
    const [internalComments, setInternalComments] = useState("");
    const [accomplishmentDate, setAccomplishmentDate] = useState("");
    const [accomplishmentTime, setAccomplishmentTime] = useState("");
    const [issues, setIssues] = useState("");
    const [report, setReport] = useState("");
    const [equipment, setEquipment] = useState("");
    const [receivement, setReceivement] = useState("");
    const [date, setDate] = useState('');
    const [numberSO, setNumberSO] = useState(0);
    const [customerId, setCustomerId] = useState<number>();
    const [customerName, setCustomerName] = useState("");
    const [seller, setSeller] = useState("");
    const [sellerCommissioning, setSellerCommissioning] = useState(false);
    const [installmentAmount, setInstallmentAmount] = useState(0);

    const [discount, setDiscount] = useState("R$0,00");
    const [totalValue, setTotalValue] = useState("R$0,00");
    const [expenditure, setExpenditure] = useState("R$0,00");
    const [totalValueServices, setTotalValueServices] = useState("R$0,00");
    const [totalValueProducts, setTotalValueProducts] = useState("R$0,00");

    const [paymentInvalid, setPaymentInvalid] = useState<boolean>(false);
    const [servicesInvalid, setServicesInvalid] = useState<boolean>(false);
    const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-suitcase");

    // Input Verify
    const [customerError, setCustomerError] = useState(false);
    const [sellerError, setSellerError] = useState(false);
    const [installmentsError, setInstallmentsError] = useState(false);
    const [productsError, setProductsError] = useState(false);
    const [servicesError, setServicesError] = useState<any>();
    const [accomplishmentDateError, setAccomplishmentDateError] = useState(false);

    const {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    } = useLinkedFieldsError();

    const [isSubmit, setIsSubmit] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();

    const { idToClone } = useParams<{ idToClone: string }>();
    const classes = useStyles();
    const history = useHistory();
    const { userSellerInfo } = useSellerUser();
    const { register, handleSubmit } = useForm();
    const {
        products,
        services,
        installments,
        dispatchProducts,
        dispatchServices,
        dispatchInstallments,
    } = useProductsInstallments();

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomerId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product, isService?: boolean } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
        if (customerId) {
            const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
            setCustomerLimitCreditInformation(limitCreditInfo);
        } else {
            setCustomerLimitCreditInformation(undefined);
        }
    }, []);

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        if (productBeingCreated.isService) {
            dispatchServices({
                type: "CHANGE",
                payload: {
                    index: productBeingCreated.index,
                    product: {
                        ...productBeingCreated.product,
                        isLinked: true,
                        name: createdProduct.name,
                        amount,
                        unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                        totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
                        INSTANCE: selectableProduct,
                    },
                }
            });
        } else {
            dispatchProducts({
                type: "CHANGE",
                payload: {
                    index: productBeingCreated.index,
                    product: {
                        ...productBeingCreated.product,
                        isLinked: true,
                        name: createdProduct.name,
                        amount,
                        unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                        totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
                        INSTANCE: selectableProduct,
                    },
                }
            });
        }
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product, isService?: boolean) => {
        setNewProductDefaultData({ nameProduct: typedText, selectedType: isService ? 'service' : 'product' });
        setProductBeingCreated({ index, product, isService })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        if (userSellerInfo) {
            setSeller(String(userSellerInfo.id));
        }
    }, [userSellerInfo]);

    useLayoutEffect(() => {
        setDate(format(new Date(), 'yyyy-MM-dd'));
    }, []);

    useEffect(() => {
        async function getNewNumberSO() {
            const lastNumber = await api.get(`service-order/lastNumberSO`);
            setNumberSO(Number(lastNumber.data.lastNumber) + 1);
        }

        getNewNumberSO();
    }, []);

    useEffect(() => {
        const totalService = services.reduce((acc, { totalValue }) => acc + totalValue, 0);
        const totalProduct = products.reduce((acc, { totalValue }) => acc + totalValue, 0);
        const discountNumber = formatToFloat(discount);
        const expenditureNumber = formatToFloat(expenditure);

        const partial = totalService + totalProduct + expenditureNumber;

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setTotalValueServices(formatCurrency(totalService));
            setTotalValueProducts(formatCurrency(totalProduct));

            setDiscount("R$0,00");
            setTotalValue("R$0,00");

            return;
        }

        const total = partial - discountNumber;

        setTotalValueServices(formatCurrency(totalService));
        setTotalValueProducts(formatCurrency(totalProduct));
        setTotalValue(formatCurrency(total));
    }, [services, products, expenditure, discount]);

    useEffect(() => {
        if (!idToClone) return;

        async function getOrderToCloneData() {
            const { data: orderToClone } = await api.get<ServiceOrderData>(`service-order/${idToClone}`);

            if (!orderToClone) return;

            const services: Product[] = JSON.parse(orderToClone.services);
            const products: Product[] = JSON.parse(orderToClone.products);
            const installments: InstallmentDetails[] = JSON.parse(orderToClone.installments);

            dispatchServices({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: services,
                }
            });

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products,
                },
            });

            // dispatchInstallments({
            //     type: "INITIAL",
            //     payload: {
            //         installments,
            //     }
            // });

            const discountData = formatCurrency(orderToClone.discount);
            const totalValueData = formatCurrency(orderToClone.totalValue);
            const expenditureData = formatCurrency(orderToClone.expenditure);

            setType(orderToClone.type ?? '');
            setIssues(orderToClone.issues ?? '');
            setReport(orderToClone.report ?? '');
            setSeller(String(orderToClone.seller));
            setDiscount(discountData ?? '');
            setNumberSO(orderToClone.numberSO + 1);
            setCustomerId(orderToClone.customerId ?? undefined);
            setCustomerName(orderToClone.customerName ?? '');
            setComments(orderToClone.comments ?? '');
            setWarranty(orderToClone.warranty ?? '');
            setEquipment(orderToClone.equipment ?? '');
            setTotalValue(totalValueData ?? '');
            setExpenditure(expenditureData ?? '');
            setReceivement(orderToClone.receivement ?? '');
            setReferencies(orderToClone.referencies ?? '');
            // setStartDate(orderToClone.startDate ?? '');
            setDeliveryDate(orderToClone.deliveryDate ?? '');
            setInternalComments(orderToClone.internalComments ?? '');
            setInstallmentAmount(orderToClone.installmentAmount ?? '');
            setAccomplishmentDate(orderToClone.accomplishmentDate ?? '');
            setAccomplishmentTime(orderToClone.accomplishmentTime ?? '');
            setSellerCommissioning(orderToClone.sellerCommissioning === 'y');

            handleCustomerChanged(orderToClone.customerId ?? undefined, orderToClone.customerName);
        }

        getOrderToCloneData();
    }, [idToClone]);

    async function onSubmit(data: any) {
        // Situação que aparece na listagem
        const situation: OrdersSituation[] = [];

        if (!inputsError()) return;

        situation.push({
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
            commentsSituation: "",
            statusSituation: "open",
        });

        try {
            const raw = {
                date,
                type: data.type ? data.type : 'serviceOrder',
                deliveryDate,
                warranty,
                accomplishmentDate,
                accomplishmentTime,
                referencies,
                comments,
                internalComments,
                equipment,
                issues,
                receivement,
                report,
                numberSO,
                customerId: customerId,
                customerName: !customerId ? customerName : null,
                seller: seller ? Number(seller) : null,
                sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
                status: "open",
                installmentAmount,
                discount: formatToFloat(discount),
                totalValue: formatToFloat(totalValue),
                expenditure: formatToFloat(expenditure),

                products: JSON.stringify(products),
                services: JSON.stringify(services),
                situation: JSON.stringify(situation),
                installments: JSON.stringify(installments),
            };

            const result = await api.post("service-order", raw);

            history.push("/ordem-de-servico");

            LogService.logRegister({
                itemId: result.data.id,
                module: 'Ordem de Serviço',
                itemName: 'Serviço'
            });

        } catch (error) {
            console.log(error);
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalExist(true);
        }
    };

    useEffect(() => {
        dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchProducts({ type: "ADD" });
        dispatchServices({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchServices({ type: "ADD" });
        dispatchInstallments({
            type: "GENERATE",
            payload: {
                amount: 0,
                value: 0,
            }
        });
    }, []);

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "cell" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if (type === "email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    function inputsError() {
        setSellerError(false);
        setProductsError(false);
        setAccomplishmentDateError(false);
        setCustomerError(false);
        setInstallmentsError(false);
        for (let i = 0; i < services.length; i++) {
            const service = services[i];

            setServiceError(i, service, false);
        }
        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            setProductError(i, product, false);
        }

        if (!customerId && !customerName) {
            setIsSubmit(false)
            setMsgError("Campo Cliente é obrigatório!")
            setShowModalExist(true);
            setCustomerError(true);
            return false
        }

        if (customerId && customerLimitCreditInformation) {
            const totalOrder = formatToFloat(totalValue ?? 0);

            if ((totalOrder + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
                // Se salvar a OS ultrapassar o limite de crédito do cliente
                setMsgError("Não é possível salvar a ordem de serviço pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
                setShowModalExist(true);
                setCustomerError(true);
                return false;
            }
        }

        // if(!seller){
        //     setIsSubmit(false)
        //     setMsgError("Campo Vendedor é obrigatório!")
        //     setShowModalExist(true);
        //     setSellerError(true);
        //     return false
        // }

        if (accomplishmentDate) {
            const dateIsValid = dateIsBetweenRange(accomplishmentDate, '', extractDateStringFromTimestamp());
            if (!dateIsValid) {
                setMsgError("A data de emissão não pode ser maior que o horário atual!");
                setAccomplishmentDateError(true);
                setActiveTab("detailsServiceOrder");
                setShowModalExist(true);
                setIsSubmit(false)
                return false;
            }
        }

        const hasServices = services.find((service) => service.name || service.INSTANCE.id);

        if (!hasServices) {
            setActiveTab("services");
            setMsgError("Selecione pelo menos 1(um) Serviço!");
            setShowModalExist(true);
            return false;
        }

        for (let i = 0; i < services.length; i++) {
            const service = services[i];

            if (service.totalValue && !service.INSTANCE?.id && !service.name) {
                setServiceError(i, service, true);
                setMsgError('Informe um serviço!');
                setActiveTab('services');
                setShowModalExist(true);
                return false;
            }
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.totalValue && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalExist(true);
                return false;
            }
        }

        if (!installments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas do pedido!");
            setShowModalExist(true);
            setInstallmentsError(true);
            return false;
        }

        const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

        if (sumInstallments !== formatToFloat(totalValue)) {
            setActiveTab("payment");
            setMsgError("A soma das parcelas deve concidir com o valor total da ordem de serviço!");
            setShowModalExist(true);
            setInstallmentsError(true);
            return false;
        }

        return true;
    }

    function setServiceError(index: number, service: Product, error?: boolean) {
        dispatchServices({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...service,
                    hasError: error,
                },
            }
        });
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const isValid = (data: any) => {
        setProductsError(data)
        console.log(productsError)
    };

    const isValid2 = (data: any) => {
        setServicesError(data);
    };

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar um Cliente / Fornecedor");

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-suitcase") {
                const hasServices = services.find((service) => service.name || service.INSTANCE.id);

                if (hasServices) {
                    const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-delete');
                    
                    if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                    } else {
                    prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                    }

                    setServicesInvalid(false);
                } else {
                    const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-check-mark');

                    setServicesInvalid(true);

                    if (current_icon) {
                        current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                        current_icon.classList.replace("text-success", "text-danger");
                    } else {
                        prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-delete");
                        prev_icon?.classList.add("text-danger");
                    }
                }
        } else if (previousIcon === "flaticon-open-box") {
            const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

            if (hasProducts) {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setProductsInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

                setProductsInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else if (previousIcon === "flaticon-list-1") {
            if (installments.length) {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-list-1", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setPaymentInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-check-mark');

                setPaymentInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-list-1", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        }
        else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('services')?.classList.remove('current');
        document.getElementById('products')?.classList.remove('current');
        document.getElementById('detailsServiceOrder')?.classList.remove('current');
        document.getElementById('totalServiceOrder')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('attachment')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'services') {
                setNextButton('first');
        } else if (tab == 'attachment') {
                setNextButton('last');
        } else {
                setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['services', 'products', 'detailsServiceOrder', 'totalServiceOrder', 'payment', 'attachment'];
        let arr2 = ['flaticon-suitcase', 'flaticon-open-box', 'flaticon-doc', 'flaticon-coins', 'flaticon-list-1', 'flaticon-tool-1'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
            <ModalLinkedFieldsError
                message="Para lançar em contas é necessário completar o cadastro do Pedido!"
                errors={linkedFieldsErrors}
                showModal={showModalLinkedFieldsError}
                setShowModal={setShowModalLinkedFieldsError}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="services" data-kt-stepper-element='nav' onClick={() => changeTab('services', 'flaticon-suitcase', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-suitcase icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Serviços</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Serviços da ordem de serviço</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Produtos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Produtos da ordem de serviço</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="detailsServiceOrder" data-kt-stepper-element='nav' onClick={() => changeTab('detailsServiceOrder', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes da ordem de serviço</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="totalServiceOrder" data-kt-stepper-element='nav' onClick={() => changeTab('totalServiceOrder', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Totais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Totais da ordem de serviço</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Pagamento</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Pagamento da ordem de serviço</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="attachment" data-kt-stepper-element='nav' onClick={() => changeTab('attachment', 'flaticon-tool-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-tool-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Anexos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Anexos da ordem de serviço</div>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/ordem-de-servico">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

                            <Grid item lg={3} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    select
                                    label="Tipo da Os"
                                    margin="normal"
                                    defaultValue="serviceOrder"
                                    value={type}
                                    onChange={(event) => setType(event.target.value)}
                                >
                                    <MenuItem value="serviceOrder">
                                        Ordem de serviço
                                    </MenuItem>

                                    <MenuItem value="budget">
                                        Orçamento
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <CustomerSelect
                                    label="Cliente"
                                    hasError={customerError}
                                    isEdit={false}
                                    customerId={customerId}
                                    setCustomerId={setCustomerId}
                                    customerName={customerName}
                                    setCustomerName={setCustomerName}
                                    onCustomerChanged={handleCustomerChanged}
                                    allowIncomplete
                                    onClickAddCustomer={handleClickAddCustomer}
                                />
                            </Grid>

                            <Grid item lg={3} md={6} xs={12}>
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    hasError={sellerError}
                                    textFieldClass={classes.error}
                                    onChangeTextField={(e) => setSellerError(e.target.value ? false : true)}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    disabled
                                    type='text'
                                    size="small"
                                    margin="normal"
                                    label="N° da OS"
                                    value={numberSO}
                                />
                            </Grid>

                            <Modal
                                show={showModalSuccess}
                                onHide={() => {
                                    setShowModalSuccess(false);
                                }}
                                aria-labelledby="contained-modal-warning"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="d-flex align-items-center">
                                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                                        Sucesso
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <b>Dados criados com sucesso !</b>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => {
                                        setShowModalSuccess(!showModalSuccess);
                                        window.location.href = "/ordem-de-servico/adicionar";
                                    }}>Fechar</Button>
                                </Modal.Footer>
                            </Modal>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/ordem-de-servico" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' style={{ display: "none" }}>
                            <Tab eventKey='services' title='Serviços'>
                                {
                                    servicesInvalid && (
                                        <p className='text-danger'>Selecione pelo menos 1(um) serviço!</p>
                                    )
                                }
                                {services.map((service, index) => (
                                    <ListProducts
                                        key={index}
                                        index={index}
                                        product={service}
                                        service
                                        isContent={(contentExists: boolean) => isValid2(contentExists)}
                                        onClickAddProduct={handleClickAddProduct}
                                        changeTabIcon={changeTabIcon}
                                    />
                                ))
                                }

                                <UnderlineLink text="adicionar outro serviço" onClick={() => dispatchServices({ type: "ADD" })} />
                            </Tab>

                            <Tab eventKey='products' title='Produtos'>
                                {
                                    productsInvalid && (
                                        <p className='text-danger'>Informe um produto!</p>
                                    )
                                }
                                {products.map((product, index) => (
                                    <ListProducts
                                        key={index}
                                        index={index}
                                        product={product}
                                        isContent={(contentExists: boolean) => isValid(contentExists)}
                                        onClickAddProduct={handleClickAddProduct}
                                        changeTabIcon={changeTabIcon}
                                    />
                                ))
                                }

                                <UnderlineLink text="adicionar outro produto" onClick={() => dispatchProducts({ type: "ADD" })} />
                            </Tab>

                            <Tab eventKey='detailsServiceOrder' title='Detalhes da OS'>
                                <div className="card-body px-3">
                                    <Grid container spacing={6}>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                disabled
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Data da OS"
                                                InputLabelProps={{ shrink: true }}
                                                value={date}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='datetime-local'
                                                margin="normal"
                                                label="Previsão data de início"
                                                InputLabelProps={{ shrink: true }}
                                                value={startDate}
                                                onChange={(event) => setStartDate(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='datetime-local'
                                                margin="normal"
                                                label="Data da entrega"
                                                InputLabelProps={{ shrink: true }}
                                                value={deliveryDate}
                                                onChange={(event) => setDeliveryDate(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='text'
                                                margin="normal"
                                                label="Garantia"
                                                value={warranty}
                                                onChange={(event) => setWarranty(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Data da realização"
                                                InputLabelProps={{ shrink: true }}
                                                value={accomplishmentDate}
                                                onChange={(event) => setAccomplishmentDate(event.target.value)}
                                                error={accomplishmentDateError}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='text'
                                                margin="normal"
                                                label="Hora da realização"
                                                value={accomplishmentTime}
                                                onChange={(event) => setAccomplishmentTime(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='text'
                                                margin="normal"
                                                label="Referência"
                                                value={referencies}
                                                onChange={(event) => setReferencies(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Observações"
                                                value={comments}
                                                onChange={(event) => setComments(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Observações internas"
                                                value={internalComments}
                                                onChange={(event) => setInternalComments(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Equipamento"
                                                value={equipment}
                                                onChange={(event) => setEquipment(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Problemas"
                                                value={issues}
                                                onChange={(event) => setIssues(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Observações do recebimento"
                                                value={receivement}
                                                onChange={(event) => setReceivement(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Laudo técnico / Diagnóstico"
                                                value={report}
                                                onChange={(event) => setReport(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab eventKey='totalServiceOrder' title='Totais da Ordem de Serviço'>
                                <div className="card-body px-3">
                                    <Grid container spacing={5}>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                disabled
                                                label="Valor dos serviços"
                                                value={totalValueServices}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                disabled
                                                label="Valor dos produtos"
                                                value={totalValueProducts}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor das despesas"

                                                value={expenditure}
                                                onChange={(evt) => setExpenditure(evt.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor do desconto"

                                                value={discount}
                                                onChange={(evt) => setDiscount(evt.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                disabled
                                                label="Total"
                                                value={totalValue}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab eventKey='payment' title='Pagamento'>
                                <div className="card-body px-3">
                                    {
                                        paymentInvalid && (
                                            <p className='text-danger'>Informe as parcelas do pedido!</p>
                                        )
                                    }
                                    <div className="row">
                                        <Grid item lg={6} md={6} xs={12}>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={sellerCommissioning}
                                                        onChange={(e, checked) => setSellerCommissioning(checked)}
                                                    />
                                                }
                                                label="Emitir comissionamento (vendedor)"
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    </div>
                                    <div className="row">
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                type="number"
                                                size="small"
                                                margin="normal"
                                                label="Quantidade de Parcelas"
                                                error={installmentsError}
                                                className={classes.error}
                                                value={installmentAmount}
                                                onChange={(evt) => {
                                                    setInstallmentAmount(Number(evt.target.value))
                                                    setInstallmentsError(evt.target.value ? false : true)
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={3} xs={12}>
                                            <Button
                                                variant="primary"
                                                className="btn-sm"
                                                block
                                                onClick={() => {
                                                    dispatchInstallments({
                                                        type: "GENERATE",
                                                        payload: {
                                                            amount: installmentAmount,
                                                            value: formatToFloat(totalValue),
                                                        }
                                                    });
                                                    changeTabIcon('flaticon-list-1')
                                                }}
                                            >
                                                Gerar parcelas
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {
                                                installments.length > 0 && installments.map((installment, index) => (
                                                    <Installment
                                                        key={index}
                                                        index={index}
                                                        installment={installment}
                                                        installmentAmount={installmentAmount}
                                                    />
                                                ))
                                            }
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey='attachment' title='Anexo'>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">
                                            Proposta/Anexos
                                        </label>

                                        <input className="form-control" type="file" id="formFile" />

                                        <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                                    </Grid>
                                </Grid>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}