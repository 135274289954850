import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import "../../../../app/style.css";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .min(3, "Mínimo de 3 caracteres")
      .max(50, "Máximo de 50 caracteres")
      .required('Campo obrigatório!'),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => {
          setIsRequested(true);
          setStatus('');
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            'Email não cadastrado na base de dados'
          );
        });
    },
  });

  return (
    <>
      {/* {isRequested && <Redirect to="/auth" />} */}
      {(
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="mb-8">
            <h2 className="font-weight-boldest">Esqueceu sua senha?</h2>
            <div className="font-size-h6 text-muted font-weight-bolder">
              Digite seu email para redefinir sua senha
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {isRequested && (
              <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
                <div className="alert-text font-weight-bold">
                  Foi enviado um email para redefinição da sua senha.
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <div>
                <label htmlFor="email" className="font-weight-bolder">Email</label>
                <input
                  id="email"
                  type="email"
                  className={`custom-form-shadow form-control rounded-lg h-auto py-4 pr-10 ${getInputClasses(
                    "email"
                  )}`}
                  placeholder="Digite o email"
                  name="email"
                  {...formik.getFieldProps("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-boldest rounded-2xl px-9 py-4 mx-3"
                disabled={formik.isSubmitting}
              >
                Enviar
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-outline-primary font-weight-boldest rounded-2xl px-9 py-4 mx-3"
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
