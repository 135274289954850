import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {
    makeStyles, Grid, MenuItem, TextField,
    FormControlLabel,
    Checkbox, IconButton, Tooltip as MaterialTooltip, Zoom
} from '@material-ui/core';

import api from '../../services/Api';
import { api as apiUrl } from '../../services/ApiURL';
import { ListProducts } from './ListProducts';
import { Product, useProductsInstallments } from './context';

import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { UnderlineLink } from '../../components/UnderlineLink';

import '../../style.css';
import { ServiceContractData, frequency as frequencyEnum } from '../../types/ServiceContractData';
import { yesOrNo } from '../../types/yesOrNo';
import { CompanyNfseActivity } from '../../types/CompanyNfseActivity';
import { extractDateStringFromTimestamp, getLastDayOfMonth } from '../../utils/dateTimeHelper';
import ModalError from '../../components/ModalError';
import { arrayLastItem } from '../../utils/arrayHelper';
import { frequencyToMonths } from '../../utils/frequencyToMonths';
import LogService from '../../services/LogService';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import { getBillType } from '../../utils/getBillType';
import { SubCategory } from '../../types/Dre';
import ModalSuccess from '../../components/ModalSuccess';
import { EmailToCustomer, ModalSendEmail } from '../../components/ModalSendEmail';
import { ModalSendWhatsapp, WhatsappToCustomer } from '../../components/ModalSendWhatsapp';
import { EmailsHistory } from '../../types/EmailsHistory';
import { WhatsappHistory } from '../../types/WhatsappHistory';
import EmailServiceContractService from '../../services/EmailServiceContractService';
import WhatsappServiceContractService from '../../services/WhatsappServiceContractService';
import { useSelector } from 'react-redux';
import CustomerSelect from '../../components/CustomerSelect';
import CenterCostService from '../../services/CenterCostService';
import BankAccountService from '../../services/BankAccountService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import { CenterCost } from '../../types/CenterCost';
import DreCategoryService from '../../services/DreCategoryService';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import useAddAccountBank from '../../hooks/addAccountBank';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { bankNumberIsInter } from '../../utils/bankNumberIsInter';
import BillToReceiveService from '../../services/BillToReceiveService';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    cnpjError: {
        marginLeft: theme.spacing(1),
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    popper: {
        width: 'fit-content !important',
        zIndex: 999,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        },
    },
}));

export function EditServiceContract() {
    const { user } = useSelector((state: any) => state.auth);

    const [forceUpdate, setForceUpdate] = useState(false);

    const [activeTab, setActiveTab] = useState("services");

    const [description, setDescription] = useState("");
    const [warranty, setWarranty] = useState("");
    const [comments, setComments] = useState("");
    const [internalComments, setInternalComments] = useState("");
    const [frequency, setFrequency] = useState("");
    const [references, setReferences] = useState("");
    const [categoryName, setCategoryName] = useState("");

    const [numberSO, setNumberSO] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [customer, setCustomer] = useState<number>();
    const [seller, setSeller] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [nextPaymentDate, setNextPaymentDate] = useState("");
    const [expireDay, setExpireDay] = useState("");
    const [daysBeforeExpire, setDaysBeforeExpire] = useState("5");
    const [billType, setBillType] = useState("account");
    const [accountBank, setAccountBank] = useState("");
    const [selectedBankAccountIsInter, setSelectedBankAccountIsInter] = useState(false);
    const [sellerCommissioning, setSellerCommissioning] = useState(false);
    const [generateNfse, setGenerateNfse] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [sendWhats, setSendWhats] = useState(false);
    const [readjustmentPercentage, setReadjustmentPercentage] = useState("");
    const [readjustmentPeriod, setReadjustmentPeriod] = useState(0);
    const [nextReadjustmentDate, setNextReadjustmentDate] = useState("");
    const [nextReadjustmentPercentage, setNextReadjustmentPercentage] = useState("");
    const [lastReadjustmentDate, setLastReadjustmentDate] = useState("");
    const [lastReadjustmentPercentage, setLastReadjustmentPercentage] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [attachment, setAttachment] = useState('');
    const [companyId, setCompanyId] = useState(0);

    //Erros
    const [customerIsInvalid, setCustomerIsInvalid] = useState(false);
    const [totalValueIsInvalid, setTotalValueIsInvalid] = useState(false);
    const [expireDayIsInvalid, setExpireDayIsInvalid] = useState(false);
    const [dreSubCategoryIdIsInvalid, setDreSubCategoryIdIsInvalid] = useState(false);
    const [accountBankIsInvalid, setAccountBankIsInvalid] = useState(false);

    const attachmentRef = useRef<HTMLInputElement>(null);

    //Envio por email / whatsapp
    const [showModalEmail, setShowModalEmail] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [showModalSuccessEmail, setShowModalSuccessEmail] = useState(false);
    const [infoEmail, setInfoEmail] = useState<EmailToCustomer>({
        refId: '',
        refName: '',
        toName: '',
        customerName: '',
        customerId: '',
        email: '',
        contactName: '',
        message: '',
        emailSubject: '',
    });
    const [emailsHistory, setEmailsHistory] = useState<EmailsHistory[]>([]);
    const [showModalWhatsapp, setShowModalWhatsapp] = useState(false);
    const [infoWhatsapp, setInfoWhatsapp] = useState<WhatsappToCustomer>({
        refId: '',
        refName: '',
        customerName: '',
        customerId: '',
        number: '',
        message: '',
    });
    const [whatsappHistory, setWhatsappHistory] = useState<WhatsappHistory[]>([]);


    // NFSE
    const [activity, setActivity] = useState<number | undefined>();
    const [activities, setActivities] = useState<CompanyNfseActivity[]>([]);
    const [nature, setNature] = useState(0);
    const [placeDelivery, setPlaceDelivery] = useState("endereço do emitente");
    const [aliquot, setAliquot] = useState("0,00");
    const [holdIss, setHoldIss] = useState("n");

    const [totalValue, setTotalValue] = useState("R$0,00");
    const [totalValueServices, setTotalValueServices] = useState("R$0,00");

    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState("");

    const [startDateInvalid, setStartDateInvalid] = useState<boolean>(false);
    const [servicesInvalid, setServicesInvalid] = useState<boolean>(false);

    const paymentRequiredFields = [expireDay, accountBank];
    const isPaymentRequiredFieldsFilled = paymentRequiredFields.every(paymentRequiredField => paymentRequiredField);
    
    const detailsRequiredFields = [startDate, dreSubCategoryId];
    const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

    const [isSubmit, setIsSubmit] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-suitcase");

    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const history = useHistory();
    const { handleSubmit } = useForm();
    const {
        services,
        dispatchServices,
    } = useProductsInstallments();

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        handleChangeAccountBank(accountBank);
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomer(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchServices({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText, selectedType: 'service' });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        async function loadData() {
            const response = await api.get<ServiceContractData>(`serviceContract/${id}`);
            const data = response.data;

            const services: Product[] = data.services ? JSON.parse(data.services) : [];

            dispatchServices({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: services,
                }
            });

            setNumberSO(data.number);
            setStartDate(data.startDate ? extractDateStringFromTimestamp(data.startDate) : '');
            setFinalDate(data.finalDate ? extractDateStringFromTimestamp(data.finalDate) : '');
            setCustomer(data.customer ? Number(data.customer) : undefined);
            setSeller(data.seller ? String(data.seller) : '');
            setCenterCost(data.centerCost ? String(data.centerCost) : '');
            setNextPaymentDate(data.nextPaymentDate ? extractDateStringFromTimestamp(data.nextPaymentDate) : '');
            setExpireDay(data.expireDay ?? '');
            setDaysBeforeExpire(data.daysBeforeExpire ?? '');
            setBillType(data.billType ?? 'account');
            handleChangeAccountBank(data.bankAccountEntity);
            setGenerateNfse(data.generateNfse === 'y');
            setSellerCommissioning(data.sellerCommissioning === 'y');
            setSendEmail(data.sendEmail === 'y');
            setSendWhats(data.sendWhats === 'y');
            setTotalValue(formatCurrency(data.totalValue));
            setDescription(data.description ?? '');
            setWarranty(data.warranty ?? '');
            setComments(data.comments ?? '');
            setInternalComments(data.internalComments ?? '');
            setFrequency(data.frequency ?? frequencyEnum.MONTHLY);
            setReferences(data.referencies ?? '');
            setCategoryName(data.categoryName ?? '');
            setReadjustmentPercentage(formatCurrency(data.readjustmentPercentage ?? '0'));
            setReadjustmentPeriod(data.readjustmentPeriod ?? 0);
            setNextReadjustmentDate(data.nextReadjustmentDate ? extractDateStringFromTimestamp(data.nextReadjustmentDate) : '');
            setNextReadjustmentPercentage(formatCurrency(data.nextReadjustmentPercentage));
            setDreSubCategoryId(data.dreSubCategoryId ?? 0);
            setAttachment(data.attachment);
            setCompanyId(data.companyId);

            if (data.readjustments) {
                const readjustments = JSON.parse(data.readjustments);
                const lastReadjustment = arrayLastItem(readjustments);
                setLastReadjustmentDate(lastReadjustment.date ?? '');
                setLastReadjustmentPercentage(lastReadjustment.percentage ?? '');
            }

            if (data.nfseData) {
                const nfseData = JSON.parse(data.nfseData);

                setActivity(nfseData.activity);
                setNature(nfseData.nature);
                setPlaceDelivery(nfseData.placeDelivery);
                setAliquot(nfseData.aliquot);
                setHoldIss(nfseData.holdIss);
            }

        }

        loadData();
        getActivities();
    }, []);

    useEffect(() => {
        const totalService = services.reduce((acc, { totalValue }) => acc + totalValue, 0);

        const partial = totalService;

        const total = formatToFloat(readjustmentPercentage) ? partial + (formatToFloat(readjustmentPercentage) / 100 * partial) : partial;

        setTotalValueServices(formatCurrency(totalService));
        setTotalValue(formatCurrency(total));
    }, [services]);

    const handleChangeAccountBank = useCallback((accountBank: BankAccount | null | undefined) => {
        setAccountBank(String(accountBank?.id ?? ''));
        setSelectedBankAccountIsInter(accountBank ? bankNumberIsInter(accountBank.numberBank) : false);
    }, []);

    async function onSubmit() {
        try {
            setIsSubmit(true);

            const lastInputs = await api.get(`serviceContract/${id}`);

            const isValid = await inputsVerify();

            if (!isValid) {
                setIsSubmit(false);
                return;
            }

            if (billType === 'billet') {
                // Verificando se o banco selecionado está corretamente configurado para emitir boletos
                const bank = await BankAccountService.getBankAccountById(accountBank);
                const bankError = await BankAccountService.validateBilletRequiredInformation(bank);

                if (bankError) {
                    setMsgError(bankError);
                    setShowModalError(true);
                    setIsSubmit(false);
                    return;
                }
            }

            const raw = {
                description: description || '',
                warranty: warranty || null,
                comments: comments || null,
                internalComments: internalComments || null,
                frequency: frequency || frequencyEnum.MONTHLY,
                referencies: references || null,
                customer: Number(customer),
                seller: Number(seller),
                totalValue: formatToFloat(totalValue),
                startDate: startDate ? new Date(startDate) : null,
                finalDate: finalDate ? new Date(finalDate) : null,
                centerCost: centerCost || null,
                nextPaymentDate: nextPaymentDate ? new Date(nextPaymentDate) : null,
                expireDay: expireDay,
                daysBeforeExpire: daysBeforeExpire || 5,
                billType: billType,
                bankAccount: accountBank || null,
                sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
                generateNfse: generateNfse ? yesOrNo.YES : yesOrNo.NO,
                sendEmail: sendEmail ? yesOrNo.YES : yesOrNo.NO,
                sendWhats: sendWhats ? yesOrNo.YES : yesOrNo.NO,
                categoryName: categoryName || null,
                readjustmentPercentage: formatToFloat(readjustmentPercentage),
                readjustmentPeriod: readjustmentPeriod,
                nextReadjustmentDate: nextReadjustmentDate ? new Date(nextReadjustmentDate) : null,
                nextReadjustmentPercentage: formatToFloat(nextReadjustmentPercentage),
                dreSubCategoryId: dreSubCategoryId,

                services: JSON.stringify(services.filter(service => service.INSTANCE?.id)),
                nfseData: generateNfse ? JSON.stringify({
                    activity,
                    nature,
                    placeDelivery,
                    aliquot,
                    holdIss,
                }) : null,
            };

            const response = await api.put(`serviceContract/${id}`, raw);

            LogService.logEdit({
                itemId: response.data.id,
                itemName: 'Contrato',
                module: 'Contratos',
                oldData: lastInputs.data,
                newData: response.data,
                formattedFields: {
                    startDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    finalDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    deliveryDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    nextReadjustmentDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    nextPaymentDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    customer: (value) => CustomerService.getNameByIdAsync(value),
                    seller: (value) => SellerService.getNameByIdAsync(value),
                    readjustmentPeriod: (value) => formatNumberToString(value),
                    nextReadjustmentPercentage: (value) => formatNumberToString(value),
                    generateNfse: (value) => getYesOrNoText(value),
                    sendEmail: (value) => getYesOrNoText(value),
                    centerCost: async (value) => (await CenterCostService.getCenterCostById(Number(value)))?.name ?? '',
                    billType: (value) => getBillType(value),
                    bankAccount: async (value) => (await BankAccountService.getBankAccountById(Number(value)))?.name ?? '',
                    frequency: (value) => frequencyToMonths(value),
                    totalValue: (value) => formatNumberToString(value),
                },
                fieldsMap: {
                    description: 'Descrição',
                    customer: 'Cliente',
                    seller: 'Vendedor',
                    expireDay: 'Dia de vencimento',
                    daysBeforeExpire: 'Dia para gerar fatura',
                    frequency: 'Periodicidade',
                    nextPaymentDate: 'Próximo pagamento',
                    billType: 'Tipo da fatura',
                    bankAccount: 'Conta bancária',
                    generateNfse: 'Gerar nota fiscal',
                    sendEmail: 'Gerar cobrança por e-mail',
                    readjustmentPeriod: 'Período reajuste',
                    nextReadjustmentPercentage: '% Próximo reajuste',
                    nextReadjustmentDate: 'Data próximo reajuste',
                    startDate: 'Data inicial',
                    finalDate: 'Data final',
                    warranty: 'Garantia',
                    centerCost: 'Centro de custos',
                    categoryName: 'Plano de contas',
                    referencies: 'Referência',
                    comments: 'Observações',
                    internalComments: 'Observações internas',
                    totalValue: 'Valor total'
                },
            });

            // Upload anexo
            let uploadSuccess = true;
            try {
                uploadSuccess = await uploadAttachment(Number(id));
            } catch (error) {
                uploadSuccess = false;
            }

            history.push("/contrato-servico");

        } catch (error) {
            console.log(error);
        }
    };

    async function uploadAttachment(id: number): Promise<boolean> {
        if (attachmentRef && attachmentRef.current) {
            if (!attachmentRef.current.files || attachmentRef.current.files.length === 0) {
                return false;
            }
            const file = attachmentRef.current.files[0];
            if (!file) {
                return false;
            }

            var formFile = new FormData();
            formFile.append('file', file, file.name);

            const response = await api.post('/file', formFile);
            const raw = {
                attachment: response.data
            }

            await api.put(`serviceContract/${id}`, raw);
            return true;
        }

        return false;
    }

    async function inputsVerify() {
        setCustomerIsInvalid(false);
        setTotalValueIsInvalid(false);
        setExpireDayIsInvalid(false);
        setDreSubCategoryIdIsInvalid(false);
        setAccountBankIsInvalid(false);
        for (let i = 0; i < services.length; i++) {
            const service = services[i];

            setServiceError(i, service, false);
        }

        if (!customer) {
            setMsgError('Informe o cliente!');
            setShowModalError(true);
            setCustomerIsInvalid(true);
            return false;
        }

        const customerObj = await CustomerService.getCustomerById(customer);
        if (customerObj.limitCredit !== null) {
            setMsgError('Não é possível vincular o contrato à um cliente com limite de crédito definido. Alterar em Cadastros -> Clientes/Fornecedores');
            setShowModalError(true);
            setCustomerIsInvalid(true);
            return false;
        }

        if (formatToFloat(totalValue) <= 0) {
            setActiveTab("services");
            setMsgError('O total dos serviços não pode ser igual ou inferior à zero!');
            setShowModalError(true);
            setTotalValueIsInvalid(true);
            return false;
        }

        for (let i = 0; i < services.length; i++) {
            const service = services[i];

            if (service.totalValue && !service.INSTANCE?.id) {
                setServiceError(i, service, true);
                setMsgError('Selecione um serviço!');
                setActiveTab('services');
                setShowModalError(true);
                return false;
            }
        }

        if (!expireDay) {
            setActiveTab("payment");
            setMsgError('Informe o dia do vencimento!');
            setShowModalError(true);
            setExpireDayIsInvalid(true);
            return false;
        }

        if (!startDate) {
            setActiveTab("details");
            setMsgError("Informe a data inicial do contrato!");
            setShowModalError(true);
            return false;
        }

        if (!dreSubCategoryId) {
            setActiveTab("details");
            setMsgError("Campo Plano de Contas é obrigatório!");
            setDreSubCategoryIdIsInvalid(true);
            setShowModalError(true);
            return false;
        }

        if (!accountBank) {
            setActiveTab("payment");
            setMsgError("Campo conta bancária é obrigatório!");
            setShowModalError(true);
            setAccountBankIsInvalid(true);
            return false;
        }

        if (selectedBankAccountIsInter && billType === 'billet') {
            const validationResult = await BillToReceiveService.validateGenerateInterBilletFields({ amount: totalValue, customerId: customer, });

            if (validationResult.error) {
                switch (validationResult.field) {
                    case 'amount':
                        setActiveTab("totalServiceOrder");
                        setTotalValueIsInvalid(true);
                        break;
                    case 'customerId':
                        setCustomerIsInvalid(true);
                        break;
                }

                setMsgError(validationResult.message);
                setShowModalError(true);
                return false;
            }
        }

        return true;
    }

    function setServiceError(index: number, service: Product, error?: boolean) {
        dispatchServices({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...service,
                    hasError: error,
                },
            }
        });
    }

    useEffect(() => {
        dispatchServices({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchServices({ type: "ADD" });
    }, []);

    function validateNextPaymentDate(expire: string, paymentDate: string) {
        if (!paymentDate || !expire) return;

        const paymentDateObj = new Date(`${paymentDate} 00:00:00`);
        if (!(paymentDateObj instanceof Date)) {
            setNextPaymentDate('');
            return;
        }

        const expireDayNumber = Number(expire);
        const monthLastDay = getLastDayOfMonth(paymentDateObj.getFullYear(), paymentDateObj.getMonth() + 1);
        if (monthLastDay <= expireDayNumber) {
            paymentDateObj.setDate(monthLastDay);
        } else {
            paymentDateObj.setDate(expireDayNumber);
        }

        setNextPaymentDate(extractDateStringFromTimestamp(paymentDateObj.toISOString()));
    }

    function updateNextReadjustmentDate(period: number, frequency: string) {
        if (!nextPaymentDate) return;

        const readjustmentDateObj = new Date(`${nextPaymentDate} 00:00:00`);
        if (!(readjustmentDateObj instanceof Date)) {
            setNextReadjustmentDate('');
            return;
        }

        readjustmentDateObj.setMonth(readjustmentDateObj.getMonth() + (period * frequencyToMonths(frequency)));

        setNextReadjustmentDate(extractDateStringFromTimestamp(readjustmentDateObj.toISOString()));
    }

    async function getActivities() {
        const response = await api.get<CompanyNfseActivity[]>('companyNfseActivities');
        setActivities(response.data);
    }

    function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>) {
        setFrequency(event.target.value);
        updateNextReadjustmentDate(readjustmentPeriod, event.target.value);
    }

    function handleChangeExpireDay(event: React.ChangeEvent<HTMLInputElement>) {
        const expireDayNumber = Number(event.target.value);
        let expireDayValue = event.target.value;

        if (expireDayNumber <= 0) {
            expireDayValue = '';
        } else if (expireDayNumber > 31) {
            expireDayValue = '31';
        }

        setExpireDay(expireDayValue);
        setForceUpdate((prevState) => !prevState);

        validateNextPaymentDate(expireDayValue, nextPaymentDate);
    }

    function handleChangeNextPaymentDate(event: React.ChangeEvent<HTMLInputElement>) {
        setNextPaymentDate(event.target.value);

        validateNextPaymentDate(expireDay, event.target.value);
    }

    function handleChangeReadjustmentPeriod(event: React.ChangeEvent<HTMLInputElement>) {
        setReadjustmentPeriod(Number(event.target.value));
        updateNextReadjustmentDate(Number(event.target.value || 0), frequency);
    }

    function handleChangeBillType(event: React.ChangeEvent<HTMLInputElement>) {
        const type = event.target.value;
        if (type === 'billet') {
            setSendEmail(true);
            setSendWhats(true);
        } else {
            setSendEmail(false);
            setSendWhats(false);
        }
        setBillType(type);
    }
    function handleChangeGenerateNfse(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setGenerateNfse(checked);
        setActivity(undefined);
        setNature(0);
        setPlaceDelivery('endereço do emitente');
        setAliquot('0,00');
        setHoldIss('n');
    }

    function handleChangeActivity(event: React.ChangeEvent<{}>, selected: CompanyNfseActivity | null) {
        setActivity(selected?.id ?? undefined);
    }

    async function getEmails() {
        setEmailsHistory([]);
        const emails = await EmailServiceContractService.getAllEmailsByServiceContractId(id);
        setEmailsHistory(emails);
    }

    async function getWhatsapps() {
        setWhatsappHistory([]);
        const history = await WhatsappServiceContractService.getAllWhatsappsByServiceContractId(id);
        setWhatsappHistory(history);
    }

    async function handleOpenSendEmailModal() {
        const customerObj = await CustomerService.getCustomerById(customer ?? 0);
        const customerName = CustomerService.getNameByIdSync(Number(customer), [customerObj]);

        setShowModalEmail(true);
        getEmails();
        setInfoEmail({
            refId: String(id),
            refName: 'serviceContract',
            toName: '',
            customerName: customerName ?? '',
            customerId: customer ? String(customer) : '',
            email: customerObj?.email ?? '',
            contactName: '',
            message: 'Olá estamos te enviando um contrato de prestação de serviços.',
            emailSubject: 'Contrato de Serviço',
        });
    }

    const handleClickSendEmail = useCallback(async () => {
        const emails = infoEmail.email.split(';').map(email => email.trim());

        try {
            await EmailServiceContractService.sendEmail(emails, {
                serviceContractId: id,
                customerId: infoEmail.customerId,
                customerName: infoEmail.customerName,
                contactName: infoEmail.contactName,
                message: infoEmail.message,
                refName: infoEmail.refName,
                subject: infoEmail.emailSubject,
                companyId: companyId,
                attachment: attachment,
                link: `${apiUrl}/${attachment}`,
            });

            setMessageSuccess('Email enviado com sucesso');
            setShowModalSuccessEmail(true);
        } catch (error: any) {
            setMsgError(error.response?.data?.message ?? 'Falha ao enviar o e-mail!');
            setShowModalError(true);
        }

        setShowModalEmail(false);
    }, [id, companyId, infoEmail, attachment]);

    async function handleOpenSendWhatsappModal() {
        const customerObj = await CustomerService.getCustomerById(customer ?? 0);
        const customerName = CustomerService.getNameByIdSync(Number(customer), [customerObj]);

        setShowModalWhatsapp(true);
        getWhatsapps();
        setInfoWhatsapp({
            refId: String(id),
            refName: 'serviceContract',
            customerName: customerName ?? '',
            customerId: customer ? String(customer) : '',
            number: customerObj?.cell ?? '',
            message: 'Olá estamos te enviando um contrato de prestação de serviços.',
        });
    }

    const handleClickSendWhatsapp = useCallback(async () => {
        try {
            await WhatsappServiceContractService.sendWhatsapp(infoWhatsapp.number, {
                serviceContractId: id,
                customerId: infoWhatsapp.customerId,
                customerName: infoWhatsapp.customerName,
                message: infoWhatsapp.message,
                refName: infoWhatsapp.refName,
                companyId: companyId,
                attachment: attachment,
                link: `${apiUrl}/${attachment}`,
            });

            setMessageSuccess('Whatsapp enviado com sucesso');
            setShowModalSuccessEmail(true);
        } catch (error) {
            setMsgError('Falha ao enviar a mensagem!');
            setShowModalError(true);
        }

        setShowModalWhatsapp(false);
    }, [id, companyId, infoWhatsapp, attachment]);

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-suitcase") {
                const hasServices = services.find((service) => service.totalValue || service.INSTANCE.id);
                if (hasServices) {
                    const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-delete');
                    
                    if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                    } else {
                    prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                    }

                    setServicesInvalid(false);
                } else {
                    const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-check-mark');

                    setServicesInvalid(true);

                    if (current_icon) {
                        current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                        current_icon.classList.replace("text-success", "text-danger");
                    } else {
                        prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-delete");
                        prev_icon?.classList.add("text-danger");
                    }
                }
        } else if (previousIcon === "flaticon-list-1") {
            if (isPaymentRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-list-1", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-check-mark');

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-list-1", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else if (previousIcon === "flaticon-doc") {
            if (isDetailsRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-check-mark');

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        }
        else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('services')?.classList.remove('current');
        document.getElementById('totalServiceOrder')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('details')?.classList.remove('current');
        document.getElementById('attachment')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        if (previousIcon === "flaticon-list-1") {
            if (!paymentRequiredFields[0]) {
                setExpireDayIsInvalid(true);
            } else {
                setExpireDayIsInvalid(false);
            }

            if (!paymentRequiredFields[1]) {
                setAccountBankIsInvalid(true);
            }
        }

        if (previousIcon === "flaticon-doc") {
            if(!detailsRequiredFields[0]) {
                setStartDateInvalid(true);
            }

            if(!detailsRequiredFields[1]) {
                setDreSubCategoryIdIsInvalid(true);
            }
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'services') {
                setNextButton('first');
        } else if (tab == 'attachment') {
                setNextButton('last');
        } else {
                setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['services', 'totalServiceOrder', 'payment', 'details', 'attachment'];
        let arr2 = ['flaticon-suitcase', 'flaticon-coins', 'flaticon-list-1', 'flaticon-doc', 'flaticon-tool-1'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                showModalError={showModalError}
                setShowModalError={setShowModalError}
                msgError={msgError}
            />
            <ModalSuccess
                setShowModal={setShowModalSuccessEmail}
                showModal={showModalSuccessEmail}
                msgModal={messageSuccess}
            />
            <ModalSendEmail
                showModal={showModalEmail}
                setShowModal={setShowModalEmail}
                onClickSend={handleClickSendEmail}
                emailsHistory={emailsHistory}
                infoEmail={infoEmail}
                setInfoEmail={setInfoEmail}
            />
            <ModalSendWhatsapp
                showModal={showModalWhatsapp}
                setShowModal={setShowModalWhatsapp}
                onClickSend={handleClickSendWhatsapp}
                whatsappHistory={whatsappHistory}
                infoWhatsapp={infoWhatsapp}
                setInfoWhatsapp={setInfoWhatsapp}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="services" data-kt-stepper-element='nav' onClick={() => changeTab('services', 'flaticon-suitcase', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                        <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <i className="flaticon-suitcase icon_change_color" style={{color: "#666"}}></i>
                                        </div>
                                        <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Serviços</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Serviços do contrato de serviço</div>
                                        </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="totalServiceOrder" data-kt-stepper-element='nav' onClick={() => changeTab('totalServiceOrder', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                        <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                        </div>
                                        <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Totais</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Totais do contrato de serviço</div>
                                        </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                        <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                        </div>
                                        <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Pagamento</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Pagamento do contrato de serviço</div>
                                        </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="details" data-kt-stepper-element='nav' onClick={() => changeTab('details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                        <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#666"}}></i>
                                        </div>
                                        <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes do contrato de serviço</div>
                                        </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="attachment" data-kt-stepper-element='nav' onClick={() => changeTab('attachment', 'flaticon-tool-1', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                        <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <i className="flaticon-tool-1 icon_change_color" style={{color: "#666"}}></i>
                                        </div>
                                        <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Anexos</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Anexos do contrato de serviço</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/contrato-servico">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button
                                            type="button"
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {isSubmit ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />

                                                <span className="ml-2">
                                                    Aguarde...
                                                </span>
                                            </> : <>
                                                <span>
                                                    Salvar
                                                </span>
                                            </>}
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    size="small"
                                    label="Descrição"
                                    margin="normal"
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <CustomerSelect
                                    label="Cliente *"
                                    hasError={customerIsInvalid}
                                    customerId={customer}
                                    setCustomerId={setCustomer}
                                    disabled={user.isAccountant == "y" ? true : false}
                                    onClickAddCustomer={handleClickAddCustomer}
                                />
                            </Grid>

                            <Grid item lg={3} md={6} xs={12}>
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    disabled
                                    type='text'
                                    size="small"
                                    margin="normal"
                                    label="N°"
                                    value={numberSO}
                                />
                            </Grid>

                            <Modal
                                show={showModalSuccess}
                                onHide={() => {
                                    setShowModalSuccess(false);
                                }}
                                aria-labelledby="contained-modal-warning"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="d-flex align-items-center">
                                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                                        Sucesso
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <b>Dados criados com sucesso !</b>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => {
                                        setShowModalSuccess(!showModalSuccess);
                                        window.location.href = "/contrato-servico/adicionar";
                                    }}>Fechar</Button>
                                </Modal.Footer>
                            </Modal>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/contrato-servico" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' style={{ display: "none" }}>
                            <Tab eventKey='services' title='Serviços'>
                            {
                                servicesInvalid && (
                                    <p className='text-danger'>Selecione um serviço!</p>
                                )
                            }
                                {services.map((service, index) => (
                                    (
                                        <ListProducts
                                            key={index}
                                            index={index}
                                            product={service}
                                            service
                                            onClickAddProduct={handleClickAddProduct}
                                            changeTabIcon={changeTabIcon}
                                        />
                                    )
                                ))
                                }

                                {
                                    user.isAccountant == "n"
                                        ?
                                        <UnderlineLink text="adicionar outro serviço" onClick={() => dispatchServices({ type: "ADD" })} />
                                        : <></>
                                }
                            </Tab>

                            <Tab eventKey='totalServiceOrder' title='Totais da Ordem de Serviço'>
                                <div>
                                    <Grid container spacing={5}>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor dos serviços"
                                                startAdornment="R$"
                                                disabled
                                                value={totalValueServices}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor reajuste atual"
                                                startAdornment="R$"
                                                disabled
                                                value={readjustmentPercentage}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Total"
                                                startAdornment="R$"
                                                disabled
                                                value={totalValue}
                                                error={totalValueIsInvalid}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab eventKey='payment' title='Pagamento'>
                                <div>
                                    {
                                        expireDayIsInvalid && (
                                            <p className='text-danger'>Informe o dia do vencimento!</p>
                                        )
                                    }
                                    <Grid container spacing={3} className="align-items-center">
                                        <Grid item lg={2} md={6} xs={12}>
                                            <NumericFormat
                                                label="Dia de Vencimento*"
                                                className="mx-2"
                                                thousandSeparator={''}
                                                decimalScale={0}
                                                value={expireDay}
                                                onChange={handleChangeExpireDay}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <NumericFormat
                                                label="Dias para gerar Fatura"
                                                className="mx-2"
                                                thousandSeparator={''}
                                                decimalScale={0}
                                                value={daysBeforeExpire}
                                                onChange={(e) => setDaysBeforeExpire(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                value={frequency}
                                                onChange={handleChangeFrequency}
                                                size="small"
                                                select
                                                label="Periodicidade"
                                                margin="normal"
                                                defaultValue={frequencyEnum.MONTHLY}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem value={frequencyEnum.MONTHLY}>
                                                    Mensal
                                                </MenuItem>
                                                <MenuItem value={frequencyEnum.BIMONTHLY}>
                                                    Bimestral
                                                </MenuItem>
                                                <MenuItem value={frequencyEnum.TRIMONTHLY}>
                                                    Trimestral
                                                </MenuItem>
                                                <MenuItem value={frequencyEnum.QUARTERLY}>
                                                    Quadrimestral
                                                </MenuItem>
                                                <MenuItem value={frequencyEnum.SEMIANNUAL}>
                                                    Semestral
                                                </MenuItem>
                                                <MenuItem value={frequencyEnum.ANNUAL}>
                                                    Anual
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Próximo Pagamento"
                                                InputLabelProps={{ shrink: true }}
                                                value={nextPaymentDate}
                                                onChange={handleChangeNextPaymentDate}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                        color="primary"
                                                        checked={sellerCommissioning}
                                                        onChange={(e, checked) => setSellerCommissioning(checked)}
                                                    />
                                                }
                                                label="Emitir comissionamento (vendedor)"
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="mt-2 d-flex align-items-center">
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                select
                                                label="Tipo da fatura"
                                                margin="normal"
                                                value={billType}
                                                onChange={handleChangeBillType}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="account">
                                                    Conta
                                                </MenuItem>

                                                <MenuItem key="1" value="billet">
                                                    Boleto
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <ApiResourceSelect
                                                label="Conta bancária *"
                                                getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                                value={accountBank}
                                                hasError={accountBankIsInvalid}
                                                onSelect={(option) => {handleChangeAccountBank(option); setAccountBankIsInvalid(option?.id !== 0 ? false : true); changeTabIcon('flaticon-list-1');}}
                                                apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                                getSelectedOption={(loadedOptions) => {
                                                    if (!accountBank) return null;
                                                    return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                                renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                        color="primary"
                                                        checked={generateNfse}
                                                        onChange={handleChangeGenerateNfse}
                                                    />
                                                }
                                                label="Gerar nota fiscal"
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        {billType === 'billet' && (
                                            <Grid item lg={3} md={6} xs={12}>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                            color="primary"
                                                            checked={sendEmail}
                                                            onChange={(e, checked) => setSendEmail(checked)}
                                                        />
                                                    }
                                                    label="Enviar cobrança por e-mail"
                                                    labelPlacement="end"
                                                />
                                            </Grid>
                                        )}
                                        {billType === 'billet' && (
                                            <Grid item lg={3} md={6} xs={12}>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Checkbox
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                            color="primary"
                                                            checked={sendWhats}
                                                            onChange={(e, checked) => setSendWhats(checked)}
                                                        />
                                                    }
                                                    label="Enviar cobrança por WhatsApp"
                                                    labelPlacement="end"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>

                                    {generateNfse && (
                                        <div className="card-body px-3">
                                            <div className="row">
                                                <Grid item lg={4} md={6} xs={12}>
                                                    <Autocomplete
                                                        size="small"
                                                        noOptionsText="Sem opções"
                                                        options={activities}
                                                        getOptionLabel={({ serviceCode, tributeCode, description }) => `${serviceCode} - ${tributeCode} - ${description}`}
                                                        value={activities.find(activityObj => activityObj.id === activity) ?? null}
                                                        onChange={(event, newInputValue) => handleChangeActivity(event, newInputValue)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                size="small"
                                                                {...params}
                                                                label="Atividade"
                                                                margin="normal"
                                                            />
                                                        }
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} md={6} xs={12}>
                                                    <TextField
                                                        select
                                                        label="Natureza da operação"
                                                        margin="normal"
                                                        size="small"
                                                        value={nature}
                                                        onChange={(e) => setNature(Number(e.target.value))}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    >
                                                        <MenuItem key="0" value="0">
                                                            Selecione
                                                        </MenuItem>

                                                        <MenuItem key="1" value="1">
                                                            Tributação no município
                                                        </MenuItem>

                                                        <MenuItem key="2" value="2">
                                                            Tributação fora do município
                                                        </MenuItem>

                                                        <MenuItem key="3" value="3">
                                                            isenção
                                                        </MenuItem>

                                                        <MenuItem key="4" value="4">
                                                            Imune
                                                        </MenuItem>

                                                        <MenuItem key="5" value="5">
                                                            Processo Judicial
                                                        </MenuItem>
                                                    </TextField>

                                                </Grid>

                                                <Grid item lg={4} md={6} xs={12}>
                                                    <TextField
                                                        select
                                                        label="Local da prestação"
                                                        margin="normal"
                                                        size="small"
                                                        value={placeDelivery}
                                                        onChange={(e) => setPlaceDelivery(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    >
                                                        <MenuItem key="0" value="endereço do emitente">
                                                            Endereço do emitente
                                                        </MenuItem>

                                                        <MenuItem key="1" value="endereço do destinatario">
                                                            Endereço do destinatário
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>

                                                <Grid item lg={3} md={6} xs={12}>
                                                    <NumericFormat
                                                        label="% Alíquota"
                                                        startAdornment="%"
                                                        value={aliquot}
                                                        onChange={(e) => setAliquot(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </Grid>

                                                <Grid item lg={3} md={6} xs={12}>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                                color="primary"
                                                                checked={holdIss == "y" ? true : false}
                                                                onChange={() => setHoldIss(holdIss == "y" ? "n" : "y")}
                                                            />
                                                        }
                                                        label="Reter ISS"
                                                        labelPlacement="end"
                                                    />
                                                </Grid>
                                            </div>
                                        </div>
                                    )}

                                    <Grid container spacing={3} className="mt-3">
                                        <Grid item lg={2} md={6} xs={12}>
                                            <NumericFormat
                                                label="Período Reajuste"
                                                className="mx-2"
                                                thousandSeparator={''}
                                                decimalScale={0}
                                                value={readjustmentPeriod}
                                                onChange={handleChangeReadjustmentPeriod}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={2} md={6} xs={12}>
                                            <NumericFormat
                                                className="mx-2"
                                                label="% Próximo Reajuste"
                                                startAdornment="%"
                                                value={nextReadjustmentPercentage}
                                                onChange={(e) => setNextReadjustmentPercentage(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Data Próximo Reajuste"
                                                InputLabelProps={{ shrink: true }}
                                                value={nextReadjustmentDate}
                                                onChange={(e) => setNextReadjustmentDate(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                className="inputSmaller mx-2"
                                                label="% Último Reajuste"
                                                startAdornment="%"
                                                value={lastReadjustmentPercentage}
                                                onChange={(e) => setLastReadjustmentPercentage(e.target.value)}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Data Último Reajuste"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                                value={lastReadjustmentDate}
                                                onChange={(e) => setLastReadjustmentDate(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab eventKey='details' title='Detalhes da OS'>
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                required
                                                label="Data Inicial"
                                                InputLabelProps={{ shrink: true }}
                                                value={startDate}
                                                onChange={(e) => {setStartDate(e.target.value); setStartDateInvalid(e.target.value ? false : true); changeTabIcon('flaticon-doc')}}
                                                disabled={user.isAccountant == "y" ? true : false}
                                                error={startDateInvalid}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type='date'
                                                margin="normal"
                                                label="Data Final"
                                                InputLabelProps={{ shrink: true }}
                                                value={finalDate}
                                                onChange={(e) => setFinalDate(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                                InputProps={{
                                                    endAdornment: (
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip-top'>
                                                                    Indica o término do contrato e encerramento da geração de cobrança, caso não informado o contrato terá duração inderteminada
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <IconButton
                                                                edge="end"
                                                            >
                                                                <i className="flaticon-info"></i>
                                                            </IconButton>
                                                        </OverlayTrigger>
                                                        // <InputAdornment position="end">
                                                        //     <OverlayTrigger
                                                        //         trigger="click"
                                                        //         placement="top"
                                                        //         overlay={
                                                        //             <Tooltip id='tooltip-top'>
                                                        //                 Indica o término do contrato e encerramento da geração de cobrança, caso não informado o contrato terá duração inderteminada
                                                        //             </Tooltip>
                                                        //         }
                                                        //     >
                                                        //         <IconButton
                                                        //             edge="end"
                                                        //         >
                                                        //             <i className="flaticon-info"></i>
                                                        //         </IconButton>
                                                        //     </OverlayTrigger>
                                                        // </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                value={warranty}
                                                onChange={(e) => setWarranty(e.target.value)}
                                                size="small"
                                                type='text'
                                                margin="normal"
                                                label="Garantia"
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <ApiResourceSelect
                                                label="Centro de Custos"
                                                getOptionLabel={(option: CenterCost) => option.name}
                                                value={centerCost}
                                                onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                                apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                                getSelectedOption={(loadedOptions) => {
                                                    if (!centerCost) return null;
                                                    return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} className="mt-2">
                                        <Grid item lg={6} md={6} xs={12}>
                                            <ApiResourceSelect
                                                style={{ width: "100%" }}
                                                label="Plano de Contas *"
                                                getOptionLabel={(option: SubCategory) => option.name}
                                                value={dreSubCategoryId}
                                                hasError={dreSubCategoryIdIsInvalid}
                                                onSelect={(option) => {setDreSubCategoryId(option?.id ?? 0); setDreSubCategoryIdIsInvalid(option?.id !== 0 ? false : true); changeTabIcon('flaticon-doc')}}
                                                apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                                                getSelectedOption={(loadedOptions) => {
                                                    if (!dreSubCategoryId) return null;
                                                    return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                value={references}
                                                onChange={(e) => setReferences(e.target.value)}
                                                size="small"
                                                type='text'
                                                margin="normal"
                                                label="Referência"
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Observações"
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                value={internalComments}
                                                onChange={(e) => setInternalComments(e.target.value)}
                                                size="small"
                                                rows={6}
                                                multiline
                                                margin="normal"
                                                label="Observações internas"
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab eventKey='attachment' title='Anexo'>
                                {!!attachment && (
                                    <>
                                        <Grid container spacing={3} className="p-4">
                                            <Grid item lg={6} md={6} xs={12}>
                                                <label htmlFor="formFile" className="mt-3">
                                                    Contrato/Anexo salvo
                                                </label>

                                                <p>
                                                    <a href={`${apiUrl}/${attachment}`} target="_blank" download>{attachment}</a>
                                                </p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} className="p-4">
                                            <Grid item lg={6} md={6} xs={12}>
                                                {
                                                    user.isAccountant == "n"
                                                        ?
                                                        <>
                                                            <MaterialTooltip TransitionComponent={Zoom} title="Enviar Whatsapp">
                                                                <Button
                                                                    className="btn p-2 mr-3"
                                                                    type="button"
                                                                    style={{ background: "#50cd89", border: "none" }}
                                                                    onClick={handleOpenSendWhatsappModal}
                                                                >
                                                                    <img src="/media/icons/whatsapp.png" alt="Whatsapp" />
                                                                </Button>
                                                            </MaterialTooltip>
                                                            <MaterialTooltip TransitionComponent={Zoom} title="Enviar email">
                                                                <Button
                                                                    className="btn p-2 mr-3"
                                                                    type="button"
                                                                    style={{ background: "#6993ff", border: "none" }}
                                                                    onClick={handleOpenSendEmailModal}
                                                                >
                                                                    <i className="flaticon2-email p-0" style={{ color: "#fff" }}></i>
                                                                </Button>
                                                            </MaterialTooltip>
                                                        </>
                                                        : <></>
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Grid container spacing={3} className="p-4">
                                    <Grid item lg={6} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">
                                            {!!attachment && (
                                                'Alterar Contrato/Anexo'
                                            )}
                                            {!attachment && (
                                                'Contrato/Anexo'
                                            )}
                                        </label>

                                        <input className="form-control" type="file" id="formFile" ref={attachmentRef} disabled={user.isAccountant == "y" ? true : false} />

                                        <label htmlFor="formFile" className="mt-3">Máximo 8MB: JPG, JPEG, GIF, PNG, BMP, PDF, DOCX.</label>
                                    </Grid>
                                </Grid>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}