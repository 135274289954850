import React, { useCallback, useEffect, useMemo, useState } from 'react'; 
import '../../../ProgressBarForm.css';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import { useCompanyBranch } from '../../../hooks/companyBranch';
import api from '../../../services/Api';
import { Badge } from '@material-ui/core';
import FifthStep from './FifthStep';
import useQueryParams from '../../../hooks/queryParams';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

export default function ListAccounting () {
    const [stepActive, setStepActive] = useState(1);
    
    const [firstStepCompleted, setFirstStepCompleted] = useState(false);
    const [secondStepCompleted, setSecondStepCompleted] = useState(false);
    const [thirdStepCompleted, setThirdStepCompleted] = useState(false);
    const [fourStepCompleted, setFourStepCompleted] = useState(false);
    const [fifthStepCompleted, setFifthStepCompleted] = useState(false);

    const [stepsVerified, setStepsVerified] = useState(false);

    const [step2Alerts, setStep2Alerts] = useState<any[]>([]);
    const [step3Alerts, setStep3Alerts] = useState<any[]>([]);
    const [step4Alerts, setStep4Alerts] = useState<any[]>([]);

    const { selectedCompany } = useCompanyBranch();
    const { getQueryParam } = useQueryParams();

    useEffect(() => {
        async function getAlerts() {
            const { data: alerts } = await api.get<any[]>('/accountingStepAlert');
            setStep2Alerts(alerts.filter((alert) => alert.step === '2'));
            setStep3Alerts(alerts.filter((alert) => alert.step === '3'));
            setStep4Alerts(alerts.filter((alert) => alert.step === '4'));
        }

        getAlerts();
    }, []);

    useEffect(() => {
        if(!stepsVerified) return;

        const step = getQueryParam('etapa');

        if(!step) {
            return;
        }

        handleClickStep(Number(step));
    }, [getQueryParam, stepsVerified]);

    useEffect(() => {
        async function verifyAllSteps() {
            if (selectedCompany) {
                await verifyFirstStep(selectedCompany.id);
                await verifySecondStep(selectedCompany.id);
                await verifyThirdStep(selectedCompany.id);
                await verifyFourStep(selectedCompany.id);
                setStepsVerified(true);
            }
        }

        verifyAllSteps();
    }, [selectedCompany, firstStepCompleted, secondStepCompleted, thirdStepCompleted, fourStepCompleted]);

    const verifyFirstStep = useCallback(async(selectedCompanyId: number) => {
        const {data} = await api.get(`/chartOfAccounts/wasSheetImported/${selectedCompanyId}`);

        if (data) {
            setFirstStepCompleted(true);
        } else {
            setFirstStepCompleted(false);
        }
    }, []);

    const verifySecondStep = useCallback(async(selectedCompanyId: number) => {
        const {data} = await api.get(`/chartOfAccounts/wasAccountBankLinked/${selectedCompanyId}`);

        if (data) {
            setSecondStepCompleted(true);
        } else {
            setSecondStepCompleted(false);
        }
    }, []);

    const verifyThirdStep = useCallback(async(selectedCompanyId: number) => {
        const {data} = await api.get(`/chartOfAccounts/wasRevenueLinked/${selectedCompanyId}`);

        if (data) {
            setThirdStepCompleted(true);
        } else {
            setThirdStepCompleted(false);
        }
    }, []);

    const verifyFourStep = useCallback(async(selectedCompanyId: number) => {
        const {data} = await api.get(`/chartOfAccounts/wasExpenseLinked/${selectedCompanyId}`);

        if (data) {
            setFourStepCompleted(true);
        } else {
            setFourStepCompleted(false);
        }
    }, []);

    const handleClickStep = useCallback((step: number) => {
        if(step === 2) {
            if(!firstStepCompleted) return;
            if(step2Alerts.length > 0) {
                api.patch(`accountingStepAlert/dismiss/${step}`);
                setStep2Alerts([]);
            }
        } else if(step === 3) {
            if(!secondStepCompleted) return;
            if(step3Alerts.length > 0) {
                api.patch(`accountingStepAlert/dismiss/${step}`);
                setStep3Alerts([]);
            }
        } else if(step === 4) {
            if(!thirdStepCompleted) return;
            if(step4Alerts.length > 0) {
                api.patch(`accountingStepAlert/dismiss/${step}`);
                setStep4Alerts([]);
            }
        } else if(step === 5) {
            if(!fourStepCompleted) return;
        }

        setStepActive(step);
    }, [firstStepCompleted, secondStepCompleted, thirdStepCompleted, fourStepCompleted,
        step2Alerts, step3Alerts, step4Alerts,
    ]);
    
    return (
        <MainPageContentWrapper>
            <div className="d-flex justify-content-center mb-7">
                <h2>Processo de Contabilidade</h2>
            </div>
        
            <div className="stepper-wrapper">
                <div className={
                    (stepActive == 1 
                    ? "stepper-item active" 
                    : "stepper-item") 
                    +` ${firstStepCompleted ? "completed" : ""}`
                }>
                    <div className="step-counter" onClick={(e) => handleClickStep(1)}>1</div>
                    <div className="step-name">Plano de contas</div>
                </div>

                <div className={
                    (stepActive == 2 
                    ? "stepper-item active" 
                    : "stepper-item") 
                    +` ${secondStepCompleted ? "completed" : ""}`
                }>
                    {step2Alerts.length > 0 && (
                        <Badge
                            badgeContent={step2Alerts.length}
                            color="primary"
                            style={{zIndex: 6}}
                            className="ml-5"
                            title={`${step2Alerts.length} ${step2Alerts.length === 1 ? 'nova conta bancária' : 'novas contas bancárias'}`}
                        />
                    )}
                    <div className="step-counter" onClick={(e) => handleClickStep(2)}>2</div>
                    <div className="step-name">Contas bancárias</div>
                </div>

                <div className={
                    (stepActive == 3 
                    ? "stepper-item active" 
                    : "stepper-item") 
                    +` ${thirdStepCompleted ? "completed" : ""}`
                }>
                    {step3Alerts.length > 0 && (
                        <Badge
                            badgeContent={step3Alerts.length}
                            color="primary"
                            style={{zIndex: 6}}
                            className="ml-5"
                            title={`${step3Alerts.length} ${step3Alerts.length === 1 ? 'novo plano' : 'novos planos'} de contas a receber`}
                        />
                    )}
                    <div className="step-counter" onClick={(e) => handleClickStep(3)}>3</div>
                    <div className="step-name">Recebimento e vendas</div>
                </div>

                <div className={
                    (stepActive == 4 
                    ? "stepper-item active" 
                    : "stepper-item") 
                    +` ${fourStepCompleted ? "completed" : ""}`
                }>
                    {step4Alerts.length > 0 && (
                        <Badge
                            badgeContent={step4Alerts.length}
                            color="primary"
                            style={{zIndex: 6}}
                            className="ml-5"
                            title={`${step4Alerts.length} ${step4Alerts.length === 1 ? 'novo' : 'novos'} ${step4Alerts.length === 1 ? 'plano' : 'planos'} de contas a pagar`}
                        />
                    )}
                    <div className="step-counter" onClick={(e) => handleClickStep(4)}>4</div>
                    <div className="step-name">Pagamentos e compras</div>
                </div>

                <div className={stepActive == 5 ? "stepper-item active" : "stepper-item"}>
                    <div className="step-counter" onClick={(e) => handleClickStep(5)}>5</div>
                    <div className="step-name">Relatório para Download</div>
                </div>
            </div>

            <div id="contentTabs">
                {stepActive === 1 && (
                    <FirstStep setFirstStepCompleted={setFirstStepCompleted} />
                )}
                {stepActive === 2 && (
                    <SecondStep setSecondStepCompleted={setSecondStepCompleted} />
                )}
                {stepActive === 3 && (
                    <ThirdStep setThirdStepCompleted={setThirdStepCompleted} />
                )}
                {stepActive === 4 && (
                    <FourthStep setFourStepCompleted={setFourStepCompleted} />
                )}
                {stepActive === 5 && (
                    <FifthStep setFifthStepCompleted={setFifthStepCompleted} />
                )}
                {/* {
                    ![1,2,3,4].includes(stepActive) && (
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2 className='m-0' style={{ color: "#ccc" }}>Em Breve...</h2>
                        </div>
                    )
                } */}
            </div>
        </MainPageContentWrapper>
    )
}