import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import {List} from '../../_metronic/layout/components/List/List';
import { orderByIdDesc } from '../utils/orderTable';

import api from "../services/Api";
import { Collapse, MenuItem, TextField } from '@material-ui/core';
import { Search } from '../components/Search';
import { Button } from 'react-bootstrap';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../components/ListWithModalChangeSituation';
import useBackendLoad from '../hooks/backendReload';
import { useHistory } from 'react-router';
import PriceListService from '../services/PriceListService';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
    listType: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'name', value: "Nome" },
    { reference: 'listType', value: "Tipo", notSortable: true },
    { reference: 'status', value: "Status", notSortable: true },
];

export function ListPriceList() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [data, setData] = useState<{ priceList: any[] }>({ priceList: [] });

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalList, setCountTotalList] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [listType, setListType] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: any[], count: number}>('priceList', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setData({ priceList: rows });
        setCountTotalList(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [data]);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = data.priceList;

        aux.forEach(({ id, name, priceListType, status }) => {
            const data: BodyDataBaseProps[] = [
                { for: 'id', value: String(id), id: true },
                { for: 'name', value: name },
                { for: 'listType', value: PriceListService.getPriceListTypeText(priceListType) },
                { for: 'status', value: PriceListService.getPriceListStatusText(status) },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [data]);

    const clearSearch = () => {
        setSearchQuery('');
        setListType('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = { searchQuery, listType };

        reloadData();
    }, [data, searchQuery, listType]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            await api.delete(`categoryproduct/${id}`);
        } catch (error) {
            console.log(error);
        }
    }, []);
    
    return (
        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de preços</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2 text-dark"
                            onClick={() => handleClickAdd()}
                        >
                            <i className="fas fa-plus text-dark"></i>
                            Adicionar
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="shadow-sm p-6 rounded-sm border border-2">
                    <h4>Pesquisa avançada</h4>

                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Tipo de Lista"
                                margin="normal"
                                value={listType}
                            >
                                <MenuItem key="0" value="" onClick={() => setListType('')}>
                                    Nenhum
                                </MenuItem>
                                <MenuItem key="1" value="1" onClick={() => setListType('1')}>
                                    Acréscimo
                                </MenuItem>
                                <MenuItem key="2" value="2" onClick={() => setListType('2')}>
                                    Desconto
                                </MenuItem>
                                <MenuItem key="2" value="3" onClick={() => setListType('3')}>
                                    Dinâmica
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-12 d-flex mt-6">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                <i className="fa fa-search"></i>
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                                Limpar
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
            <div className="mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalList}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                />
            </div>
        </MainPageContentWrapper>
    );
}