import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format } from 'date-fns';
import ReactNumberFormat from "react-number-format";
import { Link, useParams } from 'react-router-dom';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import {
    Button,
    Accordion,
    Card,
    Spinner,
    Modal,
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { Autocomplete } from '@material-ui/lab';
import { formatToFloat, formatCurrency, formatNumberToString } from '../../utils/formatCurrency';
import api from '../../services/Api';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { NumericFormat } from '../../components/NumericFormat';
import { FormControl, FormLabel, Grid, InputAdornment, Radio, RadioGroup } from '@material-ui/core';
import { paymentOptions } from '../../utils/paymentOptions';
import { Product as SelectableProduct } from '../../services/ProductService';
import { Customer } from '../../types/Customer';
import LogService from '../../services/LogService';
import CustomerService from '../../services/CustomerService';
import SellerService from '../../services/SellerService';
import NfeService from '../../services/NfeService';
import { getTypePeopleText } from '../../utils/getTypePeopleText';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import { freightModalityById } from '../../utils/freightModality';
import { getCarrierById } from '../../utils/getCarrierById';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { Installment } from '../../types/Installment';
import { useSelector } from 'react-redux';
import CustomerSelect from '../../components/CustomerSelect';
import ProductSelect from '../../components/ProductSelect';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { PriceList } from '../../types/PriceList';
import PriceListService from '../../services/PriceListService';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { NewProductModal } from '../../components/Product/NewProductModal';
import { Product } from '../../types/Product';
import useNewProductForm from '../../hooks/newProductForm';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { useStyles } from '../../hooks/styles';

type urlParam = {
    id: string
}


export function EditNfe() {
    const classes = useStyles();

    const { user } = useSelector((state: any) => state.auth);


    const [nfeConfig, setNfeConfig] = useState<any>();

    const [isSubmit, setIsSubmit] = useState(false);
    // HOOKS
    const history = useHistory();
    const { handleSubmit } = useForm();
    const { id } = useParams<urlParam>();
    const { selectedCompany } = useCompanyBranch({ withNfApiConfig: true });

    const [activeTab, setActiveTab] = useState("details");

    // STATES
    const [customer, setCustomer] = useState<number>();
    const [seller, setSeller] = useState("");
    const [carrier, setCarrier] = useState("");
    const [priceList, setPriceList] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [outputDate, setOutputDate] = useState("");
    const [bcIcms, setBcIcms] = useState("");
    const [valueIcms, setValueIcms] = useState("");
    const [bcIcmsSt, setBcIcmsSt] = useState("");
    const [valueIcmsSt, setValueIcmsSt] = useState("");
    const [valueIpi, setValueIpi] = useState("");
    const [valueProducts, setValueProducts] = useState("");
    const [totalValue, setTotalValue] = useState("");
    const [discountMoney, setDiscountMoney] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [valueFreight, setValueFreight] = useState("");
    const [valueExpenses, setValueExpenses] = useState("");
    const [valueSafe, setValueSafe] = useState("");
    const [valuePis, setValuePis] = useState("");
    const [valueCofins, setValueCofins] = useState("");
    const [valueIrrf, setValueIrrf] = useState("");
    const [valueCsll, setValueCsll] = useState("");
    const [valueSocial, setValueSocial] = useState("");
    const [informDateEmission, setInformDateEmission] = useState("");
    const [informIe, setInformIe] = useState("");
    const [emissionDate, setEmissionDate] = useState("");
    const [taxSubstitute, setTaxSubstitute] = useState("");
    const [hours, setHours] = useState("");
    const [typeAttendance, setTypeAttendance] = useState(0);
    const [destinationOperation, setDestinationOperation] = useState(1);
    const [indicator, setIndicator] = useState(0);
    const [finality, setFinality] = useState(0);
    const [type, setType] = useState(0);
    const [intermediaryCnpj, setIntermediaryCnpj] = useState("");
    const [identifierRegisterIntermediary, setIdentifierRegisterIntermediary] = useState("");
    const [typeDocument, setTypeDocument] = useState("");
    const [receiptElectronic, setReceiptElectronic] = useState("");
    const [modelDocument, setModelDocument] = useState("");
    const [sequentialEcf, setSequentialEcf] = useState("");
    const [counterOperation, setCounterOperation] = useState("");
    const [modelDocumentProducer, setModelDocumentProducer] = useState("");
    const [typePeopleProducerRural, setTypePeopleProducerRural] = useState("");
    const [cpfProducerRural, setCpfProducerRural] = useState("");
    const [cnpjProducerRural, setCnpjProducerRural] = useState("");
    const [ieIssuer, setIeIssuer] = useState("");
    const [exemptProducerRural, setExemptProducerRural] = useState("");
    const [accessKeyCte, setAccessKeyCte] = useState("");
    const [yearEmission, setYearEmission] = useState("");
    const [monthEmission, setMonthEmission] = useState("");
    const [seriesProducerRural, setSeriesProducerRural] = useState("");
    const [numberProducerRural, setNumberProducerRural] = useState("");
    const [ufIssuer, setUfIssuer] = useState("");
    const [grossWeight, setGrossWeight] = useState("");
    const [liquidWeight, setLiquidWeight] = useState("");
    const [amountInstallments, setAmountInstallments] = useState("");
    const [modalityFreight, setModalityFreight] = useState(0);
    const [nature, setNature] = useState("");
    const [seriesNfe, setSeriesNfe] = useState("");
    const [purchaseOrder, setPurchaseOrder] = useState("");
    const [observation, setObservation] = useState("");
    const [internalObservation, setInternalObservation] = useState("");
    const [volumes, setVolumes] = useState("");
    const [species, setSpecies] = useState("");
    const [brand, setBrand] = useState("");
    const [numbering, setNumbering] = useState("");
    const [plateVehicle, setPlateVehicle] = useState("");
    const [rntc, setRntc] = useState("");
    const [situation, setSituation] = useState("");
    const [ufExport, setUfExport] = useState("");
    const [locationExport, setLocationExport] = useState("");

    const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const [installments, setInstallments] = useState<{ installment: Installment[] }>({
        installment: []
    });
    const [products, setProducts] = useState({
        products: [
            {
                icms: "0",
                cfop: "0",
                ipi: "0",
                ncm: "0",
                total: 0,
                amount: 0,
                unitaryValue: 0,
                INSTANCE: {} as SelectableProduct,
            }
        ]
    });

    // MODAL
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomer(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: any } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        getInvoice();
        const date = new Date();
        const formated = format(date, "yyyyMMddHHmmss")
        const outputDateFormated = format(date, "yyyy-MM-dd")

        // setInvoiceNumber(formated);
        setOutputDate(outputDateFormated);

        getNfeConfig();
    }, []);

    useEffect(() => {
        calcTotalInvoice();
        setInstallments({ installment: [] });
    }, [products]);

    useEffect(() => {
        calcTotalInvoice();
        setInstallments({ installment: [] });
    }, [valueProducts, discountMoney, discountPercentage, valueFreight, valueExpenses, valueSafe]);

    async function getInvoice() {
        const { data } = await api.get(`/nfe/${id}`);

        setCustomer(Number(data.customer));
        setSeller(String(data.seller));
        setInvoiceNumber(data.invoiceNumber);
        setTypeDocument(data.typeDocument);
        setReceiptElectronic(data.receiptElectronic);
        setModelDocument(data.modelDocument);
        setModelDocumentProducer(data.modelDocumentProducer);
        setSequentialEcf(data.sequentialEcf);
        setCounterOperation(data.counterOperation);
        setYearEmission(data.yearEmission);
        setMonthEmission(data.monthEmission);
        setSeriesProducerRural(data.seriesProducerRural);
        setNumberProducerRural(data.numberProducerRural);
        setUfIssuer(data.ufIssuer);
        setTypePeopleProducerRural(data.typePeopleProducerRural);
        setCpfProducerRural(data.cpfProducerRural);
        setCnpjProducerRural(data.cnpjProducerRural);
        setIeIssuer(data.ieIssuer);
        setExemptProducerRural(data.exemptProducerRural);
        setAccessKeyCte(data.accessKeyCte);
        setInformDateEmission(data.informDateEmission);
        setEmissionDate(data.emissionDate);
        setInformIe(data.informIe);
        setTaxSubstitute(data.taxSubstitute);
        setNature(data.nature);
        setFinality(data.finality);
        setType(data.type);
        setSeriesNfe(data.seriesNfe);
        setOutputDate(data.outputDate);
        setHours(data.hours);
        setPurchaseOrder(data.purchaseOrder);
        setTypeAttendance(data.typeAttendance);
        setDestinationOperation(data.destinationOperation);
        setIndicator(data.indicator);
        setIntermediaryCnpj(data.intermediaryCnpj);
        setIdentifierRegisterIntermediary(data.identifierRegisterIntermediary);
        setObservation(data.observation);
        setInternalObservation(data.internalObservation);
        setProducts({ products: JSON.parse(data.products) });
        setPriceList(String(data.priceList));
        setBcIcms(formatCurrency(data.bcIcms));
        setValueIcms(formatCurrency(data.valueIcms));
        setBcIcmsSt(formatCurrency(data.bcIcmsSt));
        setValueIcmsSt(formatCurrency(data.valueIcmsSt));
        setValueIpi(formatCurrency(data.valueIpi));
        setValueProducts(formatCurrency(data.valueProducts));
        setDiscountMoney(formatCurrency(data.discountMoney));
        setDiscountPercentage(formatCurrency(data.discountPercentage));
        setValueFreight(formatCurrency(data.valueFreight));
        setValueExpenses(formatCurrency(data.valueExpenses));
        setValueSafe(formatCurrency(data.valueSafe));
        setTotalValue(formatCurrency(data.totalValue));
        setValuePis(formatCurrency(data.valuePis));
        setValueCofins(formatCurrency(data.valueCofins));
        setValueIrrf(formatCurrency(data.valueIrrf));
        setValueCsll(formatCurrency(data.valueCsll));
        setValueSocial(formatCurrency(data.valueSocial));
        setAmountInstallments(data.amountInstallments);
        setInstallments({ installment: JSON.parse(data.installments) });
        setGrossWeight(formatCurrency(data.grossWeight));
        setLiquidWeight(formatCurrency(data.liquidWeight));
        setVolumes(data.volumes);
        setSpecies(data.species);
        setBrand(data.brand);
        setNumbering(data.numbering);
        setModalityFreight(data.modalityFreight);
        setCarrier(String(data.carrier ?? ''));
        setPlateVehicle(data.plateVehicle);
        setRntc(data.rntc);
        setSituation(data.situation);
        setUfExport(data.ufExport);
        setLocationExport(data.locationExport);
    }

    async function getNfeConfig() {
        const response = await api.get('nfeConfig');
        const config = response.data[0];
        setNfeConfig(config);
    }

    const getCfop = useCallback(async () => {
        if (!customer || !selectedCompany || !nfeConfig) return undefined;

        const response = await api.get(`address/user/default/${customer}`);
        const customerAddress = response.data;
        if (!customerAddress) return undefined;

        return customerAddress.state === selectedCompany.state ? nfeConfig.cfopInUf : nfeConfig.cfopOutUf;

        // setProducts((prevState) => {
        //     console.log(prevState);
        //     return {
        //         products: prevState.products.map((product, index) => {
        //             const configCfop = customerAddress.state === selectedCompany.state ? nfeConfig.cfopInUf : nfeConfig.cfopOutUf;
        //             return {
        //                 ...product,
        //                 cfop: !product.cfop || product.cfop === '0' ? configCfop : product.cfop,
        //             };
        //         }),
        //     };
        // });
    }, [customer, selectedCompany, nfeConfig]);

    function handleChangeFinality(e: any) {
        setReceiptElectronic("");
        setModelDocument("");
        setModelDocumentProducer("");
        setSequentialEcf("");
        setCounterOperation("");
        setYearEmission("");
        setMonthEmission("");
        setSeriesProducerRural("");
        setNumberProducerRural("");
        setUfIssuer("");
        setTypePeopleProducerRural("");
        setCpfProducerRural("");
        setCnpjProducerRural("");
        setIeIssuer("");
        setAccessKeyCte("");

        setFinality(Number(e.target.value));
    }

    function checkCpf(value: string) {
        var isSubmit = true;

        if (value) {
            var cpfFormated = value.replace(/[^0-9]/g, "");
            isSubmit = cpf.isValid(cpfFormated);
            if (!isSubmit) {
                setCpfProducerRural("");
                setMsgError(`Cpf do produtor rural inválido!`);
                setShowModalAttention(true);
            }
        }
        return isSubmit;
    }

    function checkCnpj(value: string, type: string) {
        var isSubmit = true;

        if (value) {
            var cnpjFormated = value.replace(/[^0-9]/g, "");
            isSubmit = cnpj.isValid(cnpjFormated);

            if (!isSubmit) {
                if (type == "producerRural") {
                    setCnpjProducerRural('');
                } else {
                    setActiveTab("details");
                    setIntermediaryCnpj('');
                }

                setMsgError(`Cnpj do ${type == "producerRural" ? "produtor rural" : "intermediador da transação"} inválido!`);
                setShowModalAttention(true);
            }
        }
        return isSubmit;
    }

    function distributeValueInstallments(index: number) {
        var installmentsArray = installments.installment;

        var sumBeforeValue = 0;
        for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
            if (indexInstallment <= index) {
                sumBeforeValue += installmentsArray[indexInstallment].value;
            }
        }


        if (sumBeforeValue > formatToFloat(totalValue)) {
            setMsgError("A soma das parcelas ultrapassa o valor total da nota fiscal!");
            setShowModalAttention(true);

            var valueDistributed = formatToFloat(totalValue) / (installmentsArray.length - (index - 1));
            for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
                if (indexInstallment > 0) {
                    installmentsArray[indexInstallment].value = valueDistributed;
                }
            }

        } else {
            var valueDistributed = (formatToFloat(totalValue) - sumBeforeValue) / (installmentsArray.length - (index + 1));
            for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
                if (indexInstallment > index) {
                    installmentsArray[indexInstallment].value = valueDistributed;
                }
            }
        }

        setInstallments({ installment: installmentsArray });
    }

    function calcTotalInvoice() {
        var totalProducts = 0;
        var totalInvoice = 0;
        var discountInMoney = formatToFloat(discountMoney) ?? 0;
        var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
        var freight = formatToFloat(valueFreight) ?? 0;
        var expenses = formatToFloat(valueExpenses) ?? 0;
        var safe = formatToFloat(valueSafe) ?? 0;

        for (var product of products.products) {
            totalProducts += product.total;
        }

        totalInvoice = totalProducts + freight + expenses + safe; // produtos, frete, despesas e seguro
        totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
        totalInvoice = totalInvoice - (totalProducts * (discountInPercentage / 100)); // desconto em porcentagem

        setValueProducts(formatCurrency(totalProducts));
        setTotalValue(formatCurrency(totalInvoice));
    }

    function getTotalInvoice() {
        var totalProducts = 0;
        var totalInvoice = 0;
        var discountInMoney = formatToFloat(discountMoney) ?? 0;
        var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
        var freight = formatToFloat(valueFreight) ?? 0;
        var expenses = formatToFloat(valueExpenses) ?? 0;
        var safe = formatToFloat(valueSafe) ?? 0;

        for (var product of products.products) {
            totalProducts += product.total;
        }

        totalInvoice = totalProducts + freight + expenses + safe; // produtos, frete, despesas e seguro
        totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
        totalInvoice = totalInvoice - (totalProducts * (discountInPercentage / 100)); // desconto em porcentagem

        return totalInvoice;
    }

    function addProducts() {
        var productsArray = products.products;

        productsArray.push({
            icms: "0",
            cfop: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            amount: 0,
            unitaryValue: 0,
            INSTANCE: {} as SelectableProduct,
        });

        setProducts({ products: productsArray });
    }

    function deleteProduct(index: number) {
        var productsArray = products.products;
        productsArray.splice(index, 1);
        setProducts({ products: productsArray });
    }

    const selectProduct = useCallback(async (value: any, index: number) => {
        var productArray = products.products;

        if (value) {
            const defaultCfop = (await getCfop()) ?? '0';
            var productsInKit = value.kit;
            var type = value.type;

            if (value.isKit == "y") {
                type = "kit";

            } else if (value.hasVariation == "y") {
                type = "variation";

            }

            productArray[index].amount = 1;
            productArray[index].total = value.saleValue;
            productArray[index].unitaryValue = value.saleValue;
            productArray[index].ipi = value.ipiRate ? value.ipiRate : "0";
            productArray[index].icms = value.icmsRate ? value.icmsRate : "0";
            productArray[index].cfop = defaultCfop;
            productArray[index].ncm = value.ncm ? value.ncm : "0";
            productArray[index].INSTANCE = {
                id: value.id,
                name: value.name,
                code: value.code,
                saleValue: value.saleValue,
                codeGtinEan: value.codeGtinEan,
                stockInitial: value.stockInitial,
                currentStock: formatToFloat(value.currentStock),
                hasVariation: value.hasVariation,
                isKit: value.isKit,
                kit: productsInKit,
                type: type
            };

        } else {
            productArray[index].icms = "0";
            productArray[index].cfop = "0";
            productArray[index].ipi = "0";
            productArray[index].ncm = "0";
            productArray[index].amount = 0;
            productArray[index].total = 0;
            productArray[index].unitaryValue = 0;
            productArray[index].INSTANCE = {
                id: 0,
                name: "",
                code: "",
                saleValue: 0,
                codeGtinEan: "",
                stockInitial: "",
                currentStock: 0,
                hasVariation: "",
                isKit: "",
                kit: "",
                type: "product"
            };
        }

        changeTabIcon('flaticon-open-box');
        setProducts({ products: productArray });
    }, [getCfop]);

    const handleCreateProduct = useCallback((createdProduct: Product) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        selectProduct(selectableProduct, productBeingCreated.index);
        applyPriceList(priceList);
    }, [productBeingCreated, priceList, selectProduct]);

    function changeAmount(value: any, index: number) {
        var productArray = products.products;
        productArray[index].amount = value;
        productArray[index].total = productArray[index].unitaryValue * formatToFloat(value);

        setProducts({ products: productArray });
    }

    function changeProductValue(value: any, index: number) {
        var productArray = products.products;
        productArray[index].unitaryValue = formatToFloat(value);
        productArray[index].total = productArray[index].unitaryValue * Number(productArray[index].amount);

        setProducts({ products: productArray });
    }

    function setIpi(value: any, index: number) {
        var productArray = products.products;
        productArray[index].ipi = value;
        setProducts({ products: productArray });
    }

    function setIcms(value: any, index: number) {
        var productArray = products.products;
        productArray[index].icms = value;
        setProducts({ products: productArray });
    }

    function setCfop(value: any, index: number) {
        var productArray = products.products;
        productArray[index].cfop = value;
        setProducts({ products: productArray });
    }

    function setNcm(value: any, index: number) {
        var productArray = products.products;
        productArray[index].ncm = value;
        setProducts({ products: productArray });
    }

    async function applyPriceList(idPriceList: any) {
        if (Number(idPriceList)) {
            var objPriceList = await api.get(`/priceList/${idPriceList}`);
            if (objPriceList.data.priceListType == "1") {
                setTotalValue(String(getTotalInvoice() + objPriceList.data.value));

                // var productsArray     = products.products;           
                // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
                //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                // } 
                // setProducts({ products: productsArray });

            } else if (objPriceList.data.priceListType == "2") {
                setTotalValue(String(getTotalInvoice() - objPriceList.data.value));

                // var productsArray     = products.products;           
                // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
                //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                // } 
                // setProducts({ products: productsArray });

            } else {
                var priceListProducts = JSON.parse(objPriceList.data.products);
                var productsArray = products.products;

                for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                    const { data } = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                    var price = priceListProducts.find((element: any) => element.productId == productsArray[indexProduct].INSTANCE.id);

                    if (data) {
                        if (price) {
                            if (price.type == "1") {
                                productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue) - formatToFloat(price.value);
                                productsArray[indexProduct].total = productsArray[indexProduct].amount * productsArray[indexProduct].unitaryValue;

                            } else {
                                productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue) + formatToFloat(price.value);
                                productsArray[indexProduct].total = productsArray[indexProduct].amount * productsArray[indexProduct].unitaryValue;
                            }

                        } else {
                            productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue);
                            productsArray[indexProduct].total = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                        }
                    }
                }
                setProducts({ products: productsArray });
            }
        } else {
            // var productsArray     = products.products;           
            // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
            //     const {data}      = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);

            //     if (data) {
            //         productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
            //         productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
            //     }
            // }                
            // setProducts({ products: productsArray });
        }
    }

    function setValuesInstallments(value: any, index: number, type: string) {
        var installmentArray = installments.installment;

        if (type == "date") {
            installmentArray[index].date = value;

        } else if (type == "value") {
            installmentArray[index].value = formatToFloat(value);

        } else if (type == "payment") {
            installmentArray[index].payment = value;

        } else {
            installmentArray[index].comments = value;
        }

        setInstallments({ installment: installmentArray });
    }

    function generateInstallments() {
        if (formatToFloat(totalValue)) {
            var installmentsArray = [];
            var today = new Date();
            var day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
            var month = String(today.getMonth() + 1);
            var year = today.getFullYear();

            var valueInstallment = formatToFloat(totalValue) / formatToFloat(amountInstallments);

            for (var index = 0; index < formatToFloat(amountInstallments); index++) {
                month = today.getMonth() < 10 ? "0" + (today.getMonth() + (index + 1)) : String(today.getMonth() + (index + 1));

                installmentsArray.push({
                    date: year + "-" + month + "-" + day,
                    value: valueInstallment,
                    comments: "",
                    payment: "Cartão de Crédito"
                });
            }
            setInstallments({ installment: installmentsArray });

        } else {
            setMsgError("Selecione pelo menos um produto para gerar as parcelas!");
            setShowModalAttention(true);
        }
    }

    function hasInstallments() {
        var isSubmit = true;

        // if (!installments.installment.length) {
        //     setMsgError("Informe as parcelas da nota fiscal!");
        //     setShowModalAttention(true);
        //     isSubmit = false;
        // }

        return isSubmit;
    }

    async function validateIssueData() {
        if (destinationOperation === 3) {
            const response = await api.get<Customer>(`customer/${customer}`);
            const selectedCustomer = response.data;
            if (selectedCustomer.isForeign === 'n' || !selectedCustomer.passport) {
                setMsgError("Para nota fiscal no exterior, é necessário que o cliente selecionado possua passaporte estrangeiro preenchido! (Na aba Cadastros -> Clientes/Fornecedores)");
                setShowModalAttention(true);
                return false;
            }
        }

        return true;
    }

    function validateForm() {
        var isSubmit = true;

        if (!customer) {
            setMsgError("Selecione o cliente!");
            setShowModalAttention(true);
            isSubmit = false;
            return false;
        }

        if (!products.products[0].total) {
            setActiveTab("products");
            setMsgError("Selecione pelo menos um produto para nota fiscal!");
            setShowModalAttention(true);
            isSubmit = false;
            return false;
        }

        if (informDateEmission && emissionDate) {
            const dateIsValid = dateIsBetweenRange(emissionDate, '', extractDateStringFromTimestamp());
            if (!dateIsValid) {
                setActiveTab("details");
                setMsgError("A data de emissão não pode ser maior que a data atual!");
                setShowModalAttention(true);
                isSubmit = false;
                return false;
            }
        }

        // Validando campo hora
        var auxHour = hours.split(':');
        if (Number(auxHour[0]) > 23) {
            setActiveTab("details");
            setMsgError("Horário informado inválido!");
            setShowModalAttention(true);
            setHours("");
            isSubmit = false;
            return false;

        } else if (Number(auxHour[1]) > 59) {
            setActiveTab("details");
            setMsgError("Horário informado inválido!");
            setShowModalAttention(true);
            setHours("");
            isSubmit = false;
            return false;
        }

        if (indicator === 1) {
            if (!intermediaryCnpj) {
                setActiveTab("details");
                setMsgError("Para o intermediador selecionado é necessário informar o CNPJ do intermediador!");
                setShowModalAttention(true);
                return false;
            }

            if (!identifierRegisterIntermediary) {
                setActiveTab("details");
                setMsgError("Para o intermediador selecionado é necessário informar identificador do cadastro do intermediador!");
                setShowModalAttention(true);
                return false;
            }
        }

        return isSubmit;
    }

    const onSubmit = async (data: any) => {
        if (
            validateForm() && hasInstallments() && checkCpf(cpfProducerRural)
            && checkCnpj(intermediaryCnpj, 'intermediary') && checkCnpj(cnpjProducerRural, 'producerRural')
            && (await validateIssueData())
        ) {
            setIsSubmit(true);

            try {

                const lastInputs = await api.get(`/nfe/${id}`);

                const raw = {
                    ...data,
                    customer: Number(customer),
                    seller: Number(seller) ?? null,
                    invoiceNumber: invoiceNumber,

                    nature: nature,
                    typeDocument: typeDocument,
                    receiptElectronic: receiptElectronic,
                    modelDocument: modelDocument,
                    modelDocumentProducer: modelDocumentProducer,
                    sequentialEcf: sequentialEcf,
                    counterOperation: counterOperation,
                    yearEmission: yearEmission,
                    monthEmission: monthEmission,
                    seriesProducerRural: seriesProducerRural,
                    numberProducerRural: numberProducerRural,
                    ufIssuer: ufIssuer,
                    typePeopleProducerRural: typePeopleProducerRural,
                    cpfProducerRural: cpfProducerRural,
                    cnpjProducerRural: cnpjProducerRural,
                    ieIssuer: ieIssuer,
                    exemptProducerRural: exemptProducerRural,
                    accessKeyCte: accessKeyCte,
                    informDateEmission: informDateEmission,
                    emissionDate: informDateEmission ? emissionDate : null,
                    informIe: informIe,
                    taxSubstitute: informIe ? taxSubstitute : null,
                    finality: finality,
                    type: type,
                    outputDate: outputDate,
                    hours: hours,
                    typeAttendance: typeAttendance,
                    destinationOperation: destinationOperation,
                    indicator: indicator,
                    intermediaryCnpj: intermediaryCnpj,
                    identifierRegisterIntermediary: identifierRegisterIntermediary,
                    products: JSON.stringify(products.products),
                    priceList: Number(priceList) ?? null,

                    bcIcms: formatToFloat(bcIcms),
                    valueIcms: formatToFloat(valueIcms),
                    bcIcmsSt: formatToFloat(bcIcmsSt),
                    valueIcmsSt: formatToFloat(valueIcmsSt),
                    valueIpi: formatToFloat(valueIpi),
                    valueProducts: formatToFloat(valueProducts),
                    discountMoney: formatToFloat(discountMoney),
                    discountPercentage: formatToFloat(discountPercentage),
                    valueFreight: formatToFloat(valueFreight),
                    valueExpenses: formatToFloat(valueExpenses),
                    valueSafe: formatToFloat(valueSafe),
                    totalValue: formatToFloat(totalValue),
                    valuePis: formatToFloat(valuePis),
                    valueCofins: formatToFloat(valueCofins),
                    valueIrrf: formatToFloat(valueIrrf),
                    valueCsll: formatToFloat(valueCsll),
                    valueSocial: formatToFloat(valueSocial),
                    amountInstallments: formatToFloat(amountInstallments),
                    installments: JSON.stringify(installments.installment),
                    grossWeight: formatToFloat(grossWeight),
                    liquidWeight: formatToFloat(liquidWeight),
                    modalityFreight: modalityFreight,
                    carrier: carrier && ![4, 3, 9].includes(modalityFreight) ? Number(carrier) : null,
                    volumes: volumes ? parseInt(volumes) : null,
                    numbering: numbering || null,
                    species: species || null,
                    brand: brand || null,
                    plateVehicle: plateVehicle || null,
                    rntc: rntc || null,
                    situation: "open",
                    ufExport: type === 2 ? ufExport : null,
                    locationExport: type === 2 ? locationExport : null,
                };

                const result = await api.post(`/nfe/${id}`, raw);

                LogService.logEdit({
                    itemId: result.data.id,
                    itemName: 'Nf-e',
                    module: 'Notas Fiscais',
                    oldData: lastInputs.data,
                    newData: result.data,
                    formattedFields: {
                        customer: (value) => CustomerService.getNameByIdAsync(value),
                        seller: (value) => SellerService.getNameByIdAsync(value),
                        finality: (value) => NfeService.getFinality(value),
                        type: (value) => NfeService.getType(value),
                        typeDocument: (value) => NfeService.getTypeDocument(value),
                        typeAttendance: (value) => NfeService.getTypeAttendance(value),
                        discountMoney: (value) => formatNumberToString(value),
                        emissionDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        outputDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        valueFreight: (value) => formatNumberToString(value),
                        valueExpenses: (value) => formatNumberToString(value),
                        valueSafe: (value) => formatNumberToString(value),
                        valuePis: (value) => formatNumberToString(value),
                        valueCofins: (value) => formatNumberToString(value),
                        valueIrrf: (value) => formatNumberToString(value),
                        valueCsll: (value) => formatNumberToString(value),
                        valueSocial: (value) => formatNumberToString(value),
                        typePeopleProducerRural: (value) => getTypePeopleText(value),
                        destinationOperation: (value) => NfeService.getDestinationOperation(value),
                        carrier: (value) => getCarrierById(value),
                        exemptProducerRural: (value) => getYesOrNoText(value),
                        informDateEmission: (value) => getYesOrNoText(value),
                        informIe: (value) => getYesOrNoText(value),
                        modalityFreight: (value) => freightModalityById(value),
                        indicator: (value) => NfeService.getindicator(value)
                    },
                    fieldsMap: {
                        customer: 'Cliente',
                        seller: 'Vendedor',
                        nature: 'Natureza,',
                        finality: 'Finalidade',
                        type: 'Tipo',
                        typeDocument: 'Tipo de documento',
                        receiptElectronic: 'Nfe referenciada',
                        modelDocument: 'Modelo do documento',
                        modelDocumentProducer: 'Modelo do documento Produtor',
                        sequentialEcf: 'Ordem sequencial ECF',
                        counterOperation: 'Contador de ordem de operação',
                        yearEmission: 'Ano da emissão',
                        monthEmission: 'Mês da emissão',
                        seriesProducerRural: 'Série',
                        numberProducerRural: 'Número',
                        ufIssuer: 'UF do emitente',
                        typePeopleProducerRural: 'Tipo de pessoa',
                        cpfProducerRural: 'CPF',
                        cnpjProducerRural: 'CNPJ',
                        ieIssuer: 'IE do emitente',
                        exemptProducerRural: 'Isento ?',
                        accessKeyCte: 'Chave de acesso do CT-e',
                        informDateEmission: 'Informar data de emissão ?',
                        emissionDate: 'Data de emissão',
                        informIe: 'Informar IE do substituto tributário ?',
                        taxSubstitute: 'IE do substituto tributário',
                        outputDate: 'Data de saída',
                        hours: 'Hora',
                        typeAttendance: 'Tipo de atendimento',
                        destinationOperation: 'Destino da operação',
                        indicator: 'Indicador de intermediador',
                        intermediaryCnpj: 'CNPJ do intermediador da transição',
                        identifierRegisterIntermediary: 'Identificador cadastro intermediador',
                        observation: 'Observações',
                        internalObservation: 'Observações internas',
                        discountMoney: 'Desconto (R$)',
                        valueFreight: 'Valor do frete',
                        valueExpenses: 'Valor despesas',
                        valueSafe: 'Valor do seguro',
                        valuePis: 'Valor do PIS retido',
                        valueCofins: 'valor COFFINS retido',
                        valueIrrf: 'Valor IRRF retido',
                        valueCsll: 'Valor CSLLretido',
                        valueSocial: 'Prev. social retido',
                        amountInstallments: 'Quantidade de parcelas',
                        grossWeight: 'Peso bruto',
                        liquidWeight: 'Peso líquido',
                        modalityFreight: 'modalidade de frete',
                        carrier: 'Transportadora',
                        volumes: 'Volumes',
                        numbering: 'Numeração',
                        species: 'Espécie',
                        brand: 'Marca',
                        plateVehicle: 'Placa',
                        rntc: 'RNTC (ANTT)',
                        ufExport: 'UF de embarque',
                        locationExport: 'Local Embarque'
                    },
                });


                history.push("/notas-fiscais");


            } catch (error) {
                console.log(error);
            }

            setIsSubmit(false);
        }

    };

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-open-box") {
            if (products.products[0].total) {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setProductsInvalid(false);
            } else {
                setProductsInvalid(true);

                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                prev_icon?.classList.add("text-danger");
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('details')?.classList.remove('current');
        document.getElementById('products')?.classList.remove('current');
        document.getElementById('total-invoice')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('transport')?.classList.remove('current');
        document.getElementById('export')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'details') {
            setNextButton('first');
        } else if (type === 2 && tab === 'export' || type !== 2 && tab == 'transport') {
            setNextButton('last');
        } else {
            setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['details', 'products', 'total-invoice', 'payment', 'transport', 'export'];
        let arr2 = ['flaticon-doc', 'flaticon-open-box', 'flaticon-coins', 'flaticon-list-1', 'flaticon-truck', 'flaticon-up-arrow'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
                <NewCustomerModal
                    showModal={showModalNewCustomer}
                    setShowModal={setShowModalNewCustomer}
                    onCreateCustomer={handleCreateCustomer}
                    defaultData={newCustomerDefaultData}
                />

                <NewProductModal
                    showModal={showModalNewProduct}
                    setShowModal={setShowModalNewProduct}
                    onCreateProduct={handleCreateProduct}
                    defaultData={newProductDefaultData}
                    allowChangeType={false}
                />

                <Modal
                    show={showModalAttention}
                    onHide={() => setShowModalAttention(false)}
                    aria-labelledby="contained-modal-warning"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                            Atenção
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <b>{msgError}</b>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>

                <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                    <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="details" data-kt-stepper-element='nav' onClick={() => changeTab('details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a Nota Fiscal</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Produtos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Produtos da Nota Fiscal</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="total-invoice" data-kt-stepper-element='nav' onClick={() => changeTab('total-invoice', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Totais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Totais da Nota Fiscal</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Pagamentos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Pagamentos da Nota Fiscal</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="transport" data-kt-stepper-element='nav' onClick={() => changeTab('transport', 'flaticon-truck', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-truck icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Transporte</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Transporte da Nota Fiscal</div>
                                            </div>
                                            </div>
                                            {
                                                type === 2 && (
                                                    <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                                )
                                            }
                                        </div>
                                        {
                                            type === 2 && (
                                                <div className='stepper-item' id="export" data-kt-stepper-element='nav' onClick={() => changeTab('export', 'flaticon-up-arrow', previousIcon)} style={{cursor: "pointer"}}>
                                                    <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                    <div className='stepper-icon w-40px h-40px'>
                                                            <i className='stepper-check fas fa-check'></i>
                                                            <i className="flaticon-up-arrow icon_change_color" style={{color: "#666"}}></i>
                                                    </div>
                                                    <div className='stepper-label'>
                                                            <h3 className='stepper-title text-break'>Exportação</h3>
                                                            <div className='stepper-desc fw-semibold text-break'>Exportação da Nota Fiscal</div>
                                                    </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                </div>
                            </div>
                        </div>               
                    </div>
                        <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/notas-fiscais">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button
                                            type="button"
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {isSubmit ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />

                                                <span className="ml-2">
                                                    Aguarde...
                                                </span>
                                            </> : <>
                                                <span>
                                                    Salvar
                                                </span>
                                            </>}
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                        <Grid item lg={6} md={6} xs={12}>
                                <CustomerSelect
                                    label="Cliente *"
                                    isEdit
                                    customerId={customer}
                                    setCustomerId={setCustomer}
                                    disabled={user.isAccountant == "y" ? true : false}
                                    onClickAddCustomer={handleClickAddCustomer}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    disabled
                                    label="Nº NF-e"
                                    margin="normal"
                                    value={invoiceNumber}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>

                                <TextField
                                    size="small"
                                    label="Natureza"
                                    margin="normal"
                                    inputProps={{ maxLength: 50 }}
                                    value={nature}
                                    onChange={(e) => setNature(e.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />

                            </Grid>

                            <Grid item lg={3} md={6} xs={12}>

                                <TextField
                                    select
                                    label="Finalidade"
                                    margin="normal"
                                    size="small"
                                    value={finality}
                                    onChange={handleChangeFinality}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key="0" value="1">
                                        NF-e normal
                                    </MenuItem>

                                    <MenuItem key="1" value="2">
                                        NF-e complementar
                                    </MenuItem>

                                    <MenuItem key="2" value="3">
                                        NF-e de ajuste
                                    </MenuItem>

                                    <MenuItem key="3" value="4">
                                        Devolução/Retorno
                                    </MenuItem>
                                </TextField>

                            </Grid>

                            <Grid item lg={3} md={6} xs={12}>

                                <TextField
                                    select
                                    label="Tipo"
                                    margin="normal"
                                    size="small"
                                    value={type}
                                    onChange={(e) => setType(Number(e.target.value))}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key="0" value="0">
                                        Entrada
                                    </MenuItem>

                                    <MenuItem key="1" value="1">
                                        Saída
                                    </MenuItem>

                                    <MenuItem key="2" value="2">
                                        Exportação
                                    </MenuItem>

                                    <MenuItem key="3" value="3">
                                        Importação
                                    </MenuItem>
                                </TextField>

                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>

                                <TextField
                                    label="Série NF-e"
                                    disabled
                                    margin="normal"
                                    size="small"
                                    inputProps={{ maxLength: 3 }}
                                    value={seriesNfe}
                                    onChange={(e) => setSeriesNfe(e.target.value)}
                                >
                                </TextField>

                            </Grid>

                            {
                            finality && finality !== 1
                                ?
                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Tipo de Documento</FormLabel>
                                            <RadioGroup
                                                aria-label="Tipo de Documento"
                                                value={typeDocument}
                                                onChange={(e) => setTypeDocument(e.target.value)}
                                            >
                                                <FormControlLabel value="receipt" control={<Radio disabled={user.isAccountant == "y" ? true : false} />} label="Nota Fiscal" />
                                                <FormControlLabel value="taxCoupon" control={<Radio disabled={user.isAccountant == "y" ? true : false} />} label="Cupom Fiscal" />
                                                <FormControlLabel value="ruralProducer" control={<Radio disabled={user.isAccountant == "y" ? true : false} onChange={() => {
                                                    setYearEmission(String(new Date().getFullYear()));
                                                    setMonthEmission(String(new Date().getMonth() + 1));
                                                    setTypePeopleProducerRural("physical");
                                                }} />} label="Produtor Rural" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {
                                        typeDocument == "receipt"
                                            ?
                                            <Grid item lg={10} md={6} xs={12}>
                                                <ReactNumberFormat
                                                    margin="normal"
                                                    customInput={TextField}
                                                    label="Nota Fiscal Eletrônica Referenciada"
                                                    format="##################################"
                                                    mask="_"
                                                    value={receiptElectronic}
                                                    onChange={(e) => setReceiptElectronic(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>
                                            : <></>
                                    }

                                    {
                                        typeDocument == "taxCoupon"
                                            ?
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        select
                                                        label="Modelo do Documento"
                                                        margin="normal"
                                                        size="small"
                                                        value={modelDocument}
                                                        onChange={(e) => setModelDocument(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    >

                                                        <MenuItem key="0" value="">
                                                            Selecione
                                                        </MenuItem>

                                                        <MenuItem key="1" value="2B">
                                                            Cupom Fiscal emitido por máquina registradora (não ECF)
                                                        </MenuItem>

                                                        <MenuItem key="2" value="2C">
                                                            Cupom Fiscal PDV
                                                        </MenuItem>

                                                        <MenuItem key="3" value="2D">
                                                            Cupom Fiscal (emitido por ECF)
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <ReactNumberFormat
                                                        margin="normal"
                                                        customInput={TextField}
                                                        label="Ordem sequencial do ECF"
                                                        format="###"
                                                        mask="_"
                                                        value={sequentialEcf}
                                                        onChange={(e) => setSequentialEcf(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <ReactNumberFormat
                                                        margin="normal"
                                                        customInput={TextField}
                                                        label="Contador de Ordem de Operação"
                                                        format="######"
                                                        mask="_"
                                                        value={counterOperation}
                                                        onChange={(e) => setCounterOperation(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </Grid>
                                            </>
                                            : <></>
                                    }

                                    {
                                        typeDocument == "ruralProducer"
                                            ?
                                            <Grid item lg={10} md={6} xs={12}>
                                                <div className="row">
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            select
                                                            label="Modelo do Documento"
                                                            margin="normal"
                                                            size="small"
                                                            value={modelDocumentProducer}
                                                            onChange={(e) => setModelDocumentProducer(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >

                                                            <MenuItem key="0" value="">
                                                                Selecione
                                                            </MenuItem>

                                                            <MenuItem key="1" value="01">
                                                                Notal Fiscal
                                                            </MenuItem>

                                                            <MenuItem key="2" value="04">
                                                                Nota Fiscal de Produtor
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            size="small"
                                                            type="number"
                                                            label="Ano emissão"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={yearEmission}
                                                            onChange={(e) => setYearEmission}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            select
                                                            label="Mês emissão"
                                                            margin="normal"
                                                            size="small"
                                                            value={monthEmission}
                                                            onChange={(e) => setMonthEmission}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >
                                                            <MenuItem key="0" value="1">
                                                                01
                                                            </MenuItem>
                                                            <MenuItem key="1" value="2">
                                                                02
                                                            </MenuItem>
                                                            <MenuItem key="2" value="3">
                                                                03
                                                            </MenuItem>
                                                            <MenuItem key="3" value="4">
                                                                04
                                                            </MenuItem>
                                                            <MenuItem key="4" value="5">
                                                                05
                                                            </MenuItem>
                                                            <MenuItem key="5" value="6">
                                                                06
                                                            </MenuItem>
                                                            <MenuItem key="6" value="7">
                                                                07
                                                            </MenuItem>
                                                            <MenuItem key="7" value="8">
                                                                08
                                                            </MenuItem>
                                                            <MenuItem key="8" value="9">
                                                                09
                                                            </MenuItem>
                                                            <MenuItem key="9" value="10">
                                                                10
                                                            </MenuItem>
                                                            <MenuItem key="10" value="11">
                                                                11
                                                            </MenuItem>
                                                            <MenuItem key="11" value="12">
                                                                12
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <ReactNumberFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            label="Série"
                                                            format="###"
                                                            mask=""
                                                            value={seriesProducerRural}
                                                            onChange={(e) => setSeriesProducerRural(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <ReactNumberFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            label="Número"
                                                            format="#########"
                                                            mask=""
                                                            value={numberProducerRural}
                                                            onChange={(e) => setNumberProducerRural(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            select
                                                            label="UF do emitente"
                                                            margin="normal"
                                                            size="small"
                                                            value={ufIssuer}
                                                            onChange={(e) => setUfIssuer(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >
                                                            <MenuItem key="0" value="RO">
                                                                RO
                                                            </MenuItem>
                                                            <MenuItem key="1" value="AC">
                                                                AC
                                                            </MenuItem>
                                                            <MenuItem key="2" value="AM">
                                                                AM
                                                            </MenuItem>
                                                            <MenuItem key="3" value="RR">
                                                                RR
                                                            </MenuItem>
                                                            <MenuItem key="4" value="PA">
                                                                PA
                                                            </MenuItem>
                                                            <MenuItem key="5" value="AP">
                                                                AP
                                                            </MenuItem>
                                                            <MenuItem key="6" value="TO">
                                                                TO
                                                            </MenuItem>
                                                            <MenuItem key="7" value="MA">
                                                                MA
                                                            </MenuItem>
                                                            <MenuItem key="8" value="PI">
                                                                PI
                                                            </MenuItem>
                                                            <MenuItem key="9" value="CE">
                                                                CE
                                                            </MenuItem>
                                                            <MenuItem key="10" value="RN">
                                                                RN
                                                            </MenuItem>
                                                            <MenuItem key="11" value="PB">
                                                                PB
                                                            </MenuItem>
                                                            <MenuItem key="12" value="PE">
                                                                PE
                                                            </MenuItem>
                                                            <MenuItem key="13" value="AL">
                                                                AL
                                                            </MenuItem>
                                                            <MenuItem key="14" value="SE">
                                                                SE
                                                            </MenuItem>
                                                            <MenuItem key="15" value="BA">
                                                                BA
                                                            </MenuItem>
                                                            <MenuItem key="16" value="MG">
                                                                MG
                                                            </MenuItem>
                                                            <MenuItem key="17" value="ES">
                                                                ES
                                                            </MenuItem>
                                                            <MenuItem key="18" value="RJ">
                                                                RJ
                                                            </MenuItem>
                                                            <MenuItem key="19" value="SP">
                                                                SP
                                                            </MenuItem>
                                                            <MenuItem key="20" value="PR">
                                                                PR
                                                            </MenuItem>
                                                            <MenuItem key="21" value="SC">
                                                                SC
                                                            </MenuItem>
                                                            <MenuItem key="22" value="RS">
                                                                RS
                                                            </MenuItem>
                                                            <MenuItem key="23" value="MS">
                                                                MS
                                                            </MenuItem>
                                                            <MenuItem key="24" value="MT">
                                                                MT
                                                            </MenuItem>
                                                            <MenuItem key="25" value="GO">
                                                                GO
                                                            </MenuItem>
                                                            <MenuItem key="26" value="DF">
                                                                DF
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </div>
                                                <div className="row">
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            select
                                                            label="Tipo de Pessoa"
                                                            margin="normal"
                                                            size="small"
                                                            value={typePeopleProducerRural}
                                                            onChange={(e) => setTypePeopleProducerRural(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >
                                                            <MenuItem key="0" value="physical">
                                                                Pessoal Física
                                                            </MenuItem>
                                                            <MenuItem key="1" value="legal">
                                                                Pessoa Jurídica
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {
                                                            typePeopleProducerRural == "physical"
                                                                ?
                                                                <ReactNumberFormat
                                                                    margin="normal"
                                                                    customInput={TextField}
                                                                    label="CPF"
                                                                    format="###.###.###-##"
                                                                    mask="_"
                                                                    value={cpfProducerRural}
                                                                    onChange={(e) => setCpfProducerRural(e.target.value)}
                                                                    onBlur={(e) => checkCpf(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                                :
                                                                <ReactNumberFormat
                                                                    margin="normal"
                                                                    customInput={TextField}
                                                                    label="CNPJ"
                                                                    format="##.###.###/####-##"
                                                                    mask="_"
                                                                    value={cnpjProducerRural}
                                                                    onChange={(e) => setCnpjProducerRural(e.target.value)}
                                                                    onBlur={(e) => checkCnpj(e.target.value, 'producerRural')}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <ReactNumberFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            label="IE do emitente"
                                                            format="##############"
                                                            mask=""
                                                            value={ieIssuer}
                                                            onChange={(e) => setIeIssuer(e.target.value)}
                                                            disabled={exemptProducerRural == "y" || user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={exemptProducerRural == "y" ? true : false} onChange={() => { setIeIssuer(exemptProducerRural ? "ISENTO" : ""); setExemptProducerRural(exemptProducerRural == "y" ? "n" : "y") }} />}
                                                            label="Isento"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                </div>
                                                <div className="row">
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <ReactNumberFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            label="Chave de acesso do CT-e"
                                                            format="###########################################"
                                                            mask="_"
                                                            value={accessKeyCte}
                                                            onChange={(e) => setAccessKeyCte(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </div>

                                            </Grid>
                                            : <></>
                                    }

                                </div>
                                : <></>
                        }
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/notas-fiscais" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs
                            activeKey={activeTab}
                            onSelect={(tab: string) => setActiveTab(tab)}
                            id='invoice-form-tabs'
                            style={{ display: "none" }}
                        >

                            <Tab
                                eventKey='details'
                                title='Detalhes'
                            >
                                <div className="row">
                                    <Grid item lg={6} md={6} xs={12}>

                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={informDateEmission == "y" ? true : false} onChange={() => setInformDateEmission(informDateEmission == "y" ? "n" : "y")} />}
                                            label="Informar data de emissão"
                                            labelPlacement="end"
                                            name="informDateEmission"
                                        />

                                        {
                                            informDateEmission == "y"
                                                ?
                                                <div className="row">
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <TextField
                                                            size="small"
                                                            type="date"
                                                            label="Data de emissão"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={emissionDate}
                                                            onChange={(e) => setEmissionDate(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </div>
                                                : <></>
                                        }

                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>

                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={informIe == "y" ? true : false} onChange={() => setInformIe(informIe == "y" ? "n" : "y")} />}
                                            label="Informar IE do substituto tributário"
                                            labelPlacement="end"
                                            name="InformIe"
                                        />

                                        {
                                            informIe == "y"
                                                ?
                                                <div className="row">
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <TextField
                                                            size="small"
                                                            label="IE do substituto tributário"
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{ maxLength: 14 }}
                                                            value={taxSubstitute}
                                                            onChange={(e) => setTaxSubstitute(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </div>
                                                : <></>
                                        }
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            type="date"
                                            label="Data de saída"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={outputDate}
                                            onChange={(e) => setOutputDate(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <ReactNumberFormat
                                            margin="normal"
                                            customInput={TextField}
                                            label="Hora"
                                            format="##:##"
                                            mask="_"
                                            value={hours}
                                            onChange={(e) => setHours(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>

                                        <TextField
                                            label="Ordem de compra"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 50 }}
                                            value={purchaseOrder}
                                            onChange={(e) => setPurchaseOrder(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>

                                        <TextField
                                            select
                                            label="Tipo de atendimento"
                                            margin="normal"
                                            size="small"
                                            value={typeAttendance}
                                            onChange={(e) => {
                                                setTypeAttendance(Number(e.target.value));
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="0">
                                                Não se aplica
                                            </MenuItem>

                                            <MenuItem key="1" value="1">
                                                Operação presencial
                                            </MenuItem>

                                            <MenuItem key="2" value="2">
                                                Operação não presencial, pela internet
                                            </MenuItem>

                                            <MenuItem key="3" value="3">
                                                Operação não presencial, Teleatendimento
                                            </MenuItem>

                                            <MenuItem key="4" value="4">
                                                NFC-e em operação com entrega em domicílio
                                            </MenuItem>

                                            <MenuItem key="5" value="9">
                                                Operação não presencial, Outros
                                            </MenuItem>
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>

                                        <TextField
                                            select
                                            label="Destino da operação"
                                            margin="normal"
                                            size="small"
                                            value={destinationOperation}
                                            onChange={(e) => setDestinationOperation(Number(e.target.value))}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="1" value="1">
                                                Operação Interna
                                            </MenuItem>

                                            <MenuItem key="2" value="2">
                                                Operação Interestadual
                                            </MenuItem>

                                            <MenuItem key="3" value="3">
                                                Operação com exterior
                                            </MenuItem>
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>

                                        <TextField
                                            select
                                            label="Indicador de intermediador/marketplace"
                                            margin="normal"
                                            size="small"
                                            value={indicator}
                                            onChange={(e) => setIndicator(Number(e.target.value))}
                                            disabled={typeAttendance === 0 || user.isAccountant == "y" ? true : false}
                                        >

                                            <MenuItem key="0" value="0">
                                                Operação sem intermediador (em site ou plataforma própria)
                                            </MenuItem>

                                            <MenuItem key="1" value="1">
                                                Operação em site ou plataforma de terceiros (intermediadores/marketplace)
                                            </MenuItem>
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <ReactNumberFormat
                                            margin="normal"
                                            customInput={TextField}
                                            label="CNPJ do intermediador da transação"
                                            format="##.###.###/####-##"
                                            mask="_"
                                            disabled={indicator === 0 || user.isAccountant == "y" ? true : false}
                                            value={intermediaryCnpj}
                                            onChange={(e) => setIntermediaryCnpj(e.target.value)}
                                            onBlur={(e) => checkCnpj(e.target.value, 'intermediary')}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>

                                        <TextField
                                            label="Identificador cadastro intermediador"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                            disabled={indicator === 0 || user.isAccountant == "y" ? true : false}
                                            value={identifierRegisterIntermediary}
                                            onChange={(e) => setIdentifierRegisterIntermediary(e.target.value)}
                                        >
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>

                                        <TextField
                                            label="Observações"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 5000 }}
                                            value={observation}
                                            onChange={(e) => setObservation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />

                                    </Grid>


                                    <Grid item lg={6} md={6} xs={12}>

                                        <TextField
                                            label="Observações Internas"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 5000 }}
                                            value={internalObservation}
                                            onChange={(e) => setInternalObservation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />

                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <Button
                                            className="text-dark"
                                            variant="success"
                                            size="sm"
                                            block
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <i className="far fa-comments text-dark"></i>
                                            Mensagens padrões
                                        </Button>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='products'
                                title='Produtos'
                            >
                                {
                                    productsInvalid && (
                                        <p className="text-danger">Selecione pelo menos um produto para nota fiscal!</p>
                                    )
                                }
                                <div className="row">
                                    {
                                        products.products.map((product: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Grid item lg={4} md={6} xs={12} className='d-flex align-items-center'>
                                                        <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                                                            <label className="text-white m-0">{index + 1}.</label>

                                                            {
                                                                product.INSTANCE.isKit == "y"
                                                                    ?
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id='tooltip-top'>
                                                                                <b>Produtos do Kit</b><br />
                                                                                {product.INSTANCE.kit}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            variant="primary"
                                                                            className="d-flex flex-row align-items-center justify-content-center ml-2 p-0"
                                                                            style={{ padding: "5px", background: "transparent" }}
                                                                        >
                                                                            <i className="flaticon2-information m-0 p-0"></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                        <ProductSelect
                                                            label="Produto"
                                                            isEdit
                                                            selectedProduct={product}
                                                            onChangeProduct={(prod) => { selectProduct(prod, index); applyPriceList(priceList) }}
                                                            type="product"
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                            onClickAddProduct={(typedText) => handleClickAddProduct(typedText, index, product)}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label="Qtde."
                                                            margin="normal"
                                                            name="amount"

                                                            value={product.amount}
                                                            onChange={
                                                                (evt) => { changeAmount(evt.target.value, index); applyPriceList(priceList); changeTabIcon('flaticon-open-box') }
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="IPI %"
                                                            value={product.ipi}
                                                            onChange={
                                                                (e: any) => setIpi(e.target.value, index)
                                                            }
                                                            onBlur={(e) => {
                                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                                if (converted > 100) {
                                                                    setIpi("100", index);

                                                                } else {
                                                                    setIpi(converted ? converted.toFixed(2).replace('.', ',') : "0", index);
                                                                }
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="ICMS %"
                                                            value={product.icms}
                                                            onChange={
                                                                (e: any) => setIcms(e.target.value, index)
                                                            }
                                                            onBlur={(e) => {
                                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                                if (converted > 100) {
                                                                    setIcms("100", index);

                                                                } else {
                                                                    setIcms(converted ? converted.toFixed(2).replace('.', ',') : "0", index);
                                                                }
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor Unit."
                                                            value={product.unitaryValue}
                                                            onChange={
                                                                (evt) => { changeProductValue(evt.target.value, index); applyPriceList(priceList); changeTabIcon('flaticon-open-box') }
                                                            }
                                                            startAdornment="R$"
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="CFOP"
                                                            format="####"
                                                            value={product.cfop}
                                                            onChange={
                                                                (e: any) => setCfop(e.target.value, index)
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="NCM"
                                                            format="########"
                                                            value={product.ncm}
                                                            onChange={
                                                                (e: any) => setNcm(e.target.value, index)
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor Total"
                                                            disabled
                                                            value={product.total}
                                                            startAdornment="R$"
                                                        />
                                                    </Grid>

                                                    <>
                                                        {
                                                            (index > 0 && user.isAccountant == "n") && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link"
                                                                    onClick={() => deleteProduct(index)}
                                                                >
                                                                    <i className="flaticon-delete text-danger"></i>
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                </React.Fragment>
                                            );
                                        })
                                    }

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item xs={12}>
                                                <button
                                                    type="button"
                                                    className="btn btn-link p-0"
                                                    onClick={() => addProducts()}
                                                >
                                                    <ins>+ adicionar outro produto</ins>
                                                </button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Lista de preços"
                                            getOptionLabel={(option: PriceList) => option.name}
                                            value={priceList}
                                            onSelect={(option) => { setPriceList(option ? String(option.id) : ''); applyPriceList(option ? String(option.id) : '') }}
                                            apiSearchHandler={(typedText) => PriceListService.getPriceListsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!priceList) return null;
                                                return loadedOptions.find((option) => option.id === Number(priceList)) ?? PriceListService.getPriceListById(priceList)
                                            }}
                                            disabled={formatToFloat(totalValue) === 0 || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='total-invoice'
                                title='Totais da Nota Fiscal'
                            >
                                <div className="row">

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="BC de ICMS"
                                            startAdornment="R$"
                                            value={bcIcms}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do ICMS"
                                            startAdornment="R$"
                                            value={valueIcms}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="BC de ICMS-ST"
                                            startAdornment="R$"
                                            value={bcIcmsSt}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do ICMS-ST"
                                            startAdornment="R$"
                                            value={valueIcmsSt}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do IPI"
                                            startAdornment="R$"
                                            value={valueIpi}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor dos produtos"
                                            startAdornment="R$"
                                            value={valueProducts}
                                            disabled
                                        />
                                    </Grid>



                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Desconto (R$)"
                                            startAdornment="R$"
                                            value={discountMoney}
                                            onChange={(e) => setDiscountMoney(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    {/* <div className="col-lg-3">
                                    <NumericFormat 
                                        label="Desconto (%)" 
                                        withPrefix={false}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={discountPercentage}
                                        onChange={(e) => setDiscountPercentage(e.target.value)}
                                    />

                                </div> */}

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do Frete"
                                            startAdornment="R$"
                                            value={valueFreight}
                                            onChange={(e) => setValueFreight(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor despesas"
                                            startAdornment="R$"
                                            value={valueExpenses}
                                            onChange={(e) => setValueExpenses(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do Seguro"
                                            startAdornment="R$"
                                            value={valueSafe}
                                            onChange={(e) => setValueSafe(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>


                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor total da nota"
                                            startAdornment="R$"
                                            value={totalValue}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12}>

                                        <Accordion>

                                            <Accordion.Toggle as={Card.Header} eventKey="0" className="h5 mb-3 pl-0 pt-0" style={{ cursor: "pointer" }}>
                                                <img src="/media/svg/icons/Navigation/Angle-double-right.svg" className="mr-3" />
                                                Retenções
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey="0">

                                                <div className="row">

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor do PIS retido"
                                                            startAdornment="R$"
                                                            value={valuePis}
                                                            onChange={(e) => setValuePis(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor COFINS retido"
                                                            startAdornment="R$"
                                                            value={valueCofins}
                                                            onChange={(e) => setValueCofins(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor IRRF retido"
                                                            startAdornment="R$"
                                                            value={valueIrrf}
                                                            onChange={(e) => setValueIrrf(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor CSLL retido"
                                                            startAdornment="R$"
                                                            value={valueCsll}
                                                            onChange={(e) => setValueCsll(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Prev. social retido"
                                                            startAdornment="R$"
                                                            value={valueSocial}
                                                            onChange={(e) => setValueSocial(e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </div>
                                            </Accordion.Collapse>
                                        </Accordion>

                                    </Grid>

                                </div>
                            </Tab>

                            <Tab
                                eventKey='payment'
                                title='Pagamento'
                            >
                                <div className="row">
                                    <Grid item lg={4} md={6} xs={12}>
                                        <FormControlLabel
                                            value="end"
                                            control={
                                                <Checkbox
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                    color="primary"
                                                    checked={amountInstallments == '0' && installments.installment.length === 0}
                                                    onChange={() => {
                                                        if (amountInstallments == '0' && installments.installment.length === 0) {
                                                            setAmountInstallments('1');
                                                            setInstallments({ installment: [] });
                                                        } else {
                                                            setAmountInstallments('0');
                                                            setInstallments({ installment: [] });
                                                        }
                                                    }
                                                    } />}
                                            label="Sem Pagamento"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </div>
                                <div className="row">

                                    {/* <div className="col-lg-3 d-flex align-items-center">

                                    <TextField
                                        select
                                        label="Condição de Pagamento"
                                        margin="normal"
                                        name="conditionPayment"
                                        size="small"
                                    >
                                        <MenuItem key="0" value="nf-e normal">
                                            Selecione
                                        </MenuItem>

                                        <MenuItem key="1" value="30/60/90">
                                            30/60/90
                                        </MenuItem>
                                    </TextField>

                                </div>

                                <div className="p-3 d-flex align-items-center justify-content-center">
                                    <label>ou</label>
                                </div> */}

                                    <Grid item lg={4} md={6} xs={12}>

                                        <TextField
                                            type="number"
                                            label="Quantidade de Parcelas"
                                            margin="normal"
                                            name="amountParcel"
                                            size="small"
                                            value={amountInstallments}
                                            onChange={(e) => setAmountInstallments(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item md={3} xs={12}>

                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => generateInstallments()}
                                                    block
                                                >
                                                    <i className="fas fa-plus"></i>
                                                    Gerar parcelas
                                                </Button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item xs={12}>
                                        {
                                            installments.installment.length > 0 && installments.installment.map((installment, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <Grid item lg={3} md={6} xs={12}>
                                                            <div className="d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2 mr-2">
                                                                <label className="text-white m-0">
                                                                    {index + 1}.
                                                                </label>
                                                            </div>

                                                            <TextField
                                                                type="date"
                                                                label="Data"
                                                                margin="normal"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                value={installment.date}
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "date")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            {index + 1 !== installments.installment.length ? (
                                                                <NumericFormat
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    startAdornment="R$"
                                                                    onBlur={(e) => {
                                                                        var installmentArray = installments.installment;
                                                                        installmentArray[index].value = formatToFloat(e.target.value);
                                                                        setInstallments({ installment: installmentArray });
                                                                        distributeValueInstallments(index);
                                                                    }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            ) : (
                                                                <NumericFormat
                                                                    disabled
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    startAdornment="R$"
                                                                    onBlur={(e) => { setValuesInstallments(e.target.value, index, "value"); distributeValueInstallments(index) }}
                                                                />
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                select
                                                                label="Forma de pgto"
                                                                margin="normal"
                                                                value={installment.payment}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "payment")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key={-1} value="">
                                                                    Selecione
                                                                </MenuItem>
                                                                {
                                                                    paymentOptions.map((payment, i) => {
                                                                        return (
                                                                            <MenuItem key={i} value={payment.value}>
                                                                                {payment.value}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }
                                                            </TextField>

                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label="Observações"
                                                                margin="normal"
                                                                value={installment.comments}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "comments")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='transport'
                                title='Transporte'
                            >
                                <div className="row">

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Peso Bruto"
                                            startAdornment="KG"
                                            value={grossWeight}
                                            onChange={(e) => setGrossWeight(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Peso Líquido"
                                            startAdornment="KG"
                                            value={liquidWeight}
                                            onChange={(e) => setLiquidWeight(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <ReactNumberFormat
                                            margin="normal"
                                            customInput={TextField}
                                            label="Volumes"
                                            format="#####"
                                            value={volumes}
                                            onChange={(e) => setVolumes(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            label="Espécie"
                                            margin="normal"
                                            placeholder="EX: Caixa"
                                            size="small"
                                            value={species}
                                            onChange={(e) => setSpecies(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>

                                        <TextField
                                            label="Marca"
                                            margin="normal"
                                            size="small"
                                            value={brand}
                                            onChange={(e) => setBrand(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>

                                        <TextField
                                            label="Numeração"
                                            margin="normal"
                                            size="small"
                                            value={numbering}
                                            onChange={(e) => setNumbering(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>

                                        <TextField
                                            select
                                            label="Modalidade de Frete"
                                            margin="normal"
                                            size="small"
                                            value={modalityFreight}
                                            onChange={(e) => setModalityFreight(Number(e.target.value))}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="0">
                                                Emitente
                                            </MenuItem>

                                            <MenuItem key="1" value="1">
                                                Destinatário
                                            </MenuItem>

                                            <MenuItem key="2" value="2">
                                                Terceiro
                                            </MenuItem>

                                            <MenuItem key="3" value="3">
                                                Próprio por conta do Remetente
                                            </MenuItem>

                                            <MenuItem key="4" value="4">
                                                Próprio por conta Destinatário
                                            </MenuItem>

                                            <MenuItem key="5" value="9">
                                                Sem Frete
                                            </MenuItem>
                                        </TextField>

                                    </Grid>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Transportadora"
                                            getOptionLabel={(option: Carrier) => option.name}
                                            value={carrier}
                                            onSelect={(option) => setCarrier(option ? String(option.id) : '')}
                                            apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!carrier) return null;
                                                return loadedOptions.find((option) => option.id === Number(carrier)) ?? CarrierService.getCarrierById(carrier)
                                            }}
                                            disabled={[3, 4, 9].includes(modalityFreight) || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>

                                        <TextField
                                            label="Placa"
                                            margin="normal"
                                            placeholder="Placa do veiculo"
                                            size="small"
                                            value={plateVehicle}
                                            onChange={(e) => setPlateVehicle(e.target.value)}
                                            disabled={modalityFreight === 9 || user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>

                                        <TextField
                                            label="RNTC (ANTT)"
                                            margin="normal"
                                            size="small"
                                            value={rntc}
                                            onChange={(e) => setRntc(e.target.value)}
                                            disabled={modalityFreight === 9 || user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>

                                    </Grid>

                                </div>
                            </Tab>

                            {type === 2 && (
                                <Tab
                                    eventKey='export'
                                    title='Exportação'
                                >
                                    <div className="row">
                                        <Grid item lg={4} md={6} xs={12}>
                                            <TextField
                                                select
                                                label="UF de Embarque"
                                                margin="normal"
                                                size="small"
                                                value={ufExport}
                                                onChange={(e) => setUfExport(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="RO">
                                                    RO
                                                </MenuItem>
                                                <MenuItem key="1" value="AC">
                                                    AC
                                                </MenuItem>
                                                <MenuItem key="2" value="AM">
                                                    AM
                                                </MenuItem>
                                                <MenuItem key="3" value="RR">
                                                    RR
                                                </MenuItem>
                                                <MenuItem key="4" value="PA">
                                                    PA
                                                </MenuItem>
                                                <MenuItem key="5" value="AP">
                                                    AP
                                                </MenuItem>
                                                <MenuItem key="6" value="TO">
                                                    TO
                                                </MenuItem>
                                                <MenuItem key="7" value="MA">
                                                    MA
                                                </MenuItem>
                                                <MenuItem key="8" value="PI">
                                                    PI
                                                </MenuItem>
                                                <MenuItem key="9" value="CE">
                                                    CE
                                                </MenuItem>
                                                <MenuItem key="10" value="RN">
                                                    RN
                                                </MenuItem>
                                                <MenuItem key="11" value="PB">
                                                    PB
                                                </MenuItem>
                                                <MenuItem key="12" value="PE">
                                                    PE
                                                </MenuItem>
                                                <MenuItem key="13" value="AL">
                                                    AL
                                                </MenuItem>
                                                <MenuItem key="14" value="SE">
                                                    SE
                                                </MenuItem>
                                                <MenuItem key="15" value="BA">
                                                    BA
                                                </MenuItem>
                                                <MenuItem key="16" value="MG">
                                                    MG
                                                </MenuItem>
                                                <MenuItem key="17" value="ES">
                                                    ES
                                                </MenuItem>
                                                <MenuItem key="18" value="RJ">
                                                    RJ
                                                </MenuItem>
                                                <MenuItem key="19" value="SP">
                                                    SP
                                                </MenuItem>
                                                <MenuItem key="20" value="PR">
                                                    PR
                                                </MenuItem>
                                                <MenuItem key="21" value="SC">
                                                    SC
                                                </MenuItem>
                                                <MenuItem key="22" value="RS">
                                                    RS
                                                </MenuItem>
                                                <MenuItem key="23" value="MS">
                                                    MS
                                                </MenuItem>
                                                <MenuItem key="24" value="MT">
                                                    MT
                                                </MenuItem>
                                                <MenuItem key="25" value="GO">
                                                    GO
                                                </MenuItem>
                                                <MenuItem key="26" value="DF">
                                                    DF
                                                </MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item lg={8} md={6} xs={12}>
                                            <TextField
                                                label="Local Embarque"
                                                margin="normal"
                                                size="small"
                                                value={locationExport}
                                                onChange={(e) => setLocationExport(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>
                                    </div>
                                </Tab>
                            )}
                        </Tabs>
                        </div>
                    </div>
                </form>
        </MainPageContentWrapper>
    );
}