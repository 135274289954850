import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Checkbox, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import '../../../style.css';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { BankAccount } from '../../../hooks/bankAccount';
import { Customer } from '../../../hooks/customer';
import { formatCurrency } from '../../../utils/formatCurrency';
import { BillsTable } from './BillsTable';
import { SubCategory } from '../../../types/Dre';
import useBackendLoad from '../../../hooks/backendReload';
import { CenterCost } from '../../../types/CenterCost';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import BankAccountService from '../../../services/BankAccountService';
import CenterCostService from '../../../services/CenterCostService';
import CustomerService from '../../../services/CustomerService';
import DreCategoryService from '../../../services/DreCategoryService';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportCenterCostResponse = {
    rows: CenterCost[];
    count: number;
    totalExpense: number;
    totalRevenue: number;
    totalProfit: number;
}

type Filters = {
    centerCost: string;
    showTitleLate: boolean;
    releaseType: string;
    bankAccount: string;
    dreSubCategoryId: number;
    customer: string;
    supplier: string;
}

const titleHeadData: HeadDataBaseProps[] = [
    { reference: "number", value: "Nº" },
    { reference: "emissionDate", value: "Data de Emissão" },
    { reference: "dueDate", value: "Data de Vencimento" },
    { reference: "daysLate", value: "Dias Vencidos" },
    { reference: "valueTotal", value: "Valor Total" },
];

const defaultHeadData: HeadDataBaseProps[] = [
    { reference: 'name', value: 'Centro de Custos' },
    { reference: 'receive', value: 'Receitas' },
    { reference: 'pay', value: 'Despesas' },
    { reference: 'total', value: 'Saldo Final' }
];

export function ListReportCenterCost() {
    const [headData, setHeadData] = useState<HeadDataBaseProps[]>(defaultHeadData);
    
    const [foundCenterCosts, setFoundCenterCosts] = useState<any[]>([]);
    const [countTotalCenterCostsPaginated, setCountTotalCenterCostsPaginated] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [showTitleLate, setShowTitleNoMoviment] = useState(false);
    const [releaseType, setReleaseType] = useState("all");
    const [bankAccount, setBankAccount] = useState("");
    const [totalReceive, setTotalReceive] = useState(0);
    const [totalPay, setTotalPay] = useState(0);
    const [finalProfit, setFinalProfit] = useState(0);
    const [typeReport, setTypeReport] = useState("total");
    const [supplier, setSupplier] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [dreSubCategories, setDreSubCategories] = useState<SubCategory[]>([]);
    const [customer, setCustomer] = useState("");
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportCenterCostResponse>("/report/centerCosts", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, totalExpense, totalRevenue, totalProfit} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            let exportBillsData: ReportDataRow[] = [];
            const data = mountBodyDataRow(reportRow);

            let bills = [...reportRow.billsToPay, ...reportRow.billsToReceive];

            data.push({
                for: "bills",
                value: '',
                secondaryRow: true,
                jsx: <BillsTable bills={bills} />
            });

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportBillsData = bills.map((bill: any) => {
                const today      = new Date();
                const dueDate    = new Date(bill.dueDate);
                const timeDiff   = Math.abs(dueDate.getTime() - today.getTime());
                const diffInDay  = Math.ceil(timeDiff / (1000 * 3600 * 24)) // calculo para converter em dias

                return {
                    cells: [
                        {for: 'number', content: bill.id},
                        {
                            for: 'emissionDate',
                            content: bill.typeBill == 'toPay'
                            ?
                            bill.issueDate ? bill.issueDate.split("-").reverse().join("/") : '-'
                            :
                            bill.issuanceDate ? bill.issuanceDate.split("-").reverse().join("/") : '-'
                        },
                        {for: 'dueDate', content: bill.dueDate.split("-").reverse().join("/")},
                        {for: 'daysLate', content: diffInDay},
                        {for: 'valueTotal', content: formatCurrency(bill.amount)},
                    ],
                };
            });

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: titleHeadData,
                    bodyData: exportBillsData,
                }
            });

            setExportTotals([
                { name: 'Valor Total Receitas', value: formatCurrency(totalRevenue) },
                { name: 'Valor Total Despesas', value: formatCurrency(totalExpense) },
                { name: 'Saldo Final', value: formatCurrency(totalProfit) },
            ]);

        });

        setExportBodyData(exportDataList);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportCenterCostResponse>("/report/centerCosts", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, totalExpense, totalRevenue, totalProfit} = data;

        setFoundCenterCosts(rows);
        setCountTotalCenterCostsPaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
        setTotalPay(totalExpense);
        setTotalReceive(totalRevenue);
        setFinalProfit(totalProfit);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundCenterCosts;

        aux.forEach((centerCost) => {
            const data = mountBodyDataRow(centerCost, typeReport);

            list.push(data);
        });

        setBodyData(list);
    }, [foundCenterCosts, typeReport]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: CenterCost, typeReport?: string) {
        let data: BodyDataBaseProps[];
        const { totalToPay, totalToReceive } = getRevenuesAndExpenses(reportRow);

        if (typeReport === "launch") {
            data = [
                { for: "name", value: reportRow.name },
                { for: "total", value: releaseType == "receive" ? formatCurrency(totalToReceive) : formatCurrency(totalToPay) },
            ];

            let bills = [...reportRow.billsToPay, ...reportRow.billsToReceive];

            data.push({
                for: "bills",
                value: '',
                secondaryRow: true,
                jsx: <BillsTable bills={bills} />
            });

        } else {
            const totalValue = totalToReceive - totalToPay;

            data = [
                { for: "name", value: reportRow.name },
                { for: "receive", value: formatCurrency(totalToReceive) },
                { for: "pay", value: formatCurrency(totalToPay) },
                { for: "total", value: formatCurrency(totalValue) },
            ];
        }

        return data;
    }

    useEffect(() => {
        if (typeReport == "launch") {
            setHeadData([
                { reference: 'name', value: 'Centro de Custos' },
                { reference: 'total', value: 'Valor Total' },
            ]);

        } else {
            setHeadData(defaultHeadData);
        }

    }, [typeReport]);

    const clearSearch = () => {
        setShowTitleNoMoviment(false);
        setReleaseType('all');
        setBankAccount('');
        setCenterCost('');
        setCustomer('');
        setSupplier('');
        setDreSubCategoryId(0);
        setTypeReport('total');
    }

    function getRevenuesAndExpenses(centerCost: CenterCost) {
        let totalToPay = 0;
        let totalToReceive = 0;

        centerCost.billsToPay.forEach(bill => totalToPay += bill.amount);
        centerCost.billsToReceive.forEach(bill => totalToReceive += bill.amount);

        return { totalToPay, totalToReceive };
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            releaseType,
            bankAccount,
            dreSubCategoryId,
            customer,
            supplier,
            centerCost,
            showTitleLate
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        releaseType,
        bankAccount,
        dreSubCategoryId,
        customer,
        supplier,
        centerCost,
        showTitleLate
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Centros de Custos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-12 border-bottom">
                    <FormLabel component="legend">Modelo do relatório</FormLabel>
                    <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        value={typeReport}
                        onChange={(e) => {
                            setTypeReport(e.target.value);
                        }}
                        row
                    >
                            <div className="col-lg-4">
                                <FormControlLabel value="total" control={<Radio />} label="Totais por centro de custo" />
                            </div>
                            <div className="col-lg-4">
                                <FormControlLabel value="launch" control={<Radio />} label="Lançamentos por centro de custo" />
                            </div>
                            <div className="col-lg-4">
                                <FormControlLabel value="categories" control={<Radio />} label="Plano de contas por centro de custo" />
                            </div>
                    </RadioGroup>
                </div>
                <div className="col-lg-4">
                    <TextField
                        select
                        label="Tipo de Lançamento"
                        margin="normal"
                        size="small"
                        value={releaseType}
                        onChange={(e) => {
                            setReleaseType(e.target.value);
                            setSupplier("");
                            setCustomer("");
                            setDreSubCategoryId(0);
                        }}
                    >
                        {
                            typeReport != "launch" && (
                                <MenuItem key="0" value="all">
                                Todos
                                </MenuItem>
                            )
                        }

                        <MenuItem key="1" value="receive">
                            Receitas
                        </MenuItem>

                        <MenuItem key="2" value="pay">
                            Despesas
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4 d-flex align-items-center">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={bankAccount}
                        onSelect={(option) => setBankAccount(String(option?.id ?? ''))}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!bankAccount) return null;
                            return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                        }}
                    />
                </div>

        
                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showTitleLate}
                                onChange={(_, checked) => setShowTitleNoMoviment(checked)}
                            />
                        }
                        label="Listar centros de custo ativos sem movimento"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                {
                    typeReport != "total" 
                    ?
                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Centro de custos"
                                getOptionLabel={(option: CenterCost) => option.name}
                                onSelect={(option) => setCenterCost(String(option?.id ?? ''))}
                                value={centerCost}
                                apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if(!centerCost) return null;
                                    return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                }}
                            />
                        </div>
                    : <></>
                }

                {
                    typeReport == "launch" 
                    ?
                        releaseType == "pay" 
                        ?
                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Fornecedor"
                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                    value={supplier}
                                    onSelect={(option) => setSupplier(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!supplier) return null;
                                        return loadedOptions.find((option) => option.id === Number(supplier)) ?? CustomerService.getCustomerById(supplier)
                                    }}
                                />
                            </div>
                        :
                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    label="Cliente"
                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                    value={customer}
                                    onSelect={(option) => setCustomer(String(option?.id) || '')}
                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!customer) return null;
                                        return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                                    }}
                                />
                            </div>
                    : <></>
                }

                {
                    typeReport != "total" 
                    ?
                        <div className="col-lg-4 d-flex align-items-center">
                            <ApiResourceSelect
                                style={{ width: "100%" }}
                                label="Plano de Contas"
                                getOptionLabel={(option: SubCategory) => option.name}
                                value={ dreSubCategoryId }
                                onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 
                                    releaseType === 'all' || releaseType === 'toReceive' ? 'both' : releaseType === 'pay' ? 'expense' : 'revenue')}
                                getSelectedOption={(loadedOptions) => {
                                    if(!dreSubCategoryId) return null;
                                    return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                }}
                            />
                        </div>
                    : <></>
                }
                
                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Centros de Custos"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Centros de Custos', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalCenterCostsPaginated}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Receitas</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalReceive)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Despesas</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalPay)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Saldo Final</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(finalProfit)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    )
}