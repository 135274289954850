import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { Button, Modal } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { Category } from '../../types/Dre';

export type ModalType = {
    modalSubAdd: boolean
    setModalSubAdd: React.Dispatch<React.SetStateAction<boolean>>

    nameSubAdd: string
    setNameSubAdd: React.Dispatch<React.SetStateAction<string>>

    dreCategoryId: number
    setDreCategoryId: React.Dispatch<React.SetStateAction<number>>

    getCategoriesRevenue: () => void
    getCategoriesExpense: () => void
}

export default function ModalSubAdd(props: ModalType) {

    async function add() {
        var response = await api.post(`/dre/sub`, {
            name: props.nameSubAdd,
            dreCategoryId: props.dreCategoryId,
        });

        props.setNameSubAdd('')
        props.setDreCategoryId(0)
        props.getCategoriesRevenue()
        props.getCategoriesExpense()
        props.setModalSubAdd(false)
    }

    return (
        <Modal
            show={props.modalSubAdd}
            onHide={() => props.setModalSubAdd(false)}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Adicionar Sub Categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    size="small"
                    label="Nome da sub categoria*"
                    margin="normal"
                    onChange={(e) => props.setNameSubAdd(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => props.setModalSubAdd(false)}>Cancelar</Button>
                <Button variant="primary" type="submit" onClick={add}>Adicionar</Button>
            </Modal.Footer>
        </Modal>
    )
}