import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';

import { ExpenditureListItem } from './ExpenditureListItem';
import { CategoryListTitle } from './CategoryListTitle';
import { RevenueListItem } from './RevenueListItem';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';


const styles = {
    headStyle: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
    },
    headStyleTop: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
        borderTop: 0,
    },
    headStyleMid: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
        borderTop: 0,
    },
};


export function ListCategory() {
    const { user } = useSelector((state: any) => state.auth);


    const { location: { pathname }, push: historyPush } = useHistory();

    return (
        <MainPageContentWrapper>
            <div className="col-lg-12 mt-4 mb-8">
                {
                    user.isAccountant == "n"
                    ?
                    <Button variant="success" onClick={() => historyPush(`${pathname}/adicionar`)}>
                        Criar categoria
                    </Button>
                    : <></>
                }
            </div>

            <Paper component="section">
                <CategoryListTitle>Receitas</CategoryListTitle>

                <Grid container direction="column">
                    <Grid item style={styles.headStyleTop}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Receitas sem grupo financeiro definido</p>
                    </Grid>

                    <RevenueListItem type="no-group" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Outras receitas</p>
                    </Grid>

                    <RevenueListItem type="others" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Retenção de lucros</p>
                    </Grid>

                    <RevenueListItem type="retention" />
                </Grid>
            </Paper>

            <Paper component="section" className="mt-8">
                <CategoryListTitle>Despesas</CategoryListTitle>

                <Grid container direction="column">
                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Custos</p>
                    </Grid>

                    <ExpenditureListItem type="expenditure" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Despesas com pessoal</p>
                    </Grid>

                    <ExpenditureListItem type="employees" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Despesas administrativas</p>
                    </Grid>

                    <ExpenditureListItem type="admin" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Despesas financeiras</p>
                    </Grid>

                    <ExpenditureListItem type="financial" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Outras despesas</p>
                    </Grid>

                    <ExpenditureListItem type="others" />

                    <Grid item style={styles.headStyleMid}>
                        <p className="p-4 mb-0 ml-8 font-weight-bold">Distribuição de lucros</p>
                    </Grid>

                    <ExpenditureListItem type="profit" />
                </Grid>
            </Paper>
        </MainPageContentWrapper>
    );
}