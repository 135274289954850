import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Table, TextField, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from '@material-ui/core';
import { Button, Spinner } from "react-bootstrap";
import { formatCurrency } from '../../utils/formatCurrency';

import '../../style.css';
import api from '../../services/Api';
import { Category } from '../../types/Dre';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';


export function ListDre() {

    const [isSearching, setIsSearching] = useState(true);
    const [period, setPeriod] = useState('month')
    const [year, setYear] = useState(2022)
    const [yearAux, setYearAux] = useState(2022)

    const [listPeriod, setListPeriod] = useState([])
    const [listYear, setListYear] = useState([0])
    const [listCategories, setListCategories] = useState<Category[]>([])
    const [listResult, setListResult] = useState([])

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#F5F7FB",
        },
    }));

    const StyledTableCell = styled(TableCell)(() => ({
        backgroundColor: "#1E1E1E",
        color: "#fff"
    }));

    const tableRef = useRef(null);

    useEffect(() => {
        loadYears()
        getReport()
    }, []);

    async function getReport() {
        setIsSearching(true);

        var response = await api.post(`/dre/report`, {
            period: period,
            year: year,
            yearAux: yearAux
        });

        setListPeriod(response.data.period);
        setListCategories(response.data.categories);
        setListResult(response.data.result);

        setIsSearching(false);
    }

    function loadYears() {
        const date = new Date()
        var yearToday = date.getFullYear()

        const years = []
        for (let i = 2014; i <= (yearToday + 2); i++) {
            years.push(i)
        }

        setListYear(years)
    }

    return (
        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de DRE</h4>
            <div className="row mt-4">
                <div className="col-12 my-4">
                    <div className="d-flex">
                        <TextField
                            style={{ width: "200px" }}
                            size="small"
                            select
                            label="Periodo"
                            margin="normal"
                            value={period}
                            onChange={(e) => setPeriod(e.target.value)}
                        >
                            <MenuItem key="0" value="month" selected>Mensal</MenuItem>
                            <MenuItem key="1" value="twoMonths">Bimestral</MenuItem>
                            <MenuItem key="2" value="threeMonths">Trimestral</MenuItem>
                            <MenuItem key="3" value="sixMonths">Semestral</MenuItem>
                            <MenuItem key="4" value="year">Anual</MenuItem>
                        </TextField>

                        <TextField
                            className="ml-4"
                            style={{ width: "200px" }}
                            size="small"
                            select
                            label="Ano"
                            margin="normal"
                            value={year}
                            onChange={(e) => setYear(Number(e.target.value))}
                        >
                            {listYear.map((yearList) => (
                                <MenuItem key={yearList} value={yearList}>{yearList}</MenuItem>
                            ))}
                        </TextField>

                        {period == 'year' ?
                            <TextField
                                className="ml-4"
                                style={{ width: "200px" }}
                                size="small"
                                select
                                label="Ano Final"
                                margin="normal"
                                value={yearAux}
                                onChange={(e) => setYearAux(Number(e.target.value))}
                            >
                                {listYear.map((yearList) => (
                                    <MenuItem key={yearList} value={yearList}>{yearList}</MenuItem>
                                ))}
                            </TextField>
                            :
                            ''
                        }

                        <Button
                            style={{ height: "40px" }}
                            type='submit'
                            className='ml-3 mt-4'
                            variant="primary"
                            size="sm"
                            onClick={() => getReport()}
                            disabled={isSearching}
                        >
                            {isSearching ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : <>
                                <i className="fas fa-search"></i>
                                <span>
                                    Buscar
                                </span>
                            </>}
                        </Button>

                        <DownloadTableExcel
                            filename="Relatorio"
                            sheet="users"
                            currentTableRef={tableRef.current}
                        >
                            <Button
                                variant="secondary"
                                style={{ height: "40px" }}
                                type='button'
                                className='ml-3 mt-4'
                                size="sm"
                            >
                                <i className="fas fa-file-export"></i>
                                Exportar
                            </Button>
                        </DownloadTableExcel>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <TableContainer style={{ maxHeight: '75vh' }} component={Paper}>
                        <Table stickyHeader ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                    {listPeriod.map((row) => (
                                        <StyledTableCell align="center">{row}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {listCategories.map((category) => (
                                    <>
                                        {category.type == 'revenue' ?
                                            <StyledTableRow key={category.name} className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0" style={{ borderColor: 'green' }}>
                                                <StyledTableCell><i className="flaticon-add-circular-button mr-3 text-success"></i><b>{category.name}</b></StyledTableCell>
                                                {category.values.map((value) => (
                                                    <StyledTableCell align="center"><b>{formatCurrency(value)}</b></StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                            :
                                            <StyledTableRow key={category.name} className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0" style={{ borderColor: 'red' }}>
                                                <StyledTableCell><i className="flaticon-add-circular-button mr-3 text-danger"></i><b>{category.name}</b></StyledTableCell>
                                                {category.values.map((value) => (
                                                    <StyledTableCell align="center"><b>{formatCurrency(value)}</b></StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        }


                                        {category.subCategories.map((subCategory) => (
                                            <>
                                                {category.type == 'revenue' ?
                                                    <StyledTableRow key={subCategory.name} className="list-group-item list-group-item rounded-top border-4 border-top-0 border-right-0 border-bottom-0" style={{ borderColor: 'green' }}>
                                                        <TableCell>{subCategory.name}</TableCell>
                                                        {subCategory.values.map((value) => (
                                                            <TableCell align="center">{formatCurrency(value)}</TableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                    :
                                                    <StyledTableRow key={subCategory.name} className="list-group-item list-group-item rounded-top border-4 border-top-0 border-right-0 border-bottom-0" style={{ borderColor: 'red' }}>
                                                        <TableCell>{subCategory.name}</TableCell>
                                                        {subCategory.values.map((value) => (
                                                            <TableCell align="center">{formatCurrency(value)}</TableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                }
                                            </>
                                        ))}
                                    </>
                                ))}

                                <StyledTableRow className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0 border-success">
                                    <StyledTableCell><b>Resultado Líquido do Exercício</b></StyledTableCell>
                                    {listResult.map((result) => (
                                        <StyledTableCell align="center"><b className="d-block" style={{ whiteSpace: 'nowrap' }}>{formatCurrency(result)}</b></StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}