import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { format, getTime } from 'date-fns';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { orderByIdDesc } from '../../utils/orderTable';

import api from '../../services/Api';
import {
    Situation,
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency } from '../../utils/formatCurrency';
import { sortByDate, sortByNumber } from '../../utils/sortCallback';
import { formatToFloat } from '../../utils/formatCurrency';
import { dateIsBetweenRange, extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import '../../style.css';
import { Search } from '../../components/Search';
import { Card, Collapse, InputAdornment, MenuItem, Paper, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Autocomplete } from '@material-ui/lab';
import { paymentOptions } from '../../utils/paymentOptions';
import { getSituationText } from '../../utils/getSituationText';
import useBackendLoad from '../../hooks/backendReload';
import { BillsToPay } from '../../types/BillsToPay';
import BillToPayService from '../../services/BillToPayService';
import CustomerService from '../../services/CustomerService';
import SellerService from '../../services/SellerService';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { SubCategory } from '../../types/Dre';
import DreCategoryService from '../../services/DreCategoryService';
import { Bank } from '../../types/Bank';
import { banks } from '../../utils/banks';
import CenterCostService from '../../services/CenterCostService';
import { CenterCost } from '../../types/CenterCost';
import ModalError from '../../components/ModalError';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { Bar, BarChart, Cell, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import axios from 'axios';

type Filters = {
    searchQuery: string;
    situation: string;
    valueMin: number;
    valueMax: number;
    dreSubCategoryId: number;
    nameBank: string;
    centerCost: number;
    payment: string;
    issueDateMin: string;
    issueDateMax: string;
    dueDateMin: string;
    dueDateMax: string;
}

type BillsStatusChart = {
    status: string,
    quantity: number,
}

type BillsSituationsTotalChart = {
    status: string,
    value: number
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Id" },
    { reference: "doc", value: "Doc" },
    { reference: "name", value: "Beneficiário" },
    { reference: "date", value: "Data de vencimento" },
    { reference: "value", value: "Valor" },
    { reference: "payment", value: "Forma de pagamento", notSortable: true },
    { reference: "status", value: "Situação", situation: true, notSortable: true },
];

const backgroundColors: { [index: string]: string } = {
    'canceled': 'bg-light-danger',
    'paid': 'bg-light-primary',
    'late': 'bg-light-danger',
    'pending': 'bg-light-success',
}

const textColors: { [index: string]: string } = {
    'canceled': '#F64E60',
    'paid': '#2E93B9',
    'late': '#F64E60',
    'pending': '#0CA321',
}

export function ListBillsToPay() {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();
    
    const [direction, setDirection] = useState(false);
    const [billsToPay, setBillsToPay] = useState<BillsToPay[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [situationData, setSituationData] = useState<Situation[]>([]);
    const [countTotalBillsToPay, setCountTotalBillsToPay] = useState(0);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [nameBank, setNameBank] = useState("");
    const [centerCost, setCenterCost] = useState(0);
    const [payment, setPayment] = useState("");
    const [issueDateMin, setIssueDateMin] = useState("");
    const [issueDateMax, setIssueDateMax] = useState("");
    const [dueDateMin, setDueDateMin] = useState("");
    const [dueDateMax, setDueDateMax] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const [billsStatus, setBillsStatus] = useState<BillsStatusChart[]>();
    const [billsSituationsTotal, setBillsSituationsTotal] = useState<BillsSituationsTotalChart[]>();

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [billsToPay]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: BillsToPay[], count: number}>("billsToPay", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const foundBills = await BillToPayService.resolveBillsDueDates(rows);

        const situation: Situation[] = foundBills.map((bill) => ({ ...bill, customer: bill.supplier }));

        for (const bill of foundBills) {
            if(bill.supplier) {
                bill.supplierName = CustomerService.getCustomerName(bill.customerEntity);
            }
            if(bill.seller) {
                bill.sellerName = SellerService.getName(bill.sellerEntity);
            }
        }

        setCountTotalBillsToPay(count);
        setBillsToPay(foundBills);

        setBillsToPay(foundBills);
    }, []);

    useEffect(() => {
        if(billsToPay.length) {
            const situation: Situation[] = billsToPay.map((bill) => ({
                ...bill,
                customer: bill.supplier,
                beneficiaryName: bill.beneficiaryType === 'supplier'
                    ? CustomerService.getCustomerName(bill.customerEntity)
                    : SellerService.getName(bill.sellerEntity)
            }));
            setSituationData(situation);
        }
    }, [billsToPay]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = billsToPay;

        aux.forEach((bill) => {
            const amount = bill.totalPaid ? formatCurrency(bill.totalPaid) : formatCurrency(bill.remaining);
            let status = getSituationText(bill.status);
            const today = extractDateStringFromTimestamp();
            if(bill.status === 'pending') {
                if(bill.dueDate === today) {
                    status = 'Vencendo'
                } else {
                    const dueDateObj = new Date(bill.dueDate);
                    if(dueDateObj.getTime() <= (new Date()).getTime()) {
                        status = 'Vencido';
                    }
                }
            }

            // Formatando data
            let date;
            if (bill.dueDate) {
                const [year, month, day] = bill.dueDate.split("-");
                date = `${day}/${month}/${year}`;
            }

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(bill.id), id: true },
                { for: "doc", value: bill.name},
                { for: "name", value: bill.sellerName || (bill.supplierName || "-") },
                { for: "date", value: date ?? "-" },
                { for: "value", value: status == "paid" && bill.partialPayments ? formatCurrency(bill.amount) : amount },
                { for: "payment", value: bill.payment ?? "-" },
                { for: "status", value: status },
                { for: "type", value: bill.recordType },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [billsToPay]);

    const fetchBillsStatus = async () => {
        try {
            const results = await axios.get("/billsToPay/getBillsSituation");
            setBillsStatus(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBillsSituationsTotal = async () => {
        try {
            const results = await axios.get("/billsToPay/getBillsSituationsTotal");
            console.log(results.data);
            setBillsSituationsTotal(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBillsStatus();
        fetchBillsSituationsTotal();
    }, []);

    const handleClickEdit = (id: string) => {
        pushHistory(`${pathname}/${id}`);
    };

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);
    
    const handleClickDelete = useCallback(async (id: string, reason: string) => {
        try {
            var bill = await api.get(`billsToPay/${id}`);

            await api.put(`billsToPay/${id}`, {status: "canceled", reasonCanceled: reason});
            await api.put(`billsToReceive/${bill.data.idBillTransfer}`, {status: "canceled", reasonCanceled: reason});

            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }, [billsToPay]);

    const handleClickReversal = useCallback(async (id: string) => {
        try {
            const aux = billsToPay;
            const billIndex = aux.findIndex((bill) => bill.id === Number(id));

            if (billIndex < 0) {
                throw new Error();
            }

            const hasPermission = await BillToPayService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(aux[billIndex].payedDate || aux[billIndex].dueDate, selectedCompany);
            if (!hasPermission) {
                setMsgError('O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema.');
                setShowModalError(true);
                return;
            }

            aux[billIndex] = {
                ...aux[billIndex],
                status: 'pending',
                remaining: aux[billIndex].amount,
                accValue: 0,
                taxValue: 0,
                discountValue: 0,
                feeValue: 0,
                totalPaid: 0,
                payedDate: "",
                partialPayments: null
            };            

            await api.put(`billsToPay/${id}`, aux[billIndex]);

            setBillsToPay([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [billsToPay]);

    const handleChangeSituation = useCallback(async ({
        id,
        status,
        dueDate,
        payment,
        customer,
        remaining,
        bankAccount,
        accValue,
        taxValue,
        discountValue,
        feeValue,
        totalPaid,
        payedDate,
        partialPayments,
        amount,
        writeOffValue
    }: Situation) => {
        try {
            const aux = billsToPay;
            const billIndex = aux.findIndex((bill) => bill.id === id);

            if (billIndex < 0) {
                throw new Error();
            }

            const partialsJson = partialPayments ? JSON.parse(partialPayments) : null;

            if (remaining == 0 && partialsJson == null) {
                aux[billIndex] = {
                    ...aux[billIndex],
                    status,
                    dueDate,
                    payment,
                    remaining,
                    bankAccount,
                    accValue: Number(accValue),
                    taxValue: Number(taxValue),
                    discountValue: Number(discountValue),
                    feeValue: Number(feeValue),
                    totalPaid: Number(totalPaid),
                    payedDate: payedDate
                };

            } else if (remaining == 0 && partialsJson) {
                var auxPartials = [
                    ...partialsJson,
                    {
                        payedDate,
                        writeOffValue: Number(writeOffValue),
                        feeValue: Number(feeValue),
                        discountValue: Number(discountValue),
                        taxValue: Number(taxValue),
                        accValue: Number(accValue),
                        totalPaid: Number(totalPaid),
                        payment,
                        bankAccount
                    }
                ];

                aux[billIndex] = {
                    ...aux[billIndex],
                    status: "paid",
                    partialPayments: JSON.stringify(auxPartials),
                    remaining
                };
                
            } else {
                var auxPartials = [];

                if (partialsJson) {
                    auxPartials = [
                        ...partialsJson,
                        {
                            payedDate,
                            writeOffValue,
                            feeValue: Number(feeValue),
                            discountValue: Number(discountValue),
                            taxValue: Number(taxValue),
                            accValue: Number(accValue),
                            totalPaid: Number(totalPaid),
                            payment,
                            bankAccount
                        }
                    ];

                } else {
                    auxPartials = [
                        {
                            payedDate,
                            writeOffValue,
                            feeValue: Number(feeValue),
                            discountValue: Number(discountValue),
                            taxValue: Number(taxValue),
                            accValue: Number(accValue),
                            totalPaid: Number(totalPaid),
                            payment,
                            bankAccount
                        }
                    ];
                }

                aux[billIndex] = {
                    ...aux[billIndex],
                    partialPayments: JSON.stringify(auxPartials),
                    remaining
                };
            }       

            await api.put(`billsToPay/${id}`, aux[billIndex]);

            setBillsToPay([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [billsToPay]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickRecurringExpenses = useCallback(() => {
        pushHistory(`despesas-recorrentes`);
    }, []);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setDreSubCategoryId(0);
        setNameBank('');
        setPayment('');
        setCenterCost(0);
        setValueMin(0);
        setValueMax(0);
        setIssueDateMin("");
        setIssueDateMax("");
        setDueDateMin("");
        setDueDateMax("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, situation,
            valueMin, valueMax, issueDateMin, issueDateMax, dueDateMin, dueDateMax,
            dreSubCategoryId, nameBank, payment, centerCost,
        };

        reloadData();
    }, [
        billsToPay, searchQuery, situation,
        valueMin, valueMax, issueDateMin, issueDateMax, dueDateMin, dueDateMax,
        dreSubCategoryId, nameBank, payment, centerCost
    ]);

    const formatDataKey = () => {
        return "Quantidade";
    }

    const formatStatus = (tickItem: string) => {
        switch(tickItem) {
            case 'canceled':
                return 'Cancelado';
            case 'paid':
                return 'Pago';
            case 'late':
                return 'Vencido';
            case 'pending':
                return 'Pendente';
            default:
                return "Nenhum";
        }
    }

    const formatLabel = (label: string, type: string) => {
        if (type === "situation") {
            switch(label) {
                case "canceled":
                    return "Cancelado";
                case "paid":
                    return "Pago";
                case "late":
                    return "Vencido";
                case "pending":
                    return "Pendente";
                default:
                    return "Nenhum";
            }
        }
    }

    const CustomTooltip = ({ active, payload, label, type }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabel(label, type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const createCustomTooltip = (type: any) => (props: any) => (
        <CustomTooltip {...props} type={type} />
    )

    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <h4 className="font-weight-bolder">Lista de contas a pagar</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <>
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2 mb-2 text-dark"
                                onClick={() => handleClickAdd()}
                            >
                                <i className="fas fa-plus text-dark"></i>
                                Adicionar
                            </Button>
                            <Button
                                type="button"
                                variant="secondary"
                                className="mr-2 mb-2"
                                onClick={() => handleClickRecurringExpenses()}
                            >
                                <i className="fas fa-money-bill-wave"></i>
                                Despesas recorrentes
                            </Button>
                        </>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="shadow-sm p-6 rounded-sm border border-2">
                    <h4>Pesquisa avançada</h4>

                    <div className="row">
                        <div className="col-lg-2">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Nenhum
                                </MenuItem>
                                <MenuItem key="1" value="pending" onClick={() => setSituation('pending')}>
                                    Pendente
                                </MenuItem>
                                <MenuItem key="2" value="late" onClick={() => setSituation('late')}>
                                    Vencido
                                </MenuItem>
                                <MenuItem key="3" value="paid" onClick={() => setSituation('paid')}>
                                    Pago
                                </MenuItem>
                                <MenuItem key="4" value="canceled" onClick={() => setSituation('canceled')}>
                                    Cancelado
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                label="Valor"
                                startAdornment="DE"
                                value={valueMin}
                                onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                label="Valor"
                                startAdornment="ATÉ"
                                value={valueMax}
                                onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
                            />
                        </div>
                        <div className="col-lg-3">
                            <ApiResourceSelect
                                label="Plano de Contas"
                                getOptionLabel={(option: SubCategory) => option.name}
                                value={dreSubCategoryId}
                                onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                getSelectedOption={(loadedOptions) => {
                                    if(!dreSubCategoryId) return null;
                                    return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Autocomplete
                                size="small"
                                noOptionsText="Sem opções"
                                options={banks}
                                value={banks.find(bank => bank.name === nameBank) ?? null}
                                getOptionLabel={({name}) => name}
                                onChange={(event, newInputValue) => setNameBank(newInputValue?.name ?? '')}
                                renderInput={(params) =>
                                    <TextField
                                        size="small"
                                        { ...params }
                                        label="Banco"
                                        margin="normal"
                                    />
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <ApiResourceSelect
                                label="Centro de Custos"
                                getOptionLabel={(option: CenterCost) => option.name}
                                value={centerCost}
                                onSelect={(option) => setCenterCost(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if(!centerCost) return null;
                                    return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Autocomplete
                                size="small"
                                noOptionsText="Sem opções"
                                options={paymentOptions}
                                value={paymentOptions.find(payOption => payOption.value === payment) ?? null}
                                getOptionLabel={({value}) => value}
                                onChange={(event, newInputValue) => setPayment(newInputValue?.value ?? '')}
                                renderInput={(params) =>
                                    <TextField
                                        size="small"
                                        { ...params }
                                        label="Forma de Pagamento"
                                        margin="normal"
                                    />
                                }
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Emissão"
                                margin="normal"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                    ),
                                }}
                                value={issueDateMin}
                                onChange={(e) => setIssueDateMin(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Emissão"
                                margin="normal"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                    ),
                                }}
                                value={issueDateMax}
                                onChange={(e) => setIssueDateMax(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Vencimento"
                                margin="normal"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                    ),
                                }}
                                value={dueDateMin}
                                onChange={(e) => setDueDateMin(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Vencimento"
                                margin="normal"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                    ),
                                }}
                                value={dueDateMax}
                                onChange={(e) => setDueDateMax(e.target.value)}
                            />
                        </div>
                        <div className="col-12 d-flex mt-6">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                <i className="fa fa-search"></i>
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                                Limpar
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className="row">
                <Paper elevation={1} className="mt-3 col-lg-4 col-12 border border-2 py-3" style={{ height: "fit-content" }}>
                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Situação</span> - mês corrente

                        <LineChart className="w-100 mt-6" width={400} height={200} data={billsStatus}>
                            <XAxis dataKey="status" tickFormatter={formatStatus} />
                            <YAxis />
                            <Tooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("situation")}  />
                            <Legend formatter={formatDataKey} />
                            <Line strokeWidth={3} type="monotone" dataKey="quantity" stroke="#FFA800" />
                        </LineChart>
                    </Card>

                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Total por situação</span> - mês corrente

                        {
                            billsSituationsTotal?.map((billSituationTotal, index) => {
                                return (
                                    <div key={index} className="d-flex my-3" style={{ fontSize: "16px" }}>
                                        <div className={`${backgroundColors[billSituationTotal.status]} p-3 mr-2 rounded-circle`} style={{ width: "8px", border: "2px solid", borderColor: textColors[billSituationTotal.status] }}></div>
                                        <span className="font-weight-bolder">{formatStatus(billSituationTotal.status)}</span>: {formatCurrency(billSituationTotal.value)}
                                    </div>
                                )
                            })
                        }

                        {/* <BarChart
                            width={400}
                            height={300}
                            data={billsSituationsTotal}
                        >
                            <XAxis dataKey="status" />
                            <YAxis />
                            <Bar dataKey="value" fill="#8884d8">
                                {billsSituationsTotal?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                ))}
                            </Bar>
                            <Tooltip />
                        </BarChart> */}

                        {/* <PieChart className="w-100 mt-6" width={400} height={300} data={billsSituationsTotal}>
                            <Pie
                                data={billsSituationsTotal}
                                cx="50%"
                                cy="50%"
                                label
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {billsSituationsTotal?.map((entry: any, index: number) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip wrapperStyle={{ outline: "none" }} />
                        </PieChart> */}
                    </Card>
                </Paper>

                <div className="mt-3 col-lg-8 col-12">
                    <ListWithModalChangeSituation
                        billsToPay
                        situation
                        sortable={true}
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onClone={handleClickClone}
                        onDelete={handleClickDelete}
                        onReversal={handleClickReversal}
                        objectSituation={situationData}
                        onChangeSituation={handleChangeSituation}
                        situationModalTitle="Liquidar conta a pagar"
                        loadData={loadData}
                        totalCount={countTotalBillsToPay}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
        </MainPageContentWrapper>
    );
}
