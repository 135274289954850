import React, { useCallback, useEffect, useRef, useState } from 'react';
import { List } from '../../../_metronic/layout/components/List/List';

import api from "../../services/Api";
import { Search } from '../../components/Search';
import { Collapse, MenuItem, TextField } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import useBackendLoad from '../../hooks/backendReload';
import { LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
    situation: string;
}

const headRows = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Nº' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome da Conta' },
    { id: 'nameBank', numeric: false, disablePadding: false, label: 'Banco' },
    { id: 'situation', numeric: false, disablePadding: false, label: 'Situação', notSortable: true },
    { id: 'moreActions', numeric: false, disablePadding: false, label: '', notSortable: true },
];

export function ListAccountsBank() {
    const [data, setData] = useState<{ accountsBank: any[] }>({ accountsBank: [] });
    const [countTotalBanks, setCountTotalBanks] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: any[], count: number }>('accountsBank', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setData({ accountsBank: rows });
        setCountTotalBanks(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [data]);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = { searchQuery, situation };

        reloadData();
    }, [data, searchQuery, situation]);


    return (
        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de contas bancárias</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9"></div>
                <div className="col-lg-3 mt-1">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="shadow-sm p-6 rounded-sm border border-2">
                    <h4>Pesquisa avançada</h4>

                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Nenhum
                                </MenuItem>

                                <MenuItem key="1" value="y" onClick={() => setSituation('y')}>
                                    Ativo
                                </MenuItem>

                                <MenuItem key="2" value="n" onClick={() => setSituation('n')}>
                                    Inativo
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-12 d-flex mt-6">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                <i className="fa fa-search"></i>
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                                Limpar
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
            <List
                showModal={true}
                labelIcon="fas fa-plus"
                labelLinkAdd="Adicionar"
                headRows={headRows}
                hasExpenses={false}
                hasImport={false}
                hasPrintOut={false}
                hasMoreActions={false}
                data={data.accountsBank}
                page="contas-bancarias"
                sortable={true}
                loadData={loadData}
                totalCount={countTotalBanks}
                triggerLoad={triggerLoad}
                setTriggerLoad={setTriggerLoad}
            />
        </MainPageContentWrapper>
    );
}