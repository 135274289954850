import React, { useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    Card,
    Modal,
    Button,
    Spinner,
    Tabs,
    Tab,
} from "react-bootstrap";
import { Autocomplete } from '@material-ui/lab';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import api from '../../services/Api';
import logService from '../../services/LogService';
import UserService from '../../services/UserService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { yesOrNo } from '../../types/yesOrNo';
import ModalSuccess from '../../components/ModalSuccess';
import { useStyles } from '../../hooks/styles';
import { Seller } from '../../types/Seller';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Inputs = {
    typePeople: string,
    cpf: string,
    name: string,
    cnpj: string,
    corporateName: string,
    fantasyName: string,
    zipCode: string,
    address: string,
    number: string,
    district: string,
    complement: string,
    city: string,
    state: string,
    contactPeople: string,
    phone: string,
    extension: string,
    fax: string,
    cell: string,
    email: string,
    webSite: string,
    bank: string,
    agency: string,
    currentAccount: string,
    salary: string,
    sellerCommission: string,
    userId: string,
    situation: string,
    note: string
}

export function NewSeller() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar um Vendedor");

    const [isSubmit, setIsSubmit] = useState(false);


    const [activeTab, setActiveTab] = useState("details");
    const [nameInvalid, setNameInvalid] = useState(false);
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
    const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [userIdInvalid, setUserIdInvalid] = useState(false);

    const [typePeople, setTypePeople] = useState("legal");
    const [cpf, setCpf] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [name, setName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [fantasyName, setFantasyName] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [contactPeople, setContactPeople] = useState("");
    const [phone, setPhone] = useState("");
    const [extension, setExtension] = useState("");
    const [fax, setFax] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [webSite, setWebSite] = useState("");
    const [bank, setBank] = useState("");
    const [agency, setAgency] = useState("");
    const [currentAccount, setCurrentAccount] = useState("");
    const [salary, setSalary] = useState("");
    const [sellerCommission, setSellerCommission] = useState("");
    const [userId, setUserId] = useState("");
    const [isConsignment, setIsConsignment] = useState<yesOrNo>(yesOrNo.NO);
    const [situation, setSituation] = useState("active");
    const [note, setNote] = useState("");

    const [allStates, setAllStates] = useState({ states: [] });

    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [nameCnpj, setNameCnpj] = useState("");
    const [fantasy, setFantasy] = useState("");

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const detailsRequiredFields = [phone,email];
    const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

    const { register, handleSubmit, control } = useForm<Inputs>();
    const history = useHistory();

    useEffect(() => {
        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: statesBrazil.data });
        }

        getStates();
    }, []);

    function infoAddress(zipCodeCnpj?: string) {
        const checkCep = async () => {
            var zipCodeChanged = "";

            if (zipCodeCnpj) {
                zipCodeChanged = zipCodeCnpj.replace(/[^0-9]/g, "");

            } else {
                zipCodeChanged = zipCode.replace(/[^0-9]/g, "");
            }


            const cep = await axios.get(`https://viacep.com.br/ws/${zipCodeChanged}/json/`);
            setAddress(cep.data.logradouro);
            setDistrict(cep.data.bairro);
            setCity(cep.data.localidade);
            setState(cep.data.uf);
        }
        checkCep();
    }

    async function CheckCnpj(value: string) {
        const cnpjFormated = value.replace(/[^0-9]/g, "");

        var response = await axios.get(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${cnpjFormated}`);

        if (response.data.status === 'ERROR') {
            setMsgError(response.data.message);
            setShowModalExist(true);
            setCnpjInvalid(true);
            setCnpj('');
            return;
        }

        setCorporateName(response.data.nome);
        setFantasyName(response.data.fantasia);

        setZipCode(response.data.cep);
        setAddress(response.data.logradouro);
        setDistrict(response.data.bairro);
        setCity(response.data.municipio);
        setState(response.data.uf);
        setNumber(response.data.numero);
        setComplement(response.data.complemento);

        infoAddress(response.data.cep);
    }

    async function checkUserSellerExist() {
        if (userId) {
            var response = await api.get<Seller[]>(`/sellers/userIdExist/${userId}`);
            if (response.data.length > 0) {
                return false;
            }
        }

        return true;
    }

    async function checkEmailSellerExist() {
        if (email) {
            var response = await api.get(`/sellers/emailExist/${email}`);
            if (response.data.length > 0) {
                return false;
            }
        }

        return true;
    }

    async function checkCpfSellerExist() {
        if (cpf) {
            var cpfFormated = cpf.replace(/[^0-9]/g, "");
            var response = await api.get(`/sellers/cpfExist/${cpfFormated}`);
            if (response.data.length > 0) {
                setMsgError("Cpf informado já existe!");
                setShowModalExist(true);
                setCpf("");
            }
        }
    }

    async function checkCnpjSellerExist() {
        if (cnpj) {
            var cnpjormated = cnpj.replace(/[^0-9]/g, "");
            var response = await api.get(`/sellers/cnpjExist/${cnpjormated}`);
            if (response.data.length > 0) {
                setMsgError("Oops, CNPJ já foi cadastrado!");
                setShowModalExist(true);
                setCnpj("");
            }
        }
    }

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "phone" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if ("email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    async function inputsVerify(): Promise<boolean> {

        if (typePeople === "physical") {

            if (!cpf || sanitizeInput("cpf", cpf).length !== 11) {
                setMsgError("Preencha o cpf corretamente");
                setShowModalExist(true);
                setCpfInvalid(true);
                return false;

            } else if (!name) {
                setMsgError("Preencha o nome corretamente");
                setShowModalExist(true);
                setNameInvalid(true);
                return false;
            }

        } else if (typePeople === "legal") {

            if (!cnpj || sanitizeInput("cnpj", cnpj).length !== 14) {
                setMsgError("Preencha o CNPJ corretamente");
                setCnpjInvalid(true);
                return false;
            } else if (!corporateName) {
                setMsgError("Preencha a razão social da empresa");
                setCorporateNameInvalid(true);
                return false;
            } else if (!fantasyName) {
                setMsgError("Preencha o nome fantasia da empresa");
                setFantasyNameInvalid(true);
                return false;
            }
        }

        if (!phone || sanitizeInput("phone", phone).length !== 10) {
            setActiveTab("details");
            setMsgError("Preencha o telefone corretamente");
            setPhoneInvalid(true);
            return false;

        }

        if (!email || sanitizeInput("email", email) === "false") {
            setActiveTab("details");
            setMsgError("Preencha o email corretamente");
            setEmailInvalid(true);
            return false;
        }

        if (!(await checkEmailSellerExist())) {
            setMsgError("Este email já está cadastrado!");
            setEmailInvalid(true);
            return false;
        }

        if (isConsignment === yesOrNo.YES && !userId) {
            setActiveTab("additional-info");
            setMsgError("Para definir que o vendedor realiza venda consignada é preciso vincular a um usuário!");
            setUserIdInvalid(true);
            return false;
        }

        if (userId && !(await checkUserSellerExist())) {
            setActiveTab("additional-info");
            setMsgError("Não é possível vincular ao usuário selecionado pois ele já está vinculado à outro vendedor!");
            setUserIdInvalid(true);
            return false;
        }

        return true;

    }

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            let cpfFormated = cpf.replace(/[^0-9]/g, "");
            let cnpjFormated = cnpj.replace(/[^0-9]/g, "");

            setIsSubmit(true);

            const isValid = await inputsVerify();

            if (!isValid) {
                setShowModalExist(true);
                setIsSubmit(false);
                return;
            };

            const raw = {
                typePeople: typePeople,
                cpf: cpfFormated ? cpfFormated : null,
                name: name,
                cnpj: cnpjFormated ? cnpjFormated : null,
                corporateName: corporateName,
                fantasyName: fantasyName,
                zipCode: zipCode,
                address: address,
                number: data.number,
                district: district,
                complement: data.complement,
                city: city,
                state: state,
                contactPeople: data.contactPeople,
                phone: phone,
                extension: data.extension,
                fax: data.fax,
                cell: data.cell,
                email: email,
                webSite: data.webSite,
                bank: data.bank,
                agency: data.agency,
                currentAccount: data.currentAccount,
                salary: data.salary,
                sellerCommission: sellerCommission,
                userId: userId ? Number(userId) : null,
                isConsignment: isConsignment,
                situation: situation,
                note: data.note
            };

            const createResponse = await api.post("sellers", raw);

            logService.logRegister({
                itemId: createResponse.data.id,
                module: 'Vendedores',
                itemName: createResponse.data.typePeople === 'physical' ? createResponse.data.name : createResponse.data.corporateName,
            });

            setShowModalSuccess(true);
        } catch (error: any) {
            setShowModalExist(true);
            setMsgError('Ocorreu um erro ao processar a requisição');
        }

        setIsSubmit(false);
    }

    function formatToFloat(value: string) {
        if (value.includes(',') && !value.includes('.')) {
            return parseFloat(value.replace(',', '.'));

        } else {
            return parseFloat(value.replace('.', '').replace(',', '.'));
        }
    }

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-doc") {
                if (isDetailsRequiredFieldsFilled) {
                    const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-delete');
                    
                    if (current_icon) {
                        current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                        current_icon?.classList.replace("text-danger", "text-success");
                    } else {
                        prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                        prev_icon?.classList.add("text-success");
                    }
                } else {
                    const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-check-mark');

                    if (current_icon) {
                        current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                        current_icon.classList.replace("text-success", "text-danger");
                    } else {
                        prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                        prev_icon?.classList.add("text-danger");
                    }
                }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('details')?.classList.remove('current');
        document.getElementById('bank-info')?.classList.remove('current');
        document.getElementById('additional-info')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        if (!detailsRequiredFields[0]) {
            setPhoneInvalid(true);
        }

        if (!detailsRequiredFields[1]) {
            setEmailInvalid(true);
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'details') {
            setNextButton('first');
        } else if (tab == 'additional-info') {
            setNextButton('last');
        } else {
            setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['details', 'bank-info', 'additional-info'];
        let arr2 = ['flaticon-doc', 'flaticon-piggy-bank', 'flaticon-information'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
            <ModalSuccess
                msgModal="Vendedor cadastrado com sucesso!"
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/vendedores"
            />

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`} noValidate autoComplete="off">
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="details" data-kt-stepper-element='nav' onClick={() => changeTab('details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o vendedor</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="bank-info" data-kt-stepper-element='nav' onClick={() => changeTab('bank-info', 'flaticon-piggy-bank', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-piggy-bank icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes bancários</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes bancários sobre o vendedor</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="additional-info" data-kt-stepper-element='nav' onClick={() => changeTab('additional-info', 'flaticon-information', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-information icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Dados adicionais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Informações extras sobre o vendedor</div>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div id="form-buttons"
                                className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                rounded p-2 position-fixed"
                                style={{ zIndex: 1 }}>
                                <Link to="/vendedores">
                                    <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                </Link>
                                <Button
                                    type="button"
                                    variant="primary"
                                    disabled={isSubmit}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {isSubmit ? <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className="ml-2">
                                            Aguarde...
                                        </span>
                                    </> : <>
                                        <span>
                                            Salvar
                                        </span>
                                    </>}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="mb-3 mt-18">
                        <Grid item lg={2} md={6} xs={12}>
                            <TextField
                                size="small"
                                select
                                label="Tipo de pessoa"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                value={typePeople}
                                onChange={(e) => {
                                    setTypePeople(e.target.value);

                                    setCpf("");
                                    setName("");

                                    setCnpj("");
                                    setCorporateName("");
                                    setFantasyName("");
                                }}
                            >
                                <MenuItem key="0" value="legal">
                                    Pessoa Jurídica
                                </MenuItem>

                                <MenuItem key="1" value="physical">
                                    Pessoa Física
                                </MenuItem>

                            </TextField>
                        </Grid>

                        {typePeople === "physical"
                            ?
                            <>
                                <Grid item lg={3} md={6} xs={12}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error}
                                        customInput={TextField}
                                        format="###.###.###-##"
                                        label="CPF"
                                        required
                                        mask="_"
                                        value={cpf}
                                        onChange={(e) => {
                                            setCpf(e.target.value)
                                            setCpfInvalid(e.target.value ? false : true)
                                        }}
                                        error={cpfInvalid}
                                        onBlur={() => checkCpfSellerExist()}
                                    />
                                </Grid>

                                <Grid item lg={6} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Nome"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={nameInvalid}

                                    />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item lg={3} md={6} xs={12}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error}
                                        customInput={TextField}
                                        label="CNPJ"
                                        required
                                        format="##.###.###/####-##"
                                        mask="_"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => CheckCnpj(cnpj)}
                                                    >
                                                        <i className="flaticon-search"></i>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={cnpj}
                                        onChange={(e) => {
                                            setCnpj(e.target.value)
                                            setCnpjInvalid(e.target.value ? false : true)
                                        }}
                                        error={cnpjInvalid}
                                        onBlur={() => checkCnpjSellerExist()}
                                    />
                                </Grid>

                                <Grid item lg={3} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        value={corporateName}
                                        label="Razão social"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        onChange={(e) => {
                                            setCorporateName(e.target.value)
                                            setCorporateNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={corporateNameInvalid}
                                    />
                                </Grid>

                                <Grid item lg={3} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        value={fantasyName}
                                        label="Nome fantasia"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        onChange={(e) => {
                                            setFantasyName(e.target.value)
                                            setFantasyNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={fantasyNameInvalid}
                                    />
                                </Grid>
                            </>
                        }

                        {nextButton === 'first' ? (
                            <Link to="/vendedores" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />
                    </Grid>

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(tab: string) => setActiveTab(tab)}
                        id='newproduct-form-tabs'
                        style={{ display: "none" }}
                    >
                        <Tab
                            eventKey='details'
                            title="Detalhes"
                        >

                            <Grid container spacing={3}>
                                <Grid item lg={3} md={6} xs={12}>
                                    <NumericFormat
                                        margin="normal"
                                        customInput={TextField}
                                        format="#####-###"
                                        label="CEP"
                                        mask="_"
                                        value={zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />

                                    <Button variant="primary" onClick={() => infoAddress()}><i className="flaticon-search p-0"></i></Button>
                                </Grid>

                                <Grid item lg={4} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Endereço"
                                        value={address}
                                        margin="normal"
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Grid>

                                <Grid item lg={2} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Número"
                                        margin="normal"
                                        {...register("number")}
                                    />
                                </Grid>

                                <Grid item lg={3} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Bairro"
                                        value={district}
                                        margin="normal"
                                        onChange={(e) => setDistrict(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item lg={5} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Complemento"
                                        margin="normal"
                                        {...register("complement")}
                                    />
                                </Grid>

                                <Grid item lg={4} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Cidade"
                                        value={city}
                                        margin="normal"
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Grid>

                                <Grid item lg={3} md={6} xs={12}>
                                    <Autocomplete
                                        size="small"
                                        inputValue={state}
                                        options={allStates.states}
                                        getOptionLabel={({ sigla }) => sigla}
                                        style={{ width: "100%", marginTop: "6px" }}
                                        onInputChange={(event, newInputValue) => {
                                            setState(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small" {...params}
                                            label="Estado"
                                        />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item lg={4} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Pessoa de Contato"
                                        margin="normal"
                                        {...register("contactPeople")}
                                    />
                                </Grid>

                                <Grid item lg={4} md={6} xs={12}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error}
                                        customInput={TextField}
                                        format="(##) ####-####"
                                        label="Telefone"
                                        required
                                        mask="_"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                            setPhoneInvalid(e.target.value ? false : true)
                                            changeTabIcon('flaticon-doc')
                                        }}
                                        error={phoneInvalid}
                                    // onBlur={() => checkPhoneCustomerExist()}
                                    />
                                </Grid>

                                <Grid item lg={2} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Ramal"
                                        margin="normal"
                                        {...register("extension")}
                                    />
                                </Grid>

                                <Grid item lg={2} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Fax"
                                        margin="normal"
                                        {...register("fax")}
                                    />
                                </Grid>

                                {/* <div className="col-lg-4">
                                <Controller
                                    name="cell"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(99) \99999-9999" value={value} onChange={onChange}>
                                            {
                                                () => (
                                                    <TextField
                                                        size="small"
                                                        label="Celular"
                                                        margin="normal"
                                                    />
                                                )
                                            }
                                        </InputMask>
                                    )}
                                />
                            </div> */}

                                <Grid item lg={4} md={6} xs={12}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error}
                                        customInput={TextField}
                                        format="(##) #####-####"
                                        label="Celular"
                                        mask="_"
                                        {...register("cell")}
                                    // onBlur={() => checkPhoneCustomerExist()}
                                    />
                                </Grid>

                                <Grid item lg={4} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="E-mail"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailInvalid(e.target.value ? false : true)
                                            changeTabIcon('flaticon-doc')
                                        }}
                                        error={emailInvalid}
                                    //onBlur={() => checkEmailCustomerExist()}
                                    />
                                </Grid>

                                <Grid item lg={4} md={6} xs={12}>
                                    <TextField
                                        size="small"
                                        label="Website"
                                        margin="normal"
                                        placeholder="www.suaempresa.com.br"
                                        {...register("webSite")}
                                    />
                                </Grid>
                            </Grid>
                        </Tab>

                        <Tab
                            eventKey='bank-info'
                            title='Dados Bancários'
                        >

                            <Card.Body className="p-0 mt-2">
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Banco"
                                            margin="normal"
                                            {...register("bank")}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Agência"
                                            margin="normal"
                                            {...register("agency")}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Conta corrente"
                                            margin="normal"
                                            {...register("currentAccount")}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Salário"
                                            startAdornment="R$"
                                            {...register("salary")}
                                        />
                                    </Grid>
                                </div>
                            </Card.Body>

                        </Tab>

                        <Tab
                            eventKey='additional-info'
                            title='Dados Adicionais'
                        >
                            <Card.Body className="p-0 mt-2">
                                <div className="row align-items-center">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Comissão do vendedor"
                                            startAdornment="%"
                                            value={sellerCommission}
                                            onChange={(e) => setSellerCommission(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setSellerCommission("100");
                                                } else {
                                                    setSellerCommission(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Vincular ao usuário"
                                            getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                                            value={userId}
                                            hasError={userIdInvalid}
                                            onSelect={(option) => setUserId(String(option?.id ?? ''))}
                                            apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText, isAccountant: yesOrNo.NO })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!userId) return null;
                                                return loadedOptions.find((option) => option.id === Number(userId)) ?? UserService.getUserById(userId)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <FormControlLabel
                                            value="end"
                                            control={(
                                                <Checkbox
                                                    color="primary"
                                                    onChange={e => setIsConsignment(e.target.checked ? yesOrNo.YES : yesOrNo.NO)}
                                                    checked={isConsignment === yesOrNo.YES}
                                                />
                                            )}
                                            label="Venda consignada"
                                            labelPlacement="end"
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            select
                                            label="Situação"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            value={situation}
                                            onChange={(e) => setSituation(e.target.value)}
                                        >
                                            <MenuItem key="0" value="active">
                                                Ativo
                                            </MenuItem>

                                            <MenuItem key="1" value="inactive">
                                                Inativo
                                            </MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            label="Observações"
                                            multiline
                                            rows="4"
                                            margin="normal"
                                            {...register("note")}
                                        />
                                    </Grid>
                                </div>
                            </Card.Body>
                        </Tab>
                    </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}