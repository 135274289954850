import { Collapse, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { WhatsappHistory, whatsappStatus } from '../types/WhatsappHistory';
import { ValidationBadge } from './ValidationBadge';

export type ModalSendWhatsappProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onClickSend: () => any;
    infoWhatsapp: WhatsappToCustomer;
    setInfoWhatsapp: React.Dispatch<React.SetStateAction<WhatsappToCustomer>>;
    whatsappHistory: WhatsappHistory[];
}

export type WhatsappToCustomer = {
    refId: string,
    refName: string,
    customerName: string,
    customerId: string,
    number: string,
    message: string,
};

export function ModalSendWhatsapp({
    showModal,
    setShowModal,
    onClickSend,
    infoWhatsapp,
    setInfoWhatsapp,
    whatsappHistory,
}: ModalSendWhatsappProps) {
    const [isSendingWhatsapp, setIsSendingWhatsapp] = useState(false);
    const [pagesWhatsapp, setPagesWhatsapp] = useState(0);
    const [rowsPerPageWhatsapp, setRowsPerPageWhatsapp] = useState(5);

    const handleChangePageWhatsapp = useCallback((next: number) => {
        setPagesWhatsapp(next);
    }, []);

    const handleRowsPerPageWhatsapp = useCallback((value: number) => {
        setRowsPerPageWhatsapp(value);
    }, []);

    const clearWhatsappInfo = () => {
        setInfoWhatsapp((prevState) => ({
            ...prevState,
            refId: '',
            refName: '',
            customerName: '',
            customerId: '',
            number: '',
            message: '',
        }));
    }

    const handleChangeInfoWhatsappField = (event: any, refId: string, field: string) => {
        const newValue = event.target.value;
        setInfoWhatsapp((prevState) => {
            if (prevState.refId !== refId) return prevState;

            return {
                ...prevState,
                [field]: newValue,
            };
        });
    };

    const handleClickSendWhatsapp = async () => {
        setIsSendingWhatsapp(true);

        await onClickSend();

        setIsSendingWhatsapp(false);
    };

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"
            size="lg"
            show={showModal}
            onHide={() => {
                setShowModal(false);
                clearWhatsappInfo();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Enviar por Whatsapp
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-lg-12">
                        <TextField
                            size="small"
                            multiline
                            rows="2"
                            margin="normal"
                            label="Mensagem"
                            value={infoWhatsapp.message}
                            onChange={(e) => handleChangeInfoWhatsappField(e, infoWhatsapp.refId, 'message')}
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <h3 className="border-bottom mt-3 pb-3">Whatsapp enviados</h3>

                    <TableContainer style={{ maxHeight: '75vh' }}>
                        <Table stickyHeader className="wrap">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Número</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Mensagem</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    whatsappHistory.length
                                        ?
                                        whatsappHistory.slice(pagesWhatsapp * rowsPerPageWhatsapp, pagesWhatsapp * rowsPerPageWhatsapp + rowsPerPageWhatsapp).map((value, index) => {
                                            var created_at = new Date(value.created_at);
                                            var date = created_at.getDate() < 10 ? "0" + created_at.getDate() : created_at.getDate();
                                            var month = (created_at.getMonth() + 1) < 10 ? "0" + (created_at.getMonth() + 1) : (created_at.getMonth() + 1);

                                            var hour = (created_at.getHours() + 2) < 10 ? "0" + (created_at.getHours() + 2) : (created_at.getHours() + 2);


                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{value.number}</TableCell>
                                                    <TableCell>{date + "/" + month + "/" + created_at.getFullYear() + "  " + hour + ":" + (created_at.getMinutes() < 10 ? "0" + created_at.getMinutes() : created_at.getMinutes())}</TableCell>
                                                    <TableCell>{value.message}</TableCell>
                                                    <TableCell>
                                                        {value.status === whatsappStatus.FAILED && (
                                                            <ValidationBadge variant="danger" label="Falha" title="Falha ao enviar" />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={3}>Nenhum whatsapp enviado até o momento...</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        labelRowsPerPage="Linhas por página"
                        page={pagesWhatsapp}
                        component="div"
                        count={whatsappHistory.length}
                        rowsPerPage={rowsPerPageWhatsapp}
                        rowsPerPageOptions={[5, 10, 25]}
                        backIconButtonProps={{
                            'aria-label': 'Página Anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Próxima Página',
                        }}
                        onChangePage={(_, next) => handleChangePageWhatsapp(next)}
                        onChangeRowsPerPage={(evt) => handleRowsPerPageWhatsapp(Number(evt.target.value))}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => handleClickSendWhatsapp()}>
                    {
                        isSendingWhatsapp ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </>
                            :
                            <span>Enviar</span>
                    }
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowModal(false);
                    clearWhatsappInfo();
                }}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}