import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency, formatNumberToString } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { formatDate } from '../../../utils/dateFormat';
import { IproductsPurchaseOrder, IpurchaseOrder } from '../../../types/RequestPurchaseOrder';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { Entry } from '../../../types/Entry';
import { ProductsTable } from './ProductsTable';
import { CenterCost } from '../../../types/CenterCost';
import CenterCostService from '../../../services/CenterCostService';
import { Link } from 'react-router-dom';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Entrada" },
    { reference: "provider", value: "Fornecedor" },
    { reference: "totalPrice", value: "Valor Total" },
    { reference: "entryDate", value: "Data da Entrada" },
    { reference: "issuanceDate", value: "Data de Emissão" },
    { reference: "status", value: "Situação" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "ipi", value: "IPI" },
    { reference: "total", value: "Valor Total" },
]

type Filters = {
    supplier: string,
    entryDateMin: string,
    entryDateMax: string,
    issuanceDateMin: string,
    issuanceDateMax: string,
    createdDateMin: string,
    createdDateMax: string,
    cfop: string,
    status: string,
}

type Beneficiary = {
    id: number;
    name: string;
}

export function ListReportEntry() {
    const [foundEntryPaginate, setFoundEntryPaginate] = useState<any[]>([]);
    const [foundEntry, setFoundEntry] = useState<any[]>([]);
    const [countTotalEntry, setCountTotalEntry] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [supplierId, setSupplierId] = useState("");

    const [showProducts, setShowProducts] = useState(false);

    const [centerCost, setCenterCost] = useState("");
    const [status, setStatus] = useState('all');
    const [entryDateMin, setEntryDateMin] = useState('');
    const [entryDateMax, setEntryDateMax] = useState('');
    const [issuanceDateMin, setIssuanceDateMin] = useState('');
    const [issuanceDateMax, setIssuanceDateMax] = useState('');
    const [createdDateMin, setCreatedDateMin] = useState('');
    const [createdDateMax, setCreatedDateMax] = useState('');
    const [cfop, setCfop] = useState('');
    const [totalEntry, setTotalEntry] = useState(0);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();


    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundEntryPaginate;
        let total: number = 0;
        let exportProductsData: ReportDataRow[] = [];

        aux.forEach((entry) => {
            const productsFormated = JSON.parse(entry.products);
            const provider = entry.customerEntity.length 
                            ? (entry.customerEntity[0].typePeople == "physical" ? entry.customerEntity[0].name : entry.customerEntity[0].corporateName)
                            : ' - '; 

            const data: BodyDataBaseProps[] = [
                {
                    for: "id",
                    value: String(entry.id),
                    jsx: <Link to={`/entrada-de-mercadoria/${entry.id}`}>{entry.id}</Link>,
                    id: true
                },
                { for: "provider", value: provider },
                { for: "total", value: formatCurrency(entry.total)},
                { for: "entryDate", value: entry.entryDate ? formatDate(entry.entryDate) : '-'},
                { for: "issuanceDate", value: entry.issuanceDate ? formatDate(entry.issuanceDate) : '-'},
                { for: "status", value: getSituationText(entry.status) },
            ];

            if (showProducts !== false) {
                data.push({
                    for: "products",
                    value: '',
                    secondaryRow: true,
                    jsx: <ProductsTable products={productsFormated} />
                });
            };

            list.push(data);
        });

        foundEntry.forEach((entry) => {
            const provider = entry.customerEntity.length 
                            ? (entry.customerEntity[0].typePeople == "physical" ? entry.customerEntity[0].name : entry.customerEntity[0].corporateName)
                            : ' - '; 

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(entry.id), id: true },
                { for: "provider", value: provider },
                { for: "total", value: formatCurrency(entry.total)},
                { for: "entryDate", value: entry.entryDate},
                { for: "issuanceDate", value: entry.issuanceDate},
                { for: "status", value: getSituationText(entry.status) },
            ];

            total += Number(entry.total);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            // if (showProducts !== false) {
            //     exportProductsData = productsFormated.map((product) => {
            //         return {
            //             cells: [
            //                 {for: 'name', content: product.INSTANCE.name ?? '-'},
            //                 {for: 'amount', content: product.amount},
            //                 {for: 'unitaryValue', content: product.unitaryValue},
            //                 {for: 'ipi', content: product.ipi},
            //                 {for: 'total', content: product.total},
            //             ],
            //         };
            //     });
            // };

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setTotalEntry(total);

        setExportTotals([
            { name: 'Quantidade de entradas', value: foundEntry.length },
            { name: 'Valor Total', value: 'R$ ' + formatNumberToString(totalEntry) },
        ]);

        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundEntryPaginate, foundEntry]);

    const clearSearch = () => {
        setEntryDateMin('');
        setEntryDateMax('')
        setStatus('all');
        setSupplierId('');
        setShowProducts(false)
        setIssuanceDateMin('');
        setIssuanceDateMax('');
        setCreatedDateMin('');
        setCreatedDateMax('');
        setCenterCost('');
        setCfop('');
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Entry[], count:number}>("/report/entry", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;


        const entry = await api.get("/report/entry", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundEntryPaginate(data.rows ? rows : []);

        setFoundEntry(entry.data);
        setCountTotalEntry(entry.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            supplier: supplierId,
            entryDateMin,
            entryDateMax,
            issuanceDateMin,
            issuanceDateMax,
            createdDateMin,
            createdDateMax,
            cfop,
            status,
        };
        reloadData();

    }, [
        supplierId,
        entryDateMin,
        entryDateMax,
        issuanceDateMin,
        issuanceDateMax,
        createdDateMin,
        createdDateMax,
        cfop,
        status,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Entrada de Mercadoria', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Fornecedor"
                        getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                        value={supplierId}
                        onSelect={(option) => setSupplierId(option ? String(option.id) : '')}
                        apiSearchHandler={((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' }))}
                        getSelectedOption={(loadedOptions) => {
                            if(!supplierId) return null;

                            const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(supplierId));
                            if(loadedBeneficiary) return loadedBeneficiary;

                            return CustomerService.getCustomerById(supplierId);
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da entrada"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={issuanceDateMin}
                        onChange={(e) => setIssuanceDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da entrada"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={issuanceDateMax}
                        onChange={(e) => setIssuanceDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={entryDateMin}
                        onChange={(e) => setEntryDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={entryDateMax}
                        onChange={(e) => setEntryDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da criação"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={createdDateMin}
                        onChange={(e) => setCreatedDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data da criação"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={createdDateMax}
                        onChange={(e) => setCreatedDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Centro de Custos"
                        getOptionLabel={(option: CenterCost) => option.name}
                        value={centerCost}
                        onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!centerCost) return null;
                            return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="pending" onClick={() => setStatus('pending')}>
                            Pendente
                        </MenuItem>

                        <MenuItem key="3" value="progress" onClick={() => setStatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="4" value="sent" onClick={() => setStatus('sent')}>
                            Enviado
                        </MenuItem>

                        <MenuItem key="5" value="asnwered" onClick={() => setStatus('asnwered')}>
                            Respondido
                        </MenuItem>

                        <MenuItem key="6" value="chosen" onClick={() => setStatus('chosen')}>
                            Escolhido
                        </MenuItem>

                        <MenuItem key="7" value="paid" onClick={() => setStatus('paid')}>
                            Pago
                        </MenuItem>

                        <MenuItem key="7" value="attended" onClick={() => setStatus('attended')}>
                            Finalizado
                        </MenuItem>

                        <MenuItem key="7" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        label="CFOP"
                        margin="normal"
                        size="small"
                        value={cfop}
                        onChange={(e) => setCfop(e.target.value)}
                    />
                </div>

                <div className="col-lg-3">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProducts}
                                onChange={(_, checked) => setShowProducts(checked)}
                            />
                        }
                        label="Mostrar Produtos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Entrada de Mercadorias"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Entrada de Mercadorias', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalEntry}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de entradas</TableCell>
                                    <TableCell colSpan={1}>{foundEntry.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(totalEntry)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}