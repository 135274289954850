import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { Link, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import logService from '../../services/LogService';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
    description: string,
}

type subCategory = {
    id: number,
    uuidCategory: string,
    nameSubCategory: string,
}

type urlParam = {
    id: string
}

export function EditCategoryProduct() {
    const { user } = useSelector((state: any) => state.auth);
    
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Categoria de Produto");

    const [nameInvalid, setNameInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [categoryName, setCategoryName] = useState("");
    const [description, setDescription] = useState("");
    const [uuidCategory, setUuidCategory] = useState("");
    const { id } = useParams<urlParam>();

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const [subCategories, setSubCategories] = useState({
        list: [
            {
                id: 0,
                nameSubCategory: "",
            }
        ]
    });

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    useEffect(() => {
        getCategoryProduct();
    }, []);

    async function getCategoryProduct () {
        var category = await api.get(`/categoryproduct/${id}`);

        const uuidCategoryQuick = category.data.uuidCategory;

        setCategoryName(category.data.nameCategory);
        setDescription(category.data.description);
        setUuidCategory(uuidCategoryQuick);

        setBeforeSubmitData(category.data);

        var subCategory = await api.get(`/subcategoryproduct/category/${uuidCategoryQuick}`);
        
        var subCategoriesMapped = subCategory.data.map((subCategoryObj: subCategory) => {
            return {
                id: subCategoryObj.id,
                nameSubCategory: subCategoryObj.nameSubCategory,
            }
        })

        setSubCategories({list: subCategoriesMapped});
    }

    function addSubCategory() {
        var subCategoriesList = subCategories.list;

        subCategoriesList.push({
            id: 0,
            nameSubCategory: "",
        });

        setSubCategories({ list: subCategoriesList });
    }
  
    function deleteSubCategory(index: number, idSubCategory: number) {
        api.delete(`/subcategoryproduct/${idSubCategory}`);

        var subCategoriesList = subCategories.list;
        subCategoriesList.splice(index, 1);

        setSubCategories({ list: subCategoriesList });
    }

    function subCategoriesVerify(): boolean {
        var subCategoriesList = subCategories.list;

        if (subCategoriesList.length) {
            for (let index = 0; index < subCategoriesList.length; index++) {
               if (!subCategoriesList[index].nameSubCategory) {
                    setIsSubmit(false)
                    setMsgError("Preencha o nome da sub categoria corretamente")
                    setShowModalExist(true);

                    return false

                }
            }
        }
        return true

    }

    function inputsVerify(): boolean {

        if (!categoryName) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da categoria corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
            "nameCategory": categoryName,
            "description": description,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        const editResponse = await api.post(`/categoryproduct/${id}`, raw);

        logService.logEdit({
            itemId: editResponse.data.id,
            itemName: editResponse.data.nameCategory,
            module: 'Categorias de Produto',
            oldData: beforeSubmitData,
            newData: editResponse.data,
            fieldsMap: {
                nameCategory: 'Nome',
                description: 'Descrição',
            },
        });

        await saveSubCategories(uuidCategory).then(() => {
            window.location.href = "/categorias-produto"                
        });
    }

    async function saveSubCategories(uuidCategory: string) {
        var subCategoryList = subCategories.list;
        subCategoryList.forEach(async (subCategoryObj, index) => {
            if(subCategoryObj.id === 0 && subCategoryObj.nameSubCategory !== "") {

                var rawSubCategory = JSON.stringify({
                    "uuidCategory": uuidCategory,
                    "nameSubCategory": subCategoryObj.nameSubCategory,
                });

                const responseVariation = await api.post("/subcategoryproduct", rawSubCategory);
            }
        })
    }

    return (
        <MainPageContentWrapper>
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/categorias-produto";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/categorias-produto";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`} noValidate autoComplete="off">
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a categoria de produtos</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/categorias-produto">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button
                                            type="button"
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {isSubmit ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />

                                                <span className="ml-2">
                                                    Aguarde...
                                                </span>
                                            </> : <>
                                                <span>
                                                    Salvar
                                                </span>
                                            </>}
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    className={classes.error}
                                    label="Nome da Categoria*"
                                    margin="normal"
                                    value={categoryName}
                                    onChange={(e) => {
                                        setCategoryName(e.target.value)
                                        setNameInvalid(e.target.value ? false : true)
                                    }}
                                    error={nameInvalid}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>
                            <Grid item lg={8} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    className={classes.error}
                                    label="Descrição*"
                                    margin="normal"
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>
                        </Grid>

                        <Grid xs={12}>
                            <Link to="/categorias-produto" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        </Grid>

                        <Grid container spacing={1}>
                            {
                            subCategories.list.map(({ id, nameSubCategory}, index) => {
                                return (
                                    <Grid key={index} container spacing={1}>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                className={classes.error}
                                                label="Nome da sub categoria"
                                                margin="normal"
                                                value={subCategories.list[index].nameSubCategory}
                                                onChange={(e) => {
                                                    var subCategoriesList = subCategories.list;
            
                                                    subCategoriesList[index].nameSubCategory = e.target.value;
            
                                                    setSubCategories({ list: subCategoriesList });
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        {
                                            user.isAccountant == "n"
                                            ?
                                            <Grid item lg={2} md={6} xs={12} className="d-flex align-items-center">
                                                <Button size="sm" variant='danger' onClick={() => deleteSubCategory(index, id)}>Excluir</Button>
                                            </Grid>
                                            : <></>
                                        }
                                    </Grid>
                                )
                            })
                            }
                        </Grid>

                        {
                            user.isAccountant == "n"
                            ?
                            <div className='col-lg-5 col-sm-12 pl-0 mt-5'>
                                <Button variant='primary' className='mr-3' onClick={() => addSubCategory()}>
                                    Adicionar sub categoria
                                </Button>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}