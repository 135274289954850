import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../services/Api";
import { LineChart, Line, PieChart, Pie, ResponsiveContainer, Sector, Tooltip, Cell, CartesianGrid, XAxis, YAxis, Legend, BarChart, Bar, ComposedChart, Area, ReferenceLine, Label } from "recharts";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { useCompanyBranch } from "../../../hooks/companyBranch";
import { menuSystem } from "../../../hooks/customPermissionHandler";
import { MainPageContentWrapper } from "../../../components/MainPageContentWrapper";

export function AccountingDashboardPage() {
	const [companiesLast7Days, setCompaniesLast7Days] = useState(0);
	const [companiesLast30Days, setCompaniesLast30Days] = useState(0);
	const [companiesLast90Days, setCompaniesLast90Days] = useState(0);
	const [companiesLast7DaysNf, setCompaniesLast7DaysNf] = useState(0);
	const [companiesLast30DaysNf, setCompaniesLast30DaysNf] = useState(0);
	const [companiesLast90DaysNf, setCompaniesLast90DaysNf] = useState(0);
	const [companiesWithNoActivityInBills, setCompaniesWithNoActivityInBills] = useState(0);
	const [companiesWithNoActivityInNf, setCompaniesWithNoActivityInNf] = useState(0);
	const [acceptedIndications, setAcceptedIndications] = useState(0);
	const [pendingIndications, setPendingIndications] = useState(0);
	const [canceledIndications, setCanceledIndications] = useState(0);
	const { user } = useSelector((state: any) => state.auth);
	const [companyplan, setCompanyPlan] = useState<any>();
	const { selectedCompany: currentCompany } = useCompanyBranch();

	type CustomerResponseData = {
		companyActivityInBillsLast7Days: any;
		companyActivityInBillsLast30Days: any;
		companyActivityInBillsLast90Days: any;
		companyActivityInNfLast7Days: any; 
		companyActivityInNfLast30Days: any;
		companyActivityInNfLast90Days: any;
		companiesWithNoActivityInBills: any;
		companiesWithNoActivityInNf: any;
	}

	// Botões de rápido acesso
	const [modulesSystem, setModulesSystem] = useState<menuSystem[]>([]);
	const [fastAccess, setFastAccess] = useState<number[]>([]);

	const getSubscriptionPlan = useCallback(async () => {
		const response = await api.get(`companies/${user.companyId}/subscriptionPlan/active`);
		setCompanyPlan(response.data);
	}, [user]);

	async function getCompanies() {
		const { data } = await api.get<CustomerResponseData>('/accountantCompaniesActivity');
		
		const { 
			companyActivityInBillsLast7Days,
			companyActivityInBillsLast30Days,
			companyActivityInBillsLast90Days,
			companyActivityInNfLast7Days,
			companyActivityInNfLast30Days,
			companyActivityInNfLast90Days,
			companiesWithNoActivityInBills,
			companiesWithNoActivityInNf
		} = data;

		setCompaniesLast7Days(companyActivityInBillsLast7Days.length);
		setCompaniesLast30Days(companyActivityInBillsLast30Days.length);
		setCompaniesLast90Days(companyActivityInBillsLast90Days.length);
		setCompaniesLast7DaysNf(companyActivityInNfLast7Days.length);
		setCompaniesLast30DaysNf(companyActivityInNfLast30Days.length);
		setCompaniesLast90DaysNf(companyActivityInNfLast90Days.length);
		setCompaniesWithNoActivityInBills(companiesWithNoActivityInBills.length);
		setCompaniesWithNoActivityInNf(companiesWithNoActivityInNf.length);
	}

	async function getAccountantIndications() {
		const { data } = await api.get('/indications');

		const { accept, pending, canceled } = data.reduce((acc: any, indication: any) => {
			if(indication.status) {
				++acc[indication.status];
			}

			return acc;
		}, {accept: 0, pending: 0, canceled: 0});

		setAcceptedIndications(accept);
		setPendingIndications(pending);
		setCanceledIndications(canceled);
	}

	useEffect(() => {
		getSubscriptionPlan();
	}, [getSubscriptionPlan]);

	useEffect(() => {
		setFastAccess(JSON.parse(user.fastAccess) ?? []);

		getModulesSystem();
	}, [currentCompany]);

	useEffect(() => {
		getCompanies();
		getAccountantIndications();
	}, []);

	async function getModulesSystem() {
		const modules = await api.get("/menus");

		const modulesSorted = modules.data.sort((data1: any, data2: any) => {
			return data1.id - data2.id;
		})

		setModulesSystem(modulesSorted);
	}
		
	const dataChart = [
		{
		  name: 'Últimos sete dias',
		  financial: companiesLast7Days,
		  nf: companiesLast7DaysNf,
		},
		{
		  name: 'Até 30 dias',
		  financial: companiesLast30Days,
		  nf: companiesLast30DaysNf,
		},
		{
		  name: 'Últimos 3 meses',
		  financial: companiesLast90Days,
		  nf: companiesLast90DaysNf,
		},
		{
		  name: 'Nunca usou',
		  financial: companiesWithNoActivityInBills,
		  nf: companiesWithNoActivityInNf,
		},
	];

	return <div className="p-2">
		{companyplan?.expirationDate && companyplan.daysToExpire >= 0 && companyplan.daysToExpire <= 3 && (
			<MainPageContentWrapper>
				<div className="col-lg-12 mt-3">
					<div className="alert alert-danger">Faltam {companyplan.daysToExpire} dias para o seu plano expirar! Realize o pagamento para renovar</div>
				</div>
			</MainPageContentWrapper>
		)}

		<div className="row">
			{
				fastAccess.length
					?
					<div className="d-flex flex-column col-12">
						<ButtonGroup className="flex-wrap">
							{
								modulesSystem.filter((moduleData: any) => fastAccess.includes(moduleData.id)).map((moduleMenu: any) => {
									var modulesWithoudPageAdd = [11, 16, 26, 27, 73, 35, 36, 37, 38, 39, 41, 42, 43, 45, 46,
										47, 48, 49, 50, 51, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
										63, 64, 66, 67, 69, 70, 71, 74];
									var page = "";

									if (!modulesWithoudPageAdd.find((module: any) => {
										return module == moduleMenu.id;
									})) {
										page = "/adicionar";
									}

									if (moduleMenu.route) {
										return (
											<Button href={moduleMenu.route + page} key={moduleMenu.id} variant="primary" className="mb-3">Novo(a) {moduleMenu.name}</Button>
										)
									}
								})
							}
						</ButtonGroup>
					</div>
					: <></>
			}

			<div className="col-lg-8 mt-3 mb-3">
				<div className="card card-custom p-2">
					<div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap pb-10">
						<div className="col-12 d-flex flex-row align-items-center justify-content-between mb-3">
							<b>Resumo das últimas atividades de seus clientes</b>
						</div>
						<ResponsiveContainer width="100%" height={300}>
							<BarChart width={1000} height={300} data={dataChart} barSize={30}>
								<CartesianGrid vertical={false} />
								<XAxis dataKey="name" />
								<YAxis axisLine={false} tickLine={false} type="number" allowDecimals={false} x={50}>
									<Label 
										style={{
											textAnchor: "middle",
										}}
										value={'Número de clientes ativos'}
										angle={-90}
										position={'left'}
										offset={-10}
									/>
								</YAxis>
								<Tooltip />
								<Legend />
								<Bar name="Financeiro" dataKey="financial" fill="blue" />
								<Bar name="Notas Fiscais" dataKey="nf" fill="green" />
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div>

			<div className="col-lg-4 mt-3 mb-3 d-flex justify-content-between flex-column">
				<div className="card card-custom gutter-b h-60"> {/*h-100*/}
					<div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap p-0">
						<div className="col-12 d-flex flex-column align-items-center justify-content-between mb-3 p-0">
							<div className="p-5 align-self-start">
								<b>Indicações</b>
							</div>
							<div className="d-flex w-100 justify-content-between align-items-center w-100 h-100" style={{ borderTop: '1px solid #bbb6b6' }}>
								<div className="d-flex flex-column align-items-center ml-auto mr-auto mt-3" style={{ fontSize: '16px' }}>
									<b style={{ color: 'green', fontSize: '28px' }}>{ acceptedIndications }</b>
									<p className="m-0">Aceitas</p>
								</div>
								<div className="d-flex flex-column align-items-center ml-auto mr-auto mt-3" style={{ fontSize: '16px' }}>
									<b style={{ color: '#ddda16', fontSize: '28px' }}>{ pendingIndications }</b>
									<p className="m-0">Em análise</p>
								</div>
								<div className="d-flex flex-column align-items-center ml-auto mr-auto mt-3" style={{ fontSize: '16px' }}>
									<b style={{ color: 'red', fontSize: '28px' }}>{ canceledIndications }</b>
									<p className="m-0">Perdidas</p>
								</div>
							</div>
							<div className="p-5">
								<div className="mb-3">
									<a href="/indicacoes"><b>Acompanhar minhas indicações</b></a>
								</div>
								<div className="text-center">
									<a href="/indicacoes/adicionar" className="btn btn-primary">Indicar empresa</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="card card-custom gutter-b">
					<div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap p-0">
						<div className="col-12 d-flex flex-column align-items-center justify-content-between mb-3 p-0">
							<div className="p-5 align-self-start">
								<b>Acompanhamento de assinaturas</b>
							</div>
							<div className="d-flex justify-content-between align-items-center w-100 h-100" style={{ borderTop: '1px solid #bbb6b6' }}>
								<div className="m-2 flex-1 pl-3 pt-3 pb-3">
									<b style={{ color: 'red', fontSize: '28px' }}>10</b>
								</div>
								<div className="m-2 flex-1 pt-3 pb-3" style={{}}>
									<p className="m-0">Clientes com renovação automática inativa</p>
								</div>
								<div className="m-2 pr-3 pt-3 pb-3" style={{ minWidth: 'fit-content' }}>
									<a href=""><b>Ver todos</b></a>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	</div>;
}
