export enum emailStatus {
    SUCCESS = "success",
    FAILED = "failed",
}

export type EmailsHistory = {
    email: string,
    created_at: string,
    message: string,
    status: emailStatus,
    failReason: string | null;
}