import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Button, Spinner, Tabs, Tab, Modal } from "react-bootstrap";
import { MenuItem, TextField, InputAdornment, Grid } from '@material-ui/core';


import api from "../../services/Api";
import { PaymentDetails, Product, usePurchaseOrder } from './context';
import { ListProducts } from './ListProducts';
import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import { ConditionOrInstallment } from './ConditionOrInstallments';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import LogService from '../../services/LogService';
import CustomerSelect from '../../components/CustomerSelect';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import SellerService from '../../services/SellerService';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import useSellerUser from '../../hooks/sellerUser';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function NewPurchaseOrder() {
    const [isSubmit, setIsSubmit] = useState(false);

    const [activeTab, setActiveTab] = useState("products");

    const [note, setNote] = useState("");
    const [weight, setWeight] = useState("");
    const [carrier, setCarrier] = useState("");
    const [modalityFreight, setModalityFreight] = useState(0);
    const [deadline, setDeadline] = useState("");
    const [insideNotes, setInsideNotes] = useState("");
    const [supplierId, setSupplierId] = useState<number>();
    const [supplierName, setSupplierName] = useState("");
    const [seller, setSeller] = useState("");
    const [total, setTotal] = useState(0);
    const [numberPO, setNumberPO] = useState(0);
    const [orderDate, setOrderDate] = useState("");
    const [freightPrice, setFreightPrice] = useState(0);
    const [discountMoney, setDiscountMoney] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [conditionName, setConditionName] = useState("");
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [reference, setReference] = useState("");

    const [productsInvalid, setProductsInvalid] = useState<boolean>(false);
    const [paymentsInvalid, setPaymentsInvalid] = useState<boolean>(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-open-box");

    //Input Verify
    const [supplierError, setSupplierError] = useState(false);
    const [productsError, setProductsError] = useState(false);
    const [installmentsError, setInstallmentsError] = useState(false);

    const { idToClone } = useParams<{ idToClone: string }>();
    const history = useHistory();
    const subHeader = useSubheader();
    const { handleSubmit } = useForm();
    const { classes, products, payments, purchaseOrders, dispatchProducts, dispatchPayment } = usePurchaseOrder();
    const { userSellerInfo } = useSellerUser();


    subHeader.setTitle("Adicionar uma Ordem de Compra");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setSupplierId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        if (userSellerInfo) {
            setSeller(String(userSellerInfo.id));
        }
    }, [userSellerInfo]);

    useEffect(() => {
        dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchProducts({ type: "ADD" });
        dispatchPayment({
            type: "GENERATE",
            payload: {
                amount: 0,
                value: 0,
            }
        });
        setOrderDate(format(Date.now(), "yyyy-MM-dd"));
    }, []);

    useEffect(() => {
        const helper = purchaseOrders;
        const sorted = helper.sort(
            (el, el_) => {
                if (el.id > el_.id) return 1;
                if (el.id < el_.id) return -1;
                return 0;
            }
        );

        let newNumberPO = 0;

        if (sorted.length > 0)
            newNumberPO = sorted[helper.length - 1].id + 2;
        else
            newNumberPO = 1;

        setNumberPO(newNumberPO);
    }, [purchaseOrders]);

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        const discountNumber = discountMoney ? discountMoney : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freightPrice ? freightPrice : 0;

        const partial = totalProduct + freigthNumber;

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setFreightPrice(0);
            setTotal(0);
            setDiscountMoney(0);

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {

            total = total - (total * (discountPercentageFloat / 100));
        }

        setTotal(total);
    }, [products, discountMoney, discountPercentage, freightPrice]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    useEffect(() => {
        if (!idToClone) return;

        async function getOrderToCloneData() {
            const { data: orderToClone } = await api.get(`purchase-order/${idToClone}`);

            if (!orderToClone) return;

            const productsData: Product[] = JSON.parse(orderToClone.products);
            const installmentsData: PaymentDetails[] = JSON.parse(orderToClone.installments);

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: productsData,
                }
            });

            // dispatchPayment({
            //     type: "INITIAL",
            //     payload: {
            //         payments: installmentsData,
            //     }
            // });

            setNote(orderToClone.note);
            setTotal(parseFloat(orderToClone.totalPrice));
            setWeight(formatCurrency(orderToClone.totalWeight));
            setSeller(String(orderToClone.seller));
            setCarrier(orderToClone.carrier);
            setSupplierId(orderToClone.supplierId ?? undefined);
            setSupplierName(orderToClone.supplierName ?? '');
            // setDeadline(orderToClone.deadline);
            setInsideNotes(orderToClone.insideNote);
            setFreightPrice(parseFloat(orderToClone.freightPrice));
            setDiscountMoney(orderToClone.discountMoney);
            setConditionName(orderToClone.conditionName);
            setModalityFreight(Number(orderToClone.modalityFreight));
            setDiscountPercentage(orderToClone.discountPercentage);
            setReference(orderToClone.reference);
        }

        getOrderToCloneData();
    }, [idToClone]);

    function inputsValid() {
        setSupplierError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        var isSubmit = true;

        if (!supplierId && !supplierName) {
            setMsgError("Selecione um fornecedor!");
            setShowModalAttention(true);
            setSupplierError(true);
            isSubmit = false;
            return isSubmit;
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setActiveTab("products");
            setMsgError("Adicione pelo menos 1(um) Produto para a Ordem de Compra!");
            setShowModalAttention(true);
            isSubmit = false;
            return isSubmit;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (!payments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas da ordem de compra!");
            setShowModalAttention(true);
            setInstallmentsError(true);
            isSubmit = false;
            return isSubmit;
        }

        const sumInstallments = payments.reduce((sum, payment) => sum + payment.value, 0);

        if (sumInstallments !== formatToFloat(total)) {
            setActiveTab("payment");
            setMsgError("A soma das parcelas deve concidir com o valor total da ordem de compra!");
            setShowModalAttention(true);
            setInstallmentsError(true);
            isSubmit = false;
            return isSubmit;
        }

        return isSubmit;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const isValid = (data: any) => {
        setProductsError(data)
    };

    const onSubmit = async (data: any) => {
        if (inputsValid()) {
            try {
                const situation: OrdersSituation[] = [];

                situation.push({
                    dateSituation: format(Date.now(), "yyyy-MM-dd"),
                    commentsSituation: "",
                    statusSituation: "open",
                });

                const raw = {
                    orderDate,
                    numberOrder: String(numberPO),
                    insideNote: String(insideNotes).length > 0 ? insideNotes : null,
                    supplierId: supplierId,
                    supplierName: !supplierId ? supplierName : null,
                    carrier: String(carrier).length > 0 ? carrier : null,
                    seller: seller.length > 0 ? Number(seller) : null,
                    note: String(note).length > 0 ? note : null,
                    totalWeight: String(weight).length > 0 ? formatToFloat(weight) : null,
                    deadline: String(deadline).length > 0 ? deadline : null,
                    modalityFreight: String(modalityFreight).length > 0 ? Number(modalityFreight) : null,
                    discountPercentage: String(discountPercentage).length > 0 ? formatToFloat(discountPercentage) : null,
                    freightPrice: freightPrice ? freightPrice : null,
                    discountMoney: discountMoney ? discountMoney : null,
                    conditionName: String(conditionName).length > 0 ? conditionName : null,
                    status: "open",
                    totalPrice: total,
                    products: JSON.stringify(products),
                    situation: JSON.stringify(situation),
                    installments: JSON.stringify(payments),
                    movedToStock: "n",
                    reference: reference
                }

                const result = await api.post("purchase-order", raw);

                history.push("/ordem-de-compra");

                LogService.logRegister({
                    itemId: result.data.id,
                    module: 'Ordem de compra',
                    itemName: 'Compra'
                });

            } catch (error) {
                console.log(error);
            }
        }
    }

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-open-box") {
            const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

            if (hasProducts) {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setProductsInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

                setProductsInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else if (previousIcon === "flaticon-list-1") {
            if (payments.length) {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-list-1", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");

                setPaymentsInvalid(false);
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-check-mark');

                setPaymentsInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-list-1", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        }
        else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('products')?.classList.remove('current');
        document.getElementById('totalRequest')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('transport')?.classList.remove('current');
        document.getElementById('details-request')?.classList.remove('current');
        document.getElementById('attachment')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'products') {
                setNextButton('first');
        } else if (tab == 'attachment') {
                setNextButton('last');
        } else {
                setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['products', 'totalRequest', 'payment', 'transport', 'details-request', 'attachment'];
        let arr2 = ['flaticon-open-box', 'flaticon-coins', 'flaticon-list-1', 'flaticon-truck', 'flaticon-doc', 'flaticon-tool-1'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType="supplier"
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Produtos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Produtos da ordem de compra</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="totalRequest" data-kt-stepper-element='nav' onClick={() => changeTab('totalRequest', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Totais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Totais da ordem de compra</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Pagamento</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Pagamento da ordem de compra</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="transport" data-kt-stepper-element='nav' onClick={() => changeTab('transport', 'flaticon-truck', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-truck icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Transporte</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Transporte da ordem de compra</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="details-request" data-kt-stepper-element='nav' onClick={() => changeTab('details-request', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes da ordem de compra</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="attachment" data-kt-stepper-element='nav' onClick={() => changeTab('attachment', 'flaticon-tool-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-tool-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Anexos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Anexos da ordem de compra</div>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/ordem-de-compra">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={5} md={6} xs={12}>
                                <CustomerSelect
                                    label="Fornecedor*"
                                    hasError={supplierError}
                                    isEdit={false}
                                    customerId={supplierId}
                                    setCustomerId={setSupplierId}
                                    customerName={supplierName}
                                    setCustomerName={setSupplierName}
                                    allowIncomplete={true}
                                    typeCustomer="supplier"
                                    onClickAddCustomer={handleClickAddCustomer}
                                />
                            </Grid>
                            <Grid item lg={5} md={6} xs={12}>
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    disabled
                                    size="small"
                                    label="Nº do pedido"
                                    margin="normal"
                                    value={numberPO}
                                />
                            </Grid>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/ordem-de-compra" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' style={{ display: "none" }}>
                            <Tab eventKey='products' title='Produtos'>
                                {
                                    productsInvalid && (
                                        <p className="text-danger">Selecione pelo menos um produto para nota fiscal!</p>
                                    )
                                }
                                <div className="card-body p-0">
                                    <div className="row">
                                        {
                                            products.map(
                                                (prod, index) => (
                                                    <ListProducts
                                                        key={index}
                                                        product={prod}
                                                        index={index}
                                                        onClickAddProduct={handleClickAddProduct}
                                                        changeTabIcon={changeTabIcon}
                                                    />
                                                )
                                            )
                                        }

                                        <Grid item xs={12}>
                                            <button
                                                type="button"
                                                className="btn btn-link p-0"
                                                onClick={() => dispatchProducts({ type: "ADD" })}
                                            >
                                                <ins>+ adicionar outro produto</ins>
                                            </button>
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey='totalRequest' title='Totais do Pedido'>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor dos produtos"
                                                disabled
                                                value={products.reduce((acc, { total }) => acc + total, 0)}
                                                startAdornment="R$"
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                label="Valor do IPI"
                                                margin="normal"
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor do Frete"
                                                value={freightPrice}
                                                onChange={e => setFreightPrice(formatToFloat(e.target.value))}
                                                startAdornment="R$"
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Desconto (R$)"
                                                value={discountMoney}
                                                onChange={e => setDiscountMoney(formatToFloat(e.target.value))}
                                                startAdornment="R$"
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                margin="normal"
                                                customInput={TextField}
                                                label="Desconto"
                                                startAdornment="%"
                                                value={discountPercentage}
                                                onChange={(e) => setDiscountPercentage(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Peso total"
                                                value={weight}
                                                onChange={(e) => setWeight(e.target.value)}
                                                startAdornment="KG"
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <NumericFormat
                                                label="Valor total do pedido"
                                                disabled
                                                value={total}
                                                startAdornment="R$"
                                            />
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey='payment' title='Pagamento'>
                                {
                                    paymentsInvalid && (
                                        <p className="text-danger">Informe as parcelas da ordem de compra!</p>
                                    )
                                }
                                <div className="card-body p-0">
                                    <ConditionOrInstallment total={total} conditionName={conditionName} setConditionName={setConditionName} changeTabIcon={changeTabIcon} />
                                </div>
                            </Tab>

                            <Tab eventKey='transport' title='Transporte'>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <Grid item lg={4} md={6} xs={12}>
                                            <TextField
                                                select
                                                size="small"
                                                label="Modalidade de Frete"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                value={modalityFreight}
                                                onChange={(evt) => setModalityFreight(Number(evt.target.value))}
                                            >
                                                <MenuItem key="0" value="0">
                                                    Emitente
                                                </MenuItem>

                                                <MenuItem key="1" value="1">
                                                    Destinatário
                                                </MenuItem>

                                                <MenuItem key="2" value="2">
                                                    Terceiro
                                                </MenuItem>

                                                <MenuItem key="3" value="3">
                                                    Próprio por conta do Remetente
                                                </MenuItem>

                                                <MenuItem key="4" value="4">
                                                    Próprio por conta Destinatário
                                                </MenuItem>

                                                <MenuItem key="5" value="9">
                                                    Sem Frete
                                                </MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item lg={8} md={6} xs={12}>
                                            <ApiResourceSelect
                                                label="Transportadora"
                                                freeSolo
                                                getOptionLabel={(option: Carrier) => option.name}
                                                value={carrier}
                                                onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                                onInputChange={(typedText) => setCarrier(typedText || '')}
                                                apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                            />
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey='details-request' title='Detalhes do Pedido'>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type="date"
                                                label="Data da ordem"
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={orderDate}
                                            />
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                type="date"
                                                label="Prazo de Entrega"
                                                margin="normal"
                                                value={deadline}
                                                onChange={(e) => setDeadline(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                label="Referência"
                                                margin="normal"
                                                value={reference}
                                                onChange={(evt) => setReference(evt.target.value)}
                                            />
                                        </Grid>
                                    </div>

                                    <div className="row">
                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                label="Observações"
                                                multiline
                                                rows="10"
                                                placeholder="Esta informação será impressa nas observações da nota."
                                                margin="normal"
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} xs={12}>
                                            <TextField
                                                size="small"
                                                label="Observações Internas"
                                                multiline
                                                rows="10"
                                                placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                                margin="normal"
                                                value={insideNotes}
                                                onChange={(e) => setInsideNotes(e.target.value)}
                                            />
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey='attachment' title='Anexo'>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <Grid item lg={6} md={6} xs={12}>
                                            <label htmlFor="formFile" className="mt-3">Contrato/Anexos</label>
                                            <input className="form-control" type="file" id="formFile" />
                                            <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, PDF, DOC, XLS, CSV, ZIP.</label>
                                        </Grid>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}