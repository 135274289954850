import React, { useCallback, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {Workbook} from 'exceljs';
import FileSaver from 'file-saver';
import ModalError from '../../components/ModalError';
import ModalSuccess from '../../components/ModalSuccess';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import api from '../../services/Api';
import { formatToFloat } from '../../utils/formatCurrency';

type responseType = {
    status: string;
    message: string;
}

export function ImportExcelCustomer() {
    const [isImporting, setIsImporting] = useState(false);

    const [msgModal, setMsgModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    
    const readExcel = useCallback((file) => {
        if (file.length) {
            const workbook = new Workbook();
            const reader = new FileReader();

            reader.readAsArrayBuffer(file[0]);
            reader.onload = () => {
                const buffer: any = reader.result;

                try {
                    workbook.xlsx.load(buffer).then(workbook => {
                        workbook.eachSheet(async(sheet, id) => {
                            console.log(sheet);
                            console.log(id);
                            console.log("============");
                            var response: responseType = {
                                status: "success",
                                message: ""
                            };

                            sheet.eachRow((row, rowIndex) => {
                                if (rowIndex > 1) {
                                    var responseValidate = validateSheet(row.values, sheet);
        
                                    if (responseValidate.status == "error") {
                                        response = responseValidate;
                                    }
                                }
                            });

                            if (response.status == "success") {
                                setIsImporting(true);

                                const customersToCreateList: any[] = [];
                                sheet.eachRow(async (row: any, rowIndex) => {
                                    if (rowIndex > 1) {
                                        var typeRegister = "";
                                        if (row.values[1].toLowerCase() == "cliente") {
                                            typeRegister = "customer";
                                        } else if (row.values[1].toLowerCase() == "fornecedor") {
                                            typeRegister = "supplier";
                                        } else {
                                            typeRegister = "both";
                                        }
                                        
                                        customersToCreateList.push({
                                            typeRegister: typeRegister,
                                            typePeople: row.values[2].toLowerCase() == "física" ? "physical" : "legal",
                                            name: row.values[3],
                                            fantasyName: row.values[3],
                                            corporateName: row.values[4],
                                            cnpj: typeRegister == "supplier" ? row.values[5] : "",
                                            cpf: typeRegister == "customer" ? row.values[5] : "",
                                            cell: row.values[6],
                                            email: row.values[7].text ? row.values[7].text : row.values[7],

                                            isForeign: "n",
                                            isRuralProducer: "n",
                                            isRetiree: "n",
                                            activityClosed: "n",
                                            exempt: "n",
                                            registrationStatus: "active",
                                            finalCostumer: "y",
                                            icmsTaxpayer: "n"
                                        });
                                    }
                                });

                                for (const customerToCreate of customersToCreateList) {
                                    await api.post('/customer', customerToCreate);
                                }

                                setIsImporting(false);
                                setMsgModal("Excel importado com sucesso!");
                                setShowModal(true);

                            } else {
                                setMsgError(response.message);
                                setShowModalError(true);
                            }
                        })
                    });

                } catch (error) {
                    console.log(error);
                }                
            }

        }
    }, []);

    const getSheetModel = useCallback(async() => {
        const workbook = new Workbook;
        const sheet    = workbook.addWorksheet('Produtos');
        sheet.columns = [
            { header: 'Tipo de Cadastro', key: 'type', width: 15 },
            { header: 'Tipo de Pessoa', key: 'typePeople', width: 15 },
            { header: 'Nome/Nome Fantasia', key: 'name', width: 35 },
            { header: 'Razão Social', key: 'corporateName', width: 35 },
            { header: 'CPF/CNPJ', key: 'cpfCnpj', width: 25 },
            { header: 'Celular', key: 'cell', width: 25 },
            { header: 'E-mail', key: 'email', width: 35 },
        ];

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), 'Excel-Modelo.xlsx');
    }, []);

    const validateSheet = useCallback((row, rows) => {
        console.log(row);
        // VERIFICAÇÕES COLUNA DE TIPO
        if (!row[1]) {
            return {
                status: "error",
                message: "Coluna tipo de cadastro deve ser preenchida!",
            };
        }

        if (!["Cliente", "Fornecedor", "Ambos"].includes(capitalizeFirstLetter(row[1].toLowerCase()))) {
            return {
                status: "error",
                message: "Coluna tipo deve ser Cliente, Fornecedor ou Ambos!",
            };
        }
        //===================        

        // VERIFICAÇÕES COLUNA DE TIPO DE PESSOA
        if (!row[2]) {
            return {
                status: "error",
                message: "Coluna Tipo de Pessoa deve ser preenchida!",
            };
        }

        if (!["Jurídica", "Física"].includes(capitalizeFirstLetter(row[2].toLowerCase()))) {
            return {
                status: "error",
                message: "Coluna Tipo de Pessoa deve ser Jurídica ou Física!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE Nome/Nome Fantasia
        if (!row[3]) {
            return {
                status: "error",
                message: "Coluna Nome/Nome Fantasia deve ser preenchida!",
            };
        }

        if (row[3].length > 255) {
            return {
                status: "error",
                message: "Coluna Nome/Nome Fantasia deve ter no máximo 255 caracteres!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE RAZÃO SOCIAL
        // if (!row[4]) {
        //     return {
        //         status: "error",
        //         message: "Coluna Razão Social deve ser preenchida!",
        //     };
        // }

        if (row[4] && row[4].length > 255) {
            return {
                status: "error",
                message: "Coluna Razão Social deve ter no máximo 255 caracteres!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE CPF/CNPJ
        if (!row[5] || (typeof row[5] == "string" && row[5].replaceAll(/\D/g,'').length == 0)) {
            return {
                status: "error",
                message: "Coluna CPF/CNPJ deve ser preenchida!",
            };
        }

        if (row[2] == "Jurídica" && (typeof row[5] == "string" && row[5].replaceAll(/\D/g,'').length > 14)) {
            return {
                status: "error",
                message: "Coluna CPF/CNPJ deve ter no máximo 14 caracteres!",
            };
        }

        if (row[2] == "Física" && (typeof row[5] == "string" && row[5].replaceAll(/\D/g,'').length > 11)) {
            return {
                status: "error",
                message: "Coluna CPF/CNPJ deve ter no máximo 11 caracteres!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE Celular
        if (!row[6]) {
            return {
                status: "error",
                message: "Coluna Celular deve ser preenchida!",
            };
        }

        if (typeof row[6] == "string" && row[6].replaceAll(/\D/g,'').length > 11) {
            return {
                status: "error",
                message: "Coluna Celular deve ter no máximo 11 caracteres!",
            };
        }        
        //===================

        // VERIFICAÇÕES COLUNA DE E-mail
        if (!row[7]) {
            return {
                status: "error",
                message: "Coluna E-mail deve ser preenchida!",
            };
        }

        if (row[7].length > 255) {
            return {
                status: "error",
                message: "Coluna E-mail deve ter no máximo 255 caracteres!",
            };
        }      
        //===================

        return {
            status: "success",
            message: "",
        };
    }, []);

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="card card-body pt-4 newProductWrapper">
                <div className="row d-flex flex-column align-items-center mt-5 mb-5">
                    <div className="rounded col-lg-4 d-flex flex-column justify-content-center align-items-center p-5"
                    style={{ border: "2px dashed #ebedf2" }}>
                        <label htmlFor="formFile">
                            <div className="w-100 d-flex justify-content-center">
                                {
                                    isImporting
                                    ?   <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    : <i className="fas fa-arrow-down icon-4x"></i>
                                }
                            </div>
                            <h6 className="font-weight-bolder mt-3">Importe o arquivo de excel aqui</h6>
                            <input
                                type='file'
                                id='formFile'
                                accept='.xlsx'
                                onChange={(e) => readExcel(e.target.files)}
                                style={{ display: "none" }}
                            />
                        </label>
                        <Button
                            type="button"
                            variant="secondary"
                            className="mr-2"
                            onClick={() => getSheetModel()}
                        >
                            Baixar Modelo
                        </Button>
                    </div>

                    {/* <div className='d-flex justify-content-center col-lg-4 mt-5'>
                        <Button
                            type="button"
                            variant="primary"
                            className="mr-2"
                        >
                            Concluir Importação
                        </Button>
                    </div> */}
                </div>            
            </div>
        </>
    )
}