import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { formatStringDateToLocale } from '../../../utils/dateFormat';
import { extractDateStringFromTimestamp } from '../../../utils/dateTimeHelper';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportCustomerResponse = {
    rows: Customer[];
    count: number;
    quantityPurchased: number;
    totalAmountPurchased: number;
}

type Filters = {
    customerInput: string;
    typeInput: string;
    status: string;
    dateMin: string;
    dateMax: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "ID" },
    { reference: "customer", value: "Cliente" },
    { reference: "email", value: "E-mail" },
    { reference: "purchases", value: "Qtde. de Compras" },
    { reference: "total", value: "Valor Total" },
    { reference: "lastPurchase", value: "Última compra" },
];

export function ListReportCustomerMostBuyer() {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [countCustomersPaginated, setCountCustomersPaginated] = useState(0);
    const [countCustomers, setCountCustomers] = useState(0);
    const [countQuantityPurchased, setCountQuantityPurchased] = useState(0);
    const [countTotalAmountPurchased, setCountTotalAmountPurchased] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [customerInput, setCustomerInput] = useState('');
    const [typeInput, setTypeInput] = useState('');
    const [dateMin, setDateMin] = useState('');
    const [dateMax, setDateMax] = useState('');
    const [status, setStatus] = useState('')
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportCustomerResponse>("/report/customerMostBuyer", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, quantityPurchased, totalAmountPurchased} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell: any) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        });

        setExportTotals([
            { name: 'Total de clientes', value: count },
            { name: 'Quantidade de compras', value: quantityPurchased },
            { name: 'Valor total comprado', value: formatNumberToString(totalAmountPurchased) },
        ]);

        setCountQuantityPurchased(quantityPurchased);
        setCountTotalAmountPurchased(totalAmountPurchased);

        setExportBodyData(exportDataList);
        setCountCustomers(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportCustomerResponse>("report/customerMostBuyer", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCustomers(rows);
        setCountCustomersPaginated(count);
        setLastSortDirection(sortDirection);
        setLastSortReference(sortReference);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];

        customers.forEach((customer) => {
            const data = mountBodyDataRow(customer);

            list.push(data);
        });

        setBodyData(list);
    }, [customers]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Customer) {
        const properties = ['nfe', 'nfce', 'pdv', 'budget', 'requests'];

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id) ?? '-'},
            { for: "customer", value: CustomerService.getCustomerName(reportRow) ?? '-'},
            { for: "email", value: reportRow.email ?? '-' },
            { for: "purchases", value: String(getAmountOfPurchases(reportRow, properties)) ?? '-'},
            { for: "total", value: formatNumberToString(getPurchasesTotalValue(reportRow, properties)) ?? '-'},
            { for: "lastPurchase", value: getLastPurchaseDate(reportRow, properties) },
        ];

        return data;
    }

    function getLastPurchaseDate(customer: any, properties: string[]) {
        let lastDate = '';

        properties.forEach((property) => {
            const prop = customer.hasOwnProperty(property) ? property : '';

            if (prop) {
                customer[prop].forEach((e: any) => {
                    const subProp = e.budgetDate ? 'budgetDate' : e.date ? 'date' : e.requestDate ? 'requestDate' : 'createdDate';

                    lastDate = (e[subProp] > lastDate) ? e[subProp] : lastDate;
                });
            }
        });

        return formatStringDateToLocale(extractDateStringFromTimestamp(lastDate));
    }

    function getAmountOfPurchases(customer: any, properties: string[]) {
        let total = 0;

        properties.forEach((property: any) => {
            const prop = customer.hasOwnProperty(property) ? property : null;

            if (prop) {
                total += customer[prop].length;
            }
        });

        return total;
    }

    function getPurchasesTotalValue(customer: any, properties: string[]) {
        let total = 0;

        properties.forEach((property: any) => {
            const prop = customer.hasOwnProperty(property) ? property : null;

            if (prop) {
                customer[prop].forEach((e: any) => {
                    total += formatToFloat(e.totalValue);
                });
            }
        });

        return total;
    }

    const clearSearch = () => {
        setDateMin('');
        setDateMax('');
        setCustomerInput('');
        setTypeInput('');
        setStatus('');
    };

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customerInput,
            typeInput,
            status,
            dateMin,
            dateMax
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        customerInput,
        typeInput,
        status,
        dateMin,
        dateMax
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Clientes que mais compram', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerInput}
                        onSelect={(option) => setCustomerInput(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customerInput) return null;
                            return loadedOptions.find((option) => option.id === Number(customerInput)) ?? CustomerService.getCustomerById(customerInput)
                        }}
                    />
                </div>

                <div className="col-lg-6">
                    <TextField
                        select
                        label="Status"
                        margin="normal"
                        size="small"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem key="0" value="all">Todos</MenuItem>
                        <MenuItem key="1" value="open">Em aberto</MenuItem>
                        <MenuItem key="2" value="inProgress">Em andamento</MenuItem>
                        <MenuItem key="3" value="attended">Atendido</MenuItem>
                        <MenuItem key="4" value="canceled">Cancelado</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <TextField
                        type="Date"
                        label="Período - Min"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMin}
                        onChange={(e: any) => setDateMin(e.target.value)}
                    />

                    <TextField
                        type="Date"
                        label="Período - Máx"
                        margin="normal"
                        size="small"
                        className="ms-20"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMax}
                        onChange={(e: any) => setDateMax(e.target.value)}
                    />

                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo"
                        margin="normal"
                        value={typeInput}
                    >
                        <MenuItem key="0" value="all" onClick={() => setTypeInput('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="nfe" onClick={() => setTypeInput('nfe')}>
                            Nota Fiscal
                        </MenuItem>

                        <MenuItem key="2" value="requests" onClick={() => setTypeInput('requests')}>
                            Pedidos
                        </MenuItem>

                        <MenuItem key="3" value="budget" onClick={() => setTypeInput('budget')}>
                            Orçamento
                        </MenuItem>

                        <MenuItem key="4" value="pdv" onClick={() => setTypeInput('pdv')}>
                            PDV
                        </MenuItem>

                        <MenuItem key="5" value="nfce" onClick={() => setTypeInput('nfce')}>
                            Nota Fiscal do Consumidor
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de clientes que mais compram"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Clientes que mais compram', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={countCustomersPaginated}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                    />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de clientes:</TableCell>
                                    <TableCell colSpan={1}>{countCustomers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de compras:</TableCell>
                                    <TableCell colSpan={1}>{countQuantityPurchased}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total comprado:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(countTotalAmountPurchased)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}