import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';

import api from "../../services/Api";
import { Customer } from '../../types/Customer';
import { formatCpfCnpj } from '../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { Search } from '../../components/Search';
import { Card, Collapse, MenuItem, Paper, TextField } from '@material-ui/core';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import CustomerService from '../../services/CustomerService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import CustomizeColumns from '../../components/CustomizeColumns';
import { Bar, BarChart, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import axios from 'axios';

type Filters = {
    searchQuery: string;
    situation: string;
    typeRegister: string;
}

type TypeRegisterCustomerChart = {
    type: string,
    quantity: number,
}

type TypePeopleCustomerChart = {
    typePeople: string,
    quantity: number,
}

type SituationCustomerChart = {
    situation: string,
    quantity: number
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
    { reference: "cpf", value: "CPF/CNPJ" },
    { reference: "phone", value: "Celular" },
    { reference: "situation", value: "Situação", situation: true, notSortable: true },
    { reference: "typeCustomer", value: "Tipo", notSortable: true },
];

export function ListCustomer() {
    const { user } = useSelector((state: any) => state.auth);

    const columns = [
        { key: "id", label: "Nº" },
        { key: "name", label: "Nome" },
        { key: "cpf", label: "CPF/CNPJ" },
        { key: "phone", label: "Celular" },
        { key: "situation", label: "Situação" },
        { key: "typeCustomer", label: "Tipo" },
    ]

    const [customers, setCustomers] = useState<Customer[]>([]);
    const [customersFiltered, setCustomersFiltered] = useState<Customer[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCustomers, setCountTotalCustomers] = useState(0);

    const [customersTypeRegister, setCustomerTypeRegister] = useState<TypeRegisterCustomerChart[]>();
    const [customersTypePeople, setCustomerTypePeople] = useState<TypePeopleCustomerChart[]>();
    const [customersSituation, setCustomersSituation] = useState<SituationCustomerChart[]>();

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [situation, setSituation] = useState("");
    const [typeRegister, setTypeRegister] = useState("both");

    const { push: pushHistory, location: { pathname } } = useHistory();

    const fetchTypeRegisterCustomers = async () => {
        try {
            const results = await axios.get("/customer/customersTypeRegister");
            setCustomerTypeRegister(results.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    const fetchSituationCustomers = async () => {
        try {
            const results = await axios.get("/customer/customersSituation");
            setCustomersSituation(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchTypePeopleCustomers = async () => {
        try {
            const results = await axios.get("/customer/customersTypePeople");
            setCustomerTypePeople(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    // useEffect(() => {
    //     handleClickSearch();
    // }, [customers]);

    useEffect(() => {
        fetchTypeRegisterCustomers();
        fetchSituationCustomers();
        fetchTypePeopleCustomers();
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Customer[], count: number}>("customer", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCustomers(rows);
        setCustomersFiltered(rows);
        setCountTotalCustomers(count);
    }, []);
    
    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = customersFiltered;

        aux.forEach((customer) => {
            let nameValue = customer.name;

            const cpfValue = customer.cpf ? formatCpfCnpj("CPF", customer.cpf) : formatCpfCnpj("CNPJ", customer.cnpj);
            const typeValue = CustomerService.getTypeRegisterText(customer.typeRegister);
            const situationValue = customer.registrationStatus === "active" ? "Ativo" : "Inativo";

            const formatedCell = formatPhoneNumber(customer.cell);

            if (customer.cnpj) {
                nameValue = customer.fantasyName;
            }

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(customer.id), id: true },
                { for: "name", value: nameValue },
                { for: "cpf", value: cpfValue },
                { for: "phone", value: formatedCell },
                { for: "situation", value: situationValue },
                { for: "typeCustomer", value: typeValue },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [customersFiltered]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = customers.filter((customer) => customer.id !== Number(id));

            await api.delete(`customer/${id}`);

            setCustomers([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [customers]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setTypeRegister('both');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, typeRegister,
        };

        reloadData();
    }, [customers, searchQuery, situation, typeRegister]);

    const formatDataKey = () => {
        return "Quantidade";
    }

    const formatXAxisSituation = (tickItem: string) => {
        switch(tickItem) {
            case 'active':
                return 'Ativo';
            case null:
                return 'Nenhum';
            default:
                return "Nenhum";
        }
    }

    const formatXAxisPeople = (tickItem: string) => {
        switch (tickItem) {
            case 'physical':
                return 'Física';
            case 'legal':
                return 'Jurídica';
            default:
                return 'Nenhum';
        }
    }

    const formatLabel = (label: string, type: string) => {
        if (type === "situation") {
            switch(label) {
                case "active":
                    return "Ativo";
                case null:
                    return "Nenhum";
                default:
                    return "Nenhum";
            }
        }

        if (type === "typeRegister") {
            switch(label) {
                case "both":
                    return "Ambos";
                case "customer":
                    return "Cliente";
                case "supplier":
                    return "Fornecedor";
                default:
                    return "Nenhum";
            }
        }

        if (type === "typePeople") {
            switch(label) {
                case "physical":
                    return "Física";
                case "legal":
                    return "Jurídica";
                default:
                    return "Nenhum";
            }
        }
    }

    const formatLabelTypeRecord = (type: string) => {
        switch(type) {
            case "both":
                return "Ambos";
            case "customer":
                return "Cliente";
            case "supplier":
                return "Fornecedor";
            default:
                return "Nenhum";
        }
    }

    const CustomTooltip = ({ active, payload, label, type }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabel(label, type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const CustomTooltipTypeRegister = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabelTypeRecord(payload[0].payload.type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const createCustomTooltip = (type: any) => (props: any) => (
        <CustomTooltip {...props} type={type} />
    )

    return (
        <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de clientes/fornecedores</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ? 
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2 text-dark"
                            onClick={() => handleClickAdd()}
                        >
                            <i className="fas fa-plus text-dark"></i>
                            Adicionar
                        </Button>
                        : <></>
                    }
                    <Button
                        type="button"
                        variant="secondary"
                        className="mr-2"
                        onClick={() => pushHistory(`${pathname}/importar-excel`)}
                    >
                        <i className="fas fa-file-import"></i>
                        Importar
                    </Button>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>

                {/* <div className="col-lg-12 d-flex justify-content-end my-3">
                    <CustomizeColumns columns={columns} />
                </div> */}
            </div>
            <Collapse in={advancedSearch}>
                <div className="shadow-sm p-6 rounded-sm border border-2">
                    <h4>Pesquisa avançada</h4>

                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                variant="standard"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Nenhum
                                </MenuItem>

                                <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                                    Ativo
                                </MenuItem>

                                <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                                    Inativo
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Tipo de Cadastro"
                                margin="normal"
                                variant="standard"
                                value={typeRegister}
                            >
                                <MenuItem key="0" value="both" onClick={() => setTypeRegister('both')}>
                                    Ambos
                                </MenuItem>

                                <MenuItem key="1" value="customer" onClick={() => setTypeRegister('customer')}>
                                    Clientes
                                </MenuItem>

                                <MenuItem key="2" value="supplier" onClick={() => setTypeRegister('supplier')}>
                                    Fornecedores
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-12 d-flex mt-6">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                <i className="fa fa-search"></i>
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                                Limpar
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className="row">
                <Paper elevation={1} className="mt-3 col-lg-4 col-12 border border-2 py-3" style={{ height: "fit-content" }}>
                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Situação</span>

                        <BarChart className="w-100 mt-6" width={300} height={200} data={customersSituation}>
                            <XAxis dataKey="status" tickFormatter={formatXAxisSituation} />
                            <YAxis />
                            <Tooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("situation")} />
                            <Legend formatter={formatDataKey} />
                            <Bar dataKey="quantity" fill="#181c32" />
                        </BarChart>
                    </Card>

                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Tipo de cadastro</span>

                        <PieChart className="w-100" width={300} height={300}>
                            <Pie dataKey="quantity"
                                isAnimationActive={false}
                                data={customersTypeRegister}
                                cx={200}
                                cy={200}
                                outerRadius={80}
                                fill="#F64E60"
                                label
                            />
                            <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltipTypeRegister/>} />
                        </PieChart>
                    </Card>

                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Tipo de Pessoa</span>

                        <LineChart className="w-100 mt-6" width={300} height={200} data={customersTypePeople}>
                            <XAxis dataKey="typePeople" tickFormatter={formatXAxisPeople} />
                            <YAxis />
                            <Tooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("typePeople")} />
                            <Legend formatter={formatDataKey} />
                            <Line type="monotone" strokeWidth={3} dataKey="quantity" stroke="#52EF66" />
                        </LineChart>
                    </Card>
                </Paper>

                <div className="mt-3 col-lg-8 col-12">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalCustomers}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
		</MainPageContentWrapper>
    );
}