import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Badge
} from "react-bootstrap";

import api from '../../../services/Api';
import { useHistory, useParams } from 'react-router';
import { getDate } from '../../../utils/dateTimeHelper';
import ModalConfirm from '../../../components/ModalConfirm';
import { useSelector } from 'react-redux';
import { Indications, status, typeIndication } from '../../../types/Indications';
import { colorSuccess } from '../../../utils/styles';
import { BsVariant } from '../../../types/BsVariant';
import ModalSuccess from '../../../components/ModalSuccess';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';



const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type IndicationStatus = {
    status: status;
    label: string;
    variant: BsVariant;
    style?: React.CSSProperties;
}

type IndicationHistory = {
    date: string;
    description: string;
}

export function DetailsIndications() {
    const { id } = useParams<{ id: string }>();

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");

    const [showModalConfirmCancel, setShowModalConfirmCancel] = useState(false);

    const [isCanceling, setIsCanceling] = useState(false);

    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyCell, setCompanyCell] = useState("");
    const [indicationStatus, setIndicationStatus] = useState<IndicationStatus>({ status: status.PENDING, label: 'Pendente', variant: BsVariant.WARNING });
    const [indicationType, setIndicationType] = useState("");
    const [indicationHistory, setIndicationHistory] = useState<IndicationHistory[]>([]);

    function handleClickCancelButton() {
        setShowModalConfirmCancel(true);
    }

    async function confirmCancelIndication() {
        try {
            setShowModalConfirmCancel(false);
            setIsCanceling(true);

            await api.patch<Indications>(`/indications/status`, { id: Number(id), status: status.CANCELED });

            setShowModalSuccess(true);
            setMessageSuccess('Indicação cancelada com sucesso!');
        } catch (error) {
            console.log('error', error);
        }

        setIsCanceling(false);
    }

    useEffect(() => {
        async function loadIndication() {
            const indicationResponse = await api.get<Indications>(`/indications/${id}`);
            const indication = indicationResponse.data;

            let indicationVariant = BsVariant.WARNING;
            let indicationStyle: React.CSSProperties | undefined = undefined;
            let indicationLabel = 'Pendente';

            if (indication.status === status.ACCEPT) {
                indicationVariant = BsVariant.SUCCESS;
                indicationLabel = 'Aceita';
                indicationStyle = colorSuccess;
            } else if (indication.status === status.CANCELED) {
                indicationVariant = BsVariant.DANGER;
                indicationLabel = 'Cancelada';
            }

            // Montando histórico
            let history: IndicationHistory[] = [];
            if (indication.typeIndication !== typeIndication.LINK) {
                history.push({
                    date: getDate({ initialDate: new Date(indication.createdDate) }).fullDateStr,
                    description: 'Indicação criada',
                });
            }

            if (indication.acceptDate) {
                history.push({
                    date: getDate({ initialDate: new Date(indication.acceptDate) }).fullDateStr,
                    description: indication.typeIndication !== typeIndication.LINK ? 'Indicação aceita pela empresa' : 'Indicação aceita pela empresa através do link',
                });
            }

            if (indication.canceledDate) {
                history.push({
                    date: getDate({ initialDate: new Date(indication.canceledDate) }).fullDateStr,
                    description: 'Indicação cancelada',
                });
            }

            setCompanyName(indication.companyEntity.name);
            setCompanyEmail(indication.companyEntity.email);
            setCompanyCell(indication.companyEntity.cell);
            setIndicationStatus({
                status: indication.status,
                variant: indicationVariant,
                label: indicationLabel,
                style: indicationStyle,
            });
            setIndicationType(indication.typeIndication === typeIndication.LINK ? 'Via Link' : 'Manual');
            setIndicationHistory(history);
        }

        loadIndication();
    }, [id]);

    return (
        <MainPageContentWrapper>
            <ModalSuccess
                msgModal={messageSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
            />
            <ModalConfirm
                showModal={showModalConfirmCancel}
                setShowModal={setShowModalConfirmCancel}
                confirmText="Tem certeza que deseja cancelar esta indicação?"
                confirmButtonLabel="Confirmar"
                cancelButtonLabel="Fechar"
                onConfirm={confirmCancelIndication}
            />

            <div className="makeStyles-container-12">
                <div className="row">
                    <div className="col-12">
                        <h3>Detalhes da Indicação</h3>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <strong>Empresa: </strong> {companyName}
                    </div>
                    <div className="col-lg-4">
                        <strong>E-mail: </strong> {companyEmail}
                    </div>
                    <div className="col-lg-4">
                        <strong>Celular: </strong> {companyCell}
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <strong>Tipo de Indicação: </strong> {' '}
                        {indicationType}
                    </div>
                    <div className="col-lg-4">
                        <strong>Status: </strong> {' '}
                        <Badge
                            variant={indicationStatus.variant}
                            style={indicationStatus.style}
                        >
                            {indicationStatus.label}
                        </Badge>
                    </div>
                </div>

                {indicationStatus.status === status.PENDING && (
                    <div className="row mt-10">
                        <div className="col-12">
                            <Button
                                className="ml-3 mt-2"
                                variant="danger"
                                onClick={handleClickCancelButton}>
                                <i className='p-0 flaticon2-delete mr-2'></i>
                                {isCanceling ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className='ml-2'>
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span> Cancelar Indicação</span>
                                </>}
                            </Button>
                        </div>
                    </div>
                )}

                <div className="row mt-10">
                    <div className="col-12">
                        <h4>Histórico</h4>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Histórico</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    indicationHistory.length
                                        ?
                                        indicationHistory.map((history, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{history.date}</TableCell>
                                                    <TableCell>{history.description}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={3}>Nenhuma informação disponível no momento...</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}