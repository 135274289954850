import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import UserService from '../../services/UserService';
import { ValidationBadge } from '../../components/ValidationBadge';
import * as auth from "../../modules/Auth";
import { PatternFormat } from '../../components/PatternFormat';
import { AuthPageContentWrapper } from '../../components/AuthPageContentWrapper';

export default function VerifyCellPage() {
    const history = useHistory();
    const { user } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();

    const [verificationCodeSent, setVerificationCodeSent] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);

    const [cell, setCell] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    // Errors
    const [cellErrorMsg, setCellErrorMsg] = useState("");
    const [verificationCodeErrorMsg, setVerificationCodeErrorMsg] = useState("");
    const [sendVerificationCodeErrorMsg, setSendVerificationCodeErrorMsg] = useState("");
    const [verifyCodeErrorMsg, setVerifyCodeErrorMsg] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!user) return;

        if (user.cellVerifiedAt) {
            history.push('/');
        }

        setCell(user.cell);

    }, [history, user]);

    async function validateCell() {
        if (!cell) {
            setCellErrorMsg('Preencha o seu número de celular!');
            return false;
        }

        const cellExists = await UserService.verifyCellExists(cell, user.id);
        if (cellExists) {
            setCellErrorMsg('O número de celular informado já está sendo usado por outro usuário do sistema, por favor informe um número diferente.');
            return false;
        }

        return true;
    }

    async function sendVerificationCode() {
        setIsLoading(true);

        setCellErrorMsg('');
        setSendVerificationCodeErrorMsg('');

        const cellIsValid = await validateCell();

        if (!cellIsValid) {
            setIsLoading(false);
            return;
        };

        try {
            await api.post('/whatsappVerification', {
                userId: user.id, cell
            });

            setVerificationCodeSent(true);
        } catch (e) {
            console.log(e);
            setSendVerificationCodeErrorMsg('Ocorreu um erro ao enviar o código, verifique o número de celular ou tente novamente mais tarde.');
        }

        setIsLoading(false);
    }

    async function handleClickResendCode() {
        setCellErrorMsg('');
        setVerificationCodeErrorMsg('');
        setSendVerificationCodeErrorMsg('');
        setVerificationCodeSent(false);
        setVerificationCode('');
    }

    async function validateVerificationCode() {
        if (!verificationCode) {
            setVerificationCodeErrorMsg('Informe o código de verificação!');
            return false;
        }

        if (verificationCode.length !== 6) {
            setVerificationCodeErrorMsg('O código de verificação deve ter 6 dígitos!');
            return false;
        }

        return true;
    }

    async function verifyCode() {
        setIsLoading(true);

        setVerificationCodeErrorMsg('');
        setVerifyCodeErrorMsg('');

        const isValid = validateVerificationCode();

        if (!isValid) {
            setIsLoading(false);
            return;
        };

        try {
            const { data: activeCode } = await api.get(`/whatsappVerification/code/${user.id}`);

            if (activeCode.code !== verificationCode) {
                setVerificationCodeErrorMsg('Código informado está expirado ou é inválido!');
                setIsLoading(false);
                return;
            }

            const rawUser = {
                cell,
                cellVerifiedAt: new Date(),
            };

            await api.put(`/users/${user.id}`, rawUser);

            dispatch(auth.actions.setUser({
                ...user,
                ...rawUser,
            }));

            setVerificationSuccess(true);

        } catch (e) {
            console.log(e);
            setVerifyCodeErrorMsg('Ocorreu um erro ao validar o código, tente novamente mais tarde.');
        }

        setIsLoading(false);
    }

    return (
        <AuthPageContentWrapper>
            <div className="d-flex justify-content-end">
                <Link to="/logout">Logout</Link>
            </div>
            <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
                <div className="login-form login-signin" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">Verificar Número do Celular</h3>
                    </div>

                    <div
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {!verificationSuccess && !verificationCodeSent && (
                            <>
                                <p>
                                    Para acessar o sistema, é necessário confirmar o seu número de celular.
                                    Você receberá via Whatsapp o código de verificação.
                                    Certifique-se que o seu número de celular tenha acesso ao Whatsapp.
                                </p>

                                <div className="form-group">
                                    <PatternFormat
                                        label="Celular*"
                                        format="(##) #####-####"
                                        mask="_"
                                        value={cell}
                                        onChange={(e) => setCell(e.target.value)}
                                        error={!!cellErrorMsg}
                                    />

                                    {!!cellErrorMsg && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block text-danger">{cellErrorMsg}</div>
                                        </div>
                                    )}
                                </div>

                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 w-100"
                                        onClick={sendVerificationCode}
                                    >
                                        <span>Enviar Código</span>
                                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                    </button>

                                    {!!sendVerificationCodeErrorMsg && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block text-danger">{sendVerificationCodeErrorMsg}</div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {!verificationSuccess && verificationCodeSent && (
                            <>
                                <p>
                                    Digite o código enviado para o número Whatsapp informado.
                                    <br /> <b>Atenção: </b> O código enviado irá expirar em 5 minutos!
                                </p>

                                <div className="form-group">
                                    <TextField
                                        style={{ width: '100%' }}
                                        label="Insira o Código"
                                        inputProps={{
                                            maxLength: 6,
                                            style: { letterSpacing: '2rem', textAlign: 'center' },
                                        }}
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                    />

                                    {!!verificationCodeErrorMsg && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block text-danger">{verificationCodeErrorMsg}</div>
                                        </div>
                                    )}
                                </div>

                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4 w-100"
                                        onClick={handleClickResendCode}
                                    >
                                        <span>Reenviar Código</span>
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 w-100"
                                        onClick={verifyCode}
                                    >
                                        <span>Confirmar</span>
                                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                    </button>

                                    {!!verifyCodeErrorMsg && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block text-danger">{verifyCodeErrorMsg}</div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {verificationSuccess && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <h4>
                                    <ValidationBadge label="" variant="success" />
                                    Verificação concluída com êxito, clique no botão abaixo para acessar o sistema!
                                </h4>

                                <Link to="/" className="btn btn-primary">
                                    Acessar a Uninteract
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AuthPageContentWrapper>
    );
}