import React from "react";
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";

export type SendDocumentProps = {
    sendBeforeDueDate: string;
    setSendBeforeDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendInDueDate: string;
    setSendInDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendAfterDueDate: string;
    setSendAfterDueDate: React.Dispatch<React.SetStateAction<string>>;
    beforeDay: string;
    setBeforeDay: React.Dispatch<React.SetStateAction<string>>;
    afterDay: string;
    setAfterDay: React.Dispatch<React.SetStateAction<string>>;

    sendEmailBeforeDueDate: string;
    setSendEmailBeforeDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendWhatsappBeforeDueDate: string;
    setSendWhatsappBeforeDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendEmailInDueDate: string;
    setSendEmailInDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendWhatsappInDueDate: string;
    setSendWhatsappInDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendEmailAfterDueDate: string;
    setSendEmailAfterDueDate: React.Dispatch<React.SetStateAction<string>>;
    sendWhatsappAfterDueDate: string;
    setSendWhatsappAfterDueDate: React.Dispatch<React.SetStateAction<string>>;
    
    beforeDaysInvalid: boolean;
    setBeforeDaysInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    afterDaysInvalid: boolean;
    setAfterDaysInvalid: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SendDocument({
    sendBeforeDueDate,
    setSendBeforeDueDate,
    sendInDueDate,
    setSendInDueDate,
    sendAfterDueDate,
    setSendAfterDueDate,
    beforeDay,
    setBeforeDay,
    afterDay,
    setAfterDay,

    sendEmailBeforeDueDate,
    setSendEmailBeforeDueDate,
    sendWhatsappBeforeDueDate,
    setSendWhatsappBeforeDueDate,
    sendEmailInDueDate,
    setSendEmailInDueDate,
    sendWhatsappInDueDate,
    setSendWhatsappInDueDate,
    sendEmailAfterDueDate,
    setSendEmailAfterDueDate,
    sendWhatsappAfterDueDate,
    setSendWhatsappAfterDueDate,
    
    beforeDaysInvalid,
    setBeforeDaysInvalid,
    afterDaysInvalid,
    setAfterDaysInvalid,
}: SendDocumentProps) {
    return (
        <div className="row">

            <div className="row col-lg-12 d-flex align-items-center border-bottom mb-3 pb-3">
                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={(
                            <Checkbox
                                color="primary"
                                value={sendBeforeDueDate}
                                onChange={e => {
                                    setSendBeforeDueDate(e.target.checked ? "y" : "n")
                                    setBeforeDay(e.target.checked ? beforeDay : "")
                                }}
                                checked={sendBeforeDueDate == "y" ? true : false}
                            />
                        )}
                        label="Antes do vencimento"
                        labelPlacement="end"
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        size="small"
                        type="number"
                        margin="normal"
                        placeholder="Ex: 7 dias"
                        label="Dias para envio"
                        value={beforeDay}
                        onChange={(e) => {
                            setBeforeDay(e.target.value);
                            setBeforeDaysInvalid(e.target.value ? false : true);
                        }}
                        error={beforeDaysInvalid}
                    />
                </div>
                <div className="col-lg-4 d-flex flex-column">
                    <label className="m-0">Enviar por</label>
                    
                    <div>
                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendEmailBeforeDueDate}
                                    onChange={e => {
                                        setSendEmailBeforeDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendEmailBeforeDueDate == "y" ? true : false}
                                />
                            )}
                            label="Email"
                            labelPlacement="end"
                        />

                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendWhatsappBeforeDueDate}
                                    onChange={e => {
                                        setSendWhatsappBeforeDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendWhatsappBeforeDueDate == "y" ? true : false}
                                />
                            )}
                            label="Whatsapp"
                            labelPlacement="end"
                        />
                    </div>
                </div>
            </div>

            <div className="row col-lg-12 d-flex align-items-center border-bottom mb-3 pb-3">
                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={(
                            <Checkbox
                                color="primary"
                                value={sendInDueDate}
                                onChange={e => {
                                    setSendInDueDate(e.target.checked ? "y" : "n")
                                }}
                                checked={sendInDueDate == "y" ? true : false}
                            />
                        )}
                        label="No dia do vencimento"
                        labelPlacement="end"
                    />
                </div>
                <div className="col-lg-4 d-flex flex-column">
                    <label className="m-0">Enviar por</label>
                    
                    <div>
                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendEmailInDueDate}
                                    onChange={e => {
                                        setSendEmailInDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendEmailInDueDate == "y" ? true : false}
                                />
                            )}
                            label="Email"
                            labelPlacement="end"
                        />

                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendWhatsappInDueDate}
                                    onChange={e => {
                                        setSendWhatsappInDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendWhatsappInDueDate == "y" ? true : false}
                                />
                            )}
                            label="Whatsapp"
                            labelPlacement="end"
                        />
                    </div>
                </div>
            </div>

            <div className="row col-lg-12 d-flex align-items-center border-bottom mb-3 pb-3">
                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={(
                            <Checkbox
                                color="primary"
                                value={sendAfterDueDate}
                                onChange={e => {
                                    setSendAfterDueDate(e.target.checked ? "y" : "n")
                                    setAfterDay(e.target.checked ? afterDay : "")
                                }}
                                checked={sendAfterDueDate == "y" ? true : false}
                            />
                        )}
                        label="Após o vencimento"
                        labelPlacement="end"
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        size="small"
                        type="number"
                        margin="normal"
                        placeholder="Ex: 7 dias"
                        label="Enviar a cada dias"
                        value={afterDay}
                        onChange={(e) => {
                            setAfterDay(e.target.value);
                            setAfterDaysInvalid(e.target.value ? false : true);
                        }}
                        error={afterDaysInvalid}
                    />
                </div>
                <div className="col-lg-4 d-flex flex-column">
                    <label className="m-0">Enviar por</label>
                    
                    <div>
                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendEmailAfterDueDate}
                                    onChange={e => {
                                        setSendEmailAfterDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendEmailAfterDueDate == "y" ? true : false}
                                />
                            )}
                            label="Email"
                            labelPlacement="end"
                        />

                        <FormControlLabel
                            value="end"
                            control={(
                                <Checkbox
                                    color="primary"
                                    value={sendWhatsappAfterDueDate}
                                    onChange={e => {
                                        setSendWhatsappAfterDueDate(e.target.checked ? "y" : "n")
                                    }}
                                    checked={sendWhatsappAfterDueDate == "y" ? true : false}
                                />
                            )}
                            label="Whatsapp"
                            labelPlacement="end"
                        />
                    </div>
                </div>
            </div>            
        </div>
    );
}