import React, { useCallback } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import ReactNumberFormat, { NumberFormatProps as ReactNumberFormatProps } from 'react-number-format';

interface PatternFormatProps extends ReactNumberFormatProps {
    label: string;
    startAdornment?: string | JSX.Element;
    endAdornment?: string | JSX.Element;
}

export const PatternFormat: React.FC<PatternFormatProps> = ({ label, startAdornment, endAdornment, ...props }) => {
    return (
        <ReactNumberFormat
            {...props}
            className={`${props.className ?? ''}`}
            customInput={TextField}
            margin="normal"
            label={label}
            autoComplete="false"

            InputProps={{
                startAdornment: startAdornment ? (
                    <InputAdornment position='start'>
                        {startAdornment}
                    </InputAdornment>
                ) : undefined,
                endAdornment: endAdornment ? (
                    <InputAdornment position='end'>
                        {endAdornment}
                    </InputAdornment>
                ) : undefined,
            }}
        />
    );
}