import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export type TableCellProps = {
    children?: React.ReactNode,
    width: string,
};

export default function TableCell({ children, width }: TableCellProps) {
    const styles = StyleSheet.create({
        cell: {
            width: width,
            paddingTop: 8
        },
    });

    return (
        <Text style={styles.cell}>
            {children}
        </Text>
    );
};