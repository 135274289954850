import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/Api';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import {Button} from 'react-bootstrap';
import { Search } from '../../components/Search';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
}

type CenterCost = {
    id: number;
    name: string;
    status: "active" | "inactive";
};

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'name', value: "Nome" },
    { reference: 'status', value: "Status", notSortable: true },
];

export function ListCenterCost() {
    const { user } = useSelector((state: any) => state.auth);
    
	const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
    
    const [centerCosts, setCenterCosts] = useState<CenterCost[]>([]);
    const [countTotalCenterCosts, setCountTotalCenterCosts] = useState(0);

    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: CenterCost[], count: number}>('centerCost', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCenterCosts(rows);
        setCountTotalCenterCosts(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [centerCosts]);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = centerCosts;

        aux.forEach(({ id, name, status: statusResponse }) => {
            const status = statusResponse === "active" ? "Ativo" : "Inativo";

            const data: BodyDataBaseProps[] = [
                { for: 'id', value: String(id), id: true },
                { for: 'name', value: name },
                { for: 'status', value: status },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [centerCosts]);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = { searchQuery };

        reloadData();
    }, [centerCosts, searchQuery]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            await api.delete(`centerCost/${id}`);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    return (
        <>
            <MainPageContentWrapper>
                    <h4 className="font-weight-bolder">Lista de centros de custos</h4>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-9 mt-3">
                            {
                                user.isAccountant == "n"
                                ?
                                <Button
                                    type="button"
                                    variant="success"
                                    className="mr-2 text-dark"
                                    onClick={() => handleClickAdd()}
                                >
                                    <i className="fas fa-plus text-dark"></i>
                                    Adicionar
                                </Button>
                                : <></>
                            }
                        </div>
                        <div className="col-lg-3 mt-3">
                            <Search
                                query={searchQuery}
                                setQuery={setSearchQuery}
                                onClickSearch={handleClickSearch}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <ListWithModalChangeSituation
                            headData={headData}
                            bodyData={bodyData}
                            onEdit={handleClickEdit}
                            onDelete={handleClickDelete}
                            sortable={true}
                            loadData={loadData}
                            totalCount={countTotalCenterCosts}
                            triggerLoad={triggerLoad}
                            setTriggerLoad={setTriggerLoad}
                        />
                    </div>
            </MainPageContentWrapper>
        </>
    );
}