import React, { useState, useEffect, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format } from 'date-fns';
import ReactNumberFormat from "react-number-format";
import { Link, useParams } from 'react-router-dom';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { Autocomplete } from '@material-ui/lab';
import { formatToFloat, formatCurrency, formatNumberToString } from '../../utils/formatCurrency';
import api from '../../services/Api';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { NumericFormat } from '../../components/NumericFormat';
import { Grid, InputAdornment } from '@material-ui/core';
import { paymentOptions } from '../../utils/paymentOptions';
import { cnpj } from 'cpf-cnpj-validator';

import { Product as SelectableProduct } from '../../services/ProductService';
import LogService from '../../services/LogService';
import NfeService from '../../services/NfeService';
import CustomerService from '../../services/CustomerService';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import SellerService from '../../services/SellerService';
import { freightModalityById } from '../../utils/freightModality';
import { getCarrierById } from '../../utils/getCarrierById';
import { Installment } from '../../types/Installment';
import { useSelector } from 'react-redux';
import CustomerSelect from '../../components/CustomerSelect';
import ProductSelect from '../../components/ProductSelect';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import PriceListService from '../../services/PriceListService';
import { PriceList } from '../../types/PriceList';
import CarrierService from '../../services/CarrierService';
import { Carrier } from '../../types/Carrier';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product } from '../../types/Product';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { useStyles } from '../../hooks/styles';

type urlParam = {
    id: string
}

export function EditNfce() {
    const classes = useStyles();

    const { user } = useSelector((state: any) => state.auth);


    const [isSubmit, setIsSubmit] = useState(false);
    const [nfceConfig, setNfceConfig] = useState<any>();

    // HOOKS
    const history = useHistory();
    const { handleSubmit } = useForm();
    const { id } = useParams<urlParam>();

    const [activeTab, setActiveTab] = useState("details");

    // STATES
    const [nature, setNature] = useState("");
    const [typeAttendance, setTypeAttendance] = useState(0);
    const [seriesNfce, setSeriesNfce] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [customer, setCustomer] = useState<number>();
    const [seller, setSeller] = useState("");
    const [customerDontIdentified, setCustomerDontIdentified] = useState("");
    const [indicator, setIndicator] = useState("");
    const [intermediaryCnpj, setIntermediaryCnpj] = useState("");
    const [identifierRegisterIntermediary, setIdentifierRegisterIntermediary] = useState("");
    const [observation, setObservation] = useState("");
    const [internalObservation, setInternalObservation] = useState("");
    const [priceList, setPriceList] = useState("");
    const [bcIcms, setBcIcms] = useState("");
    const [valueIcms, setValueIcms] = useState("");
    const [valueProducts, setValueProducts] = useState("");
    const [valueFreight, setValueFreight] = useState("");
    const [valueExpenses, setValueExpenses] = useState("");
    const [discountMoney, setDiscountMoney] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");
    const [totalValue, setTotalValue] = useState("");
    const [amountInstallments, setAmountInstallments] = useState("");
    const [grossWeight, setGrossWeight] = useState("");
    const [liquidWeight, setLiquidWeight] = useState("");
    const [volumes, setVolumes] = useState("");
    const [species, setSpecies] = useState("");
    const [brand, setBrand] = useState("");
    const [numbering, setNumbering] = useState("");
    const [modalityFreight, setModalityFreight] = useState(0);
    const [destinationOperation, setDestinationOperation] = useState(1);
    const [carrier, setCarrier] = useState("");
    const [plateVehicle, setPlateVehicle] = useState("");
    const [dateInvoice, setDateInvoice] = useState("");

    const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const [products, setProducts] = useState({
        products: [
            {
                icms: "0",
                cfop: "0",
                ipi: "0",
                ncm: "0",
                total: 0,
                amount: 0,
                unitaryValue: 0,
                INSTANCE: {} as SelectableProduct,
            }
        ]
    });
    const [installments, setInstallments] = useState<{ installment: Installment[] }>({
        installment: []
    });

    // MODAL
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomer(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: any } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: Product) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        selectProduct(selectableProduct, productBeingCreated.index);
        applyPriceList(priceList);
    }, [productBeingCreated, priceList]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        getInvoice();
        const date = new Date();
        const formated = format(date, "yyyyMMddHHmmss")
        const DateFormated = format(date, "yyyy-MM-dd")

        setInvoiceNumber(formated);
        setDateInvoice(DateFormated);

        getNfceConfig();
    }, []);

    useEffect(() => {
        calcTotalInvoice();
        setInstallments({ installment: [] });
    }, [products]);

    useEffect(() => {
        calcTotalInvoice();
        setInstallments({ installment: [] });
    }, [valueProducts, discountMoney, discountPercentage, valueFreight, valueExpenses]);

    async function getNfceConfig() {
        const response = await api.get('nfceConfig');
        const config = response.data[0];
        setNfceConfig(config);
    }

    const getCfop = useCallback(async () => {
        if (!nfceConfig) return undefined;

        return nfceConfig.cfopConsumer;
    }, [nfceConfig]);

    async function getInvoice() {
        const { data } = await api.get(`/nfce/${id}`);

        setNature(data.nature);
        setTypeAttendance(data.typeAttendance);
        setSeriesNfce(data.seriesNfce);
        setInvoiceNumber(data.invoiceNumber);
        setCustomer(Number(data.customer));
        setSeller(String(data.seller));
        setCustomerDontIdentified(String(data.customerDontIdentified));
        setIndicator(data.indicator);
        setIntermediaryCnpj(data.intermediaryCnpj);
        setIdentifierRegisterIntermediary(data.identifierRegisterIntermediary);
        setObservation(data.observation);
        setInternalObservation(data.internalObservation);
        setPriceList(String(data.priceList));
        setBcIcms(formatCurrency(data.bcIcms));
        setValueIcms(formatCurrency(data.valueIcms));
        setValueProducts(formatCurrency(data.valueProducts));
        setValueFreight(formatCurrency(data.valueFreight));
        setValueExpenses(formatCurrency(data.valueExpenses));
        setDiscountMoney(formatCurrency(data.discountMoney));
        setDiscountPercentage(formatCurrency(data.discountPercentage));
        setTotalValue(formatCurrency(data.totalValue));
        setAmountInstallments(data.amountInstallments);
        setGrossWeight(formatCurrency(data.grossWeight));
        setLiquidWeight(formatCurrency(data.liquidWeight));
        setVolumes(data.volumes);
        setSpecies(data.species);
        setBrand(data.brand);
        setNumbering(data.numbering);
        setModalityFreight(data.modalityFreight);
        setDestinationOperation(data.destinationOperation);
        setCarrier(String(data.carrier ?? ''));
        setPlateVehicle(data.plateVehicle);
        setProducts({ products: JSON.parse(data.products) });
        setInstallments({ installment: JSON.parse(data.installments) });
    }

    function checkCnpj(value: string) {
        var isSubmit = true;

        if (value) {
            var cnpjFormated = value.replace(/[^0-9]/g, "");
            isSubmit = cnpj.isValid(cnpjFormated);

            if (!isSubmit) {
                setActiveTab("details");
                setIntermediaryCnpj('');
                setMsgError("Cnpj do intermediador da transação inválido!");
                setShowModalAttention(true);
            }
        }
        return isSubmit;
    }

    function distributeValueInstallments(index: number) {
        var installmentsArray = installments.installment;

        var sumBeforeValue = 0;
        for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
            if (indexInstallment <= index) {
                sumBeforeValue += installmentsArray[indexInstallment].value;
            }
        }


        if (sumBeforeValue > formatToFloat(totalValue)) {
            // setMsgError("A soma das parcelas ultrapassa o valor total da nota fiscal!");
            // setShowModalAttention(true);

            // var valueDistributed  = formatToFloat(totalValue) / (installmentsArray.length - (index - 1));
            // for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
            //     if (indexInstallment > 0) {
            //         installmentsArray[indexInstallment].value = valueDistributed;
            //     }
            // }

        } else {
            var valueDistributed = (formatToFloat(totalValue) - sumBeforeValue) / (installmentsArray.length - (index + 1));
            for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
                if (indexInstallment > index) {
                    installmentsArray[indexInstallment].value = valueDistributed;
                }
            }
        }

        setInstallments({ installment: installmentsArray });
    }

    function calcTotalInvoice() {
        var totalProducts = 0;
        var totalInvoice = 0;
        var discountInMoney = formatToFloat(discountMoney) ?? 0;
        var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
        var freight = formatToFloat(valueFreight) ?? 0;
        var expenses = formatToFloat(valueExpenses) ?? 0;

        for (var product of products.products) {
            totalProducts += product.total;
        }

        totalInvoice = totalProducts + freight + expenses; // produtos, frete e despesas
        totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
        totalInvoice = totalInvoice - (totalProducts * (discountInPercentage / 100)); // desconto em porcentagem

        setValueProducts(formatCurrency(totalProducts));
        setTotalValue(formatCurrency(totalInvoice));
    }

    function getTotalInvoice() {
        var totalProducts = 0;
        var totalInvoice = 0;
        var discountInMoney = formatToFloat(discountMoney) ?? 0;
        var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
        var freight = formatToFloat(valueFreight) ?? 0;
        var expenses = formatToFloat(valueExpenses) ?? 0;

        for (var product of products.products) {
            totalProducts += product.total;
        }

        totalInvoice = totalProducts + freight + expenses; // produtos, frete, despesas
        totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
        totalInvoice = totalInvoice - (totalProducts * (discountInPercentage / 100)); // desconto em porcentagem

        return totalInvoice;
    }

    function addProducts() {
        var productsArray = products.products;

        productsArray.push({
            icms: "0",
            cfop: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            amount: 0,
            unitaryValue: 0,
            INSTANCE: {} as SelectableProduct,
        });

        setProducts({ products: productsArray });
    }

    function deleteProduct(index: number) {
        var productsArray = products.products;
        productsArray.splice(index, 1);
        setProducts({ products: productsArray });
    }

    async function selectProduct(value: any, index: number) {
        var productArray = products.products;

        if (value) {
            const defaultCfop = (await getCfop()) ?? '0';

            var productsInKit = value.kit;
            var type = value.type;

            if (value.isKit == "y") {
                type = "kit";

            } else if (value.hasVariation == "y") {
                type = "variation";

            }

            productArray[index].amount = 1;
            productArray[index].total = value.saleValue;
            productArray[index].unitaryValue = value.saleValue;
            productArray[index].ipi = value.ipiRate ? value.ipiRate : "0";
            productArray[index].icms = value.icmsRate ? value.icmsRate : "0";
            productArray[index].cfop = defaultCfop;
            productArray[index].ncm = value.ncm ? value.ncm : "0";
            productArray[index].INSTANCE = {
                id: value.id,
                name: value.name,
                code: value.code,
                saleValue: value.saleValue,
                codeGtinEan: value.codeGtinEan,
                stockInitial: value.stockInitial,
                currentStock: formatToFloat(value.currentStock),
                hasVariation: value.hasVariation,
                isKit: value.isKit,
                kit: productsInKit,
                type: type
            };

        } else {
            productArray[index].icms = "0";
            productArray[index].cfop = "0";
            productArray[index].ipi = "0";
            productArray[index].ncm = "0";
            productArray[index].amount = 0;
            productArray[index].total = 0;
            productArray[index].unitaryValue = 0;
            productArray[index].INSTANCE = {
                id: 0,
                name: "",
                code: "",
                saleValue: 0,
                codeGtinEan: "",
                stockInitial: "",
                currentStock: 0,
                hasVariation: "",
                isKit: "",
                kit: "",
                type: "product"
            };
        }

        changeTabIcon('flaticon-open-box')
        setProducts({ products: productArray });
    }

    function changeAmount(value: any, index: number) {
        var productArray = products.products;
        productArray[index].amount = value;
        productArray[index].total = productArray[index].unitaryValue * formatToFloat(value);

        setProducts({ products: productArray });
    }

    function changeProductValue(value: any, index: number) {
        var productArray = products.products;
        productArray[index].unitaryValue = formatToFloat(value);
        productArray[index].total = productArray[index].unitaryValue * Number(productArray[index].amount);

        setProducts({ products: productArray });
    }

    function setIpi(value: any, index: number) {
        var productArray = products.products;
        productArray[index].ipi = value;
        setProducts({ products: productArray });
    }

    function setIcms(value: any, index: number) {
        var productArray = products.products;
        productArray[index].icms = value;
        setProducts({ products: productArray });
    }

    function setCfop(value: any, index: number) {
        var productArray = products.products;
        productArray[index].cfop = value;
        setProducts({ products: productArray });
    }

    function setNcm(value: any, index: number) {
        var productArray = products.products;
        productArray[index].ncm = value;
        setProducts({ products: productArray });
    }

    async function applyPriceList(idPriceList: any) {
        if (Number(idPriceList)) {
            var objPriceList = await api.get(`/priceList/${idPriceList}`);
            if (objPriceList.data.priceListType == "1") {
                setTotalValue(String(getTotalInvoice() + objPriceList.data.value));

                // var productsArray     = products.products;           
                // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
                //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                // } 
                // setProducts({ products: productsArray });

            } else if (objPriceList.data.priceListType == "2") {
                setTotalValue(String(getTotalInvoice() - objPriceList.data.value));

                // var productsArray     = products.products;           
                // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
                //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                // } 
                // setProducts({ products: productsArray });

            } else {
                var priceListProducts = JSON.parse(objPriceList.data.products);
                var productsArray = products.products;

                for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
                    const { data } = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
                    var price = priceListProducts.find((element: any) => element.productId == productsArray[indexProduct].INSTANCE.id);

                    if (data) {
                        if (price) {
                            if (price.type == "1") {
                                productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue) - formatToFloat(price.value);
                                productsArray[indexProduct].total = productsArray[indexProduct].amount * productsArray[indexProduct].unitaryValue;

                            } else {
                                productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue) + formatToFloat(price.value);
                                productsArray[indexProduct].total = productsArray[indexProduct].amount * productsArray[indexProduct].unitaryValue;
                            }

                        } else {
                            productsArray[indexProduct].unitaryValue = formatToFloat(data.saleValue);
                            productsArray[indexProduct].total = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
                        }
                    }
                }
                setProducts({ products: productsArray });
            }
        } else {
            // var productsArray     = products.products;           
            // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
            //     const {data}      = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);

            //     if (data) {
            //         productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
            //         productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
            //     }
            // }                
            // setProducts({ products: productsArray });
        }
    }

    function setValuesInstallments(value: any, index: number, type: string) {
        var installmentArray = installments.installment;

        if (type == "date") {
            installmentArray[index].date = value;

        } else if (type == "value") {
            installmentArray[index].value = formatToFloat(value);

        } else if (type == "payment") {
            installmentArray[index].payment = value;

        } else {
            installmentArray[index].comments = value;
        }

        setInstallments({ installment: installmentArray });
    }

    function generateInstallments() {
        if (formatToFloat(totalValue)) {
            var installmentsArray = [];
            var today = new Date();
            var day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
            var month = String(today.getMonth() + 1);
            var year = today.getFullYear();

            var valueInstallment = formatToFloat(totalValue) / formatToFloat(amountInstallments);

            for (var index = 0; index < formatToFloat(amountInstallments); index++) {
                month = today.getMonth() < 10 ? "0" + (today.getMonth() + (index + 1)) : String(today.getMonth() + (index + 1));

                installmentsArray.push({
                    date: year + "-" + month + "-" + day,
                    value: valueInstallment,
                    comments: "",
                    payment: "Cartão de Crédito"
                });
            }
            setInstallments({ installment: installmentsArray });

        } else {
            setMsgError("Selecione pelo menos um produto para gerar as parcelas!");
            setShowModalAttention(true);
        }
    }

    function hasInstallments() {
        var isSubmit = true;

        if (installments.installment.length == 0) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas da nota fiscal!");
            setShowModalAttention(true);
            isSubmit = false;
        }

        return isSubmit;
    }

    function validateForm() {
        var isSubmit = true;

        if (customerDontIdentified === 'n' && !customer) {
            setMsgError('Selecione o cliente ou marque a opção "Cliente não identificado"!');
            setShowModalAttention(true);
            isSubmit = false;
            return false;
        }

        if (!products.products[0].total) {
            setActiveTab("products");
            setMsgError("Selecione pelo menos um produto para nota fiscal!");
            setShowModalAttention(true);
            isSubmit = false;
            return false;
        }

        return isSubmit;
    }

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-open-box") {
            if (products.products[0].total) {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setProductsInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

                setProductsInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('details')?.classList.remove('current');
        document.getElementById('products')?.classList.remove('current');
        document.getElementById('total-invoice')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('transport')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'details') {
                setNextButton('first');
            } else if (typeAttendance === 1 && tab === 'transport' || typeAttendance !== 1 && tab === 'payment') {
                setNextButton('last');
        } else {
                setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['details', 'products', 'total-invoice', 'payment', 'transport'];
        let arr2 = ['flaticon-doc', 'flaticon-open-box', 'flaticon-coins', 'flaticon-list-1', 'flaticon-truck'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    const onSubmit = async (data: any) => {
        if (validateForm() && hasInstallments() && checkCnpj(intermediaryCnpj)) {
            setIsSubmit(true);

            try {

                const lastInputs = await api.get(`/nfce/${id}`);

                const raw = {
                    nature: nature,
                    typeAttendance: Number(typeAttendance),
                    seriesNfce: seriesNfce,
                    invoiceNumber: invoiceNumber,
                    customer: Number(customer),
                    seller: Number(seller) ?? null,
                    customerDontIdentified: customerDontIdentified,
                    indicator: indicator,
                    intermediaryCnpj: intermediaryCnpj,
                    identifierRegisterIntermediary: identifierRegisterIntermediary,
                    observation: observation,
                    internalObservation: internalObservation,
                    priceList: priceList,
                    bcIcms: formatToFloat(bcIcms),
                    valueIcms: formatToFloat(valueIcms),
                    valueProducts: formatToFloat(valueProducts),
                    valueFreight: formatToFloat(valueFreight),
                    valueExpenses: formatToFloat(valueExpenses),
                    discountMoney: formatToFloat(discountMoney),
                    discountPercentage: formatToFloat(discountPercentage),
                    totalValue: formatToFloat(totalValue),
                    amountInstallments: amountInstallments,
                    grossWeight: formatToFloat(grossWeight),
                    liquidWeight: formatToFloat(liquidWeight),
                    volumes: volumes,
                    species: species,
                    brand: brand,
                    numbering: numbering,
                    modalityFreight: Number(modalityFreight) ?? null,
                    destinationOperation: Number(destinationOperation) ?? null,
                    carrier: Number(carrier) ?? null,
                    plateVehicle: plateVehicle,
                    date: dateInvoice,
                    products: JSON.stringify(products.products),
                    installments: JSON.stringify(installments.installment),
                    situation: "open",
                };

                const result = await api.post(`/nfce/${id}`, raw);

                LogService.logEdit({
                    itemId: result.data.id,
                    itemName: 'Venda - NFc-e Consumidor',
                    module: 'NFc-e Consumidor',
                    oldData: lastInputs.data,
                    newData: result.data,
                    formattedFields: {
                        date: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        customer: (value) => CustomerService.getNameByIdAsync(value),
                        customerDontIdentified: (value) => getYesOrNoText(value),
                        seller: (value) => SellerService.getNameByIdAsync(value),
                        carrier: (value) => getCarrierById(value),
                        modalityFreight: (value) => freightModalityById(value),
                        destinationOperation: (value) => NfeService.getDestinationOperation(value),
                        typeAttendance: (value) => NfeService.getTypeAttendance(value),
                        discountMoney: (value) => formatNumberToString(value),
                        discountPercentage: (value) => formatNumberToString(value),
                        valueFreight: (value) => formatNumberToString(value),
                        valueExpenses: (value) => formatNumberToString(value),
                        grossWeight: (value) => formatNumberToString(value),
                        liquidWeight: (value) => formatNumberToString(value),
                        volumes: (value) => formatNumberToString(value),
                    },
                    fieldsMap: {
                        nature: 'Natureza',
                        typeAttendance: 'Tipo de atendimento',
                        customer: 'Cliente',
                        seller: 'Vendedor',
                        customerDontIdentified: 'Cliente não identificado',
                        indicator: 'Indicador de intermediador',
                        intermediaryCnpj: 'CNPJ do intermediador da transação',
                        identifierRegisterIntermediary: 'Identificador cadastro intermediador',
                        observation: 'Observações',
                        internalObservation: 'Observações internas',
                        priceList: 'Lista de preços',
                        discountMoney: 'Desconto (R$)',
                        discountPercentage: 'Desconto (%)',
                        valueFreight: 'Valor do frete',
                        valueExpenses: 'Valor despesas',
                        amountInstallments: 'Quantidade de parcelas',
                        grossWeight: 'Peso Bruto',
                        liquidWeight: 'Peso Líquido',
                        volumes: 'Volumes',
                        species: 'Espécies',
                        brand: 'Marca',
                        numbering: 'Numeração',
                        destinationOperation: 'Local de destino',
                        modalityFreight: 'Modalidade de frete',
                        carrier: 'Transportadora',
                        plateVehicle: 'Placa'
                    }
                });

                history.push("/notas-fiscais-consumidor");

            } catch (error) {
                console.log(error);
            }

            setIsSubmit(false);
        }

    };

    return (
        <MainPageContentWrapper>
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="details" data-kt-stepper-element='nav' onClick={() => changeTab('details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Produtos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Produtos do pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="total-invoice" data-kt-stepper-element='nav' onClick={() => changeTab('total-invoice', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Totais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Totais do pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Pagamento</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Pagamento do pedido</div>
                                            </div>
                                            </div>
                                            {
                                                typeAttendance == 1
                                                ?
                                                    <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                                : <></>
                                            }
                                        </div>
                                        {
                                            typeAttendance == 1
                                            ?
                                            <div className='stepper-item' id="transport" data-kt-stepper-element='nav' onClick={() => changeTab('transport', 'flaticon-truck', previousIcon)} style={{cursor: "pointer"}}>
                                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                <div className='stepper-icon w-40px h-40px'>
                                                        <i className='stepper-check fas fa-check'></i>
                                                        <i className="flaticon-truck icon_change_color" style={{color: "#666"}}></i>
                                                </div>
                                                <div className='stepper-label'>
                                                        <h3 className='stepper-title text-break'>Transporte</h3>
                                                        <div className='stepper-desc fw-semibold text-break'>Transporte do pedido</div>
                                                </div>
                                                </div>
                                            </div>
                                            : <></>
                                        }
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/notas-fiscais-consumidor">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button
                                            type="button"
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {isSubmit ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />

                                                <span className="ml-2">
                                                    Aguarde...
                                                </span>
                                            </> : <>
                                                <span>
                                                    Salvar
                                                </span>
                                            </>}
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    select
                                    label="Natureza da operação"
                                    margin="normal"
                                    size="small"
                                    value={nature}
                                    onChange={(e) => setNature(e.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >

                                    <MenuItem key="0" value="">
                                        Selecione uma natureza de operação
                                    </MenuItem>

                                    <MenuItem key="1" value="venda de producao do estabelecimento">
                                        5101 - Venda de produção do estabelecimento
                                    </MenuItem>

                                    <MenuItem key="2" value="venda de mercadoria adquirida ou recebida de terceiros">
                                        5102 - Venda de mercadoria adquirida ou recebida de terceiros
                                    </MenuItem>

                                    <MenuItem key="3" value="venda de producao do estabelecimento, efetuada fora do estabelecimento">
                                        5103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento
                                    </MenuItem>

                                    <MenuItem key="3" value="venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento">
                                        5104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento
                                    </MenuItem>

                                    <MenuItem key="3" value="venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil">
                                        5115 - Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil
                                    </MenuItem>

                                    <MenuItem key="3" value="venda de mercadoria adquirida ou recebida de terceiros em operacao com mercadoria sujeita ao regime de substituicao tributária, na condicao de contribuinte substituido">
                                        5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído
                                    </MenuItem>

                                    <MenuItem key="3" value="prestacao de servico tributado pelo ISSQN">
                                        5933 - Prestação de serviço tributado pelo ISSQN
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    select
                                    label="Tipo de atendimento"
                                    margin="normal"
                                    size="small"
                                    value={typeAttendance}
                                    onChange={(e) => {
                                        setTypeAttendance(Number(e.target.value));

                                        setGrossWeight("");
                                        setLiquidWeight("");
                                        setVolumes("");
                                        setSpecies("");
                                        setBrand("");
                                        setNumbering("");
                                        setModalityFreight(Number(e.target.value) == 1 ? 0 : 9);
                                        setCarrier("");
                                        setPlateVehicle("");
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >

                                    <MenuItem key="0" value="1">
                                        Operação presencial
                                    </MenuItem>

                                    <MenuItem key="1" value="4">
                                        Operação com entrega a domicílio
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    label="Série NFC-e"
                                    margin="normal"
                                    size="small"
                                    disabled
                                    inputProps={{ maxLength: 3 }}
                                    value={seriesNfce}
                                    onChange={(e) => setSeriesNfce(e.target.value)}
                                >
                                </TextField>
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    disabled
                                    label="Nº NFC-e"
                                    margin="normal"
                                    value={invoiceNumber}
                                />
                            </Grid>

                            <Grid item lg={7} md={6} xs={12}>
                                <CustomerSelect
                                    label="Cliente"
                                    isEdit
                                    customerId={customer}
                                    setCustomerId={setCustomer}
                                    disabled={customerDontIdentified == "y" || user.isAccountant == "y" ? true : false}
                                    onClickAddCustomer={handleClickAddCustomer}
                                />
                            </Grid>

                            <Grid item lg={5} md={6} xs={12}>
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={customerDontIdentified == "y" ? true : false} onChange={() => {
                                        setCustomerDontIdentified(customerDontIdentified == "y" ? "n" : "y");
                                        setCustomer(customerDontIdentified == "y" ? customer : undefined);
                                    }} />}
                                    label="Cliente não identificado"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/notas-fiscais-consumidor" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs
                            activeKey={activeTab}
                            onSelect={(tab: string) => setActiveTab(tab)}
                            id='invoice-form-tabs'
                            style={{ display: "none" }}
                        >
                            <Tab
                                eventKey='details'
                                title='Detalhes'
                            >
                                <div className="row">
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            select
                                            label="Indicador de intermediador/marketplace"
                                            margin="normal"
                                            size="small"
                                            value={indicator}
                                            onChange={(e) => setIndicator(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            <MenuItem key="1" value="operacao sem intermediador (em site ou plataforma própria)">
                                                Operação sem intermediador (em site ou plataforma própria)
                                            </MenuItem>

                                            <MenuItem key="2" value="operacao em site ou plataforma de terceiros (intermediadores/marketplace)">
                                                Operação em site ou plataforma de terceiros (intermediadores/marketplace)
                                            </MenuItem>
                                        </TextField>

                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <ReactNumberFormat
                                            margin="normal"
                                            customInput={TextField}
                                            label="CNPJ do intermediador da transação"
                                            format="##.###.###/####-##"
                                            mask="_"
                                            disabled={indicator != "operacao em site ou plataforma de terceiros (intermediadores/marketplace)" ? true : false}
                                            value={intermediaryCnpj}
                                            onChange={(e) => setIntermediaryCnpj(e.target.value)}
                                            onBlur={(e) => checkCnpj(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            label="Identificador cadastro intermediador"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                            disabled={indicator != "operacao em site ou plataforma de terceiros (intermediadores/marketplace)" ? true : false}
                                            value={identifierRegisterIntermediary}
                                            onChange={(e) => setIdentifierRegisterIntermediary(e.target.value)}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            label="Observações"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 5000 }}
                                            value={observation}
                                            onChange={(e) => setObservation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            label="Observações Internas"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            size="small"
                                            inputProps={{ maxLength: 5000 }}
                                            value={internalObservation}
                                            onChange={(e) => setInternalObservation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                        <Button
                                            className="text-dark"
                                            variant="success"
                                            size="sm"
                                            block
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <i className="far fa-comments text-dark"></i>
                                            Mensagens padrões
                                        </Button>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='products'
                                title='Produtos'
                            >
                                {
                                    productsInvalid && (
                                        <p className="text-danger">Selecione pelo menos um produto para nota fiscal!</p>
                                    )
                                }
                                <div className="row">
                                    {
                                        products.products.map((product: any, index: number) => {
                                            return (
                                                <>
                                                    <Grid item lg={4} md={6} xs={12} className="d-flex align-items-center">
                                                        <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                                                            <label className="text-white m-0">{index + 1}.</label>

                                                            {
                                                                product.INSTANCE.isKit == "y"
                                                                    ?
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id='tooltip-top'>
                                                                                <b>Produtos do Kit</b><br />
                                                                                {product.INSTANCE.kit}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            variant="primary"
                                                                            className="d-flex flex-row align-items-center justify-content-center ml-2 p-0"
                                                                            style={{ padding: "5px", background: "transparent" }}
                                                                        >
                                                                            <i className="flaticon2-information m-0 p-0"></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                        <ProductSelect
                                                            label="Produto"
                                                            isEdit
                                                            selectedProduct={product}
                                                            onChangeProduct={(prod) => { selectProduct(prod, index); applyPriceList(priceList) }}
                                                            type="product"
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                            onClickAddProduct={(typedText) => handleClickAddProduct(typedText, index, product)}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label="Qtde."
                                                            margin="normal"
                                                            name="amount"

                                                            value={product.amount}
                                                            onChange={
                                                                (evt) => { changeAmount(evt.target.value, index); applyPriceList(priceList); changeTabIcon('flaticon-open-box'); }
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            withPrefix={false}
                                                            label="IPI %"
                                                            value={product.ipi}
                                                            onChange={
                                                                (e: any) => setIpi(e.target.value, index)
                                                            }
                                                            onBlur={(e) => {
                                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                                if (converted > 100) {
                                                                    setIpi("100", index);

                                                                } else {
                                                                    setIpi(converted ? String(converted) : "0", index);
                                                                }
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            withPrefix={false}
                                                            label="ICMS %"
                                                            value={product.icms}
                                                            onChange={
                                                                (e: any) => setIcms(e.target.value, index)
                                                            }
                                                            onBlur={(e) => {
                                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                                if (converted > 100) {
                                                                    setIcms("100", index);

                                                                } else {
                                                                    setIcms(converted ? String(converted) : "0", index);
                                                                }
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor Unit."
                                                            withPrefix={false}
                                                            value={product.unitaryValue}
                                                            onChange={
                                                                (evt) => { changeProductValue(evt.target.value, index); applyPriceList(priceList); changeTabIcon('flaticon-open-box'); }
                                                            }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        R$
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            withPrefix={false}
                                                            format="####"
                                                            label="CFOP"
                                                            value={product.cfop}
                                                            onChange={
                                                                (e: any) => setCfop(e.target.value, index)
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            margin="normal"
                                                            customInput={TextField}
                                                            withPrefix={false}
                                                            label="NCM"
                                                            format="########"
                                                            value={product.ncm}
                                                            onChange={
                                                                (e: any) => setNcm(e.target.value, index)
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={1} md={6} xs={12}>
                                                        <NumericFormat
                                                            label="Valor Total"
                                                            disabled
                                                            value={product.total}
                                                            withPrefix={false}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        R$
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>

                                                    <>
                                                        {
                                                            (index > 0 && user.isAccountant == "n") && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link"
                                                                    onClick={() => deleteProduct(index)}
                                                                >
                                                                    <i className="flaticon-delete text-danger"></i>
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                </>
                                            );
                                        })
                                    }

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item xs={12}>
                                                <button
                                                    type="button"
                                                    className="btn btn-link p-0"
                                                    onClick={() => addProducts()}
                                                >
                                                    <ins>+ adicionar outro produto</ins>
                                                </button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Lista de preços"
                                            getOptionLabel={(option: PriceList) => option.name}
                                            value={priceList}
                                            onSelect={(option) => { setPriceList(option ? String(option.id) : ''); applyPriceList(option ? String(option.id) : '') }}
                                            apiSearchHandler={(typedText) => PriceListService.getPriceListsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!priceList) return null;
                                                return loadedOptions.find((option) => option.id === Number(priceList)) ?? PriceListService.getPriceListById(priceList)
                                            }}
                                            disabled={formatToFloat(totalValue) === 0}
                                        />
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='total-invoice'
                                title='Totais da Notal Fiscal'
                            >
                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="BC de ICMS"
                                            withPrefix={false}
                                            value={bcIcms}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do ICMS"
                                            withPrefix={false}
                                            value={valueIcms}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor dos produtos"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={valueProducts}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor do Frete"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={valueFreight}
                                            onChange={(e) => setValueFreight(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor despesas"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={valueExpenses}
                                            onChange={(e) => setValueExpenses(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Desconto (R$)"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={discountMoney}
                                            onChange={(e) => setDiscountMoney(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Desconto (%)"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        %
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={discountPercentage}
                                            onChange={(e) => setDiscountPercentage(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />

                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor total da nota"
                                            withPrefix={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={totalValue}
                                            disabled
                                        />
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='payment'
                                title='Pagamento'
                            >
                                <div className="row">

                                    {/* <div className="col-lg-3 d-flex align-items-center">

                                    <TextField
                                        select
                                        label="Condição de Pagamento"
                                        margin="normal"
                                        name="conditionPayment"
                                        size="small"
                                    >
                                        <MenuItem key="0" value="nf-e normal">
                                            Selecione
                                        </MenuItem>

                                        <MenuItem key="1" value="30/60/90">
                                            30/60/90
                                        </MenuItem>
                                    </TextField>

                                </div>

                                <div className="p-3 d-flex align-items-center justify-content-center">
                                    <label>ou</label>
                                </div> */}

                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            type="number"
                                            label="Quantidade de Parcelas"
                                            margin="normal"
                                            name="amountParcel"
                                            size="small"
                                            value={amountInstallments}
                                            onChange={(e) => setAmountInstallments(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item md={3} xs={12}>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => generateInstallments()}
                                                    block
                                                >
                                                    Gerar parcelas
                                                </Button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item xs={12}>
                                        {
                                            installments.installment.map((installment, index) => {
                                                return (
                                                    <div className="row">
                                                        <Grid item lg={3} md={6} xs={12} className="d-flex align-items-center">
                                                            <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                                                                <label className="text-white m-0">
                                                                    {index + 1}.
                                                                </label>
                                                            </div>

                                                            <TextField
                                                                type="date"
                                                                label="Data"
                                                                margin="normal"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                value={installment.date}
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "date")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>

                                                        <div className="col p-1">
                                                            {index + 1 !== installments.installment.length ? (
                                                                <NumericFormat
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    withPrefix={false}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                R$
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        var installmentArray = installments.installment;
                                                                        installmentArray[index].value = formatToFloat(e.target.value);
                                                                        setInstallments({ installment: installmentArray });
                                                                        distributeValueInstallments(index);
                                                                    }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            ) : (
                                                                <NumericFormat
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    withPrefix={false}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                R$
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onBlur={(e) => { setValuesInstallments(e.target.value, index, "value"); distributeValueInstallments(index) }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="col p-1">
                                                            <TextField
                                                                select
                                                                label="Forma de pgto"
                                                                margin="normal"
                                                                value={installment.payment}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "payment")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key={-1} value="">
                                                                    Selecione
                                                                </MenuItem>
                                                                {
                                                                    paymentOptions.map((payment, i) => {
                                                                        return (
                                                                            <MenuItem key={i} value={payment.value}>
                                                                                {payment.value}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }
                                                            </TextField>

                                                        </div>

                                                        <div className="col p-1">
                                                            <TextField
                                                                label="Observações"
                                                                margin="normal"
                                                                value={installment.comments}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "comments")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Grid>
                                </div>
                            </Tab>

                            {
                                typeAttendance == 1
                                    ?
                                    <Tab
                                        eventKey='transport'
                                        title='Transporte'
                                    >
                                        <div className="row">
                                            <Grid item lg={2} md={6} xs={12}>
                                                <NumericFormat
                                                    label="Peso Bruto"
                                                    withPrefix={false}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                KG
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={grossWeight}
                                                    onChange={(e) => setGrossWeight(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <NumericFormat
                                                    label="Peso Líquido"
                                                    withPrefix={false}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                KG
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={liquidWeight}
                                                    onChange={(e) => setLiquidWeight(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <NumericFormat
                                                    label="Volumes"
                                                    thousandSeparator=""
                                                    decimalScale={0}
                                                    value={volumes}
                                                    onChange={(e) => setVolumes(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <TextField
                                                    label="Espécie"
                                                    margin="normal"
                                                    placeholder="EX: Caixa"
                                                    size="small"
                                                    value={species}
                                                    onChange={(e) => setSpecies(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <TextField
                                                    label="Marca"
                                                    margin="normal"
                                                    size="small"
                                                    value={brand}
                                                    onChange={(e) => setBrand(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <TextField
                                                    label="Numeração"
                                                    margin="normal"
                                                    size="small"
                                                    value={numbering}
                                                    onChange={(e) => setNumbering(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item lg={3} md={6} xs={12}>
                                                <TextField
                                                    select
                                                    label="Local de Destino"
                                                    margin="normal"
                                                    size="small"
                                                    value={destinationOperation}
                                                    onChange={(e) => setDestinationOperation(Number(e.target.value))}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                    <MenuItem key="0" value="1">
                                                        Operação interna
                                                    </MenuItem>

                                                    <MenuItem key="1" value="2">
                                                        Operação interestadual
                                                    </MenuItem>

                                                    <MenuItem key="2" value="3">
                                                        Operação com exterior
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>

                                            <Grid item lg={3} md={6} xs={12}>
                                                <TextField
                                                    select
                                                    label="Modalidade de Frete"
                                                    margin="normal"
                                                    size="small"
                                                    value={modalityFreight}
                                                    onChange={(e) => setModalityFreight(Number(e.target.value))}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                    <MenuItem key="0" value="0">
                                                        Por conta do emitente
                                                    </MenuItem>

                                                    <MenuItem key="1" value="1">
                                                        Por conta do destinatário
                                                    </MenuItem>

                                                    <MenuItem key="2" value="2">
                                                        Por conta de terceiros
                                                    </MenuItem>

                                                    <MenuItem key="3" value="9">
                                                        Sem frete
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>

                                            <Grid item lg={4} md={6} xs={12}>
                                                <ApiResourceSelect
                                                    label="Transportadora"
                                                    getOptionLabel={(option: Carrier) => option.name}
                                                    value={carrier}
                                                    onSelect={(option) => setCarrier(option ? String(option.id) : '')}
                                                    apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                                    getSelectedOption={(loadedOptions) => {
                                                        if (!carrier) return null;
                                                        return loadedOptions.find((option) => option.id === Number(carrier)) ?? CarrierService.getCarrierById(carrier)
                                                    }}
                                                    disabled={modalityFreight == 9 || user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <TextField
                                                    label="Placa"
                                                    margin="normal"
                                                    placeholder="Placa do veiculo"
                                                    size="small"
                                                    value={plateVehicle}
                                                    onChange={(e) => setPlateVehicle(e.target.value)}
                                                    disabled={modalityFreight == 9 || user.isAccountant == "y" ? true : false}
                                                >
                                                </TextField>
                                            </Grid>
                                        </div>
                                    </Tab>
                                    :
                                    <></>
                            }
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}