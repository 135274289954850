import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid,
    Checkbox
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal
} from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import { Menu } from '../../types/Menu';
import api from '../../services/Api';
import { useHistory, useParams } from 'react-router';
import { Profile } from '../../types/Profile';
import PermissionMenuSelector from '../../components/PermissionMenuSelector';
import { useSelector } from 'react-redux';
import { PermissionMenu } from '../../types/PermissionMenu';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function EditProfile() {
    const { user } = useSelector((state: any) => state.auth);
    
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Editar Perfil");

    const { menus } = useMenu();
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(menus as PermissionMenu[]);
    const [savedPermissions, setSavedPermissions] = useState<number[]>();

    const [name, setName] = useState("");
    const [nameInvalid, setNameInvalid] = useState(false);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if(!menus.length || !savedPermissions) return;
        
        if(savedPermissions.length === 0) {
            setPermissionMenus(menus as PermissionMenu[]);
        } else {
            const aux = menus.map(menu => ({...menu})) as PermissionMenu[];
            const permissions = mapPermissionsChecked(savedPermissions, aux);
            setPermissionMenus([...permissions]);
        }
    }, [menus, savedPermissions]);

    useEffect(() => {
        getProfile();
    }, []);

    async function getProfile() {
        const response = await api.get<Profile>(`profiles/${id}`);

        setName(response.data.name);
        setSavedPermissions(JSON.parse(response.data.permissions));
    }

    function inputsVerify(): boolean {

        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    function handleChangeName(value: string) {
        setName(value);
        setNameInvalid(!value ? true : false);
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = filterPermissionMenu(aux);
        const permissions = mapPermissionMenu(menusFiltered);

        const raw = {
            name,
            permissions: JSON.stringify(permissions),
        };

        try {
            await api.put(`profiles/${id}`, raw);

            history.push("/perfis-acesso");
        } catch (error) {
            setMsgError('Erro ao salvar perfil');
            setShowModalError(true);
            setIsSubmit(false);
        }
    }

    function filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
        const filtered = menus.filter((menu) => menu.checked);
        if(!filtered || filtered.length === 0) {
            return [];
        }
        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = filterPermissionMenu(filtered[i].submenus as PermissionMenu[]).filter(() => true);
            }
        }
        return filtered;
    }

    function mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
        let mapped = menus.map((menu) => menu.id);
        for(let i = 0; i < menus.length; i++) {
            if(menus[i].submenus?.length) {
                mapped = mapped.concat(mapPermissionMenu(menus[i].submenus as PermissionMenu[]));
            }
        }
        return mapped;
    }

    function mapPermissionsChecked(permissions: Array<number>, menus: PermissionMenu[]) {
        const aux = menus;
        for(let i = 0; i < aux.length; i++) {
            aux[i].checked = permissions.includes(aux[i].id);
            if(aux[i].submenus?.length) {
                aux[i].submenus = mapPermissionsChecked(permissions, aux[i].submenus as PermissionMenu[]);
            }
        }
        return aux;
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">

                    <div className="col-lg-6">
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome do Perfil*"
                            margin="normal"
                            value={name}
                            onChange={(e) => handleChangeName(e.target.value)}
                            error={nameInvalid}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </div>

                    {
                        user.isAccountant == "n"
                        ?
                        <div className="col-lg-6 d-flex align-items-start justify-content-end">
                            <Button
                                type='submit'
                                className='mt-4'
                                variant="primary"
                                disabled={isSubmit}
                            >

                                {isSubmit ? <>

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className='ml-2'>
                                        Aguarde...
                                    </span>

                                </> : <>

                                    <span>
                                        Salvar
                                    </span>

                                </>}


                            </Button>
                        </div>
                        : <></>
                    }

                </div>

                <div className="row">
                    <div className="col-12">
                        <PermissionMenuSelector
                            permissionMenus={permissionMenus}
                            setPermissionMenus={setPermissionMenus}
                        />
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}