import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../../hooks/backendReload';
import { CompanySubscriptionPlanPayment, invoicePaymentMethod, invoiceStatus } from '../../../types/CompanySubscriptionPlanPayment';
import api from '../../../services/Api';
import { getSituationText } from '../../../utils/getSituationText';
import CompanySubscriptionPlanPaymentService from '../../../services/CompanySubscriptionPlanPaymentService';
import { formatDate } from '../../../utils/dateFormat';
import { Search } from '../../../components/Search';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { SubscriptionPlan } from '../../../types/SubscriptionPlan';
import SubscriptionPlanService from '../../../services/SubscriptionPlanService';
import { BsVariant } from '../../../types/BsVariant';
import ModalSuccess from '../../../components/ModalSuccess';
import CompanySubscriptionPlanService from '../../../services/CompanySubscriptionPlanService';
import { CompanySubscriptionPlan } from '../../../types/CompanySubscriptionPlan';
import { formatCurrency } from '../../../utils/formatCurrency';
import { formatIntegerToMoney } from '../../../utils/formatCurrency';
import ModalConfirm from '../../../components/ModalConfirm';
import ModalError from '../../../components/ModalError';
import { ModalRetryCard } from './ModalRetryCard';
import { Link } from 'react-router-dom';
import { yesOrNo } from '../../../types/yesOrNo';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
    planId: number;
    paymentDateMin: string;
    paymentDateMax: string;
    dueDateMin: string;
    dueDateMax: string;
    paymentMethod: string;
    status: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "planName", value: "Plano" },
    { reference: "paymentMethod", value: "Forma de Pagamento" },
    { reference: "paymentDate", value: "Competência" },
    { reference: "dueDate", value: "Data de Vencimento" },
    { reference: "status", value: "Status", situation: true, notSortable: true },
];

export function CompanyInvoice() {
    const { location: { pathname }, push: pushHistory } = useHistory();
    const { user } = useSelector((state: any) => state.auth);

    const [currentCompanySubscriptionPlan, setCurrentCompanySubscriptionPlan] = useState<CompanySubscriptionPlan>();
    const [lastCompanyPayment, setLastCompanyPayment] = useState<CompanySubscriptionPlanPayment>();

    const [companyPlanPayments, setCompanyPlanPayments] = useState<CompanySubscriptionPlanPayment[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCompanyPlanPayments, setCountTotalCompanyPlanPayments] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const [planId, setPlanId] = useState(0);
    const [paymentDateMin, setPaymentDateMin] = useState('');
    const [paymentDateMax, setPaymentDateMax] = useState('');
    const [dueDateMin, setDueDateMin] = useState('');
    const [dueDateMax, setDueDateMax] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [status, setStatus] = useState('');

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [showModalError, setShowModalError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [showModalConfirmCancel, setShowModalConfirmCancel] = useState(false);
    const [showModalRetryCard, setShowModalRetryCard] = useState(false);
    const [selectedCompanyPlanPayment, setSelectedCompanyPlanPayment] = useState<CompanySubscriptionPlanPayment>();

    useEffect(() => {
        if (!user) {
            return;
        }

        async function loadCurrentSubscriptionPlan() {
            const currentCompanyPlan = await CompanySubscriptionPlanService.getCurrentActiveCompanySubscriptionPlanByCompanyId(user.companyId);
            setCurrentCompanySubscriptionPlan(currentCompanyPlan);

            if (currentCompanyPlan.value <= 0) {
                return;
            }

            const lastPayment = await CompanySubscriptionPlanPaymentService.getLastByCompanyId(user.companyId);
            setLastCompanyPayment(lastPayment);
        }

        loadCurrentSubscriptionPlan();
    }, [user]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: CompanySubscriptionPlanPayment[], count: number }>("companySubscriptionPlanPayment/auth", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setCompanyPlanPayments(rows);
        setCountTotalCompanyPlanPayments(count);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = companyPlanPayments;

        aux.forEach((companyPlanPayment) => {
            const situation = getSituationText(companyPlanPayment.status);

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(companyPlanPayment.id), id: true },
                { for: "planName", value: companyPlanPayment.subscriptionPlan.name },
                { for: "paymentMethod", value: CompanySubscriptionPlanPaymentService.getPaymentMethodText(companyPlanPayment.paymentMethod) },
                { for: "paymentDate", value: formatDate(companyPlanPayment.paymentDate, true) },
                { for: "dueDate", value: formatDate(companyPlanPayment.dueDate, true) },
                { for: "status", value: CompanySubscriptionPlanPaymentService.getIsPaymentLate(companyPlanPayment, true) ? 'Atrasado' : situation },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [companyPlanPayments]);

    const clearSearch = () => {
        setSearchQuery('');
        setPlanId(0);
        setPaymentDateMin('');
        setPaymentDateMax('');
        setDueDateMin('');
        setDueDateMax('');
        setPaymentMethod('');
        setStatus('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, planId,
            paymentDateMin, paymentDateMax,
            dueDateMin, dueDateMax,
            paymentMethod, status,
        };

        reloadData();
    }, [
        companyPlanPayments, searchQuery,
        planId, paymentDateMin, paymentDateMax,
        dueDateMin, dueDateMax, paymentMethod,
        status,
    ]);

    const billetButtonShowCondition = useCallback((id: string) => {
        const companyPlanPayment = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!companyPlanPayment) return false;

        return companyPlanPayment.paymentMethod === invoicePaymentMethod.boleto;
    }, [companyPlanPayments]);

    const handleClickBilletButton = useCallback((id: string) => {
        const row = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!row) return;

        window.open(row.billetUrl, '_blank', 'width=800,height=800');
    }, [companyPlanPayments]);

    const retryCardButtonShowCondition = useCallback((id: string) => {
        if (!currentCompanySubscriptionPlan) return false;

        const companyPlanPayment = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!companyPlanPayment) return false;

        return companyPlanPayment.paymentMethod === invoicePaymentMethod.credit_card && companyPlanPayment.status === invoiceStatus.failed;
    }, [companyPlanPayments, currentCompanySubscriptionPlan]);

    const handleClickRetryCardButton = useCallback((id: string) => {
        const row = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!row) return;

        setSelectedCompanyPlanPayment(row);
        setShowModalRetryCard(true);
    }, [companyPlanPayments]);

    const handleRetryCardSubmitted = useCallback(async () => {
        if (!selectedCompanyPlanPayment) {
            return;
        }

        setCompanyPlanPayments((prevState) => prevState.map((payment) => {
            if (payment.id === selectedCompanyPlanPayment.id) {
                return {
                    ...payment,
                    status: invoiceStatus.pending,
                };
            } else {
                return payment;
            }
        }));

        setMessageSuccess('Dados enviados para processamento, aguarde alguns segundos e atualize a página.');
        setShowModalSuccess(true);
    }, [selectedCompanyPlanPayment]);

    const cancelPlan = useCallback(async () => {
        try {
            if (!currentCompanySubscriptionPlan) {
                throw new Error('Plano inválido');
            }

            const { data: companyPlan } = await api.delete<CompanySubscriptionPlan>(`/companySubscriptionPlans/${currentCompanySubscriptionPlan?.id}`);

            if (companyPlan.cancelRequested === yesOrNo.YES) {
                setShowModalConfirmCancel(false);
                setMessageSuccess(`Seu plano será cancelado em ${formatDate(companyPlan.cancellationDate)}, até esta data você poderá continuar usando a Uninteract normalmente.`);
                setShowModalSuccess(true);
                setCurrentCompanySubscriptionPlan((prevState) => {
                    if (!prevState) return prevState;

                    return {
                        ...prevState,
                        cancelRequested: companyPlan.cancelRequested,
                        cancelRequestedAt: companyPlan.cancelRequestedAt,
                        cancellationDate: companyPlan.cancellationDate,
                    };
                });
            } else {
                setShowModalConfirmCancel(false);
                setMessageSuccess('Plano cancelado com sucesso!');
                setShowModalSuccess(true);
                setCurrentCompanySubscriptionPlan(undefined);
            }


        } catch (error) {
            console.log('error', error);
            setMessageError('Ocorreu um erro ao processar a requisição');
            setShowModalConfirmCancel(false);
            setShowModalError(true);
        }
    }, [currentCompanySubscriptionPlan]);

    return (
        <MainPageContentWrapper>
            <ModalError
                setShowModalError={setShowModalError}
                showModalError={showModalError}
                msgError={messageError}
            />

            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
                redirect="/faturas"
            />

            <ModalConfirm
                showModal={showModalConfirmCancel}
                setShowModal={setShowModalConfirmCancel}
                confirmText="Tem certeza que deseja cancelar o seu plano? O cancelamento ocorrerá ao fim do período atual e o acesso à Uninteract ficará bloqueado a partir dessa data! Esta ação não pode ser desfeita!"
                confirmButtonLabel="Confirmar"
                cancelButtonLabel="Fechar"
                onConfirm={cancelPlan}
            />

            {selectedCompanyPlanPayment && (
                <ModalRetryCard
                    setShowModal={setShowModalRetryCard}
                    showModal={showModalRetryCard}
                    companySubscriptionPlanPaymentId={selectedCompanyPlanPayment.id}
                    onSubmitted={handleRetryCardSubmitted}
                />
            )}

            {!!currentCompanySubscriptionPlan && (
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card h-100">
                            <div className="card-body text-center d-flex flex-column justify-content-between">
                                <h5 className="pb-5">Plano Atual</h5>
                                <div>
                                    <p><strong>Nome: </strong> {currentCompanySubscriptionPlan.subscriptionPlan.name}</p>
                                    <p><strong>Valor: </strong> {currentCompanySubscriptionPlan.value ? formatCurrency(currentCompanySubscriptionPlan.value) : <>Gratuito</>}</p>
                                    {currentCompanySubscriptionPlan.expirationDate && (
                                        <p><strong>Expira em: </strong> {formatDate(currentCompanySubscriptionPlan.expirationDate)}</p>
                                    )}
                                </div>
                                {currentCompanySubscriptionPlan.cancelRequested === yesOrNo.NO && !currentCompanySubscriptionPlan.planIdToDowngradeTo ? (
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => setShowModalConfirmCancel(true)}
                                    >
                                        Cancelar plano
                                    </Button>
                                ) : (
                                    <>
                                        {!!currentCompanySubscriptionPlan.planIdToDowngradeTo && (
                                            <Button variant="danger" size="sm" disabled>
                                                Será cancelado em {formatDate(currentCompanySubscriptionPlan.downgradeDate)}
                                            </Button>
                                        )}
                                        {!currentCompanySubscriptionPlan.planIdToDowngradeTo && currentCompanySubscriptionPlan.cancelRequested === yesOrNo.YES && (
                                            <Button variant="danger" size="sm" disabled>
                                                Será cancelado em {formatDate(currentCompanySubscriptionPlan.cancellationDate)}
                                            </Button>
                                        )}
                                    </>
                                )}
                                <Link
                                    to="/plano/pagamento"
                                    className="btn btn-sm btn-secondary mt-3"
                                    onClick={() => setShowModalConfirmCancel(true)}
                                >
                                    Alteração do plano
                                </Link>

                            </div>
                        </div>
                    </div>

                    {!!currentCompanySubscriptionPlan.subscriptionPlanToDowngradeTo && (
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <div className="card-body text-center d-flex flex-column justify-content-between">
                                    <h5 className="pb-5">Novo Plano</h5>
                                    <div>
                                        <p><strong>Nome: </strong> {currentCompanySubscriptionPlan.subscriptionPlanToDowngradeTo.name}</p>
                                        <p><strong>Valor: </strong> {currentCompanySubscriptionPlan.subscriptionPlanToDowngradeTo.value ? formatCurrency(currentCompanySubscriptionPlan.subscriptionPlanToDowngradeTo.value) : <>Gratuito</>}</p>
                                    </div>
                                    {!!currentCompanySubscriptionPlan.planIdToDowngradeTo && (
                                        <Button variant="danger" size="sm" disabled>
                                            Downgrade será aplicado em {formatDate(currentCompanySubscriptionPlan.downgradeDate)}
                                        </Button>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}

                    {lastCompanyPayment && (
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <div className="card-body text-center">
                                    <h5 className="pb-5">Ultima Fatura</h5>
                                    <p><strong>Valor: </strong> {formatIntegerToMoney(lastCompanyPayment.amount, true)}</p>
                                    <p><strong>Competência: </strong> {formatDate(lastCompanyPayment.paymentDate, true)}</p>
                                    <p><strong>Situação: </strong> {CompanySubscriptionPlanPaymentService.getIsPaymentLate(lastCompanyPayment, true) ? 'Atrasado' : getSituationText(lastCompanyPayment.status)}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!currentCompanySubscriptionPlan && (
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card h-100">
                            <div className="card-body text-center d-flex flex-column justify-content-between">
                                <h5 className="pb-5">Sem Plano Vinculado</h5>
                                <Link
                                    className="btn btn-primary btn-sm"
                                    to="plano/pagamento"
                                >
                                    Adquirir Plano
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="row d-flex align-items-center">
                <div className="col-9">
                    <h4>
                        Suas Faturas
                    </h4>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Plano"
                            getOptionLabel={(option: SubscriptionPlan) => option.name}
                            value={planId}
                            onSelect={(option) => setPlanId(Number(option?.id ?? 0))}
                            apiSearchHandler={(typedText) => SubscriptionPlanService.getSubscriptionPlanFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if (!planId) return null;
                                return loadedOptions.find((option) => option.id === planId) ?? SubscriptionPlanService.getSubscriptionPlanById(planId)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Status"
                            margin="normal"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>

                            <MenuItem key="1" value="paid">
                                Pago
                            </MenuItem>

                            <MenuItem key="2" value="pending">
                                Pendente
                            </MenuItem>

                            <MenuItem key="3" value="late">
                                Atrasado
                            </MenuItem>

                            <MenuItem key="4" value="canceled">
                                Cancelado
                            </MenuItem>

                            <MenuItem key="5" value="scheduled">
                                Agendado
                            </MenuItem>

                            <MenuItem key="6" value="failed">
                                Falho
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Forma de Pagamento"
                            margin="normal"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>

                            <MenuItem key="1" value="boleto">
                                Boleto
                            </MenuItem>

                            <MenuItem key="2" value="credit_card">
                                Cartão de Crédito
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Competência"
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={paymentDateMin}
                            onChange={(e) => setPaymentDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Competência"
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={paymentDateMax}
                            onChange={(e) => setPaymentDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMin}
                            onChange={(e) => setDueDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMax}
                            onChange={(e) => setDueDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex mt-6">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    totalCount={countTotalCompanyPlanPayments}
                    sortable={true}
                    loadData={loadData}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    situation
                    customButtons={[
                        {
                            class: 'btn-light-warning',
                            content: (<i className="flaticon2-sheet mr-2"></i>),
                            variant: BsVariant.WARNING,
                            popup: "Visualizar Boleto",
                            showCondition: billetButtonShowCondition,
                            onClick: handleClickBilletButton,
                        },
                        {
                            class: 'btn-info',
                            content: (<i className="flaticon2-reload mr-2"></i>),
                            variant: BsVariant.INFO,
                            popup: "Tentar processar cartão novamente",
                            showCondition: retryCardButtonShowCondition,
                            onClick: handleClickRetryCardButton,
                        },
                    ]}
                    actionsButtonShowCondition={(id) => billetButtonShowCondition(id) || retryCardButtonShowCondition(id)}
                />
            </div>
        </MainPageContentWrapper>
    );
}