import React, { useState } from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { NumericFormat } from '../../components/NumericFormat';
import { useProductsInstallments, Product } from './context';

import '../../style.css';
import { formatToFloat } from '../../utils/formatCurrency';
import ProductSelect from '../../components/ProductSelect';
import { useSelector } from 'react-redux';
import { Product as SelectableProduct } from '../../services/ProductService';

const useStyles = makeStyles(theme => ({
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

interface ListProductsProps {
    index: number;
    product: Product;
    service?: boolean;
    isContent?: any;
    isEdit?: boolean;
    entityId?: number | string;
    onClickAddProduct?: (typedText: string, index: number, product: Product, isService?: boolean) => void;
    changeTabIcon: (previousIcon: string) => void;
};

export const ListProducts: React.FC<ListProductsProps> = ({
    index,
    service,
    product,
    isContent,
    isEdit,
    entityId,
    onClickAddProduct,
    changeTabIcon
}) => {
    const { user } = useSelector((state: any) => state.auth);

    const classes = useStyles();

    const { dispatchProducts, dispatchServices } = useProductsInstallments();
    const [contentError, setContentError] = useState(false);


    const handleChangeProduct = (value: SelectableProduct) => {
        const productObj = typeof value === 'string' ? null : value;
        const productName = typeof value === 'string' ? value : '';

        if (service) {
            dispatchServices({
                type: "CHANGE",
                payload: {
                    index,
                    product: {
                        isLinked: !!productObj,
                        name: productObj?.name ?? productName,
                        amount: 1,
                        INSTANCE: productObj ?? {} as SelectableProduct,
                        totalValue: productObj?.saleValue ?? 0,
                        unitaryValue: productObj?.saleValue ?? 0,
                    },
                }
            });
            changeTabIcon('flaticon-suitcase');
        }

        else {
            dispatchProducts({
                type: "CHANGE",
                payload: {
                    index,
                    product: {
                        isLinked: !!productObj,
                        name: productObj?.name ?? productName,
                        amount: 1,
                        INSTANCE: productObj ?? {} as SelectableProduct,
                        totalValue: productObj?.saleValue ?? 0,
                        unitaryValue: productObj?.saleValue ?? 0,
                    },
                }
            });
            changeTabIcon('flaticon-open-box');
        }
    };

    const handleChangeQuantity = (value: string) => {
        const payload = {
            index,
            product: {
                ...product,
                amount: Number(value),
                totalValue: Number(value) * product.INSTANCE.saleValue,
            },
        };

        if (service) {
            dispatchServices({
                type: "CHANGE",
                payload: payload,
            });
            changeTabIcon('flaticon-suitcase');
        } else {
            dispatchProducts({
                type: "CHANGE",
                payload: payload,
            });
            changeTabIcon('flaticon-open-box');
        }
    };

    const handleChangeValues = (amount: number, unitaryValue: any) => {
        const payload = {
            index,
            product: {
                ...product,
                amount: amount,
                unitaryValue: formatToFloat(unitaryValue),
                totalValue: amount * formatToFloat(unitaryValue),
            }
        };

        if (service) {
            dispatchServices({
                type: "CHANGE",
                payload: payload
            });
            changeTabIcon('flaticon-suitcase');
        } else {
            dispatchProducts({
                type: "CHANGE",
                payload: payload
            });
            changeTabIcon('flaticon-open-box');
        }
    }

    return (
        <div className="row newProductWrapper">
            <Grid item lg={6} md={6} xs={12} className="d-flex align-items-center">
                <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                    <label className="text-white m-0">{index + 1}.</label>
                </div>

                <ProductSelect
                    label={service ? 'Serviço' : 'Produto'}
                    isEdit={isEdit}
                    selectedProduct={product}
                    onChangeProduct={handleChangeProduct}
                    entityType="service"
                    entityId={entityId}
                    type={service ? 'service' : 'product'}
                    disabled={user.isAccountant == "y" ? true : false}
                    hasError={product.hasError}
                    allowIncomplete={true}
                    onClickAddProduct={onClickAddProduct ? (typedText) => onClickAddProduct(typedText, index, product, service) : undefined}
                />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
                <TextField
                    label={service ? 'Horas/Qtde.' : 'Qtde.'}
                    type="number"
                    margin="normal"
                    size="small"
                    value={product.amount}
                    onChange={
                        (evt) => handleChangeValues(Number(evt.target.value), product.unitaryValue)
                    }
                    disabled={user.isAccountant == "y" ? true : false}
                />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
                <NumericFormat
                    label="Valor unitário"
                    value={product.unitaryValue}
                    onChange={
                        (evt) => handleChangeValues(Number(product.amount), evt.target.value)
                    }
                    disabled={user.isAccountant == "y" ? true : false}
                />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
                <NumericFormat
                    label="Valor total"
                    disabled
                    value={product.totalValue}
                />
            </Grid>

            {
                (index > 0 && user.isAccountant == "n") && (
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => service ? dispatchServices({
                            type: "DELETE",
                            payload: {
                                index
                            }
                        }) : dispatchProducts({
                            type: "DELETE",
                            payload: {
                                index
                            }
                        })}
                    >
                        <i className="flaticon-delete"></i>
                    </button>
                )
            }
        </div>
    );
};