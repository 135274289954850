import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/Api';
import { User } from '../../types/User';
import { bypassAuthHeader } from '../../utils/bypassAuthHeader';
import { AuthPageContentWrapper } from '../../components/AuthPageContentWrapper';

export default function NewPassword() {
    const { idUser, companyId } = useParams<{ idUser: string, companyId: string }>();
    const history = useHistory();

    const [userEmail, setUserEmail] = useState('');

    // Inputs
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    // Errors
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState("");


    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function verifyUserPassword() {
            const { data: user } = await api.get<User>(`/users/${idUser}`, {
                headers: bypassAuthHeader,
            });

            const { data: userHasPassword } = await api.get<User>(`/users/${idUser}/password`, {
                headers: bypassAuthHeader,
            });

            if (userHasPassword) {
                window.location.href = "/auth/login";
            }

            setUserEmail(user.email);
        }

        verifyUserPassword();
    }, [history, idUser]);

    function inputsVerify() {
        if (!password) {
            setPasswordError(true);
            setPasswordErrorMessage("Por favor, Informe sua senha!");

            return false;
        }

        if (!passwordConfirm) {
            setPasswordConfirmError(true);
            setPasswordConfirmErrorMessage("Por favor, confirme sua senha!");

            return false;
        }

        if (passwordConfirm != password) {
            setPasswordConfirmError(true);
            setPasswordConfirmErrorMessage("Por favor, repita sua senha corretamente!");

            return false;
        }

        return true;
    }

    async function onSubmit() {
        if (!(inputsVerify())) return;

        setIsLoading(true);

        try {
            await api.put(`/users/${idUser}`, { password, companyId }, {
                headers: bypassAuthHeader,
            });
            setIsLoading(false);

            history.push("/auth/login");
        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);
    }

    return (
        <AuthPageContentWrapper>
            <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
                <div className="login-form login-signin" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">Criar senha</h3>
                        <p className="text-muted font-weight-bold">Digite a sua senha para continuar</p>
                    </div>

                    <div
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {/* begin: Alert */}
                        {/* {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                            )} */}
                        {/* end: Alert */}
                        {/* begin: Success */}
                        {/* {successMessage && (
                            <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
                                <div className="alert-text font-weight-bold">{successMessage}</div>
                            </div>
                            )} */}
                        {/* end: Alert */}

                        <div className="form-group">
                            <label>Seu email de acesso: (você poderá mudar isso no seu perfil)</label>
                            <div className="input-icon">
                                <span><i className="flaticon2-email icon"></i></span>
                                <input
                                    placeholder="Email"
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-3 pr-6`}
                                    name="email"
                                    value={userEmail}
                                    disabled
                                />
                            </div>
                        </div>

                        {/* begin: Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <div className="input-icon">
                                <span><i className="flaticon2-lock icon"></i></span>
                                <input
                                    placeholder="Senha"
                                    type="password"
                                    className={`form-control form-control-solid h-auto py-3 pr-6 ${passwordError ? 'is-invalid' : ''}`}
                                    name="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError(e.target.value ? false : true);
                                    }}
                                />
                            </div>
                            {passwordError ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{passwordErrorMessage}</div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Password */}

                        {/* begin: Confirm Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <div className="input-icon">
                                <span><i className="flaticon2-lock icon"></i></span>
                                <input
                                    placeholder="Confirmar Senha"
                                    type="password"
                                    className={`form-control form-control-solid h-auto py-3 pr-6 ${passwordConfirmError ? 'is-invalid' : ''}`}
                                    name="changepassword"
                                    value={passwordConfirm}
                                    onChange={(e) => {
                                        setPasswordConfirm(e.target.value);
                                        setPasswordConfirmError(e.target.value ? false : true);
                                    }}
                                />
                            </div>
                            {passwordConfirmError ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {passwordConfirmErrorMessage}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Confirm Password */}

                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                type="button"
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 w-100"
                                onClick={onSubmit}
                            >
                                <span>Enviar</span>
                                {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AuthPageContentWrapper>
    );
}