import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { useHeaderLayoutProps } from "../../hooks/useHeaderLayoutProps";

export function Header({ notificationsOpen, setNotificationsOpen }) {
  const layoutProps = useHeaderLayoutProps();

  const styleLogo = {
      maxWidth: '160px'
  };

  return (
    <>
      <div className="header-gradient"></div>
      {/*begin::Header*/}
      <div className="header header-fixed d-flex justify-content-between container-fluid"
          style={{ height: "auto" }}
        >
        <div className="header-logo">
          <Link to="/">
            <img
              className="logo-default position-relative"
              style={styleLogo}
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/header/logo-header-gold-alt.png")}
            />
            <img
              className="logo-sticky max-h-40px"
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/header/logo-header-black.png")}
            />
          </Link>
        </div>
        <Topbar />
      </div>
      <div
        className={`header header-fixed ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
        style={{ borderBottom: "none" }}
      >
        {/*begin::Container*/}
        <div
          className={`${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-center`}
        >
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            {/* end::Header Logo */}
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper notificationsOpen={notificationsOpen} setNotificationsOpen={setNotificationsOpen} />}
          </div>
          {/* end::Left */}

          {/*begin::Topbar*/}
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
