import React, { useEffect, useState } from 'react';

import '../../../style.css';
import ModalError from '../../../components/ModalError';
import { useParams } from 'react-router';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Product as SelectableProduct } from '../../../services/ProductService';
import { Situation } from '../../../types/Situation';
import { format } from 'date-fns';
import { ListConsignmentProducts } from './ListConsignmentProducts';
import { TextField } from '@material-ui/core';
import LogService from '../../../services/LogService';
import { SellerConsignmentProduct, SellerConsignmentStatus } from '../../../types/SellerConsignment';
import api from '../../../services/Api';
import { SellerConsignment } from '../../../types/SellerConsignment';
import { extractDateStringFromTimestamp } from '../../../utils/dateTimeHelper';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import ModalSuccess from '../../../components/ModalSuccess';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import SellerService from '../../../services/SellerService';
import StockService from '../../../services/StockService';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type AvailableTabs = 'products' | 'details' | 'history';

export function NewConsignment() {
    const [activeTab, setActiveTab] = useState<AvailableTabs>('products');

    //Fields
    const [description, setDescription] = useState('');
    const [products, setProducts] = useState<SellerConsignmentProduct[]>([{
        id: 0,
        productId: 0,
        amount: 0,
        stockLocationId: 0,
        INSTANCE: {} as SelectableProduct,
    }]);
    const [startDate, setStartDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [notes, setNotes] = useState('');
    const [totalValueProducts, setTotalValueProducts] = useState('0,00');
    const [totalQuantityProducts, setTotalQuantityProducts] = useState('0,00');

    const [startDateInvalid, setStartDateInvalid] = useState(false);
    const [finalDateInvalid, setFinalDateInvalid] = useState(false);

    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState('');
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const { sellerId, idToClone } = useParams<{ sellerId: string, idToClone: string }>();

    useEffect(() => {
        if (!idToClone) return;

        async function getConsignmentToCloneData() {
            const { data: consignmentToClone } = await api.get<SellerConsignment>(`/sellerConsignment/${idToClone}`);

            if (!consignmentToClone) return;

            setDescription(consignmentToClone.description);
            setNotes(consignmentToClone.notes ?? '');
            setStartDate(consignmentToClone.startDate ? extractDateStringFromTimestamp(String(consignmentToClone.startDate)) : '');
            setFinalDate(consignmentToClone.finalDate ? extractDateStringFromTimestamp(String(consignmentToClone.finalDate)) : '');
            setProducts(consignmentToClone.products ? JSON.parse(consignmentToClone.products) : [{
                id: 0,
                productId: 0,
                amount: 0,
                stockLocationId: 0,
                INSTANCE: {} as SelectableProduct,
            }]);
        }

        getConsignmentToCloneData();
    }, [idToClone]);

    useEffect(() => {
        function calculateTotals() {
            let totalValue = 0;
            let totalAmount = 0;

            for (const product of products) {
                totalValue += product.INSTANCE.saleValue * product.amount;
                totalAmount += product.amount;
            }

            setTotalValueProducts(formatCurrency(totalValue));
            setTotalQuantityProducts(formatCurrency(totalAmount));
        }

        calculateTotals();
    }, [products])

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            setIsSubmit(true);

            const productsAreValid = await productsVerify();

            if (!inputsVerify() || !productsAreValid) {
                setIsSubmit(false);
                setShowModalError(true);
                return;
            }

            const consignmentProductsFiltered = products.filter(productObj => productObj.productId !== 0);
            const consignmentProducts = consignmentProductsFiltered.map((productObj, index) => ({
                ...productObj,
                id: index + 1,
            }));

            const situation: Situation[] = [];

            situation.push({
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                commentsSituation: "",
                statusSituation: SellerConsignmentStatus.pending
            });

            const raw = {
                sellerId: Number(sellerId),
                description,
                notes: notes || null,
                startDate: startDate ? new Date(startDate) : null,
                finalDate: finalDate ? new Date(finalDate) : null,
                totalValueProducts: formatToFloat(totalValueProducts),
                totalQuantityProducts: formatToFloat(totalQuantityProducts),
                products: JSON.stringify(consignmentProducts),
                situation: JSON.stringify(situation),
                status: SellerConsignmentStatus.pending,
            };

            const createResponse = await api.post('/sellerConsignment', raw);

            LogService.logRegister({
                itemId: createResponse.data.id,
                module: 'Solicitações de Consignação',
                itemName: createResponse.data.description || 'Consignação'
            });

            setShowModalSuccess(true);
        } catch (error) {
            console.log(error);
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao processar a requisição');
        }

        setIsSubmit(false);
    };

    function inputsVerify() {
        setStartDateInvalid(false);
        setFinalDateInvalid(false);

        if (!sellerId) {
            setMsgError('Impossível criar solicitação de consignação sem vendedor vinculado!');
            return false;
        }

        if (!startDate) {
            setActiveTab("details");
            setMsgError('Informe a data inicial!');
            setStartDateInvalid(true);
            return false;
        }

        if (!finalDate) {
            setActiveTab("details");
            setMsgError('Informe o data final!');
            setFinalDateInvalid(true);
            return false;
        }

        const startDateTimestamp = new Date(startDate).getTime();
        const finalDateTimestamp = new Date(finalDate).getTime();

        if (startDateTimestamp > finalDateTimestamp) {
            setActiveTab("details");
            setMsgError('A data inicial não pode ser maior que a data final!');
            setStartDateInvalid(true);
            return false;
        }

        return true;
    }

    async function productsVerify() {
        if (!products || products.length === 0) {
            setActiveTab("products");
            setMsgError('Informe os itens da consignação!');
            return false;
        }

        const hasSelectedProduct = products.find((productObj) => productObj.productId !== 0);
        if (!hasSelectedProduct) {
            setActiveTab("products");
            setMsgError('Informe os itens da consignação!');
            return false;
        }

        // verificando dados de cada produto selecionado
        for (const product of products) {
            if (product.amount <= 0) {
                setActiveTab("products");
                setMsgError('Informe a quantidade para todos os itens!');
                return false;
            }

            if (!product.stockLocationId) {
                setActiveTab("products");
                setMsgError('Informe o local do estoque para todos os itens!');
                return false;
            }

            // Validando se tem saldo suficiente no estoque
            let totalInStock = 0;
            if (product.INSTANCE.type === 'variation' && product.INSTANCE.originalProductId) {
                const stockResponse = await StockService.getTotalLocalStock(product.INSTANCE.originalProductId, product.stockLocationId, product.INSTANCE.id);
                totalInStock = stockResponse.totalStock;
            } else {
                const stockResponse = await StockService.getTotalLocalStock(product.INSTANCE.id, product.stockLocationId);
                totalInStock = stockResponse.totalStock;
            }

            if ((totalInStock - product.amount) < 0) {
                setActiveTab("products");
                setMsgError(`O local de estoque informado para o produto "${product.INSTANCE.name}" não possui saldo suficiente para a solicitiação de consignação!`);
                return false;
            }
        }

        return true;
    }

    return (
        <MainPageContentWrapper>
            <div className="card card-custom gutter-b">
                <ModalError
                    showModalError={showModalError}
                    setShowModalError={setShowModalError}
                    msgError={msgError}
                />
                <ModalSuccess
                    showModal={showModalSuccess}
                    setShowModal={setShowModalSuccess}
                    msgModal="Solicitação de consignação criada com sucesso!"
                    redirect={`/vendedores/${sellerId}/consignacoes`}
                />
                <form onSubmit={onSubmit}>
                    <div className="card-body mt-0 pt-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <TextField
                                    size="small"
                                    label="Descrição"
                                    margin="normal"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={sellerId}
                                    onSelect={(option) => { }}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!sellerId) return null;
                                        return loadedOptions.find((option) => option.id === Number(sellerId)) ?? SellerService.getSellerById(sellerId)
                                    }}
                                    disabled
                                />
                            </div>
                            <div className='col-lg-2 d-flex align-items-end justify-content-end'>
                                <Button
                                    type='submit'
                                    className='mb-2'
                                    variant="primary"
                                    disabled={isSubmit}
                                >
                                    {isSubmit ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>
                                        </>
                                        : <span>Salvar</span>
                                    }
                                </Button>
                            </div>
                        </div>

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab as AvailableTabs)} id='form-tabs' className="mb-5">
                            <Tab eventKey='products' title='Produtos'>
                                <ListConsignmentProducts
                                    consignmentProducts={products}
                                    setConsignmentProducts={setProducts}
                                />
                            </Tab>
                            <Tab eventKey='details' title='Detalhes'>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <TextField
                                            size="small"
                                            type='date'
                                            margin="normal"
                                            label="Data inicial *"
                                            InputLabelProps={{ shrink: true }}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            error={startDateInvalid}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <TextField
                                            size="small"
                                            type='date'
                                            margin="normal"
                                            label="Data final *"
                                            InputLabelProps={{ shrink: true }}
                                            value={finalDate}
                                            onChange={(e) => setFinalDate(e.target.value)}
                                            error={finalDateInvalid}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <TextField
                                            size="small"
                                            rows={6}
                                            multiline
                                            margin="normal"
                                            label="Observações"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>

                </form>
            </div>
        </MainPageContentWrapper>
    );
}