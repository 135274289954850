import { CompanySubscriptionPlanPayment, invoicePaymentMethod, invoiceStatus } from "../types/CompanySubscriptionPlanPayment";
import api from "./Api";
import SuperConfigService from "./SuperConfigService";

class CompanySubscriptionPlanPaymentService {
    async getLastByCompanyId(companyId: number | string) {
        const response = await api.get(`/companySubscriptionPlanPayment/company/last/${companyId}`);
        return response.data;
    }

    getPaymentMethodText(paymentMethod: invoicePaymentMethod) {
        switch (paymentMethod) {
            case invoicePaymentMethod.boleto:
                return 'Boleto';
            case invoicePaymentMethod.credit_card:
                return 'Cartão de Crédito';
            case invoicePaymentMethod.cash:
                return 'Dinheiro';
        
            default:
                return 'N/A';
        }
    }

    async getDefaultPendingPaymentMessage() {
        const superConfig = await SuperConfigService.getConfig();

        return superConfig.defaultMsgSubscriptionPlanPaymentPending;
    }

    getIsPaymentLate(payment: CompanySubscriptionPlanPayment, onlyPendingPayments: boolean = false) {
        const today = new Date();
        const dueDate = new Date(payment.dueDate);
        
        if (onlyPendingPayments && payment.status !== invoiceStatus.pending) {
            return false;
        }

        return dueDate.getTime() <= today.getTime() && (payment.status === invoiceStatus.pending || payment.status === invoiceStatus.failed);
    }
}

export default new CompanySubscriptionPlanPaymentService();