import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Product } from '../../../types/Product';
import ProductService from '../../../services/ProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportSoldProductsResponse = {
    rows: Product[];
    count: number;
    totalQuantitySold: number;
    totalValueSold: number;
    totalGrossWeight: number;
    totalLiquidColumn: number;
}

type Filters = {
    productInput: string;
    customerInput: string;
    typeInput: string;
    dateMin: string;
    dateMax: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "name", value: "Produto" },
    { reference: "code", value: "Código" },
    { reference: "currentStock", value: "Estoque Atual" },
    { reference: "quantitySold", value: "Qtde. Vendido" },
    { reference: "soldValue", value: "Valor Vendido" },
    { reference: "averageSaleValue", value: "Valor Médio Venda" },
];

export function ListReportSoldProducts() {
    const [foundProducts, setFoundProducts] = useState<any[]>([]);
    const [countProductsPaginated, setCountProductsPaginated] = useState(0);
    const [countProducts, setCountProducts] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [customerInput, setCustomerInput] = useState('');
    const [productInput, setProductInput] = useState('');
    const [typeInput, setTypeInput] = useState('');
    const [dateMin, setDateMin] = useState('');
    const [dateMax, setDateMax] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [uTotalSale, setUTotalSale] = useState<any>();
    const [uTotalSold, setUTotalSold] = useState<any>();
    const [uTotalLiquidColumn, setUTotalLiquidColumn] = useState<any>();
    const [uTotalGrossWeight, setUTotalGrossWeight] = useState<any>();
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportSoldProductsResponse>("/report/soldProducts", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, totalValueSold, totalQuantitySold, totalGrossWeight, totalLiquidColumn} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell: any) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        });

        setExportTotals([
            { name: 'Total de produtos', value: count },
            { name: 'Total de peso bruto', value: totalGrossWeight },
            { name: 'Total de peso Líquido', value: totalLiquidColumn },
            { name: 'Quantidade vendida', value: formatNumberToString(formatToFloat(totalQuantitySold)) },
            { name: 'Valor total vendido', value: 'R$ ' + formatNumberToString(formatToFloat(totalValueSold)) },
        ]);

        setUTotalGrossWeight(totalGrossWeight);
        setUTotalLiquidColumn(totalLiquidColumn);
        setUTotalSale(totalValueSold);
        setUTotalSold(totalQuantitySold);

        setExportBodyData(exportDataList);
        setCountProducts(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportSoldProductsResponse>("/report/soldProducts", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundProducts(rows);
        setCountProductsPaginated(count);
        setLastSortDirection(sortDirection);
        setLastSortReference(sortReference);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundProducts;

        aux.forEach((product) => {
            const data = mountBodyDataRow(product);

            list.push(data);
        });

        setBodyData(list);
    }, [foundProducts]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: any) {
        if (!reportRow) return [];

        const data: BodyDataBaseProps[] = [
            { for: "name", value: reportRow.name ?? ''},
            { for: "code", value: reportRow.code ?? ''},
            { for: "currentStock", value: formatNumberToString(reportRow.currentStock) ?? '' },
            { for: "quantitySold", value: formatNumberToString(reportRow.quantitySold) ?? ''},
            { for: "soldValue", value: formatNumberToString(reportRow.soldValue) ?? ''},
            { for: "averageSaleValue", value: formatNumberToString(reportRow.averageSaleValue) ?? '' },
        ];

        return data;
    }

    const clearSearch = () => {
        setDateMin('');
        setDateMax('');
        setProductInput('');
        setCustomerInput('');
        setTypeInput('');
    };

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            productInput,
            customerInput,
            typeInput,
            dateMin,
            dateMax
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        productInput,
        customerInput,
        typeInput,
        dateMin,
        dateMax
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Produtos mais vendidos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={productInput}
                        onSelect={(option) => setProductInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>
                
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerInput}
                        onSelect={(option) => setCustomerInput(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customerInput) return null;
                            return loadedOptions.find((option) => option.id === Number(customerInput)) ?? CustomerService.getCustomerById(customerInput)
                        }}
                    />
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <TextField
                        type="Date"
                        label="Período - Min"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMin}
                        onChange={(e: any) => setDateMin(e.target.value)}
                    />

                    <TextField
                        type="Date"
                        label="Período - Máx"
                        margin="normal"
                        size="small"
                        className="ms-20"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMax}
                        onChange={(e: any) => setDateMax(e.target.value)}
                    />

                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo"
                        margin="normal"
                        value={typeInput}
                    >
                        <MenuItem key="0" value="all" onClick={() => setTypeInput('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="nfe" onClick={() => setTypeInput('nfe')}>
                            Nota Fiscal
                        </MenuItem>

                        <MenuItem key="2" value="requests" onClick={() => setTypeInput('requests')}>
                            Pedidos
                        </MenuItem>

                        <MenuItem key="3" value="budget" onClick={() => setTypeInput('budget')}>
                            Orçamento
                        </MenuItem>

                        <MenuItem key="4" value="pdv" onClick={() => setTypeInput('pdv')}>
                            PDV
                        </MenuItem>

                        <MenuItem key="5" value="nfce" onClick={() => setTypeInput('nfce')}>
                            Nota Fiscal do Consumidor
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Produtos mais Vendidos"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Produtos mais vendidos', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={countProductsPaginated}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                    />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de produtos:</TableCell>
                                    <TableCell colSpan={1}>{countProducts}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Total peso bruto:</TableCell>
                                    <TableCell colSpan={1}>{formatNumberToString(uTotalGrossWeight)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Total peso líquido:</TableCell>
                                    <TableCell colSpan={1}>{formatNumberToString(uTotalLiquidColumn)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade vendida:</TableCell>
                                    <TableCell colSpan={1}>{formatNumberToString(uTotalSold)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total vendido:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(uTotalSale)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}