import React, { useEffect, useState, useLayoutEffect, useCallback } from 'react';

import { formatCurrency, formatIntegerToMoney, formatToFloat } from '../../utils/formatCurrency';
import '../../style.css';

import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../hooks/backendReload';
import api from '../../services/Api';
import { getDate } from '../../utils/dateTimeHelper';
import { formatDate } from '../../utils/dateFormat';
import { BsVariant } from '../../types/BsVariant';
import NotificationService from '../../services/NotificationService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: 'Nº' },
    { reference: "date", value: 'Data' },
    { reference: "title", value: 'Notificação' },
];

export function ListNotifications() {
    const [notifications, setNotifications] = useState<any[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalNotifications, setCountTotalNotifications] = useState(0);
    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const history = useHistory();
    const { user } = useSelector((state: any) => state.auth);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { rows, count } = await NotificationService.getNotifications({
            pagination: {
                rowsPerPage,
                currentPage,
                sortReference,
                sortDirection,
            },
        })
        // const { data } = await api.get<{ rows: any[], count: number }>("notifications", {
        //     params: {
        //         skip: rowsPerPage * currentPage,
        //         take: rowsPerPage,
        //         sortReference,
        //         sortDirection,
        //     }
        // });

        // const { rows, count } = data;

        setNotifications(rows);
        setCountTotalNotifications(count);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = notifications;

        aux.forEach((notification) => {
            const notificationDate = getDate({initialDate: new Date(notification.updatedAt)}).fullDateStr;

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(notification.id), id: true },
                { for: "date", value: notificationDate },
                { for: "title", value: notification.title, limitText: false },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [notifications]);

    const handleClickLink = useCallback((id: string) => {
        const clickedNotification = notifications.find((notification) => notification.id === Number(id));
        history.push(clickedNotification.link);
    }, [notifications, history]);

    const handleClickDismiss = useCallback((id: string) => {
        const notificationId = Number(id);

        NotificationService.dismissNotification(notificationId)
        ;
        setNotifications((prevState) => {
            return prevState.filter((notification) => notification.id !== notificationId);
        });
        setCountTotalNotifications((prevState) => prevState - 1);

        reloadData();
    }, []);

    return (
        <MainPageContentWrapper>
            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalNotifications}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    defaultSortReference={'date'}
                    customButtons={
                        user.isAccountant == "n"
                        ?
                        [
                            {
                                class: 'btn-light-info',
                                content: (<i className='flaticon-eye mr-2'></i>),
                                variant: BsVariant.INFO,
                                popup: "Visualizar",
                                onClick: handleClickLink,
                            },
                            {
                                class: 'btn-light-danger',
                                content: (<i className='flaticon-close mr-2'></i>),
                                variant: BsVariant.DANGER,
                                popup: "Ocultar",
                                onClick: handleClickDismiss,
                            },
                        ]
                        : [
                            {
                                class: 'btn-light-info',
                                content: (<i className='p-0 flaticon-eye'></i>),
                                variant: BsVariant.INFO,
                                popup: "Visualizar",
                                onClick: handleClickLink,
                            }
                        ]
                    }
                />
            </div>
        </MainPageContentWrapper>
    );
}