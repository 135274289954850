import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import { getEnabledStatusText } from '../../../utils/getEnabledStatusText';
import { NumericFormat } from '../../../components/NumericFormat';
import { Product } from '../../../types/Product';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ProductService from '../../../services/ProductService';
import { CategoryProduct } from '../../../hooks/products';
import CategoryProductService from '../../../services/CategoryProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportStockResponse = {
    rows: Product[];
    count: number;
    currentStockTotal: number;
    currentStockCostValue: number;
    currentStockSaleValue: number;
}

type Filters = {
    format: string;
    location: string;
    situation: string;
    productInput: string;
    showTypeProducts: string;
    saleValueMin: string;
    saleValueMax: string;
    status: string;
    category: string;
    provider: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "ID" },
    { reference: "product", value: "Produto" },
    { reference: "code", value: "Código" },
    { reference: "saleValue", value: "Valor Produto" },
    { reference: "currentStock", value: "Estoque Final" },
    { reference: "", value: "Valor Total Venda" },
    { reference: "situation", value: "Status" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "total", value: "Valor Total" },
];

export function ListReportBalanceStock() {
    const [foundProductApi, setProductApi] = useState<Product[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalFoundProducts, setCountTotalFoundProducts] = useState(0);

    const [isSearching, setIsSearching] = useState(false);
    const [productInput, setProductInput] = useState<any>('');

    const [location, setLocation] = useState('');
    const [category, setCategory] = useState('');
    const [provider, setProvider] = useState('');
    const [format, setFormat] = useState('detail');
    const [showTypeProducts, setShowTypeProducts] = useState('all');
    const [status, setStatus] = useState('all');
    const [saleValueMin, setSaleValueMin] = useState('');
    const [saleValueMax, setSaleValueMax] = useState('');

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [situation, setSituation] = useState('all');
    const [uTotalCost, setUTotalCost] = useState(0);
    const [uTotalSale, setUTotalSale] = useState(0);
    const [uTotalStock, setUTotalStock] = useState(0);
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportStockResponse>("/report/balanceStock", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, currentStockTotal, currentStockCostValue, currentStockSaleValue} = data;

        const exportDataList: ReportDataRow[] = [];
        let exportProductsData: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        })

        setExportTotals([
            { name: 'Estoque atual', value: currentStockTotal },
            { name: 'Valor estoque custo atual', value: formatCurrency(currentStockCostValue) },
            { name: 'Valor estoque venda atual', value: formatCurrency(currentStockSaleValue) },
        ]);

        setExportBodyData(exportDataList);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportStockResponse>("/report/balanceStock", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, currentStockTotal, currentStockCostValue, currentStockSaleValue} = data;

        setUTotalStock(currentStockTotal);
        setUTotalCost(currentStockCostValue);
        setUTotalSale(currentStockSaleValue);
        setProductApi(rows);
        setCountTotalFoundProducts(count);

        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundProductApi;

        aux.forEach((productApi) => {
            const data = mountBodyDataRow(productApi);

            list.push(data);
        });
        
        setBodyData(list);
    }, [foundProductApi]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Product) {
        // Valor de acordo com o Estoque: 
        const totalProductSale = formatToFloat(reportRow.saleValue);
        const inStock = formatToFloat(reportRow.currentStock);

        const name = reportRow.name !== null && reportRow.name !== '' ? String(reportRow.name) : ' - ';
        const code = reportRow.code !== null && reportRow.code !== '' ? String(reportRow.code) : ' - ';
        const situation = reportRow.situation || reportRow.situation !== null ? getEnabledStatusText(reportRow.situation) : ' - ';
        const saleValue = reportRow.saleValue !== null && reportRow.saleValue !== '' ? 'R$ ' + reportRow.saleValue : ' - ';
        const saleTotal = totalProductSale * inStock;
        const currentStock = reportRow.currentStock || reportRow.currentStock !== null ? Number(reportRow.currentStock) : ' - ';

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id) },
            { for: "product", value: name },
            { for: "code", value: code, },
            { for: "saleValue", value: saleValue },
            { for: "currentStock", value: String(currentStock) },
            { for: "saleTotal", value: formatCurrency(saleTotal) },
            { for: "situation", value: situation },
        ];

        return data;
    }

    const clearSearch = () => {
        setFormat('detail');
        setLocation('')
        setSituation('all');
        setStatus('all');
        setProductInput('');
        setCategory('');
        setShowTypeProducts('all');
        setProvider('');
        setSaleValueMax('');
        setSaleValueMin('');
    };

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            format,
            location,
            situation,
            productInput: productInput || 'all',
            showTypeProducts,
            saleValueMin,
            saleValueMax,
            status,
            category,
            provider,
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);

    }, [
        format,
        location,
        situation,
        productInput,
        showTypeProducts,
        saleValueMin,
        saleValueMax,
        status,
        category,
        provider,
        lastSortDirection,
        lastSortReference,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Estoque-Saldo', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={productInput}
                        onSelect={(option) => setProductInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Exibir produtos"
                        margin="normal"
                        value={showTypeProducts}         
                    >
                    
                        <MenuItem key="0" value="all" onClick={() => setShowTypeProducts('all')}>
                            Todos
                        </MenuItem>
                    
                        <MenuItem key="1" value="inStock" onClick={() => setShowTypeProducts('inStock')}>
                            Somente em estoque
                        </MenuItem>

                        <MenuItem key="2" value="stockMin" onClick={() => setShowTypeProducts('stockMin')}>
                            Abaixo do estoque mínimo
                        </MenuItem>

                        <MenuItem key="3" value="stockMax" onClick={() => setShowTypeProducts('stockMax')}>
                            Acima do estoque máximo
                        </MenuItem>

                        <MenuItem key="4" value="noStock" onClick={() => setShowTypeProducts('noStock')}>
                            Sem estoque
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Status"
                        margin="normal"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="active" onClick={() => setStatus('active')}>
                            Ativo
                        </MenuItem>

                        <MenuItem key="2" value="inactive" onClick={() => setStatus('inactive')}>
                            Inativo
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Categorias"
                        getOptionLabel={(option: CategoryProduct) => option.nameCategory}
                        value={category}
                        onSelect={(option) => setCategory(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CategoryProductService.getCategoriesFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!category) return null;
                            return loadedOptions.find((option) => option.id === Number(category)) ?? CategoryProductService.getCategoryById(category)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Fornecedor"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={provider}
                        onSelect={(option) => setProvider(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!provider) return null;
                            return loadedOptions.find((option) => option.id === Number(provider)) ?? CustomerService.getCustomerById(provider)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Localização"
                        margin="normal"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <NumericFormat
                        label="Valor de venda Min"
                        className="inputSmaller"
                        withPrefix={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={saleValueMin}
                        onChange={(e: any) => setSaleValueMin(e.target.value)}
                    />

                    <NumericFormat
                        label="Valor de venda Max"
                        className="inputSmaller ms-20"
                        withPrefix={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={saleValueMax}
                        onChange={(e: any) => setSaleValueMax(e.target.value)}
                    />

                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Formato"
                        margin="normal"
                        value={format}
                    >
                        <MenuItem key="0" value="detail" onClick={() => setFormat('detail')}>
                            Detalhado
                        </MenuItem>

                        <MenuItem key="1" value="active" onClick={() => setFormat('active')}>
                            Resumido
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Saldo do Estoque"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Estoque-Saldo', extension: 'pdf' })}
                    >
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                        >
                            <i className="flaticon2-printer"></i>
                            Exportar PDF
                        </Button>
                    </PDFDownloadLink>
                    <Button
                        variant="secondary"
                        className="ml-3"
                        type="button"
                        onClick={handleClickExportExcel}
                    >
                        <i className="flaticon2-sheet"></i>
                        Exportar Excel
                    </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                {format === 'detail' ? (
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={countTotalFoundProducts}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                    />
                ) : ''
            }
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Estoque atual:</TableCell>
                                    <TableCell colSpan={1}>{uTotalStock}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor estoque custo atual:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotalCost) }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor estoque venda atual:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotalSale) }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}