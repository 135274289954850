import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { format, parseISO, isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { orderByIdDesc } from '../../utils/orderTable';

import api from '../../services/Api';
import {
    ObjectOrder,
    OrdersSituation,
    HeadDataBaseProps,
    BodyDataBaseProps,
    OnThrowToBillsData,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { ServiceOrderData } from '../../types/ServiceOrderData';
import { Card, Collapse, InputAdornment, MenuItem, Paper, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Search } from '../../components/Search';
import '../../style.css';
import { ServiceContractData } from '../../types/ServiceContractData';
import customerService from '../../services/CustomerService';
import { dateIsBetweenRange, extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import ModalChangeStatus, { StatusHistory } from '../../components/ModalChangeStatus';
import { BsVariant } from '../../types/BsVariant';
import { status } from '../Config/CompanyData/ManageStatus';
import useBackendLoad from '../../hooks/backendReload';
import { getSituationFromText, getSituationText } from '../../utils/getSituationText';
import NotificationService from '../../services/NotificationService';
import ServiceContractService from '../../services/ServiceContractService';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import useQueryParams from '../../hooks/queryParams';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import axios from 'axios';
import { Area, AreaChart, Cell, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';

type Filters = {
    searchQuery: string;
    situation: string;
    initialNextPaymentDate: string;
    finalNextPaymentDate: string;
    initialNextGenerateDate: string;
    finalNextGenerateDate: string;
    minValue: number;
    maxValue: number;
    bankAccount: number;
}

type ServiceContractStatusChart = {
    status: string,
    quantity: number,
}

type ServiceContractBillStatusTotalhart = {
    status: string,
    value: number
}

const backgroundColors: { [index: string]: string } = {
    'attended': 'bg-light-primary',
    'open': 'bg-light-secondary',
    'custom': 'bg-light-warning',
    'canceled': 'bg-light-danger',
    'progress': 'bg-light-warning',
}

const textColors: { [index: string]: string } = {
    'attended': '#2E93B9',
    'open': '#181C32',
    'custom': '#FFA800',
    'canceled': '#F64E60',
    'progress': '#FFA800',
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'description', value: "Descrição" },
    { reference: 'client', value: "Cliente" },
    { reference: 'nextPaymentDate', value: "Próximo Vencimento" },
    { reference: 'generateDate', value: "Próxima Geração", notSortable: true },
    { reference: 'finalDate', value: "Data de Vencimento do Contrato" },
    { reference: 'amount', value: "Valor Total" },
    { reference: 'situation', value: "Situação", situation: true, notSortable: true },
];

export function ListServiceContract() {
    const { user } = useSelector((state: any) => state.auth);

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [serviceContracts, setServiceContracts] = useState<ServiceContractData[]>([]);
    const [allStatus, setAllStatus] = useState<status[]>([]);
    const [countTotalServiceContracts, setCountTotalServiceContracts] = useState(0);

    //Modal alterar status
    const [idChangeStatus, setIdChangeStatus] = useState(0);
    const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [initialNextPaymentDate, setInitialNextPaymentDate] = useState("");
    const [finalNextPaymentDate, setFinalNextPaymentDate] = useState("");
    const [initialNextGenerateDate, setInitialNextGenerateDate] = useState("");
    const [finalNextGenerateDate, setFinalNextGenerateDate] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [bankAccount, setBankAccount] = useState(0);
    const [searchRequested, setSearchRequested] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const [serviceContractStatus, setServiceContractStatus] = useState<ServiceContractStatusChart[]>();
    const [serviceContractStatusTotal, setServiceContractStatusTotal] = useState<ServiceContractBillStatusTotalhart[]>();

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { getQueryParam } = useQueryParams();

    // useEffect(() => {
    //     reloadData();
    // }, [serviceContracts]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: ServiceContractData[], count: number }>("serviceContract", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        for (const contract of rows) {
            contract.customerName = customerService.getCustomerName(contract.customerEntity);

            const generateDateObj = new Date(`${extractDateStringFromTimestamp(contract.nextPaymentDate)} 00:00:00`);
            generateDateObj.setDate(generateDateObj.getDate() - Number(contract.daysBeforeExpire));
            contract.nextGenerateDate = extractDateStringFromTimestamp(generateDateObj.toISOString());
        }

        setServiceContracts(rows);
        setCountTotalServiceContracts(count);
    }, []);


    useEffect(() => {
        getAllStatus();
    }, []);

    useEffect(() => {
        const bankAccount = getQueryParam('bankAccount');
        if (bankAccount) {
            setBankAccount(Number(bankAccount));
            setAdvancedSearch(true);
            setSearchRequested(true);
        }
    }, [getQueryParam])

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = serviceContracts;

        aux.forEach((contract) => {
            const amount = formatCurrency(contract.totalValue);
            const status = getSituationText(contract.status);

            // Formatando data
            const nextDate = parseISO(extractDateStringFromTimestamp(contract.nextPaymentDate));
            const formatedNextDate = format(nextDate, "dd/MM/yyyy");

            const generateDate = parseISO(extractDateStringFromTimestamp(contract.nextGenerateDate));
            const formatedGenerateDate = format(generateDate, "dd/MM/yyyy");

            const finalDate = parseISO(extractDateStringFromTimestamp(contract.finalDate));
            const formatedFinalDate = format(finalDate, "dd/MM/yyyy");

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(contract.id), id: true },
                { for: "description", value: contract.description ?? '' },
                { for: "client", value: contract.customerName ?? '' },
                {
                    for: "nextpaymentDate",
                    value: (
                        contract.nextPaymentDate && contract.status !== 'attended' && contract.status !== 'canceled' ? formatedNextDate : ''
                    ),
                },
                {
                    for: "generateDate",
                    value: (
                        contract.nextPaymentDate && contract.status !== 'attended' && contract.status !== 'canceled' ? formatedGenerateDate : ''
                    )
                },
                {
                    for: "finalDate",
                    value: (
                        contract.finalDate ? formatedFinalDate : 'INDETERMINADO'
                    )
                },
                { for: "amount", value: amount },
                { for: "situation", value: status },
                { for: "customStatusLabel", value: contract.customStatus?.label ?? '' },
                { for: "customStatusColor", value: contract.customStatus?.color ?? '' },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [serviceContracts]);

    async function getAllStatus() {
        var statusData = await api.get("/status");
        setAllStatus(statusData.data);
    }

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setInitialNextPaymentDate('');
        setFinalNextPaymentDate('');
        setInitialNextGenerateDate('');
        setFinalNextGenerateDate('');
        setMinValue('');
        setMaxValue('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue),
            initialNextPaymentDate, finalNextPaymentDate, initialNextGenerateDate, finalNextGenerateDate,
            bankAccount,
        };

        reloadData();
    }, [serviceContracts, searchQuery, situation,
        initialNextPaymentDate, finalNextPaymentDate, initialNextGenerateDate, finalNextGenerateDate,
        minValue, maxValue, bankAccount]);

    useEffect(() => {
        if (searchRequested) {
            setSearchRequested(false);

            handleClickSearch();
        }
    }, [searchRequested, handleClickSearch]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = serviceContracts.filter((contract) => contract.id !== Number(id));

            await api.delete(`serviceContract/${id}`);

            setServiceContracts([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [serviceContracts]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickOpenChangeStatusModal = useCallback((id: string) => {
        const contract = serviceContracts.find(contractObj => contractObj.id === Number(id));
        if (!contract) return;

        setStatusHistory(JSON.parse(contract.situation));
        setIdChangeStatus(Number(id));
        setShowModalChangeStatus(true);
    }, [serviceContracts]);

    const handleClickChangeStatus = useCallback(async (newStatus: StatusHistory) => {
        if (!newStatus.statusSituationId) return;

        const aux = [...statusHistory];
        const lastStatus = aux[aux.length - 1];

        aux.push(newStatus);

        var status = newStatus.statusIsDefault === "y" ? getSituationFromText(newStatus.statusSituationLabel) : "custom";
        var customStatus = newStatus.statusIsDefault === "n" ? newStatus.statusSituationId : null;

        const customStatusObject = customStatus ? allStatus.find((statusObj) => statusObj.id === customStatus) : null;

        const raw: any = {
            status: status,
            customStatusId: customStatus,
            situation: JSON.stringify(aux),
        }
        if (newStatus.statusSituation === 'attended' || newStatus.statusSituation === 'canceled') {
            raw.nextPaymentDate = null;
        }

        await api.put(`serviceContract/${idChangeStatus}`, raw);

        ServiceContractService.dispatchStatusChangeNotificationAndEmail({
            serviceContractId: idChangeStatus,
            lastStatusHistory: lastStatus,
            newStatusHistory: newStatus,
        });

        setStatusHistory([...aux]);
        setServiceContracts((prevState) => (
            prevState.map((contract) => {
                if (contract.id !== idChangeStatus) {
                    return contract;
                }

                return {
                    ...contract,
                    situation: JSON.stringify(aux),
                    status: status,
                    customStatus: customStatusObject,
                }
            })
        ));
    }, [idChangeStatus, statusHistory, allStatus]);

    const fetchServiceContractStatus = async () => {
        try {
            const results = await axios.get("/serviceContract/getServiceContractStatus");
            setServiceContractStatus(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchServiceContractStatusTotal = async () => {
        try {
            const results = await axios.get("/serviceContract/getServiceContractStatusTotal");
            console.log(results.data);
            setServiceContractStatusTotal(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchServiceContractStatus();
        fetchServiceContractStatusTotal();
    }, []);

    const formatDataKey = () => {
        return "Quantidade";
    }

    const formatStatus = (tickItem: string) => {
        switch(tickItem) {
            case 'attended':
                return 'Finalizado';
            case 'open':
                return 'Em aberto';
            case 'custom':
                return 'Em negociação';
            case 'canceled':
                return 'Cancelado';
            case 'progress':
                return 'Em andamento';
            default:
                return "Nenhum";
        }
    }

    const formatLabel = (label: string, type: string) => {
        if (type === "situation") {
            switch(label) {
                case 'attended':
                    return 'Finalizado';
                case 'open':
                    return 'Em aberto';
                case 'custom':
                    return 'Em negociação';
                case 'canceled':
                    return 'Cancelado';
                case 'progress':
                    return 'Em andamento';
                default:
                    return "Nenhum";
            }
        }
    }

    const CustomTooltip = ({ active, payload, label, type }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabel(label, type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const createCustomTooltip = (type: any) => (props: any) => (
        <CustomTooltip {...props} type={type} />
    )

    const COLORS = ['yellow', 'blue', 'red', 'green']

    return (
        <MainPageContentWrapper>
            <ModalChangeStatus
                showModal={showModalChangeStatus}
                setShowModal={setShowModalChangeStatus}
                history={statusHistory}
                onClickChangeStatus={handleClickChangeStatus}
                listStatus={allStatus}
            />
            <h4 className="font-weight-bolder">Lista de contratos</h4>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2 text-dark"
                                onClick={() => handleClickAdd()}
                            >
                                <i className="fas fa-plus text-dark"></i>
                                Adicionar
                            </Button>
                            : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="shadow-sm p-6 rounded-sm border border-2">
                    <h4>Pesquisa avançada</h4>

                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Todos
                                </MenuItem>

                                {allStatus.map((status) => (
                                    <MenuItem key={status.id} value={status.isDefault === 'y' ? getSituationFromText(status.label) : status.id} onClick={(event) => { setSituation(event.currentTarget.getAttribute('data-value') ?? '') }}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Início Prox. Vencimento"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={initialNextPaymentDate}
                                onChange={(e) => setInitialNextPaymentDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Fim Prox. Vencimento"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={finalNextPaymentDate}
                                onChange={(e) => setFinalNextPaymentDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Início Prox. Geração"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={initialNextGenerateDate}
                                onChange={(e) => setInitialNextGenerateDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Fim Prox. Geração"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={finalNextGenerateDate}
                                onChange={(e) => setFinalNextGenerateDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                label="Valor mínimo"
                                startAdornment="R$"
                                value={minValue}
                                onChange={(evt) => setMinValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                label="Valor máximo"
                                startAdornment="R$"
                                value={maxValue}
                                onChange={(evt) => setMaxValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Conta bancária"
                                getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                value={bankAccount}
                                onSelect={(option) => setBankAccount(Number(option?.id))}
                                apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if (!bankAccount) return null;
                                    return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                                }}
                            />
                        </div>
                        <div className="col-12 d-flex mt-6">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                <i className="fa fa-search"></i>
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                                Limpar
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className="row">
            <Paper elevation={1} className="mt-3 col-lg-4 col-12 border border-2 py-3" style={{ height: "fit-content" }}>
                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Total por situação</span> - mês corrente

                        {
                            serviceContractStatusTotal?.map((serviceContractStatusTotal, index) => {
                                return (
                                    <div key={index} className="d-flex my-3" style={{ fontSize: "16px" }}>
                                        <div className={`${backgroundColors[index]} p-3 mr-2 rounded-circle`} style={{ width: "8px", border: "2px solid", borderColor: textColors[index] }}></div>
                                        <span className="font-weight-bolder">{formatStatus(serviceContractStatusTotal.status)}</span>: {formatCurrency(serviceContractStatusTotal.value)}
                                    </div>
                                );
                            })
                        }

                        {/* <PieChart className="w-100" width={300} height={330} data={serviceContractStatusTotal}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={serviceContractStatusTotal}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#181C32"
                                label
                            >
                                {serviceContractStatusTotal?.map((entry: any, index: number) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip wrapperStyle={{ outline: "none" }} />
                        </PieChart> */}
                    </Card>

                    <Card variant="outlined" className="bg-light p-5 border mb-3">
                        <span className="text-dark font-size-lg font-weight-bolder">Situação</span> - mês corrente

                        <AreaChart className="w-100 mt-6" width={400} height={200} data={serviceContractStatus}>
                            <XAxis dataKey="status" tickFormatter={formatStatus} />
                            <YAxis />
                            <Tooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("situation")}  />
                            <Legend formatter={formatDataKey} />
                            <Area type="monotone" dataKey="quantity" stroke="#8950FC" fill="#8950FC" />
                        </AreaChart>
                    </Card>
                </Paper>

                <div className="mt-3 col-lg-8 col-12">
                    <ListWithModalChangeSituation
                        situation
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                        onClone={handleClickClone}
                        customButtons={
                            user.isAccountant == "n"
                                ?
                                [
                                    {
                                        class: 'btn-primary',
                                        content: (<i className='flaticon-list-2 mr-2'></i>),
                                        variant: BsVariant.PRIMARY,
                                        popup: "Alterar Status",
                                        onClick: handleClickOpenChangeStatusModal,
                                    }
                                ]
                                : []
                        }
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalServiceContracts}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
        </MainPageContentWrapper>
    );
}