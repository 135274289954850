import React from "react";
import { Badge } from "react-bootstrap";

type ValidationBadgeProps = {
    variant: 'success' | 'warning' | 'danger',
    label: string;
    title?: string;
}

export function ValidationBadge({ variant, label, title }: ValidationBadgeProps) {
    const textColorClass = (
        variant === 'success'
            ? 'text-dark'
            : variant === 'warning'
                ? 'text-dark'
                : 'text-white'
    );

    const iconClass = (
        variant === 'success'
            ? 'flaticon2-check-mark'
            : variant === 'warning'
                ? 'flaticon2-warning'
                : 'flaticon2-cross'
    );

    return (
        <span>
            <Badge
                pill
                variant={variant}
                className="mr-3"
                title={title}
            >
                <i className={`${iconClass} ${textColorClass}`}></i>
            </Badge>
            <span>{label}</span>
        </span>
    );
}