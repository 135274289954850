import React, { useState, useEffect, useCallback } from 'react';
import { useSubheader } from '../../../../_metronic/layout';
import { Link, useParams } from 'react-router-dom';
import {
    Grid,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem,
    makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { NumericFormat } from '../../../components/NumericFormat';

import { useForm } from 'react-hook-form';
import {
    Button,
    Card,
    Modal,
    ButtonToolbar,
    Row,
    Col,
    Tabs,
    Tab,
    Spinner
} from 'react-bootstrap';

import { ProductDetails } from './ProductDetails';

import api from "../../../services/Api";
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import logService from '../../../services/LogService';
import { getEnabledStatusText } from '../../../utils/getEnabledStatusText';
import { getYesOrNoText } from '../../../utils/getYesOrNoText';
import { StockLocations } from '../../../types/StockLocations';
import ProductService from '../../../services/ProductService';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import StockLocationService from '../../../services/StockLocationService';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    btlr: {
        borderTopLeftRadius: 0
    },
    absoluteNav: {
        position: "absolute",
        top: "-50px",
        left: 0,
        background: "#fff",
        borderRadius: "0.42rem 0.42rem 0px 0px",
        height: "50px",
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string;
    code: string;
    isVariant: string;
    brand: string;
    unit: string;
    codeGtinEan: string;
    ncm: ncm;
    stockLocation: string;
    stockMin: string;
    stockMax: string;
    grossWeight: string;
    liquidColumn: string;
    saleValue: string;
    costValue: string;
    productSize: string;
    productOrigin: string;
    ordersNumber: string;
    typeClassification: string;
    situation: string;
    type: string;
    provider: string;
    stockInitial: string;
    note: string;
    category: string;
    subCategory: string;
    icmsRate: string;
    icmsStRate: string;
    cstRate: string;
    mvaRate: string;
    mvaAdjustedRate: string;
    deferralRate: string;
    ipiRate: string;
    pisRate: string;
    cofinsRate: string;
    taxableUnit: string;
    taxBenefitCode: string;
    cestCode: cestCode;
    federal: string;
    state: string;

    nameCategory: string;
    nameSubCategory: string;
    descriptionCategory: string;
    statusCategory: string;
};

export type Data = {
    ncm: ncm | undefined;
    name: string;
    code: string;
    unit: string;
    type: string;
    note: string;
    brand: string;
    state: string;
    cstRate: string;
    mvaRate: string;
    ipiRate: string;
    pisRate: string;
    federal: string;
    cestCode: cestCode | undefined;
    provider: string;
    category: string;
    icmsRate: string;
    stockMin: string;
    stockMax: string;
    situation: string;
    isVariant: string;
    saleValue: string;
    costValue: string;
    productSize: string;
    codeGtinEan: string;
    grossWeight: string;
    icmsStRate: string;
    cofinsRate: string;
    subCategory: string;
    taxableUnit: string;
    deferralRate: string;
    stockInitial: string;
    ordersNumber: string;
    liquidColumn: string;
    productOrigin: string;
    stockLocation: string;
    taxBenefitCode: string;
    mvaAdjustedRate: string;
    typeClassification: string;
};

type UrlParam = {
    id: string
}

type grid = {
    id: number,
    name: string,
    variation: variation[]
}

type variation = {
    id: number,
    idGrid: number,
    variationName: string
}

type variationProduct = {
    id: number,
    productId: number,
    grid: string,
    code: string,
    stockLocation: string,
    stockMin: number,
    stockMax: number,
    stockInitial: number,
    grossWeight: string,
    liquidWeight: string,
    situation: string,
    codeGtinEan: string,
    saleValue: string,
    costValue: string
    gridId: number;
    variationId: number;
    name: string;
}

type ncmNomenclature = {
    Codigo: string,
    Descricao: string,
    Data_Inicio: string,
    Data_Fim: string,
    Tipo_Ato: string,
    Numero_Ato: string,
    Ano_Ato: string
}

type ncm = {
    code: string,
    description: string
}

type cestCodeFetch = {
    numeroAnexo: string,
    descAnexo: string,
    item: string,
    cest: string,
    ncms: string[],
    descricao: string,
}


type cestCode = {
    code: string,
    description: string
}

interface VariationProduct {
    id: number;
    grid: string;
    code: string;
    stockMin: number;
    stockMax: number;
    stockInitial: number;
    situation: string;
    saleValue: string;
    costValue: string;
    productId: number;
    grossWeight: string;
    codeGtinEan: string;
    liquidWeight: string;
    stockLocation: string;
}

const DEFAULT_DATA = {
    name: '',
    code: '',
    isVariant: '',
    brand: '',
    unit: '',
    codeGtinEan: '',
    ncm: undefined,
    stockLocation: '',
    stockMin: '',
    stockMax: '',
    stockInitial: '',
    grossWeight: '',
    liquidColumn: '',
    saleValue: '',
    costValue: '',
    productSize: '',
    productOrigin: '',
    ordersNumber: '',
    typeClassification: '',
    situation: '',
    type: '',
    provider: '',
    note: '',
    category: '',
    icmsRate: '',
    icmsStRate: '',
    cstRate: '',
    mvaRate: '',
    mvaAdjustedRate: '',
    deferralRate: '',
    ipiRate: '',
    pisRate: '',
    cofinsRate: '',
    taxableUnit: '',
    taxBenefitCode: '',
    cestCode: undefined,
    federal: '',
    state: '',
    subCategory: '',
};

export function EditProducts() {
    const { user } = useSelector((state: any) => state.auth);

    const subHeader = useSubheader();
    subHeader.setTitle('Detalhes do Produto/Serviço');

    const classes = useStyles();
    const { register, formState: { errors } } = useForm<Inputs>();

    const { id } = useParams<UrlParam>();

    const [isKit, setIsKit] = useState("n");

    const [activeTab, setActiveTab] = useState("product-details");
    const [msgError, setMsgError] = useState("");
    const [showModalPrice, setShowModalPrice] = useState(false);
    const [showModalExist, setShowModalExist] = useState(false);

    const [stockLocations, setStockLocations] = useState<StockLocations[]>();

    const [productsKit, setProductsKit] = useState({
        products: [
            {
                productId: 0,
                productName: "",
                productCount: 1
            }
        ]
    });


    const [ipiCst, setIpiCst] = useState("");
    const [cofinsCst, setCofinsCst] = useState("");
    const [pisCst, setPisCst] = useState("");
    const [taxableCount, setTaxableCount] = useState("");
    const [taxableValue, setTaxableValue] = useState("");
    const [buyValue, setBuyValue] = useState('');
    const [ipiMoney, setIpiMoney] = useState('0');
    const [ipiPercent, setIpiPercent] = useState('0');
    const [freightMoney, setFreightMoney] = useState('0');
    const [aditionalCost, setAditionalCost] = useState('0');
    const [freightPercent, setFreightPercent] = useState('0');
    const [creditIcmsMoney, setCreditIcmsMoney] = useState('0');
    const [creditIcmsPercent, setCreditIcmsPercent] = useState('0');
    const [creditIcmsstMoney, setCreditIcmsstMoney] = useState('0');
    const [profitMarginMoney, setProfitMarginMoney] = useState('0');
    const [creditIcmsstPercent, setCreditIcmsstPercent] = useState('0');
    const [profitMarginPercent, setProfitMarginPercent] = useState('0');

    const [data, setData] = useState<Data>(DEFAULT_DATA);

    const [brandProduct, setBrandProduct] = useState([]);
    const [categoryProduct, setCategoryProduct] = useState([]);
    const [subCategoryProduct, setSubCategoryProduct] = useState([]);

    const [isSubmit, setIsSubmit] = useState(false);

    const [typeInvalid, setTypeInvalid] = useState(false);
    const [nameInvalid, setNameInvalid] = useState(false);
    const [unitInvalid, setUnitInvalid] = useState(false);
    const [saleValueInvalid, setSaleValueInvalid] = useState(false);
    const [stockInvalid, setStockInvalid] = useState(false);
    const [stockMinInvalid, setStockMinInvalid] = useState(false);
    const [stockMaxInvalid, setStockMaxInvalid] = useState(false);
    const [stockInitialInvalid, setStockInitialInvalid] = useState(false);
    const [productByGtinEanCodeInvalid, setProductByGtinEanCodeInvalid] = useState(false);
    const [ncmList, setNcmList] = useState<ncm[]>([]);
    const [cestCodeList, setCestCodeList] = useState<cestCode[]>([]);


    // data
    const [ncm, setNcm] = useState("");
    const [cestCode, setCestCode] = useState("");
    const [page, setPage] = useState(0);
    const [nameProduct, setNameProduct] = useState("");
    const [code, setCode] = useState("");
    const [unit, setUnit] = useState("");
    const [type, setType] = useState("");
    const [note, setNote] = useState("");
    const [brand, setBrand] = useState("");
    const [state, setState] = useState("");
    const [ipiRate, setIpiRate] = useState("");
    const [pisRate, setPisRate] = useState("");
    const [federal, setFederal] = useState("");
    const [cstRate, setCstRate] = useState("");
    const [mvaRate, setMvaRate] = useState("");
    const [provider, setProvider] = useState("");
    const [category, setCategory] = useState("");
    const [icmsRate, setIcmsRate] = useState("");
    const [stockMin, setStockMin] = useState("");
    const [stockMax, setStockMax] = useState("");
    const [saleValue, setSaleValue] = useState("");
    const [costValue, setCostValue] = useState("");
    const [situation, setSituation] = useState("");
    const [cofinsRate, setCofinsRate] = useState("");
    const [icmsStRate, setIcmsStRate] = useState("");
    const [productSize, setProductSize] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [grossWeight, setGrossWeight] = useState("");
    const [codeGtinEan, setCodeGtinEan] = useState("");
    const [taxableUnit, setTaxableUnit] = useState("");
    const [stockInitial, setStockInitial] = useState("");
    const [deferralRate, setDeferralRate] = useState("");
    const [liquidColumn, setLiquidColumn] = useState("");
    const [hasVariation, setHasVariation] = useState('n');
    const [stockLocation, setStockLocation] = useState(0);
    const [productOrigin, setProductOrigin] = useState("");
    const [ordersNumber, setOrdersNumber] = useState("");
    const [taxBenefitCode, setTaxBenefitCode] = useState("");
    const [mvaAdjustedRate, setMvaAdjustedRate] = useState("");
    const [typeClassification, setTypeClassification] = useState("");

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const [selectedCategoryProductUuid, setSelectedCategoryProductUuid] = useState("");

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const detailsRequiredFields = [unit, saleValue, stockLocation, stockMin, stockMax, stockInitial];
    const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

    const [grid, setGrid] = useState({
        grids: [
            {
                gridId: 0,
                gridName: ""
            }
        ]
    })

    const [variationGrid, setVariationGrid] = useState({
        variation: [
            {
                grid: 0,
                variationName: ""
            }
        ]
    })

    const [variationProduct, setVariationProduct] = useState({
        variation: [
            {
                id: 0,
                grid: [
                    {
                        gridId: 0,
                        variationId: 0,
                        variationName: ""
                    }
                ],
                code: "",
                name: "",
                stockLocation: "",
                stockMin: 0,
                stockMax: 0,
                stockInitial: 0,
                grossWeight: "",
                liquidWeight: "",
                situation: "",
                codeGtinEan: "",
                saleValue: "",
                costValue: "",
                inputsInvalid: {
                    grid: false,
                    stockLocation: false,
                    stockMin: false,
                    stockMax: false,
                    stockInitial: false,
                    saleValue: false
                }
            }
        ]
    })
    const [gridSelected, setGridSelected] = useState<grid[]>([]);

    function calcPrice() {
        const buyValuePrice = parseFloat(buyValue);

        const valueIcmsMoney = buyValuePrice * (parseFloat(creditIcmsPercent) / 100);
        setCreditIcmsMoney(valueIcmsMoney.toString());
    }

    function addProductToKit() {
        var kit = productsKit.products;

        kit.push({
            productId: 0,
            productName: '',
            productCount: 0
        });

        setProductsKit({ products: kit });
    }

    function deleteProductKit(index: number) {
        var kit = productsKit.products;

        kit.splice(index, 1);

        setProductsKit({ products: kit });
    }

    function changeCountProductKit(index: number, value: number) {
        var kit = productsKit.products;

        kit[index].productCount = value;

        setProductsKit({ products: kit });
    }

    function getNcmList() {
        var json = require("../../../../json/ncm-list.json");
        var nomenclatures: ncmNomenclature[] = json.Nomenclaturas;

        /**
         * ncms de 8 digitos
         */
        // var nomenclaturesComplete = nomenclatures.filter((obj) => obj.Codigo.replaceAll('.', '').length === 8);

        var ncm: ncm[] = [];
        nomenclatures.map((obj, index) => {
            if (obj.Codigo.replaceAll('.', '').length !== 8) {
                return;
            }
            var description: string = '';
            var objCode = obj.Codigo.replaceAll('.', '');
            var smallArray = nomenclatures.slice(((index - 200) >= 0 ? (index - 200) : 0), index);

            var partialNomenclatures = smallArray.filter((obj2) => {
                var obj2Code = obj2.Codigo.replaceAll('.', '');
                return obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 2)
                    || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 3)
                    || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 4)
                    || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 5)
                    || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 6)
                    || obj2Code.replaceAll('.', '') === objCode.replaceAll('.', '').substring(0, 7);
            });
            partialNomenclatures.map((obj3) => {
                description += ' ' + obj3.Descricao;
            });
            description += ' ' + obj.Descricao;
            ncm.push({
                code: obj.Codigo,
                description: description
            });
        });

        setNcmList(ncm);
    }

    function getCestCodeList() {
        var json = require("../../../../json/cest-list.json");
        var cestFetch: cestCodeFetch[] = json;

        var cestCode: cestCode[] = [];
        cestFetch.map((obj, index) => {
            cestCode.push({
                code: obj.cest,
                description: obj.descAnexo + ' - ' + obj.descricao
            });
        });

        setCestCodeList(cestCode);
    }

    function updateCestCodeList(selectedNcm: ncm | null) {
        if (!selectedNcm) {
            getCestCodeList();
            return;
        }

        var json = require("../../../../json/cest-list.json");
        var cestFetch: cestCodeFetch[] = json;
        var list: cestCode[] = [];
        var cestFiltered = cestFetch.filter((obj) => {
            return obj.ncms.findIndex((ncmStr) => ncmStr === selectedNcm.code.replaceAll('.', '').substring(0, ncmStr.length)) !== -1;
        });

        if (cestFiltered.length === 0) {
            getCestCodeList();
            return;
        }

        cestFiltered.map((filteredObj) => {
            list.push({
                code: filteredObj.cest,
                description: filteredObj.descAnexo + ' - ' + filteredObj.descricao
            });
        });

        setCestCodeList(list);
    }

    useEffect(() => {
        getData();
        getGrid();
        getNcmList();
        getCestCodeList();
    }, []);

    async function getData() {

        const { data } = await api.get(`/products/${id}`);

        setNcm(data.ncm ?? '');
        setNameProduct(data.name ?? '');
        setCode(data.code ?? '');
        setUnit(data.unit ?? '');
        setType(data.type ?? '');
        setNote(data.note ?? '');
        setBrand(data.brand ? String(data.brand) : '');
        setState(data.state ?? '');
        setIsKit(data.isKit ?? '');
        setIpiRate(data.ipiRate ?? '');
        setIpiCst(data.ipiCst ?? '');
        setPisRate(data.pisRate ?? '');
        setPisCst(data.pisCst ?? '');
        setFederal(data.federal ?? '');
        setCstRate(data.cstRate ?? '');
        setMvaRate(data.mvaRate ?? '');
        setCategory(data.category ? String(data.category) : '');
        setIcmsRate(data.icmsRate ?? '');
        setCestCode(data.cestCode ?? '');
        setStockMin(data.stockMin ?? '');
        setStockMax(data.stockMax ?? '');
        setProvider(data.provider ?? '');
        setSaleValue(data.saleValue ?? '');
        setCostValue(data.costValue ?? '');
        setSituation(data.situation ?? '');
        setIcmsStRate(data.icmsStRate ?? '');
        setCofinsRate(data.cofinsRate ?? '');
        setCofinsCst(data.cofinsCst ?? '');
        setCodeGtinEan(data.codeGtinEan ?? '');
        setProductSize(data.productSize ?? '');
        setGrossWeight(data.grossWeight ?? '');
        setSubCategory(data.subCategory ? String(data.subCategory) : '');
        setTaxableUnit(data.taxableUnit);
        setTaxableCount(String(data.taxableCount));
        setTaxableValue(data.taxableValue ? data.taxableValue.replace(',', '').replace('.', ',') : '0,00');
        setLiquidColumn(data.liquidColumn ?? '');
        setHasVariation(data.hasVariation ?? '');
        setStockInitial(data.stockInitial ?? '');
        setDeferralRate(data.deferralRate ?? '');
        setStockLocation(data.stockLocation ?? '');
        setProductOrigin(data.productOrigin ?? '');
        setOrdersNumber(data.ordersNumber ?? '');
        setTaxBenefitCode(data.taxBenefitCode ?? '');
        setMvaAdjustedRate(data.mvaAdjustedRate ?? '');
        setTypeClassification(data.typeClassification ?? '');
        setProductsKit({ products: JSON.parse(data.kit) });

        setBeforeSubmitData(data);

        if (data.category) {
            const selectedCategoryProduct = await api.get(`/categoryproduct/${data.category}`)

            setSelectedCategoryProductUuid(selectedCategoryProduct.data.uuidCategory)
        } else {
            setSelectedCategoryProductUuid("")
        }

        if (data.hasVariation === 'y') {
            var gridSelectedToVariation = gridSelected;
            var variationArr = variationProduct.variation;
            const variations = await api.get(`/variations/product/${data.id}`)
            var productVariation = variations.data;

            //Populando variações do produto
            var gridProp = [{
                gridId: 0,
                variationId: 0,
                variationName: '',
            }]
            productVariation.map((productVariationObj: variationProduct) => {
                variationArr.push({
                    id: productVariationObj.id,
                    grid: [...gridProp, {
                        gridId: productVariationObj.gridId,
                        variationId: productVariationObj.variationId,
                        variationName: productVariationObj.name.replace(`${data.name}-`, ''),
                    }],
                    code: productVariationObj.code,
                    name: "",
                    stockLocation: productVariationObj.stockLocation,
                    stockMin: productVariationObj.stockMin,
                    stockMax: productVariationObj.stockMax,
                    stockInitial: productVariationObj.stockInitial,
                    grossWeight: productVariationObj.grossWeight,
                    liquidWeight: productVariationObj.liquidWeight,
                    situation: productVariationObj.situation,
                    codeGtinEan: productVariationObj.codeGtinEan,
                    saleValue: productVariationObj.saleValue,
                    costValue: productVariationObj.costValue,
                    inputsInvalid: {
                        grid: false,
                        stockLocation: false,
                        stockMin: false,
                        stockMax: false,
                        stockInitial: false,
                        saleValue: false
                    }
                })
            })

            //Populando grades selecionadas
            variationArr[1].grid.map(async (gridObj, index) => {
                if (index > 0) {
                    var selectedGrid = await api.get(`/grid/${gridObj.gridId}`);
                    var gridVariations = await api.get(`/gridVariation/grid/${gridObj.gridId}`);
                    gridSelectedToVariation.push({
                        id: gridObj.gridId,
                        name: selectedGrid.data.name,
                        variation: gridVariations.data
                    });
                }

                setVariationProduct({ variation: variationArr });
                setGridSelected(gridSelectedToVariation);
            })

        }

    }

    function kitVerify(): boolean {

        if (isKit === "y") {
            var kit = productsKit.products;

            if (kit.length) {
                for (let kitArr = 0; kitArr < kit.length; kitArr++) {
                    if (!kit[kitArr].productName) {
                        setActiveTab("estrutura-produto");
                        setIsSubmit(false);
                        setMsgError("Selecione o produto para o kit");
                        setShowModalExist(true);

                        return false

                    } else if (!kit[kitArr].productCount) {
                        setActiveTab("estrutura-produto");
                        setIsSubmit(false);
                        setMsgError("Informe a quantidade do produto corretamente");
                        setShowModalExist(true);

                        return false
                    }
                }
            } else {
                setActiveTab("estrutura-produto");
                setIsSubmit(false);
                setMsgError("Adicione pelo menos um produto ao kit");
                setShowModalExist(true);

                return false
            }




        }

        return true
    }

    async function generateCodeProduct(nameProduct: string) {
        if (nameProduct) {
            let splited = nameProduct.trim().split(" ");
            let number = 1;

            let initials = "";

            for (let splitData of splited) {
                initials += splitData[0]?.toUpperCase() ?? '';
            }

            const { data } = await api.get(`/products/verify-code/${initials + number}`);

            if (data && data.id != id) {
                var generateSuccess = false
                while (generateSuccess == false) {
                    var productResponse = await api.get(`/products/verify-code/${initials + number}`);

                    if (productResponse.data) {
                        number++;

                    } else {
                        generateSuccess = true;
                        setCode(initials + "" + number);
                    }
                }

            } else {
                setCode(initials + "" + number);
            }
        }
    }

    async function generateCodeVariationProduct(nameProduct: string, idGridVariation: number, idVariation: number = 0, index: number) {
        let nameVariation = variationProduct.variation[index].grid.find(gridObj => gridObj.variationId === idGridVariation)?.variationName;


        let variationSplited = nameVariation?.trim().replaceAll('-', ' ').split(" ") ?? [];
        let number = 1;


        let initialsVariation = "";

        for (let splitVariationData of variationSplited) {
            initialsVariation += splitVariationData[0]?.toUpperCase() ?? '';
        }

        const { data } = await api.get(`/variations/verify-code/${initialsVariation + number}`);

        if (data && data.id != idVariation) {
            var generateSuccess = false
            while (generateSuccess == false) {
                var productResponse = await api.get(`/variations/verify-code/${initialsVariation + number}`);

                if (productResponse.data) {
                    number++;

                } else {
                    var subGenerateSuccess = false
                    while (subGenerateSuccess == false) {
                        var foundEqualCode = false;
                        for (let variationObj of variationProduct.variation) {
                            if (variationObj.code == String(initialsVariation + number)) {
                                foundEqualCode = true;
                            }
                        }

                        if (foundEqualCode) {
                            number++;

                        } else {
                            subGenerateSuccess = true;
                            updateVariationProduct(index, 'code', initialsVariation + number);
                        }
                    }
                    generateSuccess = true;
                }
            }

        } else {
            var generateSuccess = false
            while (generateSuccess == false) {
                var foundEqualCode = false;
                for (let variationObj of variationProduct.variation) {
                    if (variationObj.code == String(initialsVariation + number)) {
                        foundEqualCode = true;
                    }
                }

                if (foundEqualCode) {
                    number++;

                } else {
                    generateSuccess = true;
                    updateVariationProduct(index, 'code', initialsVariation + number);
                }
            }

        }
    }

    async function inputsVerify(): Promise<boolean> {
        if (!type) {
            setIsSubmit(false);
            setMsgError("Selecione o tipo corretamente");
            setShowModalExist(true);
            setTypeInvalid(true);

            return false
        }
        
        if (!nameProduct) {
            setIsSubmit(false);
            setMsgError("Preencha o nome corretamente");
            setShowModalExist(true);
            setNameInvalid(true);

            return false
        }
        
        if (!unit) {
            setActiveTab("detalhes-produto");
            setIsSubmit(false);
            setMsgError("Preencha a unidade corretamente");
            setShowModalExist(true);
            setUnitInvalid(true);

            return false
        }
        
        if (!saleValue) {
            setActiveTab("detalhes-produto");
            setIsSubmit(false);
            setMsgError("Preencha o valor de venda corretamente");
            setShowModalExist(true);
            setSaleValueInvalid(true);

            return false
        }

        if (hasVariation == "n" && isKit == "n" && type == "product") {
            if (!stockLocation) {
                setActiveTab("detalhes-produto");
                setIsSubmit(false);
                setMsgError("Selecione a localização no estoque");
                setShowModalExist(true);
                setStockInvalid(true);

                return false
            }
            
            if (!stockMin) {
                setActiveTab("detalhes-produto");
                setIsSubmit(false);
                setMsgError("Preencha o estoque mínimo");
                setShowModalExist(true);
                setStockMinInvalid(true);

                return false
            }
            
            if (!stockMax) {
                setActiveTab("detalhes-produto");
                setIsSubmit(false);
                setMsgError("Preencha o estoque máximo");
                setShowModalExist(true);
                setStockMaxInvalid(true);

                return false
            }
            
            if (!stockInitial) {
                setActiveTab("detalhes-produto");
                setIsSubmit(false);
                setMsgError("Preencha o estoque inicial");
                setShowModalExist(true);
                setStockInitialInvalid(true);

                return false
            }
        }

        if (codeGtinEan) {
            const { data: productByGtinEanCode } = await api.get(`/products/codeGtinEan/${codeGtinEan}`);

            if (productByGtinEanCode && productByGtinEanCode.id != id) {
                setActiveTab("detalhes-produto");
                setIsSubmit(false);
                setMsgError("Já existe um código gtin/ean com este valor!");
                setShowModalExist(true);
                setProductByGtinEanCodeInvalid(true);

                return false;
            }
        }

        return true
    }

    async function registerSelectedCategoryProductUuid(idCategory: number) {
        try {
            const category = await api.get(`/categoryproduct/${idCategory}`);

            setSelectedCategoryProductUuid(category.data.uuidCategory)

            setSubCategory("")
            // setShowSubCategoryField(true);

        } catch (error) {

            console.log(error);
            setSubCategory("")
            // setShowSubCategoryField(false);
        }
    }

    async function getGrid() {
        var gridArr = grid.grids;

        try {
            const gridResponse = await api.get("/grid");

            for (let gridIndex = 0; gridIndex < gridResponse.data.length; gridIndex++) {
                if (!gridArr[0].gridName) {
                    gridArr[0].gridId = gridResponse.data[gridIndex].id;
                    gridArr[0].gridName = gridResponse.data[gridIndex].name;

                } else {
                    gridArr.push({
                        gridId: gridResponse.data[gridIndex].id,
                        gridName: gridResponse.data[gridIndex].name
                    })
                }
            }

            setGrid({ grids: gridArr });

        } catch (error) {
            console.log(error);
        }
    }

    async function selectGrid(id: number, name: string, target: HTMLInputElement) {
        var gridSelectedToVariation = gridSelected;
        var variationArr = variationProduct.variation;

        if (target.checked) {
            var selectedGrid = await api.get(`/grid/${id}`);
            var gridVariations = await api.get(`/gridVariation/grid/${id}`);

            gridSelectedToVariation.push({
                id: id,
                name: selectedGrid.data.name,
                variation: gridVariations.data
            });
        } else {
            if (gridSelectedToVariation.length === 1 && variationArr.length > 1) {
                target.click()
                setMsgError("É necessário manter pelo menos uma grade selecionada");
                setShowModalExist(true);
            }
            for (let removeGridSelected = 0; removeGridSelected < gridSelectedToVariation.length; removeGridSelected++) {
                if (gridSelectedToVariation[removeGridSelected].id === id) {
                    gridSelectedToVariation.splice(removeGridSelected, 1);
                }
            }
            variationArr.map((variationObj, index) => {
                variationArr[index].grid = variationObj.grid.filter(gridObj => gridObj.gridId !== id);
            });
        }


        setVariationProduct({ variation: variationArr });
        setGridSelected(gridSelectedToVariation);
    }

    function addVariationProduct() {
        var variationArr = variationProduct.variation;

        if (!gridSelected.length) {
            setIsSubmit(false);
            setMsgError("Selecione pelo menos uma grade para a variação");
            setShowModalExist(true);

        } else {
            variationArr.push({
                id: 0,
                grid: [
                    {
                        gridId: 0,
                        variationId: 0,
                        variationName: ""
                    }
                ],
                code: "",
                name: "",
                stockLocation: "",
                stockMin: 0,
                stockMax: 0,
                stockInitial: 0,
                grossWeight: "",
                liquidWeight: "",
                situation: "",
                codeGtinEan: "",
                saleValue: "",
                costValue: "",
                inputsInvalid: {
                    grid: false,
                    stockLocation: false,
                    stockMin: false,
                    stockMax: false,
                    stockInitial: false,
                    saleValue: false
                }
            });

            setVariationProduct({ variation: variationArr });
        }

    }

    function updateVariationProductGrid(index: number, gridId: number, variationId: number) {
        var variationArr = variationProduct.variation;

        gridSelected.map((dataGrid, indexGrid) => {
            dataGrid.variation.map((variation, indexVariation) => {
                if (variation.id == variationId && variation.idGrid == gridId) {
                    variationArr[index].grid = [
                        {
                            gridId: 0,
                            variationId: 0,
                            variationName: '',
                        },
                        {
                            gridId: gridId,
                            variationId: variationId,
                            variationName: variation.variationName,
                        },
                    ];
                }
            });
        });


        setVariationProduct({ variation: variationArr });
    }

    function updateVariationProduct(index: number, key: string, value: any) {
        var variationArr = variationProduct.variation;

        switch (key) {
            case 'code':
                variationArr[index].code = value;
                break;
            case 'stockLocation':
                variationArr[index].stockLocation = value;
                break;
            case 'stockMin':
                variationArr[index].stockMin = Number(value);
                break;
            case 'stockMax':
                variationArr[index].stockMax = Number(value);
                break;
            case 'stockInitial':
                variationArr[index].stockInitial = Number(value);
                break;
            case 'grossWeight':
                variationArr[index].grossWeight = value;
                break;
            case 'liquidWeight':
                variationArr[index].liquidWeight = value;
                break;
            case 'situation':
                variationArr[index].situation = value;
                break;
            case 'codeGtinEan':
                variationArr[index].codeGtinEan = value;
                break;
            case 'saleValue':
                variationArr[index].saleValue = value;
                break;
            case 'costValue':
                variationArr[index].costValue = value;
                break;
        }

        setVariationProduct({ variation: variationArr });
    }

    function deleteVariationProduct(index: number) {
        var variationArr = variationProduct.variation;

        variationArr.splice(index, 1);

        setVariationProduct({ variation: variationArr });
    }

    function setUpVariationProductsGrid() {
        var gridSelectedArr = gridSelected;
        var variationProductArr = variationProduct.variation;
        var variationGridIndex = null;
        gridSelectedArr.map(({ id, name, variation }) => {
            variationProductArr.map((variationObj, index) => {
                if (index > 0) {
                    variationGridIndex = variationProduct.variation[index].grid.find(gridObj => gridObj.gridId === id);
                    if (!variationGridIndex) {
                        variationProductArr[index].grid.push({
                            gridId: id,
                            variationId: variation[0].id,
                            variationName: variation[0].variationName
                        });
                    }
                }
            });
            setVariationProduct({ variation: variationProductArr });
        });
    }

    function variationVerify(): boolean {
        if (hasVariation !== 'y') {
            return true;
        }

        // if (gridSelected.length === 0) {
        //    setIsSubmit(false);
        //    setMsgError("Selecione pelo menos uma grade de variação");
        //    setShowModalExist(true);
        //    return false;
        // }

        if (variationProduct.variation.length <= 1) {
            setActiveTab("variation");
            setIsSubmit(false);
            setMsgError("Adicione ao menos uma variação do produto");
            setShowModalExist(true);
            return false;
        }

        var variation = variationProduct.variation;
        for (let i = 0; i < variationProduct.variation.length; i++) {
            if (i > 0) {

                if (!variationProduct.variation[i].grid[1].gridId) {
                    setActiveTab("variation");
                    setIsSubmit(false);
                    setMsgError("Selecione uma variação da grade para a variação do produto");
                    setShowModalExist(true);
                    variation[i].inputsInvalid.grid = true;
                    return false;

                } else if (!variationProduct.variation[i].stockLocation) {
                    setActiveTab("variation");
                    setIsSubmit(false);
                    setMsgError("Selecione a localização no estoque da variação");
                    setShowModalExist(true);
                    variation[i].inputsInvalid.stockLocation = true;
                    return false;

                    // } else if (!variationProduct.variation[i].stockMin) {
                    //     setActiveTab("variation");
                    //     setIsSubmit(false);
                    //     setMsgError("Preencha o estoque mínimo da variação");
                    //     setShowModalExist(true);
                    //     variation[i].inputsInvalid.stockMin = true;
                    //     return false;

                    // } else if (!variationProduct.variation[i].stockMax) {
                    //     setActiveTab("variation");
                    //     setIsSubmit(false);
                    //     setMsgError("Preencha o estoque máximo da variação");
                    //     setShowModalExist(true);
                    //     variation[i].inputsInvalid.stockMax = true;
                    //     return false;

                    // } else if (!variationProduct.variation[i].stockInitial) {
                    //     setActiveTab("variation");
                    //     setIsSubmit(false);
                    //     setMsgError("Preencha o estoque inicial da variação");
                    //     setShowModalExist(true);
                    //     variation[i].inputsInvalid.stockInitial = true;
                    //     return false;

                } else if (!variationProduct.variation[i].saleValue) {
                    setActiveTab("variation");
                    setIsSubmit(false);
                    setMsgError("Preencha o valor de venda da variação corretamente");
                    setShowModalExist(true);
                    variation[i].inputsInvalid.saleValue = true;
                    return false;
                }
            }
        }

        setVariationProduct({ variation: variation });
        return true;
    }

    async function myHandleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setIsSubmit(true);

        if (!(await inputsVerify())) return

        if (!kitVerify()) return

        if (!variationVerify()) return

        if (isKit === "n") {
            var kit = productsKit.products;

            for (let clear = 0; clear < kit.length; clear++) {
                kit.splice(clear, 1);
            }

            setProductsKit({ products: kit });
        }


        const raw = {
            name: nameProduct,
            code: code,
            hasVariation: (hasVariation === "" ? "n" : hasVariation),
            brand: brand ? Number(brand) : null,
            unit: (unit === "" ? "Un" : unit),
            codeGtinEan: codeGtinEan,
            ncm: ncm,
            stockLocation: stockLocation || null,
            stockMin: stockMin,
            stockMax: stockMax,
            grossWeight: grossWeight,
            liquidColumn: liquidColumn,
            saleValue: saleValue,
            costValue: costValue,
            productSize: productSize,
            productOrigin: productOrigin,
            typeClassification: typeClassification,
            situation: (situation === "" ? "inactive" : situation),
            type: type,
            provider: provider || null,
            stockInitial: stockInitial,
            note: note,
            category: category ? Number(category) : null,
            subCategory: subCategory ? Number(subCategory) : null,
            icmsRate: icmsRate,
            icmsStRate: icmsStRate,
            cstRate: cstRate,
            mvaRate: mvaRate,
            mvaAdjustedRate: mvaAdjustedRate,
            deferralRate: deferralRate,
            ipiRate: ipiRate,
            ipiCst: ipiCst,
            pisRate: pisRate,
            pisCst: pisCst,
            cofinsRate: cofinsRate,
            cofinsCst: cofinsCst,
            taxableUnit: (taxableUnit === "" ? "Un" : taxableUnit),
            taxableCount: Number(taxableCount),
            taxableValue: formatToFloat(taxableValue),
            taxBenefitCode: taxBenefitCode,
            cestCode: cestCode,
            federal: federal,
            state: state,
            isKit: isKit,
            ordersNumber: ordersNumber,
            kit: JSON.stringify(productsKit.products)
        }

        try {

            const response = await api.post(`/products/${id}`, raw);

            await logService.logEdit({
                itemId: response.data.id,
                itemName: response.data.name,
                module: 'Produtos / Serviços',
                oldData: beforeSubmitData,
                newData: response.data,
                formattedFields: {
                    type: (value) => value === 'product' ? 'Produto' : 'Serviço',
                    brand: (value) => (brandProduct as any[]).find(({ id }) => Number(id) === Number(value))?.nameBrand ?? '',
                    hasVariation: (value) => getYesOrNoText(value),
                    isKit: (value) => getYesOrNoText(value),
                    situation: (value) => getEnabledStatusText(value),
                    taxableValue: (value) => value ? String(value).replace(',', '').replace('.', ',') : '',
                    stockLocation: (value) => stockLocations
                        ? (stockLocations.find(obj => Number(obj.id) === Number(value))?.name ?? '')
                        : '',
                    category: (value) => (categoryProduct as any[]).find(({ id }) => Number(id) === Number(value))?.nameCategory ?? '',
                    subCategory: (value) => (subCategoryProduct as any[]).find(({ id }) => Number(id) === Number(value))?.nameSubCategory ?? '',
                },
                fieldsMap: {
                    type: 'Tipo',
                    name: 'Nome',
                    code: 'Código',
                    hasVariation: 'Produto Variante',
                    unit: 'Unidade',
                    brand: 'Marca',
                    codeGtinEan: 'Código GTIN/EAN',
                    stockLocation: 'Localização no Estoque',
                    stockMin: 'Estoque mínimo',
                    stockMax: 'Estoque máximo',
                    stockInitial: 'Estoque inicial',
                    saleValue: 'Valor de venda',
                    costValue: 'Valor de custo',
                    productSize: 'Tamanho',
                    typeClassification: 'Classificação',
                    situation: 'Situação',
                    provider: 'Fornecedor',
                    ordersNumber: 'Número da ordem',
                    note: 'Observações',
                    category: 'Categoria',
                    subCategory: 'Sub categoria',
                    isKit: 'Produto é um Kit',
                    productOrigin: 'Origem',
                    ncm: 'NCM',
                    grossWeight: 'Peso bruto',
                    liquidColumn: 'Peso líquido',
                    icmsRate: 'ICMS',
                    icmsStRate: 'ICMS-ST',
                    cstRate: 'ICMS-CST',
                    mvaRate: 'MVA',
                    mvaAdjustedRate: 'MVA ajustado',
                    deferralRate: 'Diferimento',
                    ipiRate: 'IPI',
                    ipiCst: 'IPI-CST',
                    pisRate: 'PIS',
                    pisCst: 'PIS-CST',
                    cofinsRate: 'COFINS',
                    cofinsCst: 'COFINS-CST',
                    // taxableUnit: 'Unidade Tributável',
                    // taxableCount: 'Quantidade Tributável',
                    // taxableValue: 'Valor Tributável',
                    taxBenefitCode: 'Código benefício fiscal',
                    cestCode: 'Código CEST',
                    federal: 'Federal',
                    state: 'Estadual',
                },
            });

            if (hasVariation === 'y') {
                setUpVariationProductsGrid();
                var variationsId: number[] = [];
                await Promise.all(variationProduct.variation.map(async (variationObj, index) => {
                    if (index > 0) {
                        var responseVariation;
                        let rawVariation: any = {
                            productId: Number(response.data.id),
                            name: nameProduct + "-" + (variationObj.grid[1].variationName).replaceAll(`${nameProduct}-`, ''),
                            gridId: variationObj.grid[1].gridId,
                            variationId: variationObj.grid[1].variationId,
                            code: variationObj.code !== "" ? variationObj.code : null,
                            grossWeight: variationObj.grossWeight !== "" ? variationObj.grossWeight : null,
                            liquidWeight: variationObj.liquidWeight !== "" ? variationObj.liquidWeight : null,
                            situation: 'active',
                            codeGtinEan: variationObj.codeGtinEan !== "" ? variationObj.codeGtinEan : null,
                            saleValue: variationObj.saleValue,
                            costValue: variationObj.costValue !== "" ? variationObj.costValue : null,
                        }
                        if (variationObj.id === 0) {
                            rawVariation = {
                                ...rawVariation,
                                stockLocation: variationObj.stockLocation !== "" ? variationObj.stockLocation : null,
                                stockMin: variationObj.stockMin,
                                stockMax: variationObj.stockMax,
                                stockInitial: variationObj.stockInitial,
                                currentStock: variationObj.stockInitial,
                            }
                            responseVariation = await api.post("/variations", rawVariation);

                            saveVariationStock(response.data.id, responseVariation.data, variationObj);
                        } else {

                            responseVariation = await api.post(`/variations/${variationObj.id}`, rawVariation)
                        }
                        variationsId.push(Number(responseVariation.data.id));
                    }
                }));
                const savedVariations = await api.get('/variations')
                var savedProductVariation: variationProduct[] = savedVariations.data.filter((variationObj: variationProduct) => variationObj.productId === response.data.id);
                savedProductVariation.map((savedVariationsObj) => {
                    if (!variationsId.find(variationId => variationId === savedVariationsObj.id)) {
                        api.delete(`/variations/${savedVariationsObj.id}`)
                    }
                })
            }

            window.location.href = "/produtos"

        } catch (error) {

            console.log(error)
            setIsSubmit(false)

        }



    };

    async function saveVariationStock(productId: any, variationData: any, variationObj: any) {
        const today = new Date();
        const month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
        const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

        const stockId = variationObj.stockLocation !== "" ? variationObj.stockLocation : null;
        const stockResult = stockId ? await api.get(`/stockLocation/${String(stockId)}`) : null;
        const stockName = stockResult ? stockResult.data.name : '';

        const rawHistoric = {
            productId: Number(productId),
            date: today.getFullYear() + "-" + month + "-" + day,
            observation: "Variação criada e inserida no histórico",
            stockId: stockId,
            stockName: stockName,
            value: formatToFloat(variationObj.saleValue),
            type: "Balanço",
            totalLocation: variationObj.stockInitial,
            total: variationObj.stockInitial,
            hasVariation: "y",
            variationId: Number(variationData.id)
        }

        await api.post("/stockProductHistoric", rawHistoric);
    }

    async function apiSearchProducts(typedText: string) {
        const foundProducts = await ProductService.getProductsWithoutVariationsFiltered({ name: typedText }, true);

        const kitProductsId = productsKit.products.map(prod => prod.productId);

        return foundProducts.filter((prod) => !kitProductsId.includes(prod.id));
    }

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-doc") {
            if (isDetailsRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-delete');
                
                if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#details .flaticon2-check-mark');

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('product-details')?.classList.remove('current');
        document.getElementById('product-structure')?.classList.remove('current');
        document.getElementById('variation')?.classList.remove('current');
        document.getElementById('grid')?.classList.remove('current');
        document.getElementById('nfe')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        if (!detailsRequiredFields[0]) {
                setUnitInvalid(true);
        }

        if (!detailsRequiredFields[1]) {
                setSaleValueInvalid(true);
        }

        if (!detailsRequiredFields[2]) {
                setStockInvalid(true);
        }

        if (!detailsRequiredFields[3]) {
                setStockMinInvalid(true);
        }

        if (!detailsRequiredFields[4]) {
                setStockMaxInvalid(true);
        }

        if (!detailsRequiredFields[5]) {
                setStockInitialInvalid(true);
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'product-details') {
            setNextButton('first');
        } else if (tab == 'nfe') {
            setNextButton('last');
        } else {
            setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

        function backNextOption(value:string){
        let arr = hasVariation === "y" ? ['product-details', 'product-structure', 'grid', 'variation', 'nfe',] : ['product-details', 'product-structure', 'nfe'];
        let arr2 = hasVariation === "y" ? ['flaticon-doc', 'flaticon-settings', 'flaticon-grid-menu', 'flaticon-open-box', 'flaticon-notepad'] : ['flaticon-doc', 'flaticon-settings', 'flaticon-notepad'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
            if (activeTab == arr[0]) {
                setNextButton('first');
            } else {
                index = index-1;
                changeTab(arr[index], arr2[index], arr2[index+1]);
            }
        } else {
            if (activeTab == arr[count-1]) {
                setNextButton('last');
            } else {                
                index = index+1;
                changeTab(arr[index], arr2[index], arr2[index-1]);
            }
        }
    }

    return (
        <MainPageContentWrapper>
                <Modal
                    show={showModalExist}
                    onHide={() => setShowModalExist(false)}
                    aria-labelledby="contained-modal-warning"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                            Atenção
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <b>{msgError}</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>

                <form
                    noValidate
                    autoComplete='off'
                    onSubmit={(e) => myHandleSubmit(e)}
                    className={`${classes.container} steppers`}
                >
                    <div className="col-lg-12">
                        <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                        >
                            <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                                <div className='card-body px-6 py-10'>
                                    <div className='stepper-nav'>
                                            <div className='stepper-item current' id="product-details" data-kt-stepper-element='nav' onClick={() => changeTab('product-details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                <div className='stepper-icon w-40px h-40px'>
                                                        <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                                </div>
                                                <div className='stepper-label'>
                                                        <h3 className='stepper-title text-break'>Detalhes</h3>
                                                        <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o produto/serviço</div>
                                                </div>
                                                </div>
                                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                            </div>
                                            <div className='stepper-item' id="product-structure" data-kt-stepper-element='nav' onClick={() => changeTab('product-structure', 'flaticon-settings', previousIcon)} style={{cursor: "pointer"}}>
                                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                <div className='stepper-icon w-40px h-40px'>
                                                        <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                        <i className="flaticon-settings icon_change_color" style={{color: "#666"}}></i>
                                                </div>
                                                <div className='stepper-label'>
                                                        <h3 className='stepper-title text-break'>Estrutura</h3>
                                                        <div className='stepper-desc fw-semibold text-break'>Estrutura do produto/serviço</div>
                                                </div>
                                                </div>
                                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                            </div>
                                            {
                                                hasVariation === 'y' && (
                                                    <>
                                                    <div className='stepper-item' id="grid" data-kt-stepper-element='nav' onClick={() => changeTab('grid', 'flaticon-grid-menu', previousIcon)} style={{cursor: "pointer"}}>
                                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                        <div className='stepper-icon w-40px h-40px'>
                                                                <i className='stepper-check fas fa-check'></i>
                                                                <i className="flaticon-grid-menu icon_change_color" style={{color: "#666"}}></i>
                                                        </div>
                                                        <div className='stepper-label'>
                                                                <h3 className='stepper-title text-break'>Grid</h3>
                                                                <div className='stepper-desc fw-semibold text-break'>Grades do produto/serviço</div>
                                                        </div>
                                                        </div>
                                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                                    </div>
                                                    <div className='stepper-item' id="variation" data-kt-stepper-element='nav' onClick={() => changeTab('variation', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                        <div className='stepper-icon w-40px h-40px'>
                                                                <i className='stepper-check fas fa-check'></i>
                                                                <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                                        </div>
                                                        <div className='stepper-label'>
                                                                <h3 className='stepper-title text-break'>Produto variante</h3>
                                                                <div className='stepper-desc fw-semibold text-break'>Informações sobre produto variante</div>
                                                        </div>
                                                        </div>
                                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                                    </div>
                                                    </>
                                                )
                                            }
                                            <div className='stepper-item' id="nfe" data-kt-stepper-element='nav' onClick={() => changeTab('nfe', 'flaticon-notepad', previousIcon)} style={{cursor: "pointer"}}>
                                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                <div className='stepper-icon w-40px h-40px'>
                                                        <i className='stepper-check fas fa-check'></i>
                                                        <i className="flaticon-notepad icon_change_color" style={{color: "#666"}}></i>
                                                </div>
                                                <div className='stepper-label'>
                                                        <h3 className='stepper-title text-break'>Nota Fiscal</h3>
                                                        <div className='stepper-desc fw-semibold text-break'>Nota fiscal do produto/serviço</div>
                                                </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>               
                        </div>
                        <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <div id="form-buttons"
                                className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                rounded p-2 position-fixed"
                                style={{ zIndex: 1 }}>
                                <Link to="/produtos">
                                    <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                </Link>
                                {
                                    user.isAccountant
                                    ? 
                                        <Button
                                        type="submit"
                                        variant="primary"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                    : <></>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    id='outlined-select-currency'
                                    select
                                    label='Tipo'
                                    required
                                    className={classes.error}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin='normal'
                                    //{...register('type')}
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.target.value);
                                        setTypeInvalid(e.target.value ? false : true)
                                    }}
                                    error={typeInvalid}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key='product' value='product'>
                                        Produto
                                    </MenuItem>

                                    <MenuItem key='service' value='service'>
                                        Serviço
                                    </MenuItem>
                                </TextField>

                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    label={`Nome do ${type === 'service' ? 'serviço' : 'produto'}`}
                                    className={classes.error}
                                    required
                                    margin='normal'
                                    value={nameProduct}
                                    onChange={(e) => {
                                        setNameProduct(e.target.value)
                                        setNameInvalid(e.target.value ? false : true)
                                    }}
                                    onBlur={(e) => {
                                        generateCodeProduct(e.target.value);
                                    }}
                                    error={nameInvalid}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />

                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    label={`Código do ${type === 'service' ? 'serviço' : 'produto'}`}
                                    margin='normal'
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/produtos" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs
                            activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)}
                            id='newproduct-form-tabs'
                            className="mb-5 mt-5"
                            style={{ display: "none" }}
                        >

                            <Tab
                                eventKey='product-details'
                                title='Detalhes do Produto'
                            >

                                <ProductDetails
                                    urlId={id}
                                    type={type}
                                    note={note}
                                    errors={errors}
                                    setNcm={setNcm}
                                    setNote={setNote}
                                    category={category}
                                    icmsRate={icmsRate}
                                    stockMin={stockMin}
                                    stockMax={stockMax}
                                    provider={provider}
                                    setState={setState}
                                    isKit={isKit}
                                    setIsKit={setIsKit}
                                    buyValue={buyValue}
                                    ipiMoney={ipiMoney}
                                    saleValue={saleValue}
                                    costValue={costValue}
                                    situation={situation}
                                    calcPrice={calcPrice}
                                    ipiPercent={ipiPercent}
                                    icmsStRate={icmsStRate}
                                    setIpiRate={setIpiRate}
                                    setPisRate={setPisRate}
                                    setFederal={setFederal}
                                    setCstRate={setCstRate}
                                    setMvaRate={setMvaRate}
                                    setCategory={setCategory}
                                    setIcmsRate={setIcmsRate}
                                    setCestCode={setCestCode}
                                    setStockMin={setStockMin}
                                    setStockMax={setStockMax}
                                    setProvider={setProvider}
                                    setIpiMoney={setIpiMoney}
                                    setBuyValue={setBuyValue}
                                    productSize={productSize}
                                    subCategory={subCategory}
                                    setSaleValue={setSaleValue}
                                    setCostValue={setCostValue}
                                    setSituation={setSituation}
                                    stockInitial={stockInitial}
                                    freightMoney={freightMoney}
                                    hasVariation={hasVariation}
                                    gridSelected={gridSelected}
                                    setIpiPercent={setIpiPercent}
                                    aditionalCost={aditionalCost}
                                    setIcmsStRate={setIcmsStRate}
                                    setCofinsRate={setCofinsRate}
                                    stockLocation={stockLocation}
                                    showModalPrice={showModalPrice}
                                    freightPercent={freightPercent}
                                    setProductSize={setProductSize}
                                    setGrossWeight={setGrossWeight}
                                    setSubCategory={setSubCategory}
                                    setTaxableUnit={setTaxableUnit}
                                    setProductsKit={setProductsKit}
                                    setLiquidColumn={setLiquidColumn}
                                    setStockInitial={setStockInitial}
                                    setDeferralRate={setDeferralRate}
                                    setHasVariation={setHasVariation}
                                    categoryProduct={categoryProduct}
                                    setFreightMoney={setFreightMoney}
                                    setGridSelected={setGridSelected}
                                    setAditionalCost={setAditionalCost}
                                    variationProduct={variationProduct}
                                    setStockLocation={setStockLocation}
                                    setProductOrigin={setProductOrigin}
                                    ordersNumber={ordersNumber}
                                    setOrdersNumber={setOrdersNumber}
                                    setTaxBenefitCode={setTaxBenefitCode}
                                    setShowModalPrice={setShowModalPrice}
                                    setFreightPercent={setFreightPercent}
                                    profitMarginMoney={profitMarginMoney}
                                    creditIcmsstMoney={creditIcmsstMoney}
                                    setMvaAdjustedRate={setMvaAdjustedRate}
                                    setCategoryProduct={setCategoryProduct}
                                    subCategoryProduct={subCategoryProduct}
                                    typeClassification={typeClassification}
                                    profitMarginPercent={profitMarginPercent}
                                    creditIcmsstPercent={creditIcmsstPercent}
                                    setVariationProduct={setVariationProduct}
                                    setProfitMarginMoney={setProfitMarginMoney}
                                    setCreditIcmsstMoney={setCreditIcmsstMoney}
                                    setTypeClassification={setTypeClassification}
                                    setSubCategoryProduct={setSubCategoryProduct}
                                    setProfitMarginPercent={setProfitMarginPercent}
                                    setCreditIcmsstPercent={setCreditIcmsstPercent}
                                    selectedCategoryProductUuid={selectedCategoryProductUuid}
                                    setSelectedCategoryProductUuid={setSelectedCategoryProductUuid}
                                    stockInvalid={stockInvalid}
                                    setStockInvalid={setStockInvalid}
                                    stockMinInvalid={stockMinInvalid}
                                    setStockMinInvalid={setStockMinInvalid}
                                    stockMaxInvalid={stockMaxInvalid}
                                    setStockMaxInvalid={setStockMaxInvalid}
                                    stockInitialInvalid={stockInitialInvalid}
                                    setStockInitialInvalid={setStockInitialInvalid}
                                    saleValueInvalid={saleValueInvalid}
                                    setSaleValueInvalid={setSaleValueInvalid}
                                    brandProduct={brandProduct}
                                    setBrandProduct={setBrandProduct}
                                    typeInvalid={typeInvalid}
                                    setTypeInvalid={setTypeInvalid}
                                    nameInvalid={nameInvalid}
                                    setNameInvalid={setNameInvalid}
                                    unitInvalid={unitInvalid}
                                    setUnitInvalid={setUnitInvalid}
                                    productByGtinEanCodeInvalid={productByGtinEanCodeInvalid}
                                    setProductByGtinEanCodeInvalid={setProductByGtinEanCodeInvalid}
                                    name={nameProduct}
                                    setName={setNameProduct}
                                    code={code}
                                    setCode={setCode}
                                    unit={unit}
                                    setUnit={setUnit}
                                    brand={brand}
                                    setBrand={setBrand}
                                    codeGtinEan={codeGtinEan}
                                    setCodeGtinEan={setCodeGtinEan}
                                    setType={setType}
                                    changeTabIcon={changeTabIcon}
                                />

                            </Tab>

                            <Tab
                                eventKey='product-structure'
                                title='Estrutura do Produto'
                                tabClassName={hasVariation === 'y' ? 'd-none' : ''}
                            >

                                <Grid container spacing={3}>
                                    <Grid item lg={12}>

                                        {hasVariation === 'y' ? '' : <>
                                            <div className="col-lg-12 p-0">
                                                <Card.Body className="p-0 pt-3">
                                                    <div className='d-flex align-items-center'>
                                                        <input
                                                            type='checkbox'
                                                            className='mr-2'
                                                            name='isKit'
                                                            style={{ cursor: 'pointer' }}
                                                            onChange={() => setIsKit(isKit === "y" ? "n" : "y")}
                                                            checked={isKit === "y" ? true : false}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                        <label
                                                            htmlFor='isKit'
                                                            className='m-0'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setIsKit(isKit === "y" ? "n" : "y")}
                                                        >
                                                            Produto é um Kit
                                                        </label>
                                                    </div>

                                                    {isKit === "y" ? (
                                                        <>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    productsKit.products.map(({ productId, productName, productCount }, index) => (

                                                                        <Grid key={index} container spacing={1}>
                                                                            <Grid item lg={4} md={6} xs={12}>
                                                                                <ApiResourceSelect
                                                                                    label="Produto"
                                                                                    getOptionLabel={(option: { id: number, name: string }) => option.name}
                                                                                    value={{ id: productId, name: productName }}
                                                                                    onSelect={(option) => {
                                                                                        var kit = productsKit.products;

                                                                                        kit[index].productId = option?.id ?? 0;
                                                                                        kit[index].productName = option?.name ?? '';
                                                                                        option && (kit[index].productCount = 1);

                                                                                        setProductsKit({ products: kit });
                                                                                    }}
                                                                                    apiSearchHandler={apiSearchProducts}
                                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item lg={2} md={6} xs={12}>
                                                                                <NumericFormat
                                                                                    label="Quantidade"
                                                                                    decimalScale={0}
                                                                                    thousandSeparator={''}
                                                                                    value={productCount}
                                                                                    onChange={(e) => changeCountProductKit(index, parseInt(e.target.value))}
                                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                                />
                                                                            </Grid>

                                                                            {
                                                                                user.isAccountant == "n"
                                                                                    ?
                                                                                    <Grid item lg={2} md={6} xs={12} className="d-flex align-items-center">
                                                                                        <Button variant='danger' onClick={() => deleteProductKit(index)}>Excluir</Button>
                                                                                    </Grid>
                                                                                    : <></>
                                                                            }
                                                                        </Grid>

                                                                    ))
                                                                }

                                                            </Grid>


                                                            {
                                                                user.isAccountant == "n"
                                                                    ?
                                                                    <div className='col-lg-5 pl-0 mt-5'>
                                                                        <Button variant='primary' className='mr-3' onClick={() => addProductToKit()}>
                                                                            Adicionar outro produto
                                                                        </Button>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                            <br />
                                                            <br />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Card.Body>
                                            </div>
                                        </>}

                                    </Grid>

                                </Grid>

                            </Tab>

                            <Tab
                                eventKey='grid'
                                title='Grades'
                                tabClassName={hasVariation === 'y' ? '' : 'd-none'}
                            >
                                <NewProductVariationGrid />
                            </Tab>

                            <Tab
                                eventKey='variation'
                                title='Variações do produto'
                                tabClassName={hasVariation === 'y' ? '' : 'd-none'}
                            >
                                <div className=''>
                                    {variationProduct.variation.map((currentVariation, index) => {
                                        if (index > 0) {
                                            return (
                                                <>
                                                    <Grid container spacing={3} className="pb-3 mb-4 border-bottom">
                                                        <Grid item lg={12}>
                                                            {`${nameProduct}-${currentVariation.grid[1]?.variationName ?? ''}`}
                                                        </Grid>

                                                        <Grid container spacing={3} className="mb-1">
                                                            {gridSelected.map(({ id, name, variation }) => {
                                                                return (

                                                                    <Grid item lg={3} md={6} xs={12}>
                                                                        <TextField
                                                                            size="small"
                                                                            select
                                                                            label={name}
                                                                            SelectProps={{
                                                                                MenuProps: {
                                                                                    className: classes.menu,
                                                                                },
                                                                            }}
                                                                            value={(variationProduct.variation[index].grid.find(gridObj => gridObj.gridId === id)?.variationId)}
                                                                            margin='normal'
                                                                            error={currentVariation.inputsInvalid.grid}
                                                                            onChange={(e) => {
                                                                                updateVariationProductGrid(index, id, Number(e.target.value))
                                                                                var variation = variationProduct.variation;
                                                                                var nameVariation = variationProduct.variation[index].grid.find(gridObj => gridObj.gridId === id)?.variationName;
                                                                                variation[index].grid[0].variationName = `${nameProduct}-${nameVariation}`;
                                                                                variation[index].inputsInvalid.grid = e.target.value ? false : true;
                                                                                generateCodeVariationProduct(nameProduct, Number(e.target.value), currentVariation.id, index);
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        >
                                                                            <MenuItem value='' disabled>
                                                                                Selecione
                                                                            </MenuItem>
                                                                            {variation.map((value, index2) => {
                                                                                if (value.idGrid == id) {
                                                                                    return (
                                                                                        <MenuItem key={index2} value={value.id}>
                                                                                            {value.variationName}
                                                                                        </MenuItem>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }
                                                                        </TextField>
                                                                    </Grid>

                                                                )
                                                            })
                                                            }
                                                        </Grid>

                                                        <Grid container spacing={3} className="mb-1">

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <TextField
                                                                    size="small"
                                                                    label='Código'
                                                                    margin='normal'
                                                                    value={currentVariation.code}
                                                                    onChange={e => updateVariationProduct(index, 'code', e.target.value)}
                                                                    disabled
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                {/* <TextField
                                                            size="small"
                                                            label='Localização no estoque'                                            
                                                            margin='normal'
                                                            value={currentVariation.stockLocation}
                                                            onChange={e => updateVariationProduct(index, 'stockLocation', e.target.value)}
                                                        /> */}

                                                                <ApiResourceSelect
                                                                    style={{ width: '100%' }}
                                                                    label="Localização no estoque"
                                                                    getOptionLabel={(option: StockLocations) => option.name}
                                                                    value={currentVariation.stockLocation}
                                                                    onSelect={(option) => {
                                                                        updateVariationProduct(index, 'stockLocation', option?.id ?? 0);
                                                                        var variation = variationProduct.variation;
                                                                        variation[index].inputsInvalid.stockLocation = option ? false : true;
                                                                    }}
                                                                    apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                                                                    getSelectedOption={(loadedOptions) => {
                                                                        if (!currentVariation.stockLocation) return null;
                                                                        return loadedOptions.find((option) => option.id === Number(currentVariation.stockLocation)) ?? StockLocationService.getStockLocationById(currentVariation.stockLocation)
                                                                    }}
                                                                    hasError={currentVariation.inputsInvalid.stockLocation}
                                                                    disabled={currentVariation.id !== 0 || user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Estoque mínimo"
                                                                    required
                                                                    margin="normal"
                                                                    type="number"
                                                                    value={currentVariation.stockMin}
                                                                    error={currentVariation.inputsInvalid.stockMin}
                                                                    onChange={(e) => {
                                                                        updateVariationProduct(index, 'stockMin', e.target.value)
                                                                        var variation = variationProduct.variation;
                                                                        variation[index].inputsInvalid.stockMin = e.target.value ? false : true;
                                                                    }}
                                                                    disabled={currentVariation.id !== 0 || user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Estoque máximo"
                                                                    required
                                                                    margin="normal"
                                                                    type="number"
                                                                    value={currentVariation.stockMax}
                                                                    error={currentVariation.inputsInvalid.stockMax}
                                                                    onChange={(e) => {
                                                                        updateVariationProduct(index, 'stockMax', e.target.value)
                                                                        var variation = variationProduct.variation;
                                                                        variation[index].inputsInvalid.stockMax = e.target.value ? false : true;
                                                                    }}
                                                                    disabled={currentVariation.id !== 0 || user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Estoque inicial"
                                                                    required
                                                                    margin="normal"
                                                                    type="number"
                                                                    value={currentVariation.stockInitial}
                                                                    error={currentVariation.inputsInvalid.stockInitial}
                                                                    onChange={(e) => {
                                                                        updateVariationProduct(index, 'stockInitial', e.target.value)
                                                                        var variation = variationProduct.variation;
                                                                        variation[index].inputsInvalid.stockInitial = e.target.value ? false : true;
                                                                    }}
                                                                    disabled={currentVariation.id !== 0 || user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3} className="mb-1">

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    label="Código GTIN/EAN"
                                                                    value={currentVariation.codeGtinEan}
                                                                    onChange={e => updateVariationProduct(index, 'codeGtinEan', e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <NumericFormat
                                                                    label="Peso bruto"
                                                                    startAdornment="KG"
                                                                    value={currentVariation.grossWeight}
                                                                    onChange={e => updateVariationProduct(index, 'grossWeight', e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <NumericFormat
                                                                    label="Peso líquido"
                                                                    startAdornment="KG"
                                                                    value={currentVariation.liquidWeight}
                                                                    onChange={e => updateVariationProduct(index, 'liquidWeight', e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <NumericFormat
                                                                    label="Valor de venda *"
                                                                    startAdornment="R$"
                                                                    value={currentVariation.saleValue}
                                                                    error={currentVariation.inputsInvalid.saleValue}
                                                                    onChange={(e) => {
                                                                        updateVariationProduct(index, 'saleValue', e.target.value)
                                                                        var variation = variationProduct.variation;
                                                                        variation[index].inputsInvalid.saleValue = e.target.value ? false : true;
                                                                    }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2} md={6} xs={12}>
                                                                <NumericFormat
                                                                    label="Valor de custo"
                                                                    startAdornment="R$"
                                                                    value={currentVariation.costValue}
                                                                    onChange={e => updateVariationProduct(index, 'costValue', e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            {
                                                                user.isAccountant == "n"
                                                                    ?
                                                                    <Grid item lg={2} md={6} xs={12} className="d-flex align-items-center">
                                                                        <Button variant="link" onClick={() => deleteVariationProduct(index)}><i className="flaticon-delete"></i></Button>
                                                                    </Grid>
                                                                    : <></>
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }

                                    })}

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <button
                                                type='button'
                                                className='btn btn-link p-0 mt-10'
                                                onClick={() => addVariationProduct()}
                                            >
                                                <ins>+ adicionar variação</ins>
                                            </button>
                                            : <></>
                                    }
                                </div>
                            </Tab>

                            <Tab
                                eventKey='nfe'
                                title='Nota Fiscal'
                            >
                                <div className='row col-lg-12'>
                                    <div className='col-lg-12'>
                                        <TextField
                                            size="small"
                                            id='outlined-select-currency'
                                            select
                                            label='Origem do produto'
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin='normal'
                                            // {...register('productOrigin')}
                                            onChange={e => setProductOrigin(e.target.value)}
                                            value={productOrigin}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key='0' value='0 - Nacional'>
                                                0 - Nacional
                                            </MenuItem>

                                            <MenuItem
                                                key='1'
                                                value='1 - Estrangeira - Importação direta'
                                            >
                                                1 - Estrangeira - Importação direta
                                            </MenuItem>

                                            <MenuItem
                                                key='2'
                                                value='2 - Estrangeira - Adquirida no mercado interno'
                                            >
                                                2 - Estrangeira - Adquirida no mercado interno
                                            </MenuItem>

                                            <MenuItem
                                                key='3'
                                                value='3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%'
                                            >
                                                3 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação superior a 40%
                                            </MenuItem>

                                            <MenuItem
                                                key='4'
                                                value='4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes'
                                            >
                                                4 - Nacional, cuja produção tenha sido feita em
                                                conformidade com os processos produtivos
                                                básicos de que tratam as legislações citadas
                                                nos Ajustes
                                            </MenuItem>

                                            <MenuItem
                                                key='5'
                                                value='5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%'
                                            >
                                                5 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação inferior ou igual a 40%
                                            </MenuItem>

                                            <MenuItem
                                                key='6'
                                                value='6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX'
                                            >
                                                6 - Estrangeira - Importação direta, sem
                                                similar nacional, constante em lista da CAMEX
                                            </MenuItem>

                                            <MenuItem
                                                key='7'
                                                value='7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX'
                                            >
                                                7 - Estrangeira - Adquirida no mercado interno,
                                                sem similar nacional, constante em lista da
                                                CAMEX
                                            </MenuItem>

                                            <MenuItem
                                                key='8'
                                                value='8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%'
                                            >
                                                8 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação superior a 70%
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    {/* <div className='col-lg-2'>
                                    <TextField
                                        size="small"
                                        select
                                        label='Unidade Tributável'
                                        className='ml-0'
                                        SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                        }}
                                        margin='normal'
                                        // {...register('taxableUnit')}
                                        onChange={e => setTaxableUnit(e.target.value)}
                                        value={taxableUnit}
                                    >
                                        <MenuItem key='0' value='Pç'>
                                            Pç
                                        </MenuItem>

                                        <MenuItem key='1' value='Kg'>
                                            Kg
                                        </MenuItem>

                                        <MenuItem key='2' value='Un'>
                                            Un
                                        </MenuItem>
                                    </TextField>
                                </div>

                                <div className='col-lg-2'>
                                    <TextField
                                        size="small"
                                        type="number"
                                        label='Quantidade Tributável'
                                        className='ml-0'
                                        margin='normal'
                                        value={taxableCount} 
                                        onChange={(e) => {
                                            setTaxableCount(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='col-lg-2'>
                                    <NumericFormat
                                        label="Valor Tributável"
                                        decimalScale={10}
                                        startAdornment="R$"
                                        value={taxableValue} 
                                        onChange={(e) => {
                                            setTaxableValue(e.target.value);
                                        }}
                                    />
                                </div> */}

                                    <div className='col-lg-12'>
                                        <Autocomplete
                                            size="small"
                                            value={ncmList.find(ncmData => String(ncmData.code) == String(ncm)) ?? { code: "", description: "" }}
                                            noOptionsText="Sem opções"
                                            options={ncmList}
                                            renderOption={(option) => option.code + ' - ' + option.description}
                                            style={{ width: "100%" }}
                                            getOptionLabel={(option) => option.code + ' - ' + option.description ?? ''}
                                            onChange={(e, val) => {
                                                setNcm(val ? val.code : "");
                                                updateCestCodeList(val);
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    label='NCM'
                                                    margin='normal'
                                                />
                                            }
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                        {/* {errors.ncm && errors.ncm.type === 'pattern' && (
                                        <span className='text-danger pl-3'>
                                        {errors.ncm.message}
                                        </span>
                                    )} */}
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Autocomplete
                                            size="small"
                                            value={cestCodeList.find(cest => String(cest.code) == String(cestCode)) ?? { code: '', description: '' }}
                                            noOptionsText="Sem opções"
                                            options={cestCodeList}
                                            renderOption={(option) => option.code + ' - ' + option.description}
                                            style={{ width: "100%" }}
                                            getOptionLabel={(option) => option.code ?? ''}
                                            onChange={(e, val) => { setCestCode(val ? val.code : "") }}
                                            renderInput={(params) =>
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    label='Código CEST'
                                                    placeholder='Código Especificador ST'
                                                    margin='normal'
                                                // {...register('cestCode')}
                                                />
                                            }
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="Peso bruto"
                                            startAdornment="KG"
                                            value={grossWeight}
                                            onChange={e => setGrossWeight(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="Peso líquido"
                                            startAdornment="KG"
                                            value={liquidColumn}
                                            onChange={e => setLiquidColumn(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="ICMS"
                                            startAdornment="%"
                                            value={icmsRate}
                                            onChange={e => setIcmsRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setIcmsRate("100");
                                                } else {
                                                    setIcmsRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="ICMS-ST"
                                            startAdornment="%"
                                            value={icmsStRate}
                                            onChange={e => setIcmsStRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setIcmsStRate("100");
                                                } else {
                                                    setIcmsStRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="MVA"
                                            startAdornment="%"
                                            value={mvaRate}
                                            onChange={e => setMvaRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setMvaRate("100");
                                                } else {
                                                    setMvaRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="MVA ajustado"
                                            startAdornment="%"
                                            value={mvaAdjustedRate}
                                            onChange={e => setMvaAdjustedRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setMvaAdjustedRate("100");
                                                } else {
                                                    setMvaAdjustedRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="Diferimento"
                                            startAdornment="%"
                                            value={deferralRate}
                                            onChange={e => setDeferralRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setDeferralRate("100");
                                                } else {
                                                    setDeferralRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="IPI"
                                            startAdornment="%"
                                            value={ipiRate}
                                            onChange={e => setIpiRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setIpiRate("100");
                                                } else {
                                                    setIpiRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>



                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <TextField
                                            size="small"
                                            label='Código benefício fiscal'
                                            className='ml-0'
                                            margin='normal'
                                            // {...register('taxBenefitCode')}
                                            onChange={e => setTaxBenefitCode(e.target.value)}
                                            value={taxBenefitCode}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="Estadual"
                                            startAdornment="%"
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setState("100");
                                                } else {
                                                    setState(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="Federal"
                                            startAdornment="%"
                                            value={federal}
                                            onChange={e => setFederal(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setFederal("100");
                                                } else {
                                                    setFederal(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="COFINS"
                                            startAdornment="%"
                                            value={cofinsRate}
                                            onChange={e => setCofinsRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setCofinsRate("100");
                                                } else {
                                                    setCofinsRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className='col-lg-2 col-md-6 col-sm-12'>
                                        <NumericFormat
                                            label="PIS"
                                            startAdornment="%"
                                            value={pisRate}
                                            onChange={e => setPisRate(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setPisRate("100");
                                                } else {
                                                    setPisRate(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>
                                </div>

                                <div className="row col-lg-12 mt-3">
                                    <div className="col bg-warning-o-30 p-5 mb-3">
                                        <p>
                                            <strong>Atenção: </strong>
                                            Se os campos abaixos não estiverem preenchidos ao emitir uma nota fiscal, será utilizado os parâmetros padrões configurados em
                                            <strong> Dados da Empresa -{'>'} Parâmetros </strong>
                                        </p>
                                    </div>
                                </div>

                                <div className="row col-lg-12">
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <TextField
                                            size="small"
                                            select
                                            label='ICMS-CST'
                                            className='ml-0'
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin='normal'
                                            value={cstRate}
                                            onChange={(e) => setCstRate(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem value=''>
                                                Selecione
                                            </MenuItem>

                                            <MenuItem value='00 - Tributada integralmente'>
                                                00 - Tributada integralmente
                                            </MenuItem>

                                            <MenuItem value='10 - Tributada e com cobrança do ICMS por substituição tributária'>
                                                10 - Tributada e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                                                10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                                            </MenuItem>

                                            <MenuItem value='20 - Tributada com redução de base de cálculo'>
                                                20 - Tributada com redução de base de cálculo
                                            </MenuItem>

                                            <MenuItem value='30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária'>
                                                30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='40 - Isenta'>
                                                40 - Isenta
                                            </MenuItem>

                                            <MenuItem value='41 - Não tributada'>
                                                41 - Não tributada
                                            </MenuItem>

                                            <MenuItem value='41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                                                41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                                            </MenuItem>

                                            <MenuItem value='50 - Com suspensão'>
                                                50 - Com suspensão
                                            </MenuItem>

                                            <MenuItem value='51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)'>
                                                51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)
                                            </MenuItem>

                                            <MenuItem value='60 - Cobrado anteriormente por substituição tributária'>
                                                60 - Cobrado anteriormente por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                                                60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                                            </MenuItem>

                                            <MenuItem value='70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária'>
                                                70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='90 - Outras (regime Normal)'>
                                                90 - Outras (regime Normal)
                                            </MenuItem>

                                            <MenuItem value='90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                                                90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                                            </MenuItem>

                                            <MenuItem value='101 - Tributada pelo Simples Nacional com permissão de crédito'>
                                                101 - Tributada pelo Simples Nacional com permissão de crédito
                                            </MenuItem>

                                            <MenuItem value='102 - Tributada pelo Simples Nacional com permissão de crédito'>
                                                102 - Tributada pelo Simples Nacional com permissão de crédito
                                            </MenuItem>

                                            <MenuItem value='103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta'>
                                                103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta
                                            </MenuItem>

                                            <MenuItem value='201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária'>
                                                201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária'>
                                                202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária'>
                                                203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='300 - Imune'>
                                                300 - Imune
                                            </MenuItem>

                                            <MenuItem value='400 - Não tributada pelo Simples Nacional'>
                                                400 - Não tributada pelo Simples Nacional
                                            </MenuItem>

                                            <MenuItem value='500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'>
                                                500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação
                                            </MenuItem>

                                            <MenuItem value='900 - Outras (regime Simples Nacional)'>
                                                900 - Outras (regime Simples Nacional)
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <TextField
                                            size="small"
                                            select
                                            label='IPI-CST'
                                            className='ml-0'
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin='normal'
                                            value={ipiCst}
                                            onChange={(e) => setIpiCst(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem value=''>
                                                Selecione
                                            </MenuItem>

                                            <MenuItem value='00 - Entrada com recuperação de crédito'>
                                                00 - Entrada com recuperação de crédito
                                            </MenuItem>

                                            <MenuItem value='01 - Entrada tributada com alíquota zero'>
                                                01 - Entrada tributada com alíquota zero
                                            </MenuItem>

                                            <MenuItem value='02 - Entrada isenta'>
                                                02 - Entrada isenta
                                            </MenuItem>

                                            <MenuItem value='03 - Entrada não-tributada'>
                                                03 - Entrada não-tributada
                                            </MenuItem>

                                            <MenuItem value='04 - Entrada imune'>
                                                04 - Entrada imune
                                            </MenuItem>

                                            <MenuItem value='05 - Entrada com suspensão'>
                                                05 - Entrada com suspensão
                                            </MenuItem>

                                            <MenuItem value='49 - Outras entradas'>
                                                49 - Outras entradas
                                            </MenuItem>

                                            <MenuItem value='50 - Saída tributada'>
                                                50 - Saída tributada
                                            </MenuItem>

                                            <MenuItem value='51 - Saída tributada com alíquota zero'>
                                                51 - Saída tributada com alíquota zero
                                            </MenuItem>

                                            <MenuItem value='52 - Saída isenta'>
                                                52 - Saída isenta
                                            </MenuItem>

                                            <MenuItem value='53 - Saída não-tributada'>
                                                53 - Saída não-tributada
                                            </MenuItem>

                                            <MenuItem value='54 - Saída imune'>
                                                54 - Saída imune
                                            </MenuItem>

                                            <MenuItem value='55 - Saída com suspensão'>
                                                55 - Saída com suspensão
                                            </MenuItem>

                                            <MenuItem value='99 - Outras saídas'>
                                                99 - Outras saídas
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <TextField
                                            size="small"
                                            select
                                            label='PIS-CST'
                                            className='ml-0'
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin='normal'
                                            value={pisCst}
                                            onChange={(e) => setPisCst(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem value=''>
                                                Selecione
                                            </MenuItem>

                                            <MenuItem value='00 - Entrada com recuperação de crédito'>
                                                00 - Entrada com recuperação de crédito
                                            </MenuItem>

                                            <MenuItem value='01 - Entrada tributada com alíquota zero'>
                                                01 - Entrada tributada com alíquota zero
                                            </MenuItem>

                                            <MenuItem value='02 - Entrada isenta'>
                                                02 - Entrada isenta
                                            </MenuItem>

                                            <MenuItem value='03 - Entrada não-tributada'>
                                                03 - Entrada não-tributada
                                            </MenuItem>

                                            <MenuItem value='04 - Entrada imune'>
                                                04 - Entrada imune
                                            </MenuItem>

                                            <MenuItem value='05 - Entrada com suspensão'>
                                                05 - Entrada com suspensão
                                            </MenuItem>

                                            <MenuItem value='49 - Outras entradas'>
                                                49 - Outras entradas
                                            </MenuItem>

                                            <MenuItem value='50 - Saída tributada'>
                                                50 - Saída tributada
                                            </MenuItem>

                                            <MenuItem value='51 - Saída tributada com alíquota zero'>
                                                51 - Saída tributada com alíquota zero
                                            </MenuItem>

                                            <MenuItem value='52 - Saída isenta'>
                                                52 - Saída isenta
                                            </MenuItem>

                                            <MenuItem value='53 - Saída não-tributada'>
                                                53 - Saída não-tributada
                                            </MenuItem>

                                            <MenuItem value='54 - Saída imune'>
                                                54 - Saída imune
                                            </MenuItem>

                                            <MenuItem value='55 - Saída com suspensão'>
                                                55 - Saída com suspensão
                                            </MenuItem>

                                            <MenuItem value='99 - Outras saídas'>
                                                99 - Outras saídas
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <TextField
                                            size="small"
                                            select
                                            label='COFINS-CST'
                                            className='ml-0'
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin='normal'
                                            value={cofinsCst}
                                            onChange={(e) => setCofinsCst(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem value=''>
                                                Selecione
                                            </MenuItem>

                                            <MenuItem value='01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)'>
                                                01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)
                                            </MenuItem>

                                            <MenuItem value='02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)'>
                                                02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)
                                            </MenuItem>

                                            <MenuItem value='03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto'>
                                                03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto
                                            </MenuItem>

                                            <MenuItem value='04 - Operação tributável: tributação monofásica (alíquota zero)'>
                                                04 - Operação tributável: tributação monofásica (alíquota zero)
                                            </MenuItem>

                                            <MenuItem value='05 - Operação tributável: substituição tributária'>
                                                05 - Operação tributável: substituição tributária
                                            </MenuItem>

                                            <MenuItem value='06 - Operação tributável: alíquota zero'>
                                                06 - Operação tributável: alíquota zero
                                            </MenuItem>

                                            <MenuItem value='07 - Operação isenta da contribuição'>
                                                07 - Operação isenta da contribuição
                                            </MenuItem>

                                            <MenuItem value='08 - Operação sem incidência da contribuição'>
                                                08 - Operação sem incidência da contribuição
                                            </MenuItem>

                                            <MenuItem value='09 - Operação com suspensão da contribuição'>
                                                09 - Operação com suspensão da contribuição
                                            </MenuItem>

                                            <MenuItem value='49 - Outras operações de saída'>
                                                49 - Outras operações de saída
                                            </MenuItem>

                                            <MenuItem value='50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno'>
                                                50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno
                                            </MenuItem>

                                            <MenuItem value='51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno'>
                                                51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno
                                            </MenuItem>

                                            <MenuItem value='52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação'>
                                                52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação
                                            </MenuItem>

                                            <MenuItem value='53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                                53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno
                                            </MenuItem>

                                            <MenuItem value='54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação'>
                                                54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                                55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                                56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno'>
                                                60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno
                                            </MenuItem>

                                            <MenuItem value='61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno'>
                                                61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno
                                            </MenuItem>

                                            <MenuItem value='62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação'>
                                                62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação
                                            </MenuItem>

                                            <MenuItem value='63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                                63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno
                                            </MenuItem>

                                            <MenuItem value='64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação'>
                                                64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                                65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                                66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                            </MenuItem>

                                            <MenuItem value='67 - Crédito presumido: outras operações'>
                                                67 - Crédito presumido: outras operações
                                            </MenuItem>

                                            <MenuItem value='70 - Operação de aquisição sem direito a crédito'>
                                                70 - Operação de aquisição sem direito a crédito
                                            </MenuItem>

                                            <MenuItem value='71 - Operação de aquisição com isenção'>
                                                71 - Operação de aquisição com isenção
                                            </MenuItem>

                                            <MenuItem value='72 - Operação de aquisição com suspensão'>
                                                72 - Operação de aquisição com suspensão
                                            </MenuItem>

                                            <MenuItem value='73 - Operação de aquisição a alíquota zero'>
                                                73 - Operação de aquisição a alíquota zero
                                            </MenuItem>

                                            <MenuItem value='74 - Operação de aquisição sem incidência da contribuição'>
                                                74 - Operação de aquisição sem incidência da contribuição
                                            </MenuItem>

                                            <MenuItem value='75 - Operação de aquisição por substituição tributária'>
                                                75 - Operação de aquisição por substituição tributária
                                            </MenuItem>

                                            <MenuItem value='98 - Outras operações de entrada'>
                                                98 - Outras operações de entrada
                                            </MenuItem>

                                            <MenuItem value='99 - Outras operações'>
                                                99 - Outras operações
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                </div>
                                {/* <NewProductInvoice2 /> */}

                            </Tab>

                            {/* <Tab 
                            eventKey='fotos-produto' 
                            title='Fotos do Produto'>
                        
                            <NewProductPicture2 />
                        
                        </Tab> */}

                            {/* <Tab eventKey='produto-variante' title='Produto Variante'>
                            <Grid container spacing={3}>
                                <Grid item lg={3} className='pl-7 d-flex align-items-center'>
                                    <FormControlLabel
                                        //value='end'
                                        control={<Checkbox color='primary' />}
                                        label={`${type === 'service' ? 'Serviço' : 'Produto'} variante`}
                                        labelPlacement='end'
                                        // {...register('isVariant')}
                                        checked={hasVariation === 'y' ? true : false}
                                        onChange={(e) => {
                                            setHasVariation(hasVariation === 'y' ? 'n' : 'y');
                                        }}
                                    //onChange={e => setHasVariation(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Tab> */}
                        </Tabs>
                    </div>
                    </div>
                </form>
        </MainPageContentWrapper>

    );

    function NewProductVariationGrid() {
        return <>
            {hasVariation !== 'y' ? '' : <>
                <div className='col-lg-12'>
                    <Card.Body>
                        <div className='d-flex align-items-center'>
                            {
                                grid.grids.map(({ gridId, gridName }, index) => {
                                    if (gridName) {
                                        return (
                                            <FormControlLabel
                                                value='end'
                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color='primary' onChange={(e) => {
                                                    selectGrid(gridId, gridName, e.target)
                                                }} />}
                                                label={gridName}
                                                labelPlacement='end'
                                                className='mr-10'
                                                checked={gridSelected.find(gridObj => gridObj.id === gridId) ? true : false}
                                            />
                                        )
                                    }

                                })
                            }
                        </div>
                    </Card.Body>

                </div>
            </>}
        </>
    }

    function NewProductPicture2() {

        return <>

            <div className='col-lg-12'>

                <input
                    className='form-control'
                    type='file'
                    id='formFile'
                />
                <label htmlFor='formFile' className='mt-3'>
                    Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.
                </label>

            </div>

        </>

    }

}