import React from "react";
import { ResponsiveContainer, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Bar, ComposedChart, Area, Line } from "recharts";
import { formatCurrency } from "../utils/formatCurrency";

type CashFlowComposedChartProps = {
    cashFlow: cashFlow[];
    cashReceive: number;
    cashPay: number;
    tooltipTitleHandler: (element: any) => string;
}

export type cashFlow = {
    name: string,
    despesas: number,
    receitas: number,
    saldo: number,
}

const styleToolTip = {
    background: "#fff",
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "5px",
    outline: "none"
};

export function CashFlowComposedChart({
    cashFlow,
    cashReceive,
    cashPay,
    tooltipTitleHandler
}: CashFlowComposedChartProps) {
    const renderTooltipCashFlow = (element: any) => {
        return (
            <div style={styleToolTip}>
                <b>
                    {tooltipTitleHandler(element)}
                </b>

                {
                    element.payload && element.payload.map((data: any, index: number) => {
                        return (
                            <p key={index} style={{ color: data.color }} className="mb-0">
                                <b>{data.name.charAt(0).toUpperCase() + data.name.slice(1)}:</b> {formatCurrency(data.value)}
                            </p>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart width={1000} height={300} data={cashFlow} >
                    <XAxis dataKey="name" scale={"band"} />
                    <YAxis />
                    <Tooltip wrapperStyle={{ outline: "none" }} content={renderTooltipCashFlow} />
                    <Legend />
                    <Bar dataKey="receitas" barSize={300} fill="#53EF66" stroke="#0fa321" />
                    <Bar dataKey="despesas" barSize={300} fill="#F64E60" stroke="#aa091a" />
                    <Line type="monotone" dataKey="saldo" stroke="#181C32" strokeWidth={2} />
                </ComposedChart>
            </ResponsiveContainer>



            <div className="row col-12 d-flex flex-row justify-content-between align-items-center mt-3 pt-3 border-top">
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b className="text-dark">Receitas <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "#22c55e" }}>{formatCurrency(cashReceive)}</h3>
                </div>
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b className="text-dark">Despesas <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "#ff0000" }}>{formatCurrency(cashPay)}</h3>
                </div>
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b className="text-dark">Saldo <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "#181C32" }}>{formatCurrency(cashReceive - cashPay)}</h3>
                </div>
            </div>
        </>
    );
}