import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import {
    makeStyles,
    MenuItem,
    TextField,
    Grid
} from '@material-ui/core';
import { Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useParams } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";
import '../../style.css';
import { getEnabledStatusText } from '../../utils/getEnabledStatusText';
import LogService from '../../services/LogService';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';


type CenterCost = {
    id: number;
    name: string;
    status: "active" | "inactive";
};


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));

export function EditCenterCost() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [name, setName] = useState('');
    const [status, setStatus] = useState<"active" | "inactive">("active");

    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();

    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    subHeader.setTitle("Adicionar um Centro de Custos");


    useLayoutEffect(() => {
        async function loadData() {
            const response = await api.get<CenterCost>(`centerCost/${id}`);

            setName(response.data.name);
            setStatus(response.data.status);
        }

        loadData();
    }, []);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();
        setIsSubmit(true);

        try {
            const lastInputs = await api.get<CenterCost>(`centerCost/${id}`);

            const data = {
                name,
                status
            };

            const result = await api.put(`centerCost/${id}`, data);

            LogService.logEdit({
                itemId: result.data.id,
                itemName: result.data.name,
                module: 'Centro de Custos',
                oldData: lastInputs.data,
                newData: result.data,
                formattedFields: {
                    status: (value) => getEnabledStatusText(value),
                },
                fieldsMap: {
                    name: 'Nome',
                    status: 'Status',
                },
            }); 
            
            setIsSubmit(false);

            history.push("/parametros/centro-de-custos");

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainPageContentWrapper>
            <form onSubmit={(evt) => onSubmit(evt)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o centro de custos</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/parametros/centro-de-custos">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            disabled={isSubmit}
                                        >
                                            {isSubmit ? <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />

                                                <span className="ml-2">
                                                    Aguarde...
                                                </span>
                                            </> : <>
                                                <span>
                                                    Salvar
                                                </span>
                                            </>}
                                        </Button>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={9} md={6} xs={12}> 
                                <TextField
                                    size="small"
                                    label="Descrição"
                                    margin="normal"
                                    value={name}
                                    onChange={(evt) => setName(evt.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>

                            <Grid item lg={3} md={6} xs={12}> 
                                <TextField
                                    select
                                    label="Status"
                                    size="small"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    value={status}
                                    onChange={
                                        ({ target: { value } }) => setStatus(value === "active" ? "active" : "inactive")
                                    }
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key="0" value="active">
                                        Ativo
                                    </MenuItem>                                

                                    <MenuItem key="1" value="inactive">
                                        Inativo
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}