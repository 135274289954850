import React, { useState, useEffect } from 'react';
import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';
import { request } from 'http';
import logService from '../../services/LogService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { Link } from 'react-router-dom';


const useStyles: any = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
}

export function NewBrand() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Marca");
    
    const [nameInvalid, setNameInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [brandName, setBrandName] = useState("");

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    useEffect(() => {
        
    }, []);

    function inputsVerify(): boolean {

        if (!brandName) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da marca corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        const uuidBrand = uuidv4();

        var raw = JSON.stringify({
            "uuidBrand": uuidBrand,
            "nameBrand": brandName,
        });

        const createResponse = await api.post("/brand", raw);

        logService.logRegister({
            itemId: createResponse.data.id,
            module: 'Marcas',
            itemName: createResponse.data.nameBrand,
        });

        setShowModalSuccess(true);
        setIsSubmit(false);
    }

    return (
        <MainPageContentWrapper>
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/marcas";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados salvos com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/marcas";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`} noValidate autoComplete="off">
                <div className='col-lg-12'>
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a marca</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/marcas">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    className={classes.error}
                                    label="Nome da Marca*"
                                    margin="normal"
                                    value={brandName}
                                    onChange={(e) => {
                                        setBrandName(e.target.value)
                                        setNameInvalid(e.target.value ? false : true)
                                    }}
                                    error={nameInvalid}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}