import { IconButton, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ModalError from '../../../components/ModalError';
import { useCompanyBranch } from '../../../hooks/companyBranch';
import api from '../../../services/Api';
import ModalSuccess from '../../../components/ModalSuccess';
import { useNf } from '../../../hooks/nf';
import { ReceivedNfe } from '../../../types/ReceivedNfe';
import { ManifestNfe } from './ManifestNfe';
import { ImportNfe } from './ImportNfe';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

export type ReceivedNfeForm = ReceivedNfe & {
    selectedActionError?: boolean;
}

export function ImportManifestEntry() {
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [modalErrorRedirectUrl, setModalErrorRedirectUrl] = useState<string>();
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [modalSuccessRedirectUrl, setModalSuccessRedirectUrl] = useState<string>();
    const [isSearching, setIsSearching] = useState(false);
    const [receivedNfes, setReceivedNfes] = useState<ReceivedNfeForm[]>([]);
    const [currentVersion, setCurrentVersion] = useState(0);
    const [maxVersion, setMaxVersion] = useState(-1);

    const [activeScreen, setActiveScreen] = useState<'search' | 'import' | 'manifest'>("search");

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { selectedCompany } = useCompanyBranch();
    const { nfeManifestEnabled } = useNf();

    useEffect(() => {
        if (!selectedCompany) return;

        const companyIsLegal = selectedCompany?.typePeople === 'legal';

        if (!companyIsLegal) {
            setMsgError('Para realizar a importação de notas recebidas, a empresa cadastrada precisa estar registrada como pessoa jurídica!');
            setModalErrorRedirectUrl('/entrada-de-mercadoria');
            setShowModalError(true);
            return;
        }

        if (!nfeManifestEnabled) {
            setMsgError('A empresa não está habilitada para consultar ou realizar recebimento de notas fiscais. Essa configuração pode ser habilitada em Dados da Empresa -> Parâmetros -> NF-e');
            setModalErrorRedirectUrl('/entrada-de-mercadoria');
            setShowModalError(true);
            return;
        }

        setCurrentVersion(selectedCompany.nfeManifestMaxVersion ?? 0);
    }, [selectedCompany, nfeManifestEnabled]);

    async function handleSearchReceivedNfs(type: 'import' | 'manifest') {
        setIsSearching(true);

        const { data } = await api.get<{ nfes: ReceivedNfe[], maxVersion: number }>('nfeManifest', {
            params: {
                version: currentVersion,
                pending: type === 'manifest' ? '1' : null,
            },
        });

        const { nfes: foundNfes, maxVersion } = data;

        setReceivedNfes(foundNfes);
        setMaxVersion(maxVersion ?? -1)
        setIsSearching(false);
        setActiveScreen(type);
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
                redirect={modalErrorRedirectUrl}
            />
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect={modalSuccessRedirectUrl}
            />
            <div className="card card-custom gutter-b">
                <div className="card-header row m-0 pt-2 pb-3" style={{ minHeight: '10px' }}>
                    <div className="col-lg-6">
                        <h4 className="card-label m-0 mt-2">
                            Importação pela Receita
                        </h4>
                    </div>
                </div>
                <div className="card-body mt-0 pt-0">
                    {activeScreen === 'search' && (
                        <>
                            <div className="row mt-3">
                                <div className="col bg-warning-o-30 p-5 mb-3">
                                    <h3>Pesquisar notas recebidas</h3>
                                    <p>
                                        Importe todas as notas fiscais de compras emitidas para o CNPJ da empresa.
                                        <br />Clique no botão <strong>Buscar Notas para Manifestação</strong> para confirmar o recebimento das notas fiscais.
                                        <br />Clique no botão <strong>Buscar Notas para Importação</strong> para importar as notas fiscais que foram confirmadas.
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-3 pr-0 align-items-center">
                                <div className='col-lg-6'>
                                    <TextField
                                        size="small"
                                        type="number"
                                        margin="normal"
                                        label="NSU atual"
                                        InputLabelProps={{ shrink: true }}
                                        value={currentVersion}
                                        onChange={(e) => setCurrentVersion(Number(e.target.value))}
                                        InputProps={{
                                            endAdornment: (
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip-top'>
                                                            NSU (Número Sequencial Único) é o identificador de cada NF-e, a Receita sempre irá retornar todas as notas fiscais que possuam o NSU superior ao NSU atual,
                                                            altere este valor para filtrar a busca de notas recebidas
                                                        </Tooltip>
                                                    }
                                                >
                                                    <IconButton
                                                        edge="end"
                                                    >
                                                        <i className="flaticon-info"></i>
                                                    </IconButton>
                                                </OverlayTrigger>
                                            ),
                                        }}
                                    />
                                </div>

                                <div className="col-lg-12">
                                    <Button
                                        type='button'
                                        variant="primary"
                                        disabled={isSearching}
                                        onClick={() => handleSearchReceivedNfs('manifest')}
                                    >
                                        {
                                            isSearching ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>
                                                </>
                                                :
                                                <span>Buscar Notas para Manifestação</span>
                                        }
                                    </Button>

                                    <Button
                                        type="button"
                                        className="ml-3"
                                        variant="primary"
                                        disabled={isSearching}
                                        onClick={() => handleSearchReceivedNfs('import')}
                                    >
                                        {
                                            isSearching ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>
                                                </>
                                                :
                                                <span>Buscar Notas para Importação</span>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    {activeScreen === 'manifest' && (
                        <ManifestNfe
                            selectedCompany={selectedCompany}
                            setShowModalError={setShowModalError}
                            setMsgError={setMsgError}
                            setShowModalSuccess={setShowModalSuccess}
                            setMsgSuccess={setMsgSuccess}
                            setModalSuccessRedirectUrl={setModalSuccessRedirectUrl}
                            receivedNfes={receivedNfes}
                            setReceivedNfes={setReceivedNfes}
                            maxVersion={maxVersion}
                        />
                    )}
                    {activeScreen === 'import' && (
                        <ImportNfe
                            selectedCompany={selectedCompany}
                            setShowModalError={setShowModalError}
                            setMsgError={setMsgError}
                            setShowModalSuccess={setShowModalSuccess}
                            setMsgSuccess={setMsgSuccess}
                            setModalSuccessRedirectUrl={setModalSuccessRedirectUrl}
                            confirmedNfes={receivedNfes}
                            setConfirmedNfes={setReceivedNfes}
                            maxVersion={maxVersion}
                        />
                    )}
                </div>
            </div>
        </MainPageContentWrapper>
    );
}