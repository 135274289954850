import React from "react";

import {
    makeStyles,
    TextField,
    Checkbox,
    MenuItem,
    InputAdornment,
    IconButton,
    FormControlLabel
} from '@material-ui/core';
import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab
} from "react-bootstrap";

import { Autocomplete } from "@material-ui/lab";
import { InfoAddressData } from "./useCompanyFormDetails";
import { useStyles } from "../../../hooks/styles";
import { PatternFormat } from "../../PatternFormat";

type CompanyFormDetailsProps = {
    typePeople: string;
    cpf: string;
    setCpf: React.Dispatch<React.SetStateAction<string>>;
    cnpj: string;
    setCnpj: React.Dispatch<React.SetStateAction<string>>;
    municipalRegistration: string;
    setMunicipalRegistration: React.Dispatch<React.SetStateAction<string>>;
    stateRegistration: string;
    setStateRegistration: React.Dispatch<React.SetStateAction<string>>;
    taxRegime: string;
    setTaxRegime: React.Dispatch<React.SetStateAction<string>>;
    cnae: string;
    setCnae: React.Dispatch<React.SetStateAction<string>>;
    rntrc: string;
    setRntrc: React.Dispatch<React.SetStateAction<string>>;
    responsible: string;
    setResponsible: React.Dispatch<React.SetStateAction<string>>;
    cell: string;
    setCell: React.Dispatch<React.SetStateAction<string>>;
    phone: string;
    setPhone: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    website: string;
    setWebsite: React.Dispatch<React.SetStateAction<string>>;
    zipCode: string;
    setZipCode: React.Dispatch<React.SetStateAction<string>>;
    address: string;
    setAddress: React.Dispatch<React.SetStateAction<string>>;
    district: string;
    setDistrict: React.Dispatch<React.SetStateAction<string>>;
    city: string;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    state: string;
    setState: React.Dispatch<React.SetStateAction<string>>;
    number: string;
    setNumber: React.Dispatch<React.SetStateAction<string>>;
    complement: string;
    setComplement: React.Dispatch<React.SetStateAction<string>>;
    cpfInvalid: boolean;
    setCpfInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    cnpjInvalid: boolean;
    setCnpjInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    responsibleInvalid: boolean;
    setResponsibleInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    cellInvalid: boolean;
    setCellInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    emailInvalid: boolean;
    setEmailInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    zipCodeInvalid: boolean;
    setZipCodeInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    addressInvalid: boolean;
    setAddressInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    numberInvalid: boolean;
    setNumberInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    districtInvalid: boolean;
    setDistrictInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    cityInvalid: boolean;
    setCityInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    stateInvalid: boolean;
    setStateInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    allStates: { states: never[] };
    setAllStates: React.Dispatch<React.SetStateAction<{ states: never[] }>>;

    CheckCnpj: (value: string, type?: string) => Promise<void>;
    infoAddress: (params?: InfoAddressData) => Promise<void>;
    onBlurCnpjOrCpf?: (fieldType: 'CNPJ' | 'CPF') => void;
}

export function CompanyFormDetails({
    typePeople,
    cpf, setCpf,
    cnpj, setCnpj,
    municipalRegistration, setMunicipalRegistration,
    stateRegistration, setStateRegistration,
    taxRegime, setTaxRegime,
    cnae, setCnae,
    rntrc, setRntrc,
    responsible, setResponsible,
    cell, setCell,
    phone, setPhone,
    email, setEmail,
    website, setWebsite,
    zipCode, setZipCode,
    address, setAddress,
    district, setDistrict,
    city, setCity,
    state, setState,
    number, setNumber,
    complement, setComplement,
    cpfInvalid, setCpfInvalid,
    cnpjInvalid, setCnpjInvalid,
    responsibleInvalid, setResponsibleInvalid,
    cellInvalid, setCellInvalid,
    emailInvalid, setEmailInvalid,
    zipCodeInvalid, setZipCodeInvalid,
    addressInvalid, setAddressInvalid,
    numberInvalid, setNumberInvalid,
    districtInvalid, setDistrictInvalid,
    cityInvalid, setCityInvalid,
    stateInvalid, setStateInvalid,
    allStates, setAllStates,
    CheckCnpj,
    infoAddress,
    onBlurCnpjOrCpf,
}: CompanyFormDetailsProps) {
    const classes = useStyles();
    
    return (
        <>
            {typePeople === 'physical'
                ?
                <>
                    <div className="row">

                        <div className="col-lg-6">
                            <PatternFormat
                                label="CPF*"
                                format="###.###.###-##"
                                mask="_"
                                value={cpf}
                                onChange={(e) => {
                                    setCpf(e.target.value);
                                    setCpfInvalid(!e.target.value)
                                }}
                                onBlur={() => onBlurCnpjOrCpf && onBlurCnpjOrCpf('CPF')}
                                error={cpfInvalid}
                            />
                        </div>


                    </div>
                </>
                :
                <>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-3">
                            <PatternFormat
                                label="CNPJ*"
                                format="##.###.###/####-##"
                                mask="_"
                                endAdornment={(
                                    <IconButton
                                        edge="end"
                                        aria-label="CNPJ search"
                                        onClick={() => CheckCnpj(cnpj)}
                                    >
                                        <i className="flaticon-search"></i>
                                    </IconButton>
                                )}
                                value={cnpj}
                                onChange={(e) => {
                                    setCnpj(e.target.value)
                                    setCnpjInvalid(!e.target.value)
                                }}
                                error={cnpjInvalid}
                                onBlur={() => onBlurCnpjOrCpf && onBlurCnpjOrCpf('CNPJ')}
                            />
                        </div>
                        <div className="col-lg-4">
                            <TextField
                                size="small"
                                label="Inscrição estadual"
                                margin="normal"
                                value={stateRegistration}
                                onChange={(e) => {
                                    setStateRegistration(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-1 pl-0">
                            <FormControlLabel
                                value="end"
                                control={(
                                    <Checkbox
                                        color="primary"
                                        onChange={e => setStateRegistration((e.target.checked) ? "ISENTO" : "")}
                                        checked={(stateRegistration === "ISENTO") ? true : false}
                                    />
                                )}
                                label="Isento"
                                labelPlacement="end"
                                className="ml-1"
                            />
                        </div>
                        <div className="col-lg-4">
                            <TextField
                                size="small"
                                label="Inscrição municipal"
                                margin="normal"
                                value={municipalRegistration}
                                onChange={(e) => {
                                    setMunicipalRegistration(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <TextField
                                select
                                label="Regime tributário"
                                size="small"
                                SelectProps={{
                                    autoWidth: false,
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                value={taxRegime}
                                onChange={e => setTaxRegime(e.target.value)}
                            >
                                <MenuItem key="1" value="simple national">
                                    Simples Nacional
                                </MenuItem>

                                <MenuItem key="2" value="simple national excess revenue" style={{ whiteSpace: 'normal' }}>
                                    Simples Nacional - excesso de receita de sublimite de receita bruta
                                </MenuItem>

                                <MenuItem key="3" value="normal regime">
                                    Regime Normal
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                size="small"
                                label="CNAE"
                                margin="normal"
                                value={cnae}
                                onChange={(e) => {
                                    setCnae(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                size="small"
                                label="RNTRC"
                                margin="normal"
                                value={rntrc}
                                onChange={(e) => {
                                    setRntrc(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </>
            }

            <div className="row">
                <div className="col-lg-6">
                    <TextField
                        size="small"
                        label="Responsável*"
                        // required
                        margin="normal"
                        value={responsible}
                        onChange={(e) => {
                            setResponsible(e.target.value);
                            setResponsibleInvalid(!e.target.value)
                        }}
                        error={responsibleInvalid}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 d-flex justify-content-between align-items-center">
                    <PatternFormat
                        label="CEP"
                        className="mr-3"
                        format="#####-###"
                        mask="_"
                        value={zipCode}
                        onChange={(e) => {
                            setZipCode(e.target.value);
                            setZipCodeInvalid(!e.target.value);
                        }}
                        error={zipCodeInvalid}
                    />

                    <Button variant="primary" onClick={() => infoAddress()} className="btn-sm"><i className="flaticon-search p-0"></i></Button>
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Endereço"
                        margin="normal"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                            setAddressInvalid(!e.target.value);
                        }}
                        error={addressInvalid}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Número"
                        margin="normal"
                        value={number}
                        onChange={(e) => {
                            setNumber(e.target.value);
                            setNumberInvalid(!e.target.value);
                        }}
                        error={numberInvalid}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Bairro"
                        margin="normal"
                        value={district}
                        onChange={(e) => {
                            setDistrict(e.target.value);
                            setDistrictInvalid(!e.target.value)
                        }}
                        error={districtInvalid}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Complemento"
                        margin="normal"
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Cidade"
                        margin="normal"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                            setCityInvalid(!e.target.value)
                        }}
                        error={cityInvalid}
                    />
                </div>

                <div className="col-lg-4 pt-3">
                    <Autocomplete
                        size="small"
                        value={{ sigla: state }}
                        options={allStates.states}
                        getOptionLabel={({ sigla }) => sigla}
                        style={{ width: "100%", marginTop: "6px" }}
                        onInputChange={(event, newInputValue) => {
                            setState(newInputValue);
                            setStateInvalid(!newInputValue);
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Estado"
                            error={stateInvalid}
                        />
                        }
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="E-mail*"
                        className={classes.error}
                        margin="normal"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            setEmailInvalid(!e.target.value)
                        }}
                        error={emailInvalid}
                    />
                </div>

                <div className="col-lg-4">
                    <PatternFormat
                        label="Telefone"
                        className={classes.error}
                        format="(##) ####-####"
                        mask="_"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <PatternFormat
                        label="Celular*"
                        className={classes.error}
                        format="(##) #####-####"
                        mask="_"
                        value={cell}
                        onChange={(e) => {
                            setCell(e.target.value)
                            setCellInvalid(!e.target.value)
                        }}
                        error={cellInvalid}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Website"
                        margin="normal"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div>
            </div>
        </>
    );
}