import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { colorSuccess } from "../utils/styles";

export type YesOrNoBadgeParams = {
    yesText?: string;
    noText?: string;
    value: any;
};

export function YesOrNoBadge({
    yesText = 'Sim',
    noText = 'Não',
    value,
}: YesOrNoBadgeParams) {
    const [valueParsed, setValueParsed] = useState(false);

    useEffect(() => {
        const boolValue = ['y', 'yes', 'sim', 'true', '1', 'on'].includes(String(value).toLowerCase());
        setValueParsed(boolValue);
    }, [value]);

    return (
        <Badge
            pill
            className={valueParsed ? "badge-light-success" : "badge-light-danger"}
        >
            {valueParsed ? yesText : noText}
        </Badge>
    );
}