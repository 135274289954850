import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Grid,
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal,
    Alert
} from "react-bootstrap";

import { useSubheader } from "../../../_metronic/layout";

import api from '../../services/Api';
import { paymentOptions } from '../../utils/paymentOptions';
import ModalError from '../../components/ModalError';
import { NumericFormat } from '../../components/NumericFormat';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import useChangeBillSituation from '../../hooks/changeBillSituation';
import { format } from 'date-fns';
import ModalChangeBillSituation from '../../components/ModalChangeBillSituation';
import LogService from '../../services/LogService';
import { getSituationText } from '../../utils/getSituationText';
import { SubCategory } from '../../types/Dre';
import { BillsToPay } from '../../types/BillsToPay';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import { partialPayments } from '../../components/ListWithModalChangeSituation';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import CenterCostService from '../../services/CenterCostService';
import { BankAccount } from '../../types/BankAccount';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import { formatStringDateToLocale } from '../../utils/dateFormat';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import BillToPayService from '../../services/BillToPayService';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import useAddAccountBank from '../../hooks/addAccountBank';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Beneficiary = {
    id: number;
    name: string;
}

type partialPaymentsView = partialPayments & {
    bankAccountName?: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));


export function EditBillsToPay() {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();
    
    const [activeTab, setActiveTab] = useState("detalhes-despesa");

    const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [barCode, setBarCode] = useState('');
    const [payment, setPayment] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [isPaid, setIsPaid] = useState(true);
    const [isCanceled, setIsCanceled] = useState(false);
    const [reasonCanceled, setReasonCanceled] = useState("");
    const [comments, setComments] = useState('');
    const [beneficiaryType, setBeneficiaryType] = useState("supplier");
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [docNumber, setDocNumber] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [centerCost, setCenterCost] = useState('');
    const [occurrence, setOccurrence] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)

    const [isSubmit, setIsSubmit] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    // Inputs Verify
    const [expensesError, setExpensesError] = useState(false);
    const [payMethodsError, setPayMethodsError] = useState(false);
    const [dueError, setDueError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [issueDateError, setIssueDateError] = useState(false);
    const [bankAccountError, setBankAccountError] = useState<boolean>(false);
    const [accountsPlanError, setAccountsPlanError] = useState<boolean>(false);

    const [partialJson, setPartialJson] = useState<partialPaymentsView[]>([]);
    const [pagesPartial, setPagesPartial] = useState(0);
    const [rowsPerPagePartial, setRowsPerPagePartial] = useState(10);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const detailsRequiredFields = [name, payment, dueDate, amount, issueDate, bankAccount, dreSubCategoryId];
    const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();
    const {
        isSituationModalOpen,
        setIsSituationModalOpen,
        isReversalModalOpen,
        setIsReversalModalOpen,
        dueDate: dueDateInfo,
        setDueDate: setDueDateInfo,
        remaining,
        setRemaining,
        status,
        setStatus,
        paymentDate,
        setPaymentDate,
        valueToPay,
        setValueToPay,
        fee,
        setFee,
        discount,
        setDiscount,
        tax,
        setTax,
        acc,
        setAcc,
        total,
        setTotal,
        writeOffValue,
        partialPayments,
        setPartialPayments,
        validateDiscount,
        getChangedStatus,
    } = useChangeBillSituation();

    subHeader.setTitle("Adicionar Conta a Pagar");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        setBankAccount(String(accountBank.id));
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setBeneficiaryId(String(createdCustomer.id));
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    useLayoutEffect(() => {
        async function loadData() {
            const { data } = await api.get<BillsToPay>(`billsToPay/${id}`);
            // const banks    = await api.get<BankAccount>(`/accountBank/${data.bankAccount}`);

            setName(data.name);
            setAmount(data.amount);
            setRemaining(data.remaining);
            // setIsPaid(data.status == "paid" ? true : false);
            setIsCanceled(data.status == "canceled" ? true : false);
            setReasonCanceled(data.reasonCanceled);
            setBarCode(data.barCode ?? '');
            setPayment(data.payment);
            setDueDate(data.dueDate);
            setComments(data.comments ?? '');
            setBeneficiaryType(data.beneficiaryType);
            setBeneficiaryId(
                data.beneficiaryType === 'supplier'
                    ? String(data.supplier ?? '')
                    : String(data.seller ?? '')
            );
            setBeneficiaryName(
                data.beneficiaryType === 'supplier'
                    ? CustomerService.getCustomerName(data.customerEntity)
                    : SellerService.getName(data.sellerEntity)
            );
            setIssueDate(data.issueDate);
            setDocNumber(data.docNumber ?? '');
            setCenterCost(data.centerCost ? String(data.centerCost) : '');
            setOccurrence(data.occurrence);
            setBankAccount(data.bankAccount);
            setCategoryName(data.categoryName);
            setDreSubCategoryId(data.dreSubCategoryId);

            setAcc(formatCurrency(data.accValue ?? 0));
            setTax(formatCurrency(data.taxValue ?? 0));
            setFee(formatCurrency(data.feeValue ?? 0));
            setDiscount(formatCurrency(data.discountValue ?? 0));
            setStatus(data.status);
            setPartialPayments(data.partialPayments);

            if (data.status == "paid") {
                setValueToPay(formatCurrency(data.amount));
            } else {
                setValueToPay(formatCurrency(data.remaining));
            }

            setPaymentDate(BillToPayService.getBillPaymentDate(data) ?? '');

            setBeforeSubmitData(data);
        }

        loadData();
    }, [id]);

    useEffect(() => {
        async function mapPartialPayments() {
            const partials: partialPayments[] = partialPayments ? JSON.parse(partialPayments) : [];

            const mapped: partialPaymentsView[] = await Promise.all(
                partials.map(async (partial) => {
                    const bank = await BankAccountService.getBankAccountById(partial.bankAccount);
                    return {
                        ...partial,
                        bankAccountName: bank ? `${bank.nameBank} - ${bank.name}` : '',
                    };
                })
            );

            setPartialJson(mapped);
        }

        mapPartialPayments();
    }, [partialPayments]);

    useEffect(() => {
        const oldAmount = beforeSubmitData.amount;
        const oldRemaining = beforeSubmitData.remaining;
        let remainingValue = 0;

        if(oldAmount === oldRemaining) {
            remainingValue = amount;
        } else {
            const paidValue = oldAmount - oldRemaining;
            remainingValue = amount - paidValue;
            remainingValue = remainingValue < 0 ? 0 : remainingValue;
        }

        if(remainingValue === 0) {
            setIsPaid(true);
        }

        setRemaining(remainingValue);
    }, [beforeSubmitData, amount]);

    useEffect(() => {
        const valueNumber = formatToFloat(valueToPay);
        const feeNumber = formatToFloat(fee);
        const taxNumber = formatToFloat(tax);
        const accNumber = formatToFloat(acc);
        const discountNumber = formatToFloat(discount);

        validateDiscount({
            valueNumber,
            feeNumber,
            taxNumber,
            accNumber,
            discountNumber,
        });
    }, [valueToPay, fee, tax, acc, discount]);

    const handleChangePagePartial = useCallback((next: number) => {
        setPagesPartial(next);
    }, []);

    const handleRowsPerPagePartial = useCallback((value: number) => {
        setPagesPartial(0);
        setRowsPerPagePartial(value);
    }, []);

    function handleOpenChangeSituationModal() {
        const dueDateFormatted = formatStringDateToLocale(dueDate);
        const defaultPaymentDate = dueDate || format(Date.now(), "yyyy-MM-dd");

        setValueToPay(formatCurrency(remaining));
        setTotal(remaining);
        setDueDateInfo(dueDateFormatted);
        setRemaining(status == "paid" ? 0 : remaining);
        
        if(status !== 'paid') {
            setPaymentDate(defaultPaymentDate);
        }

        setIsSituationModalOpen(true);
    }

    const handleChangeSituation = useCallback(async () => {
        setIsSubmit(true);
        if (!inputsVerify()) return

        try {
            const changedRemaining = remaining - formatToFloat(valueToPay);
            const changedStatus = getChangedStatus(changedRemaining);

            const partialsJson = partialPayments ? JSON.parse(partialPayments) : null;

            var rawData;
            var auxPartials: any = [];
            if (changedRemaining == 0 && partialsJson == null) {
                rawData = {
                    status: changedStatus,
                    dueDate,
                    payment,
                    payedDate: changedStatus === 'paid' ? paymentDate : null,
                    remaining: changedRemaining,
                    bankAccount,
                    accValue: Number(formatToFloat(acc)),
                    taxValue: Number(formatToFloat(tax)),
                    discountValue: Number(formatToFloat(discount)),
                    feeValue: Number(formatToFloat(fee)),
                    totalPaid: Number((formatToFloat(total))),
                };

            } else if (changedRemaining == 0 && partialsJson) {
                auxPartials = [
                    ...partialsJson,
                    {
                        payedDate: paymentDate,
                        writeOffValue,
                        feeValue: Number(formatToFloat(fee)),
                        discountValue: Number(formatToFloat(discount)),
                        taxValue: Number(formatToFloat(tax)),
                        accValue: Number(formatToFloat(acc)),
                        totalPaid: Number(formatToFloat(total)),
                        payment,
                        bankAccount
                    }
                ];

                rawData = {
                    status: "paid",
                    partialPayments: JSON.stringify(auxPartials),
                    remaining: changedRemaining
                };
                
            } else {
                if (partialsJson) {
                    auxPartials = [
                        ...partialsJson,
                        {
                            payedDate: paymentDate,
                            writeOffValue,
                            feeValue: Number(formatToFloat(fee)),
                            discountValue: Number(formatToFloat(discount)),
                            taxValue: Number(formatToFloat(tax)),
                            accValue: Number(formatToFloat(acc)),
                            totalPaid: Number(formatToFloat(total)),
                            payment,
                            bankAccount
                        }
                    ];

                } else {
                    auxPartials = [
                        {
                            payedDate: paymentDate,
                            writeOffValue,
                            feeValue: Number(formatToFloat(fee)),
                            discountValue: Number(formatToFloat(discount)),
                            taxValue: Number(formatToFloat(tax)),
                            accValue: Number(formatToFloat(acc)),
                            totalPaid: Number(formatToFloat(total)),
                            payment,
                            bankAccount
                        }
                    ];
                }

                rawData = {
                    partialPayments: JSON.stringify(auxPartials),
                    remaining: changedRemaining
                };
            }

            await saveBillToPay(rawData);

            setIsSituationModalOpen(false);
            setIsPaid(true);
            setStatus(changedStatus);

            if (auxPartials.length) {
                setPartialPayments(JSON.stringify(auxPartials));
                setRemaining(changedRemaining);
            }

        } catch(error: any) {
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao salvar a conta!');
            console.log(error.message);
        }
        setIsSubmit(false);
    }, [saveBillToPay]);

    const handleReversal = useCallback(async () => {
        setIsSubmit(true);
        // if (!inputsVerify()) return

        const hasPermission = await BillToPayService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate, selectedCompany);
        if (!hasPermission) {
            setMsgError('O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema.');
            setIsReversalModalOpen(false);
            setShowModalError(true);
            return;
        }

        try {
            await saveBillToPay({
                status: 'pending',
                remaining: amount,
                accValue: 0,
                taxValue: 0,
                discountValue: 0,
                feeValue: 0,
                totalPaid: 0,
                payedDate: "",
                partialPayments: null
            });

            setIsReversalModalOpen(false);
            setIsPaid(true);
            setStatus('pending');
            setAcc("R$0,00");
            setTax("R$0,00");
            setFee("R$0,00");
            setDiscount("R$0,00");
            setRemaining(amount);
            setPartialPayments(null);


        } catch(error: any) {
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao salvar a conta!');
            console.log(error.message);
        }
        setIsSubmit(false);
    }, [saveBillToPay]);

    function handleCloseBillSituationModal() {
        setIsSituationModalOpen(false);
        setBankAccount('');
    };

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return

        try {
            await saveBillToPay();

            setIsSubmit(false);

            history.push("/contas-a-pagar");

        } catch (error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    async function saveBillToPay(additionalData?: any) {
        const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);
            
        let data: any = {
            isPaid,
            name,
            amount,
            remaining: Number(remaining),
            bankAccount: bankAccount ? parseInt(bankAccount) : null,
            nameBank: bankAccount ? bank.data.nameBank : null,
            barCode: !!barCode && barCode.length > 0 ? barCode : null,
            categoryName: !!categoryName && categoryName.length > 0 ? categoryName : null,
            centerCost: !!centerCost && centerCost.length > 0 ? parseInt(centerCost) : null,
            comments: !!comments && comments.length > 0 ? comments : null,
            docNumber: !!docNumber && docNumber.length > 0 ? docNumber : null,
            dueDate: !!dueDate && dueDate.length > 0 ? dueDate : null,
            issueDate: !!issueDate && issueDate.length > 0 ? issueDate : null,
            // occurrence: !!occurrence && occurrence.length > 0 ? occurrence : null,
            payment: !!payment && payment.length > 0 ? payment : null,
            beneficiaryType,
            supplier: beneficiaryType === 'supplier' ? (beneficiaryId || null) : null,
            seller: beneficiaryType === 'seller' ? (beneficiaryId || null) : null,
            dreSubCategoryId: !!dreSubCategoryId && dreSubCategoryId > 0 ? dreSubCategoryId : null,
        }

        if(remaining === 0) {
            data.status = 'paid';
        }

        if(additionalData) {
            data = {
                ...data,
                ...additionalData,
            };
        }

        const response = await api.put(`billsToPay/${id}`, data);

        LogService.logEdit({
            itemId: response.data.id,
            itemName: response.data.name,
            module: 'Contas a Pagar',
            oldData: {
                ...beforeSubmitData,
                beneficiaryName: beforeSubmitData.beneficiaryType === 'supplier'
                    ? CustomerService.getCustomerName(beforeSubmitData.customerEntity)
                    : SellerService.getName(beforeSubmitData.sellerEntity),
            },
            newData: {
                ...response.data,
                beneficiaryName,
            },
            formattedFields: {
              bankAccount: async (value) => (await BankAccountService.getBankAccountById(Number(value)))?.name ?? '',
              centerCost: async (value) => (await CenterCostService.getCenterCostById(Number(value)))?.name ?? '',
              issueDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
              dueDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
              amount: (value) => formatNumberToString(value),
              status: (value) => getSituationText(value),
            },
            fieldsMap: {
              name: 'Nome da despesa',
              bankAccount: 'Conta bancária',
              payment: 'Forma de pagamento',
              categoryName: 'Plano de contas',
              beneficiaryName: 'Beneficiário',
              dueDate: 'Data de Vencimento',
              amount: 'Valor',
              issueDate: 'Data da emissão',
              barCode: 'Código de barras',
              docNumber: 'Nº do documento',
              centerCost: 'Centro de custos',
              comments: 'Observações',
              status: 'Status',
            },
        });   
        
        setBeforeSubmitData(response.data);
    }

    function inputsVerify() {
        setExpensesError(false);
        setPayMethodsError(false);
        setDueError(false);
        setValueError(false);
        setIssueDateError(false);

        if(!name) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Nome da despesa é obrigatório!");
            setShowModalError(true);
            setExpensesError(true);
            return false;
        }

        if(!bankAccount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Selecione uma conta bancária!");
            setShowModalError(true);
            return false;
        }

        if(!categoryName) {
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!payment) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            setPayMethodsError(true);
            return false;
        }

        if(!dueDate) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Vencimento é obrigatório!");
            setShowModalError(true);
            setDueError(true);
            return false;
        }

        if(!amount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }
        if(amount <= 0) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }

        if(!issueDate) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Data de Emissão é obrigatório!");
            setShowModalError(true);
            setIssueDateError(true);
            return false;
        }

        if(issueDate) {
            const dateIsValid = dateIsBetweenRange(issueDate, '', extractDateStringFromTimestamp());
            if(!dateIsValid) {
                setActiveTab("detalhes-despesa");
                setIsSubmit(false);
                setMsgError("A data de emissão não pode ser maior que a data atual!");
                setShowModalError(true);
                setIssueDateError(true);
                return false;
            }
        }

        return true;
    }

    function handleChangeBeneficiaryType(value: string) {
        setBeneficiaryType(value);
        setBeneficiaryId('');
        setBeneficiaryName('');
    }

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-doc") {
            if (isDetailsRequiredFieldsFilled) {
                const current_icon = document.querySelector<HTMLElement>('#detalhes-despesa .flaticon2-delete');
                
                if (current_icon) {
                    current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                    current_icon?.classList.replace("text-danger", "text-success");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
                    prev_icon?.classList.add("text-success");
                }
            } else {
                const current_icon = document.querySelector<HTMLElement>('#detalhes-despesa .flaticon2-check-mark');

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('detalhes-despesa')?.classList.remove('current');
        document.getElementById('detalhes-conta')?.classList.remove('current');
        document.getElementById('detalhes-pagamento')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
            all_icons.forEach(function(item){
                item.style.color = '#666';
            });
        }

        if (!detailsRequiredFields[0]) {
            setExpensesError(true);
        }

        if (!detailsRequiredFields[1]) {
            setPayMethodsError(true);
        }

        if (!detailsRequiredFields[2]) {
            setDueError(true);
        }

        if (!detailsRequiredFields[3]) {
            setValueError(true);
        }

        if (!detailsRequiredFields[4]) {
            setIssueDateError(true);
        }

        if (!detailsRequiredFields[5]) {
            setBankAccountError(true);
        }

        if (!detailsRequiredFields[6]) {
            setAccountsPlanError(true);
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
            sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'detalhes-despesa') {
            setNextButton('first');
        } else if (partialPayments && tab === 'detalhes-pagamento' || !partialPayments && tab === 'detalhes-conta') {
            setNextButton('last');
        } else {
            setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['detalhes-despesa', 'detalhes-conta', 'detalhes-pagamento'];
        let arr2 = ['flaticon-doc', 'flaticon-file-2', 'flaticon-notepad'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
            if (activeTab == arr[0]) {
                setNextButton('first');
            } else {
                index = index-1;
                changeTab(arr[index], arr2[index], arr2[index+1]);
            }
        } else {
            if (activeTab == arr[count-1]) {
                setNextButton('last');
            } else {                
                index = index+1;
                changeTab(arr[index], arr2[index], arr2[index-1]);
            }
        }
    }

    return (

        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            {/* Modal de situação */}
            <ModalChangeBillSituation
                isSituationModalOpen={isSituationModalOpen}
                handleCloseModal={handleCloseBillSituationModal}
                viewOnly={status == "paid"}
                situationModalTitle={status == "paid" ? 'Ver pagamento' : 'Liquidar conta a pagar'}
                billsToPay={true}
                name={name}
                status={status}
                supplier={beneficiaryName}
                dueDate={dueDateInfo}
                amount={amount}
                remaining={remaining}
                paymentDate={paymentDate}
                setPaymentDate={setPaymentDate}
                payment={payment}
                setPayment={setPayment}
                bankAccount={bankAccount}
                handleChangeBankAccount={(bankAccount) => setBankAccount(String(bankAccount?.id ?? ''))}
                valueToPay={valueToPay}
                setValueToPay={setValueToPay}
                fee={fee}
                setFee={setFee}
                discount={discount}
                setDiscount={setDiscount}
                tax={tax}
                setTax={setTax}
                acc={acc}
                setAcc={setAcc}
                handleChangeSituation={handleChangeSituation}
                total={total}
                setTotal={setTotal}
                writeOffValue={writeOffValue}
                partialPayments={partialPayments}
                isPaid={isPaid}
                setIsPaid={setIsPaid}
                isSubmitting={isSubmit}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            {/* Modal estorno */}
            <Modal
                centered
                aria-labelledby="contained-modal-warning"

                show={isReversalModalOpen}
                onHide={() => setIsReversalModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <strong>Tem certeza que deseja estornar este registro ?</strong>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="warning" onClick={() => handleReversal()} disabled={isSubmit}>
                        {isSubmit ? 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : 
                            <>
                                <span>
                                    Sim
                                </span>
                            </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setIsReversalModalOpen(false)}>Não</Button>
                </Modal.Footer>
            </Modal>

            <form
                className={`${classes.container} steppers`}
                onSubmit={(evt) => onSubmit(evt)}
            >
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="detalhes-despesa" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-despesa', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Despesa</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a despesa</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="detalhes-conta" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-conta', 'flaticon-file-2', previousIcon)}  style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                <i className="flaticon-file-2 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Conta</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a conta</div>
                                            </div>
                                        </div>
                                        {
                                            partialPayments
                                            ?
                                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                            : <></>
                                        }
                                    </div>
                                    {
                                        partialPayments
                                        ?
                                        <div className='stepper-item' id="detalhes-pagamento" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-pagamento', 'flaticon-notepad', previousIcon)}  style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                                <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-notepad icon_change_color" style={{color: "#666"}}></i>
                                                </div>
                                                <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes do Pagamento</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o pagamento da conta</div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/contas-a-pagar">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <div className="d-flex" style={{ gap: "0.5rem" }}>
                                        {
                                            user.isAccountant == "n"
                                            ?
                                            <>
                                            {
                                                status == "paid"
                                                ?
                                                <>
                                                    <Button
                                                        type='button'
                                                        variant="secondary"
                                                        onClick={() => setIsReversalModalOpen(true)}
                                                    >
                                                        <span>Estornar Conta</span>
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    {
                                                        !isCanceled
                                                        ?
                                                            <Button
                                                                type='submit'
                                                                variant="primary"
                                                                disabled={isSubmit}
                                                            >
                                                                {isSubmit ? <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                        
                                                                    <span className='ml-2'>
                                                                        Aguarde...
                                                                    </span>
                                                                </> : <>
                                                                    <span>
                                                                        Salvar
                                                                    </span>
                                                                </>}
                                                            </Button>
                                                        : <></>
                                                    }
                                                </>
                                            }
                                            </>
                                            : <></>
                                        }

                                        {
                                            !isCanceled
                                            ?
                                            <Button
                                                type='button'
                                                variant="secondary"
                                                onClick={handleOpenChangeSituationModal}
                                            >
                                                <span>{status == "paid" ? 'Ver pagamento' : 'Liquidar conta'}</span>
                                            </Button>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="mb-3 mt-8 mt-lg-22 mt-md-10">
                            {nextButton === 'first' ? (
                                <Link to="/contas-a-pagar" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Link>
                            ) : (
                                <Button
                                type="button"
                                variant="outline-primary"
                                onClick={() => backNextOption('back')}
                                className="mb-2 mr-2 btn-lg"
                                >
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Button>
                            )}
                            <Button
                                type="button"
                                variant={nextButton === 'last' ? 'secondary' : 'primary'}
                                onClick={() => backNextOption('next')}
                                className="mb-2 btn-lg"
                                disabled={nextButton === 'last'}
                            >
                                <span>
                                    Avançar
                                </span>&nbsp;
                                <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                            </Button>
                            <hr />
                        </div>

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs" style={{ display: "none" }}>
                            <Tab eventKey="detalhes-despesa" title="Detalhes da Despesa" className="mb-15">
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            label="Nome da despesa*"
                                            margin="normal"
                                            error={expensesError}
                                            className={classes.error}
                                            size="small"
                                            value={name}
                                            onChange={(evt) => {
                                                setName(evt.target.value)
                                                setExpensesError(evt.target.value ? false : true)
                                                changeTabIcon('flaticon-doc')
                                            }}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Conta bancária *"
                                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                            value={bankAccount}
                                            hasError={bankAccountError}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            onSelect={(option) => {setBankAccount(String(option?.id ?? '')); setBankAccountError(option?.id ? false : true); changeTabIcon('flaticon-doc');}}
                                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!bankAccount) return null;
                                                return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                                            }}
                                            renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            select
                                            label="Forma de Pagamento*"
                                            className={classes.error}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            size="small"
                                            value={payment}
                                            onChange={(evt) => {
                                                setPayment(evt.target.value)
                                                setPayMethodsError(evt.target.value ? false : true)
                                                changeTabIcon('flaticon-doc')
                                            }}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            error={payMethodsError}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            {
                                                paymentOptions.map((payment, index) => (
                                                    <MenuItem key={index + 1} value={payment.value}>
                                                        {payment.value}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Plano de Contas *"
                                            getOptionLabel={(option: SubCategory) => option.name}
                                            value={dreSubCategoryId}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            onSelect={(option) => {setDreSubCategoryId(option?.id ?? 0); setAccountsPlanError(option?.id !== 0 ? false : true); changeTabIcon('flaticon-doc');}}
                                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!dreSubCategoryId) return null;
                                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                            }}
                                        />
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            value={beneficiaryType}
                                            select
                                            label="Tipo Beneficiário"
                                            size="small"
                                            className="ml-0"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="supplier">
                                                Fornecedor
                                            </MenuItem>

                                            <MenuItem key="1" value="seller">
                                                Vendedor
                                            </MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <ApiResourceSelect
                                            style={{ width: "100%" }}
                                            label="Beneficiário"
                                            getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                                            value={beneficiaryId}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            onSelect={(option) => { setBeneficiaryId(option ? String(option.id) : ''); setBeneficiaryName(option?.name ?? '') }}
                                            apiSearchHandler={beneficiaryType === 'supplier'
                                                ? ((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true }))
                                                : ((typedText) => SellerService.getSellersFiltered({ name: typedText }))}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!beneficiaryId) return null;

                                                const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(beneficiaryId));
                                                if(loadedBeneficiary) return loadedBeneficiary;

                                                return beneficiaryType === 'supplier' ? CustomerService.getCustomerById(beneficiaryId) : SellerService.getSellerById(beneficiaryId);
                                            }}
                                            renderAddButton={(
                                                beneficiaryType === 'supplier'
                                                    ? (typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText)} />
                                                    : undefined
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            type="date"
                                            label="Vencimento*"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={dueDate}
                                            onChange={(evt) => {
                                                setDueDate(evt.target.value);
                                                setDueError(evt.target.value ? false : true);
                                                changeTabIcon('flaticon-doc')
                                            }}
                                            size="small"
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            error={dueError}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor*"
                                            startAdornment="R$"
                                            error={valueError}
                                            className={classes.error}
                                            value={amount}
                                            onChange={(evt) => {
                                                setAmount(formatToFloat(evt.target.value));
                                                setValueError(evt.target.value ? false : true);
                                                changeTabIcon('flaticon-doc');
                                            }}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            type="date"
                                            label="Data de emissão *"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={issueDate}
                                            onChange={(evt) => {setIssueDate(evt.target.value); setIssueDateError(evt.target.value ? false : true); changeTabIcon('flaticon-doc');}}
                                            size="small"
                                            error={issueDateError}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    {/* <div className="col-lg-2 d-flex align-items-center">
                                        <FormControlLabel
                                            label="Pago"
                                            className="ml-1"
                                            checked={isPaid}
                                            onChange={() => setIsPaid(state => !state)}
                                            control={
                                                <Checkbox color="primary" />
                                            }
                                        />
                                    </div> */}

                                </div>

                                <div className="row">
                                    <Grid item lg={5} md={6} xs={12}>
                                        <TextField
                                            label="Código de barras"
                                            margin="normal"
                                            size="small"
                                            value={barCode}
                                            onChange={(evt) => setBarCode(evt.target.value)}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </div>

                            </Tab>

                            <Tab eventKey="detalhes-conta" title="Detalhes da Conta" className="mb-15">
                                <div className="row">
                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            label="Nº do documento"
                                            className="ml-0"
                                            margin="normal"
                                            size="small"
                                            value={docNumber}
                                            onChange={(evt) => setDocNumber(evt.target.value)}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Centro de Custos"
                                            getOptionLabel={(option: CenterCost) => option.name}
                                            value={centerCost}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                            onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                            apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!centerCost) return null;
                                                return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                            }}
                                        />
                                    </Grid>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg-3">
                                        <TextField
                                            select
                                            label="Ocorrência"
                                            className="ml-0"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            size="small"
                                            value={occurrence}
                                            onChange={(evt) => setOccurrence(evt.target.value)}
                                        >
                                            <MenuItem key="0" value="unica">
                                                Única
                                            </MenuItem>

                                            <MenuItem key="1" value="parcelada">
                                                Parcelada
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <Grid item lg={5} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">Anexo</label>
                                        <input className="form-control" type="file" id="formFile" disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}/>
                                        <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Observações"
                                            multiline
                                            rows="2"
                                            className="ml-0"
                                            margin="normal"
                                            size="small"
                                            value={comments}
                                            onChange={(evt) => setComments(evt.target.value)}
                                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </div>

                            </Tab>

                            {
                                partialPayments
                                ?
                                <Tab eventKey="detalhes-pagamento" title="Detalhes do Pagamento" className="mb-15">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Valor baixa</TableCell>
                                                <TableCell>Juros</TableCell>
                                                <TableCell>Desconto</TableCell>
                                                <TableCell>Taxa</TableCell>
                                                <TableCell>Acréscimo</TableCell>
                                                <TableCell>Valor pago</TableCell>
                                                <TableCell>Conta bancária</TableCell>
                                                <TableCell>Forma pag.</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                partialJson.slice(pagesPartial * rowsPerPagePartial, pagesPartial * rowsPerPagePartial + rowsPerPagePartial).map((value, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{value.payedDate.split('-').reverse().join('/')}</TableCell>
                                                            <TableCell>{formatCurrency(value.writeOffValue)}</TableCell>
                                                            <TableCell>{formatCurrency(value.feeValue)}</TableCell>
                                                            <TableCell>{formatCurrency(value.discountValue)}</TableCell>
                                                            <TableCell>{formatCurrency(value.taxValue)}</TableCell>
                                                            <TableCell>{formatCurrency(value.accValue)}</TableCell>
                                                            <TableCell>{formatCurrency(value.totalPaid)}</TableCell>
                                                            <TableCell>{value.bankAccountName ?? ''}</TableCell>
                                                            <TableCell>{value.payment}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }                                            
                                        </TableBody>
                                    </Table>

                                    <TablePagination
                                        labelRowsPerPage="Linhas por página"
                                        page={pagesPartial}
                                        component="div"
                                        count={partialJson.length}
                                        rowsPerPage={rowsPerPagePartial}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        backIconButtonProps={{
                                            'aria-label': 'Página Anterior',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Próxima Página',
                                        }}
                                        onChangePage={(_, next) => handleChangePagePartial(next)}
                                        onChangeRowsPerPage={(evt) => handleRowsPerPagePartial(Number(evt.target.value))}
                                    />
                                </Tab>
                                : <></>
                            }
                        </Tabs>

                        {
                            isCanceled
                            ?
                                <Alert variant="secondary" className="col-lg-6">
                                    <Alert.Heading>Motivo do cancelamento</Alert.Heading>
                                    <p>
                                        {reasonCanceled}
                                    </p>
                                </Alert>
                            : <></>
                        }
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}
