import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { TextField, MenuItem, InputAdornment, Grid } from '@material-ui/core';

import { PaymentDetails, usePurchaseOrder } from './context';
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';


import '../../style.css';
import { formatToFloat } from '../../utils/formatCurrency';
import { useSelector } from 'react-redux';

interface ConditionOrInstallmentProps {
    total: number;
    conditionName: string;
    setConditionName: Dispatch<SetStateAction<string>>;
    status?: string;
    changeTabIcon: (previousIcon: string) => void;
}

export const ConditionOrInstallment: React.FC<ConditionOrInstallmentProps> = ({ total, conditionName, setConditionName, status, changeTabIcon }) => {
    const { user } = useSelector((state: any) => state.auth);

    
    const [installmentsValue, setInstallmentsValue] = useState(0);
    const { classes, dispatchPayment, payments } = usePurchaseOrder();

    useEffect(() => {
        setInstallmentsValue(payments.length);
    }, [payments]);

    function handleInstallmentDateChange(date: string, index: number, payment: PaymentDetails) {
        dispatchPayment({
            type: "CHANGE_DATE",
            payload: {
                index,
                date,
                payment: {
                    ...payment,
                }
            }
        });
    }

    return (
        <div className="row">
            {/* <div className="col-lg-3 d-flex align-items-center">
                <Autocomplete
                    style={{ width: "100%" }}
                    options={conditionsNames}
        
                    value={conditionName}
                    onChange={(_, value) => value && setConditionName(value)}
                    renderInput={(params) => (
                        <TextField
                            { ...params }
                            size="small"
                            label="Condição de pagamento"
                            margin="normal"
                            disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                        />
                    )}
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="p-3 d-flex align-items-center justify-content-center">
                <label>ou</label>
            </div> */}

            <Grid item lg={3} md={6} xs={12}>
                <TextField
                    label="Quantidade de Parcelas*"
                    margin="normal"
                    size="small"
                    value={installmentsValue}
                    onChange={(evt) => setInstallmentsValue(Number(evt.target.value))}
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                />
            </Grid>

            <Grid item md={3} xs={12}>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        dispatchPayment({
                            type: "GENERATE",
                            payload: {
                                amount: installmentsValue,
                                value: total,
                            }
                        })
                        changeTabIcon('flaticon-list-1');
                    }}
                    block
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                >
                    Gerar parcelas
                </Button>
            </Grid>

            {
                payments.map((payment, index) => (
                    <div className="row col-lg-12 ml-2">
                        <Grid item lg={3} md={6} xs={12} className='d-flex align-items-center'>
                            <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                                <label className="text-white m-0">
                                    {index + 1}.
                                </label>
                            </div>

                            <TextField
                                size="small"
                                type="date"
                                label="Data"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={payment.date}
                                onChange={(event) => handleInstallmentDateChange(event.target.value, index, payment)}
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                            />
                        </Grid>

                        <Grid item lg={3} md={6} xs={12}>
                            {index + 1 !== payments.length ? (
                                <NumericFormat
                                    key={index}
                                    label="Valor"
                                    value={payment.value}
                                    withPrefix={false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={
                                        ({ target: { value } }) => dispatchPayment({
                                            type: "CHANGE_VALUE",
                                            payload: {
                                                index,
                                                value: formatToFloat(value),
                                                amount: installmentsValue,
                                                payment: {
                                                    ...payment,
                                                    value: formatToFloat(value),
                                                }
                                            }
                                        })
                                    }
                                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                />
                            ) : (
                                <NumericFormat
                                    disabled
                                    key={index}
                                    label="Valor"
                                    value={payment.value}
                                    withPrefix={false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item lg={3} md={6} xs={12}>
                            <TextField
                                select
                                size="small"
                                label="Forma de pgto"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                value={payment.payment}
                                onChange={(evt) => dispatchPayment({
                                    type: "CHANGE",
                                    payload: {
                                        index,
                                        payment: {
                                            ...payment,
                                            payment: evt.target.value
                                        }
                                    }
                                })}
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                            >
                                <MenuItem key="0" value="0">
                                    Selecione
                                </MenuItem>

                                {paymentOptions.map((payment, index) =>
                                    <MenuItem key={index} value={payment.value}>
                                        {payment.value}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>

                        <Grid item lg={3} md={6} xs={12}>
                            <TextField
                                size="small"
                                label="Observações"
                                margin="normal"
                                value={payment.comments}
                                onChange={(evt) => dispatchPayment({
                                    type: "CHANGE",
                                    payload: {
                                        index,
                                        payment: {
                                            ...payment,
                                            comments: evt.target.value
                                        }
                                    }
                                })}
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                            />
                        </Grid>
                    </div>
                ))
            }
        </div>
    );
};