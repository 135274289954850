import React, { useCallback, useState } from 'react';
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { useSubheader } from "../../../_metronic/layout";
import { makeStyles, MenuItem, TextField, Link } from '@material-ui/core';


import api from "../../services/Api";
import {
    RevenueAppearInsideDefaultOptions,
    RevenueFinancialGroupDefaultOptions,
    ExpenditureAppearInsideDefaultOptions,
    ExpenditureFinancialGroupDefaultOptions,
} from '../../hooks/parameters';
import { useParameters } from '../../hooks/parameters';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

export function NewCategory() {
    const [isExpenditure, setIsExpenditure] = useState(false);
    const [expenditureToShow, setExpenditureToShow] = useState(['']);
    const [reveneuToShow, setReveneuToShow] = useState(['']);
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, watch } = useForm();
    const { setCategories } = useParameters();
    
    subHeader.setTitle("Adicionar uma Categoria");

    const handleSetIsExpenditure = useCallback((evt: string) => {
        if (evt === "revenue") setIsExpenditure(false);
        else setIsExpenditure(true);
    }, []);


    const handleSetReveneuToShow = useCallback((value: string) => {
        switch (value) {
            case "Retenção de Lucros":
                setReveneuToShow(RevenueAppearInsideDefaultOptions.profitRetention);
                break;
            default:
                setReveneuToShow(RevenueAppearInsideDefaultOptions.others);
                break;
        }
    }, [RevenueAppearInsideDefaultOptions]);


    const handleSetExpenditureToShow = useCallback((value: string) => {
        switch (value) {
            case "custos":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.expenditure);
                break;
            case "Deduções":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            case "Despesas administrativas":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.adminExpenditure);
                break;
            case "Despesas com pessoal":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.employeesExpenditure);
                break;
            case "Despesas financeiras":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.financialExpenditure);
                break;
            case "Distribuição de lucros":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.profitDistribuition);
                break;
            case "Outras despesas":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            case "Provisão para imposto de renda e contribuição social sobre lucro":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            default:
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
        }
    }, [ExpenditureAppearInsideDefaultOptions]);


    const handleSubmit = async () => {
        try {
            const raw = watch();
            
            const response = await api.post("category", raw);

            setCategories(state => [...state, response.data]);

            history.push('/parametros/categorias');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <MainPageContentWrapper>
            <form className={classes.container} noValidate autoComplete="off">
                <div className="row col-lg-12">
                    <div className="col-lg-9">
                        <TextField
                            {...register("description")}
                            label="Descrição da Categoria"
                            margin="normal"
                            required
                        />
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            {...register("typeCategory")}
                            label="Tipo de Categoria"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            required
                            select
                            onChange={(evt) => handleSetIsExpenditure(evt.target.value)}
                        >
                            <MenuItem key="0" value="revenue">
                                Receita
                            </MenuItem>

                            <MenuItem key="1" value="expenditure">
                                Despesa
                            </MenuItem>
                        </TextField>
                    </div>
                </div>

                <div className="row col-lg-12">
                    <div className="col-lg-4">
                        <TextField
                            {...register("appearInside")}
                            select
                            label="Aparecer dentro de"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >
                            <MenuItem value="">
                                Selecione
                            </MenuItem>

                            {
                                isExpenditure ?
                                    expenditureToShow.map((data, index) => (
                                        <MenuItem key={index} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                                :
                                    reveneuToShow.map((data, index) => (
                                        <MenuItem key={index} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-5">
                        <TextField
                            {...register("financialGroup")}
                            label="Grupo Financeiro"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            required
                            select
                            onChange={
                                (evt) => isExpenditure
                                    ? handleSetExpenditureToShow(evt.target.value)
                                    : handleSetReveneuToShow(evt.target.value)
                            }
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>

                            {
                                isExpenditure ? 
                                    ExpenditureFinancialGroupDefaultOptions.map((data, index) => (
                                        <MenuItem key={index} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                                :
                                    RevenueFinancialGroupDefaultOptions.map((data, index) => (
                                        <MenuItem key={index} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-3">
                        <TextField
                                {...register("visibilityDRE")}
                                select
                                label="Visível no DRE"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                required
                            >
                                <MenuItem key="0" value="y">
                                    Sim
                                </MenuItem>

                                <MenuItem key="1" value="n">
                                    Não
                                </MenuItem>
                            </TextField>
                    </div>
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    <Button
                        variant="primary"
                        className="mr-3"
                        onClick={handleSubmit}
                    >
                        Salvar
                    </Button>
                    <Link href="/parametros/categorias" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}