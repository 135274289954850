import React, { useState } from 'react';
import {
    makeStyles,
    MenuItem,
    TextField,
    Grid
} from '@material-ui/core';
import { Button, Spinner } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";

import '../../style.css';
import LogService from '../../services/LogService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));

export function NewCenterCost() {
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, handleSubmit } = useForm();

    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    subHeader.setTitle("Adicionar um Centro de Custos");

    const onSubmit = async (data: any) => {
        setIsSubmit(true);

        try {
            const result = await api.post("centerCost", data);
            
            LogService.logRegister({
                itemId: result.data.id,
                module: 'Centro de Custos',
                itemName: result.data.name
            });

            history.push("/parametros/centro-de-custos");

        } catch (error) {
            console.log(error);
        }

        setIsSubmit(false);
    }

    return (
        <MainPageContentWrapper>
            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o centro de custos</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/parametros/centro-de-custos">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={9} md={6} xs={12}> 
                                <TextField
                                    { ...register("name") }
                                    label="Descrição"
                                    margin="normal"
                                    size="small"
                                />
                            </Grid>

                            <Grid item lg={3} md={6} xs={12}> 
                                <TextField
                                    { ...register("status") }
                                    select
                                    label="Status"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    size="small"
                                >
                                    <MenuItem key="0" value="active">
                                        Ativo
                                    </MenuItem>                                

                                    <MenuItem key="1" value="inactive">
                                        Inativo
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}