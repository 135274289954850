import { IconButton, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { NumericFormat } from "../../../components/NumericFormat";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { subscriptionPlanPaymentEnvironment as subscriptionPlanPaymentEnvironmentType } from "../../../types/SuperConfig";

type SubscriptionPlanConfigProps = {
    subscriptionPlanPaymentEnvironment: subscriptionPlanPaymentEnvironmentType;
    setSubscriptionPlanPaymentEnvironment: React.Dispatch<React.SetStateAction<subscriptionPlanPaymentEnvironmentType>>
    defaultMsgSubscriptionPlanExpiring: string;
    setDefaultMsgSubscriptionPlanExpiring: React.Dispatch<React.SetStateAction<string>>
    defaultMsgSubscriptionPlanPaymentPending: string;
    setDefaultMsgSubscriptionPlanPaymentPending: React.Dispatch<React.SetStateAction<string>>
    defaultMsgSubscriptionPlanPaymentRequired: string;
    setDefaultMsgSubscriptionPlanPaymentRequired: React.Dispatch<React.SetStateAction<string>>
    daysWithoutPlanPaymentAllowed: number;
    setDaysWithoutPlanPaymentAllowed: React.Dispatch<React.SetStateAction<number>>
    subscriptionBilletExpireDays: number;
    setSubscriptionBilletExpireDays: React.Dispatch<React.SetStateAction<number>>
}

export function SubscriptionPlanConfig({
    subscriptionPlanPaymentEnvironment,
    setSubscriptionPlanPaymentEnvironment,
    defaultMsgSubscriptionPlanExpiring,
    setDefaultMsgSubscriptionPlanExpiring,
    defaultMsgSubscriptionPlanPaymentPending,
    setDefaultMsgSubscriptionPlanPaymentPending,
    defaultMsgSubscriptionPlanPaymentRequired,
    setDefaultMsgSubscriptionPlanPaymentRequired,
    daysWithoutPlanPaymentAllowed,
    setDaysWithoutPlanPaymentAllowed,
    subscriptionBilletExpireDays,
    setSubscriptionBilletExpireDays,
}: SubscriptionPlanConfigProps) {
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-lg-4">
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                label="Ambiente"
                                margin="normal"
                                value={subscriptionPlanPaymentEnvironment}
                                onChange={e => setSubscriptionPlanPaymentEnvironment(e.target.value as subscriptionPlanPaymentEnvironmentType)}
                            >
                                <MenuItem key="0" value={subscriptionPlanPaymentEnvironmentType.test}>
                                    Homologação
                                </MenuItem>

                                <MenuItem key="1" value={subscriptionPlanPaymentEnvironmentType.production}>
                                    Produção
                                </MenuItem>

                            </TextField>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-8">
                            <TextField
                                label="Mensagem de alerta de vencimento do plano"
                                margin="normal"
                                value={defaultMsgSubscriptionPlanExpiring}
                                onChange={(e) => setDefaultMsgSubscriptionPlanExpiring(e.target.value)}
                            />
                        </div> */}
                        <div className="col-lg-8">
                            <TextField
                                label="Mensagem de alerta de pagamento pendente da fatura"
                                margin="normal"
                                value={defaultMsgSubscriptionPlanPaymentPending}
                                onChange={(e) => setDefaultMsgSubscriptionPlanPaymentPending(e.target.value)}
                            />
                        </div>
                        {/* <div className="col-lg-8">
                            <TextField
                                label="Mensagem de empresa bloqueada por falta de pagamento da fatura"
                                margin="normal"
                                value={defaultMsgSubscriptionPlanPaymentRequired}
                                onChange={(e) => setDefaultMsgSubscriptionPlanPaymentRequired(e.target.value)}
                            />
                        </div> */}
                        <div className="col-lg-8">
                            <NumericFormat
                                label="Limite de dias de acesso após data de vencimento da fatura"
                                thousandSeparator={''}
                                decimalScale={0}
                                value={daysWithoutPlanPaymentAllowed}
                                onChange={(e) => setDaysWithoutPlanPaymentAllowed(Number(e.target.value))}
                                endAdornment={(
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip-top'>
                                                Após a data de vencimento da fatura, a empresa poderá usar o sistema durante os dias definidos, após isso o acesso será bloqueado e o pagamento obrigatório
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton
                                            edge="end"
                                        >
                                            <i className="flaticon-info"></i>
                                        </IconButton>
                                    </OverlayTrigger>
                                )}
                            />
                        </div>
                        <div className="col-lg-8">
                            <NumericFormat
                                label="Dias para vencimento do boleto das faturas"
                                thousandSeparator={''}
                                decimalScale={0}
                                value={subscriptionBilletExpireDays}
                                onChange={(e) => setSubscriptionBilletExpireDays(Number(e.target.value))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}