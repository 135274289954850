/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { api as apiUrl } from "../../../../../app/services/ApiURL";
import useCompany from "../../../../../app/hooks/company";
import CompanyService from "../../../../../app/services/CompanyService";
import { formatCpfCnpjDynamic } from "../../../../../app/utils/formatCpfCnpj";
import { useCompanyBranch } from "../../../../../app/hooks/companyBranch";
import * as auth from "../../../../../app/modules/Auth";
import { CompanyBranchSelector } from "../../../../../app/components/CompanyBranchSelector";

export function UserProfileDropdown() {
  const [selectedCompany, setSelectedCompany] = useState();
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { companies, companyCanHaveBranches } = useCompany({ only: user.companyId, appendSubscriptionPlan: true });
  const [mainCompany] = companies;
  const { selectedCompany: currentCompany } = useCompanyBranch();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  useEffect(() => {
    setSelectedCompany(currentCompany);
  }, [currentCompany]);

  function handleOpenCompanyDialog() {
    setShowCompanyDialog(true);
  }

  function handleCloseCompanyDialog(selectedValue) {
    if (selectedValue.id !== selectedCompany.id) {
      dispatch(auth.actions.setSelectedCompanyId(selectedValue.id));
      window.location.href = (history.location.pathname);
    }

    setShowCompanyDialog(false);
  }

  function generateBackgroundColor() {
    const fullname = `${user.firstname} ${user.lastname}`

    let hash = 0;

    for (let i = 0; i < fullname.length; i++) {
      hash = fullname.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  return (
    <>
      <CompanyBranchSelector
        user={user}
        mainCompany={mainCompany}
        selectedCompany={selectedCompany}
        showDialog={showCompanyDialog}
        setShowDialog={setShowCompanyDialog}
        onClose={handleCloseCompanyDialog}
      />
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "d-flex align-items-center w-md-auto"
            }
          >
            <span className="symbol symbol-35 cursor-pointer">
              <span className="symbol-label text-white font-size-h5 font-weight-bold rounded-circle"
                style={{ backgroundColor: generateBackgroundColor() }}>
                {user.firstname[0]}{user.lastname[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-8 py-2 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center mb-5">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0 rounded-circle">
                    <img className="rounded-circle" src={user.photo ? `${apiUrl}/${user.photo}` : toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                    {/* <img alt="Pic" src={user.pic} /> */}
                  </div>
                  <div className="text-dark font-weight-bolder m-0 flex-grow-1 font-size-h5">
                    {user.firstname} {user.lastname}

                    <div className="text-muted font-size-sm">
                      {user.email}
                    </div>
                  </div>
                  {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span> */}
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${user.photo ? `${apiUrl}/${user.photo}` : toAbsoluteUrl(
                    "/media/misc/bg-1.jpg"
                  )})`,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  <span className="symbol-label text-success font-weight-bold font-size-h4">
                    {user.firstname[0]}
                  </span>
                  {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstname} {user.lastname}
                </div>
                <span className="label label-success label-lg font-weight-bold label-inline">
                  3 messages
                </span>
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            {mainCompany && companyCanHaveBranches(mainCompany) && (
              <div onClick={handleOpenCompanyDialog} className="navi-item cursor-pointer">
                <div className="navi-link py-2">
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                      {`${CompanyService.getCorporateName(selectedCompany)} - ${formatCpfCnpjDynamic(CompanyService.getCpfCnpj(selectedCompany))}`}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Link to="/minha-conta" className="navi-item">
              <div className="navi-link py-2">
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Minha Conta
                  </div>
                </div>
              </div>
            </Link>

            {
              user.isAccountant == "n"
                ?
                <>
                  <Link to="/config/editar-dados-empresa" className="navi-item cursor-pointer">
                    <div className="navi-link py-2">
                      <div className="navi-text">
                        <div className="font-weight-bold">
                          Dados da Empresa
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/faturas" className="navi-item">
                    <div className="navi-link py-2">
                      <div className="navi-text">
                        <div className="font-weight-bold">
                          Faturas
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
                : <></>
            }
            <a href="/plano/pagamento" className="navi-item">
              <div className="navi-link py-2">
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Upgrade no Plano
                  </div>
                </div>
              </div>
            </a>
            <Link
                onClick={() => sessionStorage.clear()}
                color="danger"
                to="/logout"
                className="text-danger"
              >
                <div className="navi-link py-2">
                  <div className="navi-text">
                    <div className="font-weight-bold">
                      Sair
                    </div>
                  </div>
                </div>
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
