import { format, isAfter } from "date-fns";
import { OrdersSituation } from "../components/ListWithModalChangeSituation";
import { LinkedFieldError } from "../types/LinkedFieldError";
import { ThrowBillInstallment } from "../types/ThrowBillInstallment";
import api from "./Api";
import CustomerService from "./CustomerService";
import SellerService from "./SellerService";
import { Request } from "../types/Request";
import { Seller } from "../types/Seller";
import { SellerConsignmentProduct } from "../types/SellerConsignment";
import SellerConsignmentService from "./SellerConsignmentService";
import { yesOrNo } from "../types/yesOrNo";

class RequestService {
    verifyLinkedFields(request: any) {
        const errors: LinkedFieldError[] = [];

        if (!request.customerId && request.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: request.customerName,
            });
        }

        const products = JSON.parse(request.products ?? '[]');
        for (const product of products) {
            if (product.name && !product.isLinked) {
                errors.push({
                    fieldName: 'Produto',
                    fieldValue: product.name,
                });
            }
        }

        return errors;
    }

    verifyCustomerLink(request: any) {
        const errors: LinkedFieldError[] = [];

        if (!request.customerId && request.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: request.customerName,
            });
        }

        return errors;
    }

    async throwToBills({
        requestId,
        installments,
        subCategoryId,
        centerCost,
        installmentsCommission,
        centerCostCommission,
        subCategoryIdCommission,
        requestObj,
    }: {
        requestId: number;
        installments: ThrowBillInstallment[];
        subCategoryId?: number;
        centerCost: string;
        installmentsCommission?: ThrowBillInstallment[];
        centerCostCommission?: string;
        subCategoryIdCommission?: number;
        requestObj: {
            id: number;
            requestDate: string;
            customerId: number;
            requestNumber: string;
            seller: string;
            situation: string;
            status: string;
        };
    }) {
        var lastBank = 0;
        var lastBilletNumber = 0;
        var billetNumber = 0;

        installments.forEach(async ({
            date,
            value,
            isPaid,
            payment,
            paymentDate,
            comments,
            bankAccount,
        }) => {
            const [day, month, year] = date.split("/");
            const formated = new Date(`${year}-${month}-${day} 23:59:59`);
            const late = isAfter(Date.now(), formated);

            const bank = await api.get(`/accountBank/${Number(bankAccount)}`);

            if (lastBank == Number(bankAccount)) {
                billetNumber = lastBilletNumber + 1;

            } else {
                billetNumber = Number(bank.data.billetSequence);
            }

            const raw = {
                payment,
                comments,
                centerCost: centerCost ? Number(centerCost) : null,
                bankAccount: bankAccount ? bankAccount : null,
                nameBank: bankAccount ? bank.data.nameBank : null,
                amount: value ? value : 0,
                dueDate: year + "-" + month + "-" + day,
                payedDate: isPaid ? paymentDate : null,
                totalPaid: isPaid ? value : null,
                remaining: value ? value : null,
                recordType: payment === 'Boleto' ? "billet" : "account",
                dreSubCategoryId: subCategoryId,
                occurrence: installments.length > 1 ? "parcelada" : "unica",
                customer: requestObj.customerId,
                docNumber: requestObj.requestNumber,
                issuanceDate: requestObj.requestDate,
                name: `Pedido ${requestObj.id}`,
                status: isPaid ? 'paid' : late ? 'late' : 'pending',
                billetNumber: billetNumber
            };

            lastBank = Number(bankAccount);
            lastBilletNumber = billetNumber;

            await api.post(`/accountBank/${Number(bankAccount)}`, { billetSequence: billetNumber + 1 });
            await api.post("billsToReceive", raw);
        });

        // Salvar Comissão
        if (installmentsCommission && subCategoryIdCommission) {
            for (const commissionData of installmentsCommission) {
                const bank = await api.get(`/accountBank/${parseInt(commissionData.bankAccount)}`);
    
                const [day, month, year] = commissionData.date.split("/");
                const formated = new Date(`${year}-${month}-${day} 23:59:59`);
                const late = isAfter(Date.now(), formated);
    
                const raw = {
                    payment: commissionData.payment,
                    comments: commissionData.comments,
                    centerCost: centerCostCommission ? Number(centerCostCommission) : null,
                    bankAccount: commissionData.bankAccount ? commissionData.bankAccount : null,
                    nameBank: commissionData.bankAccount ? bank.data.nameBank : null,
                    amount: commissionData.value,
                    dueDate: year + "-" + month + "-" + day,
                    payedDate: commissionData.isPaid ? commissionData.paymentDate : null,
                    totalPaid: commissionData.isPaid ? commissionData.value : null,
                    remaining: commissionData.value,
                    recordType: "account",
                    dreSubCategoryId: subCategoryIdCommission,
                    occurrence: installmentsCommission.length > 1 ? "parcelada" : "unica",
                    beneficiaryType: 'seller',
                    seller: requestObj.seller,
                    docNumber: requestObj.requestNumber,
                    issueDate: requestObj.requestDate,
                    name: `Comissão - Pedido N° ${requestObj.id} - ${await CustomerService.getNameByIdAsync(requestObj.customerId)} - ${commissionData.date}`,
                    status: commissionData.isPaid ? 'paid' : late ? 'late' : 'pending',
                };
    
                await api.post("billsToPay", raw);
            }
        }

        const newSituation: OrdersSituation = {
            statusSituation: "attended",
            commentsSituation: "Lançado em contas",
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
        };

        const requestSituation = JSON.parse(requestObj.situation);

        requestSituation.push(newSituation);

        requestObj.situation = JSON.stringify(requestSituation);
        requestObj.status = "attended";

        const updatedRequest = await api.put(`requests/${requestId}`, requestObj);

        return updatedRequest.data;
    }

    /**
     * Verifica se uma venda é consignada e se tem estoque consignado o suficiente para retirar do estoque no ato da venda
     */
    async validateRequestSellerConsignmentMoveToStock(requestId: number | string) {
        const { data: request } = await api.get<Request>(`/requests/${requestId}`);

        if (!request.seller || !request.sellerConsignmentStockLocationId) {
            return { success: false, message: '' };
        }

        const seller = await SellerService.getSellerById(request.seller, true);

        if (!seller || seller.isConsignment === yesOrNo.NO) {
            return { success: false, message: '' };
        }

        if (request.sellerConsignmentStockLocationId) {
            const products = JSON.parse(request.products || '[]');
            let productsNamesWithoutStock: string[] = [];

            for (const product of products) {
                const isValid = SellerConsignmentService.validateConsignmentProductQuantity(product.INSTANCE, product.amount, request.sellerConsignmentStockLocationId, seller.availableConsignmentProducts ?? []);
                if (!isValid) {
                    productsNamesWithoutStock.push(product.name);
                }
            }

            if (productsNamesWithoutStock.length > 0) {
                let message = '';

                if (productsNamesWithoutStock.length === 1) {
                    message = `O produto "${productsNamesWithoutStock[0]}" não possui saldo consignado o suficiente para realizar a venda!`;
                } else {
                    message = `Os produtos "${productsNamesWithoutStock.join(', ')}" não possuem saldo consignado o suficiente para realizar a venda!`;
                }

                return { success: false, message };
            }
        }

        return { success: true, message: '' };
    }
}

export default new RequestService();