import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { formatCurrency } from '../../../utils/formatCurrency';
import api from "../../../services/Api";

import { ListProducts } from './List-Products'

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useBackendLoad from '../../../hooks/backendReload';
import { Nfe } from '../../../types/Nfe';
import CustomerService from '../../../services/CustomerService';
import SellerService from '../../../services/SellerService';
import nfApiHelper from '../../../utils/nfApiHelper';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import { Seller } from '../../../types/Seller';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

type ReportNfeResponse = {
    rows: Nfe[];
    count: number;
}

type Filters = {
    status: string;
    situation: string;
    customerId: number;
    sellerId: number;
    dateOutputInitial: string;
    dateOutputEnd: string;
    dateEmissionInitial: string;
    dateEmissionEnd: string;
    dateCreatedInitial: string;
    dateCreatedEnd: string;
    finality: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "clientName", value: "Cliente" },
    { reference: "sellerName", value: "Vendedor" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "outputDate", value: "Data de Saída" },
    { reference: "emissionDate", value: "Data da Emissão" },
    { reference: "createdDate", value: "Data de Cadastro" },
    { reference: "finality", value: "Finalidade" },
    { reference: "situation", value: "Situação" },
    { reference: "api_situation", value: "Status" },
];

const productHeadData: HeadDataBaseProps[] = [
    { reference: "code", value: "Cod" },
    { reference: "amount", value: "Quantidade" },
    { reference: "name", value: "Nome" },
    { reference: "saleValue", value: "Valor" },
    { reference: "type", value: "Tipo" },
    { reference: "ipi", value: "IPI" },
    { reference: "icms", value: "ICMS" },
    { reference: "cfop", value: "CFOP" },
    { reference: "ncm", value: "NCM" },
    { reference: "total", value: "Total" },
];

export function ListReportNFE() {
    const [listNfe, setListNfe] = useState<Nfe[]>([]);
    const [countNfePaginated, setCountNfePaginated] = useState(0);
    const [countNfe, setCountNfe] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [customerId, setCostumerId] = useState(0)
    const [finality, setFinality] = useState('')
    const [status, setStatus] = useState('')
    const [dateOutputInitial, setDateOutputInitial] = useState('')
    const [dateOutputEnd, setDateOutputEnd] = useState('')
    const [dateEmissionInitial, setDateEmissionInitial] = useState('')
    const [dateEmissionEnd, setDateEmissionEnd] = useState('')
    const [dateCreatedInitial, setDateCreatedInitial] = useState('')
    const [dateCreatedEnd, setDateCreatedEnd] = useState('')
    const [sellerId, setSellerId] = useState(0)
    const [situation, setSituation] = useState('')
    const [showProducts, setShowProducts] = useState(false)
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportNfeResponse>("/report/nfe", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            let exportProductData: ReportDataRow[] = [];
            const data = mountBodyDataRow(reportRow);
            const products = JSON.parse(reportRow.products)

            data.push(
                {
                    for: "products/services",
                    value: '',
                    secondaryRow: true,
                    jsx: <ListProducts products={products}/>
                },
            );

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportProductData = products.map((product: any) => {
                return {
                    cells: [
                        {for: 'code', content: product.INSTANCE.code ?? ''},
                        {for: 'amount', content: product.amount},
                        {for: 'name', content: product.INSTANCE.name ?? ''},
                        {for: 'saleValue', content: formatCurrency(product.INSTANCE.saleValue) ?? ''},
                        {for: 'type', content: product.INSTANCE.type == 'product' ? 'Produto' : 'Serviço'},
                        {for: 'ipi', content: product.ipi},
                        {for: 'icms', content: product.icms},
                        {for: 'cfop', content: product.cfop},
                        {for: 'ncm', content: product.ncm},
                        {for: 'total', content: product.total}
                    ],
                };
            });

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productHeadData,
                    bodyData: exportProductData,
                }
            });

            setExportTotals([
                { name: 'Total de Notas Fiscais', value: count },
            ])
        });

        setExportBodyData(exportDataList);
        setCountNfe(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportNfeResponse>("/report/nfe", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setListNfe(rows);
        setCountNfePaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    const { user } = useSelector((state: any) => state.auth);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = listNfe;

        aux.forEach((nfe) => {
            const data = mountBodyDataRow(nfe);
            const products = JSON.parse(nfe.products)

            if (showProducts == true) {
                data.push(
                    {
                        for: "products/services",
                        value: '',
                        secondaryRow: true,
                        jsx: <ListProducts products={products}/>
                    },
                );
            }

            list.push(data);
        });

        setBodyData(list);
    }, [listNfe]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Nfe) {
        const date = new Date(reportRow.createdDate);

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "clientName", value: CustomerService.getCustomerName(reportRow.customerEntity) },
            { for: "sellerName", value: SellerService.getName(reportRow.sellerEntity) },
            { for: "totalValue", value: formatCurrency(reportRow.totalValue) },
            { for: "outputDate", value: String(reportRow.outputDate.split("-").reverse().join("/")) },
            { for: "emissionDate", value: String(reportRow.emissionDate.split("-").reverse().join("/")) },
            { for: "createdDate", value: String(("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()) },
            {
                for: "finality",
                value: reportRow.finality == '1' ? 'NF-e normal' : reportRow.finality == '2' ? 'NF-e complementar' : reportRow.finality == '3' ? 'NF-e de ajuste' : reportRow.finality == '4' ? 'Devolução/Retorno' : ''
            },
            { for: "situation", value: reportRow.situation == 'open' ? 'Em aberto' : 'Atendido' },
            { for: "api_situation", value: nfApiHelper.getStatus(reportRow.api_situation) },
        ];

        return data;
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            status, 
            situation, 
            customerId,
            sellerId,
            dateOutputInitial, 
            dateOutputEnd, 
            dateEmissionInitial, 
            dateEmissionEnd, 
            dateCreatedInitial, 
            dateCreatedEnd,
            finality
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        status, 
        situation, 
        customerId,
        sellerId,
        dateOutputInitial, 
        dateOutputEnd, 
        dateEmissionInitial, 
        dateEmissionEnd, 
        dateCreatedInitial, 
        dateCreatedEnd,
        finality
    ]);

    const clearSearch = () => {
        setCostumerId(0);
        setFinality('');
        setStatus('');
        setDateOutputInitial('');
        setDateOutputEnd('');
        setDateEmissionInitial('');
        setDateEmissionEnd('');
        setDateCreatedInitial('');
        setDateCreatedEnd('');
        setSellerId(0);
        setSituation('');
        setShowProducts(false);
    }

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Notas Fiscais', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerId}
                        onSelect={(option) => setCostumerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customerId) return null;
                            return loadedOptions.find((option) => option.id === Number(customerId)) ?? CustomerService.getCustomerById(customerId)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        label="Finalidade"
                        margin="normal"
                        size="small"
                        value={finality}
                        onChange={(e) => setFinality(e.target.value)}
                    >
                        <MenuItem key="0" value="0">Selecione</MenuItem>
                        <MenuItem key="1" value="1">NF-e normal</MenuItem>
                        <MenuItem key="2" value="2">NF-e complementar</MenuItem>
                        <MenuItem key="3" value="3">NF-e de ajuste</MenuItem>
                        <MenuItem key="4" value="4">Devolução/Retorno</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        label="Status"
                        margin="normal"
                        size="small"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem key="0" value="">Selecione</MenuItem>
                        <MenuItem key="1" value="processando_autorizacao">Em Andamento</MenuItem>
                        <MenuItem key="2" value="autorizado">Autorizado</MenuItem>
                        <MenuItem key="3" value="cancelado">Cancelado</MenuItem>
                        <MenuItem key="4" value="erro_autorizacao">Erro de autorização</MenuItem>
                        <MenuItem key="5" value="denegado">Denegado</MenuItem>
                        <MenuItem key="6" value="aguardando">Aguardando</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Saída"
                        margin="normal"
                        size="small"
                        value={dateOutputInitial}
                        onChange={(e) => setDateOutputInitial(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Saída"
                        margin="normal"
                        size="small"
                        value={dateOutputEnd}
                        onChange={(e) => setDateOutputEnd(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        value={dateEmissionInitial}
                        onChange={(e) => setDateEmissionInitial(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        value={dateEmissionEnd}
                        onChange={(e) => setDateEmissionEnd(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Cadastro"
                        margin="normal"
                        size="small"
                        value={dateCreatedInitial}
                        onChange={(e) => setDateCreatedInitial(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Cadastro"
                        margin="normal"
                        size="small"
                        value={dateCreatedEnd}
                        onChange={(e) => setDateCreatedEnd(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-6">
                    <ApiResourceSelect
                        label="Vendedor"
                        getOptionLabel={(option: Seller) => option.name}
                        value={sellerId}
                        onSelect={(option) => setSellerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText, type: 'seller' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!sellerId) return null;
                            return loadedOptions.find((option) => option.id === Number(sellerId)) ?? CustomerService.getCustomerById(sellerId)
                        }}
                    />
                </div>

                <div className="col-lg-6">
                    <TextField
                        select
                        label="Situação"
                        margin="normal"
                        size="small"
                        value={situation}
                        onChange={(e) => setSituation(e.target.value)}
                    >
                        <MenuItem key="0" value="">Selecione</MenuItem>
                        <MenuItem key="1" value="attended">Atendido</MenuItem>
                        <MenuItem key="2" value="open">Em aberto</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProducts}
                                onChange={(_, checked) => setShowProducts(checked)}
                            />
                        }
                        label="Exibir os produtos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Notas Fiscais Eletrônicas"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Notas Fiscais', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                     headData={headData}
                     bodyData={bodyData}
                     loadData={loadData}
                     sortable={true}
                     totalCount={countNfePaginated}
                     triggerLoad={triggerLoad}
                     setTriggerLoad={setTriggerLoad}
                     lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de notas fiscais</TableCell>
                                    <TableCell colSpan={1}>{countNfe}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}