import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { ValidationBadge } from "../../components/ValidationBadge";
import api from "../../services/Api";
import { Indications, status, whoPay } from "../../types/Indications";
import { yesOrNo } from "../../types/yesOrNo";
import { AuthPageContentWrapper } from "../../components/AuthPageContentWrapper";

export function AcceptIndication() {
    const { indicationId } = useParams<{ indicationId: string }>();
    const [indicationExpired, setIndicationExpired] = useState(false);
    const [resultLoaded, setResultLoaded] = useState(false);

    useEffect(() => {
        async function acceptIndication() {
            if (!indicationId) {
                return;
            }

            try {
                const indicationAcceptResponse = await api.patch<Indications>(`/indications/status`, { id: indicationId, status: status.ACCEPT });

                setTimeout(() => {
                    if (indicationAcceptResponse.data.companyAlreadyExists === yesOrNo.YES) {
                        window.location.href = '/';
                    } else if (indicationAcceptResponse.data.whoPay === whoPay.COMPANY) {
                        window.location.href = `/indicacao/${indicationAcceptResponse.data.id}/completar-cadastro`;
                    } else {
                        window.location.href = `/criar-senha/${indicationAcceptResponse.data.indicatedUserEntity.id}`;
                    }
                }, 5000);
            } catch (error) {
                console.log('error', error);
                setIndicationExpired(true);
            } finally {
                setResultLoaded(true);
            }
        }

        acceptIndication();
    }, [indicationId])

    return (
        <AuthPageContentWrapper>
            <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
                <div className="login-form login-signin" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        {resultLoaded && (
                            <>
                                {!indicationExpired && (
                                    <>
                                        <h3 className="font-size-h1">
                                            <ValidationBadge label="" variant="success" />
                                            Convite aceito, voce será redirecionado para definir o seu acesso!
                                        </h3>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                )}

                                {indicationExpired && (
                                    <>
                                        <h3 className="font-size-h1">
                                            <ValidationBadge label="" variant="danger" />
                                            Convite não é válido!
                                        </h3>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AuthPageContentWrapper>
    );
}