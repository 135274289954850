/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ResetPassword from "./ResetPassword";
import { AuthPageContentWrapper } from "../../../components/AuthPageContentWrapper";

export function AuthPage() {
  return (
    <AuthPageContentWrapper>
      <div className="d-flex flex-column flex-center mt-10 mt-lg-0">
        <Switch>
          <ContentRoute path="/auth/login" component={Login} />
          <ContentRoute
            path="/auth/registration"
            component={Registration}
          />
          <ContentRoute
            path="/auth/forgot-password"
            component={ForgotPassword}
          />
          <ContentRoute
            path="/auth/reset-password/:token"
            component={ResetPassword}
          />
          <Redirect from="/auth" exact={true} to="/auth/login" />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </AuthPageContentWrapper>
  )
}
