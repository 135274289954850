import { addDays, addHours } from "date-fns";
import { cashFlow } from "../components/CashFlowComposedChart";
import { BankAccount } from "../types/BankAccount";
import { BillsCashFlow, BillsToPayCashFlow, BillsToReceiveCashFlow } from "../types/BillsCashFlow";
import { BillsToPay } from "../types/BillsToPay";
import { BillsToReceive } from "../types/BillsToReceive";
import { formatDate } from "../utils/dateFormat";
import { getDate, getNameMonth, getNumberOfDaysBetweenDates } from "../utils/dateTimeHelper";
import { formatToFloat } from "../utils/formatCurrency";
import api from "./Api";
import CustomerService from "./CustomerService";
import SellerService from "./SellerService";

type getBillsParams = {
    accountBank?: string;
    dreSubCategory?: string;
    initialDate?: string;
    finalDate?: string;
    foreseen: boolean;
    accomplished: boolean;
    company?: string;
}

type getCashFlowCompositeChartDataParams = (
    { bills: BillsCashFlow[]; } &
    (
        { dateType: 'month'; currentMonth: number; currentYear: number; }
        | { dateType: 'year'; currentYear: number; }
        | { dateType: 'custom'; customDateDivisor: number; }
    )
)

type getCashFlowCompositeChartTooltipTitleParams = (
    { element: any } & (
        { dateType: 'month'; currentMonth: number; currentYear: number; }
        | { dateType: 'year'; currentYear: number; }
        | { dateType: 'custom'; }
    )
)

class CashFlowService {
    async getBills({
        accountBank,
        dreSubCategory,
        initialDate,
        finalDate,
        foreseen = true,
        accomplished = true,
        company = "",
    }: getBillsParams): Promise<BillsCashFlow[]> {        
        // FORESEEN
        var arrPayForeseen: BillsCashFlow[] = [];
        var arrReceiveForeseen: BillsCashFlow[] = [];

        if (foreseen) {
            const rawForeseen = {
                accountBank,
                dreSubCategory,
                initialDate,
                finalDate,
                foreseen: true,
                accomplished: false,
                company,
            };

            var toPayForeseen = await api.post<BillsToPayCashFlow[]>("cashFlow/billsToPay", rawForeseen);
            var toReceiveForeseen = await api.post<BillsToReceiveCashFlow[]>("cashFlow/billsToReceive", rawForeseen);

            for (const pay of toPayForeseen.data) {
                arrPayForeseen.push({
                    id: pay.id,
                    date: pay.dueDate,
                    name: pay.name,
                    bankAccount: Number(pay.bankAccount),
                    typeBills: "pay",
                    dreSubCategoryId: pay.dreSubCategoryId,
                    dreSubCategoryEntity: pay.dreSubCategoryEntity,
                    status: pay.status,
                    totalPaid: pay.totalPaid,
                    amount: pay.amount,
                    balance: 0,
                    
                    foreseen: pay.foreseen,
                    issueDate: pay.issueDate,
                    docNumber: pay.docNumber,
                    beneficiaryName: CustomerService.getCustomerName(pay.customerEntity) || SellerService.getName(pay.sellerEntity),
                    paymentMethod: pay.payment,
                    centerCostName: pay.centerCostEntity?.name ?? '',
                });
            }

            for (const receive of toReceiveForeseen.data) {
                arrReceiveForeseen.push({
                    id: receive.id,
                    date: receive.dueDate,
                    name: receive.name,
                    bankAccount: Number(receive.bankAccount),
                    typeBills: "receive",
                    dreSubCategoryId: receive.dreSubCategoryId,
                    dreSubCategoryEntity: receive.dreSubCategoryEntity,
                    status: receive.status,
                    totalPaid: receive.totalPaid,
                    amount: receive.amount,
                    balance: 0,

                    foreseen: receive.foreseen,
                    issueDate: receive.issuanceDate,
                    docNumber: receive.docNumber,
                    beneficiaryName: CustomerService.getCustomerName(receive.customerEntity),
                    paymentMethod: receive.payment,
                    centerCostName: receive.centerCostEntity?.name ?? '',
                });
            }
        }
        // =================================================================




        // ACCOMPLISHED
        var arrPayAccomplished: BillsCashFlow[] = [];
        var arrReceiveAccomplished: BillsCashFlow[] = [];
        var arrPartialPay: BillsCashFlow[] = [];
        var arrPartialReceive: BillsCashFlow[] = [];

        if (accomplished) {
            const rawAccomplished = {
                accountBank,
                dreSubCategory,
                initialDate,
                finalDate,
                company
            };

            var toPayAccomplished = await api.post<BillsToPay[]>("cashFlow/paid/billsToPay", rawAccomplished);
            var toReceiveAccomplished = await api.post<BillsToReceive[]>("cashFlow/paid/billsToReceive", rawAccomplished);

            var partialPay = await api.post<BillsToPay[]>("cashFlow/billsToPay/partial", rawAccomplished);
            var partialReceive = await api.post<BillsToReceive[]>("cashFlow/billsToReceive/partial", rawAccomplished);

            for (const pay of toPayAccomplished.data) {
                arrPayAccomplished.push({
                    id: pay.id,
                    date: pay.payedDate ? pay.payedDate : pay.dueDate,
                    name: pay.name,
                    bankAccount: Number(pay.bankAccount),
                    typeBills: "pay",
                    dreSubCategoryId: pay.dreSubCategoryId,
                    dreSubCategoryEntity: pay.dreSubCategoryEntity,
                    status: pay.status,
                    totalPaid: pay.totalPaid,
                    amount: pay.amount,
                    balance: 0,

                    issueDate: pay.issueDate,
                    docNumber: pay.docNumber,
                    beneficiaryName: CustomerService.getCustomerName(pay.customerEntity) || SellerService.getName(pay.sellerEntity),
                    paymentMethod: pay.payment,
                    centerCostName: pay.centerCostEntity?.name ?? '',
                });
            }

            for (const receive of toReceiveAccomplished.data) {
                arrReceiveAccomplished.push({
                    id: receive.id,
                    date: receive.payedDate ? receive.payedDate : receive.dueDate,
                    name: receive.name,
                    bankAccount: Number(receive.bankAccount),
                    typeBills: "receive",
                    dreSubCategoryId: receive.dreSubCategoryId,
                    dreSubCategoryEntity: receive.dreSubCategoryEntity,
                    status: receive.status,
                    totalPaid: receive.totalPaid,
                    amount: receive.amount,
                    balance: 0,

                    issueDate: receive.issuanceDate,
                    docNumber: receive.docNumber,
                    beneficiaryName: CustomerService.getCustomerName(receive.customerEntity),
                    paymentMethod: receive.payment,
                    centerCostName: receive.centerCostEntity?.name ?? '',
                });
            }

            for (var billPartialPay of partialPay.data) {
                var partials = JSON.parse(billPartialPay.partialPayments ?? '[]');

                if (accountBank) {
                    for (var indexPartial = 0; indexPartial < partials.length; indexPartial++) {
                        if (this.validateRangeDate(partials[indexPartial].payedDate, initialDate, finalDate) && partials[indexPartial].bankAccount == accountBank) {
                            arrPartialPay.push({
                                id: billPartialPay.id + "." + (indexPartial + 1),
                                date: partials[indexPartial].payedDate,
                                name: billPartialPay.name,
                                bankAccount: partials[indexPartial].bankAccount,
                                typeBills: "pay",
                                dreSubCategoryId: billPartialPay.dreSubCategoryId,
                                dreSubCategoryEntity: billPartialPay.dreSubCategoryEntity,
                                status: "paid",
                                totalPaid: partials[indexPartial].totalPaid,
                                amount: partials[indexPartial].totalPaid,
                                balance: 0,
    
                                issueDate: billPartialPay.issueDate,
                                docNumber: billPartialPay.docNumber,
                                beneficiaryName: CustomerService.getCustomerName(billPartialPay.customerEntity) || SellerService.getName(billPartialPay.sellerEntity),
                                paymentMethod: partials[indexPartial].payment,
                                centerCostName: billPartialPay.centerCostEntity?.name ?? '',
                            });
                        }
                    }
                } else {
                    for (var indexPartial = 0; indexPartial < partials.length; indexPartial++) {
                        if (this.validateRangeDate(partials[indexPartial].payedDate, initialDate, finalDate)) {
                            arrPartialPay.push({
                                id: billPartialPay.id + "." + (indexPartial + 1),
                                date: partials[indexPartial].payedDate,
                                name: billPartialPay.name,
                                bankAccount: partials[indexPartial].bankAccount,
                                typeBills: "pay",
                                dreSubCategoryId: billPartialPay.dreSubCategoryId,
                                dreSubCategoryEntity: billPartialPay.dreSubCategoryEntity,
                                status: "paid",
                                totalPaid: partials[indexPartial].totalPaid,
                                amount: partials[indexPartial].totalPaid,
                                balance: 0,
    
                                issueDate: billPartialPay.issueDate,
                                docNumber: billPartialPay.docNumber,
                                beneficiaryName: CustomerService.getCustomerName(billPartialPay.customerEntity) || SellerService.getName(billPartialPay.sellerEntity),
                                paymentMethod: partials[indexPartial].payment,
                                centerCostName: billPartialPay.centerCostEntity?.name ?? '',
                            });
                        }
                    }
                }
            }

            for (var billPartialReceive of partialReceive.data) {
                var partials = JSON.parse(billPartialReceive.partialPayments ?? '[]');

                if (accountBank) {
                    for (var indexPartial = 0; indexPartial < partials.length; indexPartial++) {
                        if (this.validateRangeDate(partials[indexPartial].payedDate, initialDate, finalDate) && partials[indexPartial].bankAccount == accountBank) {
                            arrPartialReceive.push({
                                id: billPartialReceive.id + "." + (indexPartial + 1),
                                date: partials[indexPartial].payedDate,
                                name: billPartialReceive.name,
                                bankAccount: partials[indexPartial].bankAccount,
                                typeBills: "receive",
                                dreSubCategoryId: billPartialReceive.dreSubCategoryId,
                                dreSubCategoryEntity: billPartialReceive.dreSubCategoryEntity,
                                status: "paid",
                                totalPaid: partials[indexPartial].totalPaid,
                                amount: partials[indexPartial].totalPaid,
                                balance: 0,
                            });
                        }
                    }
                } else {
                    for (var indexPartial = 0; indexPartial < partials.length; indexPartial++) {
                        if (this.validateRangeDate(partials[indexPartial].payedDate, initialDate, finalDate)) {
                            arrPartialReceive.push({
                                id: billPartialReceive.id + "." + (indexPartial + 1),
                                date: partials[indexPartial].payedDate,
                                name: billPartialReceive.name,
                                bankAccount: partials[indexPartial].bankAccount,
                                typeBills: "receive",
                                dreSubCategoryId: billPartialReceive.dreSubCategoryId,
                                dreSubCategoryEntity: billPartialReceive.dreSubCategoryEntity,
                                status: "paid",
                                totalPaid: partials[indexPartial].totalPaid,
                                amount: partials[indexPartial].totalPaid,
                                balance: 0,
                            });
                        }
                    }
                }
            }
        }
        // =================================================================


        var allBills: BillsCashFlow[] = [];
        if (foreseen && accomplished) {
            allBills = [
                ...arrPayForeseen,
                ...arrReceiveForeseen,

                ...arrPayAccomplished,
                ...arrReceiveAccomplished,
                ...arrPartialPay,
                ...arrPartialReceive,
            ];

        } else if (foreseen) {
            allBills = [
                ...arrPayForeseen,
                ...arrReceiveForeseen,
            ];

        } else {
            allBills = [
                ...arrPayAccomplished,
                ...arrReceiveAccomplished,
                ...arrPartialPay,
                ...arrPartialReceive,
            ];
        }

        allBills.sort(function (element1: any, element2: any) {
            var date1: any = new Date(element1.date);
            var date2: any = new Date(element2.date);

            return date1 - date2;
        });

        return allBills;
    }

    getTotalAccount(bills: BillsCashFlow[], accountBanks: BankAccount[], idAccountBank?: string) {
        var totalAccount = 0;
        var totalPay = 0;
        var totalReceive = 0;
        var openingBalance = 0;
        var totalBillsAccomplished = 0;
        var totalBillsForeseen = 0;

        const accountBanksIds = accountBanks.map((bank) => bank.id);

        var allBills = idAccountBank
        ? bills.filter((bill) => bill.bankAccount == Number(idAccountBank))
        : bills.filter((bill) => accountBanksIds.includes(bill.bankAccount) || !bill.bankAccount);

        var pays = allBills.filter((bill) => bill.typeBills == "pay");
        var receives = allBills.filter((bill) => bill.typeBills == "receive");

        if (idAccountBank) {
            const selectedBankAccount = accountBanks.find((data: BankAccount) => data.id == Number(idAccountBank));
            openingBalance = formatToFloat(selectedBankAccount?.openingBalance ?? 0);

        } else {
            accountBanks.map(async (data: any) => {
                openingBalance += formatToFloat(data.openingBalance);
            });
        }

        pays.map((pay) => {
            var amount = pay.amount;
            if (pay.status == "paid") {
                amount = pay.totalPaid ? pay.totalPaid : pay.amount;
                totalBillsAccomplished += amount;
            } else {
                totalBillsForeseen += amount;
            }
            totalPay += amount;
        });

        receives.map((receive) => {
            var amount = receive.amount;
            if (receive.status == "paid") {
                amount = receive.totalPaid ? receive.totalPaid : receive.amount;
                totalBillsAccomplished += amount;
            } else {
                totalBillsForeseen += amount;
            }

            totalReceive += amount;
        });

        totalAccount = (openingBalance + totalReceive) - totalPay;

        return {
            openingBalance,
            totalPay,
            totalReceive,
            totalBillsAccomplished,
            totalBillsForeseen,
            totalAccount,
        }
    }

    validateRangeDate(dateBill: string, initialDate?: string, finalDate?: string): boolean {
        if (initialDate && finalDate) {
            return new Date(dateBill).getTime() >= new Date(initialDate).getTime() && new Date(dateBill).getTime() <= new Date(finalDate).getTime();
        } else if (initialDate) {
            return new Date(dateBill).getTime() >= new Date(initialDate).getTime();
        } else if (finalDate) {
            return new Date(dateBill).getTime() <= new Date(finalDate).getTime();
        } else {
            return true;
        }
    }

    getCashFlowCompositeChartData(params: getCashFlowCompositeChartDataParams) {
        const today = new Date();

        var cashFlowArr: cashFlow[] = [];

        if (params.dateType === 'month') {
            var initialMonth = getDate({ initialDate: new Date(params.currentYear == 0 ? today.getFullYear() : params.currentYear, params.currentMonth == 0 ? today.getMonth() : params.currentMonth - 1) }).dateStr;
            var finalMonth = getDate({ initialDate: new Date(params.currentYear == 0 ? today.getFullYear() : params.currentYear, params.currentMonth == 0 ? today.getMonth() + 1 : params.currentMonth, 0) }).dateStr;

            var currentDayLoop = initialMonth;
            do {
                var payAmount = 0;
                var receiveAmount = 0;

                for (var bill of params.bills) {
                    var dateBill = bill.date.split('-').reverse();
                    var amount = bill.amount;

                    if (bill.status == "paid") {
                        amount = bill.totalPaid ? bill.totalPaid : bill.amount;
                    }

                    if (dateBill.join('/') == currentDayLoop) {
                        payAmount += bill.typeBills == "pay" ? amount : 0;
                        receiveAmount += bill.typeBills == "receive" ? amount : 0;
                    }
                }

                const expenses = payAmount.toFixed(2);

                cashFlowArr.push({
                    name: currentDayLoop.split("/")[0],
                    despesas: Number(expenses) * -1,
                    receitas: Number(receiveAmount.toFixed(2)),
                    saldo: Number(receiveAmount.toFixed(2)) - Number(payAmount.toFixed(2)),
                });

                var day = Number(currentDayLoop.split("/")[0]) + 1 < 10 ? "0" + (Number(currentDayLoop.split("/")[0]) + 1) : Number(currentDayLoop.split("/")[0]) + 1;
                currentDayLoop = day + "/" + currentDayLoop.split("/")[1] + "/" + currentDayLoop.split("/")[2];

            } while (new Date(Number(currentDayLoop.split('/')[2]), Number(currentDayLoop.split('/')[1]) - 1, Number(currentDayLoop.split('/')[0])) <= new Date(Number(finalMonth.split('/')[2]), Number(finalMonth.split('/')[1]) - 1, Number(finalMonth.split('/')[0])));


        } else if (params.dateType === 'year') {
            var initialMonth = getDate({ initialDate: new Date(params.currentYear == 0 ? today.getFullYear() : params.currentYear, 0) }).dateStr;
            var finalMonth = getDate({ initialDate: new Date(params.currentYear == 0 ? today.getFullYear() : params.currentYear, 12, 0) }).dateStr;

            var currentDayLoop = initialMonth;
            do {
                var payAmount = 0;
                var receiveAmount = 0;

                for (var bill of params.bills) {
                    var dateBill = bill.date.split('-').reverse();
                    var amount = bill.amount;

                    if (bill.status == "paid") {
                        amount = bill.totalPaid ? bill.totalPaid : bill.amount;
                    }

                    if ((bill.status != "paid" && getNameMonth(dateBill[1], "initials") == getNameMonth(currentDayLoop.split('/')[1], "initials")) || (bill.status == "paid" && getNameMonth(dateBill[1], "initials") == getNameMonth(currentDayLoop.split('/')[1], "initials"))) {
                        payAmount += bill.typeBills == "pay" ? amount : 0;
                        receiveAmount += bill.typeBills == "receive" ? amount : 0;
                    }
                }

                cashFlowArr.push({
                    name: getNameMonth(currentDayLoop.split("/")[1], "initials"),
                    despesas: Number(payAmount.toFixed(2)) * -1,
                    receitas: Number(receiveAmount.toFixed(2)),
                    saldo: Number(receiveAmount.toFixed(2)) - Number(payAmount.toFixed(2))
                });

                var month = Number(currentDayLoop.split("/")[1]) + 1 < 10 ? "0" + (Number(currentDayLoop.split("/")[1]) + 1) : Number(currentDayLoop.split("/")[1]) + 1;
                currentDayLoop = currentDayLoop.split("/")[0] + "/" + month + "/" + currentDayLoop.split("/")[2];

            } while (new Date(Number(currentDayLoop.split('/')[2]), Number(currentDayLoop.split('/')[1]) - 1, Number(currentDayLoop.split('/')[0])) <= new Date(Number(finalMonth.split('/')[2]), Number(finalMonth.split('/')[1]) - 1, Number(finalMonth.split('/')[0])));
        } else {
            if (params.bills.length === 0) {
                return cashFlowArr;
            }

            const firstBill = params.bills[0];
            const lastBill = params.bills[params.bills.length - 1];

            let initialDate = new Date(firstBill.date);
            initialDate = addHours(initialDate, 3);
            let finalDate = new Date(lastBill.date);
            finalDate = addHours(finalDate, 3);

            const daysDiff = getNumberOfDaysBetweenDates(initialDate, finalDate);

            let numberOfIntervals = 1;
            let divisor = params.customDateDivisor;

            // Do While para tentar definir um numero de intervalos maior que 1
            do {
                numberOfIntervals = Math.ceil((daysDiff || 1) / divisor);

                divisor = Math.ceil(divisor / 2);
            } while (divisor > 1 && numberOfIntervals <= 1);
            
            let currentDate = initialDate;
            let previousDate = initialDate;
            
            do {
                let payAmount = 0;
                let receiveAmount = 0;

                for (const bill of params.bills) {
                    let dateBill = new Date(bill.date);
                    dateBill = addHours(dateBill, 3);

                    if (
                        dateBill.getTime() <= currentDate.getTime()
                        && (
                            dateBill.getTime() > previousDate.getTime()
                            || currentDate.getTime() === initialDate.getTime()
                        )
                    ) {
                        let amount = bill.amount;

                        if (bill.status == "paid") {
                            amount = bill.totalPaid ? bill.totalPaid : bill.amount;
                        }

                        payAmount += bill.typeBills == "pay" ? amount : 0;
                        receiveAmount += bill.typeBills == "receive" ? amount : 0;
                    }
                }

                const expenses = Number(payAmount.toFixed(2));
                const revenues = Number(receiveAmount.toFixed(2));

                cashFlowArr.push({
                    name: formatDate(currentDate),
                    despesas: expenses * -1,
                    receitas: revenues,
                    saldo: revenues - expenses,
                });

                previousDate = currentDate;

                if (currentDate.getTime() < finalDate.getTime()) {
                    currentDate = addDays(currentDate, numberOfIntervals);
                }

                if (currentDate.getTime() === finalDate.getTime() && currentDate.getTime() === previousDate.getTime()) {
                    break;
                }
            
                if (currentDate.getTime() > finalDate.getTime()) {
                    currentDate = finalDate;
                }

                if (previousDate.getTime() > finalDate.getTime()) {
                    previousDate = finalDate;
                }
            } while (currentDate.getTime() <= finalDate.getTime());
        }

        return cashFlowArr;
    }

    getCashFlowCompositeChartTooltipTitle(params: getCashFlowCompositeChartTooltipTitleParams) {
        if (params.dateType === 'month') {
            const month = params.currentMonth < 10 ? "0" + params.currentMonth : params.currentMonth;
            
            return params.element.label + "/" + month + "/" + params.currentYear;
        } else if (params.dateType === 'year') {
            return params.element.label + "/" + params.currentYear;
        } else {
            return 'Até ' + params.element.label;
        }
    }
}

export default new CashFlowService();