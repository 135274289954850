import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
// import { StickyToolbar } from "./extras/StickyToolbar";
import { AnimateLoading } from "../../_partials/controls";
import { useLayoutProps } from "../hooks/useLayoutProps";
import { LayoutContentBody } from "./LayoutContentBody";
import { HeaderMobileSimple } from "./header-mobile/HeaderMobileSimple";
import { HeaderSimple } from "./header/HeaderSimple";
import { ListNotifications } from "../../../app/pages/Notifications/List-Notifications";

export function Layout({ children, standalone }) {
  const isStandalonePage = !!standalone;
  const layoutProps = useLayoutProps();

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      <AnimateLoading />

      {/*begin::Main*/}
      {!isStandalonePage ? <HeaderMobile /> : <HeaderMobileSimple />}

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {!isStandalonePage ? <Header notificationsOpen={notificationsOpen} setNotificationsOpen={setNotificationsOpen} /> : <HeaderSimple />}
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}
              {/*begin::Entry*/}
              {layoutProps.contentExtended && (
                <>
                  {layoutProps.asideDisplay && (
                    <div className="d-lg-flex flex-row-fluid">
                      <Aside />
                      <div className="content-wrapper">{children}</div>
                    </div>
                  )}

                  {!layoutProps.asideDisplay && <>{children}</>}

                </>
              )}

              {
                notificationsOpen
                ? <ListNotifications />
                :
                  <LayoutContentBody>
                    {children}
                  </LayoutContentBody>
              }
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {!isStandalonePage && <>
        <QuickUser />
        <QuickPanel />
      </>}
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
