import React, { useEffect, useState } from 'react';

import '../../style.css';
import ModalError from '../../components/ModalError';
import { useHistory, useParams } from 'react-router';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import quotationService from '../../services/QuotationService';
import { QuotationProduct, QuotationStatus, QuotationSupplier, QuotationSupplierStatus } from '../../types/Quotation';
import { Product as SelectableProduct } from '../../services/ProductService';
import { Situation } from '../../types/Situation';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { ListQuotationProducts } from './ListQuotationProducts';
import { Grid, TextField } from '@material-ui/core';
import { ListQuotationSuppliers } from './ListQuotationSuppliers';
import quotationSupplierService from '../../services/QuotationSupplierService';
import LogService from '../../services/LogService';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { useStyles } from '../../hooks/styles';
import { Link } from 'react-router-dom';

export function EditQuotation() {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState("products");

  const [isFinished, setIsFinished] = useState(false);
  //Fields
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');
  const [products, setProducts] = useState<QuotationProduct[]>([{
    id: 0,
    productId: 0,
    amount: 0,
    INSTANCE: {} as SelectableProduct,
  }]);
  const [quotationSuppliers, setQuotationSuppliers] = useState<QuotationSupplier[]>([{
    id: 0,
    quotationId: 0,
    supplier: 0,
  }]);

  const [quotationSuppliersIdsToDelete, setQuotationSuppliersIdsToDelete] = useState<number[]>([]);

  const [showModalError, setShowModalError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

  const [nextButton, setNextButton] = useState('first');
  const [previousIcon, setPreviousIcon] = useState("flaticon-open-box");

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    async function loadData() {
      const savedQuotation = await quotationService.getQuotationById(id);

      if (!savedQuotation) return;

      setIsFinished(quotationService.isFinished(savedQuotation));

      setName(savedQuotation.name);
      setComments(savedQuotation.comments ?? '');
      setProducts(savedQuotation.products ? JSON.parse(savedQuotation.products) : [{
        id: 0,
        productId: 0,
        amount: 0,
        INSTANCE: {} as SelectableProduct,
      }]);

      const savedSuppliers = await quotationSupplierService.getQuotationSuppliersByQuotationId(id, { withSupplier: true });

      setQuotationSuppliers(
        savedSuppliers.map((suppliers) => ({
          ...suppliers,
          status: suppliers.status && quotationSupplierService.convertStringToStatus(suppliers.status),
        }))
      );
    }

    loadData();
  }, [id]);

  async function onSubmit(event: any) {
    event.preventDefault();

    const lastInputs = await quotationService.getQuotationById(id);

    try {
      setIsSubmit(true);

      if (!inputsVerify() || !productsVerify()) {
        setIsSubmit(false);
        return;
      }

      const quotationProductsFiltered = products.filter(productObj => productObj.productId !== 0);
      const quotationProducts = quotationProductsFiltered.map((productObj, index) => ({
        ...productObj,
        id: index + 1,
      }));


      const raw = {
        name,
        comments: comments || null,
        responsibleName: `${user.firstname} ${user.lastname}`,
        responsibleId: user.id,

        products: JSON.stringify(quotationProducts),
        status: QuotationStatus.open,
      };

      const savedQuotation = await quotationService.updateQuotation(id, raw);

      // Salvar fornecedores
      const quotationSuppliersFiltered = quotationSuppliers.filter(supplierObj => !!supplierObj.supplier);
      const quotationSuppliersMapped = quotationSuppliersFiltered.map(supplierObj => ({ ...supplierObj, quotationId: savedQuotation.id }));
      await quotationSupplierService.saveMultipleQuotationSuppliers(quotationSuppliersMapped);

      //Excluir fornecedores
      for (const supplierIdToDelete of quotationSuppliersIdsToDelete) {
        await quotationSupplierService.deleteQuotationSupplier(supplierIdToDelete);
      }

      history.push("/cotacoes");

      LogService.logEdit({
        itemId: savedQuotation.id,
        itemName: 'Cotação',
        module: 'Cotações',
        oldData: lastInputs!,
        newData: savedQuotation,
        formattedFields: {
        },
        fieldsMap: {
          name: 'Nome',
          comments: 'Observações'
        },
      });

    } catch (error) {
      console.log(error);
      setIsSubmit(false);
    }
  };

  function inputsVerify() {
    if (!name) {
      setMsgError('Informe o nome da cotação!');
      setShowModalError(true);
      return false;
    }

    return true;
  }

  function productsVerify() {
    if (!products || products.length === 0) {
      setActiveTab("products");
      setMsgError('Informe os itens da cotação!');
      setShowModalError(true);
      return false;
    }

    const hasSelectedProduct = products.find((productObj) => productObj.productId !== 0);
    if (!hasSelectedProduct) {
      setActiveTab("products");
      setMsgError('Informe os itens da cotação!');
      setShowModalError(true);
      return false;
    }

    return true;
  }

  const [isSubmit, setIsSubmit] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  function changeTabIcon(previousIcon: string) {
    let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
    if (previousIcon === "flaticon-open-box") {
        const hasSelectedProduct = products.find((productObj) => productObj.productId !== 0);
        if (hasSelectedProduct) {
          const current_icon = document.querySelector<HTMLElement>('.flaticon2-delete');
          
          if (current_icon) {
          current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
          current_icon?.classList.replace("text-danger", "text-success");
          } else {
          prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
          prev_icon?.classList.add("text-success");
          }

          setProductsInvalid(false);
        } else {
          const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

          setProductsInvalid(true);

          if (current_icon) {
            current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
            current_icon.classList.replace("text-success", "text-danger");
          } else {
            prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
            prev_icon?.classList.add("text-danger");
          }

          prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
          prev_icon?.classList.add("text-danger");
        }
    } else {
        prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
        prev_icon?.classList.add("text-success");
    }
  }

  function changeTab(tab: string, icon: string, previousIcon: string) {
    document.getElementById('products')?.classList.remove('current');
    document.getElementById('suppliers')?.classList.remove('current');

    let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
    if (all_icons != undefined) {
      all_icons.forEach(function(item){
          item.style.color = '#666';
      });
    }

    changeTabIcon(previousIcon);

    let sel_icon = document.querySelector<HTMLElement>('.'+icon);
    if (sel_icon != undefined) {
      sel_icon.style.color = '#fff';
    }

    document.getElementById(tab)?.classList.add('current');
    setActiveTab(tab);
    if (tab == 'products') {
      setNextButton('first');
    } else {
      setNextButton('last');
    }

    setPreviousIcon(icon);
  }

  function backNextOption(value:string){
    let arr = ['products', 'suppliers'];
    let arr2 = ['flaticon-open-box', 'flaticon-users'];
    let count = arr.length
    let index = arr.indexOf(activeTab);

    if (value == 'back') {
      if (activeTab == arr[0]) {
        setNextButton('first');
      } else {
        index = index-1;
        changeTab(arr[index], arr2[index], arr2[index+1]);
      }
    } else {
      if (activeTab == arr[count-1]) {
        setNextButton('last');
      } else {                
        index = index+1;
        changeTab(arr[index], arr2[index], arr2[index-1]);
      }
    }
  }

  return (
    <MainPageContentWrapper>
        <ModalError
          showModalError={showModalError}
          setShowModalError={setShowModalError}
          msgError={msgError}
        />
        <form onSubmit={onSubmit} className={`${classes.container} steppers`}>
          <div className="col-lg-12">
            <div
                className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper' style={{ float: "left" }}
            >
                <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='card-body px-6 py-10'>
                        <div className='stepper-nav'>
                          <div className='stepper-item current' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                              <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                        <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Produtos</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Produtos da cotação</div>
                                </div>
                              </div>
                              <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                          </div>
                          <div className='stepper-item' id="suppliers" data-kt-stepper-element='nav' onClick={() => changeTab('suppliers', 'flaticon-users', previousIcon)} style={{cursor: "pointer"}}>
                              <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <i className="flaticon-users icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Fornecedores</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Fornecedores da cotação</div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <div id="form-buttons"
                          className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                          rounded p-2 position-fixed"
                          style={{ zIndex: 1 }}>
                          <Link to="/cotacoes">
                              <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                          </Link>
                          {
                            user.isAccountant == "n"
                            ?
                            <Button
                              type="button"
                              variant="primary"
                              disabled={isSubmit}
                              onClick={onSubmit}
                          >
                              {isSubmit ? <>
                                  <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                  />

                                  <span className="ml-2">
                                      Aguarde...
                                  </span>
                              </> : <>
                                  <span>
                                      Salvar
                                  </span>
                              </>}
                          </Button>
                            : <></>
                          }
                      </div>
                  </Grid>
              </Grid>

              <Grid container spacing={3} className="mb-3 mt-18">
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    size="small"
                    label="Nome"
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                  <TextField
                    size="small"
                    label="Observações"
                    margin="normal"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
              </Grid>

              {nextButton === 'first' ? (
                  <Link to="/cotacoes" className="btn btn-secondary mb-2 mr-2 btn-lg">
                      <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                      <span>Voltar</span>
                  </Link>
              ) : (
                  <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => backNextOption('back')}
                  className="mb-2 mr-2 btn-lg"
                  >
                      <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                      <span>Voltar</span>
                  </Button>
              )}
              <Button
                  type="button"
                  variant={nextButton === 'last' ? 'secondary' : 'primary'}
                  onClick={() => backNextOption('next')}
                  className="mb-2 btn-lg"
                  disabled={nextButton === 'last'}
              >
                  <span>
                      Avançar
                  </span>&nbsp;
                  <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
              </Button>
              <hr />

              <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' style={{ display: "none" }}>
                <Tab eventKey='products' title='Produtos'>
                {
                  productsInvalid && (
                    <p className="text-danger">Selecione pelo menos um produto para nota fiscal!</p>
                  )
                }
                  <ListQuotationProducts
                    quotationProducts={products}
                    setQuotationProducts={setProducts}
                    quotationIsFinished={isFinished}
                    changeTabIcon={changeTabIcon}
                  />
                </Tab>
                <Tab eventKey='suppliers' title='Fornecedores'>
                  <ListQuotationSuppliers
                    quotationSuppliers={quotationSuppliers}
                    setQuotationSuppliers={setQuotationSuppliers}
                    setQuotationSuppliersIdsToDelete={setQuotationSuppliersIdsToDelete}
                    showExtraColumns={true}
                    quotationIsFinished={isFinished}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
    </MainPageContentWrapper>
  );
}
