import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid,
    Checkbox
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal
} from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import { Menu } from '../../types/Menu';
import api from '../../services/Api';
import { useHistory } from 'react-router';
import PermissionMenuSelector from '../../components/PermissionMenuSelector';
import { PermissionMenu } from '../../types/PermissionMenu';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function NewProfile() {
    const history = useHistory();

    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Perfil");

    const { menus } = useMenu();
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(menus as PermissionMenu[]);

    const [name, setName] = useState("");
    const [nameInvalid, setNameInvalid] = useState(false);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if(!menus.length) return;
        setPermissionMenus(menus as PermissionMenu[]);
    }, [menus]);

    function inputsVerify(): boolean {

        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    function handleChangeName(value: string) {
        setName(value);
        setNameInvalid(!value ? true : false);
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = filterPermissionMenu(aux);
        const permissions = mapPermissionMenu(menusFiltered);

        const raw = {
            name,
            permissions: JSON.stringify(permissions),
        };

        try {
            await api.post('profiles', raw);

            history.push("/perfis-acesso");
        } catch (error) {
            setMsgError('Erro ao salvar perfil');
            setShowModalError(true);
            setIsSubmit(false);
        }
    }

    function filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
        const filtered = menus.filter((menu) => menu.checked);
        if(!filtered || filtered.length === 0) {
            return [];
        }
        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = filterPermissionMenu(filtered[i].submenus as PermissionMenu[]).filter(() => true);
            }
        }
        return filtered;
    }

    function mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
        let mapped = menus.map((menu) => menu.id);
        for(let i = 0; i < menus.length; i++) {
            if(menus[i].submenus?.length) {
                mapped = mapped.concat(mapPermissionMenu(menus[i].submenus as PermissionMenu[]));
            }
        }
        return mapped;
    }

    return (
        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">

                    <div className="col-lg-6">
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome do Perfil*"
                            margin="normal"
                            value={name}
                            onChange={(e) => handleChangeName(e.target.value)}
                            error={nameInvalid}
                        />
                    </div>

                    <div className="col-lg-6 d-flex align-items-start justify-content-end">
                        <Button
                            type='submit'
                            className='mt-4'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <PermissionMenuSelector
                            permissionMenus={permissionMenus}
                            setPermissionMenus={setPermissionMenus}
                        />
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}