import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Zoom } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import ModalError from '../../../components/ModalError';
import api from '../../../services/Api';
import ModalSuccess from '../../../components/ModalSuccess';
import { formatDate } from '../../../utils/dateFormat';
import { formatCurrency } from '../../../utils/formatCurrency';
import { Workbook } from 'exceljs';
import { BankAccount } from '../../../types/BankAccount';
import fileSaver from 'file-saver';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';

type ReportData = {
    billId: number;
    billType: 'receive' | 'pay';
    date: string;
    value: number;
    debitChartOfAccountsCode: string;
    debitLinkDescription: string;
    creditChartOfAccountsCode: string;
    creditLinkDescription: string;
    billName: string;
    beneficiaryName: any;
    paymentMethod: string;
    categoryName: string;
    bankAccount: BankAccount;

    historicText?: string
}

type Filters = {
    searchQuery: string;
}

type Props = {
    setFifthStepCompleted: React.Dispatch<React.SetStateAction<boolean>>
}

export default function FifthStep({ setFifthStepCompleted }: Props) {
    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [pageChanged, setPageChanged] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [dateIntervalSelected, setDateIntervalSelected] = useState(false);
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");

    const [searchQuery, setSearchQuery] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgModal, setMsgModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [countTotalReportDataRows, setCountTotalReportDataRows] = useState(0);

    const [isGeneratingExcel, setIsGenerationExcel] = useState(false);

    const [reportData, setReportData] = useState<ReportData[]>([]);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();
    const filtersRef = useRef<Filters | null>(null);

    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [sortReference, setSortReference] = useState('date');

    const isBackEndPagination = true;

    const handleChangePage = useCallback((next: number) => {
        setPages(next);
        setPageChanged(true);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setPages(0);
        setRowsPerPage(value);
        setPageChanged(true);
    }, []);

    useEffect(() => {
        if (!dateIntervalSelected) return;

        handleLoadData();
    }, [rowsPerPage, pages, triggerLoad, pageChanged, dateIntervalSelected]);

    const getReportData = useCallback(async () => {
        const refFilters = filtersRef.current ? JSON.stringify(filtersRef.current) : {};

        const { data } = await api.get<{ rows: ReportData[], count: number }>(`/accountingReport`, {
            params: {
                skip: rowsPerPage * pages,
                take: rowsPerPage,
                filters: { ...refFilters, initialDate, finalDate },
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setCountTotalReportDataRows(count);

        setReportData(rows.map(
            (row) => {
                return {
                    ...row,
                    historicText: getReportRowHistoricText(row),
                };
            }
        ));
    }, [rowsPerPage, pages, sortReference, sortDirection, initialDate, finalDate]);

    const handleLoadData = useCallback(async () => {
        if (((pageChanged) || (triggerLoad && setTriggerLoad))) {
            setIsLoadingData(true);
            await getReportData();
            setIsLoadingData(false);

            if (pageChanged) {
                setPageChanged(false);
            }

            if (triggerLoad && setTriggerLoad) {
                setPages(0);
                setTriggerLoad(false);
            }
        }
    }, [rowsPerPage, pages, triggerLoad, pageChanged, sortDirection, sortReference]);

    const handleSort = useCallback((reference: string, active: boolean) => {
        if (active) {
            setSortDirection((prevState) => prevState === 'ASC' ? 'DESC' : 'ASC');
        }

        setSortReference(reference);

        if (setTriggerLoad) {
            setTriggerLoad(true);
        }
    }, []);

    const handleClickGenerateReport = useCallback(async () => {
        if (!initialDate || !finalDate) {
            setMsgError('Selecione a data inicial e a data final para gerar o relatório!');
            setShowModalError(true);
            return;
        }

        const initialTimestamp = (new Date(initialDate)).getTime();
        const finalTimestamp = (new Date(finalDate)).getTime();

        if (initialTimestamp > finalTimestamp) {
            setMsgError('A data final deve ser maior que a data inicial!');
            setShowModalError(true);
            return;
        }

        setDateIntervalSelected(true);
        setIsLoadingData(true);
        await getReportData();
        setIsLoadingData(false);
    }, [initialDate, finalDate, getReportData]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
        };

        reloadData();
    }, [searchQuery]);

    const handleClickExportExcel = useCallback(async () => {
        if (reportData.length === 0) return;

        setIsGenerationExcel(true);

        const workbook = new Workbook();

        const bankAccounts: BankAccount[] = [];

        const { data } = await api.get<{ rows: ReportData[], count: number }>(`/accountingReport`, {
            params: {
                filters: { initialDate, finalDate },
                sortReference: 'date',
                sortDirection: 'ASC',
            }
        });

        const { rows: allReportData } = data;

        const allWorkSheet = workbook.addWorksheet('Geral');
        allWorkSheet.columns = [
            { header: 'DATA', key: 'date', width: 15 },
            { header: 'VALOR', key: 'value', width: 20 },
            { header: 'DEBITO', key: 'debit', width: 10 },
            { header: 'CREDITO', key: 'credit', width: 10 },
            { header: 'HISTORICO', key: 'history', width: 150 },
        ];
        allWorkSheet.getRow(1).font = { bold: true };

        allReportData.forEach((reportRow) => {
            const bankId = reportRow.bankAccount.id;
            const bankAlreadyAdded = bankAccounts.find(bankAccount => bankAccount.id === bankId);

            if (!bankAlreadyAdded) {
                bankAccounts.push(reportRow.bankAccount);
            }

            allWorkSheet.addRow([
                formatDate(reportRow.date),
                formatCurrency(reportRow.value),
                reportRow.debitChartOfAccountsCode,
                reportRow.creditChartOfAccountsCode,
                getReportRowHistoricText(reportRow),
            ]);
        })

        bankAccounts.forEach((bankAccount) => {
            const worksheet = workbook.addWorksheet(`${bankAccount.nameBank} - ${bankAccount.name}`);
            worksheet.columns = [
                { header: 'DATA', key: 'date', width: 15 },
                { header: 'VALOR', key: 'value', width: 20 },
                { header: 'DEBITO', key: 'debit', width: 10 },
                { header: 'CREDITO', key: 'credit', width: 10 },
                { header: 'HISTORICO', key: 'history', width: 150 },
            ];
            worksheet.getRow(1).font = { bold: true };

            allReportData.forEach((reportRow) => {
                if (reportRow.bankAccount.id === bankAccount.id) {
                    worksheet.addRow([
                        formatDate(reportRow.date),
                        formatCurrency(reportRow.value),
                        reportRow.debitChartOfAccountsCode,
                        reportRow.creditChartOfAccountsCode,
                        getReportRowHistoricText(reportRow),
                    ]);
                }
            });
        })

        await new Promise((resolve) => setTimeout(resolve, 2000));

        const buffer = await workbook.xlsx.writeBuffer();
        fileSaver.saveAs(new Blob([buffer]), getFileNameWithDate({ filename: 'RelatórioContábil', extension: 'xlsx' }));

        setIsGenerationExcel(false);
    }, [reportData, initialDate, finalDate]);

    function getReportRowHistoricText(reportRow: ReportData) {
        let historicText = `${reportRow.billName}`;

        if (reportRow.beneficiaryName) {
            historicText += reportRow.beneficiaryName ? ` - ${reportRow.beneficiaryName}` : '';
        }

        historicText += ` - ${reportRow.paymentMethod} - ${reportRow.categoryName}`;

        if (reportRow.debitLinkDescription) {
            historicText += ` - ${reportRow.debitLinkDescription}`;
        }
        if (reportRow.creditLinkDescription) {
            historicText += ` - ${reportRow.creditLinkDescription}`;
        }

        return historicText;
    }

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <div id='fifthStep' className='stepper-content'>
                <div className="row d-flex align-items-center">
                    <div className="col-12">
                        <h3>Gerar Relatório</h3>
                    </div>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="col-lg-4">
                        <TextField
                            type="date"
                            label="De"
                            margin="normal"
                            size="small"
                            value={initialDate}
                            onChange={(e) => setInitialDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-4">
                        <TextField
                            type="date"
                            label="Até"
                            margin="normal"
                            size="small"
                            value={finalDate}
                            onChange={(e) => setFinalDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col">
                        <Button
                            type="button"
                            className="mr-2 btn-primary"
                            onClick={handleClickGenerateReport}
                        >
                            Gerar Relatório
                        </Button>
                    </div>
                </div>

                {!dateIntervalSelected && (
                    <>
                        <hr />
                        <div className="text-center" style={{ color: "#ccc" }}>Selecione um período e clique em Gerar Relatório</div>
                        <hr />
                    </>
                )}

                {dateIntervalSelected && (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                                    <Button
                                        className="btn-info ml-3"
                                        type="button"
                                        onClick={handleClickExportExcel}
                                        disabled={isGeneratingExcel}
                                    >
                                        <i className="flaticon2-sheet p-0"></i>
                                        {isGeneratingExcel && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="ml-2"
                                                />
                                            </>
                                        )
                                        }
                                    </Button>
                                </Tooltip>
                            </div>

                            <div className="col-12">
                                <Table className="wrap">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={'date' === sortReference}
                                                    direction={sortDirection.toLowerCase() as 'asc' | 'desc'}
                                                    onClick={() => handleSort('date', 'date' === sortReference)}
                                                >
                                                    Data
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={'value' === sortReference}
                                                    direction={sortDirection.toLowerCase() as 'asc' | 'desc'}
                                                    onClick={() => handleSort('value', 'value' === sortReference)}
                                                >
                                                    Valor
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>Débito</TableCell>
                                            <TableCell>Crédito</TableCell>
                                            <TableCell>Histórico</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {isLoadingData && (
                                            <TableRow>
                                                <TableCell colSpan={99}>
                                                    <div className="d-flex justify-content-center">
                                                        <Spinner
                                                            as="span"
                                                            variant="primary"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {
                                            reportData.length
                                                ?
                                                !isLoadingData && reportData.slice(isBackEndPagination ? 0 : pages * rowsPerPage, isBackEndPagination ? reportData.length : pages * rowsPerPage + rowsPerPage).map((reportRow, index) => {
                                                    return (
                                                        <TableRow key={index} hover>
                                                            <TableCell>{formatDate(reportRow.date)}</TableCell>
                                                            <TableCell>
                                                                <span className={`${reportRow.billType === 'pay' ? 'text-danger' : ''}`}>
                                                                    {formatCurrency(reportRow.value)}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>{reportRow.debitChartOfAccountsCode}</TableCell>
                                                            <TableCell>{reportRow.creditChartOfAccountsCode}</TableCell>
                                                            <TableCell>
                                                                {reportRow.historicText ?? ''}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                <TableRow>
                                                    <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={5}>Nenhum dado até o momento...</TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    labelRowsPerPage="Linhas por página"
                                    page={pages}
                                    component="div"
                                    count={countTotalReportDataRows ?? reportData.length}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[15, 50, 100]}
                                    backIconButtonProps={{
                                        'aria-label': 'Página Anterior',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Próxima Página',
                                    }}
                                    onChangePage={(_, next) => handleChangePage(next)}
                                    onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                                />
                            </div>
                        </div>
                    </>
                )}

            </div>
        </>
    )
};