import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ModalSuccess from "../../components/ModalSuccess";
import api from "../../services/Api";
import CompanySubscriptionPlanService from "../../services/CompanySubscriptionPlanService";
import { Indications, whoPay } from "../../types/Indications";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { bypassAuthHeader } from "../../utils/bypassAuthHeader";
import { ExecuteCreateCompanyPlanFunction, SubscriptionPlanCheckout } from "../../components/SubscriptionPlanCheckout";
import PermissionMenuService from "../../services/PermissionMenuService";
import { AuthPageContentWrapper } from "../../components/AuthPageContentWrapper";

export function CompleteCompanyData() {
    const { indicationId } = useParams<{ indicationId: string }>();
    const [indication, setIndication] = useState<Indications>();

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    useEffect(() => {
        async function loadIndication() {
            if (!indicationId) {
                return;
            }

            try {
                const indicationResponse = await api.get<Indications>(`/indications/${indicationId}`, {
                    headers: bypassAuthHeader
                });

                const subscriptionPlan = await CompanySubscriptionPlanService.getCurrentActiveCompanySubscriptionPlanByCompanyId(
                    indicationResponse.data.companyEntity.id,
                    { bypassAuth: true }
                );

                if (indicationResponse.data.whoPay !== whoPay.COMPANY || subscriptionPlan) {
                    window.location.href = `/criar-senha/${indicationResponse.data.indicatedUserEntity.id}`;
                }

                setIndication(indicationResponse.data);

            } catch (error) {
                console.log('error', error);
            }
        }

        loadIndication();
    }, [indicationId]);

    const onSubmit = async (executeCreateCompanyPlan: ExecuteCreateCompanyPlanFunction, subscriptionPlan: SubscriptionPlan, expirationDate: string, permissions: number[]) => {
        if (!indication) return;

        await executeCreateCompanyPlan();

        const rawCompany = {
            profileId: subscriptionPlan.profileId,
            permissions: JSON.stringify(permissions),
        };

        const rawUser = {
            permissions: JSON.stringify(permissions),
            extraMenuPermissions: JSON.stringify(PermissionMenuService.getMenuExtraPermissionsFromMenuPermissions(JSON.stringify(permissions))),
            fastAccess: JSON.stringify(permissions),
        }

        const response = await api.put(`companies/${indication.companyEntity.id}`, rawCompany, { headers: bypassAuthHeader });
        const userResponse = await api.put(`users/${indication.indicatedUserEntity.id}`, rawUser, { headers: bypassAuthHeader });

        await api.post(`indications/${indication.id}/email-welcome`, {}, { headers: bypassAuthHeader });

        setShowModalSuccess(true);
        setMessageSuccess('Cadastro completado com sucesso! Você será redirecionado para criar a sua senha de acesso!');
    }

    return (
        <AuthPageContentWrapper>
            <ModalSuccess
                msgModal={messageSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect={`/criar-senha/${indication?.indicatedUserEntity.id}`}
            />
            <div className="mt-10 mt-lg-0">
                {indication && (
                    <>
                        {indication.whoPay === whoPay.COMPANY && (
                            <div className="row mt-3">
                                <div className="col-12">
                                    <SubscriptionPlanCheckout
                                        onSubmit={onSubmit}
                                        companyId={indication?.companyId ?? 0}
                                        title={'Selecione uma das opções de plano abaixo'}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </AuthPageContentWrapper>
    )
}