import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { Button, Modal } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';

type Category = {
    id: number,
    name: string,
    type: string,
    editable: boolean,
    active: boolean,
    subCategories: SubCategory[]
}

type SubCategory = {
    id: number,
    dreCategoryId: number,
    name: string,
    type: string,
    active: boolean,
    editable: boolean
}

export type ModalType = {
    modalEdit: boolean
    setModalEdit: React.Dispatch<React.SetStateAction<boolean>>

    idEdit: number
    setIdEdit: React.Dispatch<React.SetStateAction<number>>

    nameEdit: string
    setNameEdit: React.Dispatch<React.SetStateAction<string>>

    getCategoriesRevenue: () => void
    getCategoriesExpense: () => void
}

export default function ModalEdit(props: ModalType) {

    async function edit() {
        await api.put(`/dre/edit/` + props.idEdit, {
            name: props.nameEdit,
        })
        .then(response => {
            props.getCategoriesRevenue()
            console.log(response)
        })
        .catch(error => {
            console.log('Ocorreu algum erro')
        })
        .finally(() => {
            props.setModalEdit(false)
        })
    }

    return (
        <Modal
            show={props.modalEdit}
            onHide={() => props.setModalEdit(false)}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Editar Categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    size="small"
                    label="Nome da categoria*"
                    margin="normal"
                    value={props.nameEdit}
                    onChange={(e) => props.setNameEdit(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => props.setModalEdit(false)}>Cancelar</Button>
                <Button variant="primary" type="button" onClick={edit}>Salvar</Button>
            </Modal.Footer>
        </Modal>
    )
}