import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import api from "../../services/Api";
import { api as apiUrl } from '../../services/ApiURL';
import {
    ObjectOrder,
    OrdersSituation,
    HeadDataBaseProps,
    BodyDataBaseProps,
    OnThrowToBillsData,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { format, isAfter } from 'date-fns';
import { Entry } from '../../types/Entry';
import { sortByNumber } from '../../utils/sortCallback';
import { getDate } from '../../utils/dateTimeHelper';
import { formatDate } from '../../utils/dateFormat';
import { yesOrNo } from '../../types/yesOrNo';
import { btnSuccess } from '../../utils/styles';
import { BsVariant } from '../../types/BsVariant';
import DownloadableResource from '../../components/DownloadableResource';
import { useDownloadResource } from '../../hooks/downloadResource';
import { status } from '../Config/CompanyData/ManageStatus';
import ModalChangeStatus, { StatusHistory } from '../../components/ModalChangeStatus';
import { getSituationFromText, getSituationText } from '../../utils/getSituationText';
import EntryService from '../../services/EntryService';
import useBackendLoad from '../../hooks/backendReload';
import { Search } from '../../components/Search';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { useSelector } from 'react-redux';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type Filters = {
    searchQuery: string;
    situation: string;
    nfeKey: string;
    initialIssuanceDate: string;
    finalIssuanceDate: string;
    initialEntryDate: string;
    finalEntryDate: string;
    minValue: number;
    maxValue: number;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: 'Nº' },
    { reference: "nfe", value: 'NF-e' },
    { reference: "date", value: 'Data' },
    { reference: "supplier", value: 'Fornecedor' },
    { reference: "amount", value: 'Valor Total' },
    { reference: "situation", value: 'Situação', situation: true, notSortable: true },
];


export function ListEntry() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [allStatus, setAllStatus] = useState<status[]>([]);
    const [entries, setEntries] = useState<Entry[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [situationData, setSituationData] = useState<ObjectOrder[]>([]);
    const [countTotalEntries, setCountTotalEntries] = useState(0);

    //Modal alterar status
    const [idChangeStatus, setIdChangeStatus] = useState(0);
    const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [nfeKey, setNfeKey] = useState("");
    const [initialIssuanceDate, setInitialIssuanceDate] = useState("");
    const [finalIssuanceDate, setFinalIssuanceDate] = useState("");
    const [initialEntryDate, setInitialEntryDate] = useState("");
    const [finalEntryDate, setFinalEntryDate] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const [xmlFilenameToDownload, setXmlFilenameToDownload] = useState('');
    const {
        resourceUrl: xmlUrlToDownload,
        setResourceUrl: setXmlUrlToDownload,
        handleExecuteDownload,
    } = useDownloadResource();
    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: Entry[], count: number }>("entry", {
            params: {
                withSupplier: true,
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        const initialSituationData = rows.map(({ id, installments, situation: situationResponse }) => {
            const situation: OrdersSituation[] = JSON.parse(situationResponse);

            return {
                id,
                situation,
                installments,
            };
        });

        setSituationData(initialSituationData);
        setEntries(rows);
        setCountTotalEntries(count);
    }, []);

    useEffect(() => {
        getAllStatus();
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = entries

        aux.forEach((entry) => {
            const amount = formatCurrency(entry.total);
            const status = getSituationText(entry.status);


            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(entry.id), id: true },
                { for: "nfe", value: entry.nfeNumber ? String(entry.nfeNumber) : '' },
                { for: "date", value: formatDate(entry.entryDate) },
                { for: "supplier", value: entry.supplier.name },
                { for: "amount", value: amount },
                { for: "situation", value: status },
                { for: "billCreated", value: 'y', hidden: true },
                { for: "movedToStock", value: entry.movedToStock, hidden: true },
                { for: "customStatusLabel", value: entry.customStatus?.label ?? '' },
                { for: "customStatusColor", value: entry.customStatus?.color ?? '' },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [entries]);

    async function getAllStatus() {
        var statusData = await api.get("/status");
        setAllStatus(statusData.data);
    }

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setNfeKey('');
        setInitialIssuanceDate('');
        setFinalIssuanceDate('');
        setInitialEntryDate('');
        setFinalEntryDate('');
        setMinValue('');
        setMaxValue('');
    };

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, nfeKey, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue),
            initialIssuanceDate, finalIssuanceDate, initialEntryDate, finalEntryDate,
        };

        reloadData();
    }, [entries, searchQuery, situation, nfeKey,
        initialIssuanceDate, finalIssuanceDate, initialEntryDate, finalEntryDate,
        minValue, maxValue]);

    const handleClickOpenChangeStatusModal = useCallback((id: string) => {
        const foundEntry = entries.find(entryObj => entryObj.id === Number(id));
        if (!foundEntry) return;

        setStatusHistory(JSON.parse(foundEntry.situation));
        setIdChangeStatus(Number(id));
        setShowModalChangeStatus(true);
    }, [entries]);

    const handleClickChangeStatus = useCallback(async (newStatus: StatusHistory) => {
        const aux = [...statusHistory];
        const lastStatus = aux[aux.length - 1];

        aux.push(newStatus);

        var status = newStatus.statusIsDefault === "y" ? getSituationFromText(newStatus.statusSituationLabel) : "custom";
        var customStatus = newStatus.statusIsDefault === "n" ? newStatus.statusSituationId : null;

        const customStatusObject = customStatus ? allStatus.find((statusObj) => statusObj.id === customStatus) : null;

        const raw: any = {
            status: status,
            customStatusId: customStatus,
            situation: JSON.stringify(aux),
        }

        await api.put<Entry>(`entry/${idChangeStatus}`, raw);

        EntryService.dispatchStatusChangeNotificationAndEmail({
            entryId: idChangeStatus,
            lastStatusHistory: lastStatus,
            newStatusHistory: newStatus,
        });

        setStatusHistory([...aux]);
        setEntries((prevState) => (
            prevState.map((entry) => {
                if (entry.id !== idChangeStatus) {
                    return entry;
                }

                return {
                    ...entry,
                    situation: JSON.stringify(aux),
                    status: status ?? 'custom',
                    customStatus: customStatusObject,
                };
            })
        ));
    }, [idChangeStatus, statusHistory, allStatus]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = entries.filter((entry) => entry.id !== Number(id));

            await api.delete(`Entry/${id}`);

            setEntries(filtered);
        } catch (error) {
            console.log(error);
        }
    }, [entries]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const updateStockMoved = useCallback((id: string, movedToStock: string) => {
        const aux = entries;
        const auxIndex = aux.findIndex((obj) => obj.id === Number(id));

        aux[auxIndex].movedToStock = movedToStock as yesOrNo;

        setEntries([...aux]);
    }, [entries, situationData]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    function handleClickImportXml() {
        pushHistory(`${pathname}/importar-xml`);
    }

    function handleClickImportManifest() {
        pushHistory(`${pathname}/importar-receita`);
    }

    const xmlButtonShowCondition = useCallback((id: string) => {
        const foundEntry = entries.find((entry) => entry.id === Number(id));

        return !!foundEntry?.nfXml;
    }, [entries]);

    const handleClickDownloadXml = useCallback((id: string) => {
        const foundEntry = entries.find((entry) => entry.id === Number(id));

        if (!foundEntry) return;

        setXmlUrlToDownload(`${apiUrl}/${foundEntry.nfXml}`);
        setXmlFilenameToDownload(`${foundEntry.id}_NF-e_${foundEntry.nfeNumber}.xml`);
    }, [entries]);

    return (
        <>
            <DownloadableResource
                url={xmlUrlToDownload}
                filename={xmlFilenameToDownload}
                onExecuteDownload={handleExecuteDownload}
            />
            <ModalChangeStatus
                showModal={showModalChangeStatus}
                setShowModal={setShowModalChangeStatus}
                history={statusHistory}
                onClickChangeStatus={handleClickChangeStatus}
                listStatus={allStatus}
            />
            <MainPageContentWrapper>
                <h4 className="font-weight-bolder">Lista de entrada de mercadorias</h4>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <>
                                <Button
                                    type="button"
                                    variant="success"
                                    className="mr-2 mb-2 text-dark"
                                    onClick={() => handleClickAdd()}
                                >
                                    <i className="fas fa-plus text-dark"></i>
                                    Adicionar
                                </Button>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    className="mr-2 mb-2"
                                    onClick={handleClickImportXml}
                                >
                                    <i className="fas fa-file-import"></i>
                                    Importar
                                </Button>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    className="mr-2 mb-2"
                                    onClick={handleClickImportManifest}
                                >
                                    <i className="fas fa-file-import"></i>
                                    Importar pela Receita
                                </Button>
                            </>
                            : <></>
                        }
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={setAdvancedSearch}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                <Collapse in={advancedSearch}>
                    <div className="shadow-sm p-6 rounded-sm border border-2">
                        <h4>Pesquisa avançada</h4>

                        <div className="row">
                            <div className="col-lg-3">
                                <TextField
                                    select
                                    size="small"
                                    label="Situação"
                                    margin="normal"
                                    value={situation}
                                >
                                    <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                        Todos
                                    </MenuItem>

                                    {allStatus.map((status) => (
                                        <MenuItem key={status.id} value={status.isDefault === 'y' ? getSituationFromText(status.label) : status.id} onClick={(event) => { setSituation(event.currentTarget.getAttribute('data-value') ?? '') }}>
                                            {status.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="col-lg-5">
                                <TextField
                                    size="small"
                                    label="Chave NFe"
                                    margin="normal"
                                    value={nfeKey}
                                    onChange={(e) => setNfeKey(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    size="small"
                                    type="date"
                                    label="Início Data de Emissão"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={initialIssuanceDate}
                                    onChange={(e) => setInitialIssuanceDate(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    size="small"
                                    type="date"
                                    label="Fim Data de Emissão"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={finalIssuanceDate}
                                    onChange={(e) => setFinalIssuanceDate(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    size="small"
                                    type="date"
                                    label="Início Data da Entrada"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={initialEntryDate}
                                    onChange={(e) => setInitialEntryDate(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    size="small"
                                    type="date"
                                    label="Fim Data da Entrada"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={finalEntryDate}
                                    onChange={(e) => setFinalEntryDate(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor mínimo"
                                    startAdornment="R$"
                                    value={minValue}
                                    onChange={(evt) => setMinValue(evt.target.value)}
                                />
                            </div>

                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor máximo"
                                    startAdornment="R$"
                                    value={maxValue}
                                    onChange={(evt) => setMaxValue(evt.target.value)}
                                />
                            </div>

                            <div className="col-12 d-flex mt-6">
                                <Button
                                    onClick={handleClickSearch}
                                    className="mr-3"
                                >
                                    <i className="fa fa-search"></i>
                                    Pesquisar
                                </Button>

                                <Button
                                    onClick={clearSearch}
                                    variant="danger"
                                >
                                    <i className="fas fa-times"></i>
                                    Limpar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>

                <div className="mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                        situation
                        hasStock
                        typeOrder="entry"
                        onStockMoved={updateStockMoved}
                        customButtons={
                            user.isAccountant == "n"
                            ?
                            [
                                {
                                    class: 'btn-light-primary',
                                    content: (<i className='flaticon2-file-1 mr-2'></i>),
                                    variant: BsVariant.SECONDARY,
                                    popup: "Baixar XML da nota",
                                    onClick: handleClickDownloadXml,
                                    showCondition: xmlButtonShowCondition,
                                },
                                {
                                    class: 'btn-primary',
                                    content: (<i className='flaticon-list-2 mr-2'></i>),
                                    variant: BsVariant.PRIMARY,
                                    popup: "Alterar Status",
                                    onClick: handleClickOpenChangeStatusModal,
                                }
                            ]
                            : [
                                {
                                    class: 'btn-light-primary',
                                    content: (<i className='flaticon2-file-1 mr-2'></i>),
                                    variant: BsVariant.SECONDARY,
                                    popup: "Baixar XML da nota",
                                    onClick: handleClickDownloadXml,
                                    showCondition: xmlButtonShowCondition,
                                }
                            ]
                        }
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalEntries}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </MainPageContentWrapper>
        </>
    );
}