import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { Button, Spinner, Tabs, Tab, Modal } from 'react-bootstrap';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { format, isBefore } from 'date-fns';

import api from '../../services/Api';
import { Installment } from './Installment';
import { Budget } from '../../types/Budget';
import { ListProducts } from './ListProducts';
import { formatCurrency, formatToFloat, formatMoneyToInteger, formatIntegerToMoney } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { useBudget, Product, InstallmentDetails } from './context';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import LogService from '../../services/LogService';
import { freightModality } from '../../utils/freightModality';
import CustomerService from '../../services/CustomerService';
import { getSituationText } from '../../utils/getSituationText';
import SellerService from '../../services/SellerService';
import { useLinkedFieldsError } from '../../hooks/linkedFieldsError';
import BudgetService from '../../services/BudgetService';
import CustomerSelect from '../../components/CustomerSelect';
import ModalLinkedFieldsError from '../../components/ModalLinkedFieldsError';
import { useSelector } from 'react-redux';
import PriceListService from '../../services/PriceListService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { PriceList } from '../../types/PriceList';
import CarrierService from '../../services/CarrierService';
import { Carrier } from '../../types/Carrier';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import useSellerUser from '../../hooks/sellerUser';
import { yesOrNo } from '../../types/yesOrNo';
import SellerConsignmentService from '../../services/SellerConsignmentService';
import { SellerConsignmentStockLocationSelector } from '../../components/SellerConsignmentStockLocationSelector';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function EditBudgets() {
    const { user } = useSelector((state: any) => state.auth);


    const [isSubmit, setIsSubmit] = useState(false);

    const [activeTab, setActiveTab] = useState("details");

    const [customerId, setCustomerId] = useState<number>();
    const [customerName, setCustomerName] = useState("");
    const [seller, setSeller] = useState("");
    const [freigth, setFreigth] = useState("0");
    const [budgetDate, setBudgetDate] = useState("");
    const [totalValue, setTotalValue] = useState("0");
    const [budgetNumber, setBudgetNumber] = useState("");
    const [installmentAmount, setInstallmentAmount] = useState(0);
    const [modalityFreight, setModalityFreight] = useState("recipient");
    const [totalValueProducts, setTotalValueProducts] = useState("0");
    const [discountMoney, setDiscountMoney] = useState("0");
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [grossWeight, setGrossWeight] = useState("0");
    const [liquidWeight, setLiquidWeight] = useState("0");

    const [note, setNote] = useState("");
    const [carrier, setCarrier] = useState("");
    const [deadLine, setDeadLine] = useState("");
    const [reference, setReference] = useState("");
    const [insideNote, setInsideNote] = useState("");
    const [priceList, setPriceList] = useState<PriceList>({} as PriceList);
    const [saveEmit, setSaveEmit] = useState(false);
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");

    const [customerHasError, setCustomerHasError] = useState(false);

    const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();
    const [sellerConsignmentStockLocationId, setSellerConsignmentStockLocationId] = useState(0);
    const [budgetDataLoaded, setBudgetDataLoaded] = useState(false);

    const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    const {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    } = useLinkedFieldsError();

    const history = useHistory();
    const { userSellerInfo, productsAvailableForConsignment, filterProductsByStockLocationId } = useSellerUser();
    const selectedSellerIsAuthConsignmentSeller = !!userSellerInfo && userSellerInfo.isConsignment === yesOrNo.YES && userSellerInfo.id === Number(seller);
    const { id } = useParams<{ id: string }>();

    const {
        classes,
        products,
        installments,
        dispatchProducts,
        dispatchInstallments,
    } = useBudget();

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
        if (customerId) {
            const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
            setCustomerLimitCreditInformation(limitCreditInfo);
        } else {
            setCustomerLimitCreditInformation(undefined);
        }
    }, []);

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomerId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        function handleConsignmentSeller() {
            if (!userSellerInfo || userSellerInfo.isConsignment === yesOrNo.NO) {
                return;
            }

            filterProductsByStockLocationId(sellerConsignmentStockLocationId);

            if (!budgetDataLoaded) {
                return;
            }

            dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
            dispatchProducts({ type: "ADD" });
            dispatchInstallments({
                type: "GENERATE",
                payload: {
                    amount: 0,
                    value: 0,
                }
            });
        }

        handleConsignmentSeller();
    }, [userSellerInfo, seller, sellerConsignmentStockLocationId, filterProductsByStockLocationId]);

    useEffect(() => {
        async function loadData() {
            const { data } = await api.get<Budget>(`budgets/${id}`);
            const dataProducts: Product[] = JSON.parse(data.products);
            const dataInstallments: InstallmentDetails[] = JSON.parse(data.installments);

            const dataAmountInstallments = dataInstallments.length;

            const mappedProducts = dataProducts.map(prod => {
                return {
                    ...prod,
                    isLinked: prod.isLinked ?? !!prod.INSTANCE.id,
                    name: prod.name ?? prod.INSTANCE.name
                };
            });

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: mappedProducts,
                },
            });

            dispatchInstallments({
                type: "INITIAL",
                payload: {
                    installments: dataInstallments,
                }
            });

            const dataPriceList = await PriceListService.getPriceListById(data.priceList);

            if (dataPriceList) {
                setPriceList(dataPriceList);
            }

            setNote(data.note);
            setSeller(String(data.seller ?? ''));
            setCarrier(data.carrier);
            setCustomerId(data.customerId ?? undefined);
            setCustomerName(data.customerName ?? '');
            setDeadLine(data.deadLine);
            setReference(data.reference);
            setInsideNote(data.insideNote);
            setBudgetDate(data.budgetDate);
            setBudgetNumber(data.budgetNumber);
            setInstallmentAmount(dataAmountInstallments);
            setModalityFreight(data.modalityFreight);
            setSellerConsignmentStockLocationId(data.sellerConsignmentStockLocationId || 0);

            setFreigth(formatCurrency(data.freigth));
            setTotalValue(formatCurrency(data.totalValue));

            setDiscountMoney(formatCurrency(data.discountMoney));
            setDiscountPercentage(data.discountPercentage);
            setGrossWeight(data.grossWeight);
            setLiquidWeight(data.liquidWeight);

            handleCustomerChanged(data.customerId ?? undefined, data.customerName);
            setBudgetDataLoaded(true);
        }

        loadData();
    }, []);

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        if (priceList.priceListType === "1") {
            totalProduct = Number(totalProduct) + Number(priceList.value);
        }

        if (priceList.priceListType === "2") {
            totalProduct = Number(totalProduct) - Number(priceList.value);
        }

        const discountNumber = discountMoney ? formatToFloat(discountMoney) : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freigth ? formatToFloat(freigth) : 0;

        const partial = Number(totalProduct) + Number(freigthNumber);

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setTotalValueProducts(formatCurrency(totalProduct));

            setFreigth("0");
            setTotalValue("0");
            setDiscountMoney("0");

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
            total = total - (total * (discountPercentageFloat / 100));
        }


        setTotalValueProducts(formatCurrency(totalProduct));
        setTotalValue(formatCurrency(total));
    }, [products, discountMoney, discountPercentage, freigth, priceList]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    const handleSetSeller = useCallback((value: string | null) => {
        if (value) {
            return setSeller(value);
        }

        return setSeller("");
    }, []);

    const handleSetCarrier = useCallback((value: string | null) => {
        if (value) {
            return setCarrier(value);
        }

        return setCarrier("");
    }, []);

    const handleSetPriceList = useCallback((value: PriceList | null) => {
        if (value) {
            return setPriceList(value);
        }

        return setPriceList({} as PriceList);
    }, []);

    const handleSetDeadLine = useCallback((value: string) => {
        const [year, month, day] = value.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const dateNow = new Date();

        //input
        let finalDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay());

        //data atual
        let finalDateNow = new Date(dateNow.getFullYear() + '-' + dateNow.getMonth() + '-' + dateNow.getDay());

        if (isBefore(finalDate, finalDateNow)) {
            alert("Não é possível adicionar uma data que já passou!");
            return setDeadLine("");
        }

        setDeadLine(value);
    }, []);

    function hasInstallments() {
        var isSubmit = true;

        if (!installments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas do pedido!");
            setShowModalAttention(true);
            setSaveEmit(false);
            isSubmit = false;
        } else {
            installments.map((installment: any, index: number) => {
                if (!installment.value) {
                    setActiveTab("payment");
                    setMsgError("Informe o valor das parcelas do pedido!");
                    setShowModalAttention(true);
                    setSaveEmit(false);
                    isSubmit = false;
                }
            });
        }

        if (!isSubmit) return isSubmit;

        const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

        if (sumInstallments !== formatToFloat(totalValue)) {
            setMsgError("A soma das parcelas deve concidir com o valor total do pedido!");
            setShowModalAttention(true);
            isSubmit = false;
            setActiveTab('payment');
        }

        return isSubmit;
    }

    function inputsVerify() {
        setCustomerHasError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        if (!customerId && !customerName) {
            setMsgError("Selecione o cliente");
            setCustomerHasError(true);
            return false;
        }

        if (saveEmit && customerId && customerLimitCreditInformation) {
            const totalRequest = formatToFloat(totalValue ?? 0);

            if ((totalRequest + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
                // Se emitir o orçamento ultrapassar o limite de crédito do cliente
                setMsgError("Não é possível emitir o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
                setCustomerHasError(true);
                return false;
            }
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setMsgError("Selecione pelo menos 1(um) Produto!");
            setActiveTab('products');
            return false;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (selectedSellerIsAuthConsignmentSeller && productsAvailableForConsignment) {
            for (const product of products) {
                const valid = SellerConsignmentService.validateConsignmentProductQuantity(product.INSTANCE, product.amount, sellerConsignmentStockLocationId, productsAvailableForConsignment);
                if (!valid) {
                    setActiveTab('products');
                    setMsgError(`O produto ${product.name} não tem estoque em consignação o suficiente para realizar a venda!`);
                    setShowModalAttention(true);
                    return false;
                }
            }
        }

        if (!hasInstallments()) {
            return false;
        }

        return true;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) {
            setShowModalAttention(true);
            setSaveEmit(false);
            setIsSubmit(false);
            return;
        }

        // Situação que aparece na listagem
        const situation: OrdersSituation[] = [];

        const lastInputs = await api.get<Budget>(`budgets/${id}`);

        situation.push({
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
            commentsSituation: "",
            statusSituation: saveEmit ? "launched" : "open",
        });

        try {
            const rawBudget = {
                customerId: customerId ?? null,
                customerName: !customerId ? customerName : null,
                seller: seller,
                budgetDate,
                budgetNumber,
                modalityFreight,
                carrier,
                deadLine: deadLine,
                reference: reference,
                note: note,
                insideNote: insideNote,
                freigth: formatToFloat(freigth),
                totalValue: formatToFloat(totalValue),
                discountMoney: formatToFloat(discountMoney),
                discountPercentage: formatToFloat(discountPercentage),
                grossWeight: grossWeight,
                liquidWeight: liquidWeight,
                // priceList: priceList.name,

                status: saveEmit ? "launched" : "open",
                sellerConsignmentStockLocationId: sellerConsignmentStockLocationId || null,
                products: JSON.stringify(products),
                situation: JSON.stringify(situation),
                installments: JSON.stringify(installments),
            };

            if (saveEmit) {
                const linkErrors = BudgetService.verifyLinkedFields(rawBudget);

                if (linkErrors.length > 0) {
                    setLinkedFieldsErrors(linkErrors);
                    setShowModalLinkedFieldsError(true);
                    setSaveEmit(false);
                    setIsSubmit(false);
                    return;
                }
            }

            const response = await api.put(`budgets/${id}`, rawBudget);

            if (saveEmit) {

                const raw = {
                    customerId: customerId,
                    seller: seller,
                    deadLine: deadLine,
                    reference: reference,
                    note: note,
                    insideNote: insideNote,
                    requestDate: budgetDate,
                    requestNumber: budgetNumber,
                    modalityFreight,
                    freigth: formatToFloat(freigth),
                    totalValue: formatToFloat(totalValue),
                    discountMoney: formatToFloat(discountMoney),
                    discountPercentage: discountPercentage,
                    grossWeight: grossWeight,
                    liquidWeight: liquidWeight,

                    status: "open",
                    products: JSON.stringify(products),
                    situation: JSON.stringify(situation),
                    installments: JSON.stringify(installments),
                    movedToStock: "n"
                };

                await api.post("requests", raw);

                setSaveEmit(false);
            }

            history.push("/orcamentos");

            const previousCustomerEntity = await CustomerService.getCustomerById(lastInputs.data.customerId ?? 0);
            const newCustomerEntity = await CustomerService.getCustomerById(response.data.customerId);

            LogService.logEdit({
                itemId: response.data.id,
                itemName: response.data.reference || 'Orçamento',
                module: 'Orçamentos',
                oldData: {
                    ...lastInputs.data,
                    customer: CustomerService.getCustomerName(previousCustomerEntity, lastInputs.data.customerName)
                },
                newData: {
                    ...response.data,
                    customer: CustomerService.getCustomerName(newCustomerEntity, response.data.customerName)
                },
                formattedFields: {
                    deadLine: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    requestDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    movedToStock: (value) => getSituationText(value),
                    modalityFreight: (value) => freightModality(value),
                    seller: (value) => SellerService.getNameByIdAsync(value)
                },
                fieldsMap: {
                    customer: 'Cliente',
                    seller: 'Vendedor',
                    deadLine: 'Prazo de entrega',
                    reference: 'Referência',
                    note: 'Observações',
                    insideNote: 'Observações internas',
                    requestDate: 'Data do pedido',
                    requestNumber: 'Nº do pedido',
                    modalityFreight: 'Modalidade de frete',
                    carrier: 'Transportadora',
                    freight: 'Frete',
                    discountMoney: 'Desconto em dinheiro',
                    discountPercentage: 'Desconto por porcentagem',
                    grossWeight: 'Peso bruto',
                    liquidWeight: 'Peso líquido',
                    movedToStock: 'Movido para Estoque'
                },
            });

        } catch (error) {
            console.log(error);
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalAttention(true);
        }

        setIsSubmit(false);
    };

    function changeTabIcon(previousIcon: string) {
        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        if (previousIcon === "flaticon-open-box") {
            const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

            if (hasProducts) {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setProductsInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

                setProductsInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
        } else {
            prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
        }
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('details')?.classList.remove('current');
        document.getElementById('products')?.classList.remove('current');
        document.getElementById('total-budget')?.classList.remove('current');
        document.getElementById('payment')?.classList.remove('current');
        document.getElementById('transport')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
                all_icons.forEach(function(item){
                    item.style.color = '#666';
                });
        }

        changeTabIcon(previousIcon);

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
                sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'details') {
                setNextButton('first');
        } else if (tab == 'transport') {
                setNextButton('last');
        } else {
                setNextButton('mid');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['details', 'products', 'total-budget', 'payment', 'transport'];
        let arr2 = ['flaticon-doc', 'flaticon-open-box', 'flaticon-coins', 'flaticon-list-1', 'flaticon-truck'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
                if (activeTab == arr[0]) {
                    setNextButton('first');
                } else {
                    index = index-1;
                    changeTab(arr[index], arr2[index], arr2[index+1]);
                }
        } else {
                if (activeTab == arr[count-1]) {
                    setNextButton('last');
                } else {                
                    index = index+1;
                    changeTab(arr[index], arr2[index], arr2[index-1]);
                }
        }
    }

    return (
        <MainPageContentWrapper>
            <ModalLinkedFieldsError
                message="Para emitir é necessário completar o cadastro do Orçamento!"
                errors={linkedFieldsErrors}
                showModal={showModalLinkedFieldsError}
                setShowModal={setShowModalLinkedFieldsError}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={(evt) => onSubmit(evt)} className={`${classes.container} steppers`}>
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                        <div className='stepper-item current' id="details" data-kt-stepper-element='nav' onClick={() => changeTab('details', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Detalhes</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Detalhes sobre o pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                    <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Produtos</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Produtos do pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="total-budget" data-kt-stepper-element='nav' onClick={() => changeTab('total-budget', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Totais</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Totais do pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Pagamento</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Pagamento do pedido</div>
                                            </div>
                                            </div>
                                            <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                        </div>
                                        <div className='stepper-item' id="transport" data-kt-stepper-element='nav' onClick={() => changeTab('transport', 'flaticon-truck', previousIcon)} style={{cursor: "pointer"}}>
                                            <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <i className="flaticon-truck icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                    <h3 className='stepper-title text-break'>Transporte</h3>
                                                    <div className='stepper-desc fw-semibold text-break'>Transporte do pedido</div>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/orcamentos">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    {
                                        user.isAccountant == "n"
                                        ?
                                            <div className="d-flex" style={{ gap: "0.5rem" }}>
                                                <Button
                                                    type="button"
                                                    variant="primary"
                                                    disabled={isSubmit}
                                                    onClick={(evt: any) => onSubmit(evt)}
                                                >
                                                    {isSubmit ? <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />

                                                        <span className="ml-2">
                                                            Aguarde...
                                                        </span>
                                                    </> : <>
                                                        <span>
                                                            Salvar
                                                        </span>
                                                    </>}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="secondary"
                                                    onClick={() => setSaveEmit(true)}
                                                >
                                                    {
                                                        saveEmit ?
                                                            <>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />

                                                                <span className='ml-2'>
                                                                    Aguarde...
                                                                </span>

                                                            </>
                                                            :
                                                            <span>Salvar e Emitir</span>
                                                    }
                                                </Button>
                                            </div>
                                        : <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

                            {selectedSellerIsAuthConsignmentSeller && (
                                <SellerConsignmentStockLocationSelector
                                    stockLocationId={sellerConsignmentStockLocationId}
                                    setStockLocationId={setSellerConsignmentStockLocationId}
                                />
                            )}

                            <Grid item lg={6} md={6} xs={12}>
                                <CustomerSelect
                                    label="Cliente"
                                    hasError={customerHasError}
                                    isEdit
                                    customerId={customerId}
                                    setCustomerId={setCustomerId}
                                    customerName={customerName}
                                    setCustomerName={setCustomerName}
                                    onCustomerChanged={handleCustomerChanged}
                                    entityId={id}
                                    entityType='budget'
                                    disabled={user.isAccountant == "y" ? true : false}
                                    allowIncomplete
                                    onClickAddCustomer={handleClickAddCustomer}
                                />

                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <ApiResourceSelect
                                    label="Vendedor"
                                    getOptionLabel={(option: any) => option.name}
                                    value={seller}
                                    onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!seller) return null;
                                        return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Grid>

                            <Grid item lg={2} md={6} xs={12}>
                                <TextField
                                    size="small"
                                    disabled
                                    label="Nº pedido"
                                    margin="normal"
                                    value={budgetNumber}
                                />
                            </Grid>
                        </Grid>

                        {nextButton === 'first' ? (
                            <Link to="/orcamentos" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Link>
                        ) : (
                            <Button
                            type="button"
                            variant="outline-primary"
                            onClick={() => backNextOption('back')}
                            className="mb-2 mr-2 btn-lg"
                            >
                                <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                <span>Voltar</span>
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant={nextButton === 'last' ? 'secondary' : 'primary'}
                            onClick={() => backNextOption('next')}
                            className="mb-2 btn-lg"
                            disabled={nextButton === 'last'}
                        >
                            <span>
                                Avançar
                            </span>&nbsp;
                            <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                        </Button>
                        <hr />

                        <Tabs
                            activeKey={activeTab}
                            onSelect={(tab: string) => setActiveTab(tab)}
                            id='newproduct-form-tabs'
                            style={{ display: "none" }}
                        >
                            <Tab
                                eventKey='details'
                                title='Detalhes'
                            >
                                <div className="row">
                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            required
                                            type="date"
                                            label="Data do pedido"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={budgetDate}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            type="date"
                                            margin="normal"
                                            label="Prazo de Entrega"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={deadLine}
                                            onChange={(evt) => handleSetDeadLine(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={8} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Referência"
                                            margin="normal"
                                            value={reference}
                                            onChange={(evt) => setReference(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Observações"
                                            multiline
                                            rows="2"
                                            placeholder="Esta informação será impressa nas observações da nota."
                                            margin="normal"
                                            value={note}
                                            onChange={(evt) => setNote(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>


                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            multiline
                                            rows="2"
                                            margin="normal"
                                            label="Observações Internas"
                                            placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                            value={insideNote}
                                            onChange={(evt) => setInsideNote(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">Contrato/Anexos</label>
                                        <input className="form-control" type="file" id="formFile" disabled={user.isAccountant == "y" ? true : false} />
                                        <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='products'
                                title='Produtos'
                            >
                                {
                                    productsInvalid && (
                                        <p className="text-danger">Selecione pelo menos um produto para nota fiscal!</p>
                                    )
                                }
                                <div className="row">
                                    {
                                        products.map(
                                            (prod, index) => (
                                                <ListProducts
                                                    key={index}
                                                    product={prod}
                                                    index={index}
                                                    isEdit
                                                    entityId={id}
                                                    onClickAddProduct={handleClickAddProduct}
                                                    sellerConsignmentProducts={selectedSellerIsAuthConsignmentSeller ? productsAvailableForConsignment : undefined}
                                                    changeTabIcon={changeTabIcon}
                                                />
                                            )
                                        )
                                    }

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item xs={12}>
                                                <button
                                                    type="button"
                                                    className="btn btn-link p-0"
                                                    onClick={() => dispatchProducts({ type: "ADD" })}
                                                >
                                                    <ins>+ adicionar outro produto</ins>
                                                </button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Lista de preços"
                                            getOptionLabel={(option: PriceList) => option.name}
                                            value={priceList}
                                            onSelect={(option) => handleSetPriceList(option)}
                                            apiSearchHandler={(typedText) => PriceListService.getPriceListsFiltered({ name: typedText })}
                                            disabled={formatToFloat(totalValueProducts) === 0 || user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='total-budget'
                                title='Totais do Pedido'
                            >
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor Total dos produtos"
                                            value={totalValueProducts}
                                            startAdornment="R$"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Valor do IPI"
                                            margin="normal"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor Total do frete"
                                            value={freigth}
                                            onChange={(evt) => setFreigth(evt.target.value)}
                                            startAdornment="R$"
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Desconto (R$)"
                                            value={discountMoney}
                                            onChange={(evt) => setDiscountMoney(evt.target.value)}
                                            startAdornment="R$"
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Desconto (%)"
                                            startAdornment="%"
                                            value={discountPercentage}
                                            onChange={(evt) => setDiscountPercentage(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Peso Bruto"
                                            startAdornment="KG"
                                            value={grossWeight}
                                            onChange={(evt) => setGrossWeight(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Peso Líquido"
                                            startAdornment="KG"
                                            value={liquidWeight}
                                            onChange={(evt) => setLiquidWeight(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor total"
                                            startAdornment="R$"
                                            disabled
                                            value={totalValue}
                                        />
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='payment'
                                title='Pagamento'
                            >
                                <div className="row">
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            size="small"
                                            required
                                            type="number"
                                            margin="normal"
                                            label="Quantidade de Parcelas"

                                            value={installmentAmount}
                                            onChange={(evt) => setInstallmentAmount(Number(evt.target.value))}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Grid item md={3} xs={12}>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    block
                                                    onClick={() => dispatchInstallments({
                                                        type: "GENERATE",
                                                        payload: {
                                                            amount: installmentAmount,
                                                            value: formatToFloat(totalValue),
                                                        }
                                                    })}
                                                >
                                                    Gerar parcelas
                                                </Button>
                                            </Grid>
                                            : <></>
                                    }

                                    <Grid item xs={12}>
                                        {
                                            installments.length > 0 && installments.map((installment, index) => (
                                                <Installment key={index} index={index} installment={installment} installmentAmount={installmentAmount} />
                                            ))
                                        }
                                    </Grid>
                                </div>
                            </Tab>

                            <Tab
                                eventKey='transport'
                                title='Transporte'
                            >
                                <div className="row">
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            size="small"
                                            select
                                            label="Modalidade de Frete"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            value={modalityFreight}
                                            onChange={(evt) => setModalityFreight(evt.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="sender">
                                                Remetente
                                            </MenuItem>

                                            <MenuItem key="1" value="recipient">
                                                Destinatário
                                            </MenuItem>

                                            <MenuItem key="5" value="freeShipping">
                                                Sem Frete
                                            </MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item lg={8} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Transportadora"
                                            freeSolo
                                            getOptionLabel={(option: Carrier) => option.name}
                                            value={carrier}
                                            onSelect={(option) => handleSetCarrier(option ? String(option.name) : '')}
                                            onInputChange={(typedText) => handleSetCarrier(typedText || '')}
                                            apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}
