import { makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { ContactMail } from "@material-ui/icons";
import { format } from "date-fns";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useParams } from "react-router";
import { Layout } from "../../../_metronic/layout";
import { useHeaderLayoutProps } from "../../../_metronic/layout/hooks/useHeaderLayoutProps";
import ModalError from "../../components/ModalError";
import { NumericFormat } from "../../components/NumericFormat";
import api from "../../services/Api";
import quotationService from "../../services/QuotationService";
import quotationSupplierService from "../../services/QuotationSupplierService";
import '../../style.css';
import { Quotation, QuotationProduct, QuotationSupplier, QuotationSupplierStatus } from '../../types/Quotation';
import { Situation } from "../../types/Situation";
import { formatCpfCnpjDynamic } from "../../utils/formatCpfCnpj";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { paymentOptions } from "../../utils/paymentOptions";
import { randomHexString } from "../../utils/randomHexString";
import { MainPageContentWrapper } from "../../components/MainPageContentWrapper";

const useStyles = makeStyles(theme => ({
    tableCellTextField: {
        padding: 4,
    },
}));

export function AnswerQuotation() {
    const [company, setCompany] = useState<any>();
    const [quotation, setQuotation] = useState<Quotation>();
    const [quotationSupplier, setQuotationSupplier] = useState<QuotationSupplier>();
    const [quotationProducts, setQuotationProducts] = useState<QuotationProduct[]>([]);

    const [paymentDetails, setPaymentDetails] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [responsible, setResponsible] = useState('');
    const [productsTotal, setProductsTotal] = useState(0);
    const [freightTotal, setFreightTotal] = useState(0);
    const [expensesTotal, setExpensesTotal] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [canFillAnswer, setCanFillAnswered] = useState(true);
    const [messageNotFillable, setMessageNotFillable] = useState('');

    const [showModalInfo, setShowModalInfo] = useState(false);
    const [msgInfo, setMsgInfo] = useState('');

    const classes = useStyles();
    const { quotationId, quotationSupplierId } = useParams<{ quotationId: string, quotationSupplierId: string }>();

    useEffect(() => {
        quotationSupplierService.setBypassAuth(true);
        quotationService.setBypassAuth(true);
    }, []);

    useEffect(() => {
        async function loadData() {
            const foundQuotation = await quotationService.getQuotationById(quotationId);
            const foundQuotationSupplier = await quotationSupplierService.getQuotationSupplierById(quotationSupplierId, { withSupplier: true });

            if (!foundQuotation || !foundQuotationSupplier) {
                setCanFillAnswered(false);
                setMessageNotFillable('Requisição inválida');
                return;
            };

            const foundCompany = await api.get(`companies/${foundQuotation?.companyId}`);

            setQuotation(foundQuotation);
            setQuotationSupplier(foundQuotationSupplier);
            setQuotationProducts(foundQuotation?.products ? JSON.parse(foundQuotation.products) : []);
            setCompany(foundCompany.data);

            if (quotationService.isFinished(foundQuotation)) {
                setCanFillAnswered(false);
                setMessageNotFillable('O processo de cotação foi encerrado e portanto não aceita mais propostas');
            } else if (!quotationSupplierService.canBeAnswered(foundQuotationSupplier)) {
                setCanFillAnswered(false);
                setMessageNotFillable(quotationSupplierService.getCanNotAnswerMessage(foundQuotationSupplier));
            }
        }

        loadData();
    }, [quotationId, quotationSupplierId]);

    useEffect(() => {
        if (quotationProducts.length === 0) return;

        const productTotals = quotationProducts.reduce((accumulator, current) => ({
            valueProducts: accumulator.valueProducts + (current.unitaryValue ?? 0) * current.amount,
            valueFreight: accumulator.valueFreight + (current.freightValue ?? 0),
            valueExpenses: accumulator.valueExpenses + (current.expensesValue ?? 0),
            valueIcms: accumulator.valueIcms + (current.icmsTax ?? 0),
            valueIpi: accumulator.valueIpi + (current.ipiTax ?? 0),
        }), {
            valueProducts: 0,
            valueFreight: 0,
            valueExpenses: 0,
            valueIcms: 0,
            valueIpi: 0,
        });

        setProductsTotal(productTotals.valueProducts);
        setFreightTotal(productTotals.valueFreight);
        setExpensesTotal(productTotals.valueExpenses);
        setTotalValue(productTotals.valueProducts + productTotals.valueFreight + productTotals.valueExpenses);
    }, [quotationProducts]);

    function handleChangeProductField(newValue: any, quotationProductId: number, field: string) {
        setQuotationProducts((prevState) => (
            prevState.map(
                (product) => {
                    if (product.id !== quotationProductId) {
                        return product;
                    }

                    const productUpdated = {
                        ...product,
                        [field]: newValue,
                    };
                    productUpdated.total = ((productUpdated.unitaryValue ?? 0) * productUpdated.amount) + (productUpdated.freightValue ?? 0) + (productUpdated.expensesValue ?? 0);

                    return productUpdated;
                }
            )
        ));
    }

    async function onSubmit(event: React.FormEvent) {
        event.preventDefault();

        if (!quotationSupplier) return;

        setIsSubmitting(true);

        if (!verifyInputs()) {
            setIsSubmitting(false);
            setShowModalInfo(true);
            return;
        }

        const situation: Situation[] = [{
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
            commentsSituation: '',
            statusSituation: QuotationSupplierStatus.asnwered,
        }];

        const raw = {
            responsible: responsible || null,
            paymentDetails: paymentDetails || null,
            paymentMethod: paymentMethod || null,
            products: JSON.stringify(quotationProducts),
            productsTotal: productsTotal,
            freightTotal: freightTotal,
            expensesTotal: expensesTotal,
            totalValue: totalValue,
            situation: JSON.stringify(situation),
            status: QuotationSupplierStatus.asnwered,
        };

        try {
            const updatedSupplier = await quotationSupplierService.updateQuotationSupplier(quotationSupplier.id, raw);

            setShowModalInfo(true);
            setMsgInfo('Cotação enviada com sucesso!');
            setCanFillAnswered(false);
            setMessageNotFillable(quotationSupplierService.getCanNotAnswerMessage(updatedSupplier));
            setQuotationSupplier(updatedSupplier);
        } catch (error) {
            console.log('error', error);
            setShowModalInfo(true);
            setMsgInfo('Ocorreu um erro ao enviar');
        }

        setIsSubmitting(false);
    }

    function verifyInputs() {
        if (productsTotal <= 0) {
            setMsgInfo('Preencha os valores dos produtos!');
            return false;
        }

        if (!paymentMethod) {
            setMsgInfo('Selecione a forma de pagamento!');
            return false;
        }

        if (!paymentDetails) {
            setMsgInfo('Preencha corretamente os detalhes do pagamento!');
            return false;
        }

        return true;
    }

    return (
        <Layout standalone={true}>
            <MainPageContentWrapper>
                <div className="card card-custom gutter-b">
                    <ModalError
                        showModalError={showModalInfo}
                        setShowModalError={setShowModalInfo}
                        msgError={msgInfo}
                    />
                    {!canFillAnswer && (
                        <div className="p-5">
                            <h3>{messageNotFillable}</h3>
                        </div>
                    )}
                    {canFillAnswer && (
                        <form onSubmit={onSubmit}>
                            <div className="card-header row m-0 pt-2 pb-0">
                                <div className="col-12 m-0 p-0">
                                    <h4 className="card-label m-0 mt-2">
                                        {company?.name} - Cotação n° {quotationId}
                                    </h4>
                                </div>
                                <div className='col-lg-12 d-flex justify-content-end mb-3 pr-0'>
                                    <Button
                                        type='submit'
                                        variant="primary"
                                        disabled={isSubmitting}
                                    >
                                        {
                                            isSubmitting ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>
                                                </>
                                                :
                                                <span>Enviar</span>
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div className="card-body mt-0 pt-2">
                                <div className="row mb-3">
                                    <div className="col">
                                        <h4 className="card-label m-0 mt-4">
                                            Solicitação para {quotationSupplier?.INSTANCE?.name} - {formatCpfCnpjDynamic(quotationSupplier?.INSTANCE?.cpfCnpj)}
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h4>Itens Solicitados</h4>
                                        <div className="row">
                                            <div className="col">
                                                <Table style={{ overflow: 'scroll' }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Produto</TableCell>
                                                            <TableCell>Quantidade</TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            {/* <TableCell></TableCell> */}
                                                            <TableCell>Total</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {quotationProducts.map((product) => (
                                                            <React.Fragment key={product.id}>
                                                                <TableRow>
                                                                    <TableCell>{product.INSTANCE.name}</TableCell>
                                                                    <TableCell>
                                                                        {product.amount}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableCellTextField}>
                                                                        <TextField
                                                                            label="Observações"
                                                                            margin="normal"
                                                                            size="small"
                                                                            value={product.comments ?? ''}
                                                                            onChange={(e) => handleChangeProductField(e.target.value, product.id, 'comments')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableCellTextField}>
                                                                        <NumericFormat
                                                                            className="inputSmaller"
                                                                            label="Valor Unitário"
                                                                            withPrefix={false}
                                                                            value={formatCurrency(product.unitaryValue ?? 0)}
                                                                            onChange={(e) => handleChangeProductField(formatToFloat(e.target.value), product.id, 'unitaryValue')}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableCellTextField}>
                                                                        <NumericFormat
                                                                            className="inputSmaller"
                                                                            label="Frete"
                                                                            withPrefix={false}
                                                                            value={formatCurrency(product.freightValue ?? 0)}
                                                                            onChange={(e) => handleChangeProductField(formatToFloat(e.target.value), product.id, 'freightValue')}
                                                                        />
                                                                    </TableCell>
                                                                    {/* <TableCell className={classes.tableCellTextField}>
                                                                    <NumericFormat
                                                                        className="inputSmaller"
                                                                        label="Despesas"
                                                                        withPrefix={false}
                                                                        value={formatCurrency(product.expensesValue ?? 0)}
                                                                        onChange={(e) => handleChangeProductField(formatToFloat(e.target.value), product.id, 'expensesValue')}
                                                                    />
                                                                </TableCell> */}
                                                                    {/* <TableCell className={classes.tableCellTextField}>
                                                                    <NumericFormat
                                                                        className="inputSmaller"
                                                                        label="ICMS %"
                                                                        withPrefix={false}
                                                                        value={formatCurrency(product.icmsTax ?? 0)}
                                                                        onChange={(e) => handleChangeProductField(formatToFloat(e.target.value), product.id, 'icmsTax')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.tableCellTextField}>
                                                                    <NumericFormat
                                                                        className="inputSmaller"
                                                                        label="IPI %"
                                                                        withPrefix={false}
                                                                        value={formatCurrency(product.ipiTax ?? 0)}
                                                                        onChange={(e) => handleChangeProductField(formatToFloat(e.target.value), product.id, 'ipiTax')}
                                                                    />
                                                                </TableCell> */}
                                                                    <TableCell>{formatCurrency(product.total ?? 0)}</TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col">
                                                <h4>Total produtos: {formatCurrency(productsTotal)}</h4>
                                            </div>
                                            <div className="col">
                                                <h4>Total frete: {formatCurrency(freightTotal)}</h4>
                                            </div>
                                            {/* <div className="col">
                                            <h4>Total despesas: {formatCurrency(expensesTotal)}</h4>
                                        </div> */}
                                            <div className="col">
                                                <h4>Total cotação: {formatCurrency(totalValue)}</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextField
                                                    select
                                                    size="small"
                                                    label="Forma de pgto"
                                                    margin="normal"
                                                    value={paymentMethod}
                                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                                >
                                                    <MenuItem key="0" value="0">
                                                        Selecione
                                                    </MenuItem>

                                                    {paymentOptions.map((payment, index) =>
                                                        <MenuItem key={payment.id} value={payment.value}>
                                                            {payment.value}
                                                        </MenuItem>
                                                    )}
                                                </TextField>
                                            </div>
                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Detalhes Pagamento"
                                                    margin="normal"
                                                    size="small"
                                                    value={paymentDetails}
                                                    onChange={(e) => setPaymentDetails(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Responsável"
                                                    margin="normal"
                                                    size="small"
                                                    value={responsible}
                                                    onChange={(e) => setResponsible(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    )}
                </div>
            </MainPageContentWrapper>
        </Layout>
    );
}