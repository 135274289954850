import React, { useState, useEffect, useCallback } from 'react';

import api from "../../../../app/services/Api";
import axios from 'axios';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Tooltip,
    Zoom,
    TableContainer,
    styled,
} from '@material-ui/core';

import {
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import '../../../../app/style.css';
import { useSelector } from 'react-redux';
import { useStyles } from '../../../../app/hooks/styles';
import { AddAccountBankModal } from '../../../../app/components/AccountBank/AddAccountBankModal';
import useAddAccountBank from '../../../../app/hooks/addAccountBank';
import { EditAccountBankModal } from '../../../../app/components/AccountBank/EditAccountBankModal';
import { MainPageContentWrapper } from '../../../../app/components/MainPageContentWrapper';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


export function List(props) {
    const { user } = useSelector((state) => state.auth);

    const classes = useStyles();

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();

    const [showModalEditAccount, setShowModalEditAccount] = useState(false);
    const [accountBankIdToEdit, setAccountBankIdToEdit] = useState(0);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const rowsPerPageOptions = [15, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [linkDelete, setLinkDelete] = useState("");
    const [idDeleted, setIdDeleted] = useState(0);

    const isSelected = name => selected.indexOf(name) !== -1;

    const [hoverColor, setHoverColor] = useState('7FC6E0');
    const styleButton = {
        background: `#${hoverColor}`,
        border: `1px solid #${hoverColor}`
    };

    const rows = props.data;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#F5F7FB",
        },
    }));

    const StyledTableCell = styled(TableCell)(() => ({
        backgroundColor: "#1E1E1E",
        color: "#fff"
    }));

    const StyledTableSortLabel = styled(TableSortLabel)(() => ({
        '& .MuiTableSortLabel-icon': {
            color: "#fff !important"
        },
    }));

    //Sorting
    const [sortDirection, setSortDirection] = useState('DESC');
    const [sortReference, setSortReference] = useState('id');
    const [pageChanged, setPageChanged] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const isBackEndPagination = !!(props.totalCount && props.loadData);

    const handleLoadData = useCallback(async () => {
        if ((pageChanged && props.loadData) || (props.loadData && props.triggerLoad && props.setTriggerLoad)) {
            setIsLoadingData(true);
            await props.loadData({ rowsPerPage, currentPage: page, sortDirection, sortReference });
            setIsLoadingData(false);

            if (pageChanged) {
                setPageChanged(false);
            }

            if (props.triggerLoad && props.setTriggerLoad) {
                setPage(0)
                props.setTriggerLoad(false);
            }
        }
    }, [rowsPerPage, page, props.triggerLoad, pageChanged, sortDirection, sortReference]);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPage, page, props.triggerLoad, pageChanged]);

    const handleSort = useCallback((reference, active) => {
        if (active) {
            setSortDirection((prevState) => prevState === 'ASC' ? 'DESC' : 'ASC');
        }

        setSortReference(reference);

        if (props.setTriggerLoad) {
            props.setTriggerLoad(true);
        }
    }, [props.setTriggerLoad]);

    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setPageChanged(true);
    }

    function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(+event.target.value);
        setPageChanged(true);
    }

    function handleOpenEditAccountBankModal(accountBankId) {
        setAccountBankIdToEdit(accountBankId);
        setShowModalEditAccount(true);
    }

    function getLinkDelete(page, id) {
        setIdDeleted(id);

        switch (page) {
            case 'clientes':
                setLinkDelete(`/customer/${id}`);
                break;
            case 'produtos':
                setLinkDelete(`/products/${id}`);
                break;
            case 'transportadoras':
                setLinkDelete(`/carriers/${id}`);
                break;
            case 'vendedores':
                setLinkDelete(`/sellers/${id}`);
                break;
            case 'pedidos':
                setLinkDelete(`/requests/${id}`);
                break;
            case 'ordem-de-compra':
                setLinkDelete(`/budgets/${id}`);
                break;
            case 'entrada-de-mercadoria':
                setLinkDelete(`/entry/${id}`);
                break;
            case 'ordem-de-compra':
                setLinkDelete(`/purchase-order/${id}`);
                break;
            case 'contas-bancarias':
                setLinkDelete(`/accountBank/${id}`);
                break;
            case 'contas-a-receber':
                // setLinkDelete(`/billsToReceive/${id}`);
                break;
            case 'lista-de-precos':
                setLinkDelete(`/priceList/${id}`);
                break;
            case 'categorias-produto':
                setLinkDelete(`/categoryproduct/${id}`);
                break;
            case 'marcas':
                setLinkDelete(`/brand/${id}`);
                break;
        }
    }

    async function deleteData() {
        if (linkDelete) {
            for (let data = 0; data < rows.length; data++) {
                if (idDeleted === rows[data].id) {
                    rows.splice(data, 1);
                }
            }

            await api.delete(linkDelete);

            setLinkDelete("");
        }

        setShowModalConfirmDelete(false);
    }

    async function handleSaveAccountBank() {
        // Recarregar listagem
        props.setTriggerLoad(true);
        setAccountBankIdToEdit(0);
    }

    async function generateBillet(id) {
        const myHeaders = new Headers();

        const requestOptions = {
            url: "/gerar-boleto/" + id,
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await axios(requestOptions).then((result) => {
            if (JSON.parse(result).urlBillet) {
                // window.location.href = JSON.parse(result).urlBillet;
                window.open(JSON.parse(result).urlBillet, '_blank');
            } else {
                window.location.reload(true)
            }
        });
    }

    async function generateShipment(id) {
        const myHeaders = new Headers();

        const requestOptions = {
            url: "/gerar-remessa/" + id,
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await axios(requestOptions).then((result) => {
            if (JSON.parse(result).urlShipping) {
                // window.location.href = '/' + JSON.parse(result).urlShipping;
                window.open('/' + JSON.parse(result).urlShipping, '_blank');
            } else {
                window.location.reload(true)
            }
        })
    }

    return (
        <form>
            <MainPageContentWrapper>
                <div>
                    <AddAccountBankModal
                        showModal={showModalAddAccountBank}
                        setShowModal={setShowModalAddAccountBank}
                        onCreateAccountBank={handleSaveAccountBank}
                    />

                    <EditAccountBankModal
                        showModal={showModalEditAccount}
                        setShowModal={setShowModalEditAccount}
                        accountBankIdToEdit={accountBankIdToEdit}
                        setAccountBankIdToEdit={setAccountBankIdToEdit}
                        onEditAccountBank={handleSaveAccountBank}
                    />

                    {
                        user.isAccountant == "n"
                            ?
                            <>
                                {(props.showModal)
                                    ?
                                    <>
                                        <Button
                                            variant="success"
                                            onMouseEnter={() => setHoverColor('A8D8EA')}
                                            onMouseLeave={() => setHoverColor('7FC6E0')}
                                            onClick={() => setShowModalAddAccountBank(!showModalAddAccountBank)}
                                            className="mr-2 text-dark"
                                        >
                                            <i className={`${props.labelIcon} text-dark`}></i>
                                            {props.labelLinkAdd}
                                        </Button>
                                    </>
                                    :   <a href={props.linkAdd} className="btn btn-success mr-2 text-dark"
                                            onMouseEnter={() => setHoverColor('A8D8EA')}
                                            onMouseLeave={() => setHoverColor('7FC6E0')}
                                        >
                                            <i className={`${props.labelIcon} text-dark`}></i>
                                            {props.labelLinkAdd}
                                        </a>
                                }
                                {!props.hasExpenses ? "" :
                                    <button type="button" className="btn btn-secondary mr-2">Despesas recorrentes</button>}
                                {!props.hasImport ? "" : <button type="button" className="btn btn-secondary mr-2">Importar</button>}
                                {!props.hasPrintOut ? "" :
                                    <button type="button" className="btn btn-secondary mr-2">Imprimir</button>}
                                {!props.hasMoreActions ? "" :
                                    <button type="button" className="btn btn-secondary mr-2">Mais Ações</button>}
                            </>
                            : <></>
                    }

                </div>

                <div className="mt-3">


                    <div>
                        <Paper className={classes.paper}>
                            {/* <Toolbar
                            className={clsx(classes.root, {
                                [classes.highlight]: numSelected > 0,
                            })}
                            >
                            <div className={classes.actions}>
                                {numSelected > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton aria-label="Delete">
                                    <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                ) : ''
                                }
                            </div>
                        </Toolbar> */}

                            <div className={classes.tableWrapper}>
                                <Modal
                                    show={showModalConfirmDelete}
                                    onHide={() => setShowModalConfirmDelete(false)}
                                    size="md"
                                    aria-labelledby="contained-modal-warning"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title className="d-flex align-items-center">
                                            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                                            Atenção
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <b>Tem certeza que deseja excluir este registro ?</b>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" onClick={() => deleteData()}>Excluir</Button>
                                        <Button variant="secondary" onClick={() => setShowModalConfirmDelete(!showModalConfirmDelete)}>Cancelar</Button>
                                    </Modal.Footer>
                                </Modal>
                                <TableContainer style={{ maxHeight: '75vh' }}>
                                    <Table
                                        stickyHeader
                                        className={`${classes.table} wrap`}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                    >
                                        <TableHead>
                                            <StyledTableRow>
                                                {props.headRows.map(row => (
                                                    <StyledTableCell
                                                        className="text-white"
                                                        key={row.id}
                                                        align='left'
                                                        padding='default'
                                                    // sortDirection={orderBy === row.id ? order : false}
                                                    >
                                                        {
                                                            props.sortable && !row.notSortable ? (
                                                                <StyledTableSortLabel
                                                                    className="text-white"
                                                                    active={row.id === sortReference}
                                                                    direction={sortDirection.toLowerCase()}
                                                                    onClick={() => handleSort(row.id, row.id === sortReference)}
                                                                >
                                                                    {row.label}
                                                                </StyledTableSortLabel>
                                                            ) : (
                                                                <strong>
                                                                    {row.label}
                                                                </strong>
                                                            )
                                                        }
                                                    </StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        </TableHead>

                                        <TableBody>
                                            {isLoadingData && (
                                                <TableRow>
                                                    <TableCell colSpan={99}>
                                                        <div className="d-flex justify-content-center">
                                                            <Spinner
                                                                as="span"
                                                                variant="primary"
                                                                animation="border"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {!isLoadingData && stableSort(rows, getSorting(order, orderBy))
                                                .slice(isBackEndPagination ? 0 : page * rowsPerPage, isBackEndPagination ? rows.length : page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.name);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <StyledTableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={index}
                                                            selected={isItemSelected}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <TableCell component="th" id={labelId} scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                            {(() => {
                                                                switch (props.page) {
                                                                    case 'clientes':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/clientes/${row.id}`
                                                                                }}>
                                                                                    {row.typePeople == "physical"
                                                                                        ? row.name
                                                                                        : row.fantasyName
                                                                                    }

                                                                                </TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/clientes/${row.id}`
                                                                                }}>{row.typePeople == "physical" ? row.cpf : row.cnpj}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/clientes/${row.id}`
                                                                                }}>{row.phone}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/clientes/${row.id}`
                                                                                }}>{row.registrationStatus == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/clientes/${row.id}`
                                                                                }}>
                                                                                    {row.typeRegister == "customer"
                                                                                        ? "Cliente"
                                                                                        : <>{row.typeRegister == "supplier" ? "Fornecedor" : "Ambos"}</>
                                                                                    }
                                                                                </TableCell>
                                                                            </>
                                                                        );
                                                                    case 'produtos':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/produtos/${row.id}`
                                                                                }}>
                                                                                    {row.name}
                                                                                </TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/produtos/${row.id}`
                                                                                }}>{row.code}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/produtos/${row.id}`
                                                                                }}>{row.stockInitial}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/produtos/${row.id}`
                                                                                }}>R$ {row.saleValue}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'transportadoras':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/transportadoras/${row.id}`
                                                                                }}>
                                                                                    {row.typePeople == "physical" ? row.name : row.fantasyName}
                                                                                </TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/transportadoras/${row.id}`
                                                                                }}>{row.typePeople == "physical" ? row.cpf : row.cnpj}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/transportadoras/${row.id}`
                                                                                }}>{row.phone}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'vendedores':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/vendedores/${row.id}`
                                                                                }}>
                                                                                    {row.typePeople == "physical" ? row.name : row.fantasyName}
                                                                                </TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/vendedores/${row.id}`
                                                                                }}>{row.phone}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/vendedores/${row.id}`
                                                                                }}>{(row.sellerCommission) ? row.sellerCommission.trim() : 0}%</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/vendedores/${row.id}`
                                                                                }}>{row.situation == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'pedidos':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/pedidos/${row.id}`
                                                                                }}>{row.requestDate}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/pedidos/${row.id}`
                                                                                }}>{row.customerId}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/pedidos/${row.id}`
                                                                                }}>R$ {row.totalPrice}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/pedidos/${row.id}`
                                                                                }}>{row.situation == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'orcamentos':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/orcamentos/${row.id}`
                                                                                }}>{row.budgetDate}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/orcamentos/${row.id}`
                                                                                }}>{row.customerId}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/orcamentos/${row.id}`
                                                                                }}>R$ {row.totalPrice}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/orcamentos/${row.id}`
                                                                                }}>{row.situation == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'entrada-de-mercadoria':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/entrada-de-mercadoria/${row.id}`
                                                                                }}>{row.nfeNumber}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/entrada-de-mercadoria/${row.id}`
                                                                                }}>{row.entryDate}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/entrada-de-mercadoria/${row.id}`
                                                                                }}>{row.provider}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/entrada-de-mercadoria/${row.id}`
                                                                                }}>R$ {row.totalPrice}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/entrada-de-mercadoria/${row.id}`
                                                                                }}>{row.situation == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'ordem-de-compra':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/ordem-de-compra/${row.id}`
                                                                                }}>{row.orderDate}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/ordem-de-compra/${row.id}`
                                                                                }}>{row.supplierId}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/ordem-de-compra/${row.id}`
                                                                                }}>R$ {row.totalPrice}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/ordem-de-compra/${row.id}`
                                                                                }}>{row.situation == "active" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'contas-bancarias':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    handleOpenEditAccountBankModal(row.id);
                                                                                }}>{row.name}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    handleOpenEditAccountBankModal(row.id);
                                                                                }}>{row.nameBank}</TableCell>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    handleOpenEditAccountBankModal(row.id);
                                                                                }}>{row.situation === "y" ? "Ativo" : "Inativo"}</TableCell>
                                                                            </>
                                                                        );

                                                                    case 'contas-a-receber':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/contas-a-receber/${row.id}`
                                                                                }}>{row.maturity}</TableCell>

                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/contas-a-receber/${row.id}`
                                                                                }}>{row.nameRecipe}</TableCell>

                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/contas-a-receber/${row.id}`
                                                                                }}>{row.value}</TableCell>

                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/contas-a-receber/${row.id}`
                                                                                }}>{row.nameBank}</TableCell>
                                                                                <TableCell align="left">
                                                                                    <a href="javascript:void (0)"
                                                                                        onClick={() => {
                                                                                            generateBillet(`${row.id}`);
                                                                                        }}>Boleto</a>
                                                                                </TableCell>
                                                                                <TableCell align="left">
                                                                                    <a href="javascript:void (0)"
                                                                                        onClick={() => {
                                                                                            generateShipment(`${row.id}`);
                                                                                        }}>Gerar Remessa</a>
                                                                                </TableCell>
                                                                            </>
                                                                        );

                                                                    case 'lista-de-precos':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/lista-de-precos/${row.id}`
                                                                                }}>{row.name}</TableCell>

                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/lista-de-precos/${row.id}`
                                                                                }}>
                                                                                    {row.priceListType == "1"
                                                                                        ? "Acréscimo"
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                row.priceListType == "2"
                                                                                                    ? "Desconto"
                                                                                                    : "Dinâmica"
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </TableCell>

                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/lista-de-precos/${row.id}`
                                                                                }}>{row.status == "0" ? "Inativo" : "Ativo"}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'categorias-produto':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/categorias-produto/${row.id}`
                                                                                }}>{row.nameCategory}</TableCell>
                                                                            </>
                                                                        );
                                                                    case 'marcas':
                                                                        return (
                                                                            <>
                                                                                <TableCell align="left" onClick={() => {
                                                                                    window.location.href = `/marcas/${row.id}`
                                                                                }}>{row.nameBrand}</TableCell>
                                                                            </>
                                                                        );
                                                                }
                                                            })()}
                                                            <TableCell align="left">
                                                                {/* <a href="#" class="btn btn-light-danger font-weight-bold" onClick={() => setShowModalConfirmDelete(!showModalConfirmDelete)}>
                                                                    <i class="flaticon-delete p-0"></i>
                                                                </a> */}
                                                                {
                                                                    props.page == 'contas-bancarias'
                                                                        ?
                                                                        <button type="button" className="btn btn-sm btn-dark font-weight-bold p-2 mr-3" onClick={() => handleOpenEditAccountBankModal(row.id) }>
                                                                            Editar
                                                                        </button>
                                                                        :
                                                                        <button type="button" className="btn btn-sm btn-dark font-weight-bold p-2 mr-3" onClick={() => { window.location.href = `/${props.page}/${row.id}` }}>
                                                                            Editar
                                                                        </button>
                                                                }
                                                            </TableCell>
                                                        </StyledTableRow>

                                                    );
                                                })}
                                            {!isBackEndPagination && emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={props.totalCount ?? rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Página Anterior',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Próxima Página',
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>
            </MainPageContentWrapper>
        </form>
    );
}