import React from "react";
import "../../app/style.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function LogoLoading() {
  return (
    <img 
      className="logo-spinner"
      width="32"
      height="30"
      src={toAbsoluteUrl("/media/logos/header/logo-header-gold-login.png")}
      alt="Logo Spinner" />
  )
}