import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { Autocomplete } from '@material-ui/lab';
import {ProductsTable} from './ProductsTable';
import { formatDate } from '../../../utils/dateFormat';
import { IproductsPurchaseOrder, IpurchaseOrder } from '../../../types/RequestPurchaseOrder';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { PurchaseOrder } from '../../../types/PurchaseOrder';
import { Link } from 'react-router-dom';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Ordem" },
    { reference: "provider", value: "Fornecedor" },
    { reference: "seller", value: "Vendedor" },
    { reference: "totalPrice", value: "Valor Total" },
    { reference: "installments", value: "Total de Parcelas" },
    { reference: "totalWeight", value: "Peso Bruto" },
    { reference: "carrier", value: "Transportadora"},
    { reference: "orderDate", value: "Data da ordem" },
    { reference: "status", value: "Situação" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "ipi", value: "IPI" },
    { reference: "total", value: "Valor Total" },
]

type Filters = {
    supplier: string,
    orderDate: string,
    deadline: string,
    status: string,
}

type Beneficiary = {
    id: number;
    name: string;
}

export function ListReportPurchaseOrder() {
    const [foundPurchaseOrderPaginate, setFoundPurchaseOrderPaginate] = useState<any[]>([]);
    const [foundPurchaseOrder, setFoundPurchaseOrder] = useState<any[]>([]);
    const [countTotalPurchaseOrder, setCountTotalPurchaseOrder] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [supplierId, setSupplierId] = useState("");

    const [supplierInput, setSupplierInput] = useState<any>('all');
    const [showProducts, setShowProducts] = useState(false);

    const [status, setStatus] = useState('all');
    const [products, setProducts] = useState('');
    const [orderDate, setOrderDate] = useState('');
    const [deadline, setDeadline] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [suppliers, setSuppliers] = useState([]);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();


    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundPurchaseOrderPaginate;
        let total: number = 0;
        let exportProductsData: ReportDataRow[] = [];

        aux.forEach((purchaseOrder) => {

            const orderDate = purchaseOrder.orderDate ? formatDate(purchaseOrder.orderDate) : '';
            const installmentsFormated = JSON.parse(purchaseOrder.installments);
            const productsFormated = JSON.parse(purchaseOrder.products);
            const carrier = String(purchaseOrder.carrier) !== 'null' ? String(purchaseOrder.carrier) : ' - ';
            const totalWeight = String(purchaseOrder.totalWeight) !== 'null' ? String(purchaseOrder.totalWeight) : ' - ';
            const provider = purchaseOrder.customerEntity.length 
                            ? (purchaseOrder.customerEntity[0].typePeople == "physical" ? purchaseOrder.customerEntity[0].name : purchaseOrder.customerEntity[0].corporateName)
                            : ' - '; 
            const seller = purchaseOrder.sellerEntity.length 
                            ? (purchaseOrder.sellerEntity[0].typePeople == "physical" ? purchaseOrder.sellerEntity[0].name : purchaseOrder.sellerEntity[0].corporateName)
                            : ' - ';

            const data: BodyDataBaseProps[] = [
                {
                    for: "id",
                    value: String(purchaseOrder.id),
                    jsx: <Link to={`/ordem-de-compra/${purchaseOrder.id}`}>{purchaseOrder.id}</Link>,
                    id: true 
                },
                { for: "provider", value: provider },
                { for: "seller", value: seller },
                { for: "totalPrice", value: 'R$ ' + purchaseOrder.totalPrice},
                { for: "installments", value: String(installmentsFormated.length) },
                { for: "totalWeight", value: totalWeight },
                { for: "carrier", value: carrier },
                { for: "orderDate", value: orderDate },
                { for: "status", value: getSituationText(purchaseOrder.status) },
            ];

            if (showProducts !== false) {
                data.push({
                    for: "products",
                    value: '',
                    secondaryRow: true,
                    jsx: <ProductsTable products={productsFormated} />
                });
            };

            list.push(data);
        });

        foundPurchaseOrder.forEach((purchaseOrder) => {

            const orderDate = purchaseOrder.orderDate ? formatDate(purchaseOrder.orderDate) : '';
            const installmentsFormated = JSON.parse(purchaseOrder.installments);
            const productsFormated = JSON.parse(purchaseOrder.products);
            const carrier = String(purchaseOrder.carrier) !== 'null' ? String(purchaseOrder.carrier) : ' - ';
            const totalWeight = String(purchaseOrder.totalWeight) !== 'null' ? String(purchaseOrder.totalWeight) : ' - ';
            const provider = purchaseOrder.customerEntity.length 
                            ? (purchaseOrder.customerEntity[0].typePeople == "physical" ? purchaseOrder.customerEntity[0].name : purchaseOrder.customerEntity[0].corporateName)
                            : ' - '; 
            const seller = purchaseOrder.sellerEntity.length 
                            ? (purchaseOrder.sellerEntity[0].typePeople == "physical" ? purchaseOrder.sellerEntity[0].name : purchaseOrder.sellerEntity[0].corporateName)
                            : ' - ';

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(purchaseOrder.id), id: true },
                { for: "provider", value: provider },
                { for: "seller", value: seller },
                { for: "totalPrice", value: 'R$ ' + purchaseOrder.totalPrice},
                { for: "installments", value: String(installmentsFormated.length) },
                { for: "totalWeight", value: totalWeight },
                { for: "carrier", value: carrier },
                { for: "orderDate", value: orderDate },
                { for: "status", value: getSituationText(purchaseOrder.status) },
            ];

            total += Number(purchaseOrder.totalPrice);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            if (showProducts !== false) {
                exportProductsData = productsFormated.map((product: IproductsPurchaseOrder) => {
                    return {
                        cells: [
                            {for: 'name', content: product.INSTANCE.name ?? '-'},
                            {for: 'amount', content: product.amount},
                            {for: 'unitaryValue', content: product.unitaryValue},
                            {for: 'ipi', content: product.ipi},
                            {for: 'total', content: product.total},
                        ],
                    };
                });
            };

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setTotalPrice(total);

        setExportTotals([
            { name: 'Total de Ordens', value: foundPurchaseOrder.length },
            { name: 'Preço Total', value: 'R$ ' + formatNumberToString(totalPrice) },
        ]);

        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundPurchaseOrderPaginate, foundPurchaseOrder]);

    const clearSearch = () => {
        setOrderDate('');
        setDeadline('')
        setProducts('all');
        setStatus('all');
        setSupplierId('');
        setShowProducts(false)
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: PurchaseOrder[], count:number}>("/report/purchaseOrder", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;


        const purchaseOrder = await api.get("/report/purchaseOrder", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundPurchaseOrderPaginate(data.rows ? rows : []);

        setFoundPurchaseOrder(purchaseOrder.data);
        setCountTotalPurchaseOrder(purchaseOrder.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            supplier: supplierId,
            orderDate,
            deadline,
            status,
        };
        reloadData();

    }, [
        supplierId,
        orderDate,
        deadline,
        status,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Ordem de Compras', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
            <div className="col-lg-8">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Fornecedor"
                        getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                        value={supplierId}
                        onSelect={(option) => setSupplierId(option ? String(option.id) : '')}
                        apiSearchHandler={((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' }))}
                        getSelectedOption={(loadedOptions) => {
                            if(!supplierId) return null;

                            const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(supplierId));
                            if(loadedBeneficiary) return loadedBeneficiary;

                            return CustomerService.getCustomerById(supplierId);
                        }}
                    />
                </div>
                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="pending" onClick={() => setStatus('pending')}>
                            Pendente
                        </MenuItem>

                        <MenuItem key="3" value="progress" onClick={() => setStatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="4" value="sent" onClick={() => setStatus('sent')}>
                            Enviado
                        </MenuItem>

                        <MenuItem key="5" value="asnwered" onClick={() => setStatus('asnwered')}>
                            Respondido
                        </MenuItem>

                        <MenuItem key="6" value="chosen" onClick={() => setStatus('chosen')}>
                            Escolhido
                        </MenuItem>

                        <MenuItem key="7" value="paid" onClick={() => setStatus('paid')}>
                            Pago
                        </MenuItem>

                        <MenuItem key="7" value="attended" onClick={() => setStatus('attended')}>
                            Finalizado
                        </MenuItem>

                        <MenuItem key="7" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data da Ordem"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={orderDate}
                        onChange={(e) => setOrderDate(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data Limite"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                    />
                </div>

                <div className="col-lg-6"></div>
                <div className="col-lg-3">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProducts}
                                onChange={(_, checked) => setShowProducts(checked)}
                            />
                        }
                        label="Mostrar Produtos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Ordem de Compras"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Ordem de Compras', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalPurchaseOrder}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de Ordens</TableCell>
                                    <TableCell colSpan={1}>{foundPurchaseOrder.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(totalPrice)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}