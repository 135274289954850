import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { useHeaderLayoutProps } from "../../hooks/useHeaderLayoutProps";

export function HeaderSimple() {
  const layoutProps = useHeaderLayoutProps();

  const styleLogo = {
      maxWidth: '160px'
  };

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            <div className="header-logo">
              <Link to="/">
                <img
                  className="logo-default"
                  style={styleLogo}
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/header/logo-header-gold.png")}
                />
                <img
                  className="logo-sticky max-h-40px"
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/header/logo-header-black.png")}
                />
              </Link>
            </div>
            {/* end::Header Logo */}
          </div>
          {/* end::Left */}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
