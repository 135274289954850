import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react"

type MainPageContentWrapperProps = {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    mainPageContentWrapper: {
        backgroundColor: '#fff',
        padding: '20px 16px 16px 16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        wordWrap: 'break-word',
    },
});

export function MainPageContentWrapper({ children }: MainPageContentWrapperProps) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.mainPageContentWrapper, 'newProductWrapper')}>
            {children}
        </div>
    );
}