import React, { useState, useEffect } from 'react';
import { useSubheader } from "../../_metronic/layout";

import api from "../services/Api"
import axios from "axios";

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    Radio,
    RadioGroup,
    FormHelperText,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputAdornment,
    IconButton,
    Grid
} from '@material-ui/core';

import {
    Button,
    Image,
    InputGroup,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import NumberFormat from 'react-number-format';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import logService from '../services/LogService';

import '../style.css';
import { cnpjSearch } from '../utils/cnpjSearch';
import { MainPageContentWrapper } from '../components/MainPageContentWrapper';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    typePeople: string,
    cpf: string,
    name: string,
    rg: string,
    cnpj: string,
    corporateName: string,
    fantasyName: string,
    stateRegistration: string,
    registerNational: string,
    zipCode: string,
    address: string,
    number: string,
    district: string,
    complement: string,
    city: string,
    state: string,
    email: string,
    phone: string,
    note: string
}

export function NewCarriers() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar uma Transportadora");

    const [isSubmit, setIsSubmit] = useState(false);

    const [nameInvalid, setNameInvalid] = useState(false);
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
    const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [typePeople, setTypePeople] = useState("legal");
    const [cpfCarrier, setCpfCarrier] = useState("");
    const [name, setName] = useState("");
    const [rg, setRg] = useState("");
    const [cnpjCarrier, setCnpjCarrier] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [fantasyName, setFantasyName] = useState("");
    const [stateRegistration, setStateRegistration] = useState("");
    const [registerNational, setRegisterNational] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [note, setNote] = useState("");

    const [allStates, setAllStates] = useState({ states: [] });

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [nameCnpj, setNameCnpj] = useState("");
    const [fantasy, setFantasy] = useState("");

    useEffect(() => {
        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: statesBrazil.data });
        }
        getStates();
    }, []);

    async function infoAddress(zipCodeCnpj?: string) {
        const checkCep = async () => {

            var zipcodeChanged;

            if (zipCodeCnpj) {
                zipcodeChanged = zipCodeCnpj.replace(/[^0-9]/g, "");

            } else {
                zipcodeChanged = zipCode.replace(/[^0-9]/g, "");

            }


            const cep = await axios.get(`https://viacep.com.br/ws/${zipcodeChanged}/json/`);

            setAddress(cep.data.logradouro);
            setDistrict(cep.data.bairro);
            setCity(cep.data.localidade);
            setState(cep.data.uf);
        }
        checkCep();
    }

    async function CheckCnpj(value: string) {
        const cnpjFormated = value.replace(/[^0-9]/g, "");
        var cnpjIsValid    = cnpj.isValid(cnpjFormated);

        if (cnpjIsValid) {
            const cnpjResponse = await cnpjSearch(value);

            if (cnpjResponse.status === 'ERROR') {
                setMsgError(cnpjResponse.message);
                setShowModalExist(true);
                setCnpjInvalid(true);
                setCnpjCarrier('');
                return;
            }

            setCorporateName(cnpjResponse.nome);
            setFantasyName(cnpjResponse.fantasia);

            setZipCode(cnpjResponse.cep);
            setAddress(cnpjResponse.logradouro);
            setDistrict(cnpjResponse.bairro);
            setCity(cnpjResponse.municipio);
            setState(cnpjResponse.uf);
            setNumber(cnpjResponse.numero);
            setComplement(cnpjResponse.complemento);

        } else {
            setMsgError("Cnpj inválido!");
            setShowModalExist(true);
            setCnpjInvalid(true);
            setCnpjCarrier('');
        }
    }

    async function checkEmailCustomerExist() {

        if (email) {
            var response = await api.get(`/carriers/emailExist/${email}`);
            
            if (response.data.length > 0) {
                setMsgError("E-mail informado já existe!")
                setShowModalExist(true);
                setEmail("");
            }
        }

    }

    async function checkCpfCustomerExist() {

        if (cpfCarrier) {
            var cpfFormated = cpfCarrier.replace(/[^0-9]/g, "");

            var response = await api.get(`/carriers/cpfExist/${cpfFormated}`);

            if (response.data.length > 0) {
                setMsgError("Cpf informado já existe!");
                setShowModalExist(true);
                setCpfCarrier("");
            }
        }

    }

    async function checkRgCustomerExist() {

        if (rg) {
            var rgFormated = rg.replace(/[^0-9]/g, "");

            var response = await api.get(`/carriers/rgExist/${rgFormated}`);

            if (response.data.length > 0) {
                setMsgError("Rg informado já existe!");
                setShowModalExist(true);
                setRg("");
            }
        }

    }

    async function checkCnpjCustomerExist() {

        if (cnpjCarrier) {
            var cnpjFormated = cnpjCarrier.replace(/[^0-9]/g, "");

            var response = await api.get(`/carriers/cnpjExist/${cnpjFormated}`);

            if (response.data.length > 0) {
                setMsgError("Cnpj informado já existe!");
                setShowModalExist(true);
                setCnpjCarrier("");
            }
        }

    }

    async function checkPhoneCustomerExist() {

        if (phone) {
            var phoneFormated = phone.replace(/[^0-9]/g, "");

            var response = await api.get(`/carriers/phoneExist/${phoneFormated}`);

            if (response.data.length > 0) {
                setMsgError("Telefone informado já existe!");
                setShowModalExist(true);
                setPhone("");
            }
        }

    }

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "phone" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if ("email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    function inputsVerify(): boolean {

        if (typePeople === "physical") {

            if (!cpfCarrier || sanitizeInput("cpf", cpfCarrier).length !== 11) {

                setIsSubmit(false)
                setMsgError("Preencha o cpf corretamente")
                setShowModalExist(true);
                setCpfInvalid(true);

                return false

            } else if (!name) {

                setIsSubmit(false)
                setMsgError("Preencha o nome corretamente")
                setShowModalExist(true);
                setNameInvalid(true);

                return false

            }

        } else if (typePeople === "legal") {

            if (!cnpjCarrier || sanitizeInput("cnpj", cnpjCarrier).length !== 14) {

                setIsSubmit(false)
                setMsgError("Preencha o CNPJ corretamente")
                setShowModalExist(true);
                setCnpjInvalid(true);


                return false

            } else if (!corporateName) {

                setIsSubmit(false)
                setMsgError("Preencha a razão social da empresa")
                setShowModalExist(true);
                setCorporateNameInvalid(true);


                return false

            } else if (!fantasyName) {

                setIsSubmit(false)
                setMsgError("Preencha o nome fantasia da empresa")
                setShowModalExist(true);
                setFantasyNameInvalid(true);


                return false

            }


        }

        if (!email || sanitizeInput("email", email) === "false") {
            setIsSubmit(false)
            setMsgError("Preencha o email corretamente")
            setShowModalExist(true);
            setEmailInvalid(true);

            return false

        }

        if (!phone || sanitizeInput("phone", phone).length !== 10) {
            setIsSubmit(false)
            setMsgError("Preencha o telefone corretamente")
            setShowModalExist(true);
            setPhoneInvalid(true);


            return false

        }       

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        var raw = JSON.stringify({
            "typePeople": typePeople,
            "cpf": ((!cpfCarrier) ? null : cpfCarrier.replace(/[^0-9]/g, "")),
            "name": name,
            "rg": ((!rg) ? null : rg.replace(/[^0-9]/g, "")),
            "cnpj": ((!cnpjCarrier) ? null : cnpjCarrier.replace(/[^0-9]/g, "")),
            "corporateName": corporateName,
            "fantasyName": fantasyName,
            "stateRegistration": stateRegistration,
            "registerNational": registerNational,
            "zipCode": (!zipCode ? null : zipCode.replace(/[^0-9]/g, "")),
            "address": address,
            "number": number,
            "district": district,
            "complement": complement,
            "city": city,
            "state": state,
            "email": (!email ? null : email),
            "phone": (!phone ? null : phone.replace(/[^0-9]/g, "")),
            "note": note
        });

        const createResponse = await api.post("/carriers", raw);

        logService.logRegister({
            itemId: createResponse.data.id,
            module: 'Transportadoras',
            itemName: createResponse.data.typePeople === 'physical' ? createResponse.data.name : createResponse.data.corporateName,
        });

        setShowModalSuccess(true);
        setIsSubmit(false);
    }

    return (
        <MainPageContentWrapper>
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/transportadoras";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados criados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/transportadoras";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={`${classes.container} steppers`} noValidate autoComplete="off">
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="details" data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a transportadora</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/transportadoras">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-3 mt-18">
                            <Grid item lg={3} md={6} xs={12}>
                                <TextField
                                    select
                                    size="small"
                                    label="Tipo de pessoa"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    defaultValue={typePeople}
                                    onChange={(e) => {
                                        setTypePeople(e.target.value);

                                        setCpfCarrier("");
                                        setName("");
                                        setRg("");

                                        setCnpjCarrier("");
                                        setCorporateName("");
                                        setFantasyName("");
                                        setStateRegistration("");
                                    }}
                                >
                                    <MenuItem key="0" value="legal">
                                        Pessoa Jurídica
                                    </MenuItem>

                                    <MenuItem key="1" value="physical">
                                        Pessoa Física
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            {typePeople === "physical"
                                ?
                                <>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <NumberFormat
                                            margin="normal"
                                            required
                                            customInput={TextField}
                                            format="###.###.###-##"
                                            label="CPF"
                                            mask="_"
                                            value={cpfCarrier}
                                            onChange={(e) => {
                                                setCpfCarrier(e.target.value)
                                                setCpfInvalid(e.target.value ? false : true)
                                            }}
                                            onBlur={() => checkCpfCustomerExist()}
                                            error={cpfInvalid}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            label="Nome"
                                            required
                                            size="small"
                                            className={classes.error}
                                            margin="normal"
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                                setNameInvalid(e.target.value ? false : true)
                                            }}
                                            error={nameInvalid}

                                        />
                                    </Grid>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            label="RG"
                                            size="small"
                                            margin="normal"
                                            value={rg}
                                            onChange={(e) => setRg(e.target.value)}
                                            onBlur={() => checkRgCustomerExist()}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <NumberFormat
                                            margin="normal"
                                            customInput={TextField}
                                            label="CNPJ"
                                            required
                                            format="##.###.###/####-##"
                                            mask="_"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="Toggle password visibility"
                                                            onClick={() => CheckCnpj(cnpjCarrier)}
                                                        >
                                                            <i className="flaticon-search"></i>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={cnpjCarrier}
                                            onChange={(e) => {
                                                setCnpjCarrier(e.target.value)
                                                setCnpjInvalid(e.target.value ? false : true)
                                            }}
                                            error={cnpjInvalid}
                                            onBlur={() => checkCnpjCustomerExist()}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            value={corporateName}
                                            size="small"
                                            label="Razão social"
                                            required
                                            className={classes.error}
                                            margin="normal"
                                            onChange={(e) => {
                                                setCorporateName(e.target.value)
                                                setCorporateNameInvalid(e.target.value ? false : true)
                                            }}
                                            error={corporateNameInvalid}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            value={fantasyName}
                                            size="small"
                                            label="Nome fantasia"
                                            required
                                            className={classes.error}
                                            margin="normal"
                                            onChange={(e) => {
                                                setFantasyName(e.target.value)
                                                setFantasyNameInvalid(e.target.value ? false : true)
                                            }}
                                            error={fantasyNameInvalid}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={12}>
                                        <TextField
                                            label="Inscrição Estadual da empresa"
                                            size="small"
                                            margin="normal"
                                            value={stateRegistration}
                                            onChange={(e) => setStateRegistration(e.target.value)}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="RNTC (ANTT)"
                                    size="small"
                                    margin="normal"
                                    value={registerNational}
                                    onChange={(e) => setRegisterNational(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <NumberFormat
                                    margin="normal"
                                    customInput={TextField}
                                    format="#####-###"
                                    label="CEP"
                                    mask="_"
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />

                                <Button variant="primary" className="btn-sm" onClick={() => infoAddress()}><i className="flaticon-search p-0"></i></Button>
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="Endereço"
                                    size="small"
                                    margin="normal"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    type="number"
                                    label="Número"
                                    size="small"
                                    margin="normal"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="Bairro"
                                    size="small"
                                    margin="normal"
                                    value={district}
                                    onChange={(e) => setDistrict(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="Complemento"
                                    size="small"
                                    margin="normal"
                                    value={complement}
                                    onChange={(e) => setComplement(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="Cidade"
                                    size="small"
                                    margin="normal"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <Autocomplete
                                    size="small"
                                    value={{sigla: state}}
                                    options={allStates.states}
                                    getOptionLabel={({ sigla }) => sigla}
                                    style={{ marginTop: "6px" }}
                                    onInputChange={(event, newInputValue) => {
                                        setState(newInputValue);
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        label="Estado"
                                    />
                                    }
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <TextField
                                    label="E-mail"
                                    required
                                    size="small"
                                    className={classes.error}
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailInvalid(e.target.value ? false : true)
                                    }}
                                    error={emailInvalid}
                                    onBlur={() => checkEmailCustomerExist()}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} xs={12}>
                                <NumberFormat
                                    margin="normal"
                                    customInput={TextField}
                                    format="(##) ####-####"
                                    label="Telefone"
                                    required
                                    mask="_"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                        setPhoneInvalid(e.target.value ? false : true)
                                    }}
                                    error={phoneInvalid}
                                    onBlur={() => checkPhoneCustomerExist()}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Observações"
                                    size="small"
                                    multiline
                                    rows="4"
                                    margin="normal"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}