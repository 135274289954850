import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Tabs, Tab, Modal } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { makeStyles, Grid, MenuItem, TextField, Popper, FormControlLabel, Checkbox } from '@material-ui/core';


import api from '../../services/Api';
import { Installment } from './Installment';
import { ListProducts } from './ListProducts';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { UnderlineLink } from '../../components/UnderlineLink';
import { ServiceOrderData } from '../../types/ServiceOrderData';
import { useProductsInstallments, Product, InstallmentDetails } from './context';

import '../../style.css';
import LogService from '../../services/LogService';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import { getTypeService } from '../../utils/getTypeService';
import CustomerSelect from '../../components/CustomerSelect';
import { yesOrNo } from '../../types/yesOrNo';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { useStyles } from '../../hooks/styles';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import { GenericObject } from '../../types/GenericObject';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function EditServiceOrder() {
  const { user } = useSelector((state: any) => state.auth);

  const [activeTab, setActiveTab] = useState("services");

  const [beforeSubmitData, setBeforeSubmitData] = useState<GenericObject>({});
  const [date, setDate] = useState('');
  const [numberSO, setNumberSO] = useState(0);
  const [customerId, setCustomerId] = useState<number>();
  const [customerName, setCustomerName] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState(0);

  const [type, setType] = useState("");
  const [issues, setIssues] = useState("");
  const [report, setReport] = useState("");
  const [seller, setSeller] = useState("");
  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");
  const [warranty, setWarranty] = useState("");
  const [situation, setSituation] = useState("");
  const [equipment, setEquipment] = useState("");
  const [receivement, setReceivement] = useState("");
  const [referencies, setReferencies] = useState("");
  const [startDate, setStartDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [internalComments, setInternalComments] = useState("");
  const [accomplishmentDate, setAccomplishmentDate] = useState("");
  const [accomplishmentTime, setAccomplishmentTime] = useState("");
  const [sellerCommissioning, setSellerCommissioning] = useState(false);

  const [discount, setDiscount] = useState("R$0,00");
  const [totalValue, setTotalValue] = useState("R$0,00");
  const [expenditure, setExpenditure] = useState("R$0,00");
  const [totalValueServices, setTotalValueServices] = useState("R$0,00");
  const [totalValueProducts, setTotalValueProducts] = useState("R$0,00");
  const [msgError, setMsgError] = useState('');
  const [showModalExist, setShowModalExist] = useState(false);

  const [paymentInvalid, setPaymentInvalid] = useState<boolean>(false);
  const [servicesInvalid, setServicesInvalid] = useState<boolean>(false);
  const [productsInvalid, setProductsInvalid] = useState<boolean>(false);

  const [nextButton, setNextButton] = useState('first');
  const [previousIcon, setPreviousIcon] = useState("flaticon-suitcase");

  // Input Verify
  const [customerError, setCustomerError] = useState(false);
  const [sellerError, setSellerError] = useState(false);
  const [installmentsError, setInstallmentsError] = useState(false);
  const [productsError, setProductsError] = useState(true);
  const [servicesError, setServicesError] = useState(true);
  const [accomplishmentDateError, setAccomplishmentDateError] = useState(false);

  const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    products,
    services,
    installments,
    dispatchProducts,
    dispatchServices,
    dispatchInstallments,
  } = useProductsInstallments();

  // Modal New Customer
  const {
    showModalNewCustomer, setShowModalNewCustomer,
    newCustomerDefaultData, setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
    if (customerId) {
      const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
      setCustomerLimitCreditInformation(limitCreditInfo);
    } else {
      setCustomerLimitCreditInformation(undefined);
    }
  }, []);

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomerId(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product, isService?: boolean } | null>(null);
  const {
    showModalNewProduct, setShowModalNewProduct,
    newProductDefaultData, setNewProductDefaultData,
  } = useNewProductForm();

  const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
    if (!productBeingCreated) return;

    const selectableProduct: SelectableProduct = {
      ...createdProduct as any,
      saleValue: formatToFloat(createdProduct.saleValue),
    };

    const amount = productBeingCreated.product.amount || 1;

    if (productBeingCreated.isService) {
      dispatchServices({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
            totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
            INSTANCE: selectableProduct,
          },
        }
      });
    } else {
      dispatchProducts({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
            totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
            INSTANCE: selectableProduct,
          },
        }
      });
    }
  }, [productBeingCreated]);

  const handleClickAddProduct = (typedText: string, index: number, product: Product, isService?: boolean) => {
    setNewProductDefaultData({ nameProduct: typedText, selectedType: isService ? 'service' : 'product' });
    setProductBeingCreated({ index, product, isService })
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    const totalService = services.reduce((acc, { totalValue }) => acc + totalValue, 0);
    const totalProduct = products.reduce((acc, { totalValue }) => acc + totalValue, 0);
    const discountNumber = formatToFloat(discount);
    const expenditureNumber = formatToFloat(expenditure);

    const partial = totalService + totalProduct + expenditureNumber;

    if (discountNumber > partial) {
      alert("Desconto não pode ser maior que o valor total");

      setTotalValueServices(formatCurrency(totalService));
      setTotalValueProducts(formatCurrency(totalProduct));

      setDiscount("R$0,00");
      setTotalValue("R$0,00");

      return;
    }

    const total = partial - discountNumber;

    setTotalValueServices(formatCurrency(totalService));
    setTotalValueProducts(formatCurrency(totalProduct));
    setTotalValue(formatCurrency(total));
  }, [services, products, expenditure, discount]);

  useLayoutEffect(() => {
    async function loadData() {
      const { data } = await api.get<ServiceOrderData>(`service-order/${id}`);

      const services: Product[] = JSON.parse(data.services);
      const products: Product[] = JSON.parse(data.products);
      const installments: InstallmentDetails[] = JSON.parse(data.installments);

      dispatchServices({
        type: "INITIAL",
        payload: {
          index: 0,
          products: services,
        }
      });

      dispatchProducts({
        type: "INITIAL",
        payload: {
          index: 0,
          products,
        },
      });

      dispatchInstallments({
        type: "INITIAL",
        payload: {
          installments,
        }
      });

      const discountData = formatCurrency(data.discount);
      const totalValueData = formatCurrency(data.totalValue);
      const expenditureData = formatCurrency(data.expenditure);
      // const totalValueServicesData = formatCurrency(data.discount);
      // const totalValueProductsData = formatCurrency(data.discount);

      setDate(data.date ?? '');
      setType(data.type ?? '');
      setIssues(data.issues ?? '');
      setReport(data.report ?? '');
      setSeller(String(data.seller ?? ''));
      setStatus(data.status ?? '');
      setDiscount(discountData ?? '');
      setNumberSO(data.numberSO ?? '');
      setCustomerId(data.customerId ?? undefined);
      setCustomerName(data.customerName ?? '');
      setComments(data.comments ?? '');
      setWarranty(data.warranty ?? '');
      setSituation(data.situation ?? '');
      setEquipment(data.equipment ?? '');
      setTotalValue(totalValueData);
      setExpenditure(expenditureData);
      setReceivement(data.receivement ?? '');
      setReferencies(data.referencies ?? '');
      setStartDate(data.startDate ?? '');
      setDeliveryDate(data.deliveryDate ?? '');
      setInternalComments(data.internalComments ?? '');
      setInstallmentAmount(data.installmentAmount ?? '');
      setAccomplishmentDate(data.accomplishmentDate ?? '');
      setAccomplishmentTime(data.accomplishmentTime ?? '');
      setSellerCommissioning(data.sellerCommissioning === 'y');
      // setTotalValueServices(totalValueServicesData);
      // setTotalValueProducts(totalValueProductsData);

      setBeforeSubmitData(data);
      handleCustomerChanged(data.customerId ?? undefined, data.customerName);
    }

    loadData();
  }, [])

  function inputsVerify() {
    setSellerError(false);
    setProductsError(false);
    setAccomplishmentDateError(false);
    setCustomerError(false);
    setInstallmentsError(false);
    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      setServiceError(i, service, false);
    }
    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      setProductError(i, product, false);
    }

    if (accomplishmentDate) {
      const dateIsValid = dateIsBetweenRange(accomplishmentDate, '', extractDateStringFromTimestamp());
      if (!dateIsValid) {
        setMsgError("A data de emissão não pode ser maior que o horário atual!");
        setAccomplishmentDateError(true);
        setActiveTab("detailsServiceOrder");
        setShowModalExist(true);
        return false;
      }
    }

    if (!customerId && !customerName) {
      setMsgError("Campo Cliente é obrigatório!")
      setShowModalExist(true);
      setCustomerError(true);
      return false
    }

    if (customerId && customerLimitCreditInformation && beforeSubmitData.customerId !== customerId) {
      const totalOrder = formatToFloat(totalValue ?? 0);

      if ((totalOrder + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
        // Se salvar a OS ultrapassar o limite de crédito do cliente
        setMsgError("Não é possível salvar a ordem de serviço pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
        setShowModalExist(true);
        setCustomerError(true);
        return false;
      }
    }

    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      if (service.totalValue && !service.INSTANCE?.id && !service.name) {
        setServiceError(i, service, true);
        setMsgError('Informe um serviço!');
        setActiveTab('services');
        setShowModalExist(true);
        return false;
      }
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (product.totalValue && !product.INSTANCE?.id && !product.name) {
        setProductError(i, product, true);
        setMsgError('Informe um produto!');
        setActiveTab('products');
        setShowModalExist(true);
        return false;
      }
    }

    if (!installments.length) {
      setActiveTab("payment");
      setMsgError("Informe as parcelas da ordem de serviço!");
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

    if (sumInstallments !== formatToFloat(totalValue)) {
      setActiveTab("payment");
      setMsgError("A soma das parcelas deve concidir com o valor total da ordem de serviço!");
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    // if (!productsError) {
    //   setMsgError("Selecione pelo menos 1(um) Produto!");
    //   setShowModalExist(true);
    //   return false;
    // }

    const hasServices = services.find((service) => service.name || service.INSTANCE.id);

    if (!hasServices) {
      setMsgError("Selecione pelo menos 1(um) Serviço!");
      setShowModalExist(true);
      return false;
    }

    return true

  }

  function setServiceError(index: number, service: Product, error?: boolean) {
    dispatchServices({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...service,
          hasError: error,
        },
      }
    });
  }

  function setProductError(index: number, product: Product, error?: boolean) {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...product,
          hasError: error,
        },
      }
    });
  }

  const isValid = (data: any) => {
    setProductsError(data)
    console.log(productsError)
  };

  const isValid2 = (data: any) => {
    setServicesError(data);
    console.log(servicesError);
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    if (!inputsVerify()) return

    try {
      const raw = {
        // numberSO,
        type,
        date,
        deliveryDate,
        warranty,
        accomplishmentDate,
        accomplishmentTime,
        referencies,
        comments,
        internalComments,
        equipment,
        issues,
        receivement,
        report,
        customerId: customerId ?? null,
        customerName: !customerId ? customerName : null,
        seller: seller ? Number(seller) : null,
        sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
        status: "open",
        installmentAmount,
        discount: formatToFloat(discount),
        totalValue: formatToFloat(totalValue),
        expenditure: formatToFloat(expenditure),

        products: JSON.stringify(products),
        services: JSON.stringify(services),
        installments: JSON.stringify(installments),
      };

      const response = await api.put(`service-order/${id}`, raw);

      const previousCustomerEntity = await CustomerService.getCustomerById(beforeSubmitData.customerId);
      const newCustomerEntity = await CustomerService.getCustomerById(response.data.customerId);

      LogService.logEdit({
        itemId: response.data.id,
        itemName: 'Serviço',
        module: 'Ordem de Serviço',
        oldData: {
          ...beforeSubmitData,
          customer: CustomerService.getCustomerName(previousCustomerEntity, beforeSubmitData.customerName)
        },
        newData: {
          ...response.data,
          customer: CustomerService.getCustomerName(newCustomerEntity, response.data.customerName)
        },
        formattedFields: {
          accomplishmentDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          deliveryDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          createdAt: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          seller: (value) => SellerService.getNameByIdAsync(value),
          expenditure: (value) => formatNumberToString(value),
          discount: (value) => formatNumberToString(value),
          type: (value) => getTypeService(value)
        },
        fieldsMap: {
          type: 'Tipo da OS',
          customer: 'Cliente',
          seller: 'Vendedor',
          numberSO: 'Nº da OS',
          createdAt: 'Previsão data de início',
          deliveryDate: 'Data de entrega',
          warranty: 'Garantia',
          accomplishmentDate: 'Data da relização',
          accomplishmentTime: 'Hora da realização',
          referencies: 'Referência',
          comments: 'Observações',
          internalComments: 'Observações internas',
          equipment: 'Equipamento',
          issues: 'Problemas',
          receivement: 'Observações do recebimento',
          report: 'Laudo técnico',
          expenditure: 'Valor das despesas',
          discount: 'Valor do desconto',
        },
      });

      history.push("/ordem-de-servico");

    } catch (error) {
      console.log(error);
      setMsgError('Ocorreu um erro ao processar a requisição');
      setShowModalExist(true);
    }
  };

  function changeTabIcon(previousIcon: string) {
    let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
    if (previousIcon === "flaticon-suitcase") {
            const hasServices = services.find((service) => service.name || service.INSTANCE.id);

            if (hasServices) {
                const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-delete');
                
                if (current_icon) {
                current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
                current_icon?.classList.replace("text-danger", "text-success");
                } else {
                prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-check-mark");
                prev_icon?.classList.add("text-success");
                }

                setServicesInvalid(false);
            } else {
                const current_icon = document.querySelector<HTMLElement>('#services .flaticon2-check-mark');

                setServicesInvalid(true);

                if (current_icon) {
                    current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                    current_icon.classList.replace("text-success", "text-danger");
                } else {
                    prev_icon?.classList.replace("flaticon-suitcase", "flaticon2-delete");
                    prev_icon?.classList.add("text-danger");
                }
            }
    } else if (previousIcon === "flaticon-open-box") {
        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (hasProducts) {
            const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-delete');
            
            if (current_icon) {
            current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
            current_icon?.classList.replace("text-danger", "text-success");
            } else {
            prev_icon?.classList.replace("flaticon-open-box", "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
            }

            setProductsInvalid(false);
        } else {
            const current_icon = document.querySelector<HTMLElement>('#products .flaticon2-check-mark');

            setProductsInvalid(true);

            if (current_icon) {
                current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                current_icon.classList.replace("text-success", "text-danger");
            } else {
                prev_icon?.classList.replace("flaticon-open-box", "flaticon2-delete");
                prev_icon?.classList.add("text-danger");
            }
        }
    } else if (previousIcon === "flaticon-list-1") {
        if (installments.length) {
            const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-delete');
            
            if (current_icon) {
            current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
            current_icon?.classList.replace("text-danger", "text-success");
            } else {
            prev_icon?.classList.replace("flaticon-list-1", "flaticon2-check-mark");
            prev_icon?.classList.add("text-success");
            }

            setPaymentInvalid(false);
        } else {
            const current_icon = document.querySelector<HTMLElement>('#payment .flaticon2-check-mark');

            setPaymentInvalid(true);

            if (current_icon) {
                current_icon.classList.replace("flaticon2-check-mark", "flaticon2-delete");
                current_icon.classList.replace("text-success", "text-danger");
            } else {
                prev_icon?.classList.replace("flaticon-list-1", "flaticon2-delete");
                prev_icon?.classList.add("text-danger");
            }
        }
    }
    else {
      prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
      prev_icon?.classList.add("text-success");
    }
}

  function changeTab(tab: string, icon: string, previousIcon: string) {
    document.getElementById('services')?.classList.remove('current');
    document.getElementById('products')?.classList.remove('current');
    document.getElementById('detailsServiceOrder')?.classList.remove('current');
    document.getElementById('totalServiceOrder')?.classList.remove('current');
    document.getElementById('payment')?.classList.remove('current');
    document.getElementById('attachment')?.classList.remove('current');

    let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
    if (all_icons != undefined) {
      all_icons.forEach(function(item){
        item.style.color = '#666';
      });
    }

    changeTabIcon(previousIcon);

    let sel_icon = document.querySelector<HTMLElement>('.'+icon);
    if (sel_icon != undefined) {
            sel_icon.style.color = '#fff';
    }

    document.getElementById(tab)?.classList.add('current');
    setActiveTab(tab);
    if (tab == 'services') {
            setNextButton('first');
    } else if (tab == 'attachment') {
            setNextButton('last');
    } else {
            setNextButton('mid');
    }

    setPreviousIcon(icon);
  }

  function backNextOption(value:string){
      let arr = ['services', 'products', 'detailsServiceOrder', 'totalServiceOrder', 'payment', 'attachment'];
      let arr2 = ['flaticon-suitcase', 'flaticon-open-box', 'flaticon-doc', 'flaticon-coins', 'flaticon-list-1', 'flaticon-tool-1'];
      let count = arr.length
      let index = arr.indexOf(activeTab);

      if (value == 'back') {
              if (activeTab == arr[0]) {
                  setNextButton('first');
              } else {
                  index = index-1;
                  changeTab(arr[index], arr2[index], arr2[index+1]);
              }
      } else {
              if (activeTab == arr[count-1]) {
                  setNextButton('last');
              } else {                
                  index = index+1;
                  changeTab(arr[index], arr2[index], arr2[index-1]);
              }
      }
  }

  return (
    <MainPageContentWrapper>
      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={(evt) => onSubmit(evt)} className={`${classes.container} steppers`}>
        <div className="col-lg-12">
          <div
              className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_create_account_stepper' style={{ float: "left" }}
          >
            <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                <div className='card-body px-6 py-10'>
                    <div className='stepper-nav'>
                            <div className='stepper-item current' id="services" data-kt-stepper-element='nav' onClick={() => changeTab('services', 'flaticon-suitcase', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <i className="flaticon-suitcase icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Serviços</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Serviços da ordem de serviço</div>
                                </div>
                                </div>
                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                            </div>
                            <div className='stepper-item' id="products" data-kt-stepper-element='nav' onClick={() => changeTab('products', 'flaticon-open-box', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                        <i className="flaticon-open-box icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Produtos</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Produtos da ordem de serviço</div>
                                </div>
                                </div>
                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                            </div>
                            <div className='stepper-item' id="detailsServiceOrder" data-kt-stepper-element='nav' onClick={() => changeTab('detailsServiceOrder', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                        <i className="flaticon-doc icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Detalhes</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Detalhes da Nota Fiscal de Serviço</div>
                                </div>
                                </div>
                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                            </div>
                            <div className='stepper-item' id="totalServiceOrder" data-kt-stepper-element='nav' onClick={() => changeTab('totalServiceOrder', 'flaticon-coins', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <i className="flaticon-coins icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Totais</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Totais da Nota Fiscal de Serviço</div>
                                </div>
                                </div>
                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                            </div>
                            <div className='stepper-item' id="payment" data-kt-stepper-element='nav' onClick={() => changeTab('payment', 'flaticon-list-1', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <i className="flaticon-list-1 icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Pagamento</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Pagamento da Nota Fiscal de Serviço</div>
                                </div>
                                </div>
                                <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                            </div>
                            <div className='stepper-item' id="attachment" data-kt-stepper-element='nav' onClick={() => changeTab('attachment', 'flaticon-tool-1', previousIcon)} style={{cursor: "pointer"}}>
                                <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <i className="flaticon-tool-1 icon_change_color" style={{color: "#666"}}></i>
                                </div>
                                <div className='stepper-label'>
                                        <h3 className='stepper-title text-break'>Anexos</h3>
                                        <div className='stepper-desc fw-semibold text-break'>Anexos da ordem de compra</div>
                                </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>               
        </div>
        <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <div id="form-buttons"
                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                    rounded p-2 position-fixed"
                    style={{ zIndex: 1 }}>
                    <Link to="/ordem-de-servico">
                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                    </Link>
                    {
                      user.isAccountant == "n"
                      ?
                      <Button
                        type="submit"
                        variant="primary"
                      >
                        <span>
                            Salvar
                        </span>
                      </Button>
                      : <></>
                    }
                </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="mb-3 mt-18">
            <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

            <Grid item lg={3} md={6} xs={12}>
              <TextField
                select
                size="small"
                label="Tipo da Os"
                margin="normal"
                value={type}
                onChange={(event) => setType(event.target.value)}
                disabled={user.isAccountant == "y" ? true : false}
              >
                <MenuItem value="serviceOrder">
                  Ordem de serviço
                </MenuItem>

                <MenuItem value="budget">
                  Orçamento
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <CustomerSelect
                label="Cliente"
                hasError={customerError}
                isEdit
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerName={customerName}
                setCustomerName={setCustomerName}
                onCustomerChanged={handleCustomerChanged}
                entityId={id}
                entityType='service'
                disabled={user.isAccountant == "y" ? true : false}
                allowIncomplete
                onClickAddCustomer={handleClickAddCustomer}
              />
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <ApiResourceSelect
                style={{ width: "100%" }}
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                hasError={sellerError}
                textFieldClass={classes.error}
                onChangeTextField={(e) => setSellerError(e.target.value ? false : true)}
                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                }}
                disabled={user.isAccountant == "y" ? true : false}
              />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
              <TextField
                disabled
                size="small"
                type='text'
                margin="normal"
                label="N° da OS"
                value={numberSO}
              />
            </Grid>
          </Grid>

          {nextButton === 'first' ? (
              <Link to="/ordem-de-servico" className="btn btn-secondary mb-2 mr-2 btn-lg">
                  <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                  <span>Voltar</span>
              </Link>
          ) : (
              <Button
              type="button"
              variant="outline-primary"
              onClick={() => backNextOption('back')}
              className="mb-2 mr-2 btn-lg"
              >
                  <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                  <span>Voltar</span>
              </Button>
          )}
          <Button
              type="button"
              variant={nextButton === 'last' ? 'secondary' : 'primary'}
              onClick={() => backNextOption('next')}
              className="mb-2 btn-lg"
              disabled={nextButton === 'last'}
          >
              <span>
                  Avançar
              </span>&nbsp;
              <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
          </Button>
          <hr />

          <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' style={{ display: "none" }}>
            <Tab eventKey='services' title='Serviços'>
                {
                  servicesInvalid && (
                    <p className='text-danger'>Selecione pelo menos 1(um) serviço!</p>
                  )
                }
                {services.map((service, index) => (
                  <ListProducts
                    key={index}
                    index={index}
                    product={service}
                    service
                    isContent={(contentExists: boolean) => isValid2(contentExists)}
                    isEdit
                    entityId={id}
                    onClickAddProduct={handleClickAddProduct}
                    changeTabIcon={changeTabIcon}
                  />
                ))
                }

                {
                  user.isAccountant == "n"
                    ?
                    <UnderlineLink text="adicionar novo serviço" onClick={() => dispatchServices({ type: "ADD" })} />
                    : <></>
                }
            </Tab>

            <Tab eventKey='products' title='Produtos'>
                {
                  productsInvalid && (
                    <p className='text-danger'>Informe um produto!</p>
                  )
                }
                {products.map((product, index) => (
                  <ListProducts
                    key={index}
                    index={index}
                    product={product}
                    isContent={(contentExists: boolean) => isValid(contentExists)}
                    isEdit
                    entityId={id}
                    onClickAddProduct={handleClickAddProduct}
                    changeTabIcon={changeTabIcon}
                  />
                ))
                }

                <UnderlineLink text="adicionar novo produto" onClick={() => dispatchProducts({ type: "ADD" })} />
            </Tab>

            <Tab eventKey='detailsServiceOrder' title='Detalhes da OS'>
              <div className="card-body px-3">
                <Grid container spacing={6}>
                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      disabled
                      type='date'
                      size="small"
                      margin="normal"
                      label="Data da OS"
                      InputLabelProps={{ shrink: true }}
                      value={date}
                    />
                  </Grid>

                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='datetime-local'
                      margin="normal"
                      label="Previsão data de início"
                      InputLabelProps={{ shrink: true }}
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='datetime-local'
                      margin="normal"
                      label="Data da entrega"
                      InputLabelProps={{ shrink: true }}
                      value={deliveryDate}
                      onChange={(event) => setDeliveryDate(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='text'
                      margin="normal"
                      label="Garantia"
                      value={warranty}
                      onChange={(event) => setWarranty(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='date'
                      margin="normal"
                      label="Data da realização"
                      InputLabelProps={{ shrink: true }}
                      value={accomplishmentDate}
                      onChange={(event) => setAccomplishmentDate(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                      error={accomplishmentDateError}
                    />
                  </Grid>

                  <Grid item lg={2} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='text'
                      margin="normal"
                      label="Hora da realização"
                      value={accomplishmentTime}
                      onChange={(event) => setAccomplishmentTime(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      type='text'
                      margin="normal"
                      label="Referência"
                      value={referencies}
                      onChange={(event) => setReferencies(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Observações"
                      value={comments}
                      onChange={(event) => setComments(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Observações internas"
                      value={internalComments}
                      onChange={(event) => setInternalComments(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Equipamento"
                      value={equipment}
                      onChange={(event) => setEquipment(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Problemas"
                      value={issues}
                      onChange={(event) => setIssues(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Observações do recebimento"
                      value={receivement}
                      onChange={(event) => setReceivement(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      size="small"
                      rows={6}
                      multiline
                      margin="normal"
                      label="Laudo técnico / Diagnóstico"
                      value={report}
                      onChange={(event) => setReport(event.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>
                </Grid>
              </div>
            </Tab>

            <Tab eventKey='totalServiceOrder' title='Totais da Ordem de Serviço'>
              <div className="card-body px-3">
                <Grid container spacing={5}>
                  <Grid item lg={3} md={6} xs={12}>
                    <NumericFormat
                      disabled
                      label="Valor dos serviços"
                      value={totalValueServices}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <NumericFormat
                      disabled
                      label="Valor dos produtos"
                      value={totalValueProducts}
                    />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <NumericFormat
                      label="Valor das despesas"
                      value={expenditure}
                      onChange={(evt) => setExpenditure(evt.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <NumericFormat
                      label="Valor do desconto"
                      value={discount}
                      onChange={(evt) => setDiscount(evt.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <NumericFormat
                      disabled
                      label="Total"
                      value={totalValue}
                    />
                  </Grid>
                </Grid>
              </div>
            </Tab>

            <Tab eventKey='payment' title='Pagamento'>
              <div className="card-body p-0">
                {
                  paymentInvalid && (
                    <p className='text-danger'>Informe as parcelas do pedido!</p>
                  )
                }
                <div className="row">
                  <Grid item lg={6} md={6} xs={12}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          disabled={user.isAccountant == "y" ? true : false}
                          color="primary"
                          checked={sellerCommissioning}
                          onChange={(e, checked) => setSellerCommissioning(checked)}
                        />
                      }
                      label="Emitir comissionamento (vendedor)"
                      labelPlacement="end"
                    />
                  </Grid>
                </div>
                <div className="row">
                  <Grid item md={4} xs={12}>
                    <TextField
                      type="number"
                      margin="normal"
                      label="Quantidade de Parcelas"
                      size="small"
                      value={installmentAmount}
                      onChange={(evt) => {
                        setInstallmentAmount(Number(evt.target.value))
                        setInstallmentsError(evt.target.value ? false : true)
                      }}
                      error={installmentsError}
                      className={classes.error}
                      disabled={user.isAccountant == "y" ? true : false}
                    />
                  </Grid>
                  {
                    user.isAccountant == "n"
                      ?
                      <Grid item md={3} xs={12}>
                        <Button
                          variant="primary"
                          size="sm"
                          block
                          onClick={() => {
                            dispatchInstallments({
                              type: "GENERATE",
                              payload: {
                                amount: installmentAmount,
                                value: formatToFloat(totalValue),
                              }
                            });
                            changeTabIcon('flaticon-list-1');
                          }}
                        >
                          Gerar parcelas
                        </Button>
                      </Grid>
                      : <></>
                  }

                  <Grid item xs={12}>
                    {installments.length > 0 && installments.map((installment, index) => (
                      <Installment
                        key={index}
                        index={index}
                        installment={installment}
                        installmentAmount={installmentAmount}
                      />
                    ))
                    }
                  </Grid>
                </div>
              </div>
            </Tab>

            <Tab eventKey='attachments' title='Anexo'>
              <div className="card-body p-0">
                <div className="row">
                  <Grid item lg={6} md={6} xs={12}>
                    <label htmlFor="formFile" className="mt-3">
                      Contrato/Anexos
                    </label>

                    <input className="form-control" type="file" id="formFile" disabled={user.isAccountant == "y" ? true : false} />

                    <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                  </Grid>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        </div>
      </form>
    </MainPageContentWrapper>
  );
}
