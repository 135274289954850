import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import '../../../style.css';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Autocomplete } from '@material-ui/lab';
import { NumericFormat } from '../../../components/NumericFormat';
import { formatCurrency } from '../../../utils/formatCurrency';
import { formatCpfCnpj } from '../../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { BillsTable } from './BillsTable';
import { Customer } from '../../../types/Customer';
import axios from 'axios';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: 'customer', value: 'Cliente' },
    { reference: 'cnpjOrCpf', value: 'CNPJ/CPF' },
    { reference: 'tell', value: 'Telefone' },
    { reference: 'email', value: 'E-mail' },
    { reference: 'city', value: 'Cidade' },
    { reference: 'state', value: 'Estado' },
    { reference: 'total', value: 'Valor Total' },
];

const titleHeadData: HeadDataBaseProps[] = [
    { reference: "number", value: "Nº" },
    { reference: "emissionDate", value: "Data de Emissão" },
    { reference: "dueDate", value: "Data de Vencimento" },
    { reference: "daysLate", value: "Dias Vencidos" },
    { reference: "valueTotal", value: "Valor Total" },
];

type ReportCustomerResponse = {
    rows: Customer[];
    count: number;
    totalToReceive: number;
}

type states = {
    id: number,
    sigla: string,
    nome: string,
}

type Filters = {
    dueDateMin: string;
    dueDateMax: string;
    issueDateMin: string;
    issueDateMax: string;
    totalValueMin: string;
    totalValueMax: string;
    customer: string;
    city: string;
    state: string;
}

export function ListReportDefaulterCustomer() {
    const [defaulterCustomers, setDefaulterCustomers] = useState<Customer[]>([]);
    const [totalToReceive, setTotalToReceive] = useState(0);
    
    const [countDefaulterCustomersPaginated, setCountDefaulterCustomersPaginated] = useState(0);
    const [countDefaulterCustomers, setCountDefaulterCustomers] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [states, setStates] = useState<states[]>([]);
    const [dueDateMin, setDueDateMin] = useState("");
    const [dueDateMax, setDueDateMax] = useState("");
    const [issueDateMin, setIssueDateMin] = useState("");
    const [issueDateMax, setIssueDateMax] = useState("");
    const [totalValueMin, setTotalValueMin] = useState('');
    const [totalValueMax, setTotalValueMax] = useState('');
    const [customer, setCustomer] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [showTitleLate, setShowTitleLate] = useState(false);
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportCustomerResponse>("/report/defaulterCustomer", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, totalToReceive} = data;

        const exportDataList: ReportDataRow[] = [];
        let exportCustomerData: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            data.push({
                for: "bills",
                value: '',
                secondaryRow: true,
                jsx: <BillsTable bills={reportRow.toReceive} />
            });

            exportCustomerData = reportRow.toReceive.map((bill: any) => {
                const today      = new Date();
                const dueDate    = new Date(bill.dueDate);
                const timeDiff   = Math.abs(dueDate.getTime() - today.getTime());
                const diffInDay  = Math.ceil(timeDiff / (1000 * 3600 * 24)) // calculo para converter em dias

                return {
                    cells: [
                        {for: 'number', content: bill.id},
                        {for: 'emissionDate', content: bill.issuanceDate.split("-").reverse().join("/")},
                        {for: 'dueDate', content: bill.dueDate.split("-").reverse().join("/")},
                        {for: 'daysLate', content: diffInDay},
                        {for: 'valueTotal', content: formatCurrency(bill.amount)},
                    ],
                };
            });

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: titleHeadData,
                    bodyData: exportCustomerData,
                }
            });
        })

        setExportTotals([
            { name: 'Quantidade de Inadimplentes', value: count },
            { name: 'Valor Total', value: formatCurrency(totalToReceive) },
        ]);

        setExportBodyData(exportDataList);
        setCountDefaulterCustomers(count);
        setTotalToReceive(totalToReceive);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportCustomerResponse>("/report/defaulterCustomer", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setDefaulterCustomers(rows);
        setCountDefaulterCustomersPaginated(count);
        setLastSortDirection(sortDirection);
        setLastSortReference(sortReference);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = defaulterCustomers;

        aux.forEach((customer) => {
            const data = mountBodyDataRow(customer);

            if (showTitleLate) {
                data.push({
                    for: "bills",
                    value: '',
                    secondaryRow: true,
                    jsx: <BillsTable bills={customer.toReceive} />
                });
            }

            list.push(data);
        });

        setBodyData(list);
    }, [defaulterCustomers]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Customer) {
        const [city, state] = getCustomerAddress(reportRow);
        const totalLate = getCustomerTotalBillsToReceive(reportRow);

        const data: BodyDataBaseProps[] = [
            { for: "customer", value: reportRow.typePeople == "physical" ? reportRow.name : reportRow.corporateName },
            { for: "cnpjOrCpf", value: reportRow.typePeople == "physical" ? formatCpfCnpj("CPF", reportRow.cpf) : formatCpfCnpj("CNPJ", reportRow.cnpj) },
            { for: "tell", value: reportRow.phone ? formatPhoneNumber(reportRow.phone) : '-' },
            { for: "email", value: reportRow.email ?? '-' },
            { for: "city", value: city ?? '-'},
            { for: "state", value: state ?? '-'},
            { for: "total", value: formatCurrency(totalLate) },
        ];

        return data;
    }

    useEffect(() => {
        getStates();
    }, []);

    const clearSearch = () => {
        setDueDateMin("");
        setDueDateMax("");
        setIssueDateMin("");
        setIssueDateMax("");
        setTotalValueMin('');
        setTotalValueMax('');
        setCustomer("");
        setCity("");
        setState("");
        setShowTitleLate(false);
    }

    async function getStates() {
        const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        setStates(statesBrazil.data);
    }

    function getCustomerTotalBillsToReceive(customer: Customer) {
        let total = 0;
        customer.toReceive.forEach((bill) => total += bill.amount);

        return total;
    }

    function getCustomerAddress(customer: Customer) {
        let address = customer.addresses.filter((address) => address)[0];

        if (!address) return [];

        return [address.city, address.state];
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            dueDateMin,
            dueDateMax,
            issueDateMin,
            issueDateMax,
            totalValueMin,
            totalValueMax,
            customer,
            city,
            state,
        };
        reloadData();

    }, [
        dueDateMin,
        dueDateMax,
        issueDateMin,
        issueDateMax,
        totalValueMin,
        totalValueMax,
        customer,
        city,
        state,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Clientes Inadimplentes', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(String(option?.id) || '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data Vencimento"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMin}
                        onChange={(e) => setDueDateMin(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data Vencimento"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMax}
                        onChange={(e) => setDueDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={issueDateMin}
                        onChange={(e) => setIssueDateMin(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={issueDateMax}
                        onChange={(e) => setIssueDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Cidade"
                        margin="normal"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                
                <div className="col-lg-4">
                    <Autocomplete
                        style={{ width: "100%" }}
                        options={states}
                        getOptionLabel={({ sigla }) => sigla}
                        value={states.find(({ sigla }) => sigla === state) ?? null}
                        onInputChange={(event, value) => setState(value)}
                        renderInput={(params) => (
                            <TextField
                                { ...params }
                                margin="normal"
                                label="Estado"
                                size="small"
                            />
                        )}
                    />
                </div>

                <div className="col-lg-2">
                    <NumericFormat
                        label="Valor Total"
                        className="inputSmaller"
                        withPrefix={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={totalValueMin}
                        onChange={(evt) => setTotalValueMin(evt.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <NumericFormat
                        label="Valor Total"
                        className="inputSmaller"
                        withPrefix={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={totalValueMax}
                        onChange={(evt) => setTotalValueMax(evt.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showTitleLate}
                                onChange={(_, checked) => setShowTitleLate(checked)}
                            />
                        }
                        label="Exibir os títulos vencidos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>
                
                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Clientes Inadimplentes"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Clientes Inadimplentes', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countDefaulterCustomersPaginated}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de Inadimplentes</TableCell>
                                    <TableCell colSpan={1}>{countDefaulterCustomers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalToReceive)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    )
}