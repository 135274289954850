import React, { FormEvent, useEffect, useState } from "react";
import { Button, Nav, Spinner, Tab } from "react-bootstrap";
import ModalSuccess from "../../../components/ModalSuccess";
import api from "../../../services/Api";
import { SubscriptionPlanConfig } from "./SubscriptionPlanConfig";
import SuperConfigService from "../../../services/SuperConfigService";
import { CompanyAndUserConfig } from "./CompanyAndUserConfig";
import { yesOrNo } from "../../../types/yesOrNo";
import { subscriptionPlanPaymentEnvironment as subscriptionPlanPaymentEnvironmentType } from "../../../types/SuperConfig";
import { MainPageContentWrapper } from "../../../components/MainPageContentWrapper";

type SuperConfigSideTabs = (
    '' | 'subscriptionPlanConfig'
);

export function SuperConfig() {
    const [isSubmit, setIsSubmit] = useState(false);
    const [activeSideTab, setActiveSideTab] = useState<SuperConfigSideTabs>("subscriptionPlanConfig");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    // Planos e Cobrança
    const [subscriptionPlanPaymentEnvironment, setSubscriptionPlanPaymentEnvironment] = useState<subscriptionPlanPaymentEnvironmentType>(subscriptionPlanPaymentEnvironmentType.test);
    const [defaultMsgSubscriptionPlanExpiring, setDefaultMsgSubscriptionPlanExpiring] = useState('');
    const [defaultMsgSubscriptionPlanPaymentPending, setDefaultMsgSubscriptionPlanPaymentPending] = useState('');
    const [defaultMsgSubscriptionPlanPaymentRequired, setDefaultMsgSubscriptionPlanPaymentRequired] = useState('');
    const [daysWithoutPlanPaymentAllowed, setDaysWithoutPlanPaymentAllowed] = useState(0);
    const [subscriptionBilletExpireDays, setSubscriptionBilletExpireDays] = useState(0);

    // Empresas e Usuários
    const [requireUserCellValidation, setRequireUserCellValidation] = useState(yesOrNo.NO);

    useEffect(() => {
        async function loadSuperConfig() {
            const superConfig = await SuperConfigService.getConfig();

            setSubscriptionPlanPaymentEnvironment(superConfig.subscriptionPlanPaymentEnvironment);
            setDefaultMsgSubscriptionPlanExpiring(superConfig.defaultMsgSubscriptionPlanExpiring);
            setDefaultMsgSubscriptionPlanPaymentPending(superConfig.defaultMsgSubscriptionPlanPaymentPending);
            setDefaultMsgSubscriptionPlanPaymentRequired(superConfig.defaultMsgSubscriptionPlanPaymentRequired);
            setDaysWithoutPlanPaymentAllowed(superConfig.daysWithoutPlanPaymentAllowed);
            setSubscriptionBilletExpireDays(superConfig.subscriptionBilletExpireDays);

            setRequireUserCellValidation(superConfig.requireUserCellValidation)
        }

        loadSuperConfig();
    }, []);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        setIsSubmit(true);

        try {
            const raw = {
                subscriptionPlanPaymentEnvironment,
                defaultMsgSubscriptionPlanExpiring,
                defaultMsgSubscriptionPlanPaymentPending,
                defaultMsgSubscriptionPlanPaymentRequired,
                daysWithoutPlanPaymentAllowed,
                subscriptionBilletExpireDays,
                requireUserCellValidation,
            }

            await api.put('/superConfig', raw);

            setShowModalSuccess(true);
        } catch (error) {
            console.log('error', error);
        }

        setIsSubmit(false);
    };

    return (
        <MainPageContentWrapper>
            <div className="card card-custom gutter-b">
                <ModalSuccess
                    showModal={showModalSuccess}
                    setShowModal={setShowModalSuccess}
                    msgModal="Configurações salvas com sucesso!"
                />
                <form
                    className={'makeStyles-container-12 w-100'}
                    onSubmit={(evt) => onSubmit(evt)}
                >
                    <div className="card-header row m-0 pt-2 pb-0">
                        <div className="col-6 m-0 p-0">
                            <h4 className="card-label m-0 mt-2">
                                Configurações
                            </h4>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-end mb-3 pr-0'>
                            <Button
                                type='submit'
                                variant="primary"
                                disabled={isSubmit}
                            >
                                {
                                    isSubmit ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>
                                        </>
                                        :
                                        <span>Salvar</span>
                                }
                            </Button>
                        </div>
                    </div>

                    <div className="card-body mt-0 pt-0">
                        <div className="row">
                            <div className="col">
                                <Tab.Container activeKey={activeSideTab} onSelect={(tab: string) => setActiveSideTab(tab as SuperConfigSideTabs)}>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <Nav variant="tabs" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="subscriptionPlanConfig" href="#">
                                                        Planos e Cobrança
                                                    </Nav.Link>
                                                    <Nav.Link eventKey="companyAndUserConfig" href="#">
                                                        Empresas e Usuários
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="col-md-9">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="subscriptionPlanConfig">
                                                    <SubscriptionPlanConfig
                                                        subscriptionPlanPaymentEnvironment={subscriptionPlanPaymentEnvironment}
                                                        setSubscriptionPlanPaymentEnvironment={setSubscriptionPlanPaymentEnvironment}
                                                        defaultMsgSubscriptionPlanExpiring={defaultMsgSubscriptionPlanExpiring}
                                                        setDefaultMsgSubscriptionPlanExpiring={setDefaultMsgSubscriptionPlanExpiring}
                                                        defaultMsgSubscriptionPlanPaymentPending={defaultMsgSubscriptionPlanPaymentPending}
                                                        setDefaultMsgSubscriptionPlanPaymentPending={setDefaultMsgSubscriptionPlanPaymentPending}
                                                        defaultMsgSubscriptionPlanPaymentRequired={defaultMsgSubscriptionPlanPaymentRequired}
                                                        setDefaultMsgSubscriptionPlanPaymentRequired={setDefaultMsgSubscriptionPlanPaymentRequired}
                                                        daysWithoutPlanPaymentAllowed={daysWithoutPlanPaymentAllowed}
                                                        setDaysWithoutPlanPaymentAllowed={setDaysWithoutPlanPaymentAllowed}
                                                        subscriptionBilletExpireDays={subscriptionBilletExpireDays}
                                                        setSubscriptionBilletExpireDays={setSubscriptionBilletExpireDays}
                                                    />
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="companyAndUserConfig">
                                                    <CompanyAndUserConfig
                                                        requireUserCellValidation={requireUserCellValidation}
                                                        setRequireUserCellValidation={setRequireUserCellValidation}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainPageContentWrapper>
    );
}