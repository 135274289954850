import { Collapse, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

export type ModalSendEmailMultipleProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    emailSubject: string;
    setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    onClickSend: () => any;
    infoEmails: EmailToCustomer[];
    setInfoEmails: React.Dispatch<React.SetStateAction<EmailToCustomer[]>>;
}

export type EmailToCustomer = {
    refId: string,
    refName: string,
    toName: string,
    customerName: string,
    customerId: string,
    email: string,
};

const useStyles = makeStyles(theme => ({
    tableCellTextField: {
        padding: 4,
    },
}));

export function ModalSendEmailMultiple({
    showModal,
    setShowModal,
    emailSubject,
    setEmailSubject,
    message,
    setMessage,
    onClickSend,
    infoEmails,
    setInfoEmails
}: ModalSendEmailMultipleProps) {
    const classes = useStyles();

    const [collapseInfoEmails, setCollapseInfoEmails] = useState(false);
    const [isSendingEmails, setIsSendingEmails] = useState(false);

    const handleChangeInfoEmailName = (event: any, billId: string) => {
        const newName = event.target.value;
        setInfoEmails((prevState) => (
            prevState.map((info) => {
                if(info.refId !== billId) return info;

                return {
                    ...info,
                    toName: newName,
                };
            })
        ));
    };

    const handleChangeInfoEmail = (event: any, billId: string) => {
        const newEmail = event.target.value;
        setInfoEmails((prevState) => (
            prevState.map((info) => {
                if(info.refId !== billId) return info;

                return {
                    ...info,
                    email: newEmail,
                };
            })
        ));
    };

    const handleClickSendEmails = async () => {
        setIsSendingEmails(true);

        await onClickSend();

        setIsSendingEmails(false);
    };

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"
            size="lg"
            show={showModal}
            onHide={() => {
                setCollapseInfoEmails(false);
                setShowModal(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Enviar por E-Mail
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            label='Assunto'
                            margin='normal'
                            size="small"
                            value={emailSubject}
                            onChange={(e) => setEmailSubject(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <TextField
                            size="small"
                            multiline
                            rows="2"
                            margin="normal"
                            label="Mensagem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <Button
                            variant="primary"
                            onClick={() => setCollapseInfoEmails((prevState) => !prevState)}
                        >
                            Destinatários ({infoEmails.length})
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Collapse in={collapseInfoEmails}>
                            <TableContainer style={{ maxHeight: '75vh' }}>
                                <Table stickyHeader className="wrap">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Referência</TableCell>
                                            <TableCell>Nome Contato</TableCell>
                                            <TableCell>E-mails</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {infoEmails.map((infoEmail, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{infoEmail.refId}</TableCell>
                                                <TableCell className={classes.tableCellTextField}>
                                                <TextField
                                                    label="Para / Contato"
                                                    size="small"
                                                    margin="normal"
                                                    value={infoEmail.toName}
                                                    onChange={(e) => handleChangeInfoEmailName(e, infoEmail.refId)}
                                                />
                                                </TableCell>
                                                <TableCell className={classes.tableCellTextField}>
                                                    <TextField
                                                        label="E-mails"
                                                        size="small"
                                                        margin="normal"
                                                        value={infoEmail.email}
                                                        onChange={(e) => handleChangeInfoEmail(e, infoEmail.refId)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleClickSendEmails}>
                    {
                        isSendingEmails ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </>
                        :
                            <span>Enviar</span>
                    }
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowModal(false);
                }}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}