import { Bank } from "../types/Bank"

export const banks: Bank[] = [
    // { "number": "197", "name": "Stone Pagamentos S.A" },
    { "number": "001", "name": "Banco do Brasil" },
    { "number": "033", "name": "Santander" },
    { "number": "104", "name": "Caixa Econômica Federal" },
    { "number": "237", "name": "Bradesco" },
    { "number": "260", "name": "Nu Pagamentos S.A." },
    { "number": "341", "name": "Itaú" },
    // { "number": "756", "name": "Bancoob" },
    // { "number": "748", "name": "Sicredi" },
    { "number": "077", "name": "Banco Inter" },
    // { "number": "041", "name": "Banco Banrisul" },
    // { "number": "998", "name": "Outros" },
    // { "number": "999", "name": "Não é um banco" },
    // { "number": "085", "name": "Cecred" },
    // { "number": "004", "name": "Banco do Nordeste" },
    // { "number": "399", "name": "Hsbc" },
    // { "number": "422", "name": "Safra" },
    // { "number": "070", "name": "Banco de Brasília" },
    // { "number": "136", "name": "Unicred" },
    // { "number": "389", "name": "Mercantil do Brasil" },
    // { "number": "003", "name": "Banco da Amazônia" },
    // { "number": "047", "name": "Banese" },
    // { "number": "021", "name": "Banestes" },
    // { "number": "037", "name": "Banpará" },
    // { "number": "745", "name": "Citibank" },
    // { "number": "089", "name": "Credisan" },
    // { "number": "755", "name": "Bank of America" },
    // { "number": "353", "name": "Banespa" },
    // { "number": "347", "name": "Sudameris" },
    // { "number": "479", "name": "BankBoston" },
    { "number": "000", "name": "Outro" },
]

export const banksBillet: Bank[] = [
    { "number": "001", "name": "Banco do Brasil" },
    { "number": "033", "name": "Santander" },
    { "number": "104", "name": "Caixa Econômica Federal" },
    { "number": "237", "name": "Bradesco" },
    { "number": "341", "name": "Itaú" },
    { "number": "077", "name": "Banco Inter" },
]

export const numberBanksThatRequireWallet = [
    '001',
    '033',
    '104',
    '237',
    '341',
];

export const numberBanksWithAutomaticBillet = [
    '077',
]