import { BankAccount } from "./BankAccount";
import { CenterCost } from "./CenterCost";
import { Customer } from "./Customer";
import { SubCategory } from "./Dre";

export enum billetType {
    MANUAL = 'manual',
    INTER = 'inter',
}

export type BillsToReceive = {
    id: number;
    companyId: number;
    name: string;
    status: string;
    amount: number;
    dueDate: string;
    payment: string;
    reasonCanceled: string;
    payedDate: string,
    customer: string;
    customerName?: string;
    remaining: number;
    bankAccount: string;
    issuanceDate: string;
    accValue: number;
    taxValue: number;
    discountValue: number;
    feeValue: number;
    totalPaid: number;
    recordType: string;
    nameBank: string;
    categoryName: string;
    centerCost: number;
    comments?: string;
    check: boolean,
    billet: number,
    doc: string,
    docNumber: string,
    value: number,
    occurrence: string
    generateRemittance: string;
    remittanceDocument: string;
    remittanceComposeIds: string;
    billetDocument: string;
    billetNumber: number;
    billetType: billetType;
    dreSubCategoryId: number;
    partialPayments: string | null;

    writeOffValue: number;

    centerCostEntity: CenterCost;
    customerEntity: Customer;
    dreSubCategoryEntity: SubCategory;
    bankAccountEntity: BankAccount;
};