import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { formatToFloat } from "../../../utils/formatCurrency";
import { Button, Spinner } from "react-bootstrap";
import ModalError from "../../../components/ModalError";
import api from "../../../services/Api";
import ModalDelete from "../../../components/ModalDelete";
import { getDate } from "../../../utils/dateTimeHelper";
import { Modal } from "react-bootstrap";

export type ConfigFiscalIssuanceProps = {
    data: React.MutableRefObject<any>;
    defaultData: any;
    apiConfig: any;
    companyId: number;
    savedCertificate: string|null;
    setSavedCertificate: React.Dispatch<React.SetStateAction<string|null>>;
};

type UploadedCertificate = {
    fileUrl: string,
    fileName: string
};

type ListErrors = {
    campo: string,
    mensagem: string,
}

export function ConfigFiscalIssuance({
    data,
    apiConfig,
    companyId,
    savedCertificate,
    setSavedCertificate,
    defaultData,
}: ConfigFiscalIssuanceProps) {
    const [nfEnv, setNfEnv] = useState("homologation");

    const fileInput = useRef<HTMLInputElement>(null);
    const [uploadedCertificate, setUploadedCertificate] = useState<UploadedCertificate|undefined>();
    const [certificateCNPJ, setCertificateCNPJ] = useState("");
    const [certificateStartDate, setCertificateStartDate] = useState("");
    const [certificateExpireDate, setCertificateExpireDate] = useState("");
    const [certificateExpired, setCertificateExpired] = useState(false);
    const [certificatePassword, setCertificatePassword] = useState("");

    const [certificateError, setCertificateError] = useState("");
    const [certificatePasswordError, setCertificatePasswordError] = useState("");
    const [showModalCertificate, setShowModalCertificate] = useState(false);
    const [isSubmittingCertificate, setIsSubmittingCertificate ] = useState(false);

    const [showModalInfo, setShowModalInfo] = useState(false);
    const [msgInfo, setMsgInfo] = useState("");
    const [listErrors, setListErrors] = useState<ListErrors[]>([]);

    useEffect(() => {
        if(!apiConfig) {
            return;
        }

        if(apiConfig.certificado_cnpj) {
            const now = new Date();
            const expireDateObj = new Date(apiConfig.certificado_valido_ate);
            const expired = now.getTime() >= expireDateObj.getTime();
    
            setCertificateCNPJ(apiConfig.certificado_cnpj);
            setCertificateStartDate(apiConfig.certificado_valido_de);
            setCertificateExpireDate(getDate({initialDate: expireDateObj}).fullDateStr);
            setCertificateExpired(expired);
        } else {
            setCertificateCNPJ('');
            setCertificateStartDate('');
            setCertificateExpireDate('');
            setCertificateExpired(false);
        }
    }, [apiConfig]);

    useEffect(() => {
        if(!defaultData) return;
        setNfEnv(defaultData.nfEnv);
    }, [defaultData]);

    function handleChangeDataTextInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<string>>) {
        data.current[state] = event.target.value;
        setter(event.target.value);
    }
    function handleChangeDataNumericInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<any>>) {
        data.current[state] = event.currentTarget.value;
        setter(formatToFloat(event.currentTarget.value));
    }
    function handleChangeDataBooleanInput(value: boolean, state: string, setter: React.Dispatch<React.SetStateAction<boolean>>) {
        data.current[state] = value;
        setter(value);
    }

    function handleUploadClick() {
        if(fileInput && fileInput.current) {
            fileInput.current.click();
        }
    }

    function handleFileChange(event: any) {
        const input: HTMLInputElement = event.target;
        if(!input.files || input.files.length === 0) return;
        const fileUrl = window.URL.createObjectURL(input.files[0]);
        setUploadedCertificate({ fileUrl, fileName: input.files[0].name });
    }

    async function handleSubmitCertificate() {
        setIsSubmittingCertificate(true);

        let canSubmit = true;
        setCertificateError('');
        setCertificatePasswordError('');
        setListErrors([]);

        if(!uploadedCertificate?.fileUrl) {
            canSubmit = false;
            setCertificateError('Selecione o certificado');
        }
        if(!certificatePassword) {
            canSubmit = false;
            setCertificatePasswordError('A senha é obrigatória');
        }

        if(!canSubmit) {
            setIsSubmittingCertificate(false);
            return;
        }

        let uploadSuccess: any = true;
        try {
            uploadSuccess = await uploadCertificate(companyId);
        } catch (error: any) {
            uploadSuccess = false;
            setShowModalCertificate(false);
            setMsgInfo(error.response?.data?.message ?? 'Erro ao enviar o certificado');
            setListErrors(error.response.data.errors);
            setShowModalInfo(true);
            setIsSubmittingCertificate(false);
            return;
        }

        if(uploadSuccess && savedCertificate) {
            await removeOldCertificate(savedCertificate);
        }
        setShowModalCertificate(false);
        resetCertificateModalFields();
        setSavedCertificate(uploadSuccess.file);
    }

    function resetCertificateModalFields() {
        setCertificatePassword('');
        setCertificatePasswordError('');
        setUploadedCertificate(undefined);
        setCertificateError('');
    }

    async function uploadCertificate(id: number): Promise<boolean|Object> {
        if(fileInput && fileInput.current) {
            if(!fileInput.current.files || fileInput.current.files.length === 0) {
                return false;
            }
            const certificateFile = fileInput.current.files[0];
            if(!certificateFile) {
                return false;
            }

            var formFile = new FormData();
            formFile.append('file', certificateFile, certificateFile.name);
            
            const response = await api.post('/file', formFile);
            const raw = {
                digitalCertificate: response.data,
                digitalCertificatePassword: certificatePassword,
            }

            const responseUpdate = await api.post(`companies/certificate/${id}`, raw);
            return {
                file: response.data,
                data: responseUpdate.data,
            };
        }

        return false;
    }

    async function removeOldCertificate(filename: string) {
        await api.delete('/file', {data: { filename }})
    }

    return (
        <>
            {/* Modal Enviar Certificado */}
            <Modal
                show={showModalCertificate}
                onHide={() => setShowModalCertificate(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Enviar Certificado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <Button
                                className="mt-3 mb-2"
                                variant="primary"
                                onClick={handleUploadClick}
                            >
                                Selecionar arquivo do certificado
                            </Button>
                            <input
                                type="file"
                                className="d-none"
                                ref={fileInput}
                                onChange={(e) => handleFileChange(e)}
                                accept=".PFX,.P12"
                            />
                            {!!uploadedCertificate && !!uploadedCertificate.fileName && (
                                <span className="mt-3 d-block">Arquivo: <strong style={{wordBreak: 'break-word'}}>{uploadedCertificate.fileName}</strong></span>
                            )}
                            <span className="text-danger d-block">{certificateError}</span>
                        </div>
                        <div className="col-lg-6">
                            <TextField
                                autoComplete="new-password"
                                label="Senha"
                                margin="normal"
                                type="password"
                                value={certificatePassword}
                                onChange={e => setCertificatePassword(e.target.value)}
                            />
                            <span className="text-danger d-block">{certificatePasswordError}</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalCertificate(!showModalCertificate)}>Fechar</Button>
                    <Button
                        type='button'
                        onClick={handleSubmitCertificate}
                        className='mt-4'
                        variant="success"
                        disabled={isSubmittingCertificate}
                    >
                        {isSubmittingCertificate ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Enviando...
                            </span>

                        </> : <>

                            <span>
                                Enviar
                            </span>

                        </>}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Erro/Info */}
            <Modal
                show={showModalInfo}
                onHide={() => setShowModalInfo(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgInfo}</b>
                    {!!listErrors && listErrors.length > 0 && (
                        <Table>
                            <TableHead >
                                <TableRow>
                                    <TableCell padding="default">
                                        <strong>Erro</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listErrors.map((error, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <span className="text-danger">{error.mensagem}</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalInfo(!showModalInfo)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <div className="card">
                <div className="card-body">
                    {!!certificateCNPJ && (
                        <div className="row">
                            <div className="col">
                                {certificateExpired ? (
                                    <span className="text-danger">Certificado vencido em {certificateExpireDate}</span>
                                ) : (
                                    <span>Certificado válido até {certificateExpireDate}</span>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            <Button
                                className="mt-3 mb-2"
                                variant="primary"
                                onClick={() => setShowModalCertificate(true)}
                            >
                                {certificateCNPJ ? 'Alterar certificado' : 'Enviar certificado'}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                label="Ambiente"
                                margin="normal"
                                value={nfEnv ?? 'homologation'}
                                onChange={e => handleChangeDataTextInput(e, 'nfEnv', setNfEnv)}
                            >
                                <MenuItem key="0" value="homologation">
                                    Homologação
                                </MenuItem>

                                <MenuItem key="1" value="production">
                                    Produção
                                </MenuItem>

                            </TextField>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}