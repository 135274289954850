import React, { SetStateAction } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Tooltip } from "@material-ui/core";

type BankButtonProps = {
  imageUrl: string;
  tooltipTitle: string;
  onClick: () => void;
}

export function BankButton({ imageUrl, tooltipTitle, onClick }: BankButtonProps) {
  return (
    <Tooltip title={<p className="mb-0 font-size-lg">{tooltipTitle}</p>}>
      <button
        onClick={onClick}
        className="rounded-circle bank-button"
        type="button"
        style={{ backgroundImage: `url(${toAbsoluteUrl(imageUrl)})` }}>
      </button>
    </Tooltip>
  )
}