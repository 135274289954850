import React, { ButtonHTMLAttributes } from 'react'

interface UnderlineLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  	text: string;
}

export function UnderlineLink({ text, ...rest }: UnderlineLinkProps) {
  	return (
		<div className="col-sm-12">
			<button {...rest} type="button" className="btn btn-link p-0"><ins>{text}</ins></button>
		</div>
  	)
}