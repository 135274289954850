import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { PatternFormat } from '../PatternFormat';
import { IconButton, TextField } from '@material-ui/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type PaymentFormProps = {
  cardNumber: string;
  setCardNumber: React.Dispatch<React.SetStateAction<string>>;
  expiry: string;
  setExpiry: React.Dispatch<React.SetStateAction<string>>;
  cvc: string;
  setCvc: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  cardNumberError: boolean;
  setCardNumberError: React.Dispatch<React.SetStateAction<boolean>>;
  expiryError: boolean;
  setExpiryError: React.Dispatch<React.SetStateAction<boolean>>;
  cvcError: boolean;
  setCvcError: React.Dispatch<React.SetStateAction<boolean>>;
  nameError: boolean;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PaymentForm({
  cardNumber,
  setCardNumber,
  expiry,
  setExpiry,
  cvc,
  setCvc,
  name,
  setName,
  cardNumberError,
  setCardNumberError,
  expiryError,
  setExpiryError,
  cvcError,
  setCvcError,
  nameError,
  setNameError,
}: PaymentFormProps) {
  return (
    <div className="d-flex justify-content-between container newProductWrapper">
      <div className="row">
        <div className="col-10 col-lg-8">
          <TextField
            size="small"
            label="Nome do titular"
            margin="normal"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(!e.target.value);
            }}
            id="holder_name"
            error={nameError}
          />
        </div>
        <div className="col-10 col-lg-8">
          <PatternFormat
            label="Número do cartão"
            id="card"
            format="#### #### #### #######"
            mask=""
            value={cardNumber}
            onChange={(e) => {
              setCardNumber(e.target.value);
            }}
            error={cardNumberError}
          />
        </div>
        <div className="col-10 col-lg-8">
          <PatternFormat
            label="Data de validade (mês/ano)"
            id="expiry_date"
            format="##/##"
            mask="_"
            value={expiry}
            onChange={(e) => {
              setExpiry(e.target.value);
              setExpiryError(!e.target.value);
            }}
            error={expiryError}
          />
        </div>
        <div className="col-10 col-lg-8">
          <PatternFormat
            label="Código de Segurança (CVC)"
            id="cvc"
            type="password"
            format="####"
            mask=""
            value={cvc}
            onChange={(e) => {
              setCvc(e.target.value);
              setCvcError(!e.target.value);
            }}
            error={cvcError}
            endAdornment={(
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='tooltip-top'>
                    O código de segurança (ou CVC) possui de 3 a 4 digitos e normalmente está presente na parte de trás do cartão.
                  </Tooltip>
                }
              >
                <IconButton
                  edge="end"
                >
                  <i className="flaticon-info"></i>
                </IconButton>
              </OverlayTrigger>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Cards
            placeholders={{
              name: 'NOME COMPLETO'
            }}
            locale={{
              valid: 'Válido até'
            }}
            number={cardNumber}
            name={name}
            expiry={expiry}
            cvc={cvc}
            callback={(type, isValid) => {
              setCardNumberError(!isValid);
            }}
          />
        </div>
      </div>
    </div>
  );
}
