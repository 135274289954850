export function formatDate(value: any, withoutTimeZone: boolean = false) {
    const formatValue = new Date(value);

    const date = formatValue.toLocaleDateString('pt-BR', withoutTimeZone ? undefined : { timeZone: 'UTC' });

    return date;

}

export function formatStringDateToLocale(value: string) {
    if(!value) return '';

    const [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
}