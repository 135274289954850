import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { InputAdornment, makeStyles, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { Autocomplete } from '@material-ui/lab';
import { formatDate } from '../../../utils/dateFormat';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { Nfse } from '../../../types/Nfse';
import useBackendLoad from '../../../hooks/backendReload';
import { MainPageContentWrapper } from '../../../components/MainPageContentWrapper';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "NFS-e" },
    { reference: "numberRps", value: "RPS" },
    { reference: "customer", value: "Cliente" },
    { reference: "valueLiquidNote", value: "Valor Líquido" },
    { reference: "createdDate", value: "Data Emissão" },
    { reference: "situation", value: "Situação" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "total", value: "Valor Total" },
]

type Filters = {
    rps: string,
    customer: string,
    situation: string,
    date: string,
    dateLimit: string,
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));

export function ListReportNfse() {
    const [foundNfsePaginate, setFoundNfsePaginate] = useState<any[]>([]);
    const [foundNfse, setFoundNfse] = useState<any[]>([]);
    const [countTotalNfse, setCountTotalNfse] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [customer, setCustomer] = useState<any>('');
    const [customerError, setCustomerError] = useState(false);

    const [rps, setRps] = useState<any>('');
    const [situation, setSituation] = useState('all');
    const [dateLimit, setDateLimit] = useState('');
    const [date, setDate] = useState('');
    const [uTotal, setUTotal] = useState(0);

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);
    const classes = useStyles();
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundNfsePaginate;
        let exportProductsData: ReportDataRow[] = [];
        let total: number = 0;

        aux.forEach((nfse) => {
            const date = nfse.createdDate !== null ? formatDate(nfse.createdDate) : ' - ';
            const customer = nfse.customerEntity.length 
                            ? (nfse.customerEntity[0].typePeople == "physical" ? nfse.customerEntity[0].name : nfse.customerEntity[0].corporateName)
                            : ' - ';
            const liquidValue = nfse.valueLiquidNote || nfse.valueLiquidNote !== null ? 'R$ ' + nfse.valueLiquidNote : ' - ';
            const nfseNum = (nfse.numberRps !== null || nfse.numberRps !== '' || nfse.numberRps !== 0) ? nfse.numberRps : ' - ';

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(nfse.id), id: true },
                { for: "numberRps", value: String(nfseNum) },
                { for: "customer", value: customer },
                { for: "valueLiquidNote", value: liquidValue},
                { for: "createdDate", value: date },
                { for: "situation", value: getSituationText(nfse.situation) },
            ];

            list.push(data);
        });

        foundNfse.forEach((nfse) => {

            const date = nfse.createdDate !== null ? formatDate(nfse.createdDate) : ' - ';
            const customer = nfse.customerEntity.length 
                            ? (nfse.customerEntity[0].typePeople == "physical" ? nfse.customerEntity[0].name : nfse.customerEntity[0].corporateName)
                            : ' - ';
            const liquidValue = nfse.valueLiquidNote || nfse.valueLiquidNote !== null ? 'R$ ' + nfse.valueLiquidNote : ' - ';
            const nfseNum = (nfse.numberRps !== null || nfse.numberRps !== '' || nfse.numberRps !== 0) ? nfse.numberRps : ' - ';

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(nfse.id), id: true },
                { for: "numberRps", value: String(nfseNum) },
                { for: "customer", value: customer },
                { for: "valueLiquidNote", value: liquidValue},
                { for: "createdDate", value: date },
                { for: "situation", value: getSituationText(nfse.situation) },
            ];

            total += Number(nfse.valueLiquidNote);
            
            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setUTotal(total);

        setExportTotals([
            { name: 'Quantidade de Notas', value: foundNfse.length },
            { name: 'Valor total', value: 'R$ ' + formatNumberToString(total) },
        ]);
        
        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundNfsePaginate, foundNfse]);

    const clearSearch = () => {
        setRps('')
        setSituation('all');
        setCustomer('');
        setDate('');
        setDateLimit('');
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Nfse[], count:number}>("/report/nfse", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows} = data;

        const nfse = await api.get("/report/nfse", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundNfsePaginate(data.rows ? rows : []);

        setFoundNfse(nfse.data);
        setCountTotalNfse(nfse.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            rps,
            customer,
            situation: situation == "all" ? "" : situation,
            date,
            dateLimit
        };
        reloadData();

    }, [
        rps,
        customer,
        situation,
        date,
        dateLimit
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Nfse', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <MainPageContentWrapper>
            <div className="row d-flex align-items-center">

                <div className="col-lg-6">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                        onChangeTextField={(e) => setCustomerError(e.target.value ? false : true)}
                        textFieldClass={classes.error}
                        hasError={customerError}
                    />
                </div>

                <div className="col-lg-6 d-flex justify-content-between">
                    <TextField
                        type="date"
                        label="Data da Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />

                    <TextField
                        type="date"
                        label="Data da Emissão"
                        margin="normal"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={dateLimit}
                        onChange={(e) => setDateLimit(e.target.value)}
                        className="ms-20"
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        value={situation}
                    >
                        <MenuItem key="0" value="all" onClick={() => setSituation('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setSituation('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="progress" onClick={() => setSituation('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="3" value="attended" onClick={() => setSituation('attended')}>
                            Atendido
                        </MenuItem>

                        <MenuItem key="4" value="canceled" onClick={() => setSituation('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-3">
                    <TextField
                        size="small"
                        label="RPS"
                        margin="normal"
                        value={rps}
                        onChange={(e) => setRps(e.target.value)}
                    />
                </div>

                <div className="col-12 d-flex mt-6">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Notas Fiscais de Serviços"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Notas Fiscais de Serviço', extension: 'pdf' })}
                    >
                            <Button
                                variant="secondary"
                                className="ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer"></i>
                                Exportar PDF
                            </Button>
                    </PDFDownloadLink>
                        <Button
                            variant="secondary"
                            className="ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet"></i>
                            Exportar Excel
                        </Button>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalNfse}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de Notas</TableCell>
                                    <TableCell colSpan={1}>{foundNfse.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotal) }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </MainPageContentWrapper>
    );
}