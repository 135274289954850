import React, { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { formatCurrency, formatToFloat } from "../../../utils/formatCurrency";
import { NumericFormat } from "../../../components/NumericFormat";
import { Accordion, AccordionCollapse, AccordionToggle, Button, Spinner } from "react-bootstrap";
import { CompanyNfseActivity } from "../../../types/CompanyNfseActivity";
import ModalError from "../../../components/ModalError";
import api from "../../../services/Api";
import ModalDelete from "../../../components/ModalDelete";

export type ConfigNfseProps = {
    data: React.MutableRefObject<any>;
    apiConfig: any;
    companyId: number;
};

export function ConfigNfse({
    data,
    apiConfig,
    companyId
}: ConfigNfseProps) {
    const [config, setConfig] = useState<any>();

    const [enableNfse, setEnableNfse] = useState(false);
    const [seriesHomologation, setSeriesHomologation] = useState<number|null>(0);
    const [nextNumberHomologation, setNextNumberHomologation] = useState<number|null>(0);
    const [seriesProduction, setSeriesProduction] = useState<number|null>(0);
    const [nextNumberProduction, setNextNumberProduction] = useState<number|null>(0);
    const [nfseUsername, setNfseUsername] = useState("");
    const [nfsePassword, setNfsePassword] = useState("");

    const [nature, setNature] = useState(1);
    const [taxRegime, setTaxRegime] = useState(1);
    const [specialTaxRegime, setSpecialTaxRegime] = useState<number|null>(null);
    const [aliquot, setAliquot] = useState("");
    const [holdIss, setHoldIss] = useState(false);
    const [cofins, setCofins] = useState("");
    const [pis, setPis] = useState("");
    const [csll, setCsll] = useState("");
    const [ir, setIr] = useState("");
    const [inss, setInss] = useState("");
    const [holdCofins, setHoldCofins] = useState("y");
    const [holdPis, setHoldPis] = useState("y");
    const [holdCsll, setHoldCsll] = useState("y");
    const [holdIr, setHoldIr] = useState("y");
    const [holdInss, setHoldInss] = useState("y");

    const [companyActivities, setCompanyActivities] = useState<CompanyNfseActivity[]>([]);
    const [serviceCode, setServiceCode] = useState("");
    const [tributeCode, setTributeCode] = useState("");
    const [federalTax, setFederalTax] = useState(0);
    const [municipalTax, setMunicipalTax] = useState(0);
    const [description, setDescription] = useState("");
    const [secondaryCnae, setSecondaryCnae] = useState("");
    const [activityIdToDelete, setActivityIdToDelete] = useState<number|undefined>();
    const [activityIdToEdit, setActivityIdToEdit] = useState<number|undefined>();
    const [activityIsSubmit, setActivityIsSubmit] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    useEffect(() => {
        getCompanyActivities();
        getConfig();
    }, []);

    useEffect(() => {
        if(!apiConfig) {
            return;
        }
        setEnableNfse(apiConfig.habilita_nfse ?? false);
        setSeriesHomologation(apiConfig.serie_nfse_homologacao ?? 1);
        setNextNumberHomologation(apiConfig.proximo_numero_nfse_homologacao ?? 1);
        setSeriesProduction(apiConfig.serie_nfse_producao ?? 1);
        setNextNumberProduction(apiConfig.proximo_numero_nfse_producao ?? 1);
        setNfseUsername(apiConfig.login_responsavel ?? '');
    }, [apiConfig]);

    useEffect(() => {
        if(!config) return;

        setNature(config.nature);
        setTaxRegime(config.taxRegime);
        setSpecialTaxRegime(config.specialTaxRegime);
        setAliquot(config.aliquot ? formatCurrency(config.aliquot) : '');
        setHoldIss(config.holdIss === 'y');
        setCofins(config.cofins ? formatCurrency(config.cofins) : '');
        setPis(config.pis ? formatCurrency(config.pis) : '');
        setCsll(config.csll ? formatCurrency(config.csll) : '');
        setIr(config.ir ? formatCurrency(config.ir) : '');
        setInss(config.inss ? formatCurrency(config.inss) : '');
        setHoldCofins(config.holdCofins);
        setHoldPis(config.holdPis);
        setHoldCsll(config.holdCsll);
        setHoldIr(config.holdIr);
        setHoldInss(config.holdInss);
    }, [config]);

    async function getConfig() {
        const response = await api.get('nfseConfig');
        const savedConfig = response.data[0];

        setConfig(savedConfig);
        if(savedConfig) {
            data.current.id = savedConfig.id;
        }
    }

    async function getCompanyActivities() {
        const response = await api.get<CompanyNfseActivity[]>('companyNfseActivities');
        setCompanyActivities(response.data);
    }

    async function handleActivitySubmit(id?: number) {
        if(!verifyActivity()) return;

        const activity: CompanyNfseActivity = {
            companyId,
            activityId: null,
            description,
            serviceCode,
            tributeCode,
            federalTax,
            municipalTax,
            secondaryCnae,
        }

        const aux = companyActivities;
        if(id) {
            await api.put(`companyNfseActivities/${id}`, activity);
            const index = aux.findIndex(obj => obj.id === id);
            aux[index] = activity;
            clearActivityFields();
        } else {
            const result = await api.post('companyNfseActivities', activity);
            activity.id = result.data.id;
            aux.push(activity);
        }

        setCompanyActivities([...aux]);
    }

    function verifyActivity() {
        if(!serviceCode) {
            setErrorMsg('Preencha o campo Cod. de Serviço corretamente!');
            setShowModalError(true);
            setActivityIsSubmit(false);
            return false;
        }
        if(!tributeCode) {
            setErrorMsg('Preencha o campo Cod. Tributário corretamente!');
            setShowModalError(true);
            setActivityIsSubmit(false);
            return false;
        }
        if(!description) {
            setErrorMsg('Preencha o campo Descrição corretamente!');
            setShowModalError(true);
            setActivityIsSubmit(false);
            return false;
        }

        return true;
    }

    async function handleClickActivityEdit(id: number) {
        const response = await api.get<CompanyNfseActivity>(`companyNfseActivities/${id}`);
        setActivityIdToEdit(id);
        setServiceCode(response.data.serviceCode);
        setTributeCode(response.data.tributeCode);
        setFederalTax(response.data.federalTax);
        setMunicipalTax(response.data.municipalTax);
        setDescription(response.data.description);
        setSecondaryCnae(response.data.secondaryCnae);
    }

    function handleClickActivityEditCancel() {
        clearActivityFields();
    }

    function handleClickDeleteCompanyActivity(id: number) {
        setShowModalDelete(true);
        setActivityIdToDelete(id);
    }

    const handleCompanyActivityDelete = useCallback(async () => {
        if(activityIdToDelete) {
            await api.delete(`companyNfseActivities/${activityIdToDelete}`);
            const aux = companyActivities.filter(activity => activity.id !== activityIdToDelete);
            setCompanyActivities([...aux]);
            setShowModalDelete(false);
        }
    }, [activityIdToDelete]);

    function handleChangeEnableNfse(event: any) {
        handleChangeDataBooleanInput(!enableNfse, 'enableNfse', setEnableNfse);
    }

    function handleChangeDataTextInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<string>>) {
        data.current[state] = event.target.value;
        setter(event.target.value);
    }
    function handleChangeDataNumericInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<any>>) {
        data.current[state] = event.target.value;
        setter(formatToFloat(event.target.value));
    }
    function handleChangeDataBooleanInput(value: boolean, state: string, setter: React.Dispatch<React.SetStateAction<boolean>>) {
        data.current[state] = value;
        setter(value);
    }

    function clearActivityFields() {
        setActivityIdToEdit(undefined);
        setServiceCode('');
        setTributeCode('');
        setFederalTax(0);
        setMunicipalTax(0);
        setDescription('');
        setSecondaryCnae('');
    }

    return (
        <>
            <ModalError
                msgError={errorMsg}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalDelete
                showModalDelete={showModalDelete}
                setShowModalDelete={setShowModalDelete}
                onDelete={handleCompanyActivityDelete}
            />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={enableNfse}
                                        onChange={handleChangeEnableNfse}
                                    />}
                                label="Habilitar NFS-e"
                                labelPlacement="end"
                            />
                        </div>
                    </div>
                    {enableNfse && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <h3>Homologação</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <NumericFormat
                                        label="Série"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={seriesHomologation}
                                        onChange={e => handleChangeDataNumericInput(e, 'seriesHomologation', setSeriesHomologation)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <NumericFormat
                                        label="Próximo Número"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={nextNumberHomologation}
                                        onChange={e => handleChangeDataNumericInput(e, 'nextNumberHomologation', setNextNumberHomologation)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3>Produção</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <NumericFormat
                                        label="Série"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={seriesProduction}
                                        onChange={e => handleChangeDataNumericInput(e, 'seriesProduction', setSeriesProduction)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <NumericFormat
                                        label="Próximo Número"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={nextNumberProduction}
                                        onChange={e => handleChangeDataNumericInput(e, 'nextNumberProduction', setNextNumberProduction)}
                                    />
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <TextField
                                        autoComplete="new-password"
                                        label="Usuário"
                                        size="small"
                                        margin="normal"
                                        value={nfseUsername}
                                        onChange={e => handleChangeDataTextInput(e, 'nfseUsername', setNfseUsername)}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <TextField
                                        autoComplete="new-password"
                                        label="Senha"
                                        type="password"
                                        size="small"
                                        margin="normal"
                                        value={nfsePassword}
                                        onChange={e => handleChangeDataTextInput(e, 'nfsePassword', setNfsePassword)}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {enableNfse && (
                <div className="card mt-5">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Natureza da operação"
                                    margin="normal"
                                    size="small"
                                    value={nature}
                                    onChange={(e) => {
                                        handleChangeDataNumericInput(e, 'nature', setNature)
                                    }}
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>

                                    <MenuItem key="1" value="1">
                                        Tributação no município
                                    </MenuItem>

                                    <MenuItem key="2" value="2">
                                        Tributação fora do município
                                    </MenuItem>

                                    <MenuItem key="3" value="3">
                                        isenção
                                    </MenuItem>

                                    <MenuItem key="4" value="4">
                                        Imune
                                    </MenuItem>

                                    <MenuItem key="5" value="5">
                                        Processo Judicial
                                    </MenuItem>
                                </TextField>
                            </div> 
                            <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Regime de tributação"
                                    margin="normal"
                                    size="small"
                                    value={taxRegime}
                                    onChange={(e) => handleChangeDataNumericInput(e, 'taxRegime', setTaxRegime)}
                                >
                                    <MenuItem key="0" value="1">
                                        Optante pelo simples nacional
                                    </MenuItem>

                                    <MenuItem key="1" value="2">
                                        Contribuinte sujeito a tributação normal
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Regime especial de tributação"
                                    margin="normal"
                                    size="small"
                                    defaultValue={'0'}
                                    value={specialTaxRegime ?? '0'}
                                    onChange={(e) => handleChangeDataNumericInput(e, 'specialTaxRegime', setSpecialTaxRegime)}
                                >
                                    <MenuItem key="0" value="0">
                                        Nenhum
                                    </MenuItem>
                                    <MenuItem key="1" value="1">
                                        Micro empresa municipal
                                    </MenuItem>
                                    <MenuItem key="2" value="2">
                                        Estimativa
                                    </MenuItem>
                                    <MenuItem key="3" value="3">
                                        Sociedade de profissionais
                                    </MenuItem>
                                    <MenuItem key="4" value="4">
                                        Cooperativa
                                    </MenuItem>
                                    <MenuItem key="5" value="5">
                                        Micro empresario individual (MEI)
                                    </MenuItem>
                                    <MenuItem key="6" value="6">
                                        Microempresa e empresario de pequeno porte
                                    </MenuItem>
                                </TextField>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <NumericFormat
                                    label="% Alíquota"
                                    startAdornment="%"
                                    value={aliquot}
                                    onChange={(e) => handleChangeDataTextInput(e, 'aliquot', setAliquot)}
                                />
                            </div>
                            <div className="col-lg-3 d-flex align-items-center">
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={holdIss}
                                            onChange={() => handleChangeDataBooleanInput(!holdIss, 'holdIss', setHoldIss)}
                                        />
                                    }
                                    label="Reter ISS"
                                    labelPlacement="end"
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    label="% COFINS" 
                                    startAdornment="%"
                                    value={cofins}
                                    onChange={(e) => handleChangeDataTextInput(e, 'cofins', setCofins)}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat 
                                    label="% PIS" 
                                    startAdornment="%"
                                    value={pis}
                                    onChange={(e) => handleChangeDataTextInput(e, 'pis', setPis)}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat 
                                    label="% CSLL" 
                                    startAdornment="%"
                                    value={csll}
                                    onChange={(e) => handleChangeDataTextInput(e, 'csll', setCsll)}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat 
                                    label="% IR" 
                                    startAdornment="%"
                                    value={ir}
                                    onChange={(e) => handleChangeDataTextInput(e, 'ir', setIr)}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat 
                                    label="% INSS" 
                                    startAdornment="%"
                                    value={inss}
                                    onChange={(e) => handleChangeDataTextInput(e, 'inss', setInss)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {enableNfse && (
                <>
                    <div className="card mt-5">
                        <div className="card-body">
                            {companyActivities.map(activity => (
                                <div key={String(activity.id)} className="card mb-2">
                                    <div className="card-body p-5 d-flex align-items-center justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span>Código: {activity.serviceCode}</span>
                                            <strong>{activity.description}</strong>
                                        </div>

                                        <div>
                                            <Button
                                                className="btn-light-primary p-2 mr-3"
                                                variant="primary"
                                                type="button"
                                                title="Editar"
                                                onClick={() => handleClickActivityEdit(Number(activity.id))}
                                            >
                                                <i className="flaticon-edit p-0"></i>
                                            </Button>
                                            <Button
                                                className="btn-light-danger p-2 mr-3"
                                                variant="danger"
                                                type="button"
                                                title="Excluir"
                                                onClick={() => handleClickDeleteCompanyActivity(Number(activity.id))}
                                            >
                                                <i className="flaticon-delete p-0"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="card-title">
                                <h3>{activityIdToEdit ? 'Editar' : 'Adicionar'} Atividades</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <TextField
                                        label="Cod. do Serviço *"
                                        size="small"
                                        margin="normal"
                                        value={serviceCode}
                                        onChange={(e) => setServiceCode(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <TextField
                                        label="Cod. Tributação *"
                                        size="small"
                                        margin="normal"
                                        value={tributeCode}
                                        onChange={(e) => setTributeCode(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Imposto federal"
                                        startAdornment="%"
                                        value={federalTax}
                                        onChange={e => setFederalTax(formatToFloat(e.target.value))}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Imposto municipal"
                                        startAdornment="%"
                                        value={municipalTax}
                                        onChange={e => setMunicipalTax(formatToFloat(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <TextField
                                        label="Descrição *"
                                        size="small"
                                        margin="normal"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <TextField
                                        label="CNAE Secundário"
                                        size="small"
                                        margin="normal"
                                        value={secondaryCnae}
                                        onChange={(e) => setSecondaryCnae(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Button
                                        type='button'
                                        className='mt-4'
                                        variant="primary"
                                        disabled={activityIsSubmit}
                                        onClick={() => handleActivitySubmit(activityIdToEdit)}
                                    >
                                        {activityIsSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                {activityIdToEdit ? 'Salvar' : 'Adicionar'}
                                            </span>
                                        </>}
                                    </Button>

                                    {activityIdToEdit && (
                                        <Button
                                            type='button'
                                            className='mt-4 ml-3'
                                            variant="primary"
                                            onClick={handleClickActivityEditCancel}
                                        >   
                                            Cancelar
                                        </Button>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}

        </>
    );
}