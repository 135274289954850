import React, { useState } from 'react';
import { Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { usePurchaseOrder, Product } from './context';
import { NumericFormat } from '../../components/NumericFormat';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import ProductSelect from '../../components/ProductSelect';
import { useSelector } from 'react-redux';
import { Product as SelectableProduct } from '../../services/ProductService';

const useStyles = makeStyles(theme => ({
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));
interface ListProductsProps {
    index: number;
    product: Product;
    status?: string;
    isEdit?: boolean;
    entityId?: number | string;
    onClickAddProduct?: (typedText: string, index: number, product: Product) => void;
    changeTabIcon: (previousIcon: string) => void;
}

export const ListProducts: React.FC<ListProductsProps> = ({
    index,
    product,
    status,
    isEdit,
    entityId,
    onClickAddProduct,
    changeTabIcon
}) => {
    const { user } = useSelector((state: any) => state.auth);
    
    const { dispatchProducts } = usePurchaseOrder();
    const [contentError, setContentError] = useState(false);


    const handleChangeProduct = (value: SelectableProduct) => {
        const productObj = typeof value === 'string' ? null : value;
        const productName = typeof value === 'string' ? value : '';
        
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    isLinked: !!productObj,
                    name: productObj?.name ?? productName,
                    amount: 1,
                    INSTANCE: productObj ?? {} as SelectableProduct,
                    total: productObj?.saleValue ?? 0,
                    unitaryValue: productObj?.saleValue ?? 0,
                    cfop: "0",
                    icms: "0",
                    ipi: "0",
                    ncm: "0",
                },
            }
        })

        changeTabIcon('flaticon-open-box');
    }

    const handleChangeValues = (amount: number, unitaryValue: any) => {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    ...product,
                    amount: amount,
                    unitaryValue: formatToFloat(unitaryValue),
                    total: amount * formatToFloat(unitaryValue),
                }
            }
        })

        changeTabIcon('flaticon-open-box');
    } 

    return (
        <div className="row col-lg-12 p-0 pl-7 pr-7 newProductWrapper">
            <Grid item lg={4} md={6} xs={12} className='d-flex align-items-center'>
                <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                    <label className="text-white m-0">{index + 1}.</label>
                </div>

                <ProductSelect
                    label="Produto"
                    isEdit={isEdit}
                    selectedProduct={product}
                    onChangeProduct={handleChangeProduct}
                    entityType="purchase"
                    entityId={entityId}
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    hasError={product.hasError}
                    allowIncomplete={true}
                    type="product"
                    onClickAddProduct={onClickAddProduct ? (typedText) => onClickAddProduct(typedText, index, product) : undefined}
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
                <TextField
                    size="small"
                    label="Qtde."
                    margin="normal"
                    name="amount"
                    type="number"
                    value={product.amount}
                    onChange={
                        (evt) => handleChangeValues(Number(evt.target.value), product.unitaryValue)
                    }
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
                <NumericFormat
                    margin="normal"
                    customInput={TextField}
                    withPrefix={false}
                    label="IPI %"
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    value={product.ipi}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ipi: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;
                        
                        console.log(converted);
                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: converted ? String(converted) : "0"
                                    }
                                }
                            }) 
                        }
                    }}
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
            <NumericFormat
                    margin="normal"
                    customInput={TextField}
                    withPrefix={false}
                    label="ICMS %"
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    value={product.icms}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    icms: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: converted ? String(converted) : "0"
                                    }
                                }
                            }) 
                        }
                    }}
                />
            </Grid>

            <Grid item lg={2} md={6} xs={12}>
                <NumericFormat
                    label="Valor Unit."
                    value={product.unitaryValue}
                    withPrefix={false}
                    onChange={
                        (evt) => handleChangeValues(Number(product.amount), evt.target.value)
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                R$
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
                <NumericFormat
                    margin="normal"
                    customInput={TextField}
                    withPrefix={false}
                    format="####"
                    label="CFOP"
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    value={product.cfop}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    cfop: evt.target.value
                                }
                            }
                        })
                    }
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
                <NumericFormat
                    margin="normal"
                    customInput={TextField}
                    withPrefix={false}
                    label="NCM"
                    format="########"
                    disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    value={product.ncm}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ncm: evt.target.value
                                }
                            }
                        })
                    }
                />
            </Grid>

            <Grid item lg={1} md={6} xs={12}>
                <NumericFormat
                    label="Valor Total"
                    disabled
                    value={product.total}
                    withPrefix={false}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                R$
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            {
                (index > 0 && user.isAccountant == "n") && (
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => dispatchProducts({
                            type: "DELETE",
                            payload: {
                                index
                            }
                        })}
                        disabled={status == "attended"|| user.isAccountant == "y" ? true : false}
                    >
                        <i className="flaticon-delete"></i>
                    </button>
                )
            }
        </div>
    );
}