import React, { useCallback, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {Workbook} from 'exceljs';
import FileSaver from 'file-saver';
import ModalError from '../../components/ModalError';
import ModalSuccess from '../../components/ModalSuccess';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import api from '../../services/Api';
import { formatToFloat } from '../../utils/formatCurrency';

type responseType = {
    status: string;
    message: string;
}

export function ImportExcelProduct() {
    const [isImporting, setIsImporting] = useState(false);

    const [msgModal, setMsgModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    
    const readExcel = useCallback((file) => {
        if (file.length) {
            const workbook = new Workbook();
            const reader = new FileReader();

            reader.readAsArrayBuffer(file[0]);
            reader.onload = () => {
                const buffer: any = reader.result;

                try {
                    workbook.xlsx.load(buffer).then(workbook => {
                        workbook.eachSheet(async(sheet, id) => {
                            console.log(sheet);
                            console.log(id);
                            console.log("============");
                            var response: responseType = {
                                status: "success",
                                message: ""
                            };

                            sheet.eachRow((row, rowIndex) => {
                                if (rowIndex > 1) {
                                    var responseValidate = validateSheet(row.values, sheet);
        
                                    if (responseValidate.status == "error") {
                                        response = responseValidate;
                                    }
                                }
                            });

                            if (response.status == "success") {
                                setIsImporting(true);

                                const productsToCreateList: any[] = [];
                                sheet.eachRow(async (row: any, rowIndex) => {
                                    if (rowIndex > 1) {
                                        productsToCreateList.push({
                                            type: row.values[1] == "Produto" ? 'product' : 'service',
                                            name: row.values[2],
                                            unit: row.values[3],
                                            codeGtinEan: row.values[4],
                                            saleValue: row.values[5] ? formatToFloat(row.values[5]) : 0,

                                            hasVariation: 'n',
                                            situation: 'active',
                                            isKit: 'n'
                                        });
                                    }
                                });

                                for (const productToCreate of productsToCreateList) {
                                    await api.post('/products', productToCreate);
                                }

                                setIsImporting(false);
                                setMsgModal("Excel importado com sucesso!");
                                setShowModal(true);

                            } else {
                                setMsgError(response.message);
                                setShowModalError(true);
                            }
                        })
                    });

                } catch (error) {
                    console.log(error);
                }                
            }

        }
    }, []);

    const getSheetModel = useCallback(async() => {
        const workbook = new Workbook;
        const sheet    = workbook.addWorksheet('Produtos');
        sheet.columns = [
            { header: 'Tipo', key: 'type', width: 25 },
            { header: 'Nome', key: 'name', width: 50 },
            { header: 'Unidade', key: 'unit', width: 25 },
            { header: 'Código GTIN/EAN', key: 'codeGtinEan', width: 30 },
            { header: 'Valor de Venda', key: 'saleValue', width: 25 },
        ];

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), 'Excel-Modelo.xlsx');
    }, []);

    const validateSheet = useCallback((row, rows) => {
        console.log(row);
        // VERIFICAÇÕES COLUNA DE TIPO
        if (!row[1]) {
            return {
                status: "error",
                message: "Coluna tipo deve ser preenchida!",
            };
        }

        if (!["Produto", "Serviço"].includes(capitalizeFirstLetter(row[1].toLowerCase()))) {
            return {
                status: "error",
                message: "Coluna tipo deve ser Produto ou Serviço!",
            };
        }
        //===================        

        // VERIFICAÇÕES COLUNA DE NOME
        if (!row[2]) {
            return {
                status: "error",
                message: "Coluna nome deve ser preenchida!",
            };
        }

        if (row[2].length > 255) {
            return {
                status: "error",
                message: "Coluna nome deve ter no máximo 255 caracteres!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE UNIDADE
        if (!row[3]) {
            return {
                status: "error",
                message: "Coluna unidade deve ser preenchida!",
            };
        }

        if (!["Pç", "Kg", "Un"].includes(capitalizeFirstLetter(row[3].toLowerCase()))) {
            return {
                status: "error",
                message: "Coluna unidade deve ser Pç, Kg ou Un!",
            };
        }
        //===================

        // VERIFICAÇÕES COLUNA DE VALOR DE VENDA
        if (!row[5]) {
            return {
                status: "error",
                message: "Coluna valor de venda deve ser preenchida!",
            };
        }
        //===================

        return {
            status: "success",
            message: "",
        };
    }, []);

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <div className="card card-body pt-4 newProductWrapper">
                <div className="row d-flex flex-column align-items-center mt-5 mb-5">
                    <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center p-5" style={{ border: "2px dashed #eee", background: "#fefefe" }}>
                        <label htmlFor="formFile">
                            <div className="w-100 d-flex justify-content-center">
                                {
                                    isImporting
                                    ?   <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    : <img src="/media/icons/folder.png" className='mr-5' />
                                }
                            </div>
                            <h6 className="text-center mt-3">Importe o arquivo de excel aqui</h6>
                            <input
                                type='file'
                                id='formFile'
                                accept='.xlsx'
                                onChange={(e) => readExcel(e.target.files)}
                                style={{ display: "none" }}
                            />
                        </label>
                        <Button
                            type="button"
                            variant="secondary"
                            className="mr-2"
                            onClick={() => getSheetModel()}
                        >
                            Baixar Modelo
                        </Button>
                    </div>

                    {/* <div className='d-flex justify-content-center col-lg-4 mt-5'>
                        <Button
                            type="button"
                            variant="primary"
                            className="mr-2"
                        >
                            Concluir Importação
                        </Button>
                    </div> */}
                </div>            
            </div>
        </>
    )
}