import React, { useState, useEffect, useCallback } from 'react';

import api from "../../services/Api";

import {
    Paper,
    Table,
    TableRow,
    MenuItem,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    TablePagination,
    FormControlLabel,
    FormGroup,
    FormControl,
    FormLabel,
    TableFooter,
    Collapse,
    Tooltip,
    Zoom,
    IconButton,
    TableContainer,
} from '@material-ui/core';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { extractDateStringFromTimestamp, getLastDayOfMonth, getNameMonth, subtractDate, subtractMonth } from '../../utils/dateTimeHelper';
import { getBalanceAccountBankCashFlow, getBeforeBalanceCashFlow } from '../../utils/cashFlow';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import CashFlowService from '../../services/CashFlowService';
import ModalError from '../../components/ModalError';
import { BankAccountsTotalsPieChart, totalAccounts } from '../../components/BankAccountsTotalsPieChart';
import useCurrentBalance from '../../hooks/useCurrentBalance';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { cashFlow, CashFlowComposedChart } from '../../components/CashFlowComposedChart';
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import { getFileNameWithDate } from '../../utils/getFIleNameWithDate';
import { formatCurrency } from '../../utils/formatCurrency';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfCashFlow } from './Pdf-CashFlow';
import DreCategoryService from '../../services/DreCategoryService';
import { SubCategory } from '../../types/Dre';
import { BillsCashFlow } from '../../types/BillsCashFlow';
import { formatDate } from '../../utils/dateFormat';
import { useHistory } from 'react-router';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

export function ListCashFlow() {
    const { push: pushHistory } = useHistory();

    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState('');
    const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);
    const [accountBanksLoaded, setAccountBanksLoaded] = useState(false);

    const [isGeneratingExcel, setIsGenerationExcel] = useState(false);

    const [allAccountBanks, setAllAccountBanks] = useState<BankAccount[]>([]);
    const [accountBankEntity, setAccountBankEntity] = useState<BankAccount>();
    const [isLoadingCharts, setIsLoadingCharts] = useState(false);
    const [showCharts, setShowCharts] = useState(true);
    const [cashFlow, setCashFlow] = useState<cashFlow[]>([]);

    const [accountBank, setAccountBank] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [foreseen, setForeseen] = useState(true);
    const [accomplished, setAccomplished] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [typeFilter, setTypeFilter] = useState("day");
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [allYears, setAllYears] = useState<String[]>([]);
    const [showBillDetails, setShowBillDetails] = useState(false);

    const [bills, setBills] = useState<BillsCashFlow[]>([]);
    const [totalPay, setTotalPay] = useState(0);
    const [totalReceive, setTotalReceive] = useState(0);
    const [totalBillsAccomplished, setTotalBillsAccomplished] = useState(0);
    const [totalBillsForeseen, setTotalBillsForeseen] = useState(0);
    const [allBillsCollapsed, setAllBillsCollapsed] = useState(false);

    const { selectedCompany } = useCompanyBranch();

    const [activeData, setActiveData] = useState(0);
    const [totalAccounts, setTotalAccounts] = useState<totalAccounts[]>([]);
    const { totalBalance, getTotalBalance, getAccountBalance } = useCurrentBalance(selectedCompany?.id, {
        finalDate,
        initalBankAccounts: accountBankEntity ? [accountBankEntity] : allAccountBanks,
    });

    const colorSuccess = {
        color: "green",
        fontSize: "13px"
    }

    const colorDanger = {
        color: "red",
        fontSize: "13px"
    }

    const validateFilters = useCallback(() => {
        if (typeFilter === 'day' && (!initialDate || !finalDate)) {
            return 'Selecione a data inicial e a data final para realizar a busca!';
        }

        return '';
    }, [typeFilter, initialDate, finalDate]);

    useEffect(() => {
        if (firstLoadCompleted || !accountBanksLoaded) {
            return;
        }

        if (validateFilters()) {
            resetDefaultDates();
            return;
        }

        getBills().then((allBills) => {
            getAllYears(allBills);
            advancedSearch(allBills);
        });

        setFirstLoadCompleted(true);
    }, [validateFilters, firstLoadCompleted, accountBanksLoaded]);

    useEffect(() => {
        resetDefaultDates();
    }, [typeFilter]);

    useEffect(() => {
        if (isLoadingCharts) {
            getTotalAccounts().then(() => {
                setIsLoadingCharts(false);
            });

        }
    }, [finalDate, accountBankEntity, isLoadingCharts, allAccountBanks, bills]);

    useEffect(() => {
        async function getBankAccounts() {
            const accountBanks = await api.get<BankAccount[]>("/accountsBank");

            setAllAccountBanks(accountBanks.data);
            setAccountBanksLoaded(true);
        }

        getBankAccounts();
    }, [])

    async function getTotalAccounts() {
        var result = await getTotalBalance();
        setTotalAccounts(result.objects);
    }

    function resetDefaultDates() {
        const today = new Date();

        setMonth(today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : String(today.getMonth() + 1));
        setYear(String(today.getFullYear()));

        const startOfMonth = new Date();
        startOfMonth.setDate(1);

        setInitialDate(extractDateStringFromTimestamp(startOfMonth.toISOString()));
        setFinalDate(extractDateStringFromTimestamp(today.toISOString()));
    }

    async function getBills() {
        return CashFlowService.getBills({
            accountBank,
            dreSubCategory: dreSubCategoryId,
            initialDate: '',
            finalDate,
            foreseen,
            accomplished,
        });
    }

    async function getAllYears(allBills: BillsCashFlow[]) {
        var yearsArr: string[] = [];

        for (var bill of allBills) {
            var dueDateSplit = bill.date.split("-");

            if (!yearsArr.includes(dueDateSplit[0])) {
                yearsArr.push(dueDateSplit[0]);
            }
        }

        setAllYears(yearsArr);
    }

    async function advancedSearch(allBills?: BillsCashFlow[]) {
        try {
            setIsSubmit(true);

            const validationErrorMsg = validateFilters();
            if (validationErrorMsg) {
                setMsgError(validationErrorMsg);
                setShowModalError(true);
                setIsSubmit(false);
                return;
            }

            setBills([]);
            setTotalPay(0)
            setTotalReceive(0);
            setAllBillsCollapsed(false);

            if (!allBills) {
                allBills = await CashFlowService.getBills({
                    accountBank,
                    dreSubCategory: dreSubCategoryId,
                    initialDate: '',
                    finalDate,
                    foreseen,
                    accomplished,
                });
            }

            var initial = initialDate;
            var final = finalDate;
            if (typeFilter == "month") {
                var initial = year + "-" + month + "-01";
                var final = year + "-" + month + "-" + getLastDayOfMonth(Number(year), parseInt(month));
            }

            var bills = await CashFlowService.getBills({
                accountBank,
                dreSubCategory: dreSubCategoryId,
                initialDate: initial,
                finalDate: final,
                foreseen,
                accomplished,
            });
 
            var totals = CashFlowService.getTotalAccount(bills, allAccountBanks, accountBank);

            for (let indexBill = 0; indexBill < bills.length; indexBill++) {
                bills[indexBill].balance = getBalanceAccountBankCashFlow(bills[indexBill], accomplished ? totals.openingBalance : 0, allBills, foreseen, accomplished);
            }

            if (bills.length) {
                bills.unshift({
                    id: 0,
                    date: subtractDate(bills[0].date, 1),
                    name: "SALDO ANTERIOR",
                    bankAccount: 0,
                    typeBills: "",
                    dreSubCategoryId: 0,
                    dreSubCategoryEntity: undefined,
                    status: "",
                    totalPaid: 0,
                    amount: 0,
                    balance: bills.length ? getBeforeBalanceCashFlow(subtractDate(bills[0].date, 1), accomplished ? totals.openingBalance : 0, allBills, foreseen, accomplished) : 0,
                });
            }

            const billsMapped = await Promise.all(
                bills.map(async (bill): Promise<BillsCashFlow> => {
                    const foundBankAccount = allAccountBanks.find((bank: BankAccount) => bank.id == bill?.bankAccount);

                    return {
                        ...bill,
                        dreSubCategoryName: bill.dreSubCategoryEntity?.name ?? '',
                        bankAccountNameBank: foundBankAccount?.nameBank ?? '',
                    }
                })
            );

            setBills(billsMapped);

            setTotalPay(totals.totalPay * -1);
            setTotalReceive(totals.totalReceive);
            setTotalBillsAccomplished(totals.totalBillsAccomplished);
            setTotalBillsForeseen(totals.totalBillsForeseen);

            setIsSubmit(false);
            setIsLoadingCharts(true);

            if (showBillDetails) {
                handleCollapseAllBills();
            }

            const cashFlowData = CashFlowService.getCashFlowCompositeChartData({
                bills: billsMapped.slice(1),
                dateType: 'custom',
                customDateDivisor: 5,
            });
            setCashFlow(cashFlowData);

        } catch (error: any) {
            console.error(error.message);
        }

    }

    const handleChangeAccountBank = useCallback((bankAccount: BankAccount | null) => {
        const accountBankId = String(bankAccount?.id ?? '');
        setAccountBank(accountBankId);

        if (accountBankId) {
            setAccountBankEntity(allAccountBanks.find(account => account.id === Number(accountBankId)));
        } else {
            setAccountBankEntity(undefined);
        }
    }, [allAccountBanks]);

    const handleCompositeChartTooltipTitle = useCallback((element: any) => {
        return CashFlowService.getCashFlowCompositeChartTooltipTitle({
            element, dateType: 'custom', 
        });
    }, []);

    const handleClickExportExcel = useCallback(async () => {
        if (bills.length === 0) return;

        setIsGenerationExcel(true);

        const workbook = new Workbook();

        const worksheet = workbook.addWorksheet();
        
        worksheet.columns = [
            { header: 'DATA', key: 'date', width: 15 },
            { header: 'LANÇAMENTO', key: 'name', width: 60 },
            { header: 'BANCO', key: 'bankAccountNameBank', width: 25 },
            { header: 'PLANO DE CONTAS', key: 'dreSubCategoryName', width: 25 },
            { header: 'CRÉDITO (R$)', key: 'credit', width: 15 },
            { header: 'DÉBITO (R$)', key: 'debit', width: 15 },
            { header: 'SALDO', key: 'balance', width: 18 },
            { header: 'SITUAÇÃO', key: 'status', width: 10 },
        ];
        worksheet.getRow(1).font = { bold: true };

        bills.forEach((bill, index) => {
            worksheet.addRow([
                /* DATA  */        bill.date.split("-").reverse().join("/"),
                /* LANÇAMENTO  */  bill.name,
                /* BANCO  */       bill.bankAccountNameBank ?? '',
                /* PLANO  */       bill.dreSubCategoryName ?? '',
                /* CRÉDITO  */     bill.typeBills == "receive" ? new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                   bill.status == "paid"
                                        ? (bill.totalPaid) ? bill.totalPaid : bill.amount
                                        : bill.amount
                                   ) : "",
                /* DÉBITO  */      bill.typeBills == "pay" ? "-" + new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                        bill.status == "paid"
                                        ? (bill.totalPaid) ? bill.totalPaid : bill.amount
                                        : bill.amount
                                   ) : "",
                /* SALDO  */       new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bill.balance),
                /* SITUAÇÃO  */    index > 0 ? (bill.status == "paid" ? 'Realizado' : 'Previsto') : '',
            ]);
        });

        worksheet.addRow([
            'TOTAL',
            '',
            '',
            '',
            new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalReceive),
            new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalPay),
            new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bills[bills.length - 1]?.balance),
            '',
        ]);

        // Totais
        worksheet.addRow([]);
        const rows = worksheet.addRows([
            ['Quantidade Total de Lançamentos', [], bills.length - 1],
            ['Total de Receitas', [], formatCurrency(totalReceive)],
            ['Total de Despesas', [], formatCurrency(totalPay)],
            ['Saldo Lançamentos Realizados', [], formatCurrency(totalBillsAccomplished)],
            ['Saldo Lançamentos Previstos', [], formatCurrency(totalBillsForeseen)],
            ['Saldo Total', [], formatCurrency(bills[bills.length - 1]?.balance)],
        ]);

        rows.forEach(row => {
            row.getCell(1).font = { bold: true };
            row.getCell(2).merge(row.getCell(1));
        });

        await new Promise((resolve) => setTimeout(resolve, 2000));

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), getFileNameWithDate({ filename: 'Fluxo_de_Caixa', extension: 'xlsx' }));

        setIsGenerationExcel(false);
    }, [bills, totalReceive, totalPay, totalBillsAccomplished, totalBillsForeseen, showBillDetails]);

    const handleCollapseBill = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedBillIndex: number) => {
        e.stopPropagation();

        setAllBillsCollapsed(false);

        setBills((prevState) => prevState.map((bill, index) => {
            if (index === selectedBillIndex) {
                return {
                    ...bill,
                    collapsed: !bill.collapsed,
                }
            }
            
            return bill;
        }));
    }, []);

    const handleCollapseAllBills = useCallback(() => {
        setBills((prevState) => prevState.map((bill) => {
            return {
                ...bill,
                collapsed: !allBillsCollapsed,
            }
        }));

        setAllBillsCollapsed((prevState) => !prevState);
    }, [allBillsCollapsed]);

    const handleOpenBillDetails = useCallback((bill: BillsCashFlow) => {
        if (bill.foreseen && bill.foreseen.entityType === 'serviceContract') {
            pushHistory(`contrato-servico/${bill.foreseen.entityId}`);
        } else if (bill.foreseen && bill.foreseen.entityType === 'recurringExpense') {
            pushHistory(`despesas-recorrentes/${bill.foreseen.entityId}`);
        } else {
            const route = bill.typeBills == "receive" ? 'contas-a-receber' : 'contas-a-pagar';
            let billId = bill.id.toString();

            const partialSeparatorIndex = billId.indexOf('.');

            if (partialSeparatorIndex !== -1) {
                billId = billId.substring(0, partialSeparatorIndex);
            }
            
            pushHistory(`${route}/${billId}`);
        }
    }, [pushHistory]);

    return (
        <MainPageContentWrapper>
            <ModalError
                showModalError={showModalError}
                setShowModalError={setShowModalError}
                msgError={msgError}
            />
            <h4>Fluxo de Caixa</h4>
            <div className="col-lg-12 mt-3">
                <Paper className="row pt-3 pb-3">
                    <div className="col-lg-12 d-flex align-items-center mb-3">
                        <Button
                            type='submit'
                            className='mr-3'
                            variant="primary"
                            disabled={isSubmit}
                            onClick={() => advancedSearch()}
                        >
                            {isSubmit ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : <>
                                <span>
                                    <i className="fa fa-search"></i>
                                    Buscar
                                </span>
                            </>}
                        </Button>
                    </div>

                    <div className="col-lg-5">
                        <ApiResourceSelect
                            label="Conta bancária"
                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                            value={accountBank}
                            onSelect={(option) => handleChangeAccountBank(option)}
                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if (!accountBank) return null;
                                return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                            }}
                        />
                    </div>

                    <div className="col-lg-5">
                        <ApiResourceSelect
                            label="Plano de Contas"
                            getOptionLabel={(option: SubCategory) => option.name}
                            value={dreSubCategoryId}
                            onSelect={(option) => setDreSubCategoryId(String(option?.id ?? ''))}
                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'both')}
                            getSelectedOption={(loadedOptions) => {
                                if (!dreSubCategoryId) return null;
                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                            }}
                        />
                    </div>

                    <div className="col-lg-2 mt-3">
                        <FormControlLabel
                            value="true"
                            control={<Checkbox color="primary" checked={showBillDetails} onClick={() => setShowBillDetails(!showBillDetails)} />}
                            label="Mostrar detalhes"
                            labelPlacement="end"
                        />
                    </div>

                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            select
                            label='Filtrar por'
                            margin='normal'
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                        >
                            <MenuItem key='0' value='day'>
                                Dia
                            </MenuItem>

                            <MenuItem key='1' value='month'>
                                Mês
                            </MenuItem>
                        </TextField>
                    </div>

                    {
                        typeFilter == "day"
                            ?
                            <>
                                <div className="col-lg-3">
                                    <TextField
                                        type="date"
                                        label="De"
                                        margin="normal"
                                        size="small"
                                        value={initialDate}
                                        onChange={(e) => setInitialDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <TextField
                                        type="date"
                                        label="Até"
                                        margin="normal"
                                        size="small"
                                        value={finalDate}
                                        onChange={(e) => setFinalDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className="col-lg-3">
                                    <TextField
                                        size="small"
                                        select
                                        label='Mês'
                                        margin='normal'
                                        value={month}
                                        onChange={(e) => setMonth(e.target.value)}
                                    >
                                        <MenuItem key='0' value='01'>
                                            Janeiro
                                        </MenuItem>
                                        <MenuItem key='1' value='02'>
                                            Fevereiro
                                        </MenuItem>
                                        <MenuItem key='2' value='03'>
                                            Março
                                        </MenuItem>
                                        <MenuItem key='3' value='04'>
                                            Abril
                                        </MenuItem>
                                        <MenuItem key='4' value='05'>
                                            Maio
                                        </MenuItem>
                                        <MenuItem key='5' value='06'>
                                            Junho
                                        </MenuItem>
                                        <MenuItem key='6' value='07'>
                                            Julho
                                        </MenuItem>
                                        <MenuItem key='7' value='08'>
                                            Agosto
                                        </MenuItem>
                                        <MenuItem key='8' value='09'>
                                            Setembro
                                        </MenuItem>
                                        <MenuItem key='9' value='10'>
                                            Outubro
                                        </MenuItem>
                                        <MenuItem key='10' value='11'>
                                            Novembro
                                        </MenuItem>
                                        <MenuItem key='11' value='12'>
                                            Dezembro
                                        </MenuItem>
                                    </TextField>
                                </div>

                                <div className="col-lg-3">
                                    <TextField
                                        size="small"
                                        select
                                        label='Ano'
                                        margin='normal'
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        {
                                            allYears.map((allYear: any, index: number) => {
                                                return <MenuItem key={index} value={allYear}>
                                                    {allYear}
                                                </MenuItem>
                                            })
                                        }
                                    </TextField>
                                </div>
                            </>
                    }

                    <div className="col-lg-3">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Exibir resultados</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="true"
                                    control={<Checkbox color="primary" checked={foreseen} onClick={() => setForeseen(!foreseen)} />}
                                    label="Previstos"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="true"
                                    control={<Checkbox color="primary" checked={accomplished} onClick={() => setAccomplished(!accomplished)} />}
                                    label="Realizados"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </div>

                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col-lg-4">
                                <Button
                                    type="button"
                                    variant="outline-light"
                                    onClick={() => setShowCharts((prevState) => !prevState)}
                                    className="d-flex align-items-center"
                                >
                                    <strong className="mr-3">Mostrar Gráficos</strong>
                                    <i className={`${showCharts ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}`}></i>
                                </Button>
                            </div>
                        </div>

                        <Collapse in={showCharts}>
                            <div className="row">
                                <div className="col-lg-6 mt-3 border-top">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap pb-2">
                                            <div className="col-12 d-flex flex-row align-items-center justify-content-center mb-3">
                                                <b>Gráfico do Fluxo de Caixa</b>
                                            </div>
                                            <CashFlowComposedChart
                                                cashFlow={cashFlow}
                                                cashReceive={totalReceive}
                                                cashPay={totalPay * -1}
                                                tooltipTitleHandler={handleCompositeChartTooltipTitle}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 mt-3 border-top">
                                    <BankAccountsTotalsPieChart
                                        title="Saldo Total Por Conta"
                                        totalBalance={totalBalance}
                                        activeData={activeData}
                                        setActiveData={setActiveData}
                                        totalAccounts={totalAccounts}
                                    />
                                </div>
                            </div>
                        </Collapse>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <PDFDownloadLink
                                document={(
                                    <PdfCashFlow
                                        company={selectedCompany}
                                        bills={bills}
                                        totalReceive={totalReceive}
                                        totalPay={totalPay}
                                        accomplished={accomplished}
                                        foreseen={foreseen}
                                        totalBillsAccomplished={totalBillsAccomplished}
                                        totalBillsForeseen={totalBillsForeseen}
                                    />
                                )}
                                fileName={getFileNameWithDate({ filename: 'Fluxo-de-Caixa', extension: 'pdf' })}
                            >
                                <Button
                                    variant="secondary"
                                    className="ml-3"
                                    type="button"
                                >
                                    <i className="flaticon2-printer"></i>
                                    Exportar PDF
                                </Button>
                            </PDFDownloadLink>

                            <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                                <Button
                                    variant="secondary"
                                    className="ml-3"
                                    type="button"
                                    onClick={handleClickExportExcel}
                                    disabled={isGeneratingExcel}
                                >
                                    <i className="flaticon2-sheet"></i>
                                    Exportar Excel
                                    {isGeneratingExcel && (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ml-2"
                                            />
                                        </>
                                    )
                                    }
                                </Button>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="col-lg-12 mt-3 border-top">
                        <Paper style={{ width: '100%' }}>
                            <TableContainer style={{ maxHeight: '75vh' }}>
                                <Table stickyHeader className="wrap">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none">
                                                <IconButton
                                                    aria-label="expand row"
                                                    title="Expandir tudo"
                                                    size="small"
                                                    onClick={() => handleCollapseAllBills()}
                                                >
                                                    <i className={allBillsCollapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                                </IconButton>
                                            </TableCell>
                                            
                                            <TableCell padding="default">
                                                <strong>Data</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Lançamento</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Banco</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Plano de Contas</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Crédito (R$)</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Débito (R$)</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Saldo</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Situação</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            !bills.length
                                                ?
                                                <TableRow role="checkbox" tabIndex={-1}>
                                                    <TableCell scope="row" padding="default" colSpan={8} className="text-center">
                                                        Nenhuma informação encontrada...
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <></>
                                        }

                                        {
                                            bills.map((value, index) => {

                                                if (value.id == 0) {
                                                    return (
                                                        <TableRow
                                                            key={"bill" + index}
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="none"></TableCell>

                                                            <TableCell scope="row" padding="default">
                                                                {value.date.split("-").reverse().join("/")}
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default">
                                                                <b>{value.name.substr(0, 40) + (value.name.length > 40 ? '...' : '')}</b>
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default"></TableCell>
                                                            <TableCell scope="row" padding="default"></TableCell>
                                                            <TableCell scope="row" padding="default"></TableCell>
                                                            <TableCell scope="row" padding="default"></TableCell>

                                                            <TableCell scope="row" padding="default" style={value.balance < 0 ? colorDanger : colorSuccess}>
                                                                {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(value.balance)}
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default"></TableCell>
                                                        </TableRow>
                                                    )

                                                } else {
                                                    return (
                                                        <React.Fragment key={"bill" + index}>
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                className="cursor-pointer"
                                                                onClick={() => handleOpenBillDetails(value)}
                                                            >
                                                                <TableCell padding="none">
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        title="Expandir detalhes"
                                                                        size="small"
                                                                        onClick={(e) => handleCollapseBill(e, index)}
                                                                    >
                                                                        <i className={value.collapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                                                    </IconButton>
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default">
                                                                    {value.date.split("-").reverse().join("/")}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default">
                                                                    {value.name.substr(0, 40) + (value.name.length > 40 ? '...' : '')}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default">
                                                                    {value.bankAccountNameBank ?? ''}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default">
                                                                    {value.dreSubCategoryName ?? ''}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default" style={{ color: value.typeBills == "pay" ? 'red' : 'green' }}>
                                                                    {value.typeBills == "receive" ? new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                                                        value.status == "paid"
                                                                            ? (value.totalPaid) ? value.totalPaid : value.amount
                                                                            : value.amount
                                                                    ) : ""}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default" style={{ color: value.typeBills == "pay" ? 'red' : 'green' }}>
                                                                    {value.typeBills == "pay" ? "-" + new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                                                        value.status == "paid"
                                                                            ? (value.totalPaid) ? value.totalPaid : value.amount
                                                                            : value.amount
                                                                    ) : ""}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default" style={value.balance < 0 ? colorDanger : colorSuccess}>
                                                                    {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(value.balance)}
                                                                </TableCell>

                                                                <TableCell scope="row" padding="default">
                                                                    {
                                                                        value.status == "paid"
                                                                            ?
                                                                            <Badge
                                                                                style={{ background: "#1bc5bd", color: "#fff" }}
                                                                            >
                                                                                Realizado
                                                                            </Badge>
                                                                            :
                                                                            <Badge
                                                                                variant="warning"
                                                                            >
                                                                                Previsto
                                                                            </Badge>

                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                                                    <Collapse in={!!value.collapsed} timeout="auto">
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell padding="default">
                                                                                        <strong>Data de Emissão</strong>
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        <strong>N° do documento</strong>
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        <strong>Beneficiário</strong>
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        <strong>Forma de Pagamento</strong>
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        <strong>Centro de Custos</strong>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell padding="default">
                                                                                        {value.issueDate ? formatDate(value.issueDate) : ''}
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        {value.docNumber ?? ''}
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        {value.beneficiaryName ?? ''}
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        {value.paymentMethod ?? ''}
                                                                                    </TableCell>
                                                                                    <TableCell padding="default">
                                                                                        {value.centerCostName ?? ''}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    )
                                                }

                                            })
                                        }
                                    </TableBody>
                                    <TableFooter style={{ background: "#efefef" }}>
                                        {
                                            bills.length
                                                ?
                                                <>
                                                    <TableRow role="checkbox" tabIndex={-1}>
                                                        <TableCell scope="row" padding="default" colSpan={2}>
                                                            <h6>TOTAL</h6>
                                                        </TableCell>
                                                        {/* <TableCell scope="row" padding="default"></TableCell> */}
                                                        <TableCell scope="row" padding="default"></TableCell>
                                                        <TableCell scope="row" padding="default"></TableCell>
                                                        <TableCell scope="row" padding="default"></TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            <b style={totalReceive <= 0 ? colorDanger : colorSuccess}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalReceive)}</b>
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default" className="pl-1 pr-1">
                                                            <b style={totalPay <= 0 ? colorDanger : colorSuccess}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalPay)}</b>
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            <b style={bills[bills.length - 1]?.balance <= 0 ? colorDanger : colorSuccess}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bills[bills.length - 1]?.balance)}</b>
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default"></TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                <></>
                                        }
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {/* <TablePagination
                            page={page}
                            component="div"
                            count={listOptions.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            backIconButtonProps={{
                                'aria-label': 'Página Anterior',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Próxima Página',
                            }}
                            onChangePage={(_, next) => handleChangePage(next)}
                            onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                        /> */}
                    </div>

                    <div className="col-lg-12 mt-2">
                        <div className="card w-50">
                            <div className="card-body">
                                <Table className="border ml-3 mt-0">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={1}>Quantidade Total de Lançamentos:</TableCell>
                                            <TableCell colSpan={1}>{bills.length - 1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1}>Total de Receitas:</TableCell>
                                            <TableCell colSpan={1}>
                                                <span className="text-green">{formatCurrency(totalReceive)}</span>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1}>Total de Despesas:</TableCell>
                                            <TableCell colSpan={1}><span className="text-danger">{formatCurrency(totalPay)}</span></TableCell>
                                        </TableRow>
                                        {accomplished && (
                                            <TableRow>
                                                <TableCell colSpan={1}>Saldo Lançamentos Realizados:</TableCell>
                                                <TableCell colSpan={1}>
                                                    <span className={totalBillsAccomplished < 0 ? 'text-danger' : 'text-green'}>
                                                        {formatCurrency(totalBillsAccomplished)}
                                                        </span>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {foreseen && (
                                            <TableRow>
                                                <TableCell colSpan={1}>Saldo Lançamentos Previstos:</TableCell>
                                                <TableCell colSpan={1}>
                                                    <span className={totalBillsForeseen < 0 ? 'text-danger' : 'text-green'}>
                                                        {formatCurrency(totalBillsForeseen)}
                                                        </span>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={1}>Saldo Total:</TableCell>
                                            <TableCell colSpan={1}>
                                                <span className={bills[bills.length - 1]?.balance < 0 ? 'text-danger' : 'text-green'}>
                                                    {formatCurrency(bills[bills.length - 1]?.balance ?? 0)}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </MainPageContentWrapper>
    );
}