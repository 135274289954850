import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal
} from "react-bootstrap";

import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';
import ModalError from '../../components/ModalError';

import '../../style.css';
import { extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import LogService from '../../services/LogService';
import { SubCategory } from '../../types/Dre';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import useCurrentBalance from '../../hooks/useCurrentBalance';
import DreCategoryService from '../../services/DreCategoryService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import { BankAccount } from '../../types/BankAccount';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import useAddAccountBank from '../../hooks/addAccountBank';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));


export function NewTransferBillsToReceive() {
    const [activeTab, setActiveTab] = useState("detalhes-receita");

    const [isSubmit, setIsSubmit] = useState(false);
    const [isPaid, setIsPaid] = useState(true);
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)
    const [dreSubCategoryExpenseId, setDreSubCategoryExpenseId] = useState(0)
    
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isGenerateInstallment, setIsGenerateInstallment] = useState(false);
    const [occurrence, setOccurrence] = useState("unica");
    const [amount, setAmount] = useState("0");
    const [isInstallment, setIsInstallment] = useState(false);
    const [fromAccount, setFromAccount] = useState("");
    const [toAccount, setToAccount] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [customer, setCustomer] = useState("");
    const [issuanceDate, setIssuanceDate] = useState("");

    const [loadingExpense, setLoadingExpenseBalance] = useState(false);
    const [loadingRevenue, setLoadingRevenueBalance] = useState(false);
    const [expenseBalance, setExpenseBalance] = useState(0);
    const [revenueBalance, setRevenueBalance] = useState(0);

    const [nextButton, setNextButton] = useState('first');
    const [previousIcon, setPreviousIcon] = useState("flaticon-doc");

    // const detailsRequiredFields = [name, accountBank, payment, dreSubCategoryId, customer, dueDate, amount, issuanceDate];
    // const isDetailsRequiredFieldsFilled = detailsRequiredFields.every(detailsRequiredField => detailsRequiredField);

    const [installments, setInstallments] = useState({
        installments: [
            {
                date: "",
                value: 0,
                typePayment: ""
            }
        ]
    });
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, watch, handleSubmit } = useForm();

    // custom hook
    const { getAccountBalance } = useCurrentBalance();


    useEffect(() => {
        setIssuanceDate(extractDateStringFromTimestamp());
        getCategories();
    }, []);

    subHeader.setTitle("Adicionar Conta a Receber");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const [typeAccountBankBeingCreated, setTypeAccountBankBeingCreated] = useState<"from" | "to">("from");
    const handleCreateToAccountBank = (accountBank: BankAccount) => {
        if (typeAccountBankBeingCreated === 'from') {
            setFromAccount(String(accountBank.id));
        } else {
            setToAccount(String(accountBank.id));
        }
    }

    // function formatToFloat(value: any) {
    //     var auxValue = value;

    //     if (auxValue) {
    //         if (auxValue.includes('R$')) {
    //             auxValue = auxValue.replace('R$', '');
    //         }
            
    //         if (auxValue.includes(',') && !auxValue.includes('.')) {
    //            return parseFloat(auxValue.replace(',', '.'));
               
    //         } else {
    //            return parseFloat(auxValue.replace('.', '').replace(',', '.'));
    //         }
    //     } else {
    //         return auxValue;
    //     }
    // }

    async function getCategories() {
        var idTransferEnter = await DreCategoryService.getDreSubCategoryByName('Transferência de Entrada');
        var idTransferOutput = await DreCategoryService.getDreSubCategoryByName('Transferência de Saída');

        setDreSubCategoryId(idTransferEnter.id);
        setDreSubCategoryExpenseId(idTransferOutput.id);
    }

    function changeOccurrence(type: any)
    {
        if (type == "parcelada") {
            setIsInstallment(true);

        } else {
            setIsInstallment(false);
        }
    }

    function convertToFloat(value: any)
    {
        var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
        if (valueConverted.includes(".") && valueConverted.includes(",")) {
            valueConverted = valueConverted.replace(".", "").replace(",", ".");
        } else {
            valueConverted = valueConverted.replace(",", ".");
        }

        return parseFloat(valueConverted);
    }

    function generateInstallment()
    {
        if (occurrence == "parcelada") {
            if (amount && amount != "0") {
                var allInstallments     = [];
                var countInstallments   = watch('installments');
                var valueExpense        = convertToFloat(amount);
                var resultDivided       = valueExpense / watch('installments');
                var currentDate         = new Date();
    
                for (var index = 0; index < countInstallments; index++) {
                    var month = currentDate.getMonth() + 1;
                    
                    allInstallments.push({
                        date: currentDate.getFullYear() +"-"+ (month < 10 ? "0"+ month : month) +"-"+ currentDate.getDate(),
                        value: resultDivided,
                        typePayment: "Boleto",
                    });

                    currentDate.setMonth(currentDate.getMonth() + 1);
                }
    
                setInstallments({ installments: allInstallments });
                setIsGenerateInstallment(true);

            } else {
                setMsgError("Informe o valor da receita para gerar parcelas!");
                setShowModalError(true);
                setInstallments({ installments: [] });
                setIsGenerateInstallment(false);
            }
            
            

        } else {
            setInstallments({ installments: [] });
            setIsGenerateInstallment(false);
        }
    }

    async function calcAvailableMoney(accountBankId: number, setLoading: any, setAvailableMoney: any) {
        setLoading(true);

        var totalValueAccount = await getAccountBalance(accountBankId);
        setAvailableMoney(totalValueAccount);
        
        setLoading(false);
    }

    const onSubmit = async (dataForm: any) => {
        try {
            setIsSubmit(true);

            const balanceFromAccount = await getAccountBalance(fromAccount);
            const today = new Date().toISOString().split('T')[0];

            if (formatToFloat(amount) > balanceFromAccount) {
                setActiveTab("detalhes-receita");
                setIsSubmit(false);
                setMsgError(`Não é possivel transferir mais que ${formatCurrency(balanceFromAccount)} da conta origem!`);
                setShowModalError(true);
                return false;
            }

            if (!inputsVerify(dataForm)) return;

            const fromBank = await api.get(`/accountBank/${parseInt(fromAccount)}`);
            const toBank = await api.get(`/accountBank/${parseInt(toAccount)}`);

            const transferDate = String(dataForm.dueDate).length > 0 ? dataForm.dueDate : null;

            const payData = {
                name: dataForm.name,
                amount: formatToFloat(amount),
                remaining: formatToFloat(amount),
                totalPaid: isPaid ? formatToFloat(amount) : null,
                payedDate: isPaid ? transferDate : null,
                status: "paid",
                bankAccount: String(dataForm.bankAccount).length > 0 ? parseInt(fromAccount) : null,
                nameBank: String(dataForm.bankAccount).length > 0 ? fromBank.data.nameBank : null,
                centerCost: String(dataForm.centerCost).length > 0 ? parseInt(centerCost) : null,
                docNumber: String(dataForm.docNumber).length > 0 ? dataForm.docNumber : null,
                comments: String(dataForm.comments).length > 0 ? dataForm.comments : null,
                barCode: String(dataForm.barCode).length > 0 ? dataForm.barCode : null,
                dueDate: transferDate,
                payment: String(dataForm.payment).length > 0 ? dataForm.payment : null,
                supplier: null,
                issueDate: String(issuanceDate).length > 0 ? issuanceDate : null,
                occurrence: "unica",
                categoryName: String(dataForm.categoryName).length > 0 ? dataForm.categoryName : null,
                dreSubCategoryId: dreSubCategoryExpenseId > 0 ? dreSubCategoryExpenseId : null,
                recordType: "transfer"
            }

            var idPay = 0;
            await api.post("billsToPay", payData).then(async(payResponse) => {
                idPay = payResponse.data.id;
                
                LogService.logRegister({
                    itemId: payResponse.data.id,
                    module: 'Contas a Pagar',
                    itemName: payResponse.data.name + ' - Unitário'
                });
            });

            const receiveData = {
                isPaid,
                name: dataForm.name,
                amount: formatToFloat(amount),
                totalPaid: isPaid ? formatToFloat(amount) : null,
                payedDate: isPaid ? transferDate : null,
                remaining: formatToFloat(amount),
                status: isPaid ? "paid" : "pending",
                bankAccount: String(dataForm.bankAccount).length > 0 ? parseInt(toAccount) : null,
                nameBank: String(dataForm.bankAccount).length > 0 ? toBank.data.nameBank : null,
                categoryName: String(dataForm.categoryName).length > 0 ? dataForm.categoryName : null,
                centerCost: String(dataForm.centerCost).length > 0 ? parseInt(centerCost) : null,
                comments: String(dataForm.comments).length > 0 ? dataForm.comments : null,
                customer: null,
                docNumber: String(dataForm.docNumber).length > 0 ? dataForm.docNumber : null,
                dueDate: transferDate,
                issuanceDate: String(issuanceDate).length > 0 ? issuanceDate : null,
                occurrence: "unica",
                payment: String(dataForm.payment).length > 0 ? dataForm.payment : null,
                dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
                recordType: "transfer",
            }

            await api.post("billsToReceive", receiveData).then(async(receiveResponse) => {
                await api.put(`/billsToReceive/${receiveResponse.data.id}`, { idBillTransfer: idPay});
                await api.put(`/billsToPay/${idPay}`, { idBillTransfer: receiveResponse.data.id});
                
                LogService.logRegister({
                    itemId: receiveResponse.data.id,
                    module: 'Contas a Receber',
                    itemName: receiveResponse.data.name
                });
            });

            history.push("/contas-a-receber");
            setIsSubmit(false);

        } catch(error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    function inputsVerify(dataForm: any) {
        if(!dataForm.name) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Nome da receita é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if (fromAccount == toAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Não é possivel fazer transferência para mesma conta!");
            setShowModalError(true);
            return false;
        }

        if(!fromAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Selecione a conta de origem!");
            setShowModalError(true);
            return false;
        }

        if(!toAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Selecione a conta de destino!");
            setShowModalError(true);
            return false;
        }

        // if(!dataForm.categoryName) {
        //     setIsSubmit(false);
        //     setMsgError("Campo Plano de Contas é obrigatório!");
        //     setShowModalError(true);
        //     return false;
        // }

        if(!dreSubCategoryId) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas de Entrada é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dreSubCategoryExpenseId) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas de Saída é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dataForm.payment) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dataForm.dueDate) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo data de transferência é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!amount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            return false;
        }
        if(Number(amount) <= 0) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            return false;
        }

        return true;
    }

    function changeTab(tab: string, icon: string, previousIcon: string) {
        document.getElementById('detalhes-receita')?.classList.remove('current');
        document.getElementById('detalhes-conta')?.classList.remove('current');

        let all_icons = document.querySelectorAll<HTMLElement>('.icon_change_color');
        if (all_icons != undefined) {
            all_icons.forEach(function(item){
                item.style.color = '#666';
            });
        }

        // if (!detailsRequiredFields[0]) {
        //     setRevenueNameError(true);
        // }

        // if (!detailsRequiredFields[1]) {
        //     setAccountBankError(true);
        // }

        // if (!detailsRequiredFields[2]) {
        //     setPayMethodsError(true);
        // }

        // if (!detailsRequiredFields[3]) {
        //     setAccountPlanError(true);
        // }

        // if (!detailsRequiredFields[4]) {
        //     setCustomerError(true);
        // }

        // if (!detailsRequiredFields[5]) {
        //     setDueError(true);
        // }

        // if (!detailsRequiredFields[6]) {
        //     setValueError(true);
        // }

        // if (!detailsRequiredFields[7]) {
        //     setIssuanceDateError(true);
        // }

        let prev_icon = document.querySelector<HTMLElement>('.'+previousIcon);
        prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
        prev_icon?.classList.add("text-success");
        // if (previousIcon === "flaticon-doc") {
        //     if (isDetailsRequiredFieldsFilled) {
        //         const current_icon = document.querySelector<HTMLElement>('.flaticon2-delete');
                
        //         if (current_icon) {
        //             current_icon?.classList.replace("flaticon2-delete", "flaticon2-check-mark");
        //             current_icon?.classList.replace("text-danger", "text-success");
        //         } else {
        //             prev_icon?.classList.replace("flaticon-doc", "flaticon2-check-mark");
        //             prev_icon?.classList.add("text-success");
        //         }
        //     } else {
        //         prev_icon?.classList.replace("flaticon-doc", "flaticon2-delete");
        //         prev_icon?.classList.add("text-danger");
        //     }
        // } else {
        //     prev_icon?.classList.replace(previousIcon, "flaticon2-check-mark");
        //     prev_icon?.classList.add("text-success");
        // }

        let sel_icon = document.querySelector<HTMLElement>('.'+icon);
        if (sel_icon != undefined) {
            sel_icon.style.color = '#fff';
        }

        document.getElementById(tab)?.classList.add('current');
        setActiveTab(tab);
        if (tab == 'detalhes-receita') {
            setNextButton('first');
        } else {
            setNextButton('last');
        }

        setPreviousIcon(icon);
    }

    function backNextOption(value:string){
        let arr = ['detalhes-receita', 'detalhes-conta'];
        let arr2 = ['flaticon-doc', 'flaticon-file-2'];
        let count = arr.length
        let index = arr.indexOf(activeTab);

        if (value == 'back') {
            if (activeTab == arr[0]) {
                setNextButton('first');
            } else {
                index = index-1;
                changeTab(arr[index], arr2[index], arr2[index+1]);
            }
        } else {
            if (activeTab == arr[count-1]) {
                setNextButton('last');
            } else {                
                index = index+1;
                changeTab(arr[index], arr2[index], arr2[index-1]);
            }
        }
    }

    return (

        <MainPageContentWrapper>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateToAccountBank}
            />

            <form onSubmit={handleSubmit(onSubmit)}
                    className={`${classes.container} steppers`}
                    autoComplete="off"
            >
                <div className="col-lg-12">
                    <div
                        className='col-xl-2 col-lg-3 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper' style={{ float: "left" }}
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                            <div className='card-body px-6 py-10'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' id="detalhes-receita" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-receita', 'flaticon-doc', previousIcon)} style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className="flaticon-doc icon_change_color" style={{color: "#fff"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Receita</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a receita</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px' style={{borderLeft: "1px dotted #bbb"}}></div>
                                    </div>
                                    <div className='stepper-item' id="detalhes-conta" data-kt-stepper-element='nav' onClick={() => changeTab('detalhes-conta', 'flaticon-file-2', previousIcon)}  style={{cursor: "pointer"}}>
                                        <div className='stepper-wrapper' style={{marginBottom: "0"}}>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check text-success font-size-h3'></i>
                                                <i className="flaticon-file-2 icon_change_color" style={{color: "#666"}}></i>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title text-break'>Detalhes da Conta</h3>
                                                <div className='stepper-desc fw-semibold text-break'>Detalhes sobre a conta</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                    <div className="col-xl-10 col-lg-9" style={{ float: "right" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div id="form-buttons"
                                    className="bg-white d-flex justify-content-between align-items-center border border-gray-200
                                    rounded p-2 position-fixed"
                                    style={{ zIndex: 1 }}>
                                    <Link to="/contas-a-receber">
                                        <i className="flaticon2-left-arrow-1 hover px-2 py-1 font-size-h2"></i>
                                    </Link>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        disabled={isSubmit}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {isSubmit ? <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className="ml-2">
                                                Aguarde...
                                            </span>
                                        </> : <>
                                            <span>
                                                Salvar
                                            </span>
                                        </>}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="mb-3 mt-8 mt-lg-22 mt-md-10">
                            {nextButton === 'first' ? (
                                <Link to="/contas-a-receber" className="btn btn-secondary mb-2 mr-2 btn-lg">
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Link>
                            ) : (
                                <Button
                                type="button"
                                variant="outline-primary"
                                onClick={() => backNextOption('back')}
                                className="mb-2 mr-2 btn-lg"
                                >
                                    <i className="flaticon2-left-arrow" style={{fontSize: "7px"}}></i>
                                    <span>Voltar</span>
                                </Button>
                            )}
                            <Button
                                type="button"
                                variant={nextButton === 'last' ? 'secondary' : 'primary'}
                                onClick={() => backNextOption('next')}
                                className="mb-2 btn-lg"
                                disabled={nextButton === 'last'}
                            >
                                <span>
                                    Avançar
                                </span>&nbsp;
                                <i className="flaticon2-right-arrow pr-0 pl-3" style={{fontSize: "7px"}}></i>
                            </Button>
                            <hr />
                        </div>

                        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs" style={{ display: "none" }}>
                            <Tab eventKey="detalhes-receita" title="Detalhes da Receita">
                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            {...register("name")}
                                            label="Nome da Receita"
                                            margin="normal"
                                            required
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            style={{ width: "100%" }}
                                            label="Conta Origem"
                                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                            value={fromAccount}
                                            required
                                            onSelect={(option) => {
                                                setFromAccount(option ? String(option.id) : '');

                                                if (option) {
                                                    calcAvailableMoney(option.id, setLoadingExpenseBalance, setExpenseBalance);
                                                } else {
                                                    setExpenseBalance(0);
                                                }
                                            }}
                                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!fromAccount) return null;
                                                return loadedOptions.find((option) => option.id === Number(fromAccount)) ?? BankAccountService.getBankAccountById(fromAccount)
                                            }}
                                            renderAddButton={(
                                                <ApiResourceSelectAddButton
                                                    label="Adicionar Conta Bancária"
                                                    onClick={() => {
                                                        setShowModalAddAccountBank(true);
                                                        setTypeAccountBankBeingCreated('from');
                                                    }}
                                                />
                                            )}
                                        />

                                        {
                                            fromAccount
                                            ?
                                                loadingExpense
                                                ?
                                                    <Spinner animation="border" variant="dark" size="sm" />
                                                :   <p><b>Saldo:</b> {formatCurrency(expenseBalance)}</p>
                                            : <></>
                                        }
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            style={{ width: "100%" }}
                                            label="Conta Destino"
                                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                            value={toAccount}
                                            required
                                            onSelect={(option) => {
                                                setToAccount(option ? String(option.id) : '');

                                                if (option) {
                                                    calcAvailableMoney(option.id, setLoadingRevenueBalance, setRevenueBalance);
                                                } else {
                                                    setRevenueBalance(0);
                                                }
                                            }}
                                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!toAccount) return null;
                                                return loadedOptions.find((option) => option.id === Number(toAccount)) ?? BankAccountService.getBankAccountById(toAccount)
                                            }}
                                            renderAddButton={(
                                                <ApiResourceSelectAddButton
                                                    label="Adicionar Conta Bancária"
                                                    onClick={() => {
                                                        setShowModalAddAccountBank(true);
                                                        setTypeAccountBankBeingCreated('to');
                                                    }}
                                                />
                                            )}
                                        />

                                        {
                                            toAccount
                                            ?
                                                loadingRevenue
                                                ?
                                                    <Spinner animation="border" variant="dark" size="sm" />
                                                :   <p><b>Saldo:</b> {formatCurrency(revenueBalance)}</p>
                                            : <></>
                                        }
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            {...register("payment")}
                                            select
                                            label="Forma de Pagamento"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            size="small"
                                            required
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            {
                                                paymentOptions.map((payment, index) => (
                                                    <MenuItem key={index + 1} value={payment.value}>
                                                        {payment.value}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Plano de Contas de Entrada"
                                            getOptionLabel={(option: SubCategory) => option.name}
                                            value={dreSubCategoryId}
                                            onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!dreSubCategoryId) return null;
                                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                            }}
                                            required
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Plano de Contas de Saída"
                                            getOptionLabel={(option: SubCategory) => option.name}
                                            value={dreSubCategoryExpenseId}
                                            onSelect={(option) => setDreSubCategoryExpenseId(option?.id ?? 0)}
                                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!dreSubCategoryId) return null;
                                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                            }}
                                            required
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={12}>
                                        <NumericFormat
                                            label="Valor"
                                            required
                                            startAdornment="R$"
                                            value={amount}
                                            onChange={(evt) => setAmount(evt.target.value)}
                                        />
                                    </Grid>
                                    
                                    <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            {...register("dueDate")}
                                            type="date"
                                            label="Data de Transferência"
                                            margin="normal"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                        />
                                    </Grid>
                                </div>

                                <Grid item lg={2} md={6} xs={12}>
                                    <FormControlLabel
                                        label="Pago"
                                        checked={isPaid}
                                        onChange={() => setIsPaid(state => !state)}
                                        control={
                                            <Checkbox color="primary" disabled/>
                                        }
                                    />
                                </Grid>

                            </Tab>

                            <Tab eventKey="detalhes-conta" title="Detalhes da Conta">
                                <div className="row">
                                    <Grid item lg={6} md={6} xs={12}>
                                        <TextField
                                            {...register("docNumber")}
                                            label="Nº do documento"
                                            className="ml-0"
                                            margin="normal"
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} xs={12}>
                                        <ApiResourceSelect
                                            label="Centro de Custos"
                                            getOptionLabel={(option: CenterCost) => option.name}
                                            value={centerCost}
                                            onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                            apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!centerCost) return null;
                                                return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                            }}
                                        />
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item lg={5} md={6} xs={12}>
                                        <label htmlFor="formFile" className="mt-3">Anexo</label>
                                        <input className="form-control" type="file" id="formFile"/>
                                        <label htmlFor="formFile" className="mt-3">
                                            Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.
                                        </label>
                                    </Grid>
                                </div>

                                <div className="row">
                                    <Grid item xs={12}>
                                        <TextField
                                            { ...register("comments") }
                                            label="Observações"
                                            multiline
                                            rows="2"
                                            className="ml-0"
                                            margin="normal"
                                            size="small"
                                        />
                                    </Grid>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </form>
        </MainPageContentWrapper>
    );
}
