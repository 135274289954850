import { Card, Collapse, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { NumericFormat } from '../../components/NumericFormat';
import { Search } from '../../components/Search';
import api from '../../services/Api';
import { BsVariant } from '../../types/BsVariant';
import { getDate } from '../../utils/dateTimeHelper';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import nfApiHelper from '../../utils/nfApiHelper';
import '../../style.css';
import { IssueErrors } from '../../types/IssueErrors';
import { useNf } from '../../hooks/nf';
import useBackendLoad from '../../hooks/backendReload';
import { ModalValidateNf } from '../../components/ModalValidateNf';
import useSendEmailAndWhatsapp from '../../hooks/sendEmailAndWhatsapp';
import EmailNfService from '../../services/EmailNfService';
import WhatsappNfService from '../../services/WhatsappNfService';
import NfeService from '../../services/NfeService';
import ModalSuccess from '../../components/ModalSuccess';
import { ModalSendEmail } from '../../components/ModalSendEmail';
import { ModalSendWhatsapp } from '../../components/ModalSendWhatsapp';
import { createSameOriginUrl } from '../../utils/createSameOriginUrl';
import { getFileNameFromUrl } from '../../utils/getFileNameFromUrl';
import CustomerService from '../../services/CustomerService';
import ModalLimit from '../../components/ModalLimit';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';
import { NfApiSituation } from '../../types/NfApiSituation';
import axios from 'axios';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';

type Filters = {
    searchQuery: string;
    situation: string;
    valueMin: number;
    valueMax: number;
    createdDateMin: string;
    createdDateMax: string;
    outputDateMin: string;
    outputDateMax: string;
}

type NfeSituationChart = {
    apiSituation: string;
    quantity: number
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Ref" },
    { reference: "rps", value: "RPS", notSortable: true },
    { reference: "date", value: "Data" },
    { reference: "customer", value: "Cliente" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "situation", value: "Situação", situation: true, notSortable: true },
];

type Invoices = {
    id: number,
    date: string,
    customer: number,
    customerName?: string,
    totalValue: number,
    situation: string
}

export function ListNfe() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [all, setAll] = useState<any[]>([]);
    const [countTotalNf, setCountTotalNf] = useState(0);
    const { location: { pathname }, push: pushHistory } = useHistory();
    const { nfeEnabled } = useNf();

    const [nfeToIssue, setNfeToIssue] = useState<any|undefined>();
    const [showModalIssue, setShowModalIssue] = useState(false);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const [nfeDetails, setNfeDetails] = useState<any>();
    const [cancelNote, setCancelNote] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelError, setCancelError] = useState("");

    const [showModalInfo, setShowModalInfo] = useState(false);
    const [msgInfo, setMsgInfo] = useState("");
    const [issueErrors, setIssueErrors] = useState<IssueErrors[]>([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [createdDateMin, setCreatedDateMin] = useState("");
    const [createdDateMax, setCreatedDateMax] = useState("");
    const [outputDateMin, setOutputDateMin] = useState("");
    const [outputDateMax, setOutputDateMax] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");

    //Envio de Email/Whatspp
    const {
        showModalEmail, setShowModalEmail,
        infoEmail, setInfoEmail,
        emailsHistory, setEmailsHistory,
        showModalWhatsapp, setShowModalWhatsapp,
        infoWhatsapp, setInfoWhatsapp,
        whatsappHistory, setWhatsappHistory,
    } = useSendEmailAndWhatsapp();

    const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
    const [showModalLimit, setShowModalLimit] = useState(false);

    const [nfeSituation, setNfeSituation] = useState<NfeSituationChart[]>();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [all]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const response = await api.get<{rows: any[], count: number}>('nfe', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = response.data;

        const nfes = await Promise.all(rows.map(async (nfe) => {
            if(!nfApiHelper.isProccessing(nfe.api_situation)) {
                return nfe;
            }
            const response = await api.post(`nfe/check/${nfe.id}`);
            return response.data;
        }));

        setAll(nfes);
        setCountTotalNf(count);
    }, []);

    useEffect(() => {
        if(!nfeEnabled) {
            setShowModalInfo(true);
            setMsgInfo('Empresa não pode emitir NF-e portanto essa funcionalidade foi desativada!');
        }
    }, [nfeEnabled]);
    
    useEffect(() => {
        async function getBodyData() {
            const list: BodyDataBaseProps[][] = [];
            const aux = all;
    
            for (const invoice of aux) {
                invoice.customerName = CustomerService.getCustomerName(invoice.customerEntity);

                const status = invoice.situation === "open" ? "Em aberto"
                : invoice.situation === "progress" ? "Em andamento"
                : invoice.situation === "attended" ? "Atendido"
                : invoice.situation === "canceled" ? "Cancelado"
                : invoice.situation === "aproved" ? "Aprovado"
                : "";
            
                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(invoice.id), id: true },
                    { for: "rps", value: '' },
                    { for: "date", value: getDate({initialDate: new Date(invoice.createdDate)}).dateStr },
                    { for: "customer", value: String(invoice.customerName ?? '-') },
                    { for: "totalValue", value: formatCurrency(invoice.totalValue) },
                    { for: "situation", value: nfApiHelper.getStatus(invoice.api_situation) },
                    { for: "editButton", value: nfApiHelper.canEdit(invoice.api_situation) ? 'y' : 'n', hidden: true },
                ];
    
                list.push(data);
            }
    
            setBodyData(list);
        }
        getBodyData();
    }, [all]);

    const issueButtonCondition = useCallback((id: string) => {
        const row = all.find(obj => String(obj.id) === id);

        if(!row) return false;

        return nfApiHelper.canIssue(row.api_situation) && nfeEnabled;
    }, [all, nfeEnabled]);
    
    const detailsButtonCondition = useCallback((id: string) => {
        const row = all.find(obj => String(obj.id) === id);

        if(!row) return false;

        return nfApiHelper.hasDetails(row.api_situation);
    }, [all]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = all.filter((cat) => cat.id !== Number(id));
            await api.delete(`nfe/${id}`);

            setAll([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [all]);

    async function handleConfirmIssue() {
        setShowModalIssue(false);
        setIssueErrors([]);
        if(nfeToIssue) {
            try {
                const response = await api.post(`nfe/issue/${nfeToIssue.id}`);
                
                const aux = all;
                const index = aux.findIndex(obj => obj.id === response.data.id);
                aux[index] = response.data;

                setAll([...aux]);

                setShowModalInfo(true);
                setMsgInfo('Nota fiscal enviada para processo de emissão com sucesso!');
            } catch (error: any) {
                console.log({error});
                setShowModalInfo(true);
                setMsgInfo(error.response?.data?.message ?? 'Erro ao emitir');
                if(error.response?.data?.errors) {
                    setIssueErrors(error.response.data.errors);
                }
            }
        }
    }

    async function handleConfirmCancel() {
        setCancelError('');
        if(!cancelReason) {
            setCancelError('Preencha a justificativa do cancelamento');
            return;
        }
        if(cancelReason.length < 15 || cancelReason.length > 255) {
            setCancelError('Campo justificativa deve ter entre 15 e 255 caracteres');
            return;
        }

        try {
            const response = await api.post(`nfe/cancel/${nfeDetails.id ?? 0}`, {
                reason: cancelReason,
            });

            const aux = all;
            const index = aux.findIndex(obj => obj.id === response.data.id);
            aux[index] = response.data;

            setNfeDetails({
                ...response.data,
                api_last_response: JSON.parse(response.data.api_last_response),
            });

            setAll([...aux]);
            setCancelNote(false);
            setCancelReason('');
        } catch (error: any) {
            setCancelError(error.response?.data?.message ?? 'Erro ao cancelar a nota');
        }
    }

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickIssue = useCallback(async (id: string) => {
        const canIssue = await checkLimits();

        if (!canIssue) return;

        setShowModalIssue(true);
        setNfeToIssue(all.find((nfe) => nfe.id === Number(id)));
    }, [all]);

    const getNfe = useCallback(async (id: string) => {
        const response = await api.get(`nfe/${id}`);

        const apiResponse = response.data.api_last_response ? JSON.parse(response.data.api_last_response) : null;

        let xmlUrl = '';
        let xmlFileName = '';
        let xmlCancelUrl = '';
        let xmlCancelFileName = '';

        if(apiResponse?.body?.caminho_xml_nota_fiscal) {
            xmlUrl = await createSameOriginUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_nota_fiscal}`);
            xmlFileName = getFileNameFromUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_nota_fiscal}`);
        }
        if(apiResponse?.body?.caminho_xml_cancelamento) {
            xmlCancelUrl = await createSameOriginUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_cancelamento}`);
            xmlCancelFileName = getFileNameFromUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_cancelamento}`);
        }

        setNfeDetails({
            ...response.data,
            xmlUrl,
            xmlFileName,
            xmlCancelUrl,
            xmlCancelFileName,
            api_last_response: apiResponse,
        });
    }, []);

    const handleClickDetails = useCallback(async (id: string) => {
        setShowModalDetails(true);
        setCancelNote(false);
        setCancelError('');
        await getNfe(id);
    }, [getNfe]);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setValueMin(0);
        setValueMax(0);
        setCreatedDateMin("");
        setCreatedDateMax("");
        setOutputDateMin("");
        setOutputDateMax("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, situation,
            valueMin, valueMax, createdDateMin, createdDateMax, outputDateMin, outputDateMax
        };

        reloadData();
    }, [
        all, searchQuery, situation,
        valueMin, valueMax, createdDateMin, createdDateMax, outputDateMin, outputDateMax
    ]);

    const sendEmailAndWhatsappButtonCondition = useCallback((id: string) => {
        const row = all.find(obj => String(obj.id) === id);

        if(!row) return false;

        return nfApiHelper.isAuthorized(row.api_situation);
    }, [all]);

    async function getEmails(nfeId: number) {
        setEmailsHistory([]);
        const emails = await EmailNfService.getAllEmailsByNfId(nfeId, 'nfe');
        setEmailsHistory(emails);
    }

    async function getWhatsapps(nfeId: number) {
        setWhatsappHistory([]);
        const history = await WhatsappNfService.getAllWhatsappsByNfId(nfeId, 'nfe');
        setWhatsappHistory(history);
    }

    function handleOpenSendEmailModal(id: string) {
        const row = all.find(obj => String(obj.id) === id);

        if(!row) return false;

        setShowModalEmail(true);
        getEmails(row.id);
        setInfoEmail({
            refId: String(row.id),
            refName: 'nfe',
            toName: '',
            customerName: row.customerName ?? '',
            customerId: String(row.customer),
            email: row.customerEntity.email,
            contactName: row.customerName ?? '',
            message: NfeService.getDefaultEmailMessage(),
            emailSubject: 'Comprovante fiscal',
        });
    }

    const handleClickSendEmail = useCallback(async () => {
        try {
            const emails = infoEmail.email.split(';').map(email => email.trim());

            const row = all.find(obj => obj.id === Number(infoEmail.refId));

            if(!row) return false;

            await EmailNfService.sendEmail(emails, {
                nf: row,
                type: 'nfe',
                customerId: infoEmail.customerId,
                customerName: infoEmail.customerName,
                contactName: infoEmail.contactName,
                message: infoEmail.message,
                refName: infoEmail.refName,
                subject: infoEmail.emailSubject,
            });

            setShowModalSuccess(true);
        } catch (error: any) {
            setMsgInfo(error.response?.data?.message ?? 'Falha ao enviar o e-mail!');
            setShowModalInfo(true);
        }

        setShowModalEmail(false);
    }, [infoEmail, all]);

    function handleOpenSendWhatsappModal(id: string) {
        const row = all.find(obj => String(obj.id) === id);

        if(!row) return false;

        setShowModalWhatsapp(true);
        getWhatsapps(row.id);
        setInfoWhatsapp({
            refId: String(row.id),
            refName: 'nfe',
            customerName: row.customerName ?? '',
            customerId: String(row.customer),
            number: row.customerEntity.cell ?? '',
            message: NfeService.getDefaultWhatsappMessage(),
        });
    }

    const handleClickSendWhatsapp = useCallback(async () => {
        try {
            const row = all.find(obj => obj.id === Number(infoWhatsapp.refId));
        
            if(!row) return;

            await WhatsappNfService.sendWhatsapp(infoWhatsapp.number, {
                nf: row,
                type: 'nfe',
                customerId: infoWhatsapp.customerId,
                customerName: infoWhatsapp.customerName,
                message: infoWhatsapp.message,
                refName: infoWhatsapp.refName,
            });

            setShowModalSuccess(true);
        } catch (error) {
            setMsgInfo('Falha ao enviar a mensagem!');
            setShowModalInfo(true);
        }

        setShowModalWhatsapp(false);
    }, [infoWhatsapp, all]);

    async function checkLimits() {
        const response = await api.get('companySubscriptionPlans/limits/byField?field=nfe');
        const limits = response.data;
        if(limits.max !== -1 && limits.current >= limits.max) {
            setShowModalLimit(true);
            setModalLimitMsg([`O limite para emissões de notas fiscais por período foi atingido! (limite: ${limits.max})`]);
            return false;
        }

        return true;
    }

    const fetchNfeSituation = async () => {
        try {
            const results = await axios.get("/nfe/getNfeSituations");
            setNfeSituation(results.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchNfeSituation();
    }, []);

    const formatDataKey = () => {
        return "Quantidade";
    }

    const formatYAxisSituation = (tickItem: string) => {
        switch(tickItem) {
            case "processando_autorizacao":
                return "Em andamento";
            case "autorizado":
                return "Autorizado";
            case "erro_autorizacao":
                return "Erro de autorização";
            case "cancelado":
                return "Cancelado";
            case "erro_api":
                return "Denegado";
            case "aguardando":
                return "Aguardando";
            case "tempo_processamento_excedido":
                return "Tempo de processamento excedido";
            default:
                return "Nenhum";
        }
    }

    const formatLabel = (label: string, type: string) => {
        if (type === "category") {
            switch(label) {
                case "processando_autorizacao":
                    return "Em andamento";
                case "autorizado":
                    return "Autorizado";
                case "erro_autorizacao":
                    return "Erro de autorização";
                case "cancelado":
                    return "Cancelado";
                case "erro_api":
                    return "Denegado";
                case "aguardando":
                    return "Aguardando";
                case "tempo_processamento_excedido":
                    return "Tempo de processamento excedido";
                default:
                    return "Nenhum";
            }
        }
    }

    const CustomTooltip = ({ active, payload, label, type }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="styled-tooltip">
                    <p className='mb-1'><span className='font-weight-bolder'>{formatLabel(label, type)}</span>{`: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const createCustomTooltip = (type: any) => (props: any) => (
        <CustomTooltip {...props} type={type} />
    )

    return (
        <>
            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
            />
            <ModalLimit
               showModalLimit={showModalLimit}
               setShowModalLimit={setShowModalLimit}
               messages={modalLimitMsg}
            />
            <ModalSendEmail
                showModal={showModalEmail}
                setShowModal={setShowModalEmail}
                onClickSend={handleClickSendEmail}
                emailsHistory={emailsHistory}
                infoEmail={infoEmail}
                setInfoEmail={setInfoEmail}
            />
            <ModalSendWhatsapp
                showModal={showModalWhatsapp}
                setShowModal={setShowModalWhatsapp}
                onClickSend={handleClickSendWhatsapp}
                whatsappHistory={whatsappHistory}
                infoWhatsapp={infoWhatsapp}
                setInfoWhatsapp={setInfoWhatsapp}
            />

            <Modal
                show={showModalInfo}
                onHide={() => setShowModalInfo(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgInfo}</b>
                    {!!issueErrors && issueErrors.length > 0 && (
                        <Table>
                            <TableHead >
                                <TableRow>
                                    {/* <TableCell padding="default">
                                        <strong>Código</strong>
                                    </TableCell> */}
                                    <TableCell padding="default">
                                        <strong>Erro</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {issueErrors.map((error, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell>
                                            {nfeDetails.api_last_response['body']['status_sefaz']}
                                        </TableCell> */}
                                        <TableCell>
                                            <span className="text-danger">{error.mensagem}</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalInfo(!showModalInfo)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Emitir */}
            <ModalValidateNf
                nf={nfeToIssue}
                nfType="nfe"
                showModal={showModalIssue}
                setShowModal={setShowModalIssue}
                onConfirmIssue={handleConfirmIssue}
                onCloseModal={() => setNfeToIssue(undefined)}
            />

            {/* Modal detalhes da nota emitida */}
            <Modal
                show={showModalDetails}
                onHide={() => setShowModalDetails(false)}
                aria-labelledby="contained-modal-warning"
                centered 
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Nota Fiscal Nº {nfeDetails?.id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {nfeDetails && (
                        <>
                            {nfApiHelper.isProccessing(nfeDetails.api_situation) && (
                                <div className="alert alert-info"><i className="la la-check"></i> NF-e em processamento para autorização</div>
                            )}
                            {nfApiHelper.hasError(nfeDetails.api_situation) && (
                                <>
                                    <div className="alert alert-danger"><i className="la la-times"></i> Erro de autorização </div>
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell padding="default">
                                                    <strong>Código</strong>
                                                </TableCell>
                                                <TableCell padding="default">
                                                    <strong>Mensagem</strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {nfeDetails.api_last_response['body']['status_sefaz']}
                                                </TableCell>
                                                <TableCell>
                                                    {nfeDetails.api_last_response['body']['mensagem_sefaz']}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                            {nfApiHelper.isAuthorized(nfeDetails.api_situation) && (
                                <>
                                    <div className="alert alert-success"><i className="la la-check"></i> NF-e autorizada e emitida com sucesso </div>
                                    <p>Chave NFE: { nfeDetails.api_last_response['body']['chave_nfe'] }</p>
                                    <p>Número da Nota: { nfeDetails.api_last_response['body']['numero'] }</p>

                                    {cancelNote && (
                                        <div className="row col-lg-8">
                                            <TextField
                                                label="Justificativa do cancelamento *"
                                                multiline
                                                rows="4"
                                                margin="normal"
                                                size="small"
                                                inputProps={{  maxLength: 5000 }}
                                                value={cancelReason}
                                                onChange={(e) => setCancelReason(e.target.value)}
                                                error={!!cancelError}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                            <small className="text-danger">{cancelError}</small>
                                        </div>
                                    )}
                                </>
                            )}
                            {nfApiHelper.isCanceled(nfeDetails.api_situation) && (
                                <>
                                    <div className="alert alert-danger"><i className="la la-times"></i> NF-e cancelada </div>
                                    {nfeDetails.api_situation === NfApiSituation.PROCESSING_TIMEOUT && (
                                        <>
                                            <p>Motivo: Não foi possível estabelecer comunicação com a prefeitura durante o processamento da nota. Contate o suporte para mais informações.</p>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {nfeDetails && (
                        <>
                            {!cancelNote && nfApiHelper.isAuthorized(nfeDetails.api_situation) && !!nfeDetails.api_last_response && (
                                <>
                                    {!!nfeDetails.api_last_response['body']['caminho_danfe'] && (
                                        <a href={`${nfeDetails.api_last_response['url']}${nfeDetails.api_last_response['body']['caminho_danfe']}`} target="_blank" className="btn btn-success">Imprimir DANFE</a>
                                    )}
                                    {!!nfeDetails.xmlUrl && (
                                        <a href={`${nfeDetails.xmlUrl}`} target="_blank" className="btn btn-success" download={nfeDetails.xmlFileName}>Baixar XML</a>
                                    )}

                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button variant="danger" onClick={() => setCancelNote(true)}>Cancelar Nota</Button>
                                        : <></>
                                    }
                                </>
                            )}
                            {!cancelNote && nfApiHelper.isCanceled(nfeDetails.api_situation) && !!nfeDetails.xmlCancelUrl && (
                                <a href={`${nfeDetails.xmlCancelUrl}`} target="_blank" className="btn btn-success" download={nfeDetails.xmlCancelFileName}>Baixar XML</a>
                            )}

                            {(cancelNote && user.isAccountant == "n") && (
                                <>
                                    <Button variant="danger" onClick={handleConfirmCancel}>Cancelar Nota</Button>
                                    <Button variant="secondary" onClick={() => setCancelNote(false)}>Voltar</Button>
                                </>
                            )}
                        </>
                    )}
                    <Button variant="secondary" onClick={() => setShowModalDetails(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <MainPageContentWrapper>
            <h4 className="font-weight-bolder">Lista de notas fiscais</h4>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2 text-dark"
                                onClick={() => handleClickAdd()}
                            >
                                <i className="fas fa-plus text-dark"></i>
                                Adicionar
                            </Button>
                            : <></>
                        }
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={setAdvancedSearch}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                <Collapse in={advancedSearch}>
                    <div className="shadow-sm p-6 rounded-sm border border-2">
                        <h4>Pesquisa avançada</h4>

                        <div className="row">
                            <div className="col-lg-2">
                                <TextField
                                    select
                                    size="small"
                                    label="Situação"
                                    margin="normal"
                                    value={situation}
                                >
                                    <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                        Nenhum
                                    </MenuItem>
                                    <MenuItem key="1" value="processando_autorizacao" onClick={() => setSituation('processando_autorizacao')}>
                                        Em andamento
                                    </MenuItem>
                                    <MenuItem key="2" value="autorizado" onClick={() => setSituation('autorizado')}>
                                        Autorizado
                                    </MenuItem>
                                    <MenuItem key="3" value="erro_autorizacao" onClick={() => setSituation('erro_autorizacao')}>
                                        Erro de autorização
                                    </MenuItem>
                                    <MenuItem key="4" value="cancelado" onClick={() => setSituation('cancelado')}>
                                        Cancelado
                                    </MenuItem>
                                    <MenuItem key="5" value="erro_api" onClick={() => setSituation('erro_api')}>
                                        Denegado
                                    </MenuItem>
                                    <MenuItem key="6" value="aguardando" onClick={() => setSituation('aguardando')}>
                                        Aguardando
                                    </MenuItem>
                                </TextField>
                            </div>
                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor"
                                    startAdornment="DE"
                                    value={valueMin}
                                    onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
                                />
                            </div>
                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor"
                                    startAdornment="ATÉ"
                                    value={valueMax}
                                    onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data criação"
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            DE
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={createdDateMin}
                                    onChange={(e) => setCreatedDateMin(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data criação"
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            ATÉ
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={createdDateMax}
                                    onChange={(e) => setCreatedDateMax(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de saída"
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            DE
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={outputDateMin}
                                    onChange={(e) => setOutputDateMin(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <TextField
                                    type="date"
                                    label="Data de saída"
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            ATÉ
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={outputDateMax}
                                    onChange={(e) => setOutputDateMax(e.target.value)}
                                />
                            </div>
                            <div className="col-12 d-flex mt-6">
                                <Button
                                    onClick={handleClickSearch}
                                    className="mr-3"
                                >
                                    <i className="fa fa-search"></i>
                                    Pesquisar
                                </Button>

                                <Button
                                    onClick={clearSearch}
                                    variant="danger"
                                >
                                    <i className="fas fa-times"></i>
                                    Limpar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>

                <div className="row">
                    <Paper elevation={1} className="mt-3 col-lg-4 col-12 border border-2 py-3" style={{ height: "fit-content" }}>
                        <Card variant="outlined" className="bg-light p-5 border mb-3">
                            <span className="text-dark font-size-lg font-weight-bolder">Situação</span> - mês corrente

                            <BarChart className="w-100 mt-6" width={350} height={400} data={nfeSituation} layout="vertical">
                                <XAxis type='number' />
                                <YAxis dataKey="apiSituation" type="category" tickFormatter={formatYAxisSituation} />
                                <Tooltip wrapperStyle={{ outline: "none" }} content={createCustomTooltip("category")} />
                                <Legend formatter={formatDataKey} />
                                <Bar dataKey="quantity" fill="#7fc6e0" />
                            </BarChart>
                        </Card>
                    </Paper>

                    <div className="mt-3 col-lg-8 col-12">                
                        <ListWithModalChangeSituation
                            invoice
                            headData={headData}
                            bodyData={bodyData}
                            onEdit={handleClickEdit}
                            onClone={handleClickClone}
                            onDelete={handleClickDelete}
                            customButtons={
                                user.isAccountant == "n"
                                ?
                                [
                                    {
                                        class: 'btn-light-info',
                                        content: (<i className='flaticon-upload mr-2'></i>),
                                        variant: BsVariant.INFO,
                                        popup: "Emitir nota fiscal",
                                        onClick: handleClickIssue,
                                        showCondition: issueButtonCondition,
                                    },
                                    {
                                        class: 'btn-green p-2 mr-3',
                                        content: (<i className='flaticon-whatsapp mr-2'></i>),
                                        variant: BsVariant.SUCCESS,
                                        popup: "Envio de whatsapp",
                                        onClick: handleOpenSendWhatsappModal,
                                        showCondition: sendEmailAndWhatsappButtonCondition,
                                    },
                                    {
                                        class: 'btn-blue p-2 mr-3',
                                        content: (<i className="flaticon2-email mr-2"></i>),
                                        variant: BsVariant.SUCCESS,
                                        popup: "Envio de email",
                                        onClick: handleOpenSendEmailModal,
                                        showCondition: sendEmailAndWhatsappButtonCondition,
                                    },
                                    {
                                        class: 'btn-light-info',
                                        content: (<i className='flaticon-info mr-2'></i>),
                                        variant: BsVariant.INFO,
                                        popup: "Visualizar nota fiscal",
                                        onClick: handleClickDetails,
                                        showCondition: detailsButtonCondition,
                                    },
                                ]
                                : [
                                    {
                                        class: 'btn-light-info',
                                        content: (<i className='flaticon-info mr-2'></i>),
                                        variant: BsVariant.INFO,
                                        popup: "Visualizar nota fiscal",
                                        onClick: handleClickDetails,
                                        showCondition: detailsButtonCondition,
                                    }
                                ]
                            }
                            sortable={true}
                            loadData={loadData}
                            totalCount={countTotalNf}
                            triggerLoad={triggerLoad}
                            setTriggerLoad={setTriggerLoad}
                        />
                    </div>
                </div>
            </MainPageContentWrapper>
        </>
    );
}