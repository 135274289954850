import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../app/style.css";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "Informe no mínimo 3 caracteres")
      .max(50, "Informe no máximo 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Informe no mínimo 3 caracteres")
      .max(50, "Informe no máximo 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { authToken } }) => {
            disableLoading();
            props.login(authToken);
          })
          .catch((error) => {
            const defaultMessage = 'Ocorreu um erro de comunicação com o servidor, contate o suporte.'

            if ([401, 403].includes(error.response?.status ?? 0)) {
              setStatus(error.response?.data?.message ?? defaultMessage);
              setBlockReason(error.response?.data?.reason ?? '');
            } else {
              setStatus(
                defaultMessage
                // intl.formatMessage({
                //   id: "AUTH.VALIDATION.INVALID_LOGIN",
                // })
              );
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="d-flex">
        <Link to="/" className="flex-column-auto mb-14 pb-0">
            <img
                alt="Logo"
                className="max-h-60px"
                src={toAbsoluteUrl("/media/logos/header/logo-header-black-login.png")}
            />
        </Link>
      </div>
      {/* begin::Head */}
      <div className="mb-8">
        <h2 className="font-weight-boldest mb-4">
          Acesse o Uninteract
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
        </h2>
        <h6 className="text-muted font-weight-bolder">
          Sua porta de entrada para a gestão financeira empresarial eficiente.
        </h6>
      </div>
      {/* end::Head */}

      {formik.status && (
          <div className="form-group bg-light-danger rounded-lg px-3 py-4 text-center border border-danger">
            <span className="text-danger">{formik.status}</span>
            {blockReason && (
              <>
                <p className="text-danger">Motivo: {blockReason} </p>
              </>
            )}
          </div>
        )}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <div>
            <label htmlFor="email" className="font-weight-bolder">Email/usuário</label>
            <input
              id="email"
              placeholder="Digite o email ou nome de usuário"
              type="text"
              className={`custom-form-shadow custom-form-control form-control h-auto py-4 pr-10 rounded-lg ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email == "Required field" ? "Campo obrigatório!" : formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div>
            <label htmlFor="senha" className="font-weight-bolder">Senha</label>
            <div className="position-relative">
              <input
                id="senha"
                placeholder="Digite a senha"
                type={togglePassword ? "text" : "password"}
                className={`custom-form-shadow custom-form-control form-control h-auto py-4 pr-18 rounded-lg ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              <i className={"position-absolute pointer text-hover-primary fas " + (togglePassword ? "fa-eye" : "fa-eye-slash")}
                onClick={() => setTogglePassword(!togglePassword)}
                style={{ top: "15.75px", left: "86.25%", cursor: "pointer" }}></i>
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password == "Required field" ? "Campo obrigatório!" : formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-column justify-content-between align-items-end">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 font-weight-bolder text-hover-primary mb-3 mr-2 text-right w-fit-content"
            id="kt_login_forgot"
            style={{ width: "fit-content" }}
          >
            Esqueceu a senha?
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`bg-gradient btn btn-primary font-weight-boldest rounded-2xl px-9 py-4 my-3 w-100`}
            style={{ border: "1.5px solid #508da4" }}
          >
            <span>Entrar</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
