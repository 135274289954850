import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Checkbox, 
        FormControlLabel, 
        MenuItem, 
        Table, 
        TableBody, 
        TableCell, 
        TableContainer, 
        TableHead, 
        TableRow, 
        TextField, 
        Tooltip,
        Zoom
} from '@material-ui/core';
import ModalError from '../../components/ModalError';
import api from '../../services/Api';
import { formatCurrency } from '../../utils/formatCurrency';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import fs from 'fs';
import CustomerService from '../../services/CustomerService';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BillsToReceive } from '../../types/BillsToReceive';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../hooks/backendReload';
import DownloadableResource from '../../components/DownloadableResource';
import { useDownloadResource } from '../../hooks/downloadResource';
import { createSameOriginUrl } from '../../utils/createSameOriginUrl';
import ModalLimit from '../../components/ModalLimit';
import { MainPageContentWrapper } from '../../components/MainPageContentWrapper';

type billsToReceive = {
    id: number,
    check: boolean,
    billet: number,
    name: string,
    customer: string,
    customerName: string,
    dueDate: string,
    doc: string,
    value: number,
    occurrence: string,
    generateRemittance: string;
    remittanceDocument: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "billetNumber", value: "Boleto" },
    { reference: "name", value: "Cliente" },
    { reference: "date", value: "Vencimento" },
    { reference: "doc", value: "Doc" },
    { reference: "value", value: "Valor" },
    { reference: "occurrence", value: "Ocorrência" },
];

export function NewRemittanceBillsToReceive() {
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
    const {
        resourceUrl: remittanceFileUrl,
        setResourceUrl: setRemittanceFileUrl,
        handleExecuteDownload,
    } = useDownloadResource();
    const [remittanceFilename, setRemittanceFilename] = useState('');

    // STATES
    const [generatedRemittance, setGeneratedRemittance] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [accountBank, setAccountBank] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filterGenerated, setFilterGenerated] = useState("n");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [billsToReceive, setBillsToReceive] = useState<billsToReceive[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalBillsToReceive, setCountTotalBillsToReceive] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
    const [showModalLimit, setShowModalLimit] = useState(false);

    const searchBank = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {        
        setSearching(true);
        if (accountBank) {
            const raw = {
                bank: Number(accountBank),
                text: searchText,
                filterGenerated: 'n',
            };
            
            const { data } = await api.post<{rows: BillsToReceive[], count: number}>('/billsToReceive/search/remittance', raw, {
                params: {
                    skip: rowsPerPage * currentPage,
                    take: rowsPerPage,
                    sortReference: sortReference,
                    sortDirection: sortDirection,
                }
            });

            const { rows: billsToReceive, count } = data;

            const bills = [];
            for (var bill of billsToReceive) {
                const nameCustomer = CustomerService.getCustomerName(bill.customerEntity);

                bills.push({
                    id: bill.id,
                    check: false,
                    billet: bill.billetNumber,
                    name: bill.name,
                    customer: bill.customer,
                    customerName: nameCustomer,
                    dueDate: bill.dueDate,
                    doc: bill.name,
                    value: bill.amount,
                    occurrence: bill.occurrence,
                    generateRemittance: bill.generateRemittance,
                    remittanceDocument: bill.remittanceDocument,
                });
            }
            setBillsToReceive(bills);
            setCountTotalBillsToReceive(count);

        } else {
            setMsgError("Selecione a conta bancária para pesquisar!");
            setShowModalError(true);
        }
        setSearching(false);
    }, [accountBank, searchText]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];

        for (const bill of billsToReceive) {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(bill.id), hidden: true, id: true },
                { for: "billetNumber", value: String(bill.billet), },
                { for: "name", value: bill.customerName },
                { for: "date", value:  formatDate(bill.dueDate)},
                { for: "doc", value: bill.doc ?? "-" },
                { for: "value", value: formatCurrency(bill.value) },
                { for: "occurrence", value: bill.occurrence ?? "-" },
            ];

            list.push(data);
        }

        setBodyData(list);
    }, [billsToReceive]);

    function handleSearchBank(accountBank?: BankAccount | null) {
        if (accountBank !== undefined) {
            setAccountBank(String(accountBank?.id ?? ''));
        }
        
        reloadData();
    }
    
    async function generateRemittance() {
        setGenerating(true);
        const checkedBills: billsToReceive[] = [];

        for (var bill of billsToReceive) {
            if (bill.check) {
                checkedBills.push(bill);
            }
        }

        if (checkedBills.length === 0) {
            setMsgError("Selecione pelo menos uma conta a receber para gerar a remessa!");
            setShowModalError(true);
            setGenerating(false);
            return;
        }

        const canGenerateRemittance = await checkLimits(checkedBills);
        if (!canGenerateRemittance) {
            setGenerating(false);
            return;
        }
        
        validateCustomer().then(async(response) => {
            if (response) {
                var billets = [];
                for (var bill of checkedBills) {
                    const billData    = await api.get(`/billsToReceive/${bill.id}`);
                    
                    billets.push({
                        account: billData.data.bankAccount,
                        dueDate: billData.data.dueDate,
                        value: billData.data.amount,
                        customer: billData.data.customer,
                        idBill: bill.id
                    });

                    const billEdit = {
                        generateRemittance: "y"
                    }

                    await api.put(`/billsToReceive/${bill.id}`, billEdit);
                }

                const bank = await api.get(`/accountBank/${accountBank}`);
                const bankEdit = {
                    shipmentSequence: bank.data.shipmentSequence + 1
                }
                await api.post(`/accountBank/${accountBank}`, bankEdit);


                const billetData = {
                    account: accountBank,
                    billets: billets
                }
                var responseData = await api.post("/billet/generate-remittance", billetData);

                const remittanceFilePath = responseData.data;

                const indexOfDirectorySeparator = remittanceFilePath.lastIndexOf('/');
                const filename = indexOfDirectorySeparator !== -1 ? remittanceFilePath.substring(indexOfDirectorySeparator + 1) : remittanceFilePath;
                const url = await createSameOriginUrl(`${process.env.REACT_APP_BILLET_API_URL}/${remittanceFilePath}`)

                setRemittanceFilename(filename);
                setRemittanceFileUrl(url);
                setGeneratedRemittance(true);
            } else {
                setMsgError("Erro ao gerar remessa, verifique os dados do cliente!");
                setShowModalError(true);
            }
        });

        setGenerating(false);
    }

    async function validateCustomer() {
        var isValid = true;
        for (var bill of billsToReceive) {
            if (bill.check) {
                const customer = await api.get(`/customer/${bill.customer}`);

                if (customer.data.typePeople == "physical") {
                    var cpfFormated = customer.data.cpf.replace(/[^0-9]/g, "");
                    isValid = cpf.isValid(cpfFormated);

                } else {
                    var cnpjFormated = customer.data.cnpj.replace(/[^0-9]/g, "");
                    isValid = cnpj.isValid(cnpjFormated);
                }                

                if (isValid) {
                    break;
                }
            }
        }

        return isValid;
    }

    function handleChangeSelectBill(checked: boolean, id: string) {
        setBillsToReceive((prevState) => prevState.map((bill) => {
            if (String(bill.id) === id) {
                return {
                    ...bill,
                    check: checked,
                }
            } else {
                return bill;
            }
        }));
    }

    function handleChangeSelectAll(checked: boolean) {
        setSelectAll(checked);
        setBillsToReceive(prevState => (
            prevState.map(bill => {
                return {
                    ...bill,
                    check: checked,
                };
            })
        ));
    }

    const checkedConditionSelectAllBills = useCallback(() => {
        return selectAll;
    }, [selectAll]);

    const checkedConditionSelectedBill = useCallback((id: string) => {
        const billToReceive = billsToReceive.find((bill) => String(bill.id) === id);

        return billToReceive?.check ?? false;
    }, [billsToReceive]);

    function formatDate(date: any)
    {
        var valueDate   = new Date(date);
        var month       = (valueDate.getMonth() + 1) < 10  ? "0" + (valueDate.getMonth() + 1) : (valueDate.getMonth() + 1);
        var day         = (valueDate.getDate() + 1)   < 10  ? "0" + (valueDate.getDate() + 1) : (valueDate.getDate() + 1);
        
        return day +"/"+ month +"/"+ valueDate.getFullYear();
    }

    async function checkLimits(checkedBills: billsToReceive[]) {
        const response = await api.get('companySubscriptionPlans/limits/byField?field=billet_remittance');
        const limits = response.data;

        const current = limits.current + checkedBills.length;

        if(limits.max !== -1 && current > limits.max) {
            setShowModalLimit(true);
            setModalLimitMsg([`O limite para geração de boletos por período foi atingido! (limite: ${limits.max})`]);
            return false;
        }

        return true;
    }

    return (
        <MainPageContentWrapper>
            <DownloadableResource
                url={remittanceFileUrl}
                filename={remittanceFilename}
                onExecuteDownload={handleExecuteDownload}
            />

            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalLimit
               showModalLimit={showModalLimit}
               setShowModalLimit={setShowModalLimit}
               messages={modalLimitMsg}
            />

            <div className="row">
                <div className="col-lg-2 d-flex align-items-start">
                    <Button
                        type='button'
                        variant="primary"
                        className="mt-5"
                        disabled={accountBank ? false : true}
                        onClick={() => generateRemittance()}
                    >                                            
                        {
                            generating
                            ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="m-0 mr-3 p-0"
                                    />
                                    Gerando ...
                                </>
                            :
                            <>
                                <i className="fas fa-plus"></i>
                                Gerar Remessa
                            </>
                        }                        
                    </Button>
                </div>

                <div className="col-lg-3">
                    <ApiResourceSelect
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={accountBank}
                        required
                        onSelect={(option) => {handleSearchBank(option)}}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!accountBank) return null;
                            return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                        }}
                    />
                </div>

                {/* <div className="col-lg-2">
                    <TextField
                        select
                        size="small"
                        label="Gerado"
                        margin="normal"
                        value={filterGenerated}
                    >
                        <MenuItem key="0" value="all" onClick={() => setFilterGenerated('all')}>
                            Todos
                        </MenuItem>
                        <MenuItem key="1" value="y" onClick={() => setFilterGenerated('y')}>
                            Gerados
                        </MenuItem>
                        <MenuItem key="2" value="n" onClick={() => setFilterGenerated('n')}>
                            Não Gerados
                        </MenuItem>
                    </TextField>
                </div> */}

                <div className="col-lg-3">
                    <TextField 
                        size="small"
                        label="Pesquise"
                        margin="normal"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>

                <div className="col-lg-2 d-flex align-items-center">
                    <Button
                        type='button'
                        variant="primary"
                        onClick={() => handleSearchBank()}
                    >                                   
                        {
                            searching
                            ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            :
                                <i className="flaticon2-search p-0" style={{ color: "#fff" }}></i>
                        }         
                    </Button>
                </div>
            </div>

            
            {
                generatedRemittance
                ?
                    <div className="mt-3">
                        <div className='text-center p-5 mt-5' style={{ background: "#DFEDD6" }}>
                            <b>Remessa gerada com sucesso!</b>
                            <p className="m-0">Importe essa remessa em seu banco para registrar os boletos</p>
                        </div>
                    </div>
                :
                <>
                    {
                        accountBank
                        ?
                            <div className="mt-3">
                                <ListWithModalChangeSituation
                                    headData={headData}
                                    bodyData={bodyData}
                                    sortable={true}
                                    lastCell={false}
                                    loadData={searchBank}
                                    totalCount={countTotalBillsToReceive}
                                    triggerLoad={triggerLoad}
                                    setTriggerLoad={setTriggerLoad}
                                    defaultSortReference="billetNumber"
                                    selectCheckbox={{
                                        reference: 'billetNumber',
                                        showCondition: (id) => true,
                                        onChange: handleChangeSelectBill,
                                        checkedCondition: checkedConditionSelectedBill,
                                        onChangeSelectAll: handleChangeSelectAll,
                                        checkedConditionSelectAll: checkedConditionSelectAllBills,
                                    }}
                                />
                            </div>
                        :
                            <div className="mt-3">
                                <div className='bg-warning-o-30 text-center p-5 mt-5'>
                                    Selecione um <b>banco</b> para gerar a remessa!
                                </div>
                            </div>
                    }
                </>
            }

            
        </MainPageContentWrapper>
    );
}
